import React from "react";

import Grid from "@material-ui/core/Grid";
import GenericObjectDisplay from "../../clientShared/components/display/GenericObjectDisplay";
import GenericObjectAsTable from "../../clientShared/components/display/GenericObjectAsTable";
import PaymentPaidStateChip from "./ACHPaymentPaidStateChip";
import PaymentAdminFlaggedChip from "./PaymentAdminFlaggedStateChip";
import PaymentsAdminButtonSetAdminReviewState from "./PaymentsAdminButtonSetAdminReveiwState";
import {PAYMENT_TYPE_PRIORITY_ACH, PAYMENT_TYPE_STRIPE} from "../../constants/paymentConstants";
import PaymentsAdminButtonSetPaymentClearedState from "./PaymentsAdminButtonSetPaymentClearedState";
import DetailedUserAccountInfoLink from "../links/DetailedUserAccountInfoLink";
import SetStripePaymentLogStateButton from "./SetStripePaymentLogStateButton";
import PaymentClearedChip from "./PaymentClearedChip";
import PaymentHasFundsLocationChip from "./PaymentHasFundsLocationChip";
import Typography from "@material-ui/core/Typography";
import RenderDeposifyTags from "../tags/DeposifyTags";


export function StripePaymentDisplay(props) {


    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item>
                &nbsp;
            </Grid>

            <Grid item>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <PaymentPaidStateChip achPayment={props.stripePaymentLog}/>
                    </Grid>
                    <Grid item>
                        <PaymentAdminFlaggedChip achPayment={props.stripePaymentLog}/>
                    </Grid>
                    <Grid item>
                        <PaymentClearedChip paymentObject={props.stripePaymentLog}/>
                    </Grid>
                    <Grid item>
                        <PaymentHasFundsLocationChip paymentObject={props.stripePaymentLog}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Typography variant="button" display="block">
                    TAGS
                </Typography>
                <RenderDeposifyTags deposifyTags={props.stripePaymentLog.deposify_tags}/>
            </Grid>

            <Grid item>
                <GenericObjectDisplay {...props}/>
            </Grid>

            <Grid item>
                <DetailedUserAccountInfoLink accountUID={props.stripePaymentLog.account_uid}/>
            </Grid>

            <Grid item>
                <h2>
                    &nbsp;&nbsp;Danger Buttons
                </h2>
                <SetStripePaymentLogStateButton stripePaymentLog={props.stripePaymentLog}/>

                <br/><br/>
                <PaymentsAdminButtonSetAdminReviewState paymentObject={props.stripePaymentLog} isStripe={true}/>
                <br/><br/>
                {/*<PaymentsAdminButtonSetPaymentClearedState*/}
                {/*    paymentObject={props.stripePaymentLog}*/}
                {/*    paymentUID={props.stripePaymentLog.payment_log_uid}*/}
                {/*    paymentType={PAYMENT_TYPE_STRIPE}*/}
                {/*/>*/}
            </Grid>

            <Grid item>
                <h2>
                    &nbsp;&nbsp;Stripe Charge Payload
                </h2>
                <GenericObjectAsTable sourceObject={props.stripePaymentLog.stripe_charge_payload}/>
            </Grid>

            <Grid item>
                &nbsp;
            </Grid>

        </Grid>
    );
}

