import React from 'react';
import {NULL_UUID_STRING} from "../../constants/dataConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/pro-regular-svg-icons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CancelButton from "../utilities/buttons/CancelButton";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import {faExclamation} from "@fortawesome/pro-solid-svg-icons";
import DialogActions from "@material-ui/core/DialogActions";
import {
    FUNDS_LOCATION_TYPE_BANK_ACCOUNT,
    FUNDS_LOCATION_TYPE_DEPOSIFY_LOAN
} from "../../autoGenerated/constants/services/UserAccountsServiceConstants";
import DepositsAdminService from "../../api/depositsAdminService";
import {RESOURCE_TYPE_DEPOSIT} from "../../autoGenerated/constants/resourceTypeConstants";
import * as Sentry from "@sentry/browser";
import toast from "react-hot-toast";
import {bindActionCreators} from "redux";
import {resourceActionUpdateSingleResource} from "../../clientShared/actions/resourceActions";
import {connect} from "react-redux";
import ReusableFundsLocation from "./ReusableFundsLocation";


const NewFundsLocationDialog = ({deposit, ...props}) => {

    const [currentFundsLocationUID, setCurrentFundsLocationUID] = React.useState(deposit.funds_location_uid);
    const [showDialog, setShowDialog] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    function handleOpen() {
        setShowDialog(true);
        setCurrentFundsLocationUID(deposit.funds_location_uid);
    }

    function handleClose() {
        setShowDialog(false);
        setShowSpinner(false);
    }

    return (
        <>
            <Button
                color="primary"
                onClick={handleOpen}
                variant={'outlined'}
                size={'small'}
            >
                <FontAwesomeIcon
                    icon={faPencil}
                    fixedWidth
                />
                &nbsp;Edit Funds Location
            </Button>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="fullscreen-deposit-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                //fullScreen
            >
                <DialogTitle id="edit-funds-location-dialog-title">Edit Funds Location</DialogTitle>

                <DialogContent>
                    <ReusableFundsLocation
                        initialFundsLocationUID={currentFundsLocationUID}
                        valueChangeCallback={handleValueChange}
                    />
                </DialogContent>

                <DialogActions>
                    <CancelButton handleCloseFunction={handleClose} buttonText={'Close'}/>

                    <SpinnerButton
                        // Non-button props
                        showSpinner={showSpinner}
                        // Button props
                        variant="contained"
                        color="primary"
                        disabled={currentFundsLocationUID === deposit.funds_location_uid}
                        onClick={handleChangeFundsLocation}
                    >
                        <FontAwesomeIcon
                            icon={faExclamation}
                            fixedWidth
                        />
                        &nbsp;Set Funds Location
                    </SpinnerButton>
                </DialogActions>

            </Dialog>

        </>
    );

    function handleValueChange(newValue) {
        setCurrentFundsLocationUID(newValue);
    }

    function handleChangeFundsLocation() {
        setShowSpinner(true);

        const fundsLocation = currentFundsLocationUID === NULL_UUID_STRING ? FUNDS_LOCATION_TYPE_DEPOSIFY_LOAN : FUNDS_LOCATION_TYPE_BANK_ACCOUNT;

        DepositsAdminService.AdminSetDepositFundsLocation(deposit.deposit_uid, fundsLocation, currentFundsLocationUID)
            .then(function (response) {
                toast.success('Funds location updated');
                handleClose();
                setTimeout(() => {
                    props.resourceActionUpdateSingleResource(response.deposit, RESOURCE_TYPE_DEPOSIT);
                }, 500);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed to set funds location');
            })
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(null, mapActionsToProps)(React.memo(NewFundsLocationDialog));


