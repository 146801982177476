/*
    Dev
 */

import React, {useState} from "react";
import {convertHexUIDStringToFull} from "../../clientShared/utilities/uuidUtilities";
import {bindActionCreators} from "redux";
import {adminActionLoadAllUIDS} from "../../actions/adminActions";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
import {useUserAccountLoader} from "../../autoGenerated/hooks/resourceLoadingHooks";
import {
    useBalanceChangeLogUIDListLoader,
    useBalanceSourceTransferUIDListLoader,
    useDepositUIDListLoader, useFundsLockUIDListLoader,
    useInternationalUserBankAccountUIDListLoader,
    useKnownIPAddressUIDListLoader,
    useKnownUserAgentUIDListLoader,
    useLoanUIDListLoader,
    usePriorityACHPaymentUIDListLoader,
    usePriorityACHRecurringPaymentUIDListLoader, useReleasedFundsLockUIDListLoader,
    useSentEmailUIDListLoader,
    useSezzleOrderUIDListLoader,
    useStripePaymentLogUIDListLoader,
    useUSDomesticUserBankAccountUIDListLoader,
    useUserAccountBalanceSourceUIDListLoader,
    useUserHistoryEntryUIDListLoader,
    useUserWithdrawUIDListLoader
} from "../../autoGenerated/hooks/resourceUIDListLoadingHooks";
import UserAccountInfoSummary from "./UserAccountInfoSummary";
import {
    BalanceChangeLogsTable, BalanceSourceTransfersTable,
    DepositsTable, FundsLocksTable,
    InternationalBankAccountsTable,
    KnownIpAddressesTable,
    KnownUserAgentsTable,
    LoansTable,
    PriorityAchPaymentsTable,
    PriorityAchRecurringPaymentsTable, ReleasedFundsLocksTable,
    SentEmailsTable,
    SezzleOrdersTable,
    StripePaymentLogsTable,
    USDomesticBankAccountsTable, UserAccountBalanceSourcesTable,
    UserHistoryEntriesTable,
    UserWithdrawsTable
} from "../../autoGenerated/components/tables/allObjectTables";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabPanel from "@material-ui/lab/TabPanel";
import Paper from "@material-ui/core/Paper";
import TabContext from "@material-ui/lab/TabContext";
import Grid from "@material-ui/core/Grid";
import HomeLink from "../admin/buttons/linkButtons/HomeLink";
import BackLink from "../admin/buttons/linkButtons/BackLink";
import UserAccountAdmin from "./UserAccountAdmin";
import qs from "qs";
import {history} from "../../history/history";
import {APP_ROUTE_DETAILED_USER_ACCOUNT, APP_ROUTE_RESEND_EMAIL_VERIFICATION} from "../../constants/appRoutes";

const useStyles = makeStyles({
    root: {
        padding: 30,
        // overflowX: 'scroll',
        // height: '100vh',
        // width: '100%'
        overflow: 'auto'
    },
    paper: {
        // width: '100%',
        maxWidth: 1600
    },
    tabPanelRoot: {
        padding: 0
    },
    tabWrapper: {
        borderTop: '1px solid #005980'
    }
});

const TAB_INDEX_DEPOSITS = "0";
const TAB_INDEX_USER_WITHDRAWALS = "1";
const TAB_INDEX_US_DOM_BANK_ACCOUNTS = "2";
const TAB_INDEX_INT_BANK_ACCOUNTS = "3";
const TAB_INDEX_USER_HISTORY = "4";
const TAB_INDEX_IP_ADDRESSES = "5";
const TAB_INDEX_USER_AGENTS = "6";
const TAB_INDEX_SENT_EMAIL = "7";
const TAB_INDEX_ACH_PAYMENTS = "8";
const TAB_INDEX_ACH_RECURRING_PAYMENTS = "9";
const TAB_INDEX_STRIPE_PAYMENTS = "10";
const TAB_INDEX_LOANS = "11";
const TAB_INDEX_BALANCE_CHANGE_LOG = "12";
const TAB_INDEX_SEZZLE = "13";
const TAB_INDEX_ADMIN = "14";
const TAB_INDEX_BALANCE_SOURCES = "15";
const TAB_INDEX_BALANCE_SOURCE_TRANSFERS = "16";
const TAB_INDEX_FUNDS_LOCKS = "17";
const TAB_INDEX_FUNDS_LOCKS_RELEASED = "18";


function DetailedUserAccountInfoPageTwo(props) {
    const classes = useStyles();

    // Grab the UID from the URL
    const accountUID = convertHexUIDStringToFull(props.match.params.account_uid);
    const {loading: accLoading, error: accError, userAccount} = useUserAccountLoader(accountUID);

    const {depositUIDList} = useDepositUIDListLoader(accountUID);
    const {withdrawUIDList} = useUserWithdrawUIDListLoader(accountUID);
    const {bankAccountUIDList: usDomUIDList} = useUSDomesticUserBankAccountUIDListLoader(accountUID);
    const {bankAccountUIDList: intUIDList} = useInternationalUserBankAccountUIDListLoader(accountUID);

    const {entryUIDList: userHistoryUIDList} = useUserHistoryEntryUIDListLoader(accountUID);

    const {knownIpUIDList} = useKnownIPAddressUIDListLoader(accountUID);
    const {knownUserAgentUIDList} = useKnownUserAgentUIDListLoader(accountUID);
    const {emailUIDList} = useSentEmailUIDListLoader(accountUID)
    const {paymentUIDList} = usePriorityACHPaymentUIDListLoader(accountUID);
    const {recurringPaymentUIDList} = usePriorityACHRecurringPaymentUIDListLoader(accountUID);
    const {paymentLogUIDList} = useStripePaymentLogUIDListLoader(accountUID);
    const {loanUIDList} = useLoanUIDListLoader(accountUID);
    const {changeLogUIDList} = useBalanceChangeLogUIDListLoader(accountUID);
    const {sezzleOrderUIDList} = useSezzleOrderUIDListLoader(accountUID);
    const {sourceUIDList} = useUserAccountBalanceSourceUIDListLoader(accountUID);
    const {transferUIDList} = useBalanceSourceTransferUIDListLoader(accountUID);
    const {lockUIDList} = useFundsLockUIDListLoader(accountUID);
    const {lockUIDList: releasedLockUIDList} = useReleasedFundsLockUIDListLoader(accountUID);

    const tabIndex = qs.parse(props.location.search, {ignoreQueryPrefix: true}).tabIndex || TAB_INDEX_DEPOSITS;
    const [value, setValue] = useState(tabIndex);

    const depositsLabel = `Deposits (${depositUIDList.length})`;
    const withdrawalsLabel = `Withdrawals (${withdrawUIDList.length})`;
    const usDomBankAccountsLabel = `U.S. Banks (${usDomUIDList.length})`;
    const intBankAccountsLabel = `Int. Banks (${intUIDList.length})`;
    const userHistoryLabel = `History (${userHistoryUIDList.length})`;
    const knownIPLabel = `IPs (${knownIpUIDList.length})`;
    const knownUserAgentLabel = `User Agents (${knownUserAgentUIDList.length})`;
    const sentEmailLabel = `Email (${emailUIDList.length})`;
    const achLabel = `ACH (${paymentUIDList.length})`;
    const achRecurringLabel = `ACH-Rec. (${recurringPaymentUIDList.length})`;
    const stripeLabel = `Stripe (${paymentLogUIDList.length})`;
    const loanLabel = `Loans (${loanUIDList.length})`;
    const balanceChangeLogLabel = `Balance Log (${changeLogUIDList.length})`;
    const sezzleLabel = `Sezzle (${sezzleOrderUIDList.length})`;
    const balanceSourceLabel = `Balance Source (${sourceUIDList.length})`;
    const balanceTransfersLabel = `Balance Transfers (${transferUIDList.length})`;
    const lockLabel = `Funds Locks (${lockUIDList.length})`;
    const releasedLockLabel = `Released Funds Locks (${releasedLockUIDList.length})`;

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const baseURL = APP_ROUTE_DETAILED_USER_ACCOUNT.replace(":account_uid", userAccount.account_uid);
        history.push(`${baseURL}?tabIndex=${newValue}`);
    };

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}
            className={classes.root}
        >
            <Grid item>
                <BackLink/>&nbsp;|&nbsp;<HomeLink/>
            </Grid>

            <Grid item>
                <UserAccountInfoSummary accountUID={accountUID}/>
            </Grid>


            <Grid item>

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    wrap={'nowrap'}
                >
                    <Grid item xs={2}>
                        <Paper square className={classes.paper}>

                            <Tabs
                                orientation="vertical"
                                value={value}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChange}
                                aria-label="disabled tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label={depositsLabel} value={TAB_INDEX_DEPOSITS}/>
                                <Tab label={withdrawalsLabel} value={TAB_INDEX_USER_WITHDRAWALS}/>
                                <Tab label={usDomBankAccountsLabel} value={TAB_INDEX_US_DOM_BANK_ACCOUNTS}/>
                                <Tab label={intBankAccountsLabel} value={TAB_INDEX_INT_BANK_ACCOUNTS}/>
                                <Tab label={loanLabel} value={TAB_INDEX_LOANS}/>
                                <Tab label={userHistoryLabel} value={TAB_INDEX_USER_HISTORY}/>
                                <Tab label={achLabel} value={TAB_INDEX_ACH_PAYMENTS}/>
                                <Tab label={achRecurringLabel} value={TAB_INDEX_ACH_RECURRING_PAYMENTS}/>
                                <Tab label={stripeLabel} value={TAB_INDEX_STRIPE_PAYMENTS}/>
                                <Tab label={sentEmailLabel} value={TAB_INDEX_SENT_EMAIL}/>
                                <Tab label={knownIPLabel} value={TAB_INDEX_IP_ADDRESSES}/>
                                <Tab label={knownUserAgentLabel} value={TAB_INDEX_USER_AGENTS}/>
                                <Tab label={balanceChangeLogLabel} value={TAB_INDEX_BALANCE_CHANGE_LOG}/>
                                <Tab label={balanceSourceLabel} value={TAB_INDEX_BALANCE_SOURCES}/>
                                <Tab label={balanceTransfersLabel} value={TAB_INDEX_BALANCE_SOURCE_TRANSFERS}/>
                                <Tab label={lockLabel} value={TAB_INDEX_FUNDS_LOCKS}/>
                                <Tab label={releasedLockLabel} value={TAB_INDEX_FUNDS_LOCKS_RELEASED}/>
                                <Tab label={sezzleLabel} value={TAB_INDEX_SEZZLE}/>
                                <Tab label={"Admin"} value={TAB_INDEX_ADMIN}/>

                            </Tabs>


                        </Paper>
                    </Grid>

                    <Grid item xs={10}>
                        <div className={classes.tabWrapper}>
                            <TabContext value={value}>
                                <TabPanel value={TAB_INDEX_DEPOSITS} className={classes.tabPanelRoot}>
                                    <DepositsTable depositUIDList={depositUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_USER_WITHDRAWALS} className={classes.tabPanelRoot}>
                                    <UserWithdrawsTable withdrawUIDList={withdrawUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_US_DOM_BANK_ACCOUNTS} className={classes.tabPanelRoot}>
                                    <USDomesticBankAccountsTable bankAccountUIDList={usDomUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_INT_BANK_ACCOUNTS} className={classes.tabPanelRoot}>
                                    <InternationalBankAccountsTable bankAccountUIDList={intUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_USER_HISTORY} className={classes.tabPanelRoot}>
                                    <UserHistoryEntriesTable entryUIDList={userHistoryUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_IP_ADDRESSES} className={classes.tabPanelRoot}>
                                    <KnownIpAddressesTable knownIpUIDList={knownIpUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_USER_AGENTS} className={classes.tabPanelRoot}>
                                    <KnownUserAgentsTable knownUserAgentUIDList={knownUserAgentUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_SENT_EMAIL} className={classes.tabPanelRoot}>
                                    <SentEmailsTable emailUIDList={emailUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_ACH_PAYMENTS} className={classes.tabPanelRoot}>
                                    <PriorityAchPaymentsTable paymentUIDList={paymentUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_ACH_RECURRING_PAYMENTS} className={classes.tabPanelRoot}>
                                    <PriorityAchRecurringPaymentsTable
                                        recurringPaymentUIDList={recurringPaymentUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_STRIPE_PAYMENTS} className={classes.tabPanelRoot}>
                                    <StripePaymentLogsTable paymentLogUIDList={paymentLogUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_LOANS} className={classes.tabPanelRoot}>
                                    <LoansTable loanUIDList={loanUIDList}/>
                                </TabPanel>

                                <TabPanel value={TAB_INDEX_BALANCE_CHANGE_LOG} className={classes.tabPanelRoot}>
                                    <BalanceChangeLogsTable changeLogUIDList={changeLogUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_BALANCE_SOURCES} className={classes.tabPanelRoot}>
                                    <UserAccountBalanceSourcesTable sourceUIDList={sourceUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_BALANCE_SOURCE_TRANSFERS} className={classes.tabPanelRoot}>
                                    <BalanceSourceTransfersTable transferUIDList={transferUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_FUNDS_LOCKS} className={classes.tabPanelRoot}>
                                    <FundsLocksTable lockUIDList={lockUIDList}/>
                                </TabPanel>
                                <TabPanel value={TAB_INDEX_FUNDS_LOCKS_RELEASED} className={classes.tabPanelRoot}>
                                    <ReleasedFundsLocksTable lockUIDList={releasedLockUIDList}/>
                                </TabPanel>

                                <TabPanel value={TAB_INDEX_SEZZLE} className={classes.tabPanelRoot}>
                                    <SezzleOrdersTable sezzleOrderUIDList={sezzleOrderUIDList}/>
                                </TabPanel>

                                <TabPanel value={TAB_INDEX_ADMIN} className={classes.tabPanelRoot}>
                                    <UserAccountAdmin userAccount={userAccount}/>
                                </TabPanel>

                            </TabContext>
                        </div>
                    </Grid>

                </Grid>


            </Grid>

        </Grid>
    );


}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionLoadAllUIDS: adminActionLoadAllUIDS
    }, dispatch);
};

export default connect(null, mapActionsToProps)(DetailedUserAccountInfoPageTwo);

