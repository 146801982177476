/*

 */

import React from 'react';

import VisualizeContainerLoadingIndicator from "../../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import AdminDisplayCard from "../adminDisplayCard";
import {ADMIN_ICON_DEPOSITS} from "../../adminIcons";
import {dollarFormattedString} from "../../../../utilities/commonUtils";
import AdminViewFullAccountInfoLink from "../../buttons/linkButtons/adminViewFullAccountInfoLink";
import CreatedUpdatedTimestampDisplay from "../../../utilities/displayAreas/CreatedUpdatedTimestampDisplay";
import QuickTimestampDisplay from "../../../utilities/displayAreas/QuickTimestampDisplay";
import AdminViewFullScreenPropertyLink from "../../buttons/linkButtons/adminViewFullScreenPropertyLink";
import DepositAcceptedIndicator from "../depositAcceptedIndicator";
import {usStateAbbreviationToFull} from "../../../../constants/usStatesConstants";
import AdminDepositStateRender from "../adminDepositStateRender";
import LandlordAndTenantDisplayWrapper from "./LandlordAndTenantDisplayWrapper";

export default function DisplayDepositModel({loading, error, deposit, ...props}) {

    if (loading === true || error === true || deposit === undefined) {
        return (
            <VisualizeContainerLoadingIndicator
                loadingResource={deposit}
                displayName={'Deposit'}
                {...props} />
        );
    }

    return (
        <AdminDisplayCard
            cardTitle={'Deposit'}
            cardSubheader={deposit.title}
            headerIcon={ADMIN_ICON_DEPOSITS}
            jsonObject={deposit}
        >
            <h2>{deposit.title}</h2>
            <h3>{dollarFormattedString(deposit.amount_in_cents)}</h3>
            <h4><AdminDepositStateRender deposit={deposit}/></h4>
            <b>Created by:</b> <AdminViewFullAccountInfoLink accountUID={deposit.created_by_account_uid}/>
            <br/>
            <CreatedUpdatedTimestampDisplay createdTimestamp={deposit.created_timestamp}
                                            lastUpdatedTimestamp={deposit.last_updated_timestamp}/>
            <br/><br/>

            <b>US State</b>: {usStateAbbreviationToFull(deposit.geo_state)}
            <br/>
            <b>Interest</b>: {dollarFormattedString(deposit.interest_to_date_in_cents)}
            <br/>
            <b>Start date</b>: <QuickTimestampDisplay timestamp={deposit.start_date}/>
            <br/>
            <b>End date</b>: <QuickTimestampDisplay timestamp={deposit.end_date}/>
            <br/>
            <b>Property</b>: <AdminViewFullScreenPropertyLink propertyUID={deposit.property_uid}/>
            <br/><br/>

            <LandlordAndTenantDisplayWrapper
                landlordChildren={
                    <React.Fragment>
                        <AdminViewFullAccountInfoLink accountUID={deposit.landlord_account_uid}/>
                        <br/>
                        <b>Accepted</b>: <DepositAcceptedIndicator deposit={deposit} useLandlord={true}/>
                        <br/>
                        <b>Archived</b>: {deposit.landlord_archived.toString()}
                    </React.Fragment>
                }
                tenantChildren={
                    <React.Fragment>
                        <AdminViewFullAccountInfoLink accountUID={deposit.tenant_account_uid}/>
                        <br/>
                        <b>Accepted</b>: <DepositAcceptedIndicator deposit={deposit} useLandlord={false}/>
                        <br/>
                        <b>Archived</b>: {deposit.tenant_archived.toString()}
                    </React.Fragment>
                }
            />


        </AdminDisplayCard>

    );

}