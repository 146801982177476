import {
    USER_ACCOUNT_ROLES_ADMIN,
    USER_ACCOUNT_ROLES_BROKER,
    USER_ACCOUNT_ROLES_LANDLORD,
    USER_ACCOUNT_ROLES_MEDIATOR,
    USER_ACCOUNT_ROLES_SUPER_ADMIN,
    USER_ACCOUNT_ROLES_TENANT
} from "../../../autoGenerated/constants/services/UserAccountsServiceConstants";

export const GOOD_COLOUR = '#50D344';
export const BAD_COLOUR = '#D32F2F';
export const WARNING_COLOUR = '#d3912f';

// User Account Role colours
export const LANDLORD_COLOUR = '#C2D9FB';
export const TENANT_COLOUR = '#E9E9E9';
export const BROKER_COLOUR = '#C4FBDE';
export const ADMIN_COLOUR = '#FBB10A';
export const SUPER_ADMIN_COLOUR = '#b006fb';

const USER_ACCOUNT_ROLE_COLOUR_MAPPING = {
    [USER_ACCOUNT_ROLES_LANDLORD]: LANDLORD_COLOUR,
    [USER_ACCOUNT_ROLES_TENANT]: TENANT_COLOUR,
    [USER_ACCOUNT_ROLES_BROKER]: BROKER_COLOUR,
    [USER_ACCOUNT_ROLES_MEDIATOR]: ADMIN_COLOUR,
    [USER_ACCOUNT_ROLES_ADMIN]: ADMIN_COLOUR,
    [USER_ACCOUNT_ROLES_SUPER_ADMIN]: SUPER_ADMIN_COLOUR
};

export function getUserAccountRoleColour(userAccountRole) {
    return USER_ACCOUNT_ROLE_COLOUR_MAPPING[userAccountRole];
}