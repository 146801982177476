/*

 */

import React from 'react';
import Link from "@material-ui/core/Link";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    adminActionSetFullScreenFileInfo
} from "../../../../actions/adminActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile} from "@fortawesome/pro-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";

function FullScreenFileLink({fileUID, useIconButton = false, ...props}) {

    return (
        <React.Fragment>
            {
                useIconButton === false &&
                <Link
                    component="button"
                    variant="body2"
                    onClick={handleOpen}
                >
                    View File
                </Link>
            }
            {
                useIconButton === true &&
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={handleOpen}
                >
                    <FontAwesomeIcon
                        icon={faFile}
                        fixedWidth
                    />
                </IconButton>
            }
        </React.Fragment>
    );

    function handleOpen() {
        props.adminActionSetFullScreenFileInfo({
            showDialog: true,
            fileUID: fileUID
        });
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionSetFullScreenFileInfo: adminActionSetFullScreenFileInfo
    }, dispatch);
};

export default connect(null, mapActionsToProps)(FullScreenFileLink);
