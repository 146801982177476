export const CDN_BASE_URL = 'https://deposify-public-cdn.s3.us-east-2.amazonaws.com';

export const BUBBLE_BACKGROUND_URL = `${CDN_BASE_URL}/site/backgrounds/bubble_background.svg`;

// export const LOADING_SPINNER_URL = `${CDN_BASE_URL}/site/branding/site_loading_spinner_icon.png`;
export const LOADING_SPINNER_URL = `${CDN_BASE_URL}/site/branding/spinner_safe_gradient.svg`;

export const HEADER_TEXT_LOGO_URL = `${CDN_BASE_URL}/site/branding/deposify_text_logo.svg`;

// export const PROFILE_DEFAULT_PIC_URL = `${CDN_BASE_URL}/site/profiles/profile_default_pic.png`;
export const PROFILE_DEFAULT_PIC_URL = `${CDN_BASE_URL}/site/profiles/user-solid.svg`;
// export const PROFILE_DEFAULT_PIC_THUMB_URL = `${CDN_BASE_URL}/site/profiles/profile_default_pic_thumb.png`;


export const ILLUSTRATION_MY_CURRENT_LOCATION_SVG_URL = `${CDN_BASE_URL}/site/images/illustrations/undraw_my_current_location_om7g.svg`;
export const SAFE_LOGO_URL = `${CDN_BASE_URL}/site/branding/safe_logo.svg`;
// export const ILLUSTRATION_CONFIRMED_SVG_URL = `${CDN_BASE_URL}/site/images/illustrations/undraw_confirmed_81ex.svg`;
export const SAFE_CONFIRMED_SVG_URL = `${CDN_BASE_URL}/site/images/confirmations/safe_logo_check.svg`;

// Partners
export const ASSURANT_PLUS_DEPOSIFY_LOGO_URL = `${CDN_BASE_URL}/site/partners/assurant/dep_plus_assurant_logo_large.png`;
export const RENTERS_WAREHOUSE_LOGO_URL = `${CDN_BASE_URL}/site/partners/renterswarehouse/renters_warehouse_logo.png`;

// Social networks
export const SOCIAL_FACEBOOK_LOGO_URL = `${CDN_BASE_URL}/site/social/facebook.png`;
export const SOCIAL_LINKEDIN_LOGO_URL = `${CDN_BASE_URL}/site/social/linkedin.png`;
export const SOCIAL_TWITTER_LOGO_URL = `${CDN_BASE_URL}/site/social/twitter.png`;

export const PAGE_GROUP_CARD_IMAGE_URL = `${CDN_BASE_URL}/site/elements/cards/page_group_card_image.png`;

export const DARK_HEADER_LOGO_MEDIUM = `${CDN_BASE_URL}/site/branding/deposify_text_logo_dark.svg`;