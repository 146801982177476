import React from 'react';
import Grid from "@material-ui/core/Grid";
import * as Yup from "yup";
import {YUP_FIELD_DEPOSIT_AMOUNT} from "../../../utilities/yupCommonValidationSchemas";
import {axiosInstance} from "../../../utilities/axiosInstance";
import * as Sentry from "@sentry/browser";
import {dollarsToCents} from "../../../utilities/commonUtils";
import toast from "react-hot-toast";
import {useFormik} from "formik";
import {StandardTextField} from "../../../components/utilities/forms/CommonFormFields";
import SpinnerButton from "../../../components/utilities/buttons/SpinnerButton";
import {DOLLAR_ICON} from "../../../constants/commonIcons";
import {getAdminPath} from "../../../api/adminAPI";

const JEFF_CREDIT_URL = 'jeff/credit'
const JEFF_DEBIT_URL = 'jeff/debit'

const JeffCreditDebitForm = ({creditMode, doneCallback}) => {
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [serverError, setServerError] = React.useState(false);

    const formSchema = Yup.object().shape({
        account_uid: Yup.string().required(),
        deposit_uid: Yup.string().required(),
        amount: YUP_FIELD_DEPOSIT_AMOUNT
    });

    const formik = useFormik({
        initialValues: {
            account_uid: '',
            deposit_uid: "",
            amount: 0,
        },
        onSubmit: handleSubmit,
        isInitialValid: false,
        validationSchema: formSchema
    });

    function handleSubmit(values) {
        setShowSpinner(true);

        let updatedValues = {...values};
        updatedValues.amount_in_cents = dollarsToCents(updatedValues.amount);
        delete updatedValues.amount;

        const callURL = getAdminPath(creditMode ? JEFF_CREDIT_URL : JEFF_DEBIT_URL);
        axiosInstance.post(callURL, updatedValues)
            .then(function (response) {
                setShowSpinner(false);
                toast.success('Done');
                formik.resetForm();
                doneCallback();
            })
            .catch(function (error) {
                setShowSpinner(false);

                setServerError(true);
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            })
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="stretch"
                spacing={1}
            >

                <Grid item xs={12}>
                    {StandardTextField(formik, 'account_uid', 'Account UID')}
                </Grid>

                <Grid item xs={12}>
                    {StandardTextField(formik, 'deposit_uid', 'Deposit UID')}
                </Grid>

                <Grid item xs={12}>
                    {StandardTextField(formik, 'amount', 'Amount', false, DOLLAR_ICON)}
                </Grid>

                <Grid item xs={12}>
                    &nbsp;
                </Grid>

                <Grid item zeroMinWidth xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="nowrap"
                    >
                        <Grid item zeroMinWidth>
                            <SpinnerButton
                                // Non-button props
                                showSpinner={showSpinner}
                                // Button props
                                variant="contained"
                                color="primary"
                                disabled={!formik.isValid || showSpinner}
                                type={'submit'}
                            >
                                {creditMode ? "Credit Account" : "Debit Account"}
                            </SpinnerButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>

    );
};


export default JeffCreditDebitForm;