import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen} from "@fortawesome/pro-regular-svg-icons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/browser";
import Typography from "@material-ui/core/Typography";
import {history} from "../../history/history";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import {
    DEPOSIT_STATE_CANCELLED,
    DEPOSIT_STATE_CLOSED
} from "../../autoGenerated/constants/services/DepositsServiceConstants";

const VALID_STATES = [DEPOSIT_STATE_CLOSED, DEPOSIT_STATE_CANCELLED];

const AdminReopenDepositButton = ({deposit}) => {
    const [showDialog, setShowDialog] = React.useState(false);
    const [newCentsAmount, setNewCentsAmount] = useState(0);
    const [spinning, setSpinning] = useState(false);
    const [canReopen, setCanReopen] = useState(false);
    const hasValidDepositState = VALID_STATES.includes(deposit.state);
    const disabled = !canReopen || spinning || !hasValidDepositState;

    useEffect(() => {
        if (hasValidDepositState) {
            setSpinning(true);

            const callURL = getAdminPath(`jeff/deposits/${deposit.deposit_uid}/reopen`);
            axiosInstance.get(callURL)
                .then(function (response) {
                    setCanReopen(true);
                    setSpinning(false);
                })
                .catch(function (error) {
                    if(error.response.status !== 400){
                        Sentry.withScope(scope => {
                            scope.setExtra("response", error.response);
                            Sentry.captureException(error);
                        });

                        toast.error('Failed');
                    }
                    setSpinning(false);
                    setCanReopen(false);
                })
        }
    }, [])

    return (
        <div>
            <Button
                color="secondary"
                onClick={handleOpen}
                variant={'outlined'}
                // size={'small'}
                disabled={disabled}
            >
                <FontAwesomeIcon
                    icon={faFolderOpen}
                    fixedWidth
                />
                &nbsp;Reopen Deposit
            </Button>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="jeff-edit-deposit-title"
                aria-describedby="jeff-edit-deposit-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="account-dialog-title">Reopen Deposit</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">
                        Are you sure?
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        This will reopen the deposit, and accept it as the resident
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus disabled={spinning}>
                        Close
                    </Button>

                    <SpinnerButton
                        // Non-button props
                        showSpinner={spinning}
                        // Button props
                        variant="outlined"
                        color="secondary"
                        disabled={spinning}
                        onClick={handleUpdate}
                    >
                        Yes, Reopen The Deposit
                    </SpinnerButton>
                </DialogActions>
            </Dialog>

        </div>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
        setSpinning(false);
    }

    function handleUpdate() {
        setSpinning(true);

        const callURL = getAdminPath(`jeff/deposits/${deposit.deposit_uid}/reopen`);
        axiosInstance.post(callURL, {})
            .then(function (response) {
                handleClose();
                toast.success('Deposit reopened, redirecting...');
                history.push(`/deposits/${response.data.deposit_uid}/detailed`);

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
                setSpinning(false);
            })
    }
};

export default AdminReopenDepositButton;