/*

 */

import React from 'react';
import {getDateDeltaString, getServerDateTimeAsMoment, jsDateTimeFromString} from "../../../utilities/commonUtils";
import Moment from "react-moment";

export default function QuickTimestampDisplay({timestamp, showTime = false, showTimeSince = false, ...props}) {

    if (timestamp === undefined) {
        return <i>never</i>;
    }

    // if it's already a Date object
    if (timestamp instanceof Date) {
        return (
            <React.Fragment>
                <Moment
                    format={showTime === false ? 'DD MMM YYYY' : 'DD MMM YYYY HH:MM:SS'}
                    date={timestamp}
                />
                {
                    showTimeSince === true &&
                    <RenderTimeSince timestamp={timestamp}/>
                }
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Moment
                format={showTime === false ? 'DD MMM YYYY' : 'DD MMM YYYY HH:MM:SS'}
                date={jsDateTimeFromString(timestamp, true)}
            />
            {
                showTimeSince === true &&
                <RenderTimeSince timestamp={timestamp}/>
            }
        </React.Fragment>
    );

    function RenderTimeSince({timestamp}) {
        return (
            <React.Fragment>
                <br/>
                (<i>{getDateDeltaString(getServerDateTimeAsMoment(timestamp))}&nbsp;ago</i>)
            </React.Fragment>
        );
    }

}

