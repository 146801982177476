/*

 */

export const ALL_LANDLORD_ACCOUNTS_LABEL = 'All Accounts';
export const ALL_LANDLORD_ACCOUNTS_UID = 'ALL_LANDLORD_ACCOUNTS';

export const LANDLORD_ACCOUNT_UID_BEDLY = '285bd0ee-2b98-11e8-9c72-0242ac12000a';
export const LANDLORD_ACCOUNT_UID_FENWAY_CONDO = '37d143bc-0232-11e9-a573-0242ac120005';
export const LANDLORD_ACCOUNT_UID_SPLITSPOT = '6798945a-9b67-11e9-be2d-0242ac120005';
export const LANDLORD_ACCOUNT_UID_VENTURE = '7582eade-6a9d-11e9-9735-0242ac120005';
export const LANDLORD_ACCOUNT_UID_KNOX = '865047d0-66a6-11e9-9735-0242ac120005';
export const LANDLORD_ACCOUNT_UID_JUSTIN_RE = '941fcdd4-de30-11e9-be2d-0242ac120005';
export const LANDLORD_ACCOUNT_UID_EDGE_REALTY = 'b68b5ecc-5f6c-11e8-ab23-0242ac12000d';
export const LANDLORD_ACCOUNT_UID_RENTERS_WAREHOUSE = 'fe1c2214-32a5-4489-8813-00cae69ea0e3';
export const LANDLORD_ACCOUNT_UID_FRANKLIN_COMMUNITIES = '1befd50d-667e-45e3-9181-9264a43d25a8';
export const LANDLORD_ACCOUNT_UID_RAMSTON_CAPITAL = '91295437-f12c-44f2-a17a-57e313ca9ba7';
export const LANDLORD_ACCOUNT_UID_VIE_MANAGEMENT = '366c9793-2b79-46e1-bfd3-a0ac3cc11848';
export const LANDLORD_ACCOUNT_UID_ARDMORE = '6ad84b4b-534b-4bff-805f-da885ef71b96';

export const PMC_ACCOUNTS = {
    [ALL_LANDLORD_ACCOUNTS_LABEL]: ALL_LANDLORD_ACCOUNTS_UID,
    'Bedly': LANDLORD_ACCOUNT_UID_BEDLY,
    'Fenway Condo': LANDLORD_ACCOUNT_UID_FENWAY_CONDO,
    'Splitspot': LANDLORD_ACCOUNT_UID_SPLITSPOT,
    'Venture': LANDLORD_ACCOUNT_UID_VENTURE,
    'Knox': LANDLORD_ACCOUNT_UID_KNOX,
    'Justin RE': LANDLORD_ACCOUNT_UID_JUSTIN_RE,
    'Edge Realty': LANDLORD_ACCOUNT_UID_EDGE_REALTY,
    'Renters Warehouse': LANDLORD_ACCOUNT_UID_RENTERS_WAREHOUSE,
    'Franklin Communities': LANDLORD_ACCOUNT_UID_FRANKLIN_COMMUNITIES,
    'Ramston Capital': LANDLORD_ACCOUNT_UID_RAMSTON_CAPITAL,
    'Vie Management': LANDLORD_ACCOUNT_UID_VIE_MANAGEMENT,
    'Ardmore': LANDLORD_ACCOUNT_UID_ARDMORE
};