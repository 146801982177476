// Name: Security Table Render Definitions 

export const IP_ADDRESS_TABLE_RENDER_DEF = {
	ip_uid: {displayName: "Ip UID"},
	ip_address: {displayName: "Ip Address"},
	city: {displayName: "City"},
	country: {displayName: "Country"},
	region: {displayName: "Region"},
	first_seen_timestamp: {displayName: "First Seen Timestamp"},
	last_seen_timestamp: {displayName: "Last Seen Timestamp"},
	// ip_hash: {displayName: "Ip Hash"},
	// lookup_complete: {displayName: "Lookup Complete"},
	// raw_ip_lookup_data: {displayName: "Raw Ip Lookup Data"},
	// flagged_for_review: {displayName: "Flagged For Review"},
	// last_lookup_timestamp: {displayName: "Last Lookup Timestamp"}
};

export const KNOWN_IP_ADDRESS_TABLE_RENDER_DEF = {
	known_ip_uid: {displayName: "Known Ip UID"},
	account_uid: {displayName: "Account UID"},
	last_use_timestamp: {displayName: "Last Use Timestamp"},
	ip_address_uid: {displayName: "Ip Address UID"},
	first_use_timestamp: {displayName: "First Use Timestamp"}
};

export const KNOWN_USER_AGENT_TABLE_RENDER_DEF = {
	known_user_agent_uid: {displayName: "Known User Agent UID"},
	user_agent_uid: {displayName: "User Agent UID"},
	account_uid: {displayName: "Account UID"},
	first_use_timestamp: {displayName: "First Use Timestamp"},
	last_use_timestamp: {displayName: "Last Use Timestamp"}
};

export const USER_AGENT_TABLE_RENDER_DEF = {
	user_agent_uid: {displayName: "User Agent UID"},
	os_family: {displayName: "Os Family"},
	os_version: {displayName: "Os Version"},
	browser_family: {displayName: "Browser Family"},
	browser_version: {displayName: "Browser Version"},
	device_family: {displayName: "Device Family"},
	device_model: {displayName: "Device Model"},
	device_brand: {displayName: "Device Brand"},
	last_seen_timestamp: {displayName: "Last Seen Timestamp"},
	// raw_user_agent_string: {displayName: "Raw User Agent String"},
	// user_agent_hash: {displayName: "User Agent Hash"},
	// last_lookup_timestamp: {displayName: "Last Lookup Timestamp"},
	// first_seen_timestamp: {displayName: "First Seen Timestamp"}
};

