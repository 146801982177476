/*
    Data Constants
 */

//  BF4844
export const DANGER_BUTTON_COLOR = '#e97662';

export const BILLION_BRIGHT_BLUE = '#06ADFC';

// Deposit State Colours
/*
export const PENDING_COLOR = '#8CE794';
export const ACTIVE_COLOR = '#79CDA0';
export const CLOSE_REQUESTED_COLOR = '#ff884d';
export const CLOSED_COLOR = '#8BBED3';
export const DISPUTED_COLOR = DANGER_BUTTON_COLOR;
export const CANCELLED_COLOR = '#a2adb8';
export const DISPUTE_MEDIATOR_INVOLVED_COLOR = DISPUTED_COLOR;
 */
export const PENDING_COLOR = '#3dbc3d';
export const ACTIVE_COLOR = '#00b700';
export const CLOSE_REQUESTED_COLOR = '#ff884d';
export const CLOSED_COLOR = '#8BBED3';
export const DISPUTED_COLOR = '#ff334c';
export const CANCELLED_COLOR = '#a2adb8';
export const DISPUTE_MEDIATOR_INVOLVED_COLOR = DISPUTED_COLOR;

export const PENDING_COLOR_MUTED = '#aeeeb4';
export const ACTIVE_COLOR_MUTED = '#a1dcbc';
export const CLOSE_REQUESTED_COLOR_MUTED = '#ffc3a6';
export const CLOSED_COLOR_MUTED = '#aed1e0';
export const DISPUTED_COLOR_MUTED = '#f09f91';
export const CANCELLED_COLOR_MUTED = '#bec5cd';
export const DISPUTE_MEDIATOR_INVOLVED_COLOR_MUTED = DISPUTED_COLOR_MUTED;


export const DEPOSIT_INDICATOR_ICON_COLOR = '#C5C5C5';
export const INLINE_BUTTON_ICON_COLOR = '#6f6f6f';


// Format for Moment when converting the the timestamp strings: 2019-07-16 19:11:54:472315
// NOTE: Moment only uses 3 MS digits, if more than 3 are provided it will error out - so use the utils function
export const DATA_FORMAT_TIME_STAMPS_MOMENT = 'YYYY-MM-DD HH:mm:ss:SSS';

export const DATA_FORMAT_TIME_STAMP_URL_FORMAT = 'YYYY-MM-DD HH:mm:ss';


// Used by the server for date time objects default value
export const DEFAULT_DATE_TIME_STRING = '1970-01-01 00:00:00:000000';
export const DEFAULT_DATE_TIME_URL_STRING = '1970-01-01 00:00:00';
export const DEFAULT_PHONE_NUMBER = '+3530000000';
export const DEFAULT_EMAIL_ADDRESS = 'null@deposify.com';
export const NULL_UUID_STRING = '00000000-0000-0000-0000-000000000000';
export const NULL_UUID_STRING_HEX = '00000000000000000000000000000000';

export const MAX_UPLOAD_FILE_SIZE_IN_BYTES = 1e+7; // 10mb

/*
    NOTE: THESE VALUES MUST MATCH UP WITH THE SERVER
 */
// User Accounts
export const MIN_FIRST_NAME_LENGTH = 2;
export const MAX_FIRST_NAME_LENGTH = 32;
export const MIN_LAST_NAME_LENGTH = 2;
export const MAX_LAST_NAME_LENGTH = 32;
export const MIN_USERNAME_LENGTH = 5;
export const MAX_USERNAME_LENGTH = 32;
export const MIN_PASSWORD_LENGTH = 7;
export const MAX_PASSWORD_LENGTH = 70;

// Deposits
export const MIN_DEPOSIT_TITLE_LENGTH = 3;
export const MAX_DEPOSIT_TITLE_LENGTH = 128;

export const MIN_DEPOSIT_AMOUNT_IN_CENTS = 1;
export const MIN_DEPOSIT_AMOUNT_IN_DOLLARS = 0.01;
export const MAX_DEPOSIT_AMOUNT_IN_DOLLARS = 100000.00;

// Banking
export const MIN_BANK_NAME_LENGTH = 2;
export const MAX_BANK_NAME_LENGTH = 256;
export const MIN_BANK_BRANCH_NAME_LENGTH = 2;
export const MAX_BANK_BRANCH_NAME_LENGTH = 256;
export const MIN_BANK_ACCOUNT_NUMBER_LENGTH = 2;
export const MAX_BANK_ACCOUNT_NUMBER_LENGTH = 90;
export const MIN_ACH_LENGTH = 1;
export const MAX_ACH_LENGTH = 100;
export const MIN_RECIPIENT_NAME_LENGTH = 2;
export const MAX_RECIPIENT_NAME_LENGTH = 50;

export const MIN_BIC_SWIFT_CODE_LENGTH = 1;
export const MAX_BIC_SWIFT_CODE_LENGTH = 100;
export const MIN_RECIPIENT_ADDRESS_STREET_LENGTH = 5;
export const MAX_RECIPIENT_ADDRESS_STREET_LENGTH = 100;
export const MIN_RECIPIENT_ADDRESS_CITY_LENGTH = 2;
export const MAX_RECIPIENT_ADDRESS_CITY_LENGTH = 100;
export const MIN_RECIPIENT_ADDRESS_STATE_LENGTH = 2;
export const MAX_RECIPIENT_ADDRESS_STATE_LENGTH = 100;
export const MIN_RECIPIENT_POSTAL_CODE_LENGTH = 1;
export const MAX_RECIPIENT_POSTAL_CODE_LENGTH = 100;
export const MIN_COUNTRY_LENGTH = 4;
export const MAX_COUNTRY_LENGTH = 100;


export const MIN_PHONE_NUMBER_LENGTH = 5;
export const MAX_PHONE_NUMBER_LENGTH = 100;

export const MAX_CLOSE_DEPOSIT_MESSAGE_LENGTH = 256;

export const MIN_DISPUTE_REASON_MESSAGE_LENGTH = 10;
export const MAX_DISPUTE_REASON_MESSAGE_LENGTH = 512;

export const MIN_MAILBOX_MESSAGE_LENGTH = 3;
export const MAX_MAILBOX_MESSAGE_LENGTH = 512;

export const BANK_ACCOUNT_TYPES = [
    'Checking',
    'Savings'
];

export const DEPOSIT_CREATING_ACCOUNT_TYPES = [
    'Owner',
    'Resident'
];

