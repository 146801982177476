/*
    Dev
 */

import React from "react";
import {TableCell} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {isObject} from "../../../utilities/commonUtils";


const useStyles = makeStyles({
    header: {
        marginLeft: 15
    },
    table: {
        minWidth: 500,
    },
    cellStyle: {
        border: 0,
        // borderBottom: '1px solid #2D2E30'
        borderBottom: '1px solid #4e5054'
    }
});


function GenericObjectAsTable({sourceObject, ...props}) {
    const classes = useStyles();

    return (

        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableBody>
                    {
                        Object.keys(sourceObject).map(objectKey => {
                            return (
                                <TableRow key={`gobject-table-row-${objectKey}`}>
                                    <TableCell className={classes.cellStyle}>
                                        {objectKey}
                                    </TableCell>

                                    <TableCell className={classes.cellStyle}>
                                        {
                                            isObject(sourceObject[objectKey]) === true &&
                                            <GenericObjectAsTable sourceObject={sourceObject[objectKey]}/>
                                        }
                                        {
                                            isObject(sourceObject[objectKey]) === false &&
                                            <React.Fragment>{sourceObject[objectKey]}</React.Fragment>
                                        }
                                    </TableCell>

                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>

    );


}

export default React.memo(GenericObjectAsTable);