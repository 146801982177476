import React from "react";

import Grid from "@material-ui/core/Grid";
import GenericObjectDisplay from "../../clientShared/components/display/GenericObjectDisplay";
import DepositAdminButtonToggleCompliance from "./admin_buttons/DepositAdminButtonToggleCompliance";


export function ComplianceDisplay(props) {

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >

            <Grid item>
                <GenericObjectDisplay {...props}/>
            </Grid>

            <Grid item>
                <DepositAdminButtonToggleCompliance compliance={props.compliance}/>
            </Grid>


        </Grid>
    );
}

