export const SEARCH_INDEX_NAME_ALL = 'all';
export const SEARCH_INDEX_NAME_USER_ACCOUNTS = 'user_accounts';
export const SEARCH_INDEX_NAME_DEPOSITS = 'deposits';
export const SEARCH_INDEX_NAME_DEPOSIT_INVITES = 'deposit_invites';
export const SEARCH_INDEX_NAME_PROPERTIES = 'properties';
export const YARDI_VOYAGER_RESIDENT_SEARCH_INDEX_NAME = 'yardi_voyager_residents'
export const YARDI_VOYAGER_PROPERTY_SEARCH_INDEX_NAME = 'yardi_voyager_properties'
export const YARDI_VOYAGER_PROPERTY_UNIT_SEARCH_INDEX_NAME = 'yardi_voyager_property_units'

export const ALL_SEARCHABLE_INDICES = [
    SEARCH_INDEX_NAME_USER_ACCOUNTS,
    SEARCH_INDEX_NAME_DEPOSITS,
    SEARCH_INDEX_NAME_DEPOSIT_INVITES,
    SEARCH_INDEX_NAME_PROPERTIES,
    YARDI_VOYAGER_RESIDENT_SEARCH_INDEX_NAME,
    YARDI_VOYAGER_PROPERTY_SEARCH_INDEX_NAME,
    YARDI_VOYAGER_PROPERTY_UNIT_SEARCH_INDEX_NAME
]

export const SEARCH_INDEX_DISPLAY_NAMES = {
    [SEARCH_INDEX_NAME_USER_ACCOUNTS]: 'User Accounts',
    [SEARCH_INDEX_NAME_DEPOSITS]: 'Deposits',
    [SEARCH_INDEX_NAME_DEPOSIT_INVITES]: 'Deposit Invites',
    [SEARCH_INDEX_NAME_PROPERTIES]: 'Properties',
    [YARDI_VOYAGER_RESIDENT_SEARCH_INDEX_NAME]: "Yardi Residents",
    [YARDI_VOYAGER_PROPERTY_SEARCH_INDEX_NAME]: "Yardi Properties",
    [YARDI_VOYAGER_PROPERTY_UNIT_SEARCH_INDEX_NAME]: "Yardi Units",
}