/*
    Payment Cleared / Interest controls
 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import {resourceActionUpdateSingleResource} from "../../../clientShared/actions/resourceActions";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";
import {getAdminPath} from "../../../api/adminAPI";
import {axiosInstance} from "../../../utilities/axiosInstance";
import toast from "react-hot-toast";
import {DEPOSIT_STATE_PENDING} from "../../../autoGenerated/constants/services/DepositsServiceConstants";
import {NULL_UUID_STRING} from "../../../constants/dataConstants";


function DepositAdminButtonRefreshPermissions({deposit, ...props}) {

    const [showSpinner, setShowSpinner] = React.useState(false);
    const disabled = deposit.state !== DEPOSIT_STATE_PENDING || deposit.tenant_account_uid === NULL_UUID_STRING;

    return (
        <SpinnerButton
            // Non-button props
            showSpinner={showSpinner}
            // Button props
            variant="outlined"
            color="primary"
            disabled={showSpinner || disabled}
            onClick={handleClick}
        >
            <FontAwesomeIcon
                icon={faSkullCrossbones}
                fixedWidth
            />
            &nbsp;Refresh Permissions
        </SpinnerButton>
    );

    function handleClick() {
        setShowSpinner(true);

        let updatedValues = {};
        const baseURL = `jeff/deposit/${deposit.deposit_uid}/tenant/${deposit.tenant_account_uid}/perm/refresh`;

        const callURL = getAdminPath(baseURL);
        axiosInstance.post(callURL, updatedValues)
            .then(function (response) {
                setShowSpinner(false);
                toast.success('Done');
            })
            .catch(function (error) {
                setShowSpinner(false);

                // setServerError(true);
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            })
    }


}

const mapStateToProps = (state) => {
    // const _adminMakeSystemStatsSelector = adminMakeSystemStatsSelector();

    return function realMapState(state, props) {
        return {
            // systemStats: _adminMakeSystemStatsSelector(state, props.statsID)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(DepositAdminButtonRefreshPermissions);
