/*

 */

import React from 'react';
import {makeStyles} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {DIALOG_MIN_WIDTH} from "../../../constants/styleConstants";
import SpinnerButton from "../buttons/SpinnerButton";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    closeButton: {
        marginRight: 'auto'
    },
    dialogContent: {
        minWidth: DIALOG_MIN_WIDTH
    },
    dangerButton: {
        color: '#FFFFFF'
    },
    standardButton: {

    }
}));

function ConfirmationDialog(props) {
    const classes = useStyles();

    const handleClose = () => {
        if (props.cancelCallback !== undefined) {
            props.cancelCallback();
        }
    };

    function handleConfirm() {
        if (props.confirmCallback !== undefined) {
            props.confirmCallback();
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.startOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {/*<DialogContentText id="alert-dialog-description">*/}
                    {/*    {props.dialogText}*/}
                    {/*</DialogContentText>*/}
                    {props.dialogText}
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className={classes.closeButton}
                        disabled={props.showSpinner}
                        autoFocus={props.cancelFocus === true}
                    >
                        {props.cancelText}
                    </Button>

                    <SpinnerButton
                        // Non-button props
                        showSpinner={props.showSpinner}
                        useDangerColor={props.dangerMode}

                        // Button props
                        onClick={handleConfirm}
                        color={props.dangerMode === true ? 'secondary' : 'primary'}
                        autoFocus={props.cancelFocus !== true}
                        variant={'contained'}
                        className={props.dangerMode === true ? classes.dangerButton : classes.standardButton}
                        disabled={props.showSpinner}
                    >
                        {props.confirmText}
                    </SpinnerButton>

                </DialogActions>

            </Dialog>
        </React.Fragment>
    );

}

ConfirmationDialog.defaultProps = {
    startOpen: true,
    dangerMode: false,
    confirmCallback: undefined,
    cancelCallback: undefined,
    dialogTitle: 'Please Confirm',
    dialogText: '',
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    showSpinner: false
};

export default ConfirmationDialog;