/*
    Display the state for a user account
 */

import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {BAD_COLOUR, GOOD_COLOUR} from "../../../../constants/style/colours";

export default function UserAccountEmailVerifiedDisplay({userAccount, showEmail = true, ...props}) {

    const displayName = userAccount.email_verified === true ? 'Verified' : 'Not Verified';
    const icon = userAccount.email_verified === true ? faCheck : faTimes;
    const iconColour = userAccount.email_verified === true ? GOOD_COLOUR : BAD_COLOUR;

    if(showEmail === false){
        return <React.Fragment>{renderIcon()}</React.Fragment>;
    }

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            wrap={'nowrap'}
        >

            <Grid item>
                {userAccount.email_address}&nbsp;
            </Grid>

            <Grid item>
                {renderIcon()}
            </Grid>

        </Grid>
    );

    function renderIcon(){
        return (
            <Tooltip
                title={displayName}
                placement={'top'}
            >
                    <span>
                        <FontAwesomeIcon
                            icon={icon}
                            fixedWidth
                            color={iconColour}
                        />
                    </span>
            </Tooltip>
        );
    }

}
