/*
    Deposit Actions
 */

import * as Sentry from '@sentry/browser';

// Event types
import {
    API_ROUTE_READ_ALL_DEPOSITS_BY_ACCOUNT,
    API_ROUTE_READ_DEPOSIT_INVITE_BY_DEPOSIT_UID,
    API_ROUTE_READ_DEPOSIT,
    API_ROUTE_READ_DISPUTE,
    API_ROUTE_READ_DISPUTE_SPLIT,
    API_ROUTE_READ_DISPUTE_SPLIT_AWAITING_RESPONSE,
    API_ROUTE_READ_DEPOSIT_HISTORY,
    API_ROUTE_READ_DEPOSIT_META_DATA,
    API_ROUTE_READ_DEPOSIT_USER_STATS,
    API_ROUTE_SET_DISPUTE_SPLIT_ATTACHMENTS,
    API_ROUTE_SET_DISPUTE_ATTACHMENTS
} from "../constants/apiRouteConstants";
import {axiosInstance} from "../utilities/axiosInstance";
import {DEPOSIT_STARTUP_READ_LIMIT} from "../constants/depositConstants";
import {paymentsActionInvoiceReplacementCleanup} from "./paymentsActions";
import { appActionsHandleInvoiceUID} from "./appActions";


export const ADD_NEW_DEPOSIT = 'DEPOSITS:ADD_NEW_DEPOSIT';
export const UPDATE_DEPOSIT = 'DEPOSITS:UPDATE_DEPOSIT';
export const UPDATE_DEPOSITS = 'DEPOSITS:UPDATE_DEPOSITS';
export const UPDATE_DEPOSIT_INVITE = 'DEPOSITS:UPDATE_DEPOSIT_INVITE';
export const UPDATE_DEPOSIT_INVITES = 'DEPOSITS:UPDATE_DEPOSIT_INVITES';
export const UPDATE_DEPOSIT_CLOSE_REQUEST = 'DEPOSITS:READ_CLOSE_REQUEST';
export const UPDATE_DEPOSIT_CLOSE_REQUESTS = 'DEPOSITS:READ_CLOSE_REQUESTS';
export const UPDATE_DEPOSIT_DISPUTE = 'DEPOSITS:UPDATE_DISPUTE';
export const UPDATE_DEPOSIT_DISPUTE_SPLIT = 'DEPOSITS:UPDATE_DISPUTE_SPLIT';
export const UPDATE_DEPOSIT_HISTORY = 'DEPOSITS:UPDATE_DEPOSIT_HISTORY';
export const UPDATE_DEPOSIT_META_DATA = 'DEPOSITS:UPDATE_DEPOSIT_META_DATA';
export const REMOVE_DEPOSIT_FROM_STATE = 'DEPOSITS:REMOVE_DEPOSIT_FROM_STATE';
export const UPDATE_DEPOSIT_USER_STATS = 'DEPOSITS:UPDATE_DEPOSIT_USER_STATS';
export const DEPOSITS_ACTION_SET_START_UP_CHECK = 'DEPOSITS:SET_START_UP_CHECK';


// Events
export function depositActionSetStartUpCheck(startUpDepositsCheckComplete) {
    return {
        type: DEPOSITS_ACTION_SET_START_UP_CHECK,
        startUpDepositsCheckComplete: startUpDepositsCheckComplete
    }
}

export function depositActionUpdateDepositUserStats(depositUserStats) {
    return {
        type: UPDATE_DEPOSIT_USER_STATS,
        depositUserStats: depositUserStats
    }
}


export function depositActionRemoveDepositFromState(depositUID) {
    return {
        type: REMOVE_DEPOSIT_FROM_STATE,
        depositUID: depositUID
    }
}


export function addNewDepositAction(newDeposit) {
    return {
        type: ADD_NEW_DEPOSIT,
        newDeposit: newDeposit
    }
}

export function updateDeposits(deposits) {
    return {
        type: UPDATE_DEPOSITS,
        deposits: deposits
    }
}

export function updateDepositInvite(depositInvite) {
    return {
        type: UPDATE_DEPOSIT_INVITE,
        depositInvite: depositInvite
    }
}

export function updateDepositInvites(depositInvites) {
    return {
        type: UPDATE_DEPOSIT_INVITES,
        depositInvites: depositInvites
    }
}

export function updateDeposit(deposit) {
    return {
        type: UPDATE_DEPOSIT,
        deposit: deposit
    }
}

export function updateDepositCloseRequest(closeRequest) {
    return {
        type: UPDATE_DEPOSIT_CLOSE_REQUEST,
        closeRequest: closeRequest
    }
}

export function updateDepositCloseRequests(closeRequests) {
    return {
        type: UPDATE_DEPOSIT_CLOSE_REQUESTS,
        closeRequests: closeRequests
    }
}

export function updateDepositDispute(ignoredValue, dispute) {
    return {
        type: UPDATE_DEPOSIT_DISPUTE,
        dispute: dispute
    }
}

export function updateDepositDisputeSplit(ignoredValue, disputeSplit) {
    return {
        type: UPDATE_DEPOSIT_DISPUTE_SPLIT,
        disputeSplit: disputeSplit
    }
}

export function updateDepositHistory(depositHistoryEntries) {
    return {
        type: UPDATE_DEPOSIT_HISTORY,
        depositHistoryEntries: depositHistoryEntries
    }
}

export function updateDepositMetaData(depositMetaData) {
    return {
        type: UPDATE_DEPOSIT_META_DATA,
        depositMetaData: depositMetaData
    }
}

export const readDepositInviteByDepositUID = (depositUID) => {
    return dispatch => {
        _readDepositInvite(depositUID, dispatch);
    }
};

function _readDepositInvite(depositUID, dispatch) {
    return axiosInstance.get(`${API_ROUTE_READ_DEPOSIT_INVITE_BY_DEPOSIT_UID}/${depositUID}`)
        .then(function (response) {
            dispatch(updateDepositInvite(null, response.data.deposit_invite));
        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}

export const readDepositInviteByInviteUID = (inviteUID) => {
    return dispatch => {
        return _readDepositInviteByInviteUID(inviteUID, dispatch);
    }
};

function _readDepositInviteByInviteUID(inviteUID, dispatch) {
    return axiosInstance.get(`api/deposits/invites/${inviteUID}`)
        .then(function (response) {
            dispatch(updateDepositInvite(null, response.data.deposit_invite));
        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}

export const readDeposit = (depositUID) => {
    return dispatch => {
        // TODO: Add limit checking here
        const get_route = API_ROUTE_READ_DEPOSIT + '/' + depositUID;
        axiosInstance.get(get_route)
            .then(function (response) {
                dispatch(updateDeposit(null, response.data.deposit));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const readDispute = (disputeUID) => {
    return dispatch => {
        // TODO: Add limit checking here
        const get_route = API_ROUTE_READ_DISPUTE + '/' + disputeUID;
        axiosInstance.get(get_route)
            .then(function (response) {
                dispatch(updateDepositDispute(null, response.data.dispute));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const readDisputeSplit = (splitUID) => {
    return dispatch => {
        // TODO: Add limit checking here
        const get_route = API_ROUTE_READ_DISPUTE_SPLIT + '/' + splitUID;
        axiosInstance.get(get_route)
            .then(function (response) {
                dispatch(updateDepositDisputeSplit(null, response.data.dispute_split));
                //dispatch(readDisputeSplitAwaitingResponse(response.data.dispute_split.dispute_uid));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const readDisputeSplitAwaitingResponse = (disputeUID) => {
    return dispatch => {
        // TODO: Add limit checking here
        const get_route = API_ROUTE_READ_DISPUTE_SPLIT_AWAITING_RESPONSE + '/' + disputeUID;
        axiosInstance.get(get_route)
            .then(function (response) {
                dispatch(updateDepositDisputeSplit(null, response.data.dispute_split));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};


export const readDepositHistory = (depositUID, offset, limit, keepReading = true) => {
    return dispatch => {
        readDepositHistoryFromServer(depositUID, offset, limit, keepReading, dispatch);
    }
};

export const readDepositHistoryFromServer = (depositUID, offset, limit, keepReading, dispatch) => {
    const mostRecentFirst = true;
    const get_route = API_ROUTE_READ_DEPOSIT_HISTORY + '/' + depositUID + '/history/' + offset + '/' + limit + '/' + mostRecentFirst;
    return axiosInstance.get(get_route)
        .then(function (response) {
            dispatch(updateDepositHistory(response.data.history_entries));

            if (keepReading === true) {
                if (response.data.history_entries.length >= limit) {
                    readDepositHistoryFromServer(depositUID, offset + limit, limit, keepReading, dispatch);
                }
            }

        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
};

export const readDepositMetaData = (depositUID) => {
    return dispatch => {
        _readDepositMetaData(depositUID, dispatch);
    }
};

function _readDepositMetaData(depositUID, dispatch) {
    return axiosInstance.get(`${API_ROUTE_READ_DEPOSIT_META_DATA}/${depositUID}`)
        .then(function (response) {
            dispatch(updateDepositMetaData(response.data.deposit_meta_data));
        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}

export const readDepositUserStats = () => {
    return dispatch => {
        axiosInstance.get(API_ROUTE_READ_DEPOSIT_USER_STATS)
            .then(function (response) {
                dispatch(depositActionUpdateDepositUserStats(response.data.deposit_user_stats.stats));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const setDisputeSplitAttachments = (splitUID, attachedFilesUID) => {
    return dispatch => {

        const postValues = {
            'attached_files_uid': attachedFilesUID,
            'split_uid': splitUID
        };

        axiosInstance.post(API_ROUTE_SET_DISPUTE_SPLIT_ATTACHMENTS, postValues)
            .then(function (response) {
                dispatch(readDisputeSplit(splitUID));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const setDisputeAttachments = (disputeUID, attachedFilesUID) => {
    return dispatch => {

        const postValues = {
            'attached_files_uid': attachedFilesUID,
            'dispute_uid': disputeUID
        };

        axiosInstance.post(API_ROUTE_SET_DISPUTE_ATTACHMENTS, postValues)
            .then(function (response) {
                dispatch(readDispute(disputeUID));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const readRecentlyUpdatedDeposits = (offset, limit, keepReading) => {
    return dispatch => {
        _readRecentlyUpdatedDeposits(offset, limit, keepReading, dispatch);
    }
};

function _readRecentlyUpdatedDeposits(offset, limit, keepReading, dispatch) {
    const get_route = `${API_ROUTE_READ_ALL_DEPOSITS_BY_ACCOUNT}/${offset}/${limit}/True`;
    // Return the promise so the caller can do something with it if they want
    return axiosInstance.get(get_route)
        .then(function (response) {
            dispatch(updateDeposits(response.data.deposits));

            if (keepReading) {
                if (response.data.deposits.length >= limit) {
                    dispatch(readRecentlyUpdatedDeposits(offset + limit, limit, keepReading));
                }
            }

            let depositUIDs = [];
            for (const depIndex in response.data.deposits) {
                const deposit = response.data.deposits[depIndex];
                depositUIDs.push(deposit.deposit_uid);
            }

            return depositUIDs;

        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}

export function refreshAllDeposits(includeArchived = true) {
    return dispatch => {
        const get_route = `${API_ROUTE_READ_ALL_DEPOSITS_BY_ACCOUNT}/${0}/${DEPOSIT_STARTUP_READ_LIMIT}/True/${includeArchived}`;
        axiosInstance.get(get_route)
            .then(function (response) {
                // Update the state with the loaded deposits
                dispatch(updateDeposits(response.data.deposits));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            });
    }
}

export const depositsStartUp = (current_user_account_uid, userAccountSettings) => {
    return dispatch => {
        // // Reset the state
        // dispatch(depositActionSetStartUpCheck(false));
        //
        // // Deposit user stats
        // dispatch(readDepositUserStats());
        //
        // // Read the n most recently updated deposits
        // // TODO: UPDATE THIS
        // const get_route = `${API_ROUTE_READ_ALL_DEPOSITS_BY_ACCOUNT}/${0}/${DEPOSIT_STARTUP_READ_LIMIT}/True/${userAccountSettings.always_show_archived_deposits}`;
        // axiosInstance.get(get_route)
        //     .then(function (response) {
        //         // Update the state with the loaded deposits
        //         dispatch(updateDeposits(response.data.deposits));
        //
        //         // // Store the promises for any other loading we need to do for these deposits
        //         // let promiseList = [];
        //         // // Use an array to track the basic account info we are loading
        //         // let loadedBasicAccounts = [];
        //         //
        //         // // Loop through the deposits
        //         // for (const depIndex in response.data.deposits) {
        //         //     const deposit = response.data.deposits[depIndex];
        //         //
        //         //     // Meta data load
        //         //     promiseList.push(_readDepositMetaData(deposit.deposit_uid, dispatch));
        //         //
        //         //     // Deposit invite
        //         //     if (deposit.state === DEPOSIT_STATE_PENDING) {
        //         //         promiseList.push(_readDepositInvite(deposit.deposit_uid, dispatch));
        //         //     }
        //         //
        //         //     // Determine if the current user is the landlord or not
        //         //     const isLandlord = deposit.landlord_account_uid === current_user_account_uid;
        //         //     // Use that to get the other party account UID
        //         //     const otherAccountUID = isLandlord ? deposit.tenant_account_uid : deposit.landlord_account_uid;
        //         //     // Load the basic info for this account
        //         //     if (otherAccountUID !== NULL_UUID_STRING) {
        //         //         // Check if we have already started loading the basic info for this account
        //         //         if (loadedBasicAccounts.includes(otherAccountUID) === false) {
        //         //             promiseList.push(_readBasicUserInfo(otherAccountUID, dispatch));
        //         //             loadedBasicAccounts.push(otherAccountUID);
        //         //         }
        //         //     }
        //         //
        //         //     promiseList.push(readDepositHistoryFromServer(deposit.deposit_uid, 0, 100, true, dispatch));
        //         // }
        //         //
        //         // // Are we loading anything?
        //         // if (promiseList.length > 0) {
        //         //     // Wait for the promises to resolve
        //         //     Promise.all(promiseList).then(values => {
        //         //         dispatch(depositActionSetStartUpCheck(true));
        //         //     });
        //         // } else {
        //         //     dispatch(depositActionSetStartUpCheck(true));
        //         // }
        //
        //     })
        //     .catch(function (error) {
        //         Sentry.withScope(scope => {
        //             scope.setExtra("response", error.response);
        //             Sentry.captureException(error);
        //         });
        //         dispatch(depositActionSetStartUpCheck(true));
        //     });

        dispatch(depositActionSetStartUpCheck(true));

    }
};

export const refreshDeposit = (depositUID) => {
    /*
        Pass the depositUID and the tenantInvoiceUID
        Reducer takes that, looks at invoices if it has the recipient (ten) and the deposit but the UID is diff, purge
     */

    return dispatch => {
        const get_route = API_ROUTE_READ_DEPOSIT + '/' + depositUID;
        axiosInstance.get(get_route)
            .then(function (response) {
                const updatedDeposit = response.data.deposit;

                dispatch(updateDeposit(null, updatedDeposit));

                dispatch(paymentsActionInvoiceReplacementCleanup(
                    updatedDeposit.tenant_account_uid,
                    updatedDeposit.deposit_uid,
                    updatedDeposit.tenant_invoice_uid
                ));

                dispatch(appActionsHandleInvoiceUID(updatedDeposit.deposit_uid));

                // dispatch(appActionAddInfoNotification(`Deposit '${updatedDeposit.title}' updated`));

                // TODO: Replace this with something that passes the time and gives us back items afer that timestamp
                dispatch(readDepositHistory(updatedDeposit.deposit_uid, 0, 100, false));

            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};



