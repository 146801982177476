/*
    Data Constants
 */

export const SUPPORTED_US_STATES_DICT = {
    'Massachusetts': 'MA',
    'New Jersey': 'NJ',
    'New York': 'NY'
};

export const US_STATE_ABBREVIATION_TO_FULL = {
    'MA': 'Massachusetts',
    'NJ': 'New Jersey',
    'NY': 'New York'
};

export function usStateAbbreviationToFull(stateAbbreviation){
    return US_STATE_ABBREVIATION_TO_FULL[stateAbbreviation];
}

export function usStateFullToAbbreviation(fullUSStateName){
    return SUPPORTED_US_STATES_DICT[fullUSStateName];
}