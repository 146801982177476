/*

 */

import React from 'react';
import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import Grid from "@material-ui/core/Grid";

function AdminSmallUserAccountDisplay({loading, error, userAccount, showExtraInfo = false, ...props}) {

    if (loading === true || error === true || userAccount === undefined) {
        return <VisualizeContainerLoadingIndicator loadingResource={userAccount}
                                                   displayName={'User Account'} {...props} />;
    }

    if (showExtraInfo === true) {
        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item>
                    Username: {userAccount.username}
                </Grid>

                <Grid item>
                    Name: {userAccount.first_name}, {userAccount.last_name}
                </Grid>

                <Grid item>
                    Email: {userAccount.email_address}
                </Grid>

            </Grid>
        );
    }


    // {userAccount.username} / {userAccount.first_name} {userAccount.last_name}
    return (
        <React.Fragment>
            {userAccount.username}
        </React.Fragment>
    );

}

export default AdminSmallUserAccountDisplay;