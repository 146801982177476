/*
    Dev
 */

import React from "react";
import {dollarFormattedString} from "../../../utilities/commonUtils";

function JSONSchemaRenderNumber({propDetails, objectValue}){
    const isCentsValue = propDetails.cents_value || false;
    if(isCentsValue){
        //console.log('Cents', propDetails);
        return <React.Fragment>{dollarFormattedString(objectValue)}</React.Fragment>;
    }

    return <React.Fragment>{objectValue.toString()}</React.Fragment>;
}

export default React.memo(JSONSchemaRenderNumber);