/*

 */

import React from 'react';
import {getDateDeltaString, getServerDateTimeAsMoment, jsDateTimeFromString} from "../../../utilities/commonUtils";
import Moment from "react-moment";

// const useStyles = makeStyles(theme => ({
//     root: {
//         minHeight: 60,
//         minWidth: 245,
//         borderRadius: 10,
//         border: '1px solid #e97662',
//         backgroundColor: '#FFFFFF'
//     },
//     iconStyle: {
//         marginLeft: 10,
//         marginRight: 10,
//         marginTop: 10
//     },
//     titleStyle: {
//         fontWeight: 600,
//         fontStyle: 'bold',
//         fontSize: 16,
//         color: DANGER_BUTTON_COLOR
//     },
//     messageStyle: {
//         fontWeight: 300,
//         fontStyle: 'light',
//         fontSize: 13,
//         margin: 10
//     }
// }));

export default function CreatedUpdatedTimestampDisplay({
                                                           createdTimestamp,
                                                           lastUpdatedTimestamp,
                                                           showTimeSinceCreation = true,
                                                           showTimeSinceUpdated = true,
                                                           ...props
                                                       }) {

    return (
        <React.Fragment>
            <b>Created</b>: <RenderTimestamp timestamp={createdTimestamp}/> {showTimeSinceCreation === true &&
            <RenderTimeSince timestamp={createdTimestamp}/>}
            <br/>
            <b>Updated</b>: <RenderTimestamp timestamp={lastUpdatedTimestamp}/> {showTimeSinceUpdated === true &&
            <RenderTimeSince timestamp={lastUpdatedTimestamp}/>}
        </React.Fragment>
    );

    function RenderTimestamp({timestamp}) {
        return <Moment format={'DD MMM YYYY'} date={jsDateTimeFromString(timestamp, true)}/>;
    }

    function RenderTimeSince({timestamp}) {
        return (
            <React.Fragment>
                &nbsp;(<i>{getDateDeltaString(getServerDateTimeAsMoment(timestamp))}&nbsp;ago</i>)
            </React.Fragment>
        );
    }
}

