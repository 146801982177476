/*
    Common Icons for easy import around the project
*/
import {
    faHomeLg,
    faDollarSign,
    faUser,
    faLifeRing,
    faSignOut,
    faEnvelopeOpenText,
    faKey,
    faFlagUsa as USA_FLAG_LIGHT,
    faGlobeEurope as GLOBE_LIGHT,
    faCalendarAlt,
    faCalendarCheck,
    faDoorClosed,
    faUser as faUserLight,
    faPaperPlane,
    faPaperclip,
    faComments,
    faComments as faCommentsLight,
    faClipboardListCheck,
    faBooks,
    faArchive,
    faBalanceScaleLeft,
    faGavel,
    faReplyAll,
    faAt,
    faPhone,
    faShare,
    faEnvelopeOpenDollar,
    faCheck,
    faHourglassStart,
    faCheckCircle,
    faBell,
    faInfoCircle,
    faAlarmClock,
    faBuilding,
    faEnvelopeOpen, faCabinetFiling,
} from '@fortawesome/pro-solid-svg-icons'

import {
    faUser as faUserBold,
    faPlus,
    faEye,
    faEyeSlash,
    faBuilding as faBuildingRegular,
    faVoteYea,
    faVoteNay,
    faWindowClose,
    faTimes,
    faScrubber,
    faCheckCircle as faCheckCircleRegular,
    faSignOut as faSignOutRegular,
    faSearch,
    faExpandArrowsAlt
} from '@fortawesome/pro-solid-svg-icons'

import {
    faFlagUsa,
    faGlobeEurope,
    faExclamationTriangle,
    faCaretRight,
    faUser as faUserSolid,
    faPencil,
    faCommentsAlt,
    faFile,
    faBalanceScaleLeft as faBalanceScaleLeftSolid,
    faFilePdf,
    faFileWord,
    faFileExcel,
    faFileImage,
    faFileAlt,
    faTicketAlt,
    faStreetView,
    faAddressBook,
    faChevronDown,
    faPlus as faPlusSolid,
    faCheck as faCheckSolid,
    faGavel as faGavelSolid,
    faBuilding as faBuildingSolid
} from "@fortawesome/pro-solid-svg-icons";

export const FILE_ICON_PDF = faFilePdf;
export const FILE_ICON_WORD = faFileWord;
export const FILE_ICON_EXCEL = faFileExcel;
export const FILE_ICON_IMAGE = faFileImage;
export const FILE_ICON_TEXT = faFileAlt;

export const HOME_ICON = faHomeLg;
export const FUNDS_ICON = faDollarSign;
export const ACCOUNT_ICON = faUser;
export const SUPPORT_ICON = faLifeRing;

export const CANCEL_DEPOSIT_ICON = faWindowClose;

export const SIGN_OUT_ICON = faSignOut;
export const SIGN_OUT_REGULAR_ICON = faSignOutRegular;

export const ACCOUNT_ICON_BOLD = faUserBold;

export const EMAIL_ICON = faEnvelopeOpenText;
export const PASSWORD_ICON = faKey;
export const CALENDAR_ICON = faCalendarAlt;
export const CALENDAR_CHECK_ICON = faCalendarCheck;

export const US_DOMESTIC_ICON = faFlagUsa;
export const INTERNATIONAL_ICON = faGlobeEurope;

export const CHECK_MARK_ICON = faCheck;

export const ADD_ICON = faPlus;
export const DOLLAR_ICON = faDollarSign;
export const ERROR_ICON = faExclamationTriangle;

export const HIDE_PASSWORD_ICON = faEyeSlash;
export const SHOW_PASSWORD_ICON = faEye;

export const USA_FLAG_LIGHT_ICON = USA_FLAG_LIGHT;
export const GLOBE_LIGHT_ICON = GLOBE_LIGHT;

export const BANK_ICON_REGULAR = faBuildingRegular;

export const CARET_RIGHT_ICON = faCaretRight;

export const AT_SYMBOL_ICON = faAt;
export const PHONE_ICON = faPhone;
export const SHARE_ICON = faShare;

export const ACCEPT_DEPOSIT_ICON = faVoteYea;
export const REJECT_DEPOSIT_ICON = faVoteNay;
export const CIRCLE_ICON = faScrubber;


export const CLOSE_DEPOSIT_ICON = faDoorClosed;

export const USER_LIGHT_ICON = faUserLight;
export const USER_SOLID_ICON = faUserSolid;

export const EDIT_ICON = faPencil;
export const CANCEL_ICON = faTimes;

export const CLOSE_REQUEST_RESPONSE_ICON = faReplyAll;

export const DISPUTE_ICON = faBalanceScaleLeft;
export const MEDIATOR_ICON = faGavel;
export const MEDIATOR_SOLID_ICON = faGavelSolid;
export const DISPUTE_ICON_SOLID = faBalanceScaleLeftSolid;

export const MESSAGES_ICON = faCommentsAlt;
export const SEND_MESSAGE_ICON = faPaperPlane;

export const FILE_ICON = faFile;
export const ATTACH_ICON = faPaperclip;

export const MESSAGES_INDICATOR_ICON = faComments;
export const SUMMARY_ICON = faClipboardListCheck;
export const DEPOSITS_MESSAGES_ICON = faCommentsLight;
export const HISTORY_ICON = faBooks;
export const ARCHIVE_ICON = faArchive;
export const UNARCHIVE_ICON = faCabinetFiling;
export const DEPOSIT_INVITE_ICON = faEnvelopeOpenDollar;
export const INVITE_CODE_ICON = faTicketAlt;

export const DEPOSIT_STATE_ICON_PENDING = faHourglassStart;
export const DEPOSIT_STATE_ICON_ACTIVE = faCheckCircle;
export const DEPOSIT_STATE_ICON_CLOSED_REQUESTED = faBell;
export const DEPOSIT_STATE_ICON_CLOSED = faDoorClosed;
export const DEPOSIT_STATE_ICON_DISPUTED = faBalanceScaleLeft;
export const DEPOSIT_STATE_ICON_DISPUTE_SPLIT_PROPOSED = faBalanceScaleLeft;
export const DEPOSIT_STATE_ICON_MEDIATOR_INVOLVED_DISPUTE = faBalanceScaleLeft;
export const DEPOSIT_STATE_ICON_MEDIATOR_SPLIT_PROPOSED = faBalanceScaleLeft;
export const DEPOSIT_STATE_ICON_CANCELLED = CANCEL_ICON;
export const INFO_CIRCLE_SOLID_ICON = faInfoCircle;

export const LANDLORD_ICON = faBuildingSolid;
export const TENANT_ICON = faStreetView;
export const PROPERTY_MANAGER_ICON = faAddressBook;
export const ALARM_CLOCK_ICON = faAlarmClock;

export const CHEVRON_DOWN_ICON = faChevronDown;
export const ADD_SOLID_ICON = faPlusSolid;

export const BANK_ICON = faBuilding;
export const CHECK_MARK_SOLID = faCheckSolid;
export const CHECK_MARK_CIRCLE_SOLID = faCheckCircleRegular;
export const WE_HERE_HELP_ICON = faEnvelopeOpen;
export const SEARCH_ICON = faSearch;

export const EXPAND_ICON = faExpandArrowsAlt;