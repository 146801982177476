/*
    Highlighted links for each page group card on the home page
 */


import {
    RESOURCE_TYPE_ACTION_ITEM,
    RESOURCE_TYPE_ATTACHED_STORAGE_FILES,
    RESOURCE_TYPE_BANK_ACCOUNT,
    RESOURCE_TYPE_BANK_TRANSFER,
    RESOURCE_TYPE_BULK_FUNDS_TRANSFER,
    RESOURCE_TYPE_DEPOSIFY_TAG,
    RESOURCE_TYPE_DEPOSIT,
    RESOURCE_TYPE_DEPOSIT_CLOSE_REQUEST,
    RESOURCE_TYPE_DEPOSIT_HISTORY_ENTRY,
    RESOURCE_TYPE_DEPOSIT_INTEREST,
    RESOURCE_TYPE_DEPOSIT_INVITE,
    RESOURCE_TYPE_EMAIL_TEMPLATE,
    RESOURCE_TYPE_EMAIL_VERIFICATION,
    RESOURCE_TYPE_EVENT,
    RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT,
    RESOURCE_TYPE_INVOICE,
    RESOURCE_TYPE_IP_ADDRESS,
    RESOURCE_TYPE_MAILBOX_MESSAGE,
    RESOURCE_TYPE_NOTIFICATION,
    RESOURCE_TYPE_ONLINE_USER,
    RESOURCE_TYPE_PERMISSION_ENTRY,
    RESOURCE_TYPE_PERMISSION_LOG_ENTRY,
    RESOURCE_TYPE_PERMISSIONS_ORGANIZATION_SETTINGS,
    RESOURCE_TYPE_PRIORITY_ACH_PAYMENT,
    RESOURCE_TYPE_PROPERTY,
    RESOURCE_TYPE_RESOURCE_NOTE,
    RESOURCE_TYPE_SENT_EMAIL,
    RESOURCE_TYPE_STORAGE_FILE,
    RESOURCE_TYPE_STRIPE_PAYMENT_LOG,
    RESOURCE_TYPE_SYSTEM_HISTORY_ENTRY,
    RESOURCE_TYPE_SYSTEM_STATS,
    RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT,
    RESOURCE_TYPE_USER_ACCOUNT,
    RESOURCE_TYPE_USER_ACCOUNT_BALANCE,
    RESOURCE_TYPE_USER_AGENT,
    RESOURCE_TYPE_USER_HISTORY_ENTRY,
    RESOURCE_TYPE_USER_WITHDRAW,
    RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_CLOSE_INTENT,
    RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_CREATE_INTENT,
    RESOURCE_TYPE_YARDI_VOYAGER_PROPERTY, RESOURCE_TYPE_YARDI_VOYAGER_PROPERTY_UNIT,
    RESOURCE_TYPE_YARDI_VOYAGER_RESIDENT
} from "../autoGenerated/constants/resourceTypeConstants";

export const HIGHLIGHT_PLACE_HOLDER = 'HIGHLIGHT_PLACEHOLDER';

export const PAGE_GROUP_HIGHLIGHTS = {
    banking: [
        RESOURCE_TYPE_BANK_ACCOUNT,
        RESOURCE_TYPE_BANK_TRANSFER,
        RESOURCE_TYPE_BULK_FUNDS_TRANSFER
    ],
    deposits: [
        RESOURCE_TYPE_DEPOSIT,
        RESOURCE_TYPE_DEPOSIT_INVITE,
        //RESOURCE_TYPE_DEPOSIT_INTEREST,
        RESOURCE_TYPE_DEPOSIT_CLOSE_REQUEST,
        // RESOURCE_TYPE_PROPERTY
    ],
    events: [
        RESOURCE_TYPE_EVENT,
        RESOURCE_TYPE_NOTIFICATION,
        RESOURCE_TYPE_ACTION_ITEM
    ],
    history: [
        RESOURCE_TYPE_DEPOSIT_HISTORY_ENTRY,
        RESOURCE_TYPE_USER_HISTORY_ENTRY,
        RESOURCE_TYPE_SYSTEM_HISTORY_ENTRY
    ],
    messaging: [
        RESOURCE_TYPE_SENT_EMAIL,
        RESOURCE_TYPE_EMAIL_TEMPLATE,
        RESOURCE_TYPE_MAILBOX_MESSAGE
    ],
    payments: [
        RESOURCE_TYPE_USER_WITHDRAW,
        RESOURCE_TYPE_STRIPE_PAYMENT_LOG,
        RESOURCE_TYPE_PRIORITY_ACH_PAYMENT
    ],
    permissions: [
        RESOURCE_TYPE_PERMISSION_ENTRY,
        RESOURCE_TYPE_PERMISSION_LOG_ENTRY,
        RESOURCE_TYPE_PERMISSIONS_ORGANIZATION_SETTINGS
    ],
    security: [
        RESOURCE_TYPE_IP_ADDRESS,
        RESOURCE_TYPE_USER_AGENT,
        HIGHLIGHT_PLACE_HOLDER
    ],
    storage: [
        RESOURCE_TYPE_STORAGE_FILE,
        RESOURCE_TYPE_ATTACHED_STORAGE_FILES,
        HIGHLIGHT_PLACE_HOLDER
    ],
    user_accounts: [
        RESOURCE_TYPE_USER_ACCOUNT,
        RESOURCE_TYPE_USER_ACCOUNT_BALANCE,
        // RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT,
        // RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT,
        RESOURCE_TYPE_EMAIL_VERIFICATION
    ],
    system_general: [
        RESOURCE_TYPE_SYSTEM_STATS,
        RESOURCE_TYPE_RESOURCE_NOTE,
        RESOURCE_TYPE_DEPOSIFY_TAG
    ],
    frontend: [
        RESOURCE_TYPE_ONLINE_USER
    ],
    yardi: [
        RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_CREATE_INTENT,
        RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_CLOSE_INTENT,
        RESOURCE_TYPE_YARDI_VOYAGER_RESIDENT,
        RESOURCE_TYPE_YARDI_VOYAGER_PROPERTY,
        RESOURCE_TYPE_YARDI_VOYAGER_PROPERTY_UNIT,
    ]
};