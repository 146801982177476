import React from "react";

import Grid from "@material-ui/core/Grid";
import GenericObjectDisplay from "../../clientShared/components/display/GenericObjectDisplay";
import GenericObjectAsTable from "../../clientShared/components/display/GenericObjectAsTable";
import ACHPaymentPaidStateChip from "./ACHPaymentPaidStateChip";
import PaymentAdminFlaggedChip from "./PaymentAdminFlaggedStateChip";
import PaymentsAdminButtonSetAdminReviewState from "./PaymentsAdminButtonSetAdminReveiwState";
import PaymentsAdminButtonSetPaymentClearedState from "./PaymentsAdminButtonSetPaymentClearedState";
import {PAYMENT_TYPE_PRIORITY_ACH} from "../../constants/paymentConstants";
import DetailedUserAccountInfoLink from "../links/DetailedUserAccountInfoLink";
import SetACHPaymentStateButton from "./SetACHPaymentStateButton";
import PaymentClearedChip from "./PaymentClearedChip";
import PaymentHasFundsLocationChip from "./PaymentHasFundsLocationChip";


export function ACHPaymentDisplay(props) {


    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item>
                &nbsp;
            </Grid>

            <Grid item>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <ACHPaymentPaidStateChip achPayment={props.priorityAchPayment}/>
                    </Grid>
                    <Grid item>
                        <PaymentAdminFlaggedChip achPayment={props.priorityAchPayment}/>
                    </Grid>
                    <Grid item>
                        <PaymentClearedChip paymentObject={props.priorityAchPayment}/>
                    </Grid>
                    <Grid item>
                        <PaymentHasFundsLocationChip paymentObject={props.priorityAchPayment}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <GenericObjectDisplay {...props}/>
            </Grid>

            <Grid item>
                <DetailedUserAccountInfoLink accountUID={props.priorityAchPayment.account_uid}/>
            </Grid>

            <Grid item>
                <h2>
                    &nbsp;&nbsp;Danger Buttons
                </h2>
                <PaymentsAdminButtonSetAdminReviewState paymentObject={props.priorityAchPayment} isStripe={false}/>
                <br/>
                <br/>
                <SetACHPaymentStateButton achPayment={props.priorityAchPayment}/>
                <br/>
                <br/>
                {/*<PaymentsAdminButtonSetPaymentClearedState*/}
                {/*    paymentObject={props.priorityAchPayment}*/}
                {/*    paymentUID={props.priorityAchPayment.payment_uid}*/}
                {/*    paymentType={PAYMENT_TYPE_PRIORITY_ACH}*/}
                {/*/>*/}
            </Grid>

            <Grid item>
                <h2>
                    &nbsp;&nbsp;Priority Return Payload
                </h2>
                <GenericObjectAsTable sourceObject={props.priorityAchPayment.priority_return_payload}/>
            </Grid>

            <Grid item>
                &nbsp;
            </Grid>

        </Grid>
    );
}

