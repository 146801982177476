class RhinoProperty {
    constructor() {
        this.rhino_property_id = null;
        this.rhino_property_name = null;
        this.bank_hash = null;
        this.row_index = -1;
        this.duplicate_rows = [];
    }

    keysList(){
        return [
            "rhino_property_id"
        ]
    }

    extractFromTSVRow(tsvRow, headerMapping){
        this.keysList().map(currentKey => {
            let currentField = headerMapping[currentKey];
            this[currentKey] = tsvRow[currentField.column_name];
        })

        // property name is optional

        if(this.rhino_property_id === null || this.rhino_property_id === undefined){
            throw new Error("No property ID");
        }

        return this;
    }
}

export default RhinoProperty;