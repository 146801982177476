/*

 */

export const DEPOSIT_COMPLIANCE_ID_GENERATE_ACTIVE_DEPOSIT_RECEIPTS = 0;
export const DEPOSIT_COMPLIANCE_ID_DAILY_DEPOSIT_INTEREST = 1;
export const DEPOSIT_COMPLIANCE_ID_QUARTERLY_REPORT_AND_PAYOUT = 2;

export const COMPLIANCE_ID_INFO = {
    [DEPOSIT_COMPLIANCE_ID_GENERATE_ACTIVE_DEPOSIT_RECEIPTS]: 'Generate active deposit receipts',
    [DEPOSIT_COMPLIANCE_ID_DAILY_DEPOSIT_INTEREST]: 'Daily interest',
    [DEPOSIT_COMPLIANCE_ID_QUARTERLY_REPORT_AND_PAYOUT]: 'Quarterly interest payout'
}

