/**
 * API Routes
 *
 * TODO: Be nice if this was an autogenerated file
 */

//
// Resources
//
export const API_ROUTE_READ_RESOURCE_UID_LIST_VIA_QUERY = 'api/resources/resource/:resourceType/uid/list/query';
export const API_ROUTE_COMMON_RESOURCE_URL = 'api/resources/resource/:resourceType/:resourceUID';


//
// Forms
//
export const API_ROUTE_FORM = 'api/forms/form/:formID'
export const API_ROUTE_PUBLIC_FORM = 'api/forms/form/public/:formID'

//
// Deposits
//
export const API_ROUTE_RESEND_DEPOSIT_INVITE = 'api/deposits/:depositUID/invite/resend/email';

export const API_ROUTE_REJECT_DEPOSIT_AS_TENANT = 'api/deposits/reject/tenant';
export const API_ROUTE_REJECT_DEPOSIT_AS_LANDLORD = 'api/deposits/reject/landlord';

export const API_ROUTE_ACCEPT_DEPOSIT_AS_TENANT = 'api/deposits/:depositUID/accept/tenant';
export const API_ROUTE_ACCEPT_DEPOSIT_AS_LANDLORD = 'api/deposits/accept/landlord';
export const API_ROUTE_CANCEL_DEPOSIT = 'api/deposits/:depositUID/cancel';

export const API_ROUTE_DEPOSIT_WITHHOLD_FUNDS = 'api/deposits/deposit/withheld/funds/add';

export const API_ROUTE_READ_CHILD_PROPERTIES = 'api/deposits/properties/:propertyUID/children/:offset/:limit';

export const API_ROUTE_PROPERTY_ADDRESS_GEOCODER_SEARCH = 'api/properties/geocoder/address/lookup';
export const API_ROUTE_PROPERTY_ADDRESS_GEOCODER_LOCATION_ID_LOOKUP = 'api/properties/geocoder/locationID/lookup';

//
// Payments
//
export const API_ROUTE_CREATE_PAYMENT_REQUEST = 'api/payments/request';
export const API_ROUTE_GET_PAYMENT_FEES = 'api/payments/fees/:resourceUID';
export const API_ROUTE_GET_SEZZLE_PAYMENT_INFO = 'api/payments/sezzle/deposit/:resourceUID/info';
export const API_ROUTE_DEPOSIT_LOAN_REQUEST = 'api/payments/loans/deposit/loan/request/:depositUID';

export const API_ROUTE_CREATE_PRIORITY_ACH_PAYMENT = 'api/payments/priority/ach/process';
export const API_ROUTE_PROCESS_STRIPE_PAYMENT = 'api/payments/stripe/process';
export const API_ROUTE_CREATE_SEZZLE_ORDER = 'api/payments/sezzle/deposit/:depositUID/create';

export const API_ROUTE_CANCEL_USER_WITHDRAWAL = 'api/payments/withdrawals/:withdrawUID/cancel';
export const API_ROUTE_RESUBMIT_USER_WITHDRAWAL = 'api/payments/withdrawals/:withdrawUID/resubmit';
export const API_ROUTE_UPDATE_USER_WITHDRAWAL = 'api/payments/withdrawals/:withdrawUID/update';

export const API_ROUTE_GET_LOA_PAYMENT_INFO = 'api/payments/loans/loan/payment/info/:loanAmountInCents';

//
// Storage
//
export const API_ROUTE_CREATE_STORAGE_FILE_FOR_UPLOAD = 'api/storage/file/upload/create';
export const API_ROUTE_MARK_STORAGE_FILE_UPLOAD_COMPLETE = 'api/storage/file/:fileUID/upload/complete';
export const API_ROUTE_DELETE_STORAGE_FILE = 'api/storage/file/:fileUID';
export const API_ROUTE_ADD_FILES_TO_ATTACHED_STORAGE_FILES = 'api/storage/attached/:attachedFilesUID/add/files';

//
// Account
//
export const API_ROUTE_GET_PROFILE_PIC_URL = 'api/accounts/:accountUID/profile/pic/download/url';
export const API_ROUTE_FINALIZE_PROFILE_PIC_UPLOAD_URL = 'api/accounts/settings/profile/pic/finalize';
export const API_ROUTE_CHANGE_USER_ACCOUNT_PASSWORD = 'api/accounts/update/password';