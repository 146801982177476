/*
    UUID Utilities
 */

// Regular expression for matching a UUIDs
export const UUID_REGEXP = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const UUID_HEX_REGEXP = /^[0-9a-f]{8}[0-9a-f]{4}[1-5][0-9a-f]{3}[89ab][0-9a-f]{3}[0-9a-f]{12}$/i;

// ---------------------------------------------------------------------------------------------------------------------
export function ensureFullUUIDValue(uuidValue) {
    /*
        Returns the UUID value in full form, E.g. c6e9857e-c49f-40fe-b8fe-073eb7d1588c
        If the UUID is hex, it'll be converted to full
     */
    if (isHexUUID(uuidValue)) {
        return convertHexUIDStringToFull(uuidValue);
    }

    return uuidValue;
}

// ---------------------------------------------------------------------------------------------------------------------
export function isUUIDValue(uuidValue) {
    /*
        Return bool indicating if this is a UUID
     */
    if (isFullUUID(uuidValue)) {
        return true;
    }
    return isHexUUID(uuidValue);
}

// ---------------------------------------------------------------------------------------------------------------------
export function isHexUUID(uuidValue) {
    /*
        Return bool indicating if the UUID value is a HEX uuid, E.g. c6e9857ec49f40feb8fe073eb7d1588c
     */
    const hexUUID = uuidValue.search(UUID_HEX_REGEXP);
    return hexUUID >= 0;
}

// TODO: Hyphenated is probably a better name than full
// ---------------------------------------------------------------------------------------------------------------------
export function isFullUUID(uuidValue) {
    /*
        Return bool indicating if the UUID value is a full uuid, E.g. c6e9857e-c49f-40fe-b8fe-073eb7d1588c
     */
    const hexUUID = uuidValue.search(UUID_REGEXP);
    return hexUUID >= 0;
}

// ---------------------------------------------------------------------------------------------------------------------
export function convertHexUIDStringToFull(uidString) {
    /*
        Convert a hex UID: c6e9857ec49f40feb8fe073eb7d1588c
        To full with dashes: c6e9857e-c49f-40fe-b8fe-073eb7d1588c

        The server doesn't care about the format, it will take any valid UID format.
        The client does, since we use the UUID of deposits, account, ... as the key.
        Since the key is the full UUID with dashes, we must make sure we use that for lookup and not the hex.
     */

    // If there are no dashes, then add them and send it back
    if (uidString.indexOf('-') === -1) {
        return uidString.substr(0, 8) + "-" + uidString.substr(8, 4) + "-" + uidString.substr(12, 4) + "-" + uidString.substr(16, 4) + "-" + uidString.substr(20);
    }

    // Return as is
    return uidString;
}