import React from 'react';
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis,} from 'recharts';
import {SYSTEM_STATS_DEFAULT_HEIGHT, SYSTEM_STATS_DEFAULT_WIDTH} from "../systemStatsConstants";
import {systemStatsCreatedTimestampToName} from "../../utilities/statsUtilities";


const KEYS_AND_DATA = [
    {dataKey: "stats_json.state_stats.pending.state_stats.count", stroke: "#49bdec", fill: "#49bdec", name: 'Pending'},
    {dataKey: "stats_json.state_stats.active.state_stats.count", stroke: "#a1dcbc", fill: "#a1dcbc", name: 'Active'},
    {
        dataKey: "stats_json.state_stats.close_requested.state_stats.count",
        stroke: "#ffc3a6",
        fill: "#ffc3a6",
        name: 'Close Requested'
    },
    {dataKey: "stats_json.state_stats.closed.state_stats.count", stroke: "#aed1e0", fill: "#aed1e0", name: 'Closed'},
    {
        dataKey: "stats_json.state_stats.disputed.state_stats.count",
        stroke: "#f09f91",
        fill: "#f09f91",
        name: 'Disputed'
    },
    {
        dataKey: "stats_json.state_stats.dispute_split_proposed.state_stats.count",
        stroke: "#f09f91",
        fill: "#f09f91",
        name: 'Dispute Split'
    },
    {
        dataKey: "stats_json.state_stats.mediator_involved_dispute.state_stats.count",
        stroke: "#f09f91",
        fill: "#f09f91",
        name: 'Med Dispute'
    },
    {
        dataKey: "stats_json.state_stats.mediator_split_proposed.state_stats.count",
        stroke: "#f09f91",
        fill: "#f09f91",
        name: 'Med Split'
    },
    {
        dataKey: "stats_json.state_stats.cancelled.state_stats.count",
        stroke: "#bec5cd",
        fill: "#bec5cd",
        name: 'Cancelled'
    },
];

export function DailyDetailedDepositStats({
                                              systemStats,
                                              width = SYSTEM_STATS_DEFAULT_WIDTH,
                                              height = SYSTEM_STATS_DEFAULT_HEIGHT,
                                              ...props
                                          }) {
    // Convert the created timestamp into the name for the tooltip
    systemStats.forEach(systemStatsCreatedTimestampToName);

    return (
        <React.Fragment>
            Deposits Detailed<br/>
            {/*<AreaChart*/}
            {/*    width={width}*/}
            {/*    height={height}*/}
            {/*    data={systemStats}*/}
            {/*    margin={{*/}
            {/*        top: 10, right: 30, left: 0, bottom: 0,*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <CartesianGrid strokeDasharray="3 3"/>*/}
            {/*    <XAxis dataKey="name"/>*/}
            {/*    <YAxis/>*/}
            {/*    <Tooltip/>*/}
            {/*    <Area type="monotone" dataKey="stats_json.state_stats.pending.state_stats.count" stroke="#aeeeb4"*/}
            {/*          fill="#aeeeb4" name={'Pending'}/>*/}
            {/*    <Area type="monotone" dataKey="stats_json.state_stats.active.state_stats.count" stroke="#a1dcbc"*/}
            {/*          fill="#a1dcbc" name={'Active'}/>*/}
            {/*    <Area type="monotone" dataKey="stats_json.state_stats.close_requested.state_stats.count"*/}
            {/*          stroke="#ffc3a6" fill="#ffc3a6" name={'Close Requested'}/>*/}
            {/*    <Area type="monotone" dataKey="stats_json.state_stats.closed.state_stats.count" stroke="#aed1e0"*/}
            {/*          fill="#aed1e0" name={'Closed'}/>*/}
            {/*    <Area type="monotone" dataKey="stats_json.state_stats.disputed.state_stats.count" stroke="#f09f91"*/}
            {/*          fill="#f09f91" name={'Disputed'}/>*/}
            {/*    <Area type="monotone" dataKey="stats_json.state_stats.dispute_split_proposed.state_stats.count"*/}
            {/*          stroke="#f09f91" fill="#f09f91" name={'Dispute Split'}/>*/}
            {/*    <Area type="monotone" dataKey="stats_json.state_stats.mediator_involved_dispute.state_stats.count"*/}
            {/*          stroke="#f09f91" fill="#f09f91" name={'Med Dispute'}/>*/}
            {/*    <Area type="monotone" dataKey="stats_json.state_stats.mediator_split_proposed.state_stats.count"*/}
            {/*          stroke="#f09f91" fill="#f09f91" name={'Med Split'}/>*/}
            {/*    <Area type="monotone" dataKey="stats_json.state_stats.cancelled.state_stats.count" stroke="#bec5cd"*/}
            {/*          fill="#bec5cd" name={'Cancelled'}/>*/}
            {/*</AreaChart>*/}

            <BarChart
                width={width}
                height={height}
                data={systemStats}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                {/*<Bar dataKey="stats_json.state_stats.pending.state_stats.count" fill="#aeeeb4" name={'Pending'} />*/}
                {
                    Object.keys(KEYS_AND_DATA).map((currentKey, barIndex) => {
                        return <Bar {...KEYS_AND_DATA[currentKey]} key={`dep-stats-bar-${barIndex}`} />;
                    })
                }
            </BarChart>
        </React.Fragment>
    );

}
