import React, {useEffect} from 'react';
import {newLoadingIndicatorSelector} from "../../selectors/loadingSelectors";
import {useDispatch, useSelector} from "react-redux";
import {checkLoadingIndicator, createLoadingIndicatorUID} from "../../utilities/loadingUtilities";
import {loadingActionLoadResourceFromServer} from "../actions/sharedLoadingActions";
import {resourceActionUpdateSingleResource} from "../actions/resourceActions";
import {DEV_BUILD} from "../../constants/apiConstants";


const useResourceLoader = (resourceUID, resourceType, resourcesSelector, outgoingResourceName) => {
    /*
        NOTE:   This is 100% a quick 10min test and should not be considered production safe

        Example:
            useLoadingHook(depositUID, RESOURCE_TYPE_DEPOSIT, NewDepositSelector, 'deposit');
     */
    const loadingIndicatorKey = createLoadingIndicatorUID(resourceUID, resourceType);

    const dispatch = useDispatch();
    const resource = useSelector(state => resourcesSelector(state, resourceUID));
    const loadingIndicator = useSelector(state => newLoadingIndicatorSelector(state, loadingIndicatorKey));

    useEffect(() => {
        const fetchFromServer = async () => {
            // console.log('::::::::::: Hook Server Load Call');
            dispatch(loadingActionLoadResourceFromServer(resourceType, resourceUID, resourceActionUpdateSingleResource));
        };
        fetchFromServer();
    }, []);

    const {loading, error, response} = checkLoadingIndicator(loadingIndicator);
    const returnLoading = loading === true || resource === undefined;

    if(DEV_BUILD === true && error === true){
        throw new Error(`useResourceLoader, res: ${resourceType}, ${resourceUID}`);
    }

    // console.log(':: Hook', loading, error, response, resource, returnLoading);
    return {loading: returnLoading, error, [outgoingResourceName]: resource};
};

export default useResourceLoader;