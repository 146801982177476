/*
    Style Constants
 */

export const PRIMARY_BLUE_COLOUR = '#29ABE2';
export const BACKGROUND_COLOUR = '#F6F9FC';
export const BRIGHT_STARTUP_SPINNER_BLUE = '#4fc8f8';
export const TEXT_PRIMARY_COLOUR = '#54595B';

export const INFO_BLUE_COLOUR = '#6298e9';

export const HELP_QUESTION_BUTTON_COLOR = '#c6c6c6';

export const ADD_GREEN_COLOR = '#3dbc3d';

export const PAGE_CONTENTS_WIDTH_DESKTOP = '90%';
export const PAGE_CONTENTS_MARGIN_LEFT_DESKTOP  = '5%';
export const PAGE_CONTENTS_WIDTH_SMALL_SCREEN = '96%';
export const PAGE_CONTENTS_MARGIN_LEFT_SMALL_SCREEN  = '2%';

export const SMALL_SCREEN_DEPOSIT_FONT_SIZE = 12;
export const MEDIUM_SCREEN_DEPOSIT_FONT_SIZE = 14;
export const LARGE_SCREEN_DEPOSIT_FONT_SIZE = 16;

export const DIALOG_MIN_WIDTH = 350;

//export const DEPOSIT_TABS_SELECTED_COLOR = '#6ab6e2';
export const DEPOSIT_TABS_SELECTED_COLOR = '#979797';

export const CIRCULAR_PROGRESS_DEFAULT_SIZE = 20;

export const PMC_COLOUR = '#c4fbde';
export const LANDLORD_COLOUR = '#c2d9fb';
export const ADMIN_COLOUR = '#fbb10a';

export const FADED_LINK_COLOUR = "rgb(66,142,174)";