import React, {useState} from 'react';
import AddressAutoComplete from "../../../clientShared/forms/fields/AddressAutoComplete";
import PropertyTypeAutoSuggestion from "../../../components/properties/suggestions/PropertyTypeAutoSuggestion";
import {FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import IF from "../../../clientShared/components/utility/IFComponent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDigging} from "@fortawesome/pro-solid-svg-icons";
import CopyButton from "../../../clientShared/components/utility/CopyButton";
import {Alert} from "@material-ui/lab";
import {
    getSinglePropertyTypeSuggestion,
    PROPERTY_TYPE_COMMUNITY,
    PROPERTY_TYPE_UNIT
} from "../../../constants/propertyConstants";

const CHILDREN_NONE = 'child.none';
const CHILDREN_NUMERICAL = 'child.numerical';
const CHILDREN_MANUAL = 'child.manual';


const PropertiesBulkImport = ({id}) => {
    const [addressSuggestion, setAddressSuggestion] = useState(undefined);
    const [propertyTypeInfo, setPropertyTypeInfo] = useState(getSinglePropertyTypeSuggestion(PROPERTY_TYPE_COMMUNITY));
    const [childPropertyTypeInfo, setChildPropertyTypeInfo] = useState(getSinglePropertyTypeSuggestion(PROPERTY_TYPE_UNIT));
    const [childType, setChildType] = useState(CHILDREN_NONE);
    const [startNumber, setStartNumber] = useState(1);
    const [endNumber, setEndNumber] = useState(10);
    const [title, setTitle] = useState('');
    const [manualUnitNumbers, setManualUnitNumbers] = useState('23B, 48A');
    const [manualUnitNumbersDelimiter, setManualUnitNumbersDelimiter] = useState(',');
    const [outputJSON, setOutputJSON] = useState({});
    const [validJSON, setValidJSON] = useState(false);
    const [unitCount, setUnitCount] = useState(0);

    const updateAddressSuggestion = (newSuggestion) => {
        setAddressSuggestion(newSuggestion);
        if (newSuggestion !== undefined && title === '') {
            setTitle(newSuggestion.label);
        }
    }

    const updatePropertyTypeInfo = (newPropertyTypeInfo) => {
        setPropertyTypeInfo(newPropertyTypeInfo);
    }

    const updateChildPropertyTypeInfo = (newPropertyTypeInfo) => {
        setChildPropertyTypeInfo(newPropertyTypeInfo);
    }

    const handleChildChange = (event) => {
        setChildType(event.target.value);
    };

    const updateJSON = () => {
        /*
            {
                location_ids: {
                    1: <some_location_id>,
                    2: <some_location_id>,
                    ...
                }
                properties: {
                    1: {
                        title, type, unit_apt_number, ..., parent_id, location_id, child_units: {type, [ids]}
                    },
                    2: {
                        'Deer Lake Community', 'community', '', -1, 1
                    },
                    3: {
                        '#23 Deer Lake', 'unit', '23', 2, 1
                    }
                },
                tags: [],
                account_uid: <some_account_uid>
            }
         */

        let finalJSON = {properties: [], tags: [], accountUID: ''};
        let isValidJSON = true;

        let newProperty = {
            id: 0,
            title: title,
            property_type: propertyTypeInfo.value,
            // parentID: -1,
            locationID: '',
            childUnits: {property_type: null, ids: []}
        }

        if (title === '') {
            isValidJSON = false;
        }

        if (addressSuggestion !== undefined) {
            newProperty.locationID = addressSuggestion.locationId;
        } else {
            isValidJSON = false;
        }

        if (childType === CHILDREN_NUMERICAL) {
            if (startNumber >= endNumber) {
                isValidJSON = false;
            } else {
                for (let i = startNumber; i < (endNumber + 1); ++i) {
                    newProperty.childUnits.ids.push(i.toString());
                }
            }
        }

        if (childType === CHILDREN_MANUAL) {
            let actualDelimiter = manualUnitNumbersDelimiter;
            if (manualUnitNumbersDelimiter === '\\t') actualDelimiter = '\t';
            if (manualUnitNumbersDelimiter === '\\n') actualDelimiter = '\n';

            newProperty.childUnits.ids = manualUnitNumbers.split(actualDelimiter).map(x => x.trim());
        }

        if (childType !== CHILDREN_NONE) {
            if (childPropertyTypeInfo !== undefined) {
                newProperty.childUnits.property_type = childPropertyTypeInfo.value;
            } else {
                isValidJSON = false;
            }
        }

        finalJSON.properties.push(newProperty);

        setOutputJSON(finalJSON);
        setValidJSON(isValidJSON);
        setUnitCount(newProperty.childUnits.ids.length);
    }

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}
        >
            <Grid item>
                <PropertyTypeAutoSuggestion valueChangeCallback={updatePropertyTypeInfo}
                                            currentValue={propertyTypeInfo}/>
            </Grid>

            <Grid item>
                <AddressAutoComplete changeCallback={updateAddressSuggestion}/>
            </Grid>

            <Grid item>
                <TextField
                    id="title"
                    label="Title"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={title}
                    onChange={(changeEvent) => setTitle(changeEvent.target.value)}
                    fullWidth={true}
                />
            </Grid>

            <Grid item>
                <PropertyTypeAutoSuggestion
                    valueChangeCallback={updateChildPropertyTypeInfo}
                    currentValue={childPropertyTypeInfo}
                />
            </Grid>

            <Grid item>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Child Types</FormLabel>
                    <RadioGroup
                        row aria-label="gender" name="row-radio-buttons-group"
                        onChange={handleChildChange}
                        value={childType}
                    >
                        <FormControlLabel value={CHILDREN_NONE} control={<Radio/>} label="None"/>
                        <FormControlLabel value={CHILDREN_NUMERICAL} control={<Radio/>} label="Numerical"/>
                        <FormControlLabel value={CHILDREN_MANUAL} control={<Radio/>} label="Manual List"/>
                    </RadioGroup>
                </FormControl>
            </Grid>

            <br/>

            <IF condition={childType !== CHILDREN_NONE}>

                <IF condition={childType === CHILDREN_NUMERICAL}>
                    <Grid item>
                        <TextField
                            id="start-number"
                            label="Start Number"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={startNumber}
                            onChange={(changeEvent) => setStartNumber(changeEvent.target.value)}
                        />
                        <TextField
                            id="end-number"
                            label="End Number"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={endNumber}
                            onChange={(changeEvent) => setEndNumber(changeEvent.target.value)}
                        />
                    </Grid>
                </IF>

                <IF condition={childType === CHILDREN_MANUAL}>
                    <Grid item>
                        <TextField
                            id="manual-unit-numbers"
                            label="Unit Numbers"
                            variant="standard"
                            value={manualUnitNumbers}
                            onChange={(changeEvent) => setManualUnitNumbers(changeEvent.target.value)}
                            fullWidth={true}
                        />
                        <br/>
                        <TextField
                            id="manual-unit-numbers-delimiter"
                            label="Unit Numbers Delimiter"
                            variant="standard"
                            value={manualUnitNumbersDelimiter}
                            onChange={(changeEvent) => setManualUnitNumbersDelimiter(changeEvent.target.value)}
                        />
                    </Grid>
                </IF>

            </IF>

            <Grid item>
                <Button variant="contained" size="medium" color="primary" onClick={updateJSON}>
                    <FontAwesomeIcon icon={faDigging} fixedWidth/>&nbsp;Update JSON
                </Button>
            </Grid>

            <Grid item>
                <IF condition={validJSON}>
                    <CopyButton
                        dataToCopy={JSON.stringify(outputJSON, undefined, 4)}
                        toolTipPostFixText={'JSON'}
                    />
                </IF>

                <IF condition={!validJSON}>
                    <Alert severity="warning">
                        Invalid JSON
                    </Alert>
                </IF>
            </Grid>

            <Grid item>
                Unit count: {unitCount.toString()}
                <br/>
                <pre>
                    {JSON.stringify(outputJSON, undefined, 4)}
                </pre>
            </Grid>

        </Grid>
    );
};

export default PropertiesBulkImport;