/*

 */

import React from 'react';
import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import {CopyUIDLink} from "../../admin/buttons/linkButtons/copyUIDLink";


export default function UserAccountProfilePicInfoDisplay({userAccount, picMode = true, ...props}) {
    if(userAccount.profile_pic_file_uid === NULL_UUID_STRING) {
        return (
            <React.Fragment>
                <b>Profile pic</b>: <i>none</i>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <b>Profile pic</b>: <CopyUIDLink uid={userAccount.profile_pic_file_uid}/>
        </React.Fragment>
    );
}
