import React from "react";
import {faHandshake, faUnicorn} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RHINO_COLOUR} from "../../constants/rhinoConstants";

export default function DepositFundsHeldByPartner({fundsHeldByPartner}) {

    if (fundsHeldByPartner) {
        return (
            <>
                <FontAwesomeIcon
                    icon={faHandshake}
                    fixedWidth
                />
                &nbsp;Partner
            </>
        );
    }

    return (
        <>
            <FontAwesomeIcon
                icon={faUnicorn}
                fixedWidth
                color={RHINO_COLOUR}
            />
            &nbsp;Rhino
        </>
    )


}