/*
    System General Service
 */

import axiosRequest from "../clientShared/utilities/axios/AxiosRequest";
import {getAdminPath} from "./adminAPI";
import {RESOURCE_TYPE_DEPOSIFY_TAG} from "../autoGenerated/constants/resourceTypeConstants";
import {NULL_UUID_STRING} from "../constants/dataConstants";

//
// Service
//
const SystemGeneralService = {
    ReadSystemStats,
    CreateDeposifyTag
};

// Export
export default SystemGeneralService;

//----------------------------------------------------------------------------------------------------------------------
function ReadSystemStats(statsID, readCount) {
    const getURL = getAdminPath(`system/general/stats/multi/recent/stats/id/${statsID}/count/${readCount}`);
    return axiosRequest({
        url: getURL,
        method: 'GET'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function CreateDeposifyTag(postValues) {
    return axiosRequest({
        url: getAdminPath(`resources/resource/${RESOURCE_TYPE_DEPOSIFY_TAG}/${NULL_UUID_STRING}`),
        method: 'POST',
        data: postValues
    });
}