/*

 */

import React from 'react';
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        marginLeft: 12,
        marginRight: 12,
        marginTop: 12
    }
});


function AdminRootPage(props) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            {props.children}
        </Box>
    )
}

export default AdminRootPage;