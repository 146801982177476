import React from 'react';
import * as Sentry from "@sentry/browser";
import {FormControlLabel, FormGroup, Switch} from "@material-ui/core";
import {SITE_OFFLINE_UID, useSiteOffline} from "../../../clientShared/hooks/useSiteOffline";
import AuthAPI from "../../../api/authAPI";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {generalLoaderActionUpdateLoadedData} from "../../../clientShared/actions/GeneralLoaderActions";
import toast from "react-hot-toast";

const SiteOfflineStatusSwitch = (props) => {

    const {loading, error, offline} = useSiteOffline();
    const switchValue = offline !== true;
    const switchLabel = switchValue === true ? 'Site Online' : 'Site Offline';

    return (
        <FormGroup row>
            <FormControlLabel
                control={
                    <Switch
                        checked={switchValue}
                        onChange={handleClick}
                        name="checkedB"
                        // color={offline === true ? "secondary" : "primary"}
                        color={'secondary'}
                        disabled={loading === true || error === true}
                    />
                }
                label={switchLabel}
            />
        </FormGroup>
    );

    function handleClick() {
        return AuthAPI.SetSiteOfflineStatus(!offline)
            .then(function (response) {
                props.generalLoaderActionUpdateLoadedData({offline: !offline}, SITE_OFFLINE_UID);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
                toast.error('Update failed');
            });
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        generalLoaderActionUpdateLoadedData: generalLoaderActionUpdateLoadedData,
    }, dispatch);
};

export default connect(null, mapActionsToProps)(SiteOfflineStatusSwitch);