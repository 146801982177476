/*

 */

import React from 'react';
import AdminPermissionDisplay from "./adminDisplayPermissionDisplay";
import {faStore, faUser, faUserTie} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function AdminPermissionListingDisplay({permissionListing, ...props}) {

    return (
        <React.Fragment>
            <b><FontAwesomeIcon icon={faUser} fixedWidth/>&nbsp;{permissionListing.account_entries.length.toString()} Account Entries</b>
            <br/>
            {
                permissionListing.account_entries.map(accountEntry => {
                    return (
                        <React.Fragment>
                            <AdminPermissionDisplay permission={accountEntry} isOrgEntry={false}/>
                            <br/>
                        </React.Fragment>
                    );
                })
            }

            <hr/>

            <b><FontAwesomeIcon icon={faStore} fixedWidth/>&nbsp;{permissionListing.org_entries.length.toString()} Org Entries</b>
            <br/>
            {
                permissionListing.org_entries.map(orgEntry => {
                    return (
                        <React.Fragment>
                            <AdminPermissionDisplay permission={orgEntry} isOrgEntry={true}/>
                            <br/>
                        </React.Fragment>
                    );
                })
            }

            {
                permissionListing.org_entries.length === 0 &&
                <React.Fragment>None</React.Fragment>
            }
        </React.Fragment>
    );

}
