import React from "react";
import GenericObjectDisplay from "../../../../clientShared/components/display/GenericObjectDisplay";
import GenericObjectWrapper from "../../../../clientShared/components/display/GenericObjectWrapper";
import {
    RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT,
    RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT,
    RESOURCE_TYPE_USER_ACCOUNT
} from "../../../../autoGenerated/constants/resourceTypeConstants";
import {
    INTERNAL_USER_BANK_ACCOUNT_TYPE_US_DOMESTIC
} from "../../../../autoGenerated/constants/services/UserAccountsServiceConstants";
import UserWithdrawalMarkCompleteButton from "./UserWithdrawalMarkCompleteButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AdminCancelUserWithdrawalButton from "./AdminCancelUserWithdrawalButton";


export function UserWithdrawDisplay(props) {
    return (
        <React.Fragment>
            <GenericObjectDisplay {...props}/>
            <br/>

            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <Typography variant={"h5"}>Tools</Typography>
                </Grid>

                <Grid item>
                    <UserWithdrawalMarkCompleteButton userWithdrawal={props.userWithdraw}/>
                </Grid>

                <Grid item>
                    <AdminCancelUserWithdrawalButton userWithdrawal={props.userWithdraw}/>
                </Grid>

                <Grid item>&nbsp;</Grid>

            </Grid>

            <br/>
            <GenericObjectWrapper
                resourceUID={props.userWithdraw.user_bank_account_uid}
                resourceType={props.userWithdraw.user_bank_account_type === INTERNAL_USER_BANK_ACCOUNT_TYPE_US_DOMESTIC ? RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT : RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT}
            />
            <br/>
            <GenericObjectWrapper resourceUID={props.userWithdraw.account_uid}
                                  resourceType={RESOURCE_TYPE_USER_ACCOUNT}/>
        </React.Fragment>
    );
}

