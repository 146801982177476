/*

 */
import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import './App.css';
import {SENTRY_URL} from "./constants/apiConstants";
import {sentrySetAnonymousUserDetails} from "./utilities/sentryLoggingUtils";
import {hasAccessTokenInLocalStorage} from "./utilities/commonUtils";
import {authActionAppStartupAuthCheck} from "./actions/authActions";
import {appActionSetLoggedOut} from "./reducers/appReducers";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {history} from './history/history'
import {adminStartupLoadingSelector} from "./selectors/adminSelectors";
import {adminActionStartupLoad} from "./actions/adminActions";
import {appIsStartingSelector} from "./selectors/appSelectors";
import NotLoggedInApp from "./NotLoggedInApp";
import LoggedInApp from "./LoggedInApp";
import AppLoading from "./AppLoading";
import CacheBuster from "./clientShared/app/CahceBuster";


// TODO: Update this for production
Sentry.init({dsn: SENTRY_URL});

// Set the Sentry tags for this environment
Sentry.setTags({
    "environment": process.env.NODE_ENV,
    "react": true,
    "client": 'ADMIN',
    "client_version": global.appVersion
});

// Set the current user (auth actions will update this as they happen)
sentrySetAnonymousUserDetails();

class App extends Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
        });

        // If they have a token, try the normal startup and see what happens
        if (hasAccessTokenInLocalStorage()) {
            // Kick off the auth startup - it will do the token magic and then dispatch all other startup functions
            this.props.authActionAppStartupAuthCheck();
        } else {
            this.props.appActionSetLoggedOut();
        }
    }

    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return <AppLoading/>;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }

                    /*
                        :( ANONYMOUS USERS
                    */
                    if (this.props.userLoggedIn !== true) {
                        return (
                            <NotLoggedInApp/>
                        );
                    }

                    /*
                        :) LOGGED IN USERS
                    */
                    if (this.props.adminStartupLoading === true) {
                        return <AppLoading/>;
                    }

                    return <LoggedInApp/>;
                }}
            </CacheBuster>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        userLoggedIn: state.auth.userLoggedIn,
        adminStartupLoading: adminStartupLoadingSelector(state),
        appIsStarting: appIsStartingSelector(state)
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        authActionAppStartupAuthCheck: authActionAppStartupAuthCheck,
        appActionSetLoggedOut: appActionSetLoggedOut,
        adminActionStartupLoad: adminActionStartupLoad
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(App);
