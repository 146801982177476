// Name: System_General Body Render Definitions 

export const SYSTEM_STATS_BODY_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	stats_uid: {displayName: "Stats UID"},
	stats_type: {displayName: "Stats Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	stats_id: {displayName: "Stats Id"},
	version: {displayName: "Version"},
	stats_json: {displayName: "Stats Json"}
};

export const RESOURCE_NOTE_BODY_RENDER_DEF = {
	notes_uid: {displayName: "Notes UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	author_account_uid: {displayName: "Author Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	notes: {displayName: "Notes"}
};


export const DEPOSIFY_TAG_BODY_RENDER_DEF = {
	title: {displayName: "Title"},
	icon_name: {displayName: "Icon Name"},
	deposify_tags: {displayName: "Deposify Tags"},
	tag_uid: {displayName: "Tag UID"},
	notes_uid: {displayName: "Notes UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	html_colour: {displayName: "Html Colour"},
	description: {displayName: "Description"},
	created_timestamp: {displayName: "Created Timestamp"}
};

export const PERMANENT_LINK_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	resource_type: {displayName: "Resource Type"},
	payload: {displayName: "Payload"},
	link_uid: {displayName: "Link UID"},
	link_type: {displayName: "Link Type"},
	notes_uid: {displayName: "Notes UID"},
	last_used_timestamp: {displayName: "Last Used Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	created_timestamp: {displayName: "Created Timestamp"},
	resource_uid: {displayName: "Resource UID"}
};
