/*
    Frontend Admin Service
 */

import axiosRequest from "../clientShared/utilities/axios/AxiosRequest";
import {getAdminPath} from "./adminAPI";

//
// Service
//
const FrontendAdminService = {
    LogOnlineUserOut,
    LogAllOnlineUsersOut
};

// Export
export default FrontendAdminService;

//----------------------------------------------------------------------------------------------------------------------
function LogOnlineUserOut(onlineAccountUID) {
    const url = getAdminPath(`auth/online/user/${onlineAccountUID}/logout/send/see/true`);
    return axiosRequest({
        url: url,
        method: 'DELETE'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function LogAllOnlineUsersOut() {
    const url = getAdminPath(`auth/online/users/all/logout/send/see/true`);
    return axiosRequest({
        url: url,
        method: 'DELETE'
    });
}
