/*
    API Constants
 */


// DEV
// export const DEV_BUILD = true;
// export const API_DOMAIN = 'https://staging.deposify.com';
// export const ADMIN_URL = 'http://localhost:3000';
// export const SENTRY_URL = 'https://0b40535f5c6f489391375195342fa004@sentry.deposify.com/10';
// export const DEV_KEEP_LOADING = false;
// export const ADMIN_SEARCH_MAX_NUM_RESULTS = 3;
// export const DEFAULT_BATCH_UID_LOAD_LIMIT = 10;


// PROD
export const DEV_BUILD = false;
export const API_DOMAIN = 'https://us.deposify.com';
export const ADMIN_URL = 'https://admin.deposify.com';
export const SENTRY_URL = 'https://7466192a9bc340ff90b99aa033fa43c0@o1019107.ingest.sentry.io/6053486';
export const DEV_KEEP_LOADING = true;
export const ADMIN_SEARCH_MAX_NUM_RESULTS = 100;
export const DEFAULT_BATCH_UID_LOAD_LIMIT = 600;


// Common
export const API_AUTH_HEADER_KEY = 'Authorization';
export const API_AUTH_BEARER_KEY = 'Bearer '; // <--- NOTE: SPACE AT THE END
export const API_AUTH_ACCESS_TOKEN = 'access_token';
export const API_AUTH_REFRESH_TOKEN = 'refresh_token';


// TODO: Move this somewhere better
export const DEFAULT_ITEMS_PER_PAGE = 20;

export const DEFAULT_STATS_LOOK_BACK_COUNT = 21;

export const RESOURCE_URL_PREFIX = 'ornate/cabbage';