/*

 */

import React from 'react';
import * as Sentry from '@sentry/browser';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import ConfirmationDialog from "../../utilities/dialogs/ConfirmationDialog";
import {getAdminPath} from "../../../api/adminAPI";
import {axiosInstance} from "../../../utilities/axiosInstance";
import {loadingActionLoadInvoiceFromServer} from "../../../autoGenerated/actions/resourceLoadingActions";
import toast from "react-hot-toast";

function CancelCloseRequestButton({closeRequestUID, depositUID, cancellingAccountUID, ...props}) {
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);

    return (
        <React.Fragment>
            <ConfirmationDialog
                startOpen={showDialog}
                dangerMode={true}
                confirmCallback={cancelDepositCloseRequest}
                cancelCallback={handleClose}
                dialogTitle={'Cancel Close Request'}
                dialogText={'Are you sure you sure you want to cancel this close reqeust?'}
                confirmText={'Yes, Cancel It'}
                showSpinner={showSpinner}
            />

            <Button
                color="secondary"
                onClick={handleOpen}
            >
                <FontAwesomeIcon
                    icon={faSkullCrossbones}
                    fixedWidth
                />
                &nbsp;Cancel
            </Button>
        </React.Fragment>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
        setShowSpinner(false);
    }

    function cancelDepositCloseRequest() {
        setShowSpinner(true);

        // Cancel the deposit
        const putURL = getAdminPath(`deposits/${depositUID}/close/request/cancel/account/${cancellingAccountUID}`);

        axiosInstance.put(putURL)
            .then(function (response) {

                props.loadingActionLoadInvoiceFromServer(depositUID);

                handleClose();

            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);

                toast.error('Failed to cancel close request');
            });
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadingActionLoadInvoiceFromServer: loadingActionLoadInvoiceFromServer
    }, dispatch);
};

export default connect(null, mapActionsToProps)(CancelCloseRequestButton);
