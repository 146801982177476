/*

 */
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from "@material-ui/styles";
import {Route, Router, Switch} from 'react-router-dom';
import {history} from './history/history'
import {
    APP_ROUTE_DETAILED_DEPOSIT,
    APP_ROUTE_DETAILED_USER_ACCOUNT,
    APP_ROUTE_EMAIL_TEMPLATES,
    APP_ROUTE_HOME,
    APP_ROUTE_LOGIN,
    APP_ROUTE_PROPERTIES_BULK
} from "./constants/appRoutes";
import Login from './components/auth/pages/LoginPage';
import BadRoute from './components/app/routing/BadRoute'
import FullScreenAccountInfoDialog from "./components/admin/fullScreenAccountInfoDialog";
import AdminHomePage from "./components/pages/adminHomePage";
import FullScreenPropertyDialog from "./components/admin/fullScreenPropertyDialog";
import FullScreenUSDomesticBankAccountDialog from "./components/admin/fullScreenUSDomesticBankAccountDialog";
import FullScreenInternationalBankAccountDialog from "./components/admin/fullScreenInternationalBankAccountDialog";
import FullScreenDepositDialog from "./components/admin/fullScreenDepositDialog";
import FullScreenAttachedFilesDialog from "./components/admin/fullScreenAttachedFilesDialog";
import AdminGenericFullScreenDialog from "./components/admin/AdminGenericFullScreenDialog";
import FullScreenFileDialog from "./components/admin/fullScreenFileDialog";
import {ALL_RESOURCE_PAGE_ROUTES} from "./autoGenerated/constants/pageGroups";
import DataDisplayDrawer from "./components/pages/DataDisplayDrawer";
import shortid from 'shortid';
import SingleResourcePage from "./components/pages/SingleResourcePage";
import {APP_THEME} from "./AppTheme";
import {DEV_BUILD} from "./constants/apiConstants";
import DevPage from "./components/dev/DevPage";
import DetailedUserAccountInfoPageTwo from "./components/accounts/DetailedUserAccountInfoPageTwo";
import EditEmailTemplatePage from "./components/dev/EditEmailTemplatePage";
import {Toaster} from "react-hot-toast";
import PropertiesBulkAdminToolPage from "./components/admin/PropertiesBulkAdminTool";
import JeffPage from "./jeff/jeffPage";
import {QueryClient, QueryClientProvider} from "react-query";
import DetailedDepositInfoPageTwo from "./components/deposits/DetailedDepositInfoPageTwo";
import RhinoBankBulkPage from "./components/rhino/bankBulk/rhinoBankBulkPage";
import {RHINO_LINK_BANK_BULK_IMPORT} from "./components/rhino/rhinoLinks";

const queryClient = new QueryClient();


export default function LoggedInApp(props) {

    return (
        <ThemeProvider theme={APP_THEME}>
            <QueryClientProvider client={queryClient}>
                <CssBaseline/>
                <Router history={history}>
                    {/* Utils */}
                    <Toaster position="bottom-left"/>

                    {/*Dialogs*/}
                    <FullScreenAccountInfoDialog/>
                    <FullScreenPropertyDialog/>
                    <FullScreenUSDomesticBankAccountDialog/>
                    <FullScreenInternationalBankAccountDialog/>
                    <FullScreenDepositDialog/>
                    <FullScreenAttachedFilesDialog/>
                    <AdminGenericFullScreenDialog/>
                    <FullScreenFileDialog/>
                    <DataDisplayDrawer/>

                    {/*<br/>*/}
                    {/*<AdminHomepageStatsCollection/>*/}

                    {/*// TODO: Why no private routes?*/}
                    {/* Page Content */}
                    <Switch>
                        <Route exact path={APP_ROUTE_HOME} component={AdminHomePage}/>
                        <Route exact path={APP_ROUTE_LOGIN} component={Login}/>
                        <Route exact path={"/jeff"} component={JeffPage}/>

                        <Route exact path={RHINO_LINK_BANK_BULK_IMPORT} component={RhinoBankBulkPage}/>

                        <Route exact path={APP_ROUTE_DETAILED_USER_ACCOUNT} component={DetailedUserAccountInfoPageTwo}/>
                        <Route exact path={APP_ROUTE_EMAIL_TEMPLATES} component={EditEmailTemplatePage}/>
                        <Route exact path={APP_ROUTE_PROPERTIES_BULK} component={PropertiesBulkAdminToolPage}/>

                        <Route exact path={APP_ROUTE_DETAILED_DEPOSIT} component={DetailedDepositInfoPageTwo}/>

                        {/* Page routes */}
                        {
                            Object.keys(ALL_RESOURCE_PAGE_ROUTES).map(pageRoute => {
                                return (
                                    <Route
                                        exact
                                        path={pageRoute}
                                        component={ALL_RESOURCE_PAGE_ROUTES[pageRoute].page_name}
                                        key={shortid.generate()}
                                    />
                                );
                            })
                        }

                        {/* Single resource page: /deposits/<deposit_uid> */}
                        {
                            Object.keys(ALL_RESOURCE_PAGE_ROUTES).map(pageRoute => {
                                return (
                                    <Route
                                        exact
                                        path={`${pageRoute}/:url_resource_uid`}
                                        render={
                                            (props) => <SingleResourcePage
                                                {...props}
                                                resourceType={ALL_RESOURCE_PAGE_ROUTES[pageRoute].resource_type}
                                            />
                                        }
                                        key={shortid.generate()}
                                    />
                                );
                            })
                        }

                        {/* --- DEV --- */}
                        {
                            DEV_BUILD === true &&
                            <Route exact path={'/dev'} component={DevPage}/>
                        }

                        {/* TODO: Fix this up */}
                        <Route component={BadRoute}/>

                    </Switch>

                </Router>
            </QueryClientProvider>
        </ThemeProvider>
    );

}

