/*

 */

import React from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";


export default function SortingColumnAutoSuggest({columnSuggestions, currentValue, changeCallback, disabled=false, ...props}) {

    return (
        <Autocomplete
            options={columnSuggestions}
            getOptionLabel={option => option}
            id="sorting-column-auto-select"
            autoSelect
            autoHighlight
            value={currentValue}
            onChange={handleChange}
            renderInput={params => (
                <TextField
                    {...params}
                    label={'Sorting Column'}
                    margin="normal"
                    fullWidth
                />
            )}
            style={{minWidth: 300}}
            disabled={disabled}
        />
    );

    function handleChange(event, value){
        changeCallback(value);
    }
        
}
