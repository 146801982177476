/*

 */

import React from 'react';
import Grid from "@material-ui/core/Grid";
import {capitalize, uuidToHex} from "../../../utilities/commonUtils";
import {CopyUIDLink} from "../../admin/buttons/linkButtons/copyUIDLink";
import {
    RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT,
    RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT
} from "../../../constants/resourceConstants";
import AdminViewIntBankAccountLink from "../../admin/buttons/linkButtons/adminViewIntBankAccountLink";
import AdminViewUSDomBankAccountLink from "../../admin/buttons/linkButtons/adminViewUSDomBankAccountLink";

export default function QuickResourceInfoDisplay({resourceUID, resourceType, showUID=false, showHorizontal=false, ...props}) {

    const linkText = showUID === true ? `${uuidToHex(resourceUID)}` : 'Copy UID';

    if(showHorizontal === true){
        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                wrap={'nowrap'}
            >
                <Grid item>
                    <b>Resource</b>:&nbsp;
                </Grid>

                <Grid item>
                    {capitalize(resourceType.toString())}
                </Grid>

                <Grid item>
                    &nbsp;
                    <CopyUIDLink uid={resourceUID} linkText={linkText}/>
                    {renderViewLink()}
                </Grid>

            </Grid>
        );
    }

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            wrap={'nowrap'}
        >

            <Grid item>
                {capitalize(resourceType.toString())}
            </Grid>

            <Grid item>
                <CopyUIDLink uid={resourceUID} linkText={linkText}/>
                {renderViewLink()}
            </Grid>

        </Grid>
    );

    function renderViewLink(){
        if(resourceType === RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT){
            return <React.Fragment><br/><AdminViewIntBankAccountLink bankAccountUID={resourceUID}/></React.Fragment>;
        }
        else if(resourceType === RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT){
            return <React.Fragment><br/><AdminViewUSDomBankAccountLink bankAccountUID={resourceUID}/></React.Fragment>;
        }

        return <React.Fragment/>;
    }

}

