/*

 */

import React from 'react';
import {capitalize} from "../../../utilities/commonUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import CopyTextDataLink from "../buttons/linkButtons/copyDataLink";
import QuickTimestampDisplay from "../../utilities/displayAreas/QuickTimestampDisplay";
import AdminAccountExistsViaEmailDisplay from "./adminAccountExistsViaEmailDisplay";

export default function DepositInviteSentDisplay({depositInvite, useEmail}) {

    const isUsed = useEmail === true ? depositInvite.use_email_address : depositInvite.use_phone_number;
    const itemText = useEmail === true ? 'email' : 'text';
    const data = useEmail === true ? depositInvite.email_address : depositInvite.phone_number;
    const firstTimeStamp = useEmail === true ? depositInvite.first_email_timestamp : depositInvite.first_text_timestamp;
    const lastTimeStamp = useEmail === true ? depositInvite.last_email_timestamp : depositInvite.last_text_timestamp;

    return (
        <React.Fragment>
            <Tooltip
                title={isUsed === true ? `${capitalize(itemText)} invite sent` : `No ${itemText} invite sent`}
                placement={'top'}
            >
                <span>
                <FontAwesomeIcon
                    icon={isUsed === true ? faCheck : faTimes}
                    fixedWidth
                    color={isUsed === true ? 'green' : 'red'}
                />
                </span>
            </Tooltip>
            {
                isUsed === true &&
                <React.Fragment>
                    <br/>
                    <CopyTextDataLink linkText={data} dataToCopy={data}/>
                    <br/>
                    First: <QuickTimestampDisplay timestamp={firstTimeStamp}/>
                    <br/>
                    Last: <QuickTimestampDisplay timestamp={lastTimeStamp} showTimeSince={true}/>
                    <br/>
                    <AdminAccountExistsViaEmailDisplay emailAddress={data}/>
                </React.Fragment>
            }
        </React.Fragment>
    );

}