// Name: Deposits Table Render Definitions 

export const ARCHIVED_DEPOSIT_CLOSE_REQUEST_TABLE_RENDER_DEF = {
    reason_for_close: {displayName: "Reason For Close"},
    deposit_uid: {displayName: "Deposit UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    state: {displayName: "State"},
    notes: {displayName: "Notes"},
    triggering_account_uid: {displayName: "Triggering Account UID"},
    deposify_tags: {displayName: "Deposify Tags"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    archive_uid: {displayName: "Archive UID"},
    amount_in_cents_to_tenant: {displayName: "Amount In Cents To Tenant"},
    triggering_account_type: {displayName: "Triggering Account Type"},
    response_reason: {displayName: "Response Reason"},
    attached_files_uid: {displayName: "Attached Files UID"},
    amount_in_cents_to_landlord: {displayName: "Amount In Cents To Landlord"}
};

export const DEPOSIT_TABLE_RENDER_DEF = {
    deposit_uid: {displayName: "Deposit UID"},
    title: {displayName: "Title"},
    state: {displayName: "State"},
    amount_in_cents: {displayName: "Amount In Cents"},
    // interest_to_date_in_cents: {displayName: "Interest To Date In Cents"},
    start_date: {displayName: "Start Date"},
    landlord_account_uid: {displayName: "Landlord"},
    tenant_account_uid: {displayName: "Tenant"},
    became_active_timestamp: {displayName: "Became Active"},
    // property_uid: {displayName: "Property UID"},
    funds_held_by_partner: {displayName: "Funds Loc."},
    geo_state: {displayName: "Geo State"},
    // attached_files_uid: {displayName: "Attached Files UID"},
    // cover_photo_uid: {displayName: "Cover Photo UID"},
    // end_amount_in_cents_to_landlord: {displayName: "End Amount In Cents To Landlord"},
    // outstanding_landlord_prompts: {displayName: "Outstanding Landlord Prompts"},
    // funds_location: {displayName: "Funds Location"},
    // outstanding_tenant_prompts: {displayName: "Outstanding Tenant Prompts"},
    // dispute_uid: {displayName: "Dispute UID"},
    // tenant_accepted_timestamp: {displayName: "Tenant Accepted Timestamp"},
    // adjustments_count: {displayName: "Adjustments Count"},
    // mailbox_uid: {displayName: "Mailbox UID"},
    // funds_location_uid: {displayName: "Funds Location UID"},
    // last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    // created_by_account_uid: {displayName: "Created By Account UID"},
    // deposify_tags: {displayName: "Deposify Tags"},
    // created_timestamp: {displayName: "Created Timestamp"},
    // invoice_count: {displayName: "Invoice Count"},
    // landlord_archived: {displayName: "Landlord Archived"},
    // tenant_invoice_uid: {displayName: "Tenant Invoice UID"},
    // tenant_has_accepted: {displayName: "Tenant Has Accepted"},
    // tenant_funds_lock_uid: {displayName: "Tenant Funds Lock UID"},
    // tenant_archived: {displayName: "Tenant Archived"},
    // credit_adjustments_total_in_cents: {displayName: "Credit Adjustments Total In Cents"},
    // end_date: {displayName: "End Date"},
    // debit_adjustments_total_in_cents: {displayName: "Debit Adjustments Total In Cents"},
    // landlord_has_accepted: {displayName: "Landlord Has Accepted"},
    // active_close_request_uid: {displayName: "Active Close Request UID"},
    // landlord_accepted_timestamp: {displayName: "Landlord Accepted Timestamp"},
    // end_amount_in_cents_to_tenant: {displayName: "End Amount In Cents To Tenant"},
    // has_outstanding_adjustment_payments: {displayName: "Has Outstanding Adjustment Payments"}
};

export const DEPOSIT_INVITE_TABLE_RENDER_DEF = {
    // invite_uid: {displayName: "Invite UID"},
    deposit_uid: {displayName: "Deposit UID"},
    inviting_account_uid: {displayName: "Inviting Account UID"},
    invitee_account_type: {displayName: "Invitee Account Type"},
    use_email_address: {displayName: "Use Email Address"},
    use_phone_number: {displayName: "Use Phone Number"},
    created_timestamp: {displayName: "Created Timestamp"},
    // last_text_timestamp: {displayName: "Last Text Timestamp"},
    // short_code: {displayName: "Short Code"},
    // phone_number: {displayName: "Phone Number"},
    // email_hash: {displayName: "Email Hash"},
    // email_address: {displayName: "Email Address"},
    // first_email_timestamp: {displayName: "First Email Timestamp"},
    // last_email_timestamp: {displayName: "Last Email Timestamp"},
    // first_text_timestamp: {displayName: "First Text Timestamp"},
    // last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    // deposify_tags: {displayName: "Deposify Tags"}
};

// Name: Deposits Table Render Definitions

export const COMPLIANCE_TABLE_RENDER_DEF = {
    compliance_uid: {displayName: "Compliance UID"},
    name: {displayName: "Name"},
    // description: {displayName: "Description"},
    disabled: {displayName: "Disabled"},
    // handled_by_dedicated_function: {displayName: "Handled By Dedicated Function"},
    id: {displayName: "Id"},
    type: {displayName: "Type"},
    action_type: {displayName: "Action Type"},
    // action_payload: {displayName: "Action Payload"},
    // rule_ids: {displayName: "Rule Ids"},
    // created_timestamp: {displayName: "Created Timestamp"},
    // last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const COMPLIANCE_HISTORY_TABLE_RENDER_DEF = {
    compliance_history_uid: {displayName: "Compliance History UID"},
    deposit_uid: {displayName: "Deposit UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    compliance_id: {displayName: "Compliance Id"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const COMPLIANCE_RULE_TABLE_RENDER_DEF = {
    compliance_rule_uid: {displayName: "Compliance Rule UID"},
    id: {displayName: "Id"},
    type: {displayName: "Type"},
    value_operator: {displayName: "Value Operator"},
    value: {displayName: "Value"},
    // created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const DEPOSIT_ADJUSTMENT_TABLE_RENDER_DEF = {
    amount_in_cents: {displayName: "Amount In Cents"},
    invoice_item_uid: {displayName: "Invoice Item UID"},
    deposit_uid: {displayName: "Deposit UID"},
    adjustment_uid: {displayName: "Adjustment UID"},
    private_notes: {displayName: "Private Notes"},
    adjustment_type: {displayName: "Adjustment Type"},
    created_timestamp: {displayName: "Created Timestamp"},
    public_reason_for_adjustment: {displayName: "Public Reason For Adjustment"},
    invoice_uid: {displayName: "Invoice UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const DEPOSIT_BANK_MAPPING_TABLE_RENDER_DEF = {
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    bank_account_uid: {displayName: "Bank Account UID"},
    geo_state: {displayName: "Geo State"},
    created_timestamp: {displayName: "Created Timestamp"}
};

export const DEPOSIT_BULK_IMPORT_TABLE_RENDER_DEF = {
    bulk_import_uid: {displayName: "Bulk Import UID"},
    organization_uid: {displayName: "Organization UID"},
    attached_files_uid: {displayName: "Attached Files UID"},
    deposit_uids: {displayName: "Deposit UIDs"},
    created_timestamp: {displayName: "Created Timestamp"},
    notes: {displayName: "Notes"},
    name: {displayName: "Name"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"}
};

export const DEPOSIT_CLOSE_REQUEST_TABLE_RENDER_DEF = {
    amount_in_cents_to_tenant: {displayName: "Amount In Cents To Tenant"},
    state: {displayName: "State"},
    response_reason: {displayName: "Response Reason"},
    attached_files_uid: {displayName: "Attached Files UID"},
    deposit_uid: {displayName: "Deposit UID"},
    reason_for_close: {displayName: "Reason For Close"},
    triggering_account_type: {displayName: "Triggering Account Type"},
    notes: {displayName: "Notes"},
    close_request_uid: {displayName: "Close Request UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    amount_in_cents_to_landlord: {displayName: "Amount In Cents To Landlord"},
    triggering_account_uid: {displayName: "Triggering Account UID"}
};

export const DEPOSIT_GROUP_TABLE_RENDER_DEF = {
    subtitle: {displayName: "Subtitle"},
    creating_account_uid: {displayName: "Creating Account UID"},
    html_colour_code: {displayName: "Html Colour Code"},
    created_timestamp: {displayName: "Created Timestamp"},
    title: {displayName: "Title"},
    parent_group_uid: {displayName: "Parent Group UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    group_uid: {displayName: "Group UID"}
};

export const DEPOSIT_INTEREST_TABLE_RENDER_DEF = {
    amount_in_cents: {displayName: "Amount In Cents"},
    deposit_uid: {displayName: "Deposit UID"},
    interest_uid: {displayName: "Interest UID"},
    bank_account_interest_uid: {displayName: "Bank Account Interest UID"},
    bank_account_uid: {displayName: "Bank Account UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    interest_end_date: {displayName: "Interest End Date"},
    created_timestamp: {displayName: "Created Timestamp"},
    interest_start_date: {displayName: "Interest Start Date"}
};

export const DEPOSIT_INTEREST_LOG_TABLE_RENDER_DEF = {
    interest_in_cents_decimal: {displayName: "Interest In Cents Decimal"},
    entry_uid: {displayName: "Entry UID"},
    day_count: {displayName: "Day Count"},
    deposit_uid: {displayName: "Deposit UID"},
    end_date: {displayName: "End Date"},
    principal_in_cents_decimal: {displayName: "Principal In Cents Decimal"},
    bank_account_uid: {displayName: "Bank Account UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    bank_account_interest_rate_per_annum: {displayName: "Bank Account Interest Rate Per Annum"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    start_date: {displayName: "Start Date"}
};

export const DEPOSIT_META_DATA_TABLE_RENDER_DEF = {
    photo_file_uids: {displayName: "Photo File UIDs"},
    state: {displayName: "State"},
    deposit_uid: {displayName: "Deposit UID"},
    zip_code: {displayName: "Zip Code"},
    created_timestamp: {displayName: "Created Timestamp"},
    city: {displayName: "City"},
    tenant_last_name: {displayName: "Tenant Last Name"},
    street_address: {displayName: "Street Address"},
    tenant_first_name: {displayName: "Tenant First Name"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    unit_number: {displayName: "Unit Number"}
};

export const DEPOSIT_USER_GROUP_TABLE_RENDER_DEF = {
    group_uids: {displayName: "Group UIDs"},
    deposit_uid: {displayName: "Deposit UID"},
    account_uid: {displayName: "Account UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const DEPOSIT_USER_STATS_TABLE_RENDER_DEF = {
    account_uid: {displayName: "Account UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    stats: {displayName: "Stats"}
};

export const DEPOSIT_WITHHELD_FUNDS_TABLE_RENDER_DEF = {
    withheld_uid: {displayName: "Withheld UID"},
    // description: {displayName: "Description"},
    state: {displayName: "State"},
    // created_by_account_uid: {displayName: "Created By Account UID"},
    amount_in_cents: {displayName: "Amount In Cents"},
    // notes_uid: {displayName: "Notes UID"},
    title: {displayName: "Title"},
    deposit_uid: {displayName: "Deposit UID"},
    // deposify_tags: {displayName: "Deposify Tags"},
    // last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    // cancelled_timestamp: {displayName: "Cancelled Timestamp"},
    created_timestamp: {displayName: "Created Timestamp"},
    // attached_files_uid: {displayName: "Attached Files UID"}
};

export const DISPUTE_TABLE_RENDER_DEF = {
    state: {displayName: "State"},
    active_dispute_split_uid: {displayName: "Active Dispute Split UID"},
    attached_files_uid: {displayName: "Attached Files UID"},
    deposit_uid: {displayName: "Deposit UID"},
    first_dispute_split_uid: {displayName: "First Dispute Split UID"},
    triggering_account_type: {displayName: "Triggering Account Type"},
    reason_for_dispute: {displayName: "Reason For Dispute"},
    notes: {displayName: "Notes"},
    dispute_uid: {displayName: "Dispute UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    triggering_account_uid: {displayName: "Triggering Account UID"}
};

export const DISPUTE_INTERVENTION_REQUEST_TABLE_RENDER_DEF = {
    reason_for_request: {displayName: "Reason For Request"},
    state: {displayName: "State"},
    attached_files_uid: {displayName: "Attached Files UID"},
    request_uid: {displayName: "Request UID"},
    triggering_account_type: {displayName: "Triggering Account Type"},
    notes: {displayName: "Notes"},
    created_timestamp: {displayName: "Created Timestamp"},
    dispute_uid: {displayName: "Dispute UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    triggering_account_uid: {displayName: "Triggering Account UID"}
};

export const DISPUTE_SPLIT_TABLE_RENDER_DEF = {
    state: {displayName: "State"},
    tenant_account_uid: {displayName: "Tenant Account UID"},
    landlord_response: {displayName: "Landlord Response"},
    response_message: {displayName: "Response Message"},
    landlord_account_uid: {displayName: "Landlord Account UID"},
    deposit_uid: {displayName: "Deposit UID"},
    tenant_response: {displayName: "Tenant Response"},
    landlord_response_timestamp: {displayName: "Landlord Response Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    attached_files_uid: {displayName: "Attached Files UID"},
    tenant_response_timestamp: {displayName: "Tenant Response Timestamp"},
    dispute_uid: {displayName: "Dispute UID"},
    amount_in_cents_to_landlord: {displayName: "Amount In Cents To Landlord"},
    amount_in_cents_to_tenant: {displayName: "Amount In Cents To Tenant"},
    proposing_account_type: {displayName: "Proposing Account Type"},
    split_message: {displayName: "Split Message"},
    split_uid: {displayName: "Split UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    proposing_account_uid: {displayName: "Proposing Account UID"}
};

export const PROPERTY_TABLE_RENDER_DEF = {
    property_uid: {displayName: "Property UID"},
    title: {displayName: "Title"},
    unit_apt_number: {displayName: "Unit Apt Number"},
    property_type: {displayName: "Property Type"},
    property_address_uid: {displayName: "Property Address UID"},
    // last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    creating_account_uid: {displayName: "Creating Account UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    parent_property_uid: {displayName: "Parent Property UID"},
    // old_md_list: {displayName: "Old Md List"},
    // old_md: {displayName: "Old Md"},
    // deposify_tags: {displayName: "Deposify Tags"}
};

export const PROPERTY_ADDRESS_TABLE_RENDER_DEF = {
    state: {displayName: "State"},
    location_id: {displayName: "Location Id"},
    longitude: {displayName: "Longitude"},
    street: {displayName: "Street"},
    house_number: {displayName: "House Number"},
    version: {displayName: "Version"},
    county: {displayName: "County"},
    location_id_type: {displayName: "Location Id Type"},
    district: {displayName: "District"},
    city: {displayName: "City"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    creating_account_uid: {displayName: "Creating Account UID"},
    postal_code: {displayName: "Postal Code"},
    property_address_uid: {displayName: "Property Address UID"},
    country: {displayName: "Country"},
    latitude: {displayName: "Latitude"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    location_match_level: {displayName: "Location Match Level"}
};

export const PROPERTY_GROUP_TABLE_RENDER_DEF = {
    subtitle: {displayName: "Subtitle"},
    creating_account_uid: {displayName: "Creating Account UID"},
    html_colour_code: {displayName: "Html Colour Code"},
    created_timestamp: {displayName: "Created Timestamp"},
    title: {displayName: "Title"},
    parent_group_uid: {displayName: "Parent Group UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    group_uid: {displayName: "Group UID"}
};

export const PROPERTY_USER_GROUP_TABLE_RENDER_DEF = {
    group_uids: {displayName: "Group UIDs"},
    account_uid: {displayName: "Account UID"},
    property_uid: {displayName: "Property UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const PMC_REPORT_TIMETABLE_TABLE_RENDER_DEF = {
    landlord_account_uid_list: {displayName: "Landlord Account UID List"},
    notes_uid: {displayName: "Notes UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    recipient_account_uid: {displayName: "Recipient Account UID"},
    deposify_tags: {displayName: "Deposify Tags"},
    timetable_uid: {displayName: "Timetable UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const YARDI_DEPOSIT_XML_TABLE_RENDER_DEF = {
    created_deposit_group_uid: {displayName: "Created Deposit Group UID"},
    created_deposit_uids: {displayName: "Created Deposit UIDs"},
    created_tenant_account_uids: {displayName: "Created Tenant Account UIDs"},
    created_pmc_account_uid: {displayName: "Created Pmc Account UID"},
    notes_uid: {displayName: "Notes UID"},
    admin_error_message: {displayName: "Admin Error Message"},
    created_timestamp: {displayName: "Created Timestamp"},
    processing_completed_timestamp: {displayName: "Processing Completed Timestamp"},
    xml_uid: {displayName: "Xml UID"},
    permanent_link_uid: {displayName: "Permanent Link UID"},
    deposify_tags: {displayName: "Deposify Tags"},
    state: {displayName: "State"},
    pmc_email_hashes: {displayName: "Pmc Email Hashes"},
    xml_data: {displayName: "Xml Data"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const DEPOSIT_POST_CLOSE_ADJUSTMENT_TABLE_RENDER_DEF = {
    target_account_uid: {displayName: "Target Account UID"},
    public_reason_for_adjustment: {displayName: "Public Reason For Adjustment"},
    use_recipient_account: {displayName: "Use Recipient Account"},
    adjustment_uid: {displayName: "Adjustment UID"},
    goal_amount_in_cents: {displayName: "Goal Amount In Cents"},
    state: {displayName: "State"},
    target_account_type: {displayName: "Target Account Type"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    private_notes: {displayName: "Private Notes"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    deposit_uid: {displayName: "Deposit UID"},
    actual_amount_in_cents: {displayName: "Actual Amount In Cents"},
    notes_uid: {displayName: "Notes UID"},
    recipient_account_uid: {displayName: "Recipient Account UID"}
};