import React from 'react';
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {axiosInstance} from "../../../utilities/axiosInstance";
import FilePondComp from "../../storage/filepond/FilePond";
import {RESOURCE_TYPE_DEPOSIT} from "../../../constants/resourceConstants";
import {
    STORAGE_FILE_CATEGORY_DEPOSIT_ATTACHMENT,
    STORAGE_FILE_TYPE_ATTACHMENT
} from "../../../constants/storageConstants";
import {getAdminPath} from "../../../api/adminAPI";
import {RESOURCE_TYPE_ATTACHED_STORAGE_FILES} from "../../../autoGenerated/constants/resourceTypeConstants";
import {resourceActionUpdateSingleResource} from "../../../clientShared/actions/resourceActions";
import toast from "react-hot-toast";


const useStyles = makeStyles(theme => ({
    root: {
        //margin: 5,
        //borderRadius: 4,
        boxShadow: 'none',
        //border: '1px solid #EBEBEB',
        //border: '1px solid #fafafa',
        width: '100%',
        //backgroundColor: '#FFFFFF',
        //backgroundColor: '#007371',
        //backgroundColor: '#FFFFFF',
        //maxHeight: DEPOSIT_CARD_MIN_AREA_HEIGHT,
        //minHeight: DEPOSIT_CARD_MIN_AREA_HEIGHT,
        minWidth: 600
    },
    headerTitle: {
        fontSize: 12,
        color: '#bcbcbc',
        marginBottom: 15,
        marginTop: 10,
        marginLeft: 5
    },
    noDepositsInfoText: {
        marginBottom: 30,
        fontSize: 12,
        fontWeight: 300,
        fontColor: '#54595B',
        [theme.breakpoints.up("md")]: {
            fontSize: 16
        },
    },
    marginBottomTen: {
        marginBottom: 10
    },
    marginTopFive: {
        marginTop: 5
    },
    // TODO: Adjust this and the font size above
    listingContents: {
        //maxHeight: DEPOSIT_CARD_MIN_AREA_HEIGHT,
        //minHeight: DEPOSIT_CARD_MIN_AREA_HEIGHT,
        //overflow: 'auto'
        overFlowY: 'auto',
        overflowX: 'hidden',
        // TODO: REMOVE
        borderRadius: 4,
        //backgroundColor: '#ffc688'
        //backgroundColor: '#FFFFFF',
    },
    sendMessageArea: {
        paddingTop: 10,
        //backgroundColor: '#FFFFFF',
        borderLeft: '1px dashed #EDEDED'
    }
}));

function AdminDepositFilePond({attachedFilesUID, depositUID, uploadingAccountUID, uploadDisabled, ...props}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.listingContents}>

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    wrap={'nowrap'}
                >

                    <Grid item xs={12}>

                        <FilePondComp
                            resourceType={RESOURCE_TYPE_DEPOSIT}
                            fileType={STORAGE_FILE_TYPE_ATTACHMENT}
                            fileCategory={STORAGE_FILE_CATEGORY_DEPOSIT_ATTACHMENT}
                            removeAfterUpload={false}
                            resourceUID={depositUID}
                            setParentRef={setFilePondReference}
                            uploadingStateCallback={handleFilesAreUploadingCallback}
                            fileUploadCompleteCallback={fileUploadCompleteCallback}
                            uploadingAccountUID={uploadingAccountUID}
                            uploadDisabled={uploadDisabled}
                            allFileUploadsCompleteCallback={allFileUploadsCompleteCallback}
                        />

                    </Grid>


                </Grid>

            </div>
        </div>
    );

    function setFilePondReference(theRef) {
        //setPondRef(theRef);
    }

    function handleFilesAreUploadingCallback(filesAreUploading) {
        //setFilesBeingUploaded(filesAreUploading);
    }

    function allFileUploadsCompleteCallback(allFileUIDs){
        const putValues = {
            'file_uid_list': allFileUIDs,
        };
        const putURL = getAdminPath(
            `deposits/${depositUID}/files/added/by/admin/for/user/${uploadingAccountUID}`
        );

        axiosInstance.put(putURL, putValues)
            .then(function (response) {
                props.resourceActionUpdateSingleResource(response.data.attached_storage_files, RESOURCE_TYPE_ATTACHED_STORAGE_FILES);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed to add file to deposit');
            })
    }

    function fileUploadCompleteCallback(fileUID) {


    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(null, mapActionsToProps)(AdminDepositFilePond);