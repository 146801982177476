import React from "react";

import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {faPencil} from "@fortawesome/pro-regular-svg-icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CancelButton from "../utilities/buttons/CancelButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {connect} from "react-redux";
import {allBankAccountsSelector} from "../../autoGenerated/selectors/resourceSelectors";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import DepositsAdminService from "../../api/depositsAdminService";
import {RESOURCE_TYPE_DEPOSIT} from "../../autoGenerated/constants/resourceTypeConstants";
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {resourceActionUpdateSingleResource} from "../../clientShared/actions/resourceActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {COMPLIANCE_ID_INFO} from "../../constants/complianceIDs";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

function constructInitialState(incomingDeposit, useCompleted) {
    let returnObject = {};

    if (incomingDeposit === undefined) {
        return returnObject;
    }

    Object.keys(COMPLIANCE_ID_INFO).map(complianceID => {
        returnObject[complianceID] = false;
    });

    const complianceList = useCompleted ? incomingDeposit.completed_compliances : incomingDeposit.disabled_compliances;

    complianceList.map(completedComplianceID => {
        returnObject[completedComplianceID] = true;
    });

    return returnObject;
}

//http://localhost:3000/deposits/7b596a7b62d7496087595afd3e070ba8

function EditEnabledDisabledComplianceDialog({deposit, completedMode, ...props}) {
    const classes = useStyles();

    const [showDialog, setShowDialog] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [allowSubmit, setAllowSubmit] = React.useState(false);

    const [complianceDict, setComplianceDict] = React.useState(constructInitialState(deposit, completedMode));

    const displayName = completedMode ? 'Completed' : 'Disabled';

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={handleOpen}
                variant={'outlined'}
                size={'small'}
            >
                <FontAwesomeIcon
                    icon={faPencil}
                    fixedWidth
                />
                &nbsp;Edit {displayName} Compliance
            </Button>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="fullscreen-deposit-dialog-title"
                fullWidth={true}
                maxWidth={'sm'}
                //fullScreen
            >
                <DialogTitle id="edit-funds-location-dialog-title">Edit {displayName} Compliance</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Editing compliance should be done with care and attention.<br/>
                        Changes here impact receipts, interest and other core deposit functionality.
                    </DialogContentText>

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        {
                            Object.keys(complianceDict).map(complianceID => {
                                return (
                                    <Grid item key={`comp-id-${complianceID}`}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={complianceDict[complianceID]}
                                                    onChange={handleChange}
                                                    name={complianceID}
                                                    color="primary"
                                                />
                                            }
                                            label={COMPLIANCE_ID_INFO[complianceID]}
                                        />
                                    </Grid>
                                );
                            })
                        }

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <CancelButton handleCloseFunction={handleClose} buttonText={'Close'}/>

                    <SpinnerButton
                        // Non-button props
                        showSpinner={showSpinner}
                        // Button props
                        variant="contained"
                        color="secondary"
                        disabled={showSpinner === true || allowSubmit === false}
                        onClick={handleChangeFundsLocation}
                    >
                        <FontAwesomeIcon
                            icon={faSkullCrossbones}
                            fixedWidth
                        />
                        &nbsp;Update Deposit Compliance
                    </SpinnerButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
        setShowSpinner(false);
    }

    function handleChange(event) {
        // setCurrentFundsLocationUID(event.target.value);
        // setState({ ...state, [name]: event.target.checked });

        let updatedCompliance = {...complianceDict};
        updatedCompliance[event.target.name] = event.target.checked;
        // console.log(updatedCompliance);
        setComplianceDict(updatedCompliance);

        setAllowSubmit(true);
    }

    function handleChangeFundsLocation() {
        setShowSpinner(true);

        let completedList = completedMode === true ? [] : deposit.completed_compliances;
        let disabledList = completedMode === false ? [] : deposit.disabled_compliances;
        let updateList = completedMode === true ? completedList : disabledList;
        for (const key of Object.keys(complianceDict)) {
            const currentCompBool = complianceDict[key];
            if (currentCompBool === true) {
                updateList.push(key);
            }
        }

        DepositsAdminService.AdminDepositsSetEnabledDisabledCompliances(
            deposit.deposit_uid,
            completedList,
            disabledList
        )
            .then(function (response) {
                handleClose();
                setTimeout(() => {
                    props.resourceActionUpdateSingleResource(response.deposit, RESOURCE_TYPE_DEPOSIT);
                }, 500);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }


}

const mapStateToProps = (state) => ({
    allBankAccounts: allBankAccountsSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(React.memo(EditEnabledDisabledComplianceDialog));

