/*

 */

import React from 'react';
import {useUserAccountBalanceLoader, useUserAccountLoader} from "../../autoGenerated/hooks/resourceLoadingHooks";
import Grid from "@material-ui/core/Grid";
import AccountProfileImage from "../../clientShared/components/profile/AccountProfileImage";
import Typography from "@material-ui/core/Typography";
import UserAccountEmailDisplay from "./UserAccountEmailDisplay";
import ClickToCopy from "../../clientShared/components/utility/ClickToCopy";
import {dollarFormattedString, uuidToHex} from "../../utilities/commonUtils";
import RenderTimestamp from "../../clientShared/utilities/RenderTimestamp";
import Divider from "@material-ui/core/Divider";
import UserAccountRolesDisplay from "../../clientShared/components/display/services/accounts/UserAccountRolesDisplay";
import RenderDeposifyTags, {
    DEPOSIFY_TAG_ID_ACCOUNT_HAS_LOANS,
    DEPOSIFY_TAG_ID_RHINO_DISABLE_EMAILS
} from "../tags/DeposifyTags";
import Alert from "@material-ui/lab/Alert";
import {
    USER_ACCOUNT_STATE_ACTIVE, USER_ACCOUNT_STATE_AWAITING_FINALIZATION,
    USER_ACCOUNT_STATE_BANNED
} from "../../autoGenerated/constants/services/UserAccountsServiceConstants";

const UserAccountProfilePicAndNameHeader = ({accountUID, userAccount}) => {
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
        >
            <Grid item>
                <AccountProfileImage accountUID={accountUID} showGradientBorder={false}/>
            </Grid>

            <Grid item>
                <Typography variant="h6">
                    <ClickToCopy dataToCopy={`${userAccount.first_name} ${userAccount.last_name}`}/>
                </Typography>
                <UserAccountEmailDisplay userAccount={userAccount}/>
            </Grid>

        </Grid>
    );
}

const UserAccountProfileQuickSummary = ({userAccount}) => {
    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid item>
                <Typography variant="button" display="block">
                    ACCOUNT
                </Typography>
            </Grid>

            <Grid item>
                <ClickToCopy dataToCopy={`${uuidToHex(userAccount.account_uid)}`}/>
            </Grid>

            <Grid item>
                <Typography variant="overline" display="block">
                    CREATED
                </Typography>
                <RenderTimestamp timestamp={userAccount.created_timestamp}/>
            </Grid>

        </Grid>
    );
}

const UserAccountBalanceDisplay = ({accountUID}) => {

    const {loading, error, userAccountBalance} = useUserAccountBalanceLoader(accountUID);

    if (loading === true || error === true) {
        return <React.Fragment>...</React.Fragment>;
    }

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid item>
                <Typography variant="button" display="block">
                    BALANCE
                </Typography>
                <ClickToCopy dataToCopy={dollarFormattedString(userAccountBalance.total_balance_in_cents)}/>
            </Grid>

            <Grid item>

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                >
                    <Grid item>
                        <Typography variant="button" display="block">
                            LOCKED
                        </Typography>
                        <ClickToCopy dataToCopy={dollarFormattedString(userAccountBalance.locked_balance_in_cents)}/>
                    </Grid>

                    <Grid item>
                        <Typography variant="button" display="block">
                            FREE
                        </Typography>
                        <ClickToCopy dataToCopy={dollarFormattedString(userAccountBalance.free_balance_in_cents)}/>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>
    );
}

const RenderUserAccountAlerts = ({userAccount}) => {

    const rhinoEmails = userAccount.deposify_tags.includes(DEPOSIFY_TAG_ID_RHINO_DISABLE_EMAILS) === true;

    // TODO: Quick and dirty, proper cleanup needed
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={1}
        >
            {/* Email verification */}
            {
                userAccount.email_verified === false && !rhinoEmails &&
                <Grid item>
                    <Alert severity="warning">This user has not verified their email</Alert>
                </Grid>
            }

            {/* Email verification */}
            {
                userAccount.state === USER_ACCOUNT_STATE_AWAITING_FINALIZATION &&
                <Grid item>
                    <Alert severity="error">Account Not Finalized</Alert>
                </Grid>
            }

            {/* Not Active */}
            {
                userAccount.state !== USER_ACCOUNT_STATE_ACTIVE && userAccount.state !== USER_ACCOUNT_STATE_AWAITING_FINALIZATION &&
                <Grid item>
                    <Alert severity="error">This user account is not active</Alert>
                </Grid>
            }

            {/* Banned */}
            {
                userAccount.state === USER_ACCOUNT_STATE_BANNED &&
                <Grid item>
                    <Alert severity="error">This user is banned</Alert>
                </Grid>
            }

            {/* Rhino Emaiks */}
            {
                rhinoEmails &&
                <Grid item>
                    <Alert severity="warning">Rhino User - no emails will be sent from Deposify</Alert>
                </Grid>
            }

            {/* Has borrowed funds */}
            {
                userAccount.deposify_tags.includes(DEPOSIFY_TAG_ID_ACCOUNT_HAS_LOANS) === true &&
                <Grid item>
                    <Alert severity="error">This user has borrowed funds</Alert>
                </Grid>
            }

            {/* Pending Email verification */}
            {
                userAccount.has_pending_email_change === true &&
                <Grid item>
                    <Alert severity="info">This user has a pending email change</Alert>
                </Grid>
            }

        </Grid>
    );
}


function UserAccountInfoSummary({accountUID}) {
    const {loading: accLoading, error: accError, userAccount} = useUserAccountLoader(accountUID);

    if (accLoading === true || accError === true) {
        return <b>Loading</b>;
    }

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid item>
                <UserAccountProfilePicAndNameHeader accountUID={accountUID} userAccount={userAccount}/>
            </Grid>

            <Divider orientation="horizontal" flexItem/>

            <Grid item>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                >
                    <Grid item>
                        <UserAccountProfileQuickSummary userAccount={userAccount}/>
                    </Grid>

                    <Divider orientation="vertical" flexItem/>

                    <Grid item>
                        <UserAccountBalanceDisplay accountUID={accountUID}/>
                    </Grid>

                    <Divider orientation="vertical" flexItem/>

                    <Grid item>
                        <Typography variant="button" display="block">
                            ROLES
                        </Typography>
                        <UserAccountRolesDisplay userAccount={userAccount}/>
                    </Grid>
                </Grid>

            </Grid>

            <Grid item>
                <Typography variant="button" display="block">
                    TAGS
                </Typography>
                <RenderDeposifyTags deposifyTags={userAccount.deposify_tags}/>
            </Grid>

            <Grid item>
                <RenderUserAccountAlerts userAccount={userAccount}/>
            </Grid>

        </Grid>
    );


}

export default UserAccountInfoSummary;
