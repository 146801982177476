/*
    App Actions
 */

import {
    APP_EVENT_ADD_EXPANDED_DEPOSIT_UID,
    APP_EVENT_ADD_SELECTED_DEPOSIT_UID,
    APP_EVENT_CLEAR_SHOW_DEPOSIT_DIALOG,
    APP_EVENT_CLEAR_SHOW_NEW_DEPOSIT_INVITE_DIALOG,
    APP_EVENT_HANDLE_INVOICE_UPDATE,
    APP_EVENT_REMOVE_EXPANDED_DEPOSIT_UID,
    APP_EVENT_REMOVE_SELECTED_DEPOSIT_UID,
    APP_EVENT_SET_ACCEPT_DEPOSIT_INVITE_DIALOG_DETAILS,
    APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_CLOSE_REQUEST_DIALOG_DETAILS,
    APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_DISPUTE_SPLIT_DIALOG_DETAILS,
    APP_EVENT_SET_ARCHIVE_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_CANCEL_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_CANCEL_WITHDRAWAL_DIALOG_DETAILS,
    APP_EVENT_SET_CLOSE_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_BANK_ACCOUNT_SELECTION_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_OR_EDIT_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_OR_EDIT_INTERNATIONAL_BANK_ACCOUNT_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_OR_EDIT_US_DOMESTIC_BANK_ACCOUNT_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_OR_EDIT_WITHDRAWAL_DIALOG_DETAILS,
    APP_EVENT_SET_DEPOSIT_FUNDS_DIALOG_DETAILS,
    APP_EVENT_SET_DEPOSIT_INVITE_DIALOG_DETAILS,
    APP_EVENT_SET_DEPOSIT_LISTING_HOVER_DEPOSIT,
    APP_EVENT_SET_DEPOSIT_LISTING_MODE,
    APP_EVENT_SET_DEPOSIT_LISTING_SHOW_EXTRA_CONTROLS,
    APP_EVENT_SET_DEPOSIT_SEARCH_RES_ANIM,
    APP_EVENT_SET_DEPOSIT_SEARCH_RESULTS,
    APP_EVENT_SET_DISPUTE_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_EDIT_BANK_ACCOUNT_DETAILS,
    APP_EVENT_SET_EDIT_DEPOSIT_UID,
    APP_EVENT_SET_EXPANDED_DEPOSIT_UIDS,
    APP_EVENT_SET_FULL_SCREEN_ACCOUNT_DEFAULT,
    APP_EVENT_SET_FULL_SCREEN_ATTACHED_FILES_DIALOG_DETAILS,
    APP_EVENT_SET_FULL_SCREEN_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_FULL_SCREEN_INT_BANK_DIALOG_DETAILS,
    APP_EVENT_SET_FULL_SCREEN_PROPERTY_DIALOG_DETAILS,
    APP_EVENT_SET_FULL_SCREEN_US_DOM_BANK_DIALOG_DETAILS,
    APP_EVENT_SET_FULLSCREEN_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_LAST_UPDATED_DEPOSIT_UID,
    APP_EVENT_SET_MIGRATED_ACCOUNTS,
    APP_EVENT_SET_MULTI_SELECT_DIALOG_MODE,
    APP_EVENT_SET_NEW_DEPOSIT_INVITE_DIALOG_DETAILS,
    APP_EVENT_SET_PROPOSE_DISPUTE_SPLIT_DIALOG_DETAILS,
    APP_EVENT_SET_REJECT_DEPOSIT_INVITE_DIALOG_DETAILS,
    APP_EVENT_SET_SEARCH_QUERY_INFO,
    APP_EVENT_SET_SELECTED_DEPOSIT_UIDS,
    APP_EVENT_SET_SHOW_CREATE_DEPOSIT_DIALOG,
    APP_EVENT_SET_SHOW_DEPOSIT_DIALOG,
    APP_EVENT_SET_SHOW_EDIT_DEPOSIT_DIALOG,
    APP_EVENT_SET_SHOW_MOBILE_SEARCH_CONTROLS,
    APP_EVENT_SET_SHOW_MULTI_SELECT_DIALOG,
    APP_EVENT_SET_WITHDRAW_DIALOG_DETAILS,
    APP_EVENT_SHOW_NEW_DEPOSIT_INVITE_DIALOG,
    APP_EVENT_TYPE_ACCOUNT_LOGGED_IN,
    APP_EVENT_TYPE_ACCOUNT_LOGGED_OUT,
    APP_EVENT_TYPE_CHANGE_PRIMARY_NAV_TAB,
    APP_EVENT_TYPE_SET_APP_IS_STARTING
} from "./appActionTypes";

import {_readCurrentUserAccount, readUserAccountSettings,} from "./userActions";
import {eventsActionSetStartupCheck} from "./eventsActions";
import {depositActionSetStartUpCheck} from "./depositActions";
import {axiosInstance} from "../utilities/axiosInstance";
import * as Sentry from "@sentry/browser";
import {adminActionLoadAllUIDS} from "./adminActions";
import {RESOURCE_TYPE_BANK_ACCOUNT, RESOURCE_TYPE_DEPOSIFY_TAG} from "../autoGenerated/constants/resourceTypeConstants";
import {RESOURCE_TYPE_INFO} from "../autoGenerated/constants/resourceTypeInfo";
import {loadingActionLoadResourceFromServer} from "../clientShared/actions/sharedLoadingActions";
import {resourceActionUpdateSingleResource} from "../clientShared/actions/resourceActions";
import {store} from "../store/appStore";

export function appActionSetAppIsStarting(appIsStarting) {
    return {
        type: APP_EVENT_TYPE_SET_APP_IS_STARTING,
        appIsStarting: appIsStarting
    }
}

export function appActionSetMigratedAccounts(migratedAccounts) {
    return {
        type: APP_EVENT_SET_MIGRATED_ACCOUNTS,
        migratedAccounts: migratedAccounts
    }
}

export function appActionSetFullScreenUSDomBankDetails(adminFullScreenUSDomBankDialogDetails) {
    return {
        type: APP_EVENT_SET_FULL_SCREEN_US_DOM_BANK_DIALOG_DETAILS,
        adminFullScreenUSDomBankDialogDetails: adminFullScreenUSDomBankDialogDetails
    }
}

export function appActionsSetFullScreenAttachedFilesDialogDetails(adminFullScreenAttachedFilesDialogDetails) {
    return {
        type: APP_EVENT_SET_FULL_SCREEN_ATTACHED_FILES_DIALOG_DETAILS,
        adminFullScreenAttachedFilesDialogDetails: adminFullScreenAttachedFilesDialogDetails
    }
}

export function appActionSetFullScreenDepositDetails(adminFullScreenDepositDialogDetails) {
    return {
        type: APP_EVENT_SET_FULL_SCREEN_DEPOSIT_DIALOG_DETAILS,
        adminFullScreenDepositDialogDetails: adminFullScreenDepositDialogDetails
    }
}

export function appActionSetFullScreenIntBankDetails(adminFullScreenIntBankDialogDetails) {
    return {
        type: APP_EVENT_SET_FULL_SCREEN_INT_BANK_DIALOG_DETAILS,
        adminFullScreenIntBankDialogDetails: adminFullScreenIntBankDialogDetails
    }
}

export function appActionSetFullScreenAccountDetails(adminFullScreenAccountDialogDetails) {
    return {
        type: APP_EVENT_SET_FULL_SCREEN_ACCOUNT_DEFAULT,
        adminFullScreenAccountDialogDetails: adminFullScreenAccountDialogDetails
    }
}

export function appActionSetFullScreenPropertyDialogDetails(adminFullScreenPropertyDialogDetails) {
    return {
        type: APP_EVENT_SET_FULL_SCREEN_PROPERTY_DIALOG_DETAILS,
        adminFullScreenPropertyDialogDetails: adminFullScreenPropertyDialogDetails
    }
}

export function changePrimaryNavTabAction(selected_tab) {
    return {
        type: APP_EVENT_TYPE_CHANGE_PRIMARY_NAV_TAB,
        selected_tab: selected_tab
    }
}

export function accountLoggedInAction() {
    return {
        type: APP_EVENT_TYPE_ACCOUNT_LOGGED_IN
    }
}

export function accountLoggedOutAction() {
    return {
        type: APP_EVENT_TYPE_ACCOUNT_LOGGED_OUT
    }
}

export function appActionShowDepositDialog(depositUID) {
    return {
        type: APP_EVENT_SET_SHOW_DEPOSIT_DIALOG,
        depositUID: depositUID
    }
}

export function appActionClearShowDepositDialog() {
    return {
        type: APP_EVENT_CLEAR_SHOW_DEPOSIT_DIALOG
    }
}


export function appActionShowNewDepositInviteDialog(depositUID) {
    return {
        type: APP_EVENT_SHOW_NEW_DEPOSIT_INVITE_DIALOG,
        depositUID: depositUID
    }
}

export function appActionClearShowNewDepositInviteDialog() {
    return {
        type: APP_EVENT_CLEAR_SHOW_NEW_DEPOSIT_INVITE_DIALOG
    }
}

export function appSetDepositSearchResults(deposit_search_results) {
    return {
        type: APP_EVENT_SET_DEPOSIT_SEARCH_RESULTS,
        deposit_search_results: deposit_search_results
    }
}

export function appActionSetDepositListingMode(depositListingMode) {
    return {
        type: APP_EVENT_SET_DEPOSIT_LISTING_MODE,
        depositListingMode: depositListingMode
    }
}

export function appActionSetSearchQueryInfo(searchQueryInfo) {
    return {
        type: APP_EVENT_SET_SEARCH_QUERY_INFO,
        searchQueryInfo: searchQueryInfo
    }
}

export function appActionSetDepositSearchResAnim(deposit_search_res_anim) {
    return {
        type: APP_EVENT_SET_DEPOSIT_SEARCH_RES_ANIM,
        deposit_search_res_anim: deposit_search_res_anim
    }
}

export function appActionSetSelectedDepositUIDs(selected_deposit_uids) {
    return {
        type: APP_EVENT_SET_SELECTED_DEPOSIT_UIDS,
        selected_deposit_uids: selected_deposit_uids
    }
}

export function appActionAddSelectedDepositUID(deposit_uid) {
    return {
        type: APP_EVENT_ADD_SELECTED_DEPOSIT_UID,
        deposit_uid: deposit_uid
    }
}

export function appActionRemoveSelectedDepositUID(deposit_uid) {
    return {
        type: APP_EVENT_REMOVE_SELECTED_DEPOSIT_UID,
        deposit_uid: deposit_uid
    }
}

export function appActionSetExpandedDepositUIDs(expanded_deposit_uids) {
    return {
        type: APP_EVENT_SET_EXPANDED_DEPOSIT_UIDS,
        expanded_deposit_uids: expanded_deposit_uids
    }
}

export function appActionAddExpandedDepositUID(deposit_uid) {
    return {
        type: APP_EVENT_ADD_EXPANDED_DEPOSIT_UID,
        deposit_uid: deposit_uid
    }
}

export function appActionRemoveExpandedDepositUID(deposit_uid) {
    return {
        type: APP_EVENT_REMOVE_EXPANDED_DEPOSIT_UID,
        deposit_uid: deposit_uid
    }
}

export function appActionSetDepositListingShowExtraControls(newValue) {
    return {
        type: APP_EVENT_SET_DEPOSIT_LISTING_SHOW_EXTRA_CONTROLS,
        newValue: newValue
    }
}

export function appActionSetLastUpdatedDepositUID(depositUID) {
    return {
        type: APP_EVENT_SET_LAST_UPDATED_DEPOSIT_UID,
        depositUID: depositUID
    }
}

export function appActionSetShowMultiSelectDialog(newValue) {
    return {
        type: APP_EVENT_SET_SHOW_MULTI_SELECT_DIALOG,
        newValue: newValue
    }
}

export function appActionSetMultiSelectDialogMode(newValue) {
    return {
        type: APP_EVENT_SET_MULTI_SELECT_DIALOG_MODE,
        newValue: newValue
    }
}

// TODO: Clean these up
export function appActionSetShowCreateDepositDialog(newValue) {
    return {
        type: APP_EVENT_SET_SHOW_CREATE_DEPOSIT_DIALOG,
        newValue: newValue
    }
}

export function appActionSetEditDepositUID(depositUID) {
    return {
        type: APP_EVENT_SET_EDIT_DEPOSIT_UID,
        depositUID: depositUID
    }
}


export function appActionSetShowEditDepositDialog(newValue) {
    return {
        type: APP_EVENT_SET_SHOW_EDIT_DEPOSIT_DIALOG,
        newValue: newValue
    }
}

export function appActionSetEditBankAccountDetails(updatedEditBankInfo) {
    return {
        type: APP_EVENT_SET_EDIT_BANK_ACCOUNT_DETAILS,
        updatedEditBankInfo: updatedEditBankInfo
    }
}

export function appActionSetWithdrawDialogDetails(updatedWithdrawDialogDetails) {
    return {
        type: APP_EVENT_SET_WITHDRAW_DIALOG_DETAILS,
        updatedWithdrawDialogDetails: updatedWithdrawDialogDetails
    }
}

export function appActionSetDepositInviteDialogDetails(updatedDepositInviteDialogDetails) {
    return {
        type: APP_EVENT_SET_DEPOSIT_INVITE_DIALOG_DETAILS,
        updatedDepositInviteDialogDetails: updatedDepositInviteDialogDetails
    }
}

export function appActionSetCancelDepositDialogDetails(updatedCancelDepositDialogDetails) {
    return {
        type: APP_EVENT_SET_CANCEL_DEPOSIT_DIALOG_DETAILS,
        updatedCancelDepositDialogDetails: updatedCancelDepositDialogDetails
    }
}

export function appActionSetRejectDepositInviteDialogDetails(updatedRejectDepositInviteDialogDetails) {
    return {
        type: APP_EVENT_SET_REJECT_DEPOSIT_INVITE_DIALOG_DETAILS,
        updatedRejectDepositInviteDialogDetails: updatedRejectDepositInviteDialogDetails
    }
}

export function appActionSetAcceptDepositInviteDialogDetails(updatedAcceptDepositInviteDialogDetails) {
    return {
        type: APP_EVENT_SET_ACCEPT_DEPOSIT_INVITE_DIALOG_DETAILS,
        updatedAcceptDepositInviteDialogDetails: updatedAcceptDepositInviteDialogDetails
    }
}

export function appActionSetDepositFundsDialogDetails(updatedDepositFundsDialogDetails) {
    return {
        type: APP_EVENT_SET_DEPOSIT_FUNDS_DIALOG_DETAILS,
        updatedDepositFundsDialogDetails: updatedDepositFundsDialogDetails
    }
}

export function appActionSetArchiveDepositDialogDetails(updatedArchiveDepositDialogDetails) {
    return {
        type: APP_EVENT_SET_ARCHIVE_DEPOSIT_DIALOG_DETAILS,
        updatedArchiveDepositDialogDetails: updatedArchiveDepositDialogDetails
    }
}

export function appActionSetCloseDepositDialogDetails(updatedCloseDepositDialogDetails) {
    return {
        type: APP_EVENT_SET_CLOSE_DEPOSIT_DIALOG_DETAILS,
        updatedCloseDepositDialogDetails: updatedCloseDepositDialogDetails
    }
}

export function appActionSetDisputeDepositDialogDetails(updatedDisputeDepositDialogDetails) {
    return {
        type: APP_EVENT_SET_DISPUTE_DEPOSIT_DIALOG_DETAILS,
        updatedDisputeDepositDialogDetails: updatedDisputeDepositDialogDetails
    }
}

export function appActionSetAcceptOrRejectDepositCloseRequestDialogDetails(updatedAcceptOrRejectDepositCloseRequestDialogDetails) {
    return {
        type: APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_CLOSE_REQUEST_DIALOG_DETAILS,
        updatedAcceptOrRejectDepositCloseRequestDialogDetails: updatedAcceptOrRejectDepositCloseRequestDialogDetails
    }
}

export function appActionSetAcceptOrRejectDisputeSplitDialogDetails(acceptOrRejectDisputeSplitDialogDetails) {
    return {
        type: APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_DISPUTE_SPLIT_DIALOG_DETAILS,
        acceptOrRejectDisputeSplitDialogDetails: acceptOrRejectDisputeSplitDialogDetails
    }
}

export function appActionSetProposeDisputeSplitDialogDetails(proposeDisputeSplitDialogDetails) {
    return {
        type: APP_EVENT_SET_PROPOSE_DISPUTE_SPLIT_DIALOG_DETAILS,
        proposeDisputeSplitDialogDetails: proposeDisputeSplitDialogDetails
    }
}

export function appActionsSetDepositListingHoverDepositUID(depositListingHoverDepositUID) {
    return {
        type: APP_EVENT_SET_DEPOSIT_LISTING_HOVER_DEPOSIT,
        depositListingHoverDepositUID: depositListingHoverDepositUID
    }
}

export function appActionsSetFullscreenDepositDialogDetails(fullscreenDepositDialogDetails) {
    return {
        type: APP_EVENT_SET_FULLSCREEN_DEPOSIT_DIALOG_DETAILS,
        fullscreenDepositDialogDetails: fullscreenDepositDialogDetails
    }
}

export function appActionsHandleInvoiceUID(depositUID) {
    return {
        type: APP_EVENT_HANDLE_INVOICE_UPDATE,
        depositUID: depositUID
    }
}


export function appActionSetShowMobileSearchControls(depositListingShowMobileSearchControls) {
    return {
        type: APP_EVENT_SET_SHOW_MOBILE_SEARCH_CONTROLS,
        depositListingShowMobileSearchControls: depositListingShowMobileSearchControls
    }
}

export function appActionSetCreateOrEditDialogDetails(createOrEditDepositDialogDetails) {
    return {
        type: APP_EVENT_SET_CREATE_OR_EDIT_DEPOSIT_DIALOG_DETAILS,
        createOrEditDepositDialogDetails: createOrEditDepositDialogDetails
    }
}

export function appActionSetCreateOrEditWithdrawalDialogDetails(createOrEditWithdrawalDialogDetails) {
    return {
        type: APP_EVENT_SET_CREATE_OR_EDIT_WITHDRAWAL_DIALOG_DETAILS,
        createOrEditWithdrawalDialogDetails: createOrEditWithdrawalDialogDetails
    }
}

export function appActionSetCreateOrEditUSDomesticBankAccountDialogDetails(createOrEditUSDomesticBankAccountDialogDetails) {
    return {
        type: APP_EVENT_SET_CREATE_OR_EDIT_US_DOMESTIC_BANK_ACCOUNT_DIALOG_DETAILS,
        createOrEditUSDomesticBankAccountDialogDetails: createOrEditUSDomesticBankAccountDialogDetails
    }
}

export function appActionSetCreateOrEditInternationalBankAccountDialogDetails(createOrEditInternationalBankAccountDialogDetails) {
    return {
        type: APP_EVENT_SET_CREATE_OR_EDIT_INTERNATIONAL_BANK_ACCOUNT_DIALOG_DETAILS,
        createOrEditInternationalBankAccountDialogDetails: createOrEditInternationalBankAccountDialogDetails
    }
}

export function appActionSetCreateBankAccountSelectionDialogDetails(createBankAccountSelectionDialogDetails) {
    return {
        type: APP_EVENT_SET_CREATE_BANK_ACCOUNT_SELECTION_DIALOG_DETAILS,
        createBankAccountSelectionDialogDetails: createBankAccountSelectionDialogDetails
    }
}

export function appActionSetCancelWithdrawalDialogDetails(cancelWithdrawalDialogDetails) {
    return {
        type: APP_EVENT_SET_CANCEL_WITHDRAWAL_DIALOG_DETAILS,
        cancelWithdrawalDialogDetails: cancelWithdrawalDialogDetails
    }
}

export function appActionSetNewDepositInviteDialogDetails(newDepositInviteDialogDetails) {
    return {
        type: APP_EVENT_SET_NEW_DEPOSIT_INVITE_DIALOG_DETAILS,
        newDepositInviteDialogDetails: newDepositInviteDialogDetails
    }
}

// TODO: If this fails, we should boot to login
export const appStartUp = () => {
    return dispatch => {
        // Reset the state
        dispatch(appActionSetAppIsStarting(true));

        let accountPromises = [
            _readCurrentUserAccount(dispatch),
            readUserAccountSettings(dispatch)
        ];

        Promise.all(accountPromises).then(function (promiseResults) {
            // TODO: Error checking
            // TODO: Are we sure order is always maintained here?
            const userAccount = promiseResults[0];
            //const userAccountSettings = promiseResults[1];

            // TODO: This needs to be cleaned up and fixed
            if (userAccount === undefined) {
                dispatch(appActionSetAppIsStarting(false));
                dispatch(eventsActionSetStartupCheck(true));
                dispatch(depositActionSetStartUpCheck(true));
                return;
            }

            // Kick off the other startup actions now that we have the user account
            // dispatch(authActionAppStartupAuthCheck());
            //dispatch(eventsStartUp());
            //dispatch(depositsStartUp(userAccount.account_uid, userAccountSettings));
            //dispatch(propertiesStartUp());

            // Keep our promises
            let promiseList = [];


            // Load all the bank account UIDs
            // const bankAccountResourceTypeInfo = RESOURCE_TYPE_INFO[RESOURCE_TYPE_BANK_ACCOUNT];
            // dispatch(adminActionLoadAllUIDS(RESOURCE_TYPE_BANK_ACCOUNT, bankAccountResourceTypeInfo.uidListLoadEndpoint));
            // promiseList.push(
            //     dispatch(adminActionLoadAllUIDS(RESOURCE_TYPE_BANK_ACCOUNT, bankAccountResourceTypeInfo.uidListLoadEndpoint))
            // );

            const deposifyTagResourceTypeInfo = RESOURCE_TYPE_INFO[RESOURCE_TYPE_DEPOSIFY_TAG];
            promiseList.push(
                dispatch(adminActionLoadAllUIDS(RESOURCE_TYPE_DEPOSIFY_TAG, deposifyTagResourceTypeInfo.uidListLoadEndpoint))
            );

            // Account settings
            //promiseList.push(readUserAccountSettings(dispatch));

            // Account balance
            //promiseList.push(readCurrentUserAccountBalance(dispatch));

            // International bank accounts
            // promiseList.push(readInternationalBankAccounts(
            //     0,
            //     STARTUP_BANK_ACCOUNT_READ_LIMIT,
            //     true,
            //     true,
            //     dispatch)
            // );
            //
            // // US Domestic bank accounts
            // promiseList.push(readUSDomesticBankAccounts(
            //     0,
            //     STARTUP_BANK_ACCOUNT_READ_LIMIT,
            //     true,
            //     true,
            //     dispatch
            // ));
            //
            // // Withdrawals
            // promiseList.push(readWithdrawals(
            //     0,
            //     STARTUP_WITHDRAWALS_READ_LIMIT,
            //     true,
            //     dispatch)
            // );

            // Once all the promises are resolved, clear the loading flag
            Promise.all(promiseList).then(values => {
                // Kick off the loading of all bank accounts
                dispatch(postStartupLoadAllBankAccounts());
                dispatch(postStartupLoadAllDeposifyTags());

                // App is in a started enough state to make go now
                dispatch(appActionSetAppIsStarting(false));
            });

            // dispatch(appActionSetAppIsStarting(false));

        });
    }
};

export const loadMigratedAccounts = () => {
    return dispatch => {
        axiosInstance.get('api/ornate/cabbage/accounts/migrate/all')
            .then(function (response) {
                dispatch(appActionSetMigratedAccounts(response.data.user_accounts));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const postStartupLoadAllBankAccounts = () => {
    return dispatch => {
        const bankAccountUIDList = store.getState().resources.resourceUIDs.bank_account;
        if (bankAccountUIDList !== undefined) {
            for (const currentIndex in bankAccountUIDList) {
                dispatch(loadingActionLoadResourceFromServer(RESOURCE_TYPE_BANK_ACCOUNT,
                    bankAccountUIDList[currentIndex],
                    resourceActionUpdateSingleResource,
                ))
            }
        }

    }
};

export const postStartupLoadAllDeposifyTags = () => {
    return dispatch => {
        const tagsUIDList = store.getState().resources.resourceUIDs.deposify_tags;
        if (tagsUIDList !== undefined) {
            for (const currentIndex in tagsUIDList) {
                console.log('Load tag startup', tagsUIDList[currentIndex]);
                dispatch(loadingActionLoadResourceFromServer(RESOURCE_TYPE_DEPOSIFY_TAG,
                    tagsUIDList[currentIndex],
                    resourceActionUpdateSingleResource,
                ))
            }
        }

    }
};