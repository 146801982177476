import React from 'react';

export default function IF({children, condition}) {
    /**
     * Usage:
     * <IF condition={condition}>
     *  <Greeter username={user.name} />
     * </IF>
     */
    if (condition) {
        // render children if the condition is truthy
        return children;
    }

    return null;
}
