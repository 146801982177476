/*

 */

import {createSelector} from 'reselect'
import {depositUIDSelector} from "./depositsSelectors";

const selectedDepositUIDsSelector = state => state.app.selected_deposit_uids;
export const expandedDepositUIDsSelector = state => state.app.expanded_deposit_uids;
export const depositListingModeSelector = state => state.app.deposit_listing_mode;

export const appIsStartingSelector = state => state.app.app_is_starting;

export const popupDepositUIDSelector = state => state.app.show_deposit_dialog_uid;
export const popupDepositInviteUIDSelector = state => state.app.show_new_invite_dialog_uid;

export const depositInviteDialogDetailsSelector = state => state.app.depositInviteDialogDetails;

export const cancelDepositDialogDetailsSelector = state => state.app.cancelDepositDialogDetails;
export const rejectDepositInviteDialogDetailsSelector = state => state.app.rejectDepositInviteDialogDetails;
export const acceptDepositInviteDialogDetailsSelector = state => state.app.acceptDepositInviteDialogDetails;

export const depositFundsDialogDetailsSelector = state => state.app.depositFundsDialogDetails;

export const archiveDepositDialogDetailsSelector = state => state.app.archiveDepositDialogDetails;
export const closeDepositDialogDetailsSelector = state => state.app.closeDepositDialogDetails;
export const disputeDepositDialogDetailsSelector = state => state.app.disputeDepositDialogDetails;

export const acceptOrRejectDepositCloseRequestDialogDetailsSelector = state => state.app.acceptOrRejectDepositCloseRequestDialogDetails;

export const acceptOrRejectDisputeSplitDialogDetailsSelector = state => state.app.acceptOrRejectDisputeSplitDialogDetails;

export const proposeDisputeSplitDialogDetailsSelector = state => state.app.proposeDisputeSplitDialogDetails;

export const depositListingHoverDepositUIDSelector = state => state.app.depositListingHoverDepositUID;

export const fullscreenDepositDialogDetailsSelector = state => state.app.fullscreenDepositDialogDetails;

export const depositSearchQueryInfoSelector = state => state.app.search_query_info;
export const depositsSearchResultsSelector =state => state.app.deposit_search_results;

export const depositListingShowExtraControlsSelector = state => state.app.depositListingShowExtraControls;

export const selectedNavTab = state => state.app.selected_primary_nav_tab;

export const lastUpdatedDepositUIDSelector = state => state.app.lastUpdatedDepositUID;

export const notificationsSelector = state => state.app.notifications;

export const depositListingShowMobileSearchControlsSelector = state => state.app.depositListingShowMobileSearchControls;

export const createOrEditDepositDialogDetailsSelector = state => state.app.createOrEditDepositDialogDetails;

export const createOrEditWithdrawalDialogDetailsSelector = state => state.app.createOrEditWithdrawalDialogDetails;

export const createOrEditUSDomesticBankAccountDialogDetailsSelector = state => state.app.createOrEditUSDomesticBankAccountDialogDetails;
export const createOrEditInternationalBankAccountDialogDetailsSelector = state => state.app.createOrEditInternationalBankAccountDialogDetails;
export const createBankAccountSelectionDialogDetailsSelector = state => state.app.createBankAccountSelectionDialogDetails;

export const cancelWithdrawalDialogDetailsSelector = state => state.app.cancelWithdrawalDialogDetails;

export const newDepositInviteDialogDetailsSelector = state => state.app.newDepositInviteDialogDetails;

export const allSelectedDepositUIDs = createSelector(
    [selectedDepositUIDsSelector],
    (depositUIDs) => depositUIDs
);

export const makeIsSelectedSelector = () => createSelector(
    [selectedDepositUIDsSelector, depositUIDSelector],
    (depositUIDList, depositUID) => depositUIDList.includes(depositUID)
);

export const makeIsExpandedSelector = () => createSelector(
    [expandedDepositUIDsSelector, depositUIDSelector],
    (depositUIDList, depositUID) => depositUIDList.includes(depositUID)
);

export const makeIsHoveringOverSelector = () => createSelector(
    [depositListingHoverDepositUIDSelector, depositUIDSelector],
    (depositListingHoverDepositUID, depositUID) => depositListingHoverDepositUID === depositUID
);

export const adminFullScreenAccountDialogDetailsSelector = state => state.app.adminFullScreenAccountDialogDetails;
export const adminFullScreenPropertyDialogDetailsSelector = state => state.app.adminFullScreenPropertyDialogDetails;
export const adminFullScreenUSDomBankDialogDetailsSelector = state => state.app.adminFullScreenUSDomBankDialogDetails;
export const adminFullScreenIntBankDialogDetailsSelector = state => state.app.adminFullScreenIntBankDialogDetails;
export const adminFullScreenDepositDialogDetailsSelector = state => state.app.adminFullScreenDepositDialogDetails;
export const adminFullScreenAttachedFilesDialogDetailsSelector = state => state.app.adminFullScreenAttachedFilesDialogDetails;
