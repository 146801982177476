import React from 'react';
import * as Sentry from "@sentry/browser";
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import {faSearch} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getAdminPath} from "../../../api/adminAPI";
import {axiosInstance} from "../../../utilities/axiosInstance";
import {
    ALL_SEARCHABLE_INDICES,
    SEARCH_INDEX_DISPLAY_NAMES,
    SEARCH_INDEX_NAME_ALL,
    SEARCH_INDEX_NAME_USER_ACCOUNTS
} from "../../../constants/searchConstants";
import {adminActionSetSearchInfo, adminActionSetSearchResults} from "../../../actions/adminActions";
import {adminSearchInfoSelector, adminSearchResultsSelector} from "../../../selectors/adminSelectors";
import Collapse from "@material-ui/core/Collapse";
import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import SearchBarResults from "./SearchBarResults";
import {ADMIN_SEARCH_MAX_NUM_RESULTS} from "../../../constants/apiConstants";
import toast from "react-hot-toast";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 600,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    resultsRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#36414e',
        margin: 10
    },
    resultsWrapper: {
        margin: 10
    }
}));


function SearchBar(props) {
    const classes = useStyles();

    const [searchIndex, setSearchIndex] = React.useState(SEARCH_INDEX_NAME_USER_ACCOUNTS);
    const [searchString, setSearchString] = React.useState('');

    const handleChange = event => {
        setSearchIndex(event.target.value);
    };

    const handleSearchStringChange = event => {
        setSearchString(event.target.value);
    };

    const _handleKeyDown = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };


    return (
        <React.Fragment>
            <Paper component="form" className={classes.root}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchIndex}
                    onChange={handleChange}
                >
                    {/*<MenuItem value={SEARCH_INDEX_NAME_ALL}>All</MenuItem>*/}
                    {/*<MenuItem value={SEARCH_INDEX_NAME_DEPOSITS}>Deposits</MenuItem>*/}
                    {/*<MenuItem value={SEARCH_INDEX_NAME_DEPOSIT_INVITES}>Invites</MenuItem>*/}
                    {/*<MenuItem value={SEARCH_INDEX_NAME_USER_ACCOUNTS}>Accounts</MenuItem>*/}
                    {
                        Object.keys(SEARCH_INDEX_DISPLAY_NAMES).map((name, index) => {
                            return <MenuItem value={name} key={`search-menu-item-${index}`}>{SEARCH_INDEX_DISPLAY_NAMES[name]}</MenuItem>
                        })
                    }
                </Select>
                <InputBase
                    className={classes.input}
                    placeholder="Search"
                    inputProps={{'aria-label': 'search'}}
                    onChange={handleSearchStringChange}
                    onKeyDown={_handleKeyDown}
                />
                <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={handleSubmit}
                >
                    <FontAwesomeIcon icon={faSearch} fixedWidth/>
                </IconButton>
            </Paper>

            <Collapse in={props.adminSearchInfo.showSearchResults}>
                <SearchBarResults/>
            </Collapse>

        </React.Fragment>
    );

    function handleSubmit() {

        if (searchString.length === 0) {
            return;
        }

        let search_indices = [];
        if (searchIndex === SEARCH_INDEX_NAME_ALL) {
            search_indices = ALL_SEARCHABLE_INDICES;
        } else {
            search_indices = [searchIndex];
        }

        const searchURL = getAdminPath(`search/general`);
        const searchValues = {
            'search_string': `*${searchString.trim()}*`,
            'account_uid': NULL_UUID_STRING,
            'organization_uids': [],
            'search_indices': search_indices,
            'max_num_results': ADMIN_SEARCH_MAX_NUM_RESULTS,
            'search_results_offset': 0
        };

        return axiosInstance.put(searchURL, searchValues)
            .then(function (response) {

                props.adminActionSetSearchInfo(
                    {
                        searchQuery: searchString,
                        searchIndex: searchIndex,
                        showSearchResults: true
                    }
                );

                props.adminActionSetSearchResults(response.data.search_results);

            })
            .catch(function (error) {
                // Tell Sentry about the error
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    scope.setExtra("searchURL", searchURL);
                    scope.setExtra("searchValues", searchValues);
                    Sentry.captureException(error);
                });

                toast.error('Failed to search');

            })
    }
}

const mapStateToProps = (state) => ({
    adminSearchInfo: adminSearchInfoSelector(state),
    adminSearchResults: adminSearchResultsSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionSetSearchInfo: adminActionSetSearchInfo,
        adminActionSetSearchResults: adminActionSetSearchResults
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(SearchBar);
