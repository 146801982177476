/*
    Dev
 */

import React from "react";
import {
    USER_ACCOUNT_STATE_AWAITING_FINALIZATION
} from "../../autoGenerated/constants/services/UserAccountsServiceConstants";
import AdminFinalizeInfo from "./AdminFinalizeInfo";
import IF from "../../clientShared/components/utility/IFComponent";
import AdminChangeAccountEmail from "./AdminChangeAccountEmail";
import Grid from "@material-ui/core/Grid";
import AdminChangeAccountPassword from "./AdminChangeAccountPassword";
import AdminVerificationInfo from "./AdminVerificationInfo";
import AdminChangeAccountRoles from "./AdminChangeAccountRoles";

/**
 * resent verification
 * change email
 * change password
 * extend finalization
 */

function UserAccountAdmin({userAccount}) {

    if(!userAccount){
        return <>...</>;
    }

    const notFinalized = userAccount.state === USER_ACCOUNT_STATE_AWAITING_FINALIZATION;
    const emailNotVerified = !notFinalized && userAccount.email_verified === false;

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            style={{margin: 12}}
            // spacing={4}
        >
            <IF condition={notFinalized}>
                <Grid item>
                    <AdminFinalizeInfo userAccount={userAccount}/>
                </Grid>

                <Grid item>&nbsp;</Grid>
            </IF>

            <IF condition={emailNotVerified}>
                <Grid item>
                    <AdminVerificationInfo userAccount={userAccount}/>
                </Grid>

                <Grid item>&nbsp;</Grid>
            </IF>

            <Grid item>
                <AdminChangeAccountEmail userAccount={userAccount}/>
            </Grid>

            <Grid item>&nbsp;</Grid>

            <Grid item>
                <AdminChangeAccountPassword userAccount={userAccount}/>
            </Grid>

            <Grid item>&nbsp;</Grid>

            <Grid item>
                <AdminChangeAccountRoles userAccount={userAccount}/>
            </Grid>

        </Grid>
    );


}


export default UserAccountAdmin;

