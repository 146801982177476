/*

 */

import React from 'react';
import ClickToCopy from "../../../../clientShared/components/utility/ClickToCopy";


function CopyTextDataLink({dataToCopy, linkText = 'Copy', ...props}) {

    return <ClickToCopy dataToCopy={dataToCopy} displayData={linkText} linkStyle={true}/>;

    // return (
    //     <CopyToClipboard
    //         text={`${dataToCopy}`}
    //     >
    //         <Tooltip
    //             title={'Copy to clipboard'}
    //             placement={'top'}
    //         >
    //             <span>
    //             <Link
    //                 component="button"
    //                 variant="body2"
    //                 onClick={handleClick}
    //             >
    //                 {linkText}
    //             </Link>
    //                 </span>
    //         </Tooltip>
    //     </CopyToClipboard>
    // );
    //
    // function handleClick() {
    //     props.appActionAddInfoNotification('Copied to clipboard');
    // }
}


// const mapActionsToProps = (dispatch, props) => {
//     return bindActionCreators({
//         appActionAddInfoNotification: appActionAddInfoNotification
//     }, dispatch);
// };

// export default connect(null, mapActionsToProps)(CopyTextDataLink);
export default CopyTextDataLink;
