import React, {useRef, useState} from 'react';
import {ClickAwayListener, makeStyles, Popover} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ClickToCopy from "../../../../clientShared/components/utility/ClickToCopy";
import Grid from "@material-ui/core/Grid";
import {faInfoCircle} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IF from "../../../../clientShared/components/utility/IFComponent";

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: "none"
    },
    popoverContent: {
        pointerEvents: "auto"
    },
    contents: {
        margin: 18,
        marginRight: 32,
        maxWidth: 400
    },
}));

export const FundsLocationQuestionMark = () => {
    return (
        <FontAwesomeIcon icon={faInfoCircle} fixedWidth/>
    );
}


export const FundsLocationPop = ({
                                     title, description, dataArray, children = null, displayText = null
                                 }) => {
    if(!children && !displayText)
        throw new Error("Must supply children, or display text")

    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);

    const classes = useStyles();
    const bottomMargin = dataArray.length === 0 ? 0: 18;

    const popoverEnter = ({currentTarget}) => {
        setOpenedPopover(true);
    };

    const popoverLeave = ({currentTarget}) => {
        setOpenedPopover(false);
    };

    const handleClick = (clickEvent) => {
        clickEvent.preventDefault();
        clickEvent.stopPropagation();
        if (openedPopover) {
            popoverLeave(clickEvent.currentTarget);
        } else {
            popoverEnter(clickEvent.currentTarget);
        }
    }

    return (
        <>
            <ClickAwayListener onClickAway={popoverLeave}>
                <span
                    ref={popoverAnchor}
                    onClick={handleClick}
                    onMouseEnter={popoverEnter}
                    onMouseLeave={popoverLeave}
                >
                    <IF condition={children}>
                        {children}
                    </IF>
                    <IF condition={!children}>
                        {displayText} <FundsLocationQuestionMark/>
                    </IF>
                </span>
            </ClickAwayListener>

            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent
                }}
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{onMouseEnter: popoverEnter, onMouseLeave: popoverLeave}}
                disableScrollLock
            >
                <div className={classes.contents}>
                    <Typography variant={"h6"} gutterBottom align={"center"}>
                        {title}
                    </Typography>

                    <Typography variant={"body2"} gutterBottom style={{fontWeight: 300, marginBottom: bottomMargin}}>
                        {description}
                    </Typography>

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        spacing={2}
                    >
                        {
                            dataArray.map((currentData, dataIndex) => {
                                return (
                                    <Grid item key={`funds-info-${dataIndex}`}>
                                        <ClickToCopy dataToCopy={currentData}/>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>

                </div>
            </Popover>

        </>
    );
};

