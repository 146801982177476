/*
    Payment Cleared / Interest controls
 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import FrontendAdminService from "../../api/FrontendAdminService";
import ConfirmationDialog from "../utilities/dialogs/ConfirmationDialog";
import {resourceActionRemoveSingleResource} from "../../clientShared/actions/resourceActions";
import {RESOURCE_TYPE_ONLINE_USER} from "../../autoGenerated/constants/resourceTypeConstants";
import toast from "react-hot-toast";


function LogOnlineUserOutButton({onlineUserUID, ...props}) {

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    return (
        <React.Fragment>
            <ConfirmationDialog
                startOpen={showConfirm}
                dangerMode={true}
                confirmCallback={acceptConfirm}
                cancelCallback={cancelConfirm}
                dialogTitle={'Are you sure?'}
                dialogText={`Are you sure you sure you want to log this user out of the system?`}
                confirmText={`Yes`}
                showSpinner={showSpinner}
                cancelFocus={true}
            />
            <Button
                color="secondary"
                onClick={handleButtonClick}
                variant={'contained'}
            >
                <FontAwesomeIcon
                    icon={faSkullCrossbones}
                    fixedWidth
                />
                &nbsp;Log User Out
            </Button>
        </React.Fragment>
    );

    function handleButtonClick() {
        setShowSpinner(false);
        setShowConfirm(true);
    }

    function acceptConfirm() {
        setShowSpinner(true);
        FrontendAdminService.LogOnlineUserOut(onlineUserUID)
            .then(function (response) {
                props.resourceActionRemoveSingleResource(RESOURCE_TYPE_ONLINE_USER, onlineUserUID, true);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
                setShowSpinner(false);
                toast.error('Failed to log user out');
            })
    }

    function cancelConfirm() {
        setShowConfirm(false);
    }


}


const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionRemoveSingleResource: resourceActionRemoveSingleResource,
    }, dispatch);
};

export default connect(null, mapActionsToProps)(LogOnlineUserOutButton);
