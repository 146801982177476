import React from "react";

import Grid from "@material-ui/core/Grid";
import GenericObjectDisplay from "../../clientShared/components/display/GenericObjectDisplay";
import BankingAdminButtonToggleInterestEnabled from "./BankingAdminButtonToggleInterestEnabled";


export function BankAccountDisplay(props) {

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >

            <Grid item>
                <GenericObjectDisplay {...props}/>
            </Grid>

            <Grid item>
                <BankingAdminButtonToggleInterestEnabled bankAccount={props.bankAccount}/>
            </Grid>


        </Grid>
    );
}

