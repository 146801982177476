/*
    Auth Constants
 */

export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE';


export const LOGIN_STATE_LOGIN_DETAILS_INCORRECT = 'login_details_incorrect';
export const LOGIN_STATE_LOGIN_COMPLETE = 'login_complete';
export const LOGIN_STATE_EMAIL_NOT_VERIFIED = 'email_not_verified';
export const LOGIN_STATE_ACCOUNT_BANNED = 'account_banned';

export const SECONDS_LIMIT_CHECK_FOR_STARTUP_ACCESS_TOKEN = 60;