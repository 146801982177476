

export const EMAIL_TEMPLATE_HTML = "<!DOCTYPE html PUBLIC \"-//W3C//DTD XHTML 1.0 Transitional//EN\" \"http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd\">\n" +
    "<html>\n" +
    "    <head>\n" +
    "        <meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\">\n" +
    "        \n" +
    "        <style type=\"text/css\">\n" +
    "            @import url('https://fonts.googleapis.com/css?family=Raleway:regular,light,bold,medium');\n" +
    "        </style>\n" +
    "        <title>Deposify Email</title>\n" +
    "        <link href=\"https://fonts.googleapis.com/css?family=Raleway:regular,light,bold,medium\" rel=\"stylesheet\">\n" +
    "    </head>\n" +
    "    <body leftmargin=\"0\" marginwidth=\"0\" topmargin=\"0\" marginheight=\"0\" offset=\"0\" style=\"-webkit-text-size-adjust: none;margin: 0;padding: 0;font-family: 'Raleway', sans-serif;font-size: 18px;line-height: 1.72222;background-color: #FAFAFA;width: 100% !important;\">\n" +
    "<!--        <br/>-->\n" +
    "        <center>\n" +
    "            <table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" height=\"100%\" width=\"100%\" id=\"logoTable\" style=\"margin: 0;padding: 0;background-color: #FAFAFA;height: 100% !important;width: 100% !important;\">\n" +
    "                <tr>\n" +
    "                    <td align=\"center\" valign=\"top\" style=\"border-collapse: collapse;\">\n" +
    "                        <img src=\"https://deposify-public-cdn.s3.us-east-2.amazonaws.com/emails/images/deposify_logo_email_header_small.jpg\" style=\"max-width: 180px;margin: 10px 0 0 10px;border: 0;height: 47px;line-height: 100%;outline: none;text-decoration: none;\" id=\"headerImage campaign-icon\" alt=\"Deposify logo\">\n" +
    "                    </td>\n" +
    "                </tr>\n" +
    "            </table>\n" +
    "<!--            <br/>-->\n" +
    "            <table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" height=\"100%\" width=\"100%\" id=\"backgroundTable\" style=\"margin: 0;padding: 0;background-color: #FAFAFA;height: 100% !important;width: 100% !important;\">\n" +
    "                <tr>\n" +
    "                    <td align=\"center\" valign=\"top\" style=\"border-collapse: collapse;\">\n" +
    "                            <table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"600\" id=\"templateContainer\" style=\"border: 1px solid #DDDDDD;background-color: #FFFFFF;\">\n" +
    "                            <tr>\n" +
    "                                <td align=\"center\" valign=\"top\" style=\"border-collapse: collapse;\">\n" +
    "                                    <!-- // Begin Template Header \\\\ -->\n" +
    "                                    <table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"600\" id=\"templateHeader\" style=\"background-color: #FFFFFF;border-bottom: 0;\">\n" +
    "                                        <tr>\n" +
    "                                            <td class=\"headerContent\" style=\"border-collapse: collapse;color: #202020; font-family: Arial;font-size: 34px;font-weight: bold;line-height: 100%;padding: 0;text-align: center;vertical-align: middle;\">\n" +
    "                                                <!-- // Begin Module: Standard Header Image \\\\ -->\n" +
    "<!--                                                <img src=\"https://deposify.com/wp-content/uploads/2016/10/Deposify-logo.jpg\" style=\"max-width: 180px;float:left; margin: 10px 0 0 10px;border: 0;height: auto;line-height: 100%;outline: none;text-decoration: none;\" id=\"headerImage campaign-icon\" alt=\"Deposify logo\">-->\n" +
    "                                                <!-- // End Module: Standard Header Image \\\\ -->\n" +
    "                                            </td>\n" +
    "                                        </tr>\n" +
    "                                    </table>\n" +
    "                                    <!-- // End Template Header \\\\ -->\n" +
    "                                </td>\n" +
    "                            </tr>\n" +
    "                            <tr>\n" +
    "                                <td align=\"center\" valign=\"top\" style=\"border-collapse: collapse;\">\n" +
    "                                    <!-- // Begin Template Body \\\\ -->\n" +
    "                                    <table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"600\" id=\"templateBody\">\n" +
    "                                        <tr>\n" +
    "                                            <td valign=\"top\" class=\"bodyContent\" style=\"border-collapse: collapse;background-color: #FFFFFF;\">\n" +
    "                                                <!-- // Begin Module: Standard Content \\\\ -->\n" +
    "                                                <table border=\"0\" cellpadding=\"20\" cellspacing=\"0\" width=\"100%\">\n" +
    "                                                    <tr>\n" +
    "                                                        <td valign=\"top\" style=\"border-collapse: collapse; padding: 4px 20px 26px 20px;\">\n" +
    "                                                            <div class=\"body_font\" style=\"color: #505050;font-family: 'Raleway', sans-serif!important;font-size: 14px;line-height: 150%;text-align: left;\">\n" +
    "                                                                <br/>\n" +
    "                                                                {{ email_body|safe }}\n" +
    "                                                            </div>\n" +
    "                                                        </td>\n" +
    "                                                    </tr>\n" +
    "                                                </table>\n" +
    "                                                <!-- // End Module: Standard Content \\\\ -->\n" +
    "                                            </td>\n" +
    "                                        </tr>\n" +
    "                                    </table>\n" +
    "                                    <!-- // End Template Body \\\\ -->\n" +
    "                                </td>\n" +
    "                            </tr>\n" +
    "                            <tr>\n" +
    "                                <td align=\"center\" valign=\"top\" style=\"border-collapse: collapse;\">\n" +
    "                                    <!-- // Begin Template Footer \\\\ -->\n" +
    "                                    <table border=\"0\" cellpadding=\"10\" cellspacing=\"0\" width=\"600\" id=\"templateFooter\" style=\"background-color: #FFFFFF;border-top: 0;\">\n" +
    "                                        <tr>\n" +
    "                                            <td style=\"background: -webkit-linear-gradient(left top, #457bf9 20%, #13f0fc 100%);padding: 1px;\"></td>\n" +
    "                                        </tr>\n" +
    "                                        <tr>\n" +
    "                                            <td valign=\"top\" class=\"footerContent\" style=\"border-collapse: collapse; background-color: #FAFAFA;\">\n" +
    "                                            \n" +
    "                                                <!-- // Begin Module: Standard Footer \\\\ -->\n" +
    "                                                <table border=\"0\" cellpadding=\"10\" cellspacing=\"0\" width=\"100%\">\n" +
    "                                                    <tr>\n" +
    "                                                        <td colspan=\"2\" valign=\"middle\" id=\"\" style=\"border-collapse: collapse;\">\n" +
    "                                                            <div style=\"color: #707070;font-family: 'Raleway', sans-serif!important;font-size: 10px;line-height: 125%;text-align: left;\">\n" +
    "                                                                <p>Please do not reply to this email address. Should you ever need any help, please do not hesitate to contact us at <a href=\"mailto:support@deposify.com\" style=\"color: #336699;font-weight: normal;text-decoration: underline;\"> support@deposify.com </a> or <a href=\"tel:6179630430\" style=\"color: #336699;font-weight: normal;text-decoration: underline;\"> 617.963.0430</a></p>\n" +
    "                                                                <p>Deposify | <span>745</span> <span>Atlantic</span> <span>Avenue,</span> <span>Boston,</span> <span>MA 02111</span></p>\n" +
    "                                                            </div>\n" +
    "                                                        </td>\n" +
    "                                                    </tr>\n" +
    "                                                </table>\n" +
    "                                                <!-- // End Module: Standard Footer \\\\ -->\n" +
    "                                            </td>\n" +
    "                                        </tr>\n" +
    "                                    </table>\n" +
    "                                    <!-- // End Template Footer \\\\ -->\n" +
    "                                </td>\n" +
    "                            </tr>\n" +
    "                        </table>\n" +
    "                        <br>\n" +
    "                    </td>\n" +
    "                </tr>\n" +
    "            </table>\n" +
    "        </center>\n" +
    "    </body>\n" +
    "</html>";