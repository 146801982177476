/**
 * Rhino search for property by ID
 */
import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {useDebounce} from "@umijs/hooks";
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import toast from "react-hot-toast";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";


const RhinoPropertySearchByIDField = ({uidListCallback}) => {
    const [searchCol, setSearchCol] = React.useState('unit');
    const [rawSearchValue, setRawSearchValue] = useState("");
    const finalUserSearchString = useDebounce(rawSearchValue, 300);

    useEffect(() => {
        if (finalUserSearchString === "") {
            uidListCallback([]);
        } else {
            if (!Number.isInteger(Number(finalUserSearchString))) {
                toast.error("Not a valid ID");
                return;
            }

            const callURL = getAdminPath(`jeff/properties/read/by/rhino/id`);
            const searchPayload = {search_id: finalUserSearchString, search_col: searchCol};
            axiosInstance.put(callURL, searchPayload)
                .then(function (response) {
                    uidListCallback(response.data.uid_list);
                })
                .catch(function (error) {
                    toast.error('Failed to search');
                })
        }
    }, [finalUserSearchString, searchCol])

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">ID Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={searchCol}
                        onChange={handleColChange}
                    >
                        <MenuItem value={"property"}>Property ID</MenuItem>
                        <MenuItem value={"unit"}>Unit ID</MenuItem>
                        <MenuItem value={"partner"}>Partner ID</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <TextField
                    id="standard-basic"
                    label="Rhino ID Search"
                    onChange={handleChange}
                    fullWidth={true}
                />
            </Grid>
        </Grid>
    );

    function handleChange(changeEvent) {
        setRawSearchValue(changeEvent.target.value);
    }

    function handleColChange(event) {
        setSearchCol(event.target.value);
    }
}

export default RhinoPropertySearchByIDField;