/*

 */
import React from 'react';
import {createMuiTheme} from "@material-ui/core/styles";


export const APP_THEME = createMuiTheme({
    "palette": {
        "common": {
            "black": "#000",
            "white": "#fff"
        },
        "background": {
            "paper": "rgba(51, 52, 54, 1)",
            "default": "rgba(51, 52, 54, 1)"
        },
        "primary": {
            "light": "rgba(92, 206, 255, 1)",
            "main": "rgba(41, 171, 226, 1)",
            "dark": "rgba(19, 102, 138, 1)",
            "contrastText": "#fff"
        },
        "secondary": {
            "light": "rgba(255, 165, 149, 1)",
            "main": "rgba(233, 118, 98, 1)",
            "dark": "rgba(187, 88, 72, 1)",
            "contrastText": "#fff"
        },
        "error": {
            "light": "#e57373",
            "main": "#f44336",
            "dark": "#d32f2f",
            "contrastText": "#fff"
        },
        "text": {
            "primary": "rgba(233, 233, 233, 0.87)",
            "secondary": "rgba(173, 173, 173, 0.54)",
            "disabled": "rgba(71, 71, 71, 0.38)",
            "hint": "rgba(35,35,35,0.38)"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1em"
            }
        },
        MuiCard:{
            // This sticks the card actions to the bottom of the card
            root: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }
        }
    }
});
