/*
    Events Actions
 */

import * as Sentry from "@sentry/browser";

import {
    API_ROUTE_DELETE_ACTION_ITEM,
    API_ROUTE_READ_ACTION_ITEMS
} from "../constants/apiRouteConstants";
import {axiosInstance} from "../utilities/axiosInstance";
import {
    EVENTS_ACTION_ITEM_KEEP_READING_DEFAULT,
    EVENTS_ACTION_ITEM_READ_LIMIT_DEFAULT, EVENTS_ACTION_ITEM_READ_OFFSET_DEFAULT
} from "../constants/eventConstants";


// Action types
export const UPDATE_ACTION_ITEMS = 'EVENTS:UPDATE_ACTION_ITEMS';
export const DELETE_ACTION_ITEM = 'EVENTS:DELETE_ACTION_ITEM';
export const REPLACE_ACTION_ITEMS = 'EVENTS:REPLACE_ACTION_ITEMS';
export const EVENTS_ACTION_SET_STARTUP_CHECK = 'EVENTS:SET_STARTUP_CHECK';

// Events
export function eventsActionSetStartupCheck(startUpEventsCheckComplete) {
    return {
        type: EVENTS_ACTION_SET_STARTUP_CHECK,
        startUpEventsCheckComplete: startUpEventsCheckComplete
    }
}

export function updateActionItems(actionItems) {
    return {
        type: UPDATE_ACTION_ITEMS,
        actionItems: actionItems
    }
}

export function replaceActionItems(actionItems) {
    return {
        type: REPLACE_ACTION_ITEMS,
        actionItems: actionItems
    }
}

export function removeActionItem(itemUID) {
    return {
        type: DELETE_ACTION_ITEM,
        itemUID: itemUID
    }
}

function _readActionItems(offset, limit, keepReading, dispatch) {
    const get_route = `${API_ROUTE_READ_ACTION_ITEMS}/${offset}/${limit}/True`;
    return axiosInstance.get(get_route)
        .then(function (response) {
            dispatch(updateActionItems(response.data.action_items));

            if (keepReading) {
                if (response.data.action_items.length >= limit) {
                    dispatch(readActionItems(offset + limit, limit, true));
                }
            }

        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
        })
}

export const readActionItems = (offset = EVENTS_ACTION_ITEM_READ_OFFSET_DEFAULT,
                                limit = EVENTS_ACTION_ITEM_READ_LIMIT_DEFAULT,
                                keepReading = EVENTS_ACTION_ITEM_KEEP_READING_DEFAULT) => {
    return dispatch => {
        _readActionItems(offset, limit, keepReading, dispatch);
    }
};

export const replaceActionItemsAction = () => {
    return dispatch => {
        // TODO: Add limit checking here
        const get_route = API_ROUTE_READ_ACTION_ITEMS + '/0/100/True';
        axiosInstance.get(get_route)
            .then(function (response) {
                dispatch(replaceActionItems(response.data.action_items));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const deleteActionItem = (itemUID) => {
    return dispatch => {
        const deleteRoute = API_ROUTE_DELETE_ACTION_ITEM + '/' + itemUID + '/delete';
        axiosInstance.delete(deleteRoute)
            .then(function (response) {
                dispatch(removeActionItem(itemUID));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};


export const eventsStartUp = () => {
    return dispatch => {
        // Reset
        dispatch(eventsActionSetStartupCheck(false));

        // Read the first few action items and wait for it to complete - any others will load in the background
        _readActionItems(
            EVENTS_ACTION_ITEM_READ_OFFSET_DEFAULT,
            EVENTS_ACTION_ITEM_READ_LIMIT_DEFAULT,
            true,
            dispatch
        ).then(function (response) {
            dispatch(eventsActionSetStartupCheck(true));
        });

    }
};