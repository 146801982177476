/*

 */

import React from 'react';
import * as Sentry from '@sentry/browser';
import {axiosInstance} from "../../../utilities/axiosInstance";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {BANK_ACCOUNT_STATS_DICT} from "../../../constants/fundsConstants";
import {
    loadingActionLoadInternationalUserBankAccountFromServer,
    loadingActionLoadUSDomesticUserBankAccountFromServer
} from "../../../autoGenerated/actions/resourceLoadingActions";
import {INTERNAL_USER_BANK_ACCOUNT_TYPE_US_DOMESTIC} from "../../../autoGenerated/constants/services/UserAccountsServiceConstants";
import toast from "react-hot-toast";


function BankAccountStateSelector({bankAccount, ...props}) {

    return (
        <FormControl>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={bankAccount.state}
                onChange={handleChange}
            >
                {
                    Object.keys(BANK_ACCOUNT_STATS_DICT).map(bankAccountStateKey => {
                        return <MenuItem value={bankAccountStateKey}>{BANK_ACCOUNT_STATS_DICT[bankAccountStateKey]}</MenuItem>;
                    })
                }
            </Select>
        </FormControl>
    );

    function handleChange(event) {
        // withdrawals/<uuid:withdraw_uid>/set/state/<string:new_state>
        const newState = event.target.value;
        const bankURLChunk = bankAccount.internal_account_type === INTERNAL_USER_BANK_ACCOUNT_TYPE_US_DOMESTIC ? 'domestic' : 'international';
        const putURL = `api/ornate/cabbage/accounts/banks/${bankURLChunk}/${bankAccount.bank_account_uid}/set/state/${newState}`;

        axiosInstance.put(putURL, {})
            .then(function (response) {
                toast.success('Bank account updated...');

                if(bankAccount.internal_account_type === INTERNAL_USER_BANK_ACCOUNT_TYPE_US_DOMESTIC){
                    props.loadingActionLoadUSDomesticUserBankAccountFromServer(bankAccount.bank_account_uid);
                }
                else{
                    props.loadingActionLoadInternationalUserBankAccountFromServer(bankAccount.bank_account_uid);
                }

            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    scope.setExtra("newState", newState);
                    scope.setExtra("bankAccount", bankAccount);
                    Sentry.captureException(error);
                });
            });
    }


}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadingActionLoadUSDomesticUserBankAccountFromServer: loadingActionLoadUSDomesticUserBankAccountFromServer,
        loadingActionLoadInternationalUserBankAccountFromServer: loadingActionLoadInternationalUserBankAccountFromServer
    }, dispatch);
};

export default connect(null, mapActionsToProps)(BankAccountStateSelector);