/*

 */

import React from 'react';
import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import AdminDisplayAttachedFiles from "./adminDisplayAttachedFiles";
import {AttachedStorageFilesContainer} from "../../../autoGenerated/components/containers/resourceContainers";

export default function AttachedFilesDisplayHOC({attachedFilesUID, ...props}) {

    if(attachedFilesUID === NULL_UUID_STRING){
        return <i>No attached files</i>;
    }

    return (
        <AttachedStorageFilesContainer attachedStorageFilesUID={attachedFilesUID}>
            <AdminDisplayAttachedFiles/>
        </AttachedStorageFilesContainer>
    );

}