import React from "react";

import Grid from "@material-ui/core/Grid";
import {FUNDS_LOCATION_TYPE_BANK_ACCOUNT} from "../../autoGenerated/constants/services/UserAccountsServiceConstants";
import {NULL_UUID_STRING} from "../../constants/dataConstants";
import {BankAccountContainer} from "../../autoGenerated/components/containers/resourceContainers";
import {TinyBankAccountCard} from "../allTinyCards";
import NewFundsLocationDialog from "./NewFundsLocationDialog";


export function FundsLocationDisplay({sourceObject, ...props}) {

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >

            {
                sourceObject.funds_location === FUNDS_LOCATION_TYPE_BANK_ACCOUNT && sourceObject.funds_destination_uid !== NULL_UUID_STRING &&
                <Grid item>
                    <BankAccountContainer bankAccountUID={sourceObject.funds_location_uid}>
                        <TinyBankAccountCard/>
                    </BankAccountContainer>
                </Grid>
            }

            <Grid item>
                <NewFundsLocationDialog deposit={sourceObject}/>
            </Grid>

        </Grid>
    );
}

