/*
    Data driven table row
 */

import React from "react";
import TableCell from "@material-ui/core/TableCell";
import {
    RENDER_OBJECT_DATA_MODE_TABLE,
    renderObjectData
} from "../../../components/tables/JSONSchema/JSONSchemaUtilities";
import {CircularProgress} from "@material-ui/core";
import {TABLE_COLUMN_ALIGNMENT_DEFAULT} from "../../constants/tables/tableConstants";


function GenericTableRow({ContainerComponent, resourceUID, resourceUIDName, loadedObjectName, tableRenderDefinition, jsonSchema, customRenderComponents = {}}) {

    const containerProps = {[resourceUIDName]: resourceUID};

    return (
        <ContainerComponent {...containerProps}>
            <ObjectTableRow/>
        </ContainerComponent>
    );

    function ObjectTableRow({loading, error, ...rowProps}) {

        const loadedObject = rowProps[loadedObjectName];

        if (loadedObject === undefined || loading === true || error === true) {
            return (
                <React.Fragment>
                    {
                        Object.keys(tableRenderDefinition).map(objectKey => {
                            const renderDef = tableRenderDefinition[objectKey];
                            const alignment = renderDef.columnAlign === undefined ? TABLE_COLUMN_ALIGNMENT_DEFAULT : renderDef.columnAlign;

                            return (
                                <TableCell align={alignment} key={`loading-cell-${objectKey}`}>
                                    <CircularProgress size={18}/>
                                </TableCell>
                            );
                        })
                    }
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {
                    Object.keys(tableRenderDefinition).map(objectKey => {
                        const renderDef = tableRenderDefinition[objectKey];
                        const alignment = renderDef.columnAlign === undefined ? TABLE_COLUMN_ALIGNMENT_DEFAULT : renderDef.columnAlign;

                        return (
                            <TableCell align={alignment} key={`${resourceUID}-${objectKey}`}>
                                {renderObjectData(RENDER_OBJECT_DATA_MODE_TABLE, objectKey, loadedObject, jsonSchema, customRenderComponents)}
                            </TableCell>
                        );
                    })
                }
            </React.Fragment>
        );
    }

}

export default React.memo(GenericTableRow);