// Name: Frontend Body Render Definitions 

export const FRONTEND_GENERAL_SETTINGS_BODY_RENDER_DEF = {
	settings_uid: {displayName: "Settings UID"},
	site_offline: {displayName: "Site Offline"}
};

export const ONLINE_USER_BODY_RENDER_DEF = {
	online_user_uid: {displayName: "Online User UID"},
	account_uid: {displayName: "Account UID"},
	refresh_count: {displayName: "Refresh Count"},
	// access_token: {displayName: "Access Token"},
	last_login_timestamp: {displayName: "Last Login Timestamp"},
	stay_logged_in: {displayName: "Stay Logged In"},
	user_agent_string: {displayName: "User Agent String"},
	ip_address_string: {displayName: "Ip Address String"},
	last_login_refresh_timestamp: {displayName: "Last Login Refresh Timestamp"},
	refresh_token_expire_timestamp: {displayName: "Refresh Token Expire Timestamp"},
	// refresh_token: {displayName: "Refresh Token"}
};

