import React from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {PROPERTY_TYPE_SUGGESTIONS_LIST} from "../../../constants/propertyConstants";

export default function PropertyTypeAutoSuggestion({
                                                       valueChangeCallback,
                                                       labelText = 'Property Type',
                                                       defaultValue = null,
                                                       currentValue = null,
                                                       ...props
                                                   }) {

    return (
        <Autocomplete
            options={PROPERTY_TYPE_SUGGESTIONS_LIST}
            getOptionLabel={option => option.label}
            // TODO: Rename
            id="auto-select"
            autoSelect
            autoHighlight
            onChange={handleChange}
            value={currentValue}
            defaultValue={defaultValue}
            renderInput={params => (
                <TextField
                    {...params}
                    label={labelText}
                    margin="normal"
                    fullWidth
                />
            )}
        />
    );

    function handleChange(event, value) {
        //console.log(value);
        valueChangeCallback(value);
    }
}