/*

 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import LoginForm from "../forms/LoginForm";
import {useLocation} from "react-router";

// const useStyles = makeStyles(theme => ({
//     tagLine: {
//         fontSize: '2rem',
//         fontWeight: 300
//     },
//     logoHeader: {
//         marginTop: 20
//     },
//     container: {
//         flex: 1,
//     },
//     backgroundImage: {
//         flex: 1,
//         resizeMode: 'cover', // or 'stretch'
//     },
//     loginForm: {
//         position: 'absolute',
//         top: 0,
//         bottom: 0,
//         left: 0,
//         right: 0
//     },
// }));

function LoginPage(props) {
    //const classes = useStyles();

    // Grab the current location and store it so that when login is complete we return the user there
    const preLoginRoute = useLocation();

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
                marginTop: '15%'
            }}
        >
            <Grid
                item
                xs={9}
                md={5}
            >
                <Paper
                    style={{
                        boxShadow: '0px 15px 20px #757575',
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={4}
                    >

                        <Grid item xs={12} style={{width: '90%', marginBottom: 30}}>
                            <div
                                style={{
                                    marginLeft: 20,
                                    marginRight: 20
                                }}
                            >
                                <LoginForm preLoginRoute={preLoginRoute}/>
                            </div>
                        </Grid>


                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default LoginPage;