/*
    Generic Loading Container

    HOC To load a resource and pass it down to children
 */
import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    checkLoadingIndicator,
    checkResourceUIDIsValid,
    createLoadingIndicatorUID
} from "../../../utilities/loadingUtilities";
import {makeLoadingIndicatorSelector} from "../../../selectors/loadingSelectors";
import {RESOURCE_TYPE_INFO} from "../../../autoGenerated/constants/resourceTypeInfo";


function GenericContainer({resourceUID, resourceType, makeResourceSelector, serverLoadingFunction,  ...props}) {
    const {loading, error, response} = checkLoadingIndicator(props.resourceLoadingIndicator);

    const resourceTypeInfo = RESOURCE_TYPE_INFO[resourceType];

    // Load if not already in redux and if we aren't already loading, or if it's failed to load
    if (props.resource === undefined && loading === false && error === false) {
        // Check the UID isn't undefined or NULL
        if (checkResourceUIDIsValid(resourceUID)) {
            serverLoadingFunction(resourceUID);
        }
    }

    // Update any child components with the loading props, specific props for this container and any props passed in
    const updatedChildrenWithProps = React.Children.map(props.children,
        (child) => {
            return React.cloneElement(child, {
                // Loading indicator related
                loading: loading,
                error: error,
                response: response,

                // Specific
                [resourceTypeInfo.clientUIDName]: resourceUID,
                [resourceTypeInfo.clientObjectName]: props.resource,

                // Any other props
                ...props
            });
        }
    );

    // Render the children
    return <React.Fragment>{updatedChildrenWithProps}</React.Fragment>;
}


const mapStateToProps = (state) => {
    const createLoadingIndicatorSelector = makeLoadingIndicatorSelector();

    return function realMapState(state, props) {
        const _makeResourceSelector = props.makeResourceSelector(state);
        const loadingIndicatorKey = createLoadingIndicatorUID(props.resourceUID, props.resourceType);

        return {
            resource: _makeResourceSelector(state, props.resourceUID),
            resourceLoadingIndicator: createLoadingIndicatorSelector(state, loadingIndicatorKey)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        serverLoadingFunction: props.serverLoadingFunction
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(GenericContainer);