import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {faAt} from "@fortawesome/pro-regular-svg-icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {connect} from "react-redux";
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {ALL_PRIORITY_ACH_PAYMENT_STATES, PRIORITY_ACH_PAYMENT_STATE_CLEARED} from "../../constants/paymentConstants";
import PaymentsAdminService from "../../api/PaymentsAdminService";
import {RESOURCE_TYPE_PRIORITY_ACH_PAYMENT} from "../../autoGenerated/constants/resourceTypeConstants";
import CancelButton from "../utilities/buttons/CancelButton";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import {resourceActionUpdateSingleResource} from "../../clientShared/actions/resourceActions";
import {allBankAccountsSelector} from "../../autoGenerated/selectors/resourceSelectors";
import Grid from "@material-ui/core/Grid";
import ReusableFundsLocation from "../deposits/ReusableFundsLocation";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));


function SetACHPaymentStateButton({achPayment, ...props}) {
    const classes = useStyles();

    const [showDialog, setShowDialog] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [paymentState, setPaymentState] = React.useState(achPayment.state);
    const [paymentCleared, setPaymentCleared] = React.useState(achPayment.payment_cleared_into_deposify_bank_account);
    const [fundsLocationUID, setFundsLocationUID] = React.useState(achPayment.clear_into_funds_location_uid);

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={handleOpen}
                variant={'outlined'}
                size={'small'}
            >
                <FontAwesomeIcon
                    icon={faAt}
                    fixedWidth
                />
                &nbsp;Set Payment State
            </Button>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="fullscreen-deposit-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                //fullScreen
            >
                <DialogTitle id="edit-funds-location-dialog-title">Set ACH Payment State</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Set state of the payment
                    </DialogContentText>


                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        spacing={2}
                    >
                        {/*<Grid item>*/}
                        {/*    <FormControlLabel*/}
                        {/*        control={*/}
                        {/*            <Switch*/}
                        {/*                checked={complianceDict[complianceID]}*/}
                        {/*                onChange={handleChange}*/}
                        {/*                name={complianceID}*/}
                        {/*                color="primary"*/}
                        {/*            />*/}
                        {/*        }*/}
                        {/*        label={COMPLIANCE_ID_INFO[complianceID]}*/}
                        {/*    />*/}
                        {/*</Grid>*/}

                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-dialog-select-label">State</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={paymentState}
                                    onChange={handleStateChange}
                                    input={<Input/>}
                                    fullWidth
                                >
                                    {
                                        ALL_PRIORITY_ACH_PAYMENT_STATES.map(currentACHState => {
                                            return (
                                                <MenuItem
                                                    value={currentACHState}
                                                    key={`ach-state-select-${currentACHState}`}
                                                >
                                                    {currentACHState}
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <hr/>
                        </Grid>

                        <Grid item>
                            <ReusableFundsLocation
                                initialFundsLocationUID={fundsLocationUID}
                                valueChangeCallback={handleFundsLocationChange}
                            />

                            {/*<FormControl className={classes.formControl}>*/}
                            {/*    <InputLabel id="funds-location-select-label">Funds Location</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        labelId="funds-location-select-label"*/}
                            {/*        id="funds-location-select"*/}
                            {/*        value={fundsLocationUID}*/}
                            {/*        onChange={handleFundsLocationChange}*/}
                            {/*        input={<Input />}*/}
                            {/*        fullWidth*/}
                            {/*    >*/}
                            {/*        {*/}
                            {/*            Object.values(props.allBankAccounts).map(currentBankAccount => {*/}
                            {/*                return (*/}
                            {/*                    <MenuItem*/}
                            {/*                        value={currentBankAccount.bank_account_uid}*/}
                            {/*                        key={`bank-account-select-${currentBankAccount.bank_account_uid}`}*/}
                            {/*                    >*/}
                            {/*                        {currentBankAccount.bank_name} - {currentBankAccount.geo_state} - {currentBankAccount.address_line_one}*/}
                            {/*                    </MenuItem>*/}
                            {/*                );*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}
                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <CancelButton handleCloseFunction={handleClose} buttonText={'Close'}/>

                    <SpinnerButton
                        // Non-button props
                        showSpinner={showSpinner}
                        // Button props
                        variant="contained"
                        color="secondary"
                        disabled={showSpinner}
                        onClick={handleChangeFundsLocation}
                    >
                        <FontAwesomeIcon
                            icon={faSkullCrossbones}
                            fixedWidth
                        />
                        &nbsp;Set State
                    </SpinnerButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
        setShowSpinner(false);
    }

    function handleStateChange(event) {
        setPaymentState(event.target.value);
        if (event.target.value === PRIORITY_ACH_PAYMENT_STATE_CLEARED) {
            setPaymentCleared(true);
        } else {
            setPaymentCleared(false);
        }
    }

    function handleFundsLocationChange(newValue) {
        setFundsLocationUID(newValue);
    }

    function handleChangeFundsLocation() {
        setShowSpinner(true);

        const updateData = {
            state: paymentState,
            payment_cleared_into_deposify_bank_account: paymentCleared,
            clear_into_funds_location_uid: fundsLocationUID
        }

        PaymentsAdminService.AdminPaymentsACHUpdate(achPayment.payment_uid, updateData)
            .then(function (response) {
                setShowSpinner(false);
                handleClose();
                setTimeout(() => {
                    props.resourceActionUpdateSingleResource(response.priority_ach_payment, RESOURCE_TYPE_PRIORITY_ACH_PAYMENT);
                }, 500);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }


}

const mapStateToProps = (state) => ({
    allBankAccounts: allBankAccountsSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(React.memo(SetACHPaymentStateButton));

