/*
    Dev
 */

import React from "react";
import {UIDLinkOrCopy} from "../../admin/buttons/linkButtons/UIDLinkOrCopy";
import {renderServerTimestamp, renderServerTimestampWithTime} from "../../../utilities/commonUtils";

function JSONSchemaRenderString({propDetails, objectValue}){
    const stringFormat = propDetails.format || undefined;

    if(stringFormat) {
        if(stringFormat === 'date-time'){
            // console.log('Date/Time', propDetails);
            return <React.Fragment>{renderServerTimestampWithTime(objectValue)}</React.Fragment>;
        }
        else{
            console.log('Unknown formatted string', propDetails);
            return <React.Fragment>UK FS ERROR</React.Fragment>;
        }
    }

    const referenceType = propDetails.reference_type || undefined;

    // TODO: uuid constant
    if(referenceType === 'uuid'){
        const uidResourceType = propDetails.uid_resource_type || undefined;
        if(uidResourceType){
            // console.log('Link render', propDetails)
            //return <React.Fragment>UID Link</React.Fragment>;
            return <UIDLinkOrCopy uid={objectValue} resourceType={uidResourceType}/>;
        }
        else{
            // console.log('Copy render', propDetails)
            //return <React.Fragment>UID Copy</React.Fragment>;
            return <UIDLinkOrCopy uid={objectValue}/>;
        }
    }
    else if(referenceType === 'us_state'){
        // console.log('US State', propDetails)
        return <React.Fragment>{objectValue}</React.Fragment>;
    }
    else if(referenceType === 'enum'){
        // console.log('Enum', propDetails)
        // return <React.Fragment>Enum</React.Fragment>;
        return <React.Fragment>{objectValue}</React.Fragment>;
    }

    return <React.Fragment>{objectValue}</React.Fragment>;

}

export default React.memo(JSONSchemaRenderString);