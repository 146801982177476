/*
    Home Page link
 */

import React from "react";
import {APP_ROUTE_HOME} from "../../../constants/appRoutes";
import LinkBase from "./linkBase";

function HomeLink({text= "Home Page", ...props}) {

    return (
        <LinkBase url={APP_ROUTE_HOME} text={text}/>
    );
}


export default HomeLink;
