/*

 */

export const RESOURCE_TYPE_UNKNOWN_RESOURCE_TYPE = 'unknown_resource_type';

export const RESOURCE_TYPE_USER_ACCOUNT = 'user_account';
export const RESOURCE_TYPE_USER_ACCOUNT_META_DATA = 'user_account_meta_data';
export const RESOURCE_TYPE_USER_ACCOUNT_BALANCE = 'user_account_balance';
export const RESOURCE_TYPE_USER_ACCOUNT_BALANCE_SOURCE = 'user_account_balance_source';
export const RESOURCE_TYPE_USER_HISTORY_ENTRY = 'user_history_entry';
export const RESOURCE_TYPE_BALANCE_CHANGE_LOG = 'balance_change_log';
export const RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT = 'international_user_bank_account';
export const RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT = 'us_domestic_user_bank_account';
export const RESOURCE_TYPE_USER_BANK_ACCOUNT_DELTA = 'user_bank_account_delta';
export const RESOURCE_TYPE_USER_ACCOUNT_SETTINGS = 'user_account_settings';
export const RESOURCE_TYPE_USER_ACCOUNT_ADMIN_GHOST = 'user_account_admin_ghost';

export const RESOURCE_TYPE_PREVIOUS_EMAIL_ADDRESS = 'previous_email_address';
export const RESOURCE_TYPE_PENDING_EMAIL_CHANGE = 'pending_email_change';

export const RESOURCE_TYPE_PASSWORD = 'password';
export const RESOURCE_TYPE_EXPIRED_PASSWORD = 'expired_password';
export const RESOURCE_TYPE_PASSWORD_RESET = 'password_reset';

export const RESOURCE_TYPE_DEPOSIT = 'deposit';
export const RESOURCE_TYPE_DEPOSIT_INVITE = 'deposit_invite';
export const RESOURCE_TYPE_DEPOSIT_ADJUSTMENT = 'deposit_adjustment';
export const RESOURCE_TYPE_DEPOSIT_INTEREST = 'deposit_interest';
export const RESOURCE_TYPE_DEPOSIT_HISTORY_ENTRY = 'deposit_history_entry';
export const RESOURCE_TYPE_DEPOSIT_CLOSE_REQUEST = 'deposit_close_request';
export const RESOURCE_TYPE_DEPOSIT_USER_STATS = 'deposit_user_stats';

export const RESOURCE_TYPE_DEPOSITS_BULK_IMPORT = 'deposits_bulk_import';

export const RESOURCE_TYPE_DISPUTE = 'dispute';
export const RESOURCE_TYPE_DISPUTE_SPLIT = 'dispute_split';
export const RESOURCE_TYPE_DISPUTE_INTERVENTION_REQUEST = 'dispute_intervention_request';

export const RESOURCE_TYPE_PROPERTY = 'property';
export const RESOURCE_TYPE_PROPERTY_ADDRESS = 'property_address';

export const RESOURCE_TYPE_EMAIL_TEMPLATE = 'email_template';
export const RESOURCE_TYPE_EMAIL_VERIFICATION = 'email_verification';
export const RESOURCE_TYPE_EMAIL_VERIFICATION_RECORD = 'email_verification_record';
export const RESOURCE_TYPE_EMAIL_EVENT = 'email_event';
export const RESOURCE_TYPE_SENT_EMAIL = 'sent_email';

export const RESOURCE_TYPE_MAILBOX = 'mailbox';
export const RESOURCE_TYPE_MAILBOX_META_DATA = 'mailbox_meta_data';
export const RESOURCE_TYPE_MAILBOX_MESSAGE = 'mailbox_message';

export const RESOURCE_TYPE_PERMISSION_ENTRY = 'permission_entry';
export const RESOURCE_TYPE_PERMISSION_LOG_ENTRY = 'permission_log_entry';

export const RESOURCE_TYPE_INVOICE = 'invoice';
export const RESOURCE_TYPE_INVOICE_ITEM = 'invoice_item';
export const RESOURCE_TYPE_PAYMENT_REQUEST = 'payment_request';
export const RESOURCE_TYPE_STRIPE_PAYMENT_LOG = 'stripe_payment_log';
export const RESOURCE_TYPE_USER_WITHDRAW = 'user_withdraw';

export const RESOURCE_TYPE_BANK_ACCOUNT = 'bank_account';
export const RESOURCE_TYPE_BANK_ACCOUNT_INTEREST = 'bank_account_interest';
export const RESOURCE_TYPE_BANK_ACCOUNT_BALANCE = 'bank_account_balance';
export const RESOURCE_TYPE_BANK_ACCOUNT_BALANCE_RECORD = 'bank_account_balance_record';
export const RESOURCE_TYPE_BANK_ACCOUNT_BALANCE_SOURCE = 'bank_account_balance_source';
export const RESOURCE_TYPE_BANK_TRANSFER = 'bank_transfer';

export const RESOURCE_TYPE_BULK_FUNDS_TRANSFER = 'bulk_funds_transfer';

export const RESOURCE_TYPE_FUNDS_LOCK = 'funds_lock';
export const RESOURCE_TYPE_FUNDS_LOCK_RELEASE_LOG = 'funds_lock_release_log';
export const RESOURCE_TYPE_RELEASED_FUNDS_LOCK = 'released_funds_lock';

export const RESOURCE_TYPE_BALANCE_SOURCE_TRANSFER = 'balance_source_transfer';
export const RESOURCE_TYPE_CREDIT_USER_ACCOUNT_LOG = 'credit_user_account_log';

export const RESOURCE_TYPE_EVENT = 'event';
export const RESOURCE_TYPE_NOTIFICATION = 'notification';
export const RESOURCE_TYPE_ACTION_ITEM = 'action_item';

export const RESOURCE_TYPE_UPDATE_LOG_ENTRY = 'update_log_entry';

export const RESOURCE_TYPE_STORAGE_FILE = 'storage_file';
export const RESOURCE_TYPE_ATTACHED_STORAGE_FILES = 'attached_storage_files';
export const RESOURCE_TYPE_PRE_UPLOADED_FILE = 'pre_uploaded_file';

export const RESOURCE_TYPE_SYSTEM_HISTORY_ENTRY = 'system_history_entry';

export const RESOURCE_TYPE_IP_ADDRESS = 'ip_address';
export const RESOURCE_TYPE_KNOWN_IP_ADDRESS = 'known_ip_address';
export const RESOURCE_TYPE_USER_AGENT = 'user_agent';
export const RESOURCE_TYPE_KNOWN_USER_AGENT = 'known_user_agent';

export const RESOURCE_TYPE_BASIC_USER_ACCOUNT_INFO = 'basic_user_account_info';

// CLIENT ONLY - NOT ON SERVER - USED TO HELP THE CLIENT LOAD
export const RESOURCE_TYPE_ALL_MAILBOX_MESSAGES = 'all_mailbox_messages';
export const RESOURCE_TYPE_ONLINE_USER_INFO = 'online_user_info';