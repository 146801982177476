// Name: History Body Render Definitions 

export const SNAPSHOT_BODY_RENDER_DEF = {
	resource_uid: {displayName: "Resource UID"},
	snapshot_type: {displayName: "Snapshot Type"},
	payload: {displayName: "Payload"},
	resource_type: {displayName: "Resource Type"},
	account_uid: {displayName: "Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	snapshot_uid: {displayName: "Snapshot UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const SYSTEM_HISTORY_ENTRY_BODY_RENDER_DEF = {
	visible_to_account_uids: {displayName: "Visible To Account UIDs"},
	event_id: {displayName: "Event Id"},
	event_type: {displayName: "Event Type"},
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	entry_uid: {displayName: "Entry UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	resource_uid: {displayName: "Resource UID"},
	entry_payload: {displayName: "Entry Payload"},
	deposify_tags: {displayName: "Deposify Tags"},
	deposify_only: {displayName: "Deposify Only"},
	account_uid: {displayName: "Account UID"}
};

export const DEPOSIT_HISTORY_ENTRY_BODY_RENDER_DEF = {
	visible_to_account_uids: {displayName: "Visible To Account UIDs"},
	event_id: {displayName: "Event Id"},
	event_type: {displayName: "Event Type"},
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	entry_uid: {displayName: "Entry UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	resource_uid: {displayName: "Resource UID"},
	entry_payload: {displayName: "Entry Payload"},
	deposify_tags: {displayName: "Deposify Tags"},
	deposify_only: {displayName: "Deposify Only"},
	account_uid: {displayName: "Account UID"}
};

export const UPDATE_LOG_BODY_RENDER_DEF = {
	update_note: {displayName: "Update Note"},
	entry_type: {displayName: "Entry Type"},
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	entry_uid: {displayName: "Entry UID"},
	payload: {displayName: "Payload"},
	created_timestamp: {displayName: "Created Timestamp"},
	resource_uid: {displayName: "Resource UID"},
	account_uid: {displayName: "Account UID"}
};

export const USER_HISTORY_ENTRY_BODY_RENDER_DEF = {
	visible_to_account_uids: {displayName: "Visible To Account UIDs"},
	event_id: {displayName: "Event Id"},
	event_type: {displayName: "Event Type"},
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	entry_uid: {displayName: "Entry UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	resource_uid: {displayName: "Resource UID"},
	entry_payload: {displayName: "Entry Payload"},
	deposify_tags: {displayName: "Deposify Tags"},
	deposify_only: {displayName: "Deposify Only"},
	account_uid: {displayName: "Account UID"}
};

