/*

 */

import React, {useState} from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Tooltip from "@material-ui/core/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-light-svg-icons";
import {PRIMARY_BLUE_COLOUR} from "../../../constants/styleConstants";

const TOOL_TIP = 'Click to copy';
const COPIED_TOOL_TIP = <React.Fragment><FontAwesomeIcon icon={faCheck} fixedWidth color={'#1be82b'}/>Copied</React.Fragment>;

const ClickToCopy = ({dataToCopy, displayData = undefined, linkStyle= false}) => {

    const [toolTip, setToolTip] = useState(TOOL_TIP);
    const renderData = displayData === undefined ? dataToCopy : displayData;
    const styleOptions = linkStyle ? {color: PRIMARY_BLUE_COLOUR} : {};

    return (
        <CopyToClipboard text={`${dataToCopy}`} onCopy={handleClick}>
            <Tooltip title={toolTip} placement={'right'}>
                <span style={styleOptions}>
                    {renderData}
                </span>
            </Tooltip>
        </CopyToClipboard>
    );

    // return (
    //     <CopyToClipboard text={`${dataToCopy}`} onCopy={handleClick}>
    //         <Tooltip title={toolTip} placement={'right'}>
    //             <span>
    //                 {renderData}
    //             </span>
    //         </Tooltip>
    //     </CopyToClipboard>
    // );

    function handleClick(){
        setToolTip(COPIED_TOOL_TIP);

        setTimeout(() => {
            setToolTip(TOOL_TIP);
        }, 2000);
    }
}

export default ClickToCopy;
