import React from "react";
import GenericObjectDisplay from "../../../../clientShared/components/display/GenericObjectDisplay";
import GenericObjectWrapper from "../../../../clientShared/components/display/GenericObjectWrapper";
import {RESOURCE_TYPE_USER_ACCOUNT_BALANCE} from "../../../../autoGenerated/constants/resourceTypeConstants";
import DetailedUserAccountInfoLink from "../../../links/DetailedUserAccountInfoLink";
import SetAdminGhostButton from "../../../admin/buttons/SetAdminGhostButton";
import Grid from "@material-ui/core/Grid";


export function UserAccountDisplay(props) {

    return (
        <>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                // spacing={2}
                style={{marginTop: 12}}
            >
                <Grid item>
                    <DetailedUserAccountInfoLink accountUID={props.userAccount.account_uid}/>
                </Grid>
            </Grid>

            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item>
                    <GenericObjectDisplay {...props}/>
                </Grid>

                <Grid item>
                    <DetailedUserAccountInfoLink accountUID={props.userAccount.account_uid}/>
                </Grid>

                <Grid item>
                    <SetAdminGhostButton targetAccountUID={props.userAccount.account_uid} />
                </Grid>

                <Grid item>
                    <GenericObjectWrapper
                        resourceUID={props.userAccount.account_uid}
                        resourceType={RESOURCE_TYPE_USER_ACCOUNT_BALANCE}
                    />
                </Grid>
            </Grid>
        </>
    );
}

