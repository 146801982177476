import React from 'react';
import Link from "@material-ui/core/Link";
import {history} from "../../history/history";
import {RESOURCE_TYPE_INFO} from "../../autoGenerated/constants/resourceTypeInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HIGHLIGHT_PLACE_HOLDER} from "../../constants/resourceCardHighlights";
import {getResourceIcon} from "../../autoGenerated/constants/serviceAndResourceIcons";


export default function ResourcePageLink({resourceType, pageURL, ...props}) {
    if (resourceType === HIGHLIGHT_PLACE_HOLDER) {
        return (
            <React.Fragment>
                &nbsp;
            </React.Fragment>
        );
    }

    const resourceTypeInfo = RESOURCE_TYPE_INFO[resourceType];
    const resourceIcon = getResourceIcon(resourceType);

    return (
        <React.Fragment>
            <Link
                href={pageURL}
                // component="button"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={resourceIcon} fixedWidth/>
                &nbsp;&nbsp;{resourceTypeInfo.displayName}
            </Link>
        </React.Fragment>
    );

    function handleClick(event) {
        event.preventDefault();
        history.push(pageURL)
    }
}