/*
    Admin Actions
 */

import {
    ADMIN_ADD_TO_FULL_SCREEN_HISTORY_CHAIN,
    ADMIN_MOVE_BACK_IN_FULL_SCREEN_HISTORY_CHAIN,
    ADMIN_SET_FULL_SCREEN_HISTORY_CHAIN, ADMIN_SET_GHOST_ACCOUNT_INFO,
    ADMIN_SET_LAST_ALL_ONLINE_USERS_REFRESH,
    ADMIN_SET_STARTUP_LOADING, ADMIN_SET_SYSTEM_STATS,
    ADMIN_UPDATE_ONLINE_USER_ACCOUNTS
} from "../actions/adminActionTypes";

import {store} from "../store/appStore"
import {axiosInstance} from "../utilities/axiosInstance";
import * as Sentry from "@sentry/browser";
import {DEFAULT_BATCH_UID_LOAD_LIMIT, DEV_KEEP_LOADING} from "../constants/apiConstants";
import {NULL_UUID_STRING} from "../constants/dataConstants";
import {makeUpdateDictActionCreator} from "../utilities/admin/actionUtilities";
import {
    ADMIN_ADD_SYSTEM_STATS,
    ADMIN_REMOVE_FILE_AFTER_DELETE,
    ADMIN_SET_FULL_SCREEN_FILE_INFO,
    ADMIN_SET_GENERIC_FULL_SCREEN_DISPLAY,
    ADMIN_SET_SEARCH_INFO,
    ADMIN_SET_SEARCH_RESULTS
} from "./adminActionTypes";
import {ADMIN_GHOST_ACCOUNT_UID, DEFAULT_SORTING_COLUMN} from "../constants/adminConstants";
import {
    resourceActionUpdateResourceUIDs,
    resourceActionUpdateResourceUIDsForOwner
} from "../clientShared/actions/resourceActions";
import SystemGeneralService from "../api/systemGeneralService";
import {createCommonResourceUIDListURL} from "../clientShared/utilities/resourceUtilities";
import {loadingActionSetLoadingIndicator} from "./loadingActions";
import {
    LOADING_INDICATOR_STATE_ERROR,
    LOADING_INDICATOR_STATE_LOADED,
    LOADING_INDICATOR_STATE_LOADING
} from "../constants/loadingConstants";
import {createUIDListLoadingResourceUID} from "../utilities/loadingUtilities";
import {loadingActionLoadResourceFromServer} from "../clientShared/actions/sharedLoadingActions";
import {
    RESOURCE_TYPE_DEPOSIT_HISTORY_ENTRY,
    RESOURCE_TYPE_USER_ACCOUNT_ADMIN_GHOST
} from "../autoGenerated/constants/resourceTypeConstants";
import {getAdminPath} from "../api/adminAPI";

export function adminActionSetFullScreenHistoryChain(fullScreenHistoryChain) {
    return {
        type: ADMIN_SET_FULL_SCREEN_HISTORY_CHAIN,
        fullScreenHistoryChain: fullScreenHistoryChain
    }
}

export function adminActionAddToFullScreenHistoryChain(resourceUID, resourceType) {
    return {
        type: ADMIN_ADD_TO_FULL_SCREEN_HISTORY_CHAIN,
        resourceUID: resourceUID,
        resourceType: resourceType
    }
}

export function adminActionMoveBackInFullScreenHistoryChain() {
    return {
        type: ADMIN_MOVE_BACK_IN_FULL_SCREEN_HISTORY_CHAIN
    }
}

export function adminActionSetFullScreenFileInfo(fullScreenFileInfo) {
    return {
        type: ADMIN_SET_FULL_SCREEN_FILE_INFO,
        fullScreenFileInfo: fullScreenFileInfo
    }
}

export function adminActionRemoveFileAfterDelete(fileUID) {
    return {
        type: ADMIN_REMOVE_FILE_AFTER_DELETE,
        fileUID: fileUID
    }
}

//
// ONLINE USERS
//
export function adminActionSetAllOnlineUsersLastRefresh(allOnlineUsersLastRefresh) {
    return {
        type: ADMIN_SET_LAST_ALL_ONLINE_USERS_REFRESH,
        allOnlineUsersLastRefresh: allOnlineUsersLastRefresh
    }
}

export const adminActionUpdateAllOnlineUserAccounts = makeUpdateDictActionCreator(ADMIN_UPDATE_ONLINE_USER_ACCOUNTS, 'allOnlineUserAccounts');

export function adminActionUpdateSingleOnlineUserAccount(OnlineUserAccount) {
    return adminActionUpdateAllOnlineUserAccounts([OnlineUserAccount]);
}


//
// SEARCH
//
export function adminActionSetSearchInfo(adminSearchInfo) {
    return {
        type: ADMIN_SET_SEARCH_INFO,
        adminSearchInfo: adminSearchInfo
    }
}

export function adminActionSetSearchResults(adminSearchResults) {
    return {
        type: ADMIN_SET_SEARCH_RESULTS,
        adminSearchResults: adminSearchResults
    }
}

//
// GENERIC FULL SCREEN DISPLAY
//
export function adminActionSetGenericFullScreenDisplay(fullScreenDisplay) {
    return {
        type: ADMIN_SET_GENERIC_FULL_SCREEN_DISPLAY,
        fullScreenDisplay: fullScreenDisplay
    }
}


//
// UPDATE UIDS FOR RESOURCE
//
export const adminActionLoadAllUIDS = (resourceType,
                                       loadURL,
                                       offset = 0,
                                       limit = DEFAULT_BATCH_UID_LOAD_LIMIT,
                                       sort_column = DEFAULT_SORTING_COLUMN,
                                       account_uid = NULL_UUID_STRING,
                                       replaceAll = false,
                                       keepLoading = DEV_KEEP_LOADING,
                                       readDescending=true) => {
    return dispatch => {
        const readPayload = {
            resource_type: resourceType,
            offset: offset,
            limit: limit,
            order_column_name: sort_column,
            account_uid: account_uid,
            read_descending: readDescending
        };

        let actualLoadURL = createCommonResourceUIDListURL();
        // I'm so sorry about this
        if(resourceType === RESOURCE_TYPE_DEPOSIT_HISTORY_ENTRY){
            actualLoadURL = getAdminPath(`jeff/uid/list/read`)
        }

        const resUID = createUIDListLoadingResourceUID(account_uid, resourceType);

        dispatch(loadingActionSetLoadingIndicator(resUID, resourceType, LOADING_INDICATOR_STATE_LOADING, undefined));

        // Make the call
        return axiosInstance.put(actualLoadURL, readPayload)
            .then(function (response) {
                // console.log(resourceType, response);

                // Update the list in state
                if (account_uid === NULL_UUID_STRING) {
                    dispatch(resourceActionUpdateResourceUIDs(resourceType, response.data.uid_list, replaceAll));
                } else {
                    dispatch(resourceActionUpdateResourceUIDsForOwner(account_uid, resourceType, response.data.uid_list, replaceAll));
                }

                // If we got a full list back and the keep loading flag is true
                if (keepLoading === true && response.data.uid_list.length >= limit) {
                    // Only do the replace all for the first update
                    dispatch(adminActionLoadAllUIDS(resourceType, loadURL, offset + limit, limit, sort_column, account_uid, false, keepLoading, readDescending));
                }
                else{
                    dispatch(loadingActionSetLoadingIndicator(resUID, resourceType, LOADING_INDICATOR_STATE_LOADED, undefined));
                }
            })
            .catch(function (error) {
                //handleLoadingIndicatorError(resourceUID, resourceType, error, dispatch);
                // Tell Sentry about the error
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    scope.setExtra("loadURL", loadURL);
                    scope.setExtra("offset", offset);
                    scope.setExtra("limit", limit);
                    Sentry.captureException(error);
                });

                if (error.response) {
                    dispatch(loadingActionSetLoadingIndicator(resUID, resourceType, LOADING_INDICATOR_STATE_ERROR, error.response));
                } else {
                    dispatch(loadingActionSetLoadingIndicator(resUID, resourceType, LOADING_INDICATOR_STATE_ERROR, error));
                }
            })

    }
};

//
// UPDATE UIDS FOR ACCOUNT BY RESOURCE
//
export const adminActionLoadAllUIDSForAccount = (accountUID,
                                                 resourceType,
                                                 loadURL,
                                                 offset = 0,
                                                 limit = DEFAULT_BATCH_UID_LOAD_LIMIT,
                                                 replaceAll = false,
                                                 keepLoading = DEV_KEEP_LOADING,
                                                 urlArgs = undefined) => {
    return dispatch => {
        //dispatch(uidListLoader(getAdminPath('deposits/all/uids'), offset, limit, adminActionUpdateAllDepositUIDs, DEV_KEEP_LOADING, [orderColumn]));

        let readURL = loadURL + `/${accountUID}/${offset}/${limit}`;
        if (urlArgs !== undefined) {
            // TODO: Replace with join
            urlArgs.map(extraArg => {
                readURL += `/${extraArg}`;
                return readURL;
            })
        }

        // Make the call
        return axiosInstance.get(readURL)
            .then(function (response) {
                // Update the list in state
                dispatch(resourceActionUpdateResourceUIDsForOwner(accountUID, resourceType, response.data.uid_list, replaceAll));

                // If we got a full list back and the keep loading flag is true
                if (keepLoading === true && response.data.uid_list.length >= limit) {
                    // Only do the replace all for the first update
                    dispatch(adminActionLoadAllUIDSForAccount(accountUID, resourceType, loadURL, offset + limit, limit, false, keepLoading, urlArgs));
                }
            })
            .catch(function (error) {
                //handleLoadingIndicatorError(resourceUID, resourceType, error, dispatch);
                // Tell Sentry about the error
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    scope.setExtra("loadURL", loadURL);
                    scope.setExtra("offset", offset);
                    scope.setExtra("limit", limit);
                    Sentry.captureException(error);
                });

            })

    }
};


//
// STARTUP
//

export function adminActionSetStartupLoading(adminStartupLoading) {
    return {
        type: ADMIN_SET_STARTUP_LOADING,
        adminStartupLoading: adminStartupLoading
    }
}

export function adminActionStartupLoad() {
    return dispatch => {
        const adminStartingState = store.getState().admin.adminStartupLoading;
        if (adminStartingState === true) {
            return;
        }

        // Reset the state
        dispatch(adminActionSetStartupLoading(true));

        let accountPromises = [
            // Deposits
            // dispatch(adminActionLoadAllDepositUIDs(0, DEFAULT_BATCH_UID_LOAD_LIMIT, DEFAULT_SORTING_COLUMN)),
            // Accounts
            // dispatch(adminActionLoadAllUserAccountUIDs()),
            // User account history
            // dispatch(adminLoadingUserAccountHistoryEntryUIDsFromServer(0, 300, DEFAULT_DATE_TIME_URL_STRING)),
            // online users
            //dispatch(adminLoadingUserOnlineUserAccountUIDsFromServer()),
            // Ghost account
            dispatch(adminLoadingActionLoadGhostAccountInfoFromServer())
        ];

        Promise.all(accountPromises).then(function (promiseResults) {
            dispatch(adminActionSetStartupLoading(false));
        });
    }
}

//
// SYSTEM STATS
//
export function adminSetSystemStats(systemStats) {
    return {
        type: ADMIN_SET_SYSTEM_STATS,
        systemStats: systemStats
    }
}

export function adminAddSystemStats(statsID, systemStats) {
    return {
        type: ADMIN_ADD_SYSTEM_STATS,
        statsID: statsID,
        systemStats: systemStats
    }
}

export function adminActionLoadSystemStatsFromServer(statsID, readCount) {
    /*
        Load system stats and update a dict in stat

        110: {loaded: true, ..., stats: []}
     */
    return function (dispatch) {
        let defaultStatsThing = {loading: true, error: false, loaded: false};

        // Set the loading state
        dispatch(adminAddSystemStats(statsID, defaultStatsThing));

        SystemGeneralService.ReadSystemStats(statsID, readCount)
            .then(function (response) {

                defaultStatsThing.loading = false;
                defaultStatsThing.loaded = true;

                const finalStats = {...defaultStatsThing, stats: [...response.system_stats]}

                dispatch(adminAddSystemStats(statsID, finalStats));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                defaultStatsThing.loading = false;
                defaultStatsThing.error = true;

                dispatch(adminAddSystemStats(statsID, defaultStatsThing));
            })
    }
}

//
// GHOST ACCOUNT INFO
//
export function adminActionSetGhostAccountInfo(adminGhostAccountInfo) {
    return {
        type: ADMIN_SET_GHOST_ACCOUNT_INFO,
        adminGhostAccountInfo: adminGhostAccountInfo
    }
}

export function adminLoadingActionLoadGhostAccountInfoFromServer() {
    return dispatch => {
        return dispatch(loadingActionLoadResourceFromServer(
            RESOURCE_TYPE_USER_ACCOUNT_ADMIN_GHOST,
            ADMIN_GHOST_ACCOUNT_UID,
            adminActionSetGhostAccountInfo
        ));
    }
}