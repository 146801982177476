// Name: Security Body Render Definitions 

export const IP_ADDRESS_BODY_RENDER_DEF = {
	ip_hash: {displayName: "Ip Hash"},
	flagged_for_review: {displayName: "Flagged For Review"},
	lookup_complete: {displayName: "Lookup Complete"},
	ip_uid: {displayName: "Ip UID"},
	last_lookup_timestamp: {displayName: "Last Lookup Timestamp"},
	ip_address: {displayName: "Ip Address"},
	raw_ip_lookup_data: {displayName: "Raw Ip Lookup Data"},
	first_seen_timestamp: {displayName: "First Seen Timestamp"},
	last_seen_timestamp: {displayName: "Last Seen Timestamp"},
	country: {displayName: "Country"},
	city: {displayName: "City"},
	region: {displayName: "Region"}
};

export const KNOWN_IP_ADDRESS_BODY_RENDER_DEF = {
	first_use_timestamp: {displayName: "First Use Timestamp"},
	last_use_timestamp: {displayName: "Last Use Timestamp"},
	ip_address_uid: {displayName: "Ip Address UID"},
	known_ip_uid: {displayName: "Known Ip UID"},
	account_uid: {displayName: "Account UID"}
};

export const KNOWN_USER_AGENT_BODY_RENDER_DEF = {
	user_agent_uid: {displayName: "User Agent UID"},
	first_use_timestamp: {displayName: "First Use Timestamp"},
	known_user_agent_uid: {displayName: "Known User Agent UID"},
	last_use_timestamp: {displayName: "Last Use Timestamp"},
	account_uid: {displayName: "Account UID"}
};

export const USER_AGENT_BODY_RENDER_DEF = {
	user_agent_uid: {displayName: "User Agent UID"},
	device_brand: {displayName: "Device Brand"},
	last_lookup_timestamp: {displayName: "Last Lookup Timestamp"},
	user_agent_hash: {displayName: "User Agent Hash"},
	os_family: {displayName: "Os Family"},
	os_version: {displayName: "Os Version"},
	device_family: {displayName: "Device Family"},
	device_model: {displayName: "Device Model"},
	first_seen_timestamp: {displayName: "First Seen Timestamp"},
	browser_version: {displayName: "Browser Version"},
	browser_family: {displayName: "Browser Family"},
	last_seen_timestamp: {displayName: "Last Seen Timestamp"},
	raw_user_agent_string: {displayName: "Raw User Agent String"}
};

