// Client type: admin
// Name: Storage Service Custom Render Components 

import {STORAGE_OBJECT_KEY_UPLOADING_ACCOUNT_UID} from "../objects/objectKeys";
import AdminViewFullAccountInfoLink from "../../../components/admin/buttons/linkButtons/adminViewFullAccountInfoLink";

export const ATTACHED_STORAGE_FILES_CUSTOM_RENDER_COMPONENTS = {};

export const STORAGE_FILE_CUSTOM_RENDER_COMPONENTS = {
    [STORAGE_OBJECT_KEY_UPLOADING_ACCOUNT_UID]: {
        dataPropName: 'accountUID',
        renderComp: AdminViewFullAccountInfoLink,
        passDataObject: false,
        extraProps: {showExtraInfo: true}
    },
};
