/*

 */

import React from 'react';
import {capitalize} from "../../../utilities/commonUtils";
import {getDepositDisplayIcon, getDepositStateColor} from "../../../utilities/dataDisplayUtilities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {DEPOSIT_STATE_CLOSED} from "../../../autoGenerated/constants/services/DepositsServiceConstants";

export default function AdminDepositStateRender({deposit, large=false}) {
    const state = deposit.state;

    const colour = getDepositStateColor(state);
    const renderIcon = getDepositDisplayIcon(state);
    const iconSize = large ? "2x" : "1x";
    const typeSize = large ? "h5" : "body2";
    const heldByPartner = deposit.funds_held_by_partner === true;
    let displayText = capitalize(state);

    if(heldByPartner && state === DEPOSIT_STATE_CLOSED){
        displayText = "Closed & Transferred to Partner";
    }

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            wrap={'nowrap'}
        >
            <Grid item>
                <FontAwesomeIcon
                    icon={renderIcon}
                    fixedWidth
                    size={iconSize}
                    color={colour}
                />
            </Grid>

            <Grid item>
                <Typography variant={typeSize}>
                &nbsp;{displayText}
                </Typography>
            </Grid>
        </Grid>
    );

}