/*

 */

import React from 'react';
import {uuidToHex} from "../../../utilities/commonUtils";
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import {renderPermissionsList} from "../../../utilities/permissionUtilities";

export default function AdminPermissionDisplay({permission, isOrgEntry, ...props}) {

    return (
        <React.Fragment>
            Owner: {renderOwnerUID()}
            <br/>
            Disabled: {permission.disabled.toString()}
            <br/>
            {renderPermissionsList(permission.permissions)}
        </React.Fragment>
    );

    function renderOwnerUID(){
        if(isOrgEntry === true){
            return <React.Fragment>{uuidToHex(permission.owner_uid)}</React.Fragment>;
        }

        return <AdminViewFullAccountInfoLink accountUID={permission.owner_uid}/>;
    }

}
