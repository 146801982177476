// Name: JSON Schemas Mapping 


import {
	RESOURCE_TYPE_USER_ACCOUNT,
	RESOURCE_TYPE_USER_ACCOUNT_BALANCE,
	RESOURCE_TYPE_USER_ACCOUNT_BALANCE_SOURCE,
	RESOURCE_TYPE_USER_HISTORY_ENTRY,
	RESOURCE_TYPE_BALANCE_CHANGE_LOG,
	RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT,
	RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT,
	RESOURCE_TYPE_USER_BANK_ACCOUNT_DELTA,
	RESOURCE_TYPE_USER_ACCOUNT_SETTINGS,
	RESOURCE_TYPE_USER_ACCOUNT_ADMIN_GHOST,
	RESOURCE_TYPE_USER_ACCOUNT_FINALIZATION,
	RESOURCE_TYPE_THIRD_PARTY_USER_ACCOUNT_MAPPING,
	RESOURCE_TYPE_PREVIOUS_EMAIL_ADDRESS,
	RESOURCE_TYPE_PENDING_EMAIL_CHANGE,
	RESOURCE_TYPE_ARCHIVED_PENDING_EMAIL_CHANGE,
	RESOURCE_TYPE_PASSWORD,
	RESOURCE_TYPE_EXPIRED_PASSWORD,
	RESOURCE_TYPE_PASSWORD_RESET,
	RESOURCE_TYPE_DEPOSIT,
	RESOURCE_TYPE_DEPOSIT_INVITE,
	RESOURCE_TYPE_DEPOSIT_ADJUSTMENT,
	RESOURCE_TYPE_DEPOSIT_INTEREST,
	RESOURCE_TYPE_DEPOSIT_HISTORY_ENTRY,
	RESOURCE_TYPE_DEPOSIT_CLOSE_REQUEST,
	RESOURCE_TYPE_DEPOSIT_USER_STATS,
	RESOURCE_TYPE_DEPOSIT_BANK_MAPPING,
	RESOURCE_TYPE_DEPOSITS_BULK_IMPORT,
	RESOURCE_TYPE_DEPOSIT_INTEREST_LOG,
	RESOURCE_TYPE_ARCHIVED_DEPOSIT_CLOSE_REQUEST,
	RESOURCE_TYPE_DEPOSIT_WITHHELD_FUNDS,
	RESOURCE_TYPE_PMC_REPORT_TIMETABLE,
	RESOURCE_TYPE_DEPOSIT_GROUP,
	RESOURCE_TYPE_DEPOSIT_POST_CLOSE_ADJUSTMENT,
	RESOURCE_TYPE_DEPOSIT_USER_GROUP,
	RESOURCE_TYPE_PROPERTY_GROUP,
	RESOURCE_TYPE_PROPERTY_USER_GROUP,
	RESOURCE_TYPE_DISPUTE,
	RESOURCE_TYPE_DISPUTE_SPLIT,
	RESOURCE_TYPE_DISPUTE_INTERVENTION_REQUEST,
	RESOURCE_TYPE_PROPERTY,
	RESOURCE_TYPE_PROPERTY_ADDRESS,
	RESOURCE_TYPE_COMPLIANCE,
	RESOURCE_TYPE_COMPLIANCE_RULE,
	RESOURCE_TYPE_COMPLIANCE_HISTORY,
	RESOURCE_TYPE_EMAIL_TEMPLATE,
	RESOURCE_TYPE_EMAIL_VERIFICATION,
	RESOURCE_TYPE_EMAIL_VERIFICATION_RECORD,
	RESOURCE_TYPE_EMAIL_EVENT,
	RESOURCE_TYPE_SENT_EMAIL,
	RESOURCE_TYPE_MAILBOX,
	RESOURCE_TYPE_MAILBOX_META_DATA,
	RESOURCE_TYPE_MAILBOX_MESSAGE,
	RESOURCE_TYPE_PERMISSION_ENTRY,
	RESOURCE_TYPE_PERMISSION_LOG_ENTRY,
	RESOURCE_TYPE_PERMISSIONS_ORGANIZATION_SETTINGS,
	RESOURCE_TYPE_RESOURCE_PERMISSIONS,
	RESOURCE_TYPE_ORGANIZATION,
	RESOURCE_TYPE_ORGANIZATION_RESOURCE_PERMISSION_RULE,
	RESOURCE_TYPE_INVOICE,
	RESOURCE_TYPE_INVOICE_ITEM,
	RESOURCE_TYPE_PAYMENT_REQUEST,
	RESOURCE_TYPE_STRIPE_PAYMENT_LOG,
	RESOURCE_TYPE_USER_WITHDRAW,
	RESOURCE_TYPE_PRIORITY_ACH_PAYMENT,
	RESOURCE_TYPE_PRIORITY_ACH_RECURRING_PAYMENT,
	RESOURCE_TYPE_LOAN_APPLICATION,
	RESOURCE_TYPE_LOAN_SYSTEM_INFO,
	RESOURCE_TYPE_LOAN,
	RESOURCE_TYPE_LOAN_HISTORY,
	RESOURCE_TYPE_LOAN_PAYMENT_LOG,
	RESOURCE_TYPE_STRIPE_WEBHOOK_LOG,
	RESOURCE_TYPE_USER_ACCOUNT_PAYMENT_PROVIDER_INFO,
	RESOURCE_TYPE_SEZZLE_ORDER,
	RESOURCE_TYPE_SEZZLE_WEBHOOK_EVENT,
	RESOURCE_TYPE_BANK_ACCOUNT,
	RESOURCE_TYPE_BANK_ACCOUNT_HISTORICAL_INTEREST_RATE,
	RESOURCE_TYPE_BANK_ACCOUNT_INTEREST,
	RESOURCE_TYPE_BANK_ACCOUNT_BALANCE_RECORD,
	RESOURCE_TYPE_BANK_TRANSFER,
	RESOURCE_TYPE_BULK_FUNDS_TRANSFER,
	RESOURCE_TYPE_FUNDS_LOCK,
	RESOURCE_TYPE_FUNDS_LOCK_RELEASE_LOG,
	RESOURCE_TYPE_RELEASED_FUNDS_LOCK,
	RESOURCE_TYPE_BALANCE_SOURCE_TRANSFER,
	RESOURCE_TYPE_CREDIT_USER_ACCOUNT_LOG,
	RESOURCE_TYPE_EVENT,
	RESOURCE_TYPE_EVENT_HOOK,
	RESOURCE_TYPE_NOTIFICATION,
	RESOURCE_TYPE_ACTION_ITEM,
	RESOURCE_TYPE_UPDATE_LOG_ENTRY,
	RESOURCE_TYPE_STORAGE_FILE,
	RESOURCE_TYPE_ATTACHED_STORAGE_FILES,
	RESOURCE_TYPE_SYSTEM_HISTORY_ENTRY,
	RESOURCE_TYPE_SNAPSHOT,
	RESOURCE_TYPE_IP_ADDRESS,
	RESOURCE_TYPE_KNOWN_IP_ADDRESS,
	RESOURCE_TYPE_USER_AGENT,
	RESOURCE_TYPE_KNOWN_USER_AGENT,
	RESOURCE_TYPE_SYSTEM_STATS,
	RESOURCE_TYPE_RESOURCE_NOTE,
	RESOURCE_TYPE_DEPOSIFY_TAG,
	RESOURCE_TYPE_PERMANENT_LINK,
	RESOURCE_TYPE_ONLINE_USER,
	RESOURCE_TYPE_FRONTEND_GENERAL_SETTINGS,
	RESOURCE_TYPE_YARDI_SCREENING_WORKS_PRO_XML,
	RESOURCE_TYPE_YARDI_VOYAGER_XML,
	RESOURCE_TYPE_YARDI_VOYAGER_TRANSACTION,
	RESOURCE_TYPE_YARDI_VOYAGER_RESIDENT,
	RESOURCE_TYPE_YARDI_VOYAGER_RESIDENT_CUSTOM_COLUMN,
	RESOURCE_TYPE_YARDI_VOYAGER_PROPERTY_UNIT,
	RESOURCE_TYPE_YARDI_VOYAGER_PROPERTY,
	RESOURCE_TYPE_YARDI_VOYAGER_PMC_SERVICE_INTERFACE_VERSION,
	RESOURCE_TYPE_YARDI_VOYAGER_PMC,
	RESOURCE_TYPE_YARDI_VOYAGER_PMC_CREDENTIALS,
	RESOURCE_TYPE_YARDI_VOYAGER_PET,
	RESOURCE_TYPE_YARDI_VOYAGER_ILS_GUEST_CARD_EVENT,
	RESOURCE_TYPE_YARDI_VOYAGER_CHARGE,
	RESOURCE_TYPE_YARDI_VOYAGER_CHARGE_CODE,
	RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_CREATE_INTENT,
	RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_CLOSE_INTENT,
	RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_WITHHOLD_INTENT,
	RESOURCE_TYPE_YARDI_VOYAGER_EVENT,
	RESOURCE_TYPE_YARDI_VOYAGER_FLEX_DEPOSIT_INTENT,
	RESOURCE_TYPE_YARDI_VOYAGER_PHONE,
	RESOURCE_TYPE_YARDI_VOYAGER_OTHER_OCCUPANT,
	RESOURCE_TYPE_YARDI_VOYAGER_PMC_CUSTOM_COLUMN_SETTINGS,
	RESOURCE_TYPE_YARDI_VOYAGER_PMC_STATS,
	RESOURCE_TYPE_YARDI_VOYAGER_PMC_STATS_COLLECTOR,
	RESOURCE_TYPE_YARDI_VOYAGER_ACTIVE_SCAN_LOG_ENTRY,
	RESOURCE_TYPE_YARDI_VOYAGER_SYSTEM_SETTINGS
} from "../constants/resourceTypeConstants";
import {
	BANK_ACCOUNT_BALANCE_RECORD_JSON_SCHEMA,
	BANK_ACCOUNT_JSON_SCHEMA,
	BANK_ACCOUNT_HISTORICAL_INTEREST_RATE_JSON_SCHEMA,
	BANK_ACCOUNT_INTEREST_JSON_SCHEMA,
	BANK_TRANSFER_JSON_SCHEMA,
	BULK_FUNDS_TRANSFER_JSON_SCHEMA,
} from "./BankingServiceJSONSchemas.js";
import {
	ARCHIVED_DEPOSIT_CLOSE_REQUEST_JSON_SCHEMA,
	COMPLIANCE_JSON_SCHEMA,
	COMPLIANCE_HISTORY_JSON_SCHEMA,
	COMPLIANCE_RULE_JSON_SCHEMA,
	DEPOSIT_JSON_SCHEMA,
	DEPOSIT_ADJUSTMENT_JSON_SCHEMA,
	DEPOSIT_BANK_MAPPING_JSON_SCHEMA,
	DEPOSIT_BULK_IMPORT_JSON_SCHEMA,
	DEPOSIT_CLOSE_REQUEST_JSON_SCHEMA,
	DEPOSIT_GROUP_JSON_SCHEMA,
	DEPOSIT_INTEREST_JSON_SCHEMA,
	DEPOSIT_INTEREST_LOG_JSON_SCHEMA,
	DEPOSIT_INVITE_JSON_SCHEMA,
	DEPOSIT_POST_CLOSE_ADJUSTMENT_JSON_SCHEMA,
	DEPOSIT_USER_GROUP_JSON_SCHEMA,
	DEPOSIT_USER_STATS_JSON_SCHEMA,
	DEPOSIT_WITHHELD_FUNDS_JSON_SCHEMA,
	DISPUTE_JSON_SCHEMA,
	DISPUTE_INTERVENTION_REQUEST_JSON_SCHEMA,
	DISPUTE_SPLIT_JSON_SCHEMA,
	PMC_REPORT_TIMETABLE_JSON_SCHEMA,
	PROPERTY_JSON_SCHEMA,
	PROPERTY_ADDRESS_JSON_SCHEMA,
	PROPERTY_GROUP_JSON_SCHEMA,
	PROPERTY_USER_GROUP_JSON_SCHEMA,
} from "./DepositsServiceJSONSchemas.js";
import {
	ACTION_ITEM_JSON_SCHEMA,
	EVENT_HOOK_JSON_SCHEMA,
	EVENT_JSON_SCHEMA,
	NOTIFICATION_JSON_SCHEMA,
} from "./EventsServiceJSONSchemas.js";
import {
	FRONTEND_GENERAL_SETTINGS_JSON_SCHEMA,
	ONLINE_USER_JSON_SCHEMA,
} from "./FrontendServiceJSONSchemas.js";
import {
	SNAPSHOT_JSON_SCHEMA,
	SYSTEM_HISTORY_ENTRY_JSON_SCHEMA,
	DEPOSIT_HISTORY_ENTRY_JSON_SCHEMA,
	UPDATE_LOG_JSON_SCHEMA,
	USER_HISTORY_ENTRY_JSON_SCHEMA,
} from "./HistoryServiceJSONSchemas.js";
import {
	EMAIL_EVENT_JSON_SCHEMA,
	EMAIL_TEMPLATE_JSON_SCHEMA,
	MAILBOX_JSON_SCHEMA,
	MAILBOX_MESSAGE_JSON_SCHEMA,
	MAILBOX_META_DATA_JSON_SCHEMA,
	SENT_EMAIL_JSON_SCHEMA,
} from "./MessagingServiceJSONSchemas.js";
import {
	INVOICE_JSON_SCHEMA,
	INVOICE_ITEM_JSON_SCHEMA,
	LOAN_JSON_SCHEMA,
	LOAN_APPLICATION_JSON_SCHEMA,
	LOAN_HISTORY_JSON_SCHEMA,
	LOAN_PAYMENT_LOG_JSON_SCHEMA,
	LOAN_SYSTEM_INFO_JSON_SCHEMA,
	PAYMENT_REQUEST_JSON_SCHEMA,
	PRIORITY_ACH_PAYMENT_JSON_SCHEMA,
	PRIORITY_ACH_RECURRING_PAYMENT_JSON_SCHEMA,
	SEZZLE_ORDER_JSON_SCHEMA,
	SEZZLE_WEBHOOK_EVENT_JSON_SCHEMA,
	STRIPE_PAYMENT_LOG_JSON_SCHEMA,
	STRIPE_WEBHOOK_LOG_JSON_SCHEMA,
	USER_ACCOUNT_PAYMENT_PROVIDER_INFO_JSON_SCHEMA,
	USER_WITHDRAW_JSON_SCHEMA,
} from "./PaymentsServiceJSONSchemas.js";
import {
	ORGANIZATION_JSON_SCHEMA,
	ORGANIZATION_RESOURCE_PERMISSION_RULE_JSON_SCHEMA,
	PERMISSIONS_ORGANIZATION_SETTINGS_JSON_SCHEMA,
	PERMISSION_ENTRY_JSON_SCHEMA,
	PERMISSION_LOG_ENTRY_JSON_SCHEMA,
	RESOURCE_PERMISSION_JSON_SCHEMA,
} from "./PermissionsServiceJSONSchemas.js";
import {
} from "./SearchServiceJSONSchemas.js";
import {
	IP_ADDRESS_JSON_SCHEMA,
	KNOWN_IP_ADDRESS_JSON_SCHEMA,
	KNOWN_USER_AGENT_JSON_SCHEMA,
	USER_AGENT_JSON_SCHEMA,
} from "./SecurityServiceJSONSchemas.js";
import {
	ATTACHED_STORAGE_FILES_JSON_SCHEMA,
	STORAGE_FILE_JSON_SCHEMA,
} from "./StorageServiceJSONSchemas.js";
import {
	DEPOSIFY_TAG_JSON_SCHEMA,
	PERMANENT_LINK_JSON_SCHEMA,
	RESOURCE_NOTE_JSON_SCHEMA,
	SYSTEM_STATS_JSON_SCHEMA,
} from "./SystemGeneralServiceJSONSchemas.js";
import {
	ADMIN_GHOST_USER_ACCOUNT_JSON_SCHEMA,
	ARCHIVED_PENDING_EMAIL_CHANGE_JSON_SCHEMA,
	BALANCE_CHANGE_LOG_JSON_SCHEMA,
	BALANCE_SOURCE_TRANSFER_JSON_SCHEMA,
	CREDIT_USER_ACCOUNT_LOG_JSON_SCHEMA,
	EMAIL_VERIFICATION_JSON_SCHEMA,
	EMAIL_VERIFICATION_RECORD_JSON_SCHEMA,
	EXPIRED_PASSWORD_JSON_SCHEMA,
	FUNDS_LOCK_JSON_SCHEMA,
	FUNDS_LOCK_RELEASE_LOG_JSON_SCHEMA,
	INTERNATIONAL_USER_BANK_ACCOUNT_JSON_SCHEMA,
	PASSWORD_JSON_SCHEMA,
	PASSWORD_RESET_JSON_SCHEMA,
	PENDING_EMAIL_CHANGE_JSON_SCHEMA,
	PREVIOUS_EMAIL_ADDRESS_JSON_SCHEMA,
	RELEASED_FUNDS_LOCK_JSON_SCHEMA,
	THIRD_PARTY_USER_ACCOUNT_MAPPING_JSON_SCHEMA,
	USER_ACCOUNT_JSON_SCHEMA,
	USER_ACCOUNT_BALANCE_JSON_SCHEMA,
	USER_ACCOUNT_BALANCE_SOURCE_JSON_SCHEMA,
	USER_ACCOUNT_FINALIZATION_JSON_SCHEMA,
	USER_ACCOUNT_SETTINGS_JSON_SCHEMA,
	USER_BANK_ACCOUNT_DELTA_JSON_SCHEMA,
	US_DOMESTIC_USER_BANK_ACCOUNT_JSON_SCHEMA,
} from "./UserAccountsServiceJSONSchemas.js";
import {
	YARDI_SCREENING_WORKS_PRO_XML_JSON_SCHEMA,
	YARDI_VOYAGER_ACTIVE_SCAN_LOG_ENTRY_JSON_SCHEMA,
	YARDI_VOYAGER_CHARGE_JSON_SCHEMA,
	YARDI_VOYAGER_CHARGE_CODE_JSON_SCHEMA,
	YARDI_VOYAGER_DEPOSIT_CLOSE_INTENT_JSON_SCHEMA,
	YARDI_VOYAGER_DEPOSIT_CREATE_INTENT_JSON_SCHEMA,
	YARDI_VOYAGER_DEPOSIT_WITHHOLD_INTENT_JSON_SCHEMA,
	YARDI_VOYAGER_EVENT_JSON_SCHEMA,
	YARDI_VOYAGER_FLEX_DEPOSIT_INTENT_JSON_SCHEMA,
	YARDI_VOYAGER_ILS_GUEST_CARD_EVENT_JSON_SCHEMA,
	YARDI_VOYAGER_OTHER_OCCUPANT_JSON_SCHEMA,
	YARDI_VOYAGER_PET_JSON_SCHEMA,
	YARDI_VOYAGER_PHONE_JSON_SCHEMA,
	YARDI_VOYAGER_PMC_JSON_SCHEMA,
	YARDI_VOYAGER_PMC_CREDENTIALS_JSON_SCHEMA,
	YARDI_VOYAGER_PMC_CUSTOM_COLUMN_SETTINGS_JSON_SCHEMA,
	YARDI_VOYAGER_PMC_SERVICE_INTERFACE_VERSION_JSON_SCHEMA,
	YARDI_VOYAGER_PMC_STATS_JSON_SCHEMA,
	YARDI_VOYAGER_PMC_STATS_COLLECTOR_JSON_SCHEMA,
	YARDI_VOYAGER_PROPERTY_JSON_SCHEMA,
	YARDI_VOYAGER_PROPERTY_UNIT_JSON_SCHEMA,
	YARDI_VOYAGER_RESIDENT_JSON_SCHEMA,
	YARDI_VOYAGER_RESIDENT_CUSTOM_COLUMN_JSON_SCHEMA,
	YARDI_VOYAGER_SYSTEM_SETTINGS_JSON_SCHEMA,
	YARDI_VOYAGER_TRANSACTION_JSON_SCHEMA,
	YARDI_VOYAGER_XML_JSON_SCHEMA,
} from "./YardiServiceJSONSchemas.js";

export const RESOURCE_TO_JSON_SCHEMA_DICT = {
	[RESOURCE_TYPE_BANK_ACCOUNT_BALANCE_RECORD]: BANK_ACCOUNT_BALANCE_RECORD_JSON_SCHEMA,
	[RESOURCE_TYPE_BANK_ACCOUNT]: BANK_ACCOUNT_JSON_SCHEMA,
	[RESOURCE_TYPE_BANK_ACCOUNT_HISTORICAL_INTEREST_RATE]: BANK_ACCOUNT_HISTORICAL_INTEREST_RATE_JSON_SCHEMA,
	[RESOURCE_TYPE_BANK_ACCOUNT_INTEREST]: BANK_ACCOUNT_INTEREST_JSON_SCHEMA,
	[RESOURCE_TYPE_BANK_TRANSFER]: BANK_TRANSFER_JSON_SCHEMA,
	[RESOURCE_TYPE_BULK_FUNDS_TRANSFER]: BULK_FUNDS_TRANSFER_JSON_SCHEMA,
	[RESOURCE_TYPE_ARCHIVED_DEPOSIT_CLOSE_REQUEST]: ARCHIVED_DEPOSIT_CLOSE_REQUEST_JSON_SCHEMA,
	[RESOURCE_TYPE_COMPLIANCE]: COMPLIANCE_JSON_SCHEMA,
	[RESOURCE_TYPE_COMPLIANCE_HISTORY]: COMPLIANCE_HISTORY_JSON_SCHEMA,
	[RESOURCE_TYPE_COMPLIANCE_RULE]: COMPLIANCE_RULE_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT]: DEPOSIT_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_ADJUSTMENT]: DEPOSIT_ADJUSTMENT_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_BANK_MAPPING]: DEPOSIT_BANK_MAPPING_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSITS_BULK_IMPORT]: DEPOSIT_BULK_IMPORT_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_CLOSE_REQUEST]: DEPOSIT_CLOSE_REQUEST_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_GROUP]: DEPOSIT_GROUP_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_INTEREST]: DEPOSIT_INTEREST_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_INTEREST_LOG]: DEPOSIT_INTEREST_LOG_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_INVITE]: DEPOSIT_INVITE_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_POST_CLOSE_ADJUSTMENT]: DEPOSIT_POST_CLOSE_ADJUSTMENT_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_USER_GROUP]: DEPOSIT_USER_GROUP_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_USER_STATS]: DEPOSIT_USER_STATS_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_WITHHELD_FUNDS]: DEPOSIT_WITHHELD_FUNDS_JSON_SCHEMA,
	[RESOURCE_TYPE_DISPUTE]: DISPUTE_JSON_SCHEMA,
	[RESOURCE_TYPE_DISPUTE_INTERVENTION_REQUEST]: DISPUTE_INTERVENTION_REQUEST_JSON_SCHEMA,
	[RESOURCE_TYPE_DISPUTE_SPLIT]: DISPUTE_SPLIT_JSON_SCHEMA,
	[RESOURCE_TYPE_PMC_REPORT_TIMETABLE]: PMC_REPORT_TIMETABLE_JSON_SCHEMA,
	[RESOURCE_TYPE_PROPERTY]: PROPERTY_JSON_SCHEMA,
	[RESOURCE_TYPE_PROPERTY_ADDRESS]: PROPERTY_ADDRESS_JSON_SCHEMA,
	[RESOURCE_TYPE_PROPERTY_GROUP]: PROPERTY_GROUP_JSON_SCHEMA,
	[RESOURCE_TYPE_PROPERTY_USER_GROUP]: PROPERTY_USER_GROUP_JSON_SCHEMA,
	[RESOURCE_TYPE_ACTION_ITEM]: ACTION_ITEM_JSON_SCHEMA,
	[RESOURCE_TYPE_EVENT_HOOK]: EVENT_HOOK_JSON_SCHEMA,
	[RESOURCE_TYPE_EVENT]: EVENT_JSON_SCHEMA,
	[RESOURCE_TYPE_NOTIFICATION]: NOTIFICATION_JSON_SCHEMA,
	[RESOURCE_TYPE_FRONTEND_GENERAL_SETTINGS]: FRONTEND_GENERAL_SETTINGS_JSON_SCHEMA,
	[RESOURCE_TYPE_ONLINE_USER]: ONLINE_USER_JSON_SCHEMA,
	[RESOURCE_TYPE_SNAPSHOT]: SNAPSHOT_JSON_SCHEMA,
	[RESOURCE_TYPE_SYSTEM_HISTORY_ENTRY]: SYSTEM_HISTORY_ENTRY_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIT_HISTORY_ENTRY]: DEPOSIT_HISTORY_ENTRY_JSON_SCHEMA,
	[RESOURCE_TYPE_UPDATE_LOG_ENTRY]: UPDATE_LOG_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_HISTORY_ENTRY]: USER_HISTORY_ENTRY_JSON_SCHEMA,
	[RESOURCE_TYPE_EMAIL_EVENT]: EMAIL_EVENT_JSON_SCHEMA,
	[RESOURCE_TYPE_EMAIL_TEMPLATE]: EMAIL_TEMPLATE_JSON_SCHEMA,
	[RESOURCE_TYPE_MAILBOX]: MAILBOX_JSON_SCHEMA,
	[RESOURCE_TYPE_MAILBOX_MESSAGE]: MAILBOX_MESSAGE_JSON_SCHEMA,
	[RESOURCE_TYPE_MAILBOX_META_DATA]: MAILBOX_META_DATA_JSON_SCHEMA,
	[RESOURCE_TYPE_SENT_EMAIL]: SENT_EMAIL_JSON_SCHEMA,
	[RESOURCE_TYPE_INVOICE]: INVOICE_JSON_SCHEMA,
	[RESOURCE_TYPE_INVOICE_ITEM]: INVOICE_ITEM_JSON_SCHEMA,
	[RESOURCE_TYPE_LOAN]: LOAN_JSON_SCHEMA,
	[RESOURCE_TYPE_LOAN_APPLICATION]: LOAN_APPLICATION_JSON_SCHEMA,
	[RESOURCE_TYPE_LOAN_HISTORY]: LOAN_HISTORY_JSON_SCHEMA,
	[RESOURCE_TYPE_LOAN_PAYMENT_LOG]: LOAN_PAYMENT_LOG_JSON_SCHEMA,
	[RESOURCE_TYPE_LOAN_SYSTEM_INFO]: LOAN_SYSTEM_INFO_JSON_SCHEMA,
	[RESOURCE_TYPE_PAYMENT_REQUEST]: PAYMENT_REQUEST_JSON_SCHEMA,
	[RESOURCE_TYPE_PRIORITY_ACH_PAYMENT]: PRIORITY_ACH_PAYMENT_JSON_SCHEMA,
	[RESOURCE_TYPE_PRIORITY_ACH_RECURRING_PAYMENT]: PRIORITY_ACH_RECURRING_PAYMENT_JSON_SCHEMA,
	[RESOURCE_TYPE_SEZZLE_ORDER]: SEZZLE_ORDER_JSON_SCHEMA,
	[RESOURCE_TYPE_SEZZLE_WEBHOOK_EVENT]: SEZZLE_WEBHOOK_EVENT_JSON_SCHEMA,
	[RESOURCE_TYPE_STRIPE_PAYMENT_LOG]: STRIPE_PAYMENT_LOG_JSON_SCHEMA,
	[RESOURCE_TYPE_STRIPE_WEBHOOK_LOG]: STRIPE_WEBHOOK_LOG_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_ACCOUNT_PAYMENT_PROVIDER_INFO]: USER_ACCOUNT_PAYMENT_PROVIDER_INFO_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_WITHDRAW]: USER_WITHDRAW_JSON_SCHEMA,
	[RESOURCE_TYPE_ORGANIZATION]: ORGANIZATION_JSON_SCHEMA,
	[RESOURCE_TYPE_ORGANIZATION_RESOURCE_PERMISSION_RULE]: ORGANIZATION_RESOURCE_PERMISSION_RULE_JSON_SCHEMA,
	[RESOURCE_TYPE_PERMISSIONS_ORGANIZATION_SETTINGS]: PERMISSIONS_ORGANIZATION_SETTINGS_JSON_SCHEMA,
	[RESOURCE_TYPE_PERMISSION_ENTRY]: PERMISSION_ENTRY_JSON_SCHEMA,
	[RESOURCE_TYPE_PERMISSION_LOG_ENTRY]: PERMISSION_LOG_ENTRY_JSON_SCHEMA,
	[RESOURCE_TYPE_RESOURCE_PERMISSIONS]: RESOURCE_PERMISSION_JSON_SCHEMA,
	[RESOURCE_TYPE_IP_ADDRESS]: IP_ADDRESS_JSON_SCHEMA,
	[RESOURCE_TYPE_KNOWN_IP_ADDRESS]: KNOWN_IP_ADDRESS_JSON_SCHEMA,
	[RESOURCE_TYPE_KNOWN_USER_AGENT]: KNOWN_USER_AGENT_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_AGENT]: USER_AGENT_JSON_SCHEMA,
	[RESOURCE_TYPE_ATTACHED_STORAGE_FILES]: ATTACHED_STORAGE_FILES_JSON_SCHEMA,
	[RESOURCE_TYPE_STORAGE_FILE]: STORAGE_FILE_JSON_SCHEMA,
	[RESOURCE_TYPE_DEPOSIFY_TAG]: DEPOSIFY_TAG_JSON_SCHEMA,
	[RESOURCE_TYPE_PERMANENT_LINK]: PERMANENT_LINK_JSON_SCHEMA,
	[RESOURCE_TYPE_RESOURCE_NOTE]: RESOURCE_NOTE_JSON_SCHEMA,
	[RESOURCE_TYPE_SYSTEM_STATS]: SYSTEM_STATS_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_ACCOUNT_ADMIN_GHOST]: ADMIN_GHOST_USER_ACCOUNT_JSON_SCHEMA,
	[RESOURCE_TYPE_ARCHIVED_PENDING_EMAIL_CHANGE]: ARCHIVED_PENDING_EMAIL_CHANGE_JSON_SCHEMA,
	[RESOURCE_TYPE_BALANCE_CHANGE_LOG]: BALANCE_CHANGE_LOG_JSON_SCHEMA,
	[RESOURCE_TYPE_BALANCE_SOURCE_TRANSFER]: BALANCE_SOURCE_TRANSFER_JSON_SCHEMA,
	[RESOURCE_TYPE_CREDIT_USER_ACCOUNT_LOG]: CREDIT_USER_ACCOUNT_LOG_JSON_SCHEMA,
	[RESOURCE_TYPE_EMAIL_VERIFICATION]: EMAIL_VERIFICATION_JSON_SCHEMA,
	[RESOURCE_TYPE_EMAIL_VERIFICATION_RECORD]: EMAIL_VERIFICATION_RECORD_JSON_SCHEMA,
	[RESOURCE_TYPE_EXPIRED_PASSWORD]: EXPIRED_PASSWORD_JSON_SCHEMA,
	[RESOURCE_TYPE_FUNDS_LOCK]: FUNDS_LOCK_JSON_SCHEMA,
	[RESOURCE_TYPE_FUNDS_LOCK_RELEASE_LOG]: FUNDS_LOCK_RELEASE_LOG_JSON_SCHEMA,
	[RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT]: INTERNATIONAL_USER_BANK_ACCOUNT_JSON_SCHEMA,
	[RESOURCE_TYPE_PASSWORD]: PASSWORD_JSON_SCHEMA,
	[RESOURCE_TYPE_PASSWORD_RESET]: PASSWORD_RESET_JSON_SCHEMA,
	[RESOURCE_TYPE_PENDING_EMAIL_CHANGE]: PENDING_EMAIL_CHANGE_JSON_SCHEMA,
	[RESOURCE_TYPE_PREVIOUS_EMAIL_ADDRESS]: PREVIOUS_EMAIL_ADDRESS_JSON_SCHEMA,
	[RESOURCE_TYPE_RELEASED_FUNDS_LOCK]: RELEASED_FUNDS_LOCK_JSON_SCHEMA,
	[RESOURCE_TYPE_THIRD_PARTY_USER_ACCOUNT_MAPPING]: THIRD_PARTY_USER_ACCOUNT_MAPPING_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_ACCOUNT]: USER_ACCOUNT_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_ACCOUNT_BALANCE]: USER_ACCOUNT_BALANCE_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_ACCOUNT_BALANCE_SOURCE]: USER_ACCOUNT_BALANCE_SOURCE_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_ACCOUNT_FINALIZATION]: USER_ACCOUNT_FINALIZATION_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_ACCOUNT_SETTINGS]: USER_ACCOUNT_SETTINGS_JSON_SCHEMA,
	[RESOURCE_TYPE_USER_BANK_ACCOUNT_DELTA]: USER_BANK_ACCOUNT_DELTA_JSON_SCHEMA,
	[RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT]: US_DOMESTIC_USER_BANK_ACCOUNT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_SCREENING_WORKS_PRO_XML]: YARDI_SCREENING_WORKS_PRO_XML_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_ACTIVE_SCAN_LOG_ENTRY]: YARDI_VOYAGER_ACTIVE_SCAN_LOG_ENTRY_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_CHARGE]: YARDI_VOYAGER_CHARGE_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_CHARGE_CODE]: YARDI_VOYAGER_CHARGE_CODE_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_CLOSE_INTENT]: YARDI_VOYAGER_DEPOSIT_CLOSE_INTENT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_CREATE_INTENT]: YARDI_VOYAGER_DEPOSIT_CREATE_INTENT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_DEPOSIT_WITHHOLD_INTENT]: YARDI_VOYAGER_DEPOSIT_WITHHOLD_INTENT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_EVENT]: YARDI_VOYAGER_EVENT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_FLEX_DEPOSIT_INTENT]: YARDI_VOYAGER_FLEX_DEPOSIT_INTENT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_ILS_GUEST_CARD_EVENT]: YARDI_VOYAGER_ILS_GUEST_CARD_EVENT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_OTHER_OCCUPANT]: YARDI_VOYAGER_OTHER_OCCUPANT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PET]: YARDI_VOYAGER_PET_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PHONE]: YARDI_VOYAGER_PHONE_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PMC]: YARDI_VOYAGER_PMC_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PMC_CREDENTIALS]: YARDI_VOYAGER_PMC_CREDENTIALS_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PMC_CUSTOM_COLUMN_SETTINGS]: YARDI_VOYAGER_PMC_CUSTOM_COLUMN_SETTINGS_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PMC_SERVICE_INTERFACE_VERSION]: YARDI_VOYAGER_PMC_SERVICE_INTERFACE_VERSION_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PMC_STATS]: YARDI_VOYAGER_PMC_STATS_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PMC_STATS_COLLECTOR]: YARDI_VOYAGER_PMC_STATS_COLLECTOR_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PROPERTY]: YARDI_VOYAGER_PROPERTY_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_PROPERTY_UNIT]: YARDI_VOYAGER_PROPERTY_UNIT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_RESIDENT]: YARDI_VOYAGER_RESIDENT_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_RESIDENT_CUSTOM_COLUMN]: YARDI_VOYAGER_RESIDENT_CUSTOM_COLUMN_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_SYSTEM_SETTINGS]: YARDI_VOYAGER_SYSTEM_SETTINGS_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_TRANSACTION]: YARDI_VOYAGER_TRANSACTION_JSON_SCHEMA,
	[RESOURCE_TYPE_YARDI_VOYAGER_XML]: YARDI_VOYAGER_XML_JSON_SCHEMA,
};

export function getJSONSchema(resourceType){
	return RESOURCE_TO_JSON_SCHEMA_DICT[resourceType];
}
