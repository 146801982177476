import React from 'react';
import Grid from "@material-ui/core/Grid";
import {
    RESOURCE_TYPE_CREDIT_USER_ACCOUNT_LOG,
    RESOURCE_TYPE_DEPOSIT,
    RESOURCE_TYPE_ONLINE_USER,
    RESOURCE_TYPE_PRIORITY_ACH_PAYMENT,
    RESOURCE_TYPE_STRIPE_PAYMENT_LOG,
    RESOURCE_TYPE_USER_ACCOUNT
} from "../../autoGenerated/constants/resourceTypeConstants";
import {
    CREDIT_USER_ACCOUNT_LOG_ROUTE,
    DEPOSIT_ROUTE,
    ONLINE_USER_ROUTE,
    PRIORITY_ACH_PAYMENT_ROUTE,
    STRIPE_PAYMENT_LOG_ROUTE,
    USER_ACCOUNT_ROUTE
} from "../../autoGenerated/constants/routes";
import ResourcePageLink from "./ResourcePageLink";

const QUICK_LINKS = {
    [RESOURCE_TYPE_DEPOSIT]: DEPOSIT_ROUTE,
    [RESOURCE_TYPE_USER_ACCOUNT]: USER_ACCOUNT_ROUTE,
    [RESOURCE_TYPE_CREDIT_USER_ACCOUNT_LOG]: CREDIT_USER_ACCOUNT_LOG_ROUTE,
    // [RESOURCE_TYPE_COMPLIANCE]: COMPLIANCE_ROUTE,
    [RESOURCE_TYPE_PRIORITY_ACH_PAYMENT]: PRIORITY_ACH_PAYMENT_ROUTE,
    [RESOURCE_TYPE_STRIPE_PAYMENT_LOG]: STRIPE_PAYMENT_LOG_ROUTE,
    [RESOURCE_TYPE_ONLINE_USER]: ONLINE_USER_ROUTE,
}

export default function AdminQuickAccessLinks(props) {
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            {
                Object.keys(QUICK_LINKS).map((resourceType, resourceTypeIndex) => {
                    return (
                        <div key={`admin-quick-div-${resourceTypeIndex}`}>
                            <Grid item>
                                <ResourcePageLink resourceType={resourceType} pageURL={QUICK_LINKS[resourceType]}/>
                                {
                                    resourceTypeIndex < (Object.keys(QUICK_LINKS).length - 1) &&
                                    <>&nbsp;&nbsp;|&nbsp;&nbsp;</>
                                }
                            </Grid>
                        </div>
                    );
                })
            }

        </Grid>
    );


}