import React, {useState} from 'react';
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/browser";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DialogActions from "@material-ui/core/DialogActions";
import {faAt} from "@fortawesome/pro-regular-svg-icons";


const AdminVerifyEmailButton = ({accountUID, buttonText = "Set Email Verified"}) => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [showDialog, setShowDialog] = React.useState(false);

    function handleButtonPress() {
        setShowSpinner(true);
        setShowDialog(false);

        const callURL = getAdminPath(`jeff/account/${accountUID}/email/verify`);
        axiosInstance.get(callURL)
            .then(function (response) {
                setShowSpinner(false);
                toast.success('Done');
            })
            .catch(function (error) {
                setShowSpinner(false);

                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            })
    }

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
    }

    return (
        <>
            <SpinnerButton
                // Non-button props
                showSpinner={showSpinner}
                // Button props
                variant="text"
                color="primary"
                disabled={showSpinner}
                onClick={handleOpen}
            >
                {buttonText}
            </SpinnerButton>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="jeff-credit-dialog-title"
                aria-describedby="jeff-credit-description"
                maxWidth={'md'}
                fullWidth={true}
            >
                <DialogTitle id="account-dialog-title">Set Email Verified?</DialogTitle>
                <DialogContent>
                    Are you sure you verify the email for this user account?
                    <br/>
                    <br/>

                    <Button
                        color="secondary"
                        onClick={handleButtonPress}
                        variant={"outlined"}
                    >
                        <FontAwesomeIcon
                            icon={faAt}
                            fixedWidth
                        />
                        &nbsp;Yes, Verify It
                    </Button>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );

};


export default AdminVerifyEmailButton;