/*

 */

import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {adminGhostAccountInfoSelector} from "../../../selectors/adminSelectors";
import CreatedUpdatedTimestampDisplay from "../../utilities/displayAreas/CreatedUpdatedTimestampDisplay";
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import {faGhost} from "@fortawesome/pro-solid-svg-icons";
import AdminDisplayCard from "./adminDisplayCard";
import ClearAdminGhostButton from "../buttons/ClearAdminGhostButton";
import RefreshAdminGhostButton from "../buttons/RefreshAdminGhostButton";
import Grid from "@material-ui/core/Grid";

function AdminDisplayGhostAccount(props) {
    if (props.adminGhostAccountInfo === undefined) {
        return <React.Fragment/>;
    }

    return (
        <AdminDisplayCard
            cardTitle={'Ghost Info'}
            cardSubheader={''}
            headerIcon={faGhost}
            jsonObject={props.adminGhostAccountInfo}
        >
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item>
                    Ghosting account: <AdminViewFullAccountInfoLink accountUID={props.adminGhostAccountInfo.target_account_uid}/>
                </Grid>

                <Grid item>
                    Set by: <AdminViewFullAccountInfoLink accountUID={props.adminGhostAccountInfo.set_by_admin_account_uid}/>
                </Grid>

                <Grid item>
                    In use: {props.adminGhostAccountInfo.currently_in_use.toString()}
                </Grid>

                <Grid item>
                    <CreatedUpdatedTimestampDisplay
                        createdTimestamp={props.adminGhostAccountInfo.created_timestamp}
                        lastUpdatedTimestamp={props.adminGhostAccountInfo.last_updated_timestamp}
                        showTimeSinceCreation={true}
                        showTimeSinceUpdated={true}
                    />
                </Grid>

                <Grid item>
                    <ClearAdminGhostButton/>
                </Grid>

                <Grid item>
                    <RefreshAdminGhostButton/>
                </Grid>
            </Grid>

        </AdminDisplayCard>
    );

}

const mapStateToProps = (state) => ({
    adminGhostAccountInfo: adminGhostAccountInfoSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(AdminDisplayGhostAccount);