import React from 'react';
import CardHeader from "@material-ui/core/CardHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {FILE_ICON} from "../../../constants/commonIcons";
import Collapse from "@material-ui/core/Collapse";
import AdminDepositFilePond from "./adminDepositFilePond";
import IconButton from "@material-ui/core/IconButton";
import {faEye, faEyeSlash, faInfoCircle, faSirenOn} from "@fortawesome/pro-solid-svg-icons";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import AdminSmallUserAccountDisplay from "../displays/adminSmallUserAccountDisplay";
import Grid from "@material-ui/core/Grid";
import {UserAccountContainer} from "../../../autoGenerated/components/containers/resourceContainers";


const useStyles = makeStyles({
    formControl: {
        margin: 10,
        minWidth: 120,
    },
});

export function AdminDepositFilePondCard({attachedFilesUID, deposit, ...props}) {
    const classes = useStyles();

    const [showUploadArea, setShowUploadArea] = React.useState(false);
    const [uploadingAccountUID, setUploadingAccountUID] = React.useState(undefined);
    let uploadDisabled = uploadingAccountUID === undefined || uploadingAccountUID === NULL_UUID_STRING;

    if(uploadDisabled === false){
        if(uploadingAccountUID !== deposit.landlord_account_uid && uploadingAccountUID !== deposit.tenant_account_uid){
            setUploadingAccountUID(undefined);
        }
    }

    const handleChange = event => {
        setUploadingAccountUID(event.target.value);
    };

    return (
        <Card variant="outlined">
            <CardHeader
                avatar={
                    <FontAwesomeIcon icon={FILE_ICON} fixedWidth size={'3x'}/>
                }
                title={'Deposit Files Upload'}
                subheader={''}
                action={
                    <IconButton aria-label="settings" onClick={toggleUploadingArea}>
                        <FontAwesomeIcon
                            icon={showUploadArea === true ? faEyeSlash : faEye}
                            fixedWidth
                        />
                    </IconButton>
                }
            />
            <CardContent>

                <Collapse in={!showUploadArea}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        warp={'nowrap'}
                        spacing={1}
                    >
                        <Grid item>
                            <i>Click the eye to expand</i>
                        </Grid>
                    </Grid>
                </Collapse>

                <Collapse in={showUploadArea}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        warp={'nowrap'}
                        spacing={1}
                    >
                        <Grid item>
                            Any files uploaded to the deposit must be uploaded by the Landlord or the Tenant.
                        </Grid>

                        <Grid item>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="demo-simple-select-label">Uploading Account</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={uploadingAccountUID}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={undefined}><i>None</i></MenuItem>
                                    {
                                        deposit.landlord_account_uid !== NULL_UUID_STRING &&
                                        <MenuItem value={deposit.landlord_account_uid}>
                                            Landlord (
                                            <UserAccountContainer accountUID={deposit.landlord_account_uid}>
                                                <AdminSmallUserAccountDisplay/>
                                            </UserAccountContainer>
                                            )
                                        </MenuItem>
                                    }
                                    {
                                        deposit.tenant_account_uid !== NULL_UUID_STRING &&
                                        <MenuItem value={deposit.tenant_account_uid}>
                                            Tenant (
                                            <UserAccountContainer accountUID={deposit.tenant_account_uid}>
                                                <AdminSmallUserAccountDisplay/>
                                            </UserAccountContainer>
                                            )
                                        </MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            {
                                uploadDisabled === true &&
                                <React.Fragment>
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        fixedWidth
                                        size={'2x'}
                                    />
                                    &nbsp;
                                    <i>Uploads are disabled until you select an account</i>
                                </React.Fragment>
                            }
                            {
                                uploadDisabled === false &&
                                <React.Fragment>
                                    <FontAwesomeIcon
                                        icon={faSirenOn}
                                        fixedWidth
                                        color={'orange'}
                                        size={'2x'}
                                    />
                                    <br/>
                                    Careful now, you're about to upload files as&nbsp;
                                    <strong>
                                        <UserAccountContainer accountUID={uploadingAccountUID}>
                                            <AdminSmallUserAccountDisplay/>
                                        </UserAccountContainer>
                                    </strong>
                                </React.Fragment>
                            }
                        </Grid>

                        <Grid item>
                            <AdminDepositFilePond
                                depositUID={deposit.deposit_uid}
                                attachedFilesUID={attachedFilesUID}
                                uploadingAccountUID={uploadingAccountUID}
                                uploadDisabled={uploadDisabled}
                            />
                        </Grid>
                    </Grid>


                </Collapse>

            </CardContent>
        </Card>
    );

    function toggleUploadingArea() {
        setShowUploadArea(!showUploadArea);
    }

}

