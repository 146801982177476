/*
    Data driven table headers
 */

import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {TABLE_COLUMN_ALIGNMENT_DEFAULT} from "../../constants/tables/tableConstants";


export default function GenericTableHeaders({tableRenderDef, ...props}) {

    return (
        <TableRow>
            {
                Object.keys(tableRenderDef).map(objectKey => {
                    const renderDef = tableRenderDef[objectKey];
                    const alignment = renderDef.columnAlign === undefined ? TABLE_COLUMN_ALIGNMENT_DEFAULT : renderDef.columnAlign;

                    return (
                        <TableCell align={alignment} key={`cell-${objectKey}`}>
                            {renderDef.displayName}
                        </TableCell>
                    );
                })
            }
        </TableRow>
    );

}