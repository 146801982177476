import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/browser";
import {
    USER_ACCOUNT_ROLE_ADMIN,
    USER_ACCOUNT_ROLE_LANDLORD,
    USER_ACCOUNT_ROLE_TENANT
} from "../../constants/accountConstants";
import Checkbox from "@material-ui/core/Checkbox";
import {FormControlLabel} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import LinearProgress from "@material-ui/core/LinearProgress";
import {loadingActionLoadUserAccountFromServer} from "../../autoGenerated/actions/resourceLoadingActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDigging, faExclamation} from "@fortawesome/pro-solid-svg-icons";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


const AdminChangeAccountRoles = ({userAccount, ...props}) => {
    const classes = useStyles();
    const [updating, setUpdating] = useState(false);

    const isLandlord = userAccount.roles.includes(USER_ACCOUNT_ROLE_LANDLORD);
    const isTenant = userAccount.roles.includes(USER_ACCOUNT_ROLE_TENANT);
    const isAdmin = userAccount.roles.includes(USER_ACCOUNT_ROLE_ADMIN);

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant="h5" component="h2">
                    Roles
                </Typography>

                <Typography className={classes.pos} color="textSecondary">
                    Directly set the roles this user account. Be careful with the admin role, this gives a user access to this Deposify admin system.
                </Typography>

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isLandlord}
                                    onChange={() => handleChange(USER_ACCOUNT_ROLE_LANDLORD)}
                                    name="Landlord"
                                    color="primary"
                                    disabled={updating}
                                />
                            }
                            label="Landlord"
                        />
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isTenant}
                                    onChange={() => handleChange(USER_ACCOUNT_ROLE_TENANT)}
                                    name="Tenant"
                                    color="primary"
                                    disabled={updating}
                                />
                            }
                            label="Tenant"
                        />
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAdmin}
                                    onChange={() => handleChange(USER_ACCOUNT_ROLE_ADMIN)}
                                    name="Admin"
                                    color="primary"
                                    disabled={updating}
                                />
                            }
                            label="Admin"
                        />
                    </Grid>
                </Grid>

                <Collapse in={updating}>
                    <LinearProgress/>
                </Collapse>
            </CardContent>
        </Card>
    );

    function handleChange(updatingRole){
        let newRoles = [...userAccount.roles];

        if(newRoles.includes(updatingRole)){
            let index = newRoles.indexOf(updatingRole);
            if(index === -1)
                throw new Error("Not found for removal")
            newRoles.splice(index, 1);
        }
        else{
            newRoles.push(updatingRole);
        }

        if(newRoles.length === 0){
            toast.error("Can't remove all roles");
            return;
        }

        // console.log(updatingRole, newRoles)
        setUpdating(true);

        const callURL = getAdminPath(`jeff/account/${userAccount.account_uid}/roles`);
        axiosInstance.patch(callURL, {roles: newRoles})
            .then(function (response) {

                props.loadingActionLoadUserAccountFromServer(userAccount.account_uid).then(() => {
                    toast.success('Done');
                    setUpdating(false);
                    // yea, this is not good,but I don't have time to dive into all the cache invalidation
                    window.location.reload();
                })

            })
            .catch(function (error) {
                setUpdating(false);

                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed to update roles');
            })
    }

};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadingActionLoadUserAccountFromServer: loadingActionLoadUserAccountFromServer,
    }, dispatch);
};

export default connect(null, mapActionsToProps)(AdminChangeAccountRoles);