/*

 */

import React from 'react';
import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import AdminDisplayCloseRequest from "./adminDisplayCloseRequest";
import {DepositCloseRequestContainer} from "../../../autoGenerated/components/containers/resourceContainers";

export default function CloseRequestDisplayHOC({closeRequestUID, ...props}) {

    if(closeRequestUID === NULL_UUID_STRING){
        return <AdminDisplayCloseRequest emptyDisplay={true}/>
    }

    return (
        <DepositCloseRequestContainer depositCloseRequestUID={closeRequestUID}>
            <AdminDisplayCloseRequest/>
        </DepositCloseRequestContainer>
    );

}