/*
    Deposits Admin Service
 */

import axiosRequest from "../clientShared/utilities/axios/AxiosRequest";
import {getAdminPath} from "./adminAPI";
import {createCommonResourceURL} from "../clientShared/utilities/resourceUtilities";
import {
    RESOURCE_TYPE_PRIORITY_ACH_PAYMENT,
    RESOURCE_TYPE_STRIPE_PAYMENT_LOG
} from "../autoGenerated/constants/resourceTypeConstants";

//
// Service
//
const PaymentsAdminService = {
    AdminPaymentsStripeSetAdminReview,
    AdminPaymentsACHSetAdminReview,
    AdminPaymentsSetPaymentClearedState,
    AdminPaymentsACHSetState,
    AdminPaymentsACHUpdate,
    AdminPaymentsStripeUpdate
};

// Export
export default PaymentsAdminService;


//----------------------------------------------------------------------------------------------------------------------
function AdminPaymentsStripeSetAdminReview(paymentLogUID, isComplete) {
    const url = getAdminPath(`payments/stripe/payment/${paymentLogUID}/admin/set/${isComplete}`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


//----------------------------------------------------------------------------------------------------------------------
function AdminPaymentsACHSetAdminReview(paymentUID, isComplete) {
    const url = getAdminPath(`payments/ach/payment/${paymentUID}/admin/set/${isComplete}`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function AdminPaymentsSetPaymentClearedState(paymentUID, paymentType, isCleared) {
    const url = getAdminPath(`payments/${paymentUID}/type/${paymentType}/set/cleared/${isCleared}`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


//----------------------------------------------------------------------------------------------------------------------
function AdminPaymentsACHSetState(paymentUID, newState) {
    const url = getAdminPath(`payments/ach/payment/${paymentUID}/set/state/${newState}`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function AdminPaymentsACHUpdate(paymentUID, updateData) {
    return axiosRequest({
        url: createCommonResourceURL(RESOURCE_TYPE_PRIORITY_ACH_PAYMENT, paymentUID),
        method: 'PUT',
        data: updateData
    });
}

//----------------------------------------------------------------------------------------------------------------------
function AdminPaymentsStripeUpdate(paymentLogUID, updateData) {
    return axiosRequest({
        url: createCommonResourceURL(RESOURCE_TYPE_STRIPE_PAYMENT_LOG, paymentLogUID),
        method: 'PUT',
        data: updateData
    });
}