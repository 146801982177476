/**
 * Axios Request Wrapper
 *
 * Based on: https://gist.github.com/sheharyarn/7f43ef98c5363a34652e60259370d2cb
 */


import * as Sentry from "@sentry/browser";
import {axiosInstance} from "../../../utilities/axiosInstance";


/**
 * Request Wrapper with default success/error actions
 */
const axiosRequest = function (options) {

    const onSuccess = function (response) {
        //console.debug('Request Successful!', response);
        return response.data;
    };

    const onError = function (error) {
        //console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            //console.error('Status:',  error.response.status);
            //console.error('Data:',    error.response.data);
            //console.error('Headers:', error.response.headers);
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });

        } else {
            // Something else happened while setting up the request
            // triggered the error
            //console.error('Error Message:', error.message);
            Sentry.withScope(scope => {
                scope.setExtra("message", error.message);
                Sentry.captureException(error);
            });
        }

        return Promise.reject(error.response || error.message);
    };

    return axiosInstance(options)
        .then(onSuccess)
        .catch(onError);
};

export default axiosRequest;