/*
    APP Routes
 */

export const APP_ROUTE_LOGIN = '/login';
export const APP_ROUTE_CREATE_ACCOUNT = '/account/new';
export const APP_ROUTE_INVITE_CODE = '/invite/:invite_code';
export const APP_ROUTE_VERIFY_EMAIL = '/verify/email/:email_verification_token';

export const APP_ROUTE_DETAILED_USER_ACCOUNT = '/accounts/:account_uid/detailed'
export const APP_ROUTE_EMAIL_TEMPLATES = '/admin/email/templates'
export const APP_ROUTE_PROPERTIES_BULK = '/admin/properties/bulk'

export const APP_ROUTE_HOME = '/';
export const APP_ROUTE_DEPOSITS = '/deposits';
export const APP_ROUTE_DETAILED_DEPOSIT = '/deposits/:deposit_uid/detailed'
export const APP_ROUTE_REPORTS = '/reports';
export const APP_ROUTE_FUNDS = '/funds';
export const APP_ROUTE_ACCOUNT = '/profile';
export const APP_ROUTE_SUPPORT = '/support';

export const APP_ADMIN_ROUTE_USER_ACCOUNTS = '/accounts';
export const APP_ADMIN_ROUTE_DEPOSITS = '/deposits';
export const APP_ADMIN_ROUTE_US_DOM_BANK_ACCOUNTS = '/banks/us/dom';
export const APP_ADMIN_ROUTE_INTERNATIONAL_BANK_ACCOUNTS = '/banks/international';
export const APP_ADMIN_ROUTE_DEPOSIT_INVITES = '/deposits/invites';

export const APP_ADMIN_ROUTE_MAILBOXES  = '/mailboxes';
export const APP_ADMIN_ROUTE_MAILBOX_MESSAGES = '/mailbox/messages';
export const APP_ADMIN_ROUTE_PERMISSION_ENTRIES = '/permissions/entries';
export const APP_ADMIN_ROUTE_PERMISSION_LOGS = '/permissions/logs';

export const APP_ADMIN_ROUTE_ALL_USER_ACCOUNT_HISTORY = '/history/accounts/all';

export const APP_ADMIN_ROUTE_ONLINE_USERS = '/online/users';
export const APP_ADMIN_ROUTE_ALL_SENT_EMAILS = '/emails/sent';
export const APP_ADMIN_ROUTE_IP_ADDRESSES = '/ips';
export const APP_ADMIN_ROUTE_KNOWN_IP_ADDRESSES = '/ips/known';
export const APP_ADMIN_ROUTE_USER_AGENTS = '/user/agents';
export const APP_ADMIN_ROUTE_KNOWN_USER_AGENTS = '/user/agents/known';

export const APP_ADMIN_ROUTE_BALANCE_CHANGE_LOGS = '/accounts/balance/change/log';
export const APP_ADMIN_ROUTE_BALANCE_SOURCE_TRANSFERS = '/accounts/balance/source/transfer';
export const APP_ADMIN_ROUTE_CREDIT_USER_ACCOUNT_LOGS = '/accounts/credit/log';
export const APP_ADMIN_ROUTE_EMAIL_VERIFICATIONS = '/emails/verification';
export const APP_ADMIN_ROUTE_FUNDS_LOCK_RELEASE_LOGS = '/funds/locks/log';

export const APP_ADMIN_ROUTE_USER_BANK_ACCOUNT_DELTAS = '/banking/account/deltas';
export const APP_ADMIN_ROUTE_RELEASED_FUNDS_LOCKS = '/funds/locks/released';
export const APP_ADMIN_ROUTE_PREVIOUS_EMAIL_ADDRESSS = '/emails/previous';
export const APP_ADMIN_ROUTE_PENDING_EMAIL_CHANGES = '/emails/change/pending';
export const APP_ADMIN_ROUTE_PASSWORD_RESETS = '/passwords/reset';

export const APP_ADMIN_ROUTE_BANK_ACCOUNT_INTERESTS = '/banking/interest';
export const APP_ADMIN_ROUTE_BANK_ACCOUNTS = '/banking/account';
export const APP_ADMIN_ROUTE_BANK_TRANSFERS = '/banking/transfers';
export const APP_ADMIN_ROUTE_DEPOSIT_ADJUSTMENTS = '/adjustments';
export const APP_ADMIN_ROUTE_DEPOSIT_BULK_IMPORTS = '/bulk/import';
export const APP_ADMIN_ROUTE_DEPOSIT_INTERESTS = '/interest';
export const APP_ADMIN_ROUTE_PROPERTY_ADDRESSS = '/properties/addresses';
export const APP_ADMIN_ROUTE_ACTION_ITEMS = '/updates/log';
export const APP_ADMIN_ROUTE_EVENTS = '/event';
export const APP_ADMIN_ROUTE_NOTIFICATIONS = '/notification';
export const APP_ADMIN_ROUTE_DEPOSIT_HISTORY_ENTRYS = '/history/deposits';
export const APP_ADMIN_ROUTE_SYSTEM_HISTORY_ENTRYS = '/history/system';
export const APP_ADMIN_ROUTE_UPDATE_LOGS = '/history/updates/log';
export const APP_ADMIN_ROUTE_EMAIL_EVENTS = '/emails/events';
export const APP_ADMIN_ROUTE_EMAIL_TEMPLATES = '/emails/templates';
export const APP_ADMIN_ROUTE_INVOICES = '/invoices';
export const APP_ADMIN_ROUTE_INVOICE_ITEMS = '/invoices/items';
export const APP_ADMIN_ROUTE_STRIPE_PAYMENT_LOGS = '/stripe/payment/logs';
export const APP_ADMIN_ROUTE_PAYMENT_REQUESTS = '/payment/requests';

export const APP_ADMIN_ROUTE_USER_WITHDRAWS = '/payment/withdrawals';

export const APP_ADMIN_ROUTE_US_DOMESTIC_USER_BANK_ACCOUNTS = '/banks/domestic';
export const APP_ADMIN_ROUTE_INTERNATIONAL_USER_BANK_ACCOUNTS = '/banks/international';

export const APP_ADMIN_ROUTE_STORAGE_FILES = '/files';
export const APP_ADMIN_ROUTE_ATTACHED_STORAGE_FILESS = '/files';
export const APP_ADMIN_ROUTE_PROPERTYS = '/properties';
export const APP_ADMIN_ROUTE_DEPOSIT_CLOSE_REQUESTS = '/close/requests';

export const APP_ADMIN_ROUTE_DANGER_PAGE = '/danger';

export const APP_ADMIN_ROUTE_USER_ACCOUNT_BALANCES = '/balance';
