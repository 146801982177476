const CryptoJS = require("crypto-js");


class BankAccount {
    constructor() {
        this.meta = {
            missingValues: false,
            missingFields: []
        };

        this.bank_name = null;
        this.nickname = null;
        this.name_on_account = null;
        this.account_number = null;
        this.routing_number = null;
        this.account_type = null;
        this.geo_state = null;
        this.partner_id = -1;
    }

    keysList(){
        return [
            "bank_name",
            "nickname",
            "name_on_account",
            "account_number",
            "routing_number",
            "account_type",
            "geo_state",
            "partner_id"
        ]
    }

    inspectMissingValues() {
        // Check if any values are null or undefined
        this.meta.missingValues = Object.values(this).some(v => v === null || v === undefined);

        // Update the missingFields list
        this.meta.missingFields = Object.entries(this).reduce((missing, [key, value]) => {
            if (value === null || value === undefined) {
                missing.push(key);
            }
            return missing;
        }, []);

        return this.meta.missingValues;
    }

    setMissingValues(value) {
        this.meta.missingValues = value;
    }

    extractFromTSVRow(tsvRow, headerMapping){
        this.keysList().map(currentKey => {
            let currentField = headerMapping[currentKey];
            this[currentKey] = tsvRow[currentField.column_name];
        })

        if(this.nickname === undefined){
            this.nickname = this.bank_name;
        }

        if(this.geo_state === undefined){
            this.geo_state = "NY";
        }

        // enum values are lower case
        this.account_type = this.account_type?.toLowerCase();

        this.inspectMissingValues();

        return this;
    }

    generateKey(){
        const values = this.keysList().map(key => this[key]);
        return values.join("-");
    }

    generateHash(){
        return CryptoJS.SHA256(this.generateKey()).toString();
    }
}

export default BankAccount;
