/*

 */

import {createSelector} from 'reselect'
import {
    allAttachedStorageFilesSelector,
    uuidSelector
} from "../autoGenerated/selectors/resourceSelectors";


export const adminSearchInfoSelector = state => state.admin.adminSearchInfo;
export const adminSearchResultsSelector = state => state.admin.adminSearchResults;

export const adminStartupLoadingSelector = state => state.admin.adminStartupLoading;

export const adminAllSystemStatsSelector = state => state.admin.systemStats;

export const statsIDSelector = (state, statsID) => statsID;

//
// GENERIC FULL SCREEN DIALOG
//
export const fullScreenDisplaySelector = state => state.admin.fullScreenDisplay;
export const adminGhostAccountInfoSelector = state => state.admin.adminGhostAccountInfo;


export const fullScreenFileInfoSelector = state => state.admin.fullScreenFileInfo;
export const fullScreenHistoryChainSelector = state => state.admin.fullScreenHistoryChain;


// TODO: Consider moving to better home?
export const makeAttachedFilesWithFileSelector = () => createSelector(
    [allAttachedStorageFilesSelector, uuidSelector],
    (attachedStorageFiles, fileUID) => {

        let returnList = [];

        const keys = Object.keys(attachedStorageFiles);
        for (const key of keys) {
            const currentAttachedStorageFiles = attachedStorageFiles[key];
            if (currentAttachedStorageFiles.file_uids.includes(fileUID)) {
                returnList.push(currentAttachedStorageFiles.attached_files_uid);
            }
        }

        return returnList;
    }
);

export const adminMakeSystemStatsSelector = () => createSelector(
    [adminAllSystemStatsSelector, statsIDSelector],
    (allSystemStats, statsID) => allSystemStats[statsID] || {}
);

