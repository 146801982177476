import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    appActionSetFullScreenPropertyDialogDetails
} from "../../../../actions/appActions";
import Link from "@material-ui/core/Link";
import {NULL_UUID_STRING} from "../../../../constants/dataConstants";
import {uuidToHex} from "../../../../utilities/commonUtils";

function AdminViewFullScreenPropertyLink({propertyUID, linkText = 'View', showUID = false, ...props}) {

    if(propertyUID === NULL_UUID_STRING){
        return <i>None</i>;
    }

    return (
        <Link
            component="button"
            onClick={linkClicked}
        >
            {linkText}
            {showUID === true && <React.Fragment>&nbsp;{uuidToHex(propertyUID)}</React.Fragment>}
        </Link>
    );

    function linkClicked() {
        props.appActionSetFullScreenPropertyDialogDetails(
            {
                showDialog: true,
                propertyUID: propertyUID
            }
        );
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        appActionSetFullScreenPropertyDialogDetails: appActionSetFullScreenPropertyDialogDetails
    }, dispatch);
};

export default connect(null, mapActionsToProps)(AdminViewFullScreenPropertyLink);