/**
 * Address Auto Complete Field
 */
import React, {useCallback, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from '@material-ui/core/CircularProgress';
import DepositsService from "../../../api/DepositsService";
import toast from "react-hot-toast";
import {useDebounce} from "@umijs/hooks";

const US_LABEL_PREFIX = 'United States, ';
// Change reasons used when the autocomplete input value is updated
const INPUT_CHANGE_REASON_USER_TEXT_INPUT = 'input';
const INPUT_CHANGE_REASON_USER_CLEARED_INPUT = 'clear';

const AddressAutoComplete = ({changeCallback}) => {
    /**
     * Address auto complete filed - user types in an address, server suggests the full address
     *
     * Note: filterOptions={(x) => x}
     *       Since we are using an autocomplete field we need to set the filterOptions.
     *       We return the option as is, without any filtering.
     *       In a normal autocomplete we'd filter based on some string matching, but here all out options
     *       are coming from the server based on the text input, so we display the options as is.
     *
     */
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [userSearchString, setUserSearchString] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const finalUserSearchString = useDebounce(userSearchString, 300);

    const updateSuggestions = (newSuggestions) => {
        const updatedSuggestions = newSuggestions.map(currentSuggestion => {
            // if the label starts with 'united states' then we can remove this to make it more readable
            const originalLabel = currentSuggestion.label;
            if (originalLabel.startsWith(US_LABEL_PREFIX)) {
                return {...currentSuggestion, label: originalLabel.replace(US_LABEL_PREFIX, '')};
            } else {
                return currentSuggestion;
            }
        });

        setOptions(updatedSuggestions);
        setLoading(false);
    }

    const handleSelectionChange = useCallback((newValue) => {
        changeCallback(newValue);
    }, [changeCallback]);

    useEffect(() => {
        if (finalUserSearchString !== '') {
            setLoading(true);

            DepositsService.PropertyAddressGeocoderSearch(finalUserSearchString)
                .then(function (response) {
                    updateSuggestions(response.suggestions.suggestions);
                })
                .catch(function (error) {
                    toast.error('Unable to read address suggestions');
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }

    }, [finalUserSearchString]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleInputChange = (changeEvent, value, reason) => {
        /**
         * Input change event when the user types or clears
         */
        // if cleared: reason = clear, value = undefined
        if (reason === INPUT_CHANGE_REASON_USER_TEXT_INPUT) {
            setUserSearchString(value);
            setLoading(true);
        }
    }

    const handleSelectedItemChange = (changeEvent, value, reason, details) => {
        /**
         * Change event when an option in the list is selected or cleared
         */
        // if cleared: reason = clear, value = undefined
        setSelectedOption(value);
        handleSelectionChange(value);
    }

    return (
        <Autocomplete
            id="address-auto-complete-input"
            fullWidth={true}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            //
            // NOTE: filterOptions -- Very important - this stops the normal filtering so our full list is shown
            //
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Address"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onInputChange={handleInputChange}
            onChange={handleSelectedItemChange}
        />
    );
}

export default AddressAutoComplete;