import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from "@material-ui/core/Grid";
import {PAGE_GROUP_CARD_IMAGE_URL} from "../../constants/cdnConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBurrito, faCloudMeatball, faCocktail, faToilet} from "@fortawesome/pro-regular-svg-icons";
import {BankBulkImportLink} from "./rhinoLinks";
import {faKorvue} from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
        minWidth: 345,
        minHeight: 430
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));


export default function RhinoNoodlesCard({...props}) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <FontAwesomeIcon icon={faCocktail} fixedWidth/>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={"Cocktails"}
                subheader={"Nom, nom for us David"}
            />
            <CardMedia
                className={classes.media}
                image={PAGE_GROUP_CARD_IMAGE_URL}
                title={"Cocktails"}
            />
            <CardContent>
                {renderPageGroupHighlights()}
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    disabled={false}
                >
                    <ExpandMoreIcon/>
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {renderAllWholePageGroup()}
                </CardContent>
            </Collapse>
        </Card>
    );

    function renderPageGroupHighlights() {
        return (
            <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="flex-start"
            >
                <Grid item>
                    <BankBulkImportLink/>
                </Grid>
            </Grid>
        );
    }

    function renderAllWholePageGroup() {

        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid item>
                    <Typography paragraph>All pages:</Typography>
                </Grid>

                <Grid item>
                    <BankBulkImportLink/>
                </Grid>
            </Grid>
        );
    }

}