/*

 */

import React from 'react';
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import DepositAcceptedIndicator from "./depositAcceptedIndicator";
import {UIDLinkOrCopy} from "../buttons/linkButtons/UIDLinkOrCopy";
import {RESOURCE_TYPE_USER_ACCOUNT} from "../../../autoGenerated/constants/resourceTypeConstants";


export default function DepositPartyDisplay({deposit, useLandlord, ...props}) {

    const accountUID = useLandlord === true ? deposit.landlord_account_uid : deposit.tenant_account_uid;

    return (
        <React.Fragment>
            <AdminViewFullAccountInfoLink accountUID={accountUID}/>
            {/*<UIDLinkOrCopy uid={accountUID} resourceType={RESOURCE_TYPE_USER_ACCOUNT}/>*/}
            <br/>
            <DepositAcceptedIndicator deposit={deposit} useLandlord={useLandlord}/>
        </React.Fragment>
    );


}
