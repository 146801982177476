// Name: Events Table Render Definitions 

export const ACTION_ITEM_TABLE_RENDER_DEF = {
    created_timestamp: {displayName: "Created Timestamp"},
    item_uid: {displayName: "Item UID"},
    last_reminder_sent_timestamp: {displayName: "Last Reminder Sent Timestamp"},
    action_item_id: {displayName: "Action Item Id"},
    item_payload: {displayName: "Item Payload"},
    resource_type: {displayName: "Resource Type"},
    resource_uid: {displayName: "Resource UID"},
    reminder_count: {displayName: "Reminder Count"},
    account_uid: {displayName: "Account UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const EVENT_HOOK_TABLE_RENDER_DEF = {
    disabled: {displayName: "Disabled"},
    any_resource_type: {displayName: "Any Resource Type"},
    account_uids: {displayName: "Account UIDs"},
    created_timestamp: {displayName: "Created Timestamp"},
    resource_uids: {displayName: "Resource UIDs"},
    event_ids: {displayName: "Event Ids"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    any_account_uid: {displayName: "Any Account UID"},
    resource_types: {displayName: "Resource Types"},
    url: {displayName: "Url"},
    hook_uid: {displayName: "Hook UID"},
    any_resource_uid: {displayName: "Any Resource UID"}
};

export const EVENT_TABLE_RENDER_DEF = {
    notification_message: {displayName: "Notification Message"},
    spawn_action_item: {displayName: "Spawn Action Item"},
    action_item_message: {displayName: "Action Item Message"},
    created_timestamp: {displayName: "Created Timestamp"},
    spawn_notification: {displayName: "Spawn Notification"},
    deposify_only: {displayName: "Deposify Only"},
    event_destination: {displayName: "Event Destination"},
    event_uid: {displayName: "Event UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    history_message: {displayName: "History Message"},
    event_id: {displayName: "Event Id"},
    event_type: {displayName: "Event Type"}
};

export const NOTIFICATION_TABLE_RENDER_DEF = {
    notification_uid: {displayName: "Notification UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    notification_payload: {displayName: "Notification Payload"},
    resource_uid: {displayName: "Resource UID"},
    resource_type: {displayName: "Resource Type"},
    account_uid: {displayName: "Account UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    event_id: {displayName: "Event Id"},
    event_type: {displayName: "Event Type"}
};

