import React from 'react';
import Grid from "@material-ui/core/Grid";
import IF from "../../clientShared/components/utility/IFComponent";


const DepositRhinoFundsInfo = ({deposit}) => {

    if (deposit.funds_held_by_partner === false) {
        return (<></>);
    }

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
        >
            <Grid item>
                Funds for this deposit are <u>held by the Partner</u>.
            </Grid>

            <Grid item>|</Grid>

            <IF condition={deposit.payment_cleared}>
                <Grid item>
                    Funds have been transferred.
                </Grid>
            </IF>

            <IF condition={!deposit.payment_cleared}>
                <Grid item>
                    Funds have <u>NOT</u> yet been transferred.
                </Grid>
            </IF>
        </Grid>
    );
};

export default DepositRhinoFundsInfo;