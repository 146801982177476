/*
    Dev
 */

import React from "react";
import Typography from "@material-ui/core/Typography";
import {ACTIVE_COLOR_MUTED, DISPUTED_COLOR_MUTED} from "../../../constants/dataConstants";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    goodStyle: {
        color: ACTIVE_COLOR_MUTED
    },
    badStyle: {
        color: DISPUTED_COLOR_MUTED
    }
});


function BooleanTypeRender({boolValue, invertColours = false, ...props}) {
    const classes = useStyles();

    const goodClass = invertColours ? classes.badStyle : classes.goodStyle;
    const badClass = invertColours ? classes.goodStyle : classes.badStyle;

    if (boolValue === true) {
        return (<Typography className={goodClass}>True</Typography>);
    }
    return <Typography className={badClass}>False</Typography>;
}

export default React.memo(BooleanTypeRender);