import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Link from "@material-ui/core/Link";
import {faUserRobot} from "@fortawesome/pro-regular-svg-icons";
import {history} from "../../history/history";


export default function DetailedUserAccountInfoLink({accountUID, linkText='Detailed Account Info', ...props}) {
    const url = `/accounts/${accountUID}/detailed`;

    return (
        <Link
            // href={`/accounts/${accountUID}/detailed`}
            href={url}
            // component="button"
            onClick={(clickEvent) => {
                clickEvent.preventDefault();
                history.push(url);
            }}
        >
            <FontAwesomeIcon icon={faUserRobot} fixedWidth/>
            &nbsp;&nbsp;{linkText}
        </Link>
    );
}