/*

 */

import React from 'react';

import VisualizeContainerLoadingIndicator
    from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import ErrorInfoDisplay from "../../utilities/displayAreas/ErrorInfoDisplay";
import {ADMIN_ICON_INVITE} from "../adminIcons";
import AdminDisplayCard from "./adminDisplayCard";
import AdminViewFullScreenDepositLink from "../buttons/linkButtons/adminViewFullScreenDepositLink";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EMAIL_ICON, PHONE_ICON} from "../../../constants/commonIcons";
import Divider from "@material-ui/core/Divider";
import DepositInviteSentDisplay from "./depositInviteSentDisplay";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    divider: {
        height: 28,
        margin: 4,
    }
}));

function AdminDisplayDepositInvite({loading, error, depositInvite, ...props}) {

    const classes = useStyles();

    if (error === true) {
        return (
            <ErrorInfoDisplay showError={true}>
                Failed to load deposit invite
            </ErrorInfoDisplay>
        );
    }

    if (loading === true || error === true || depositInvite === undefined) {
        return <VisualizeContainerLoadingIndicator loadingResource={depositInvite}
                                                   displayName={'Deposit invite'} {...props} />;
    }

    return (
        <AdminDisplayCard
            cardTitle={'Deposit Invite'}
            cardSubheader={''}
            headerIcon={ADMIN_ICON_INVITE}
            jsonObject={depositInvite}
        >
            <AdminViewFullScreenDepositLink depositUID={depositInvite.deposit_uid} showBasicInfo={true}/>
            <br/>

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                wrap={'nowrap'}
                spacing={1}
            >
                <Grid item>
                    <h3>
                        <FontAwesomeIcon
                            icon={EMAIL_ICON}
                            size={'2x'}
                            fixedWidth
                        />
                        Email
                    </h3>
                    <DepositInviteSentDisplay depositInvite={depositInvite} useEmail={true}/>
                </Grid>

                <Grid item>
                    <Divider className={classes.divider} orientation="vertical"/>
                </Grid>

                <Grid item>
                    <h3>
                        <FontAwesomeIcon
                            icon={PHONE_ICON}
                            size={'2x'}
                            fixedWidth
                        />
                        Text
                    </h3>
                    <DepositInviteSentDisplay depositInvite={depositInvite} useEmail={false}/>
                </Grid>

            </Grid>

        </AdminDisplayCard>
    );

}

export default AdminDisplayDepositInvite;