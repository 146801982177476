/*

 */

import React from 'react';
import CreatedUpdatedTimestampDisplay from "../../utilities/displayAreas/CreatedUpdatedTimestampDisplay";


export default function CreatedUpdatedTimestampWrapper({sourceObject, ...props}) {

    return (
        <CreatedUpdatedTimestampDisplay
            createdTimestamp={sourceObject.created_timestamp}
            lastUpdatedTimestamp={sourceObject.last_updated_timestamp}
        />
    );

}
