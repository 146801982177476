import Link from "@material-ui/core/Link";
import {history} from "../../history/history";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {faDollarSign} from "@fortawesome/pro-solid-svg-icons";

export default function DetailedDepositLink({depositUID, linkText = 'View Detailed Deposit Info', ...props}) {
    const url = `/deposits/${depositUID}/detailed`;

    return (
        <Link
            // href={`/accounts/${accountUID}/detailed`}
            href={url}
            // component="button"
            onClick={(clickEvent) => {
                clickEvent.preventDefault();
                history.push(url);
            }}
        >
            <FontAwesomeIcon icon={faDollarSign} fixedWidth/>
            &nbsp;{linkText}
        </Link>
    );
}