// Name: Permissions Body Render Definitions 

export const ORGANIZATION_BODY_RENDER_DEF = {
	eminent_organization_uid: {displayName: "Eminent Organization UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	do_not_create_permissions: {displayName: "Do Not Create Permissions"},
	owner_account_uid_for_resources: {displayName: "Owner Account UID For Resources"},
	parent_organization_uid: {displayName: "Parent Organization UID"},
	description: {displayName: "Description"},
	created_timestamp: {displayName: "Created Timestamp"},
	name: {displayName: "Name"},
	disabled: {displayName: "Disabled"},
	organization_uid: {displayName: "Organization UID"}
};

export const ORGANIZATION_RESOURCE_PERMISSION_RULE_BODY_RENDER_DEF = {
	eminent_organization_uid: {displayName: "Eminent Organization UID"},
	match_value_types: {displayName: "Match Value Types"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	rule_uid: {displayName: "Rule UID"},
	attribute_names: {displayName: "Attribute Names"},
	description: {displayName: "Description"},
	organization_permissions: {displayName: "Organization Permissions"},
	name: {displayName: "Name"},
	account_uids_to_get_permissions: {displayName: "Account UIDs To Get Permissions"},
	match_values: {displayName: "Match Values"},
	resource_type: {displayName: "Resource Type"},
	triggering_account_uids: {displayName: "Triggering Account UIDs"},
	created_timestamp: {displayName: "Created Timestamp"},
	operator_match_type: {displayName: "Operator Match Type"},
	disabled: {displayName: "Disabled"},
	operator_type: {displayName: "Operator Type"},
	triggering_organization_uids: {displayName: "Triggering Organization UIDs"},
	rule_type: {displayName: "Rule Type"},
	organization_uids_to_get_permissions: {displayName: "Organization UIDs To Get Permissions"},
	account_permissions: {displayName: "Account Permissions"}
};

export const PERMISSIONS_ORGANIZATION_SETTINGS_BODY_RENDER_DEF = {
	disable_auto_permission_create: {displayName: "Disable Auto Permission Create"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	organization_uid: {displayName: "Organization UID"}
};

export const PERMISSION_ENTRY_BODY_RENDER_DEF = {
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	owner_type: {displayName: "Owner Type"},
	owner_uid: {displayName: "Owner UID"},
	resource_uid: {displayName: "Resource UID"},
	has_notes: {displayName: "Has Notes"},
	permission_uid: {displayName: "Permission UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	permissions: {displayName: "Permissions"},
	disabled: {displayName: "Disabled"}
};

export const PERMISSION_LOG_ENTRY_BODY_RENDER_DEF = {
	resource_type: {displayName: "Resource Type"},
	organization_uid_used: {displayName: "Organization UID Used"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	resource_uid: {displayName: "Resource UID"},
	needed_permissions: {displayName: "Needed Permissions"},
	account_uid: {displayName: "Account UID"},
	log_entry_uid: {displayName: "Log Entry UID"},
	permission_decision: {displayName: "Permission Decision"},
	created_timestamp: {displayName: "Created Timestamp"},
	log_version: {displayName: "Log Version"}
};

export const RESOURCE_PERMISSION_BODY_RENDER_DEF = {
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	parent_resource_uid: {displayName: "Parent Resource UID"},
	owner_uid: {displayName: "Owner UID"},
	owner_type: {displayName: "Owner Type"},
	resource_uid: {displayName: "Resource UID"},
	permission_uid: {displayName: "Permission UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	permissions: {displayName: "Permissions"},
	disabled: {displayName: "Disabled"}
};

