/*
    Reset Payment Cleared / Interest controls
 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import ConfirmationDialog from "../utilities/dialogs/ConfirmationDialog";
import PaymentsAdminService from "../../api/PaymentsAdminService";
import {PAYMENT_TYPE_STRIPE} from "../../constants/paymentConstants";
import {
    RESOURCE_TYPE_PRIORITY_ACH_PAYMENT,
    RESOURCE_TYPE_STRIPE_PAYMENT_LOG
} from "../../autoGenerated/constants/resourceTypeConstants";

import {resourceActionUpdateSingleResource} from "../../clientShared/actions/resourceActions";
import toast from "react-hot-toast";



function PaymentsAdminButtonSetPaymentClearedState({paymentObject, paymentUID, paymentType, ...props}) {

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    if(paymentObject.payment_succeeded === false){
        return <React.Fragment/>;
    }

    const newClearedValue = !paymentObject.payment_cleared_into_deposify_bank_account;
    const displayText = newClearedValue === true ? 'Mark As Cleared' : 'Mark NOT Cleared';

    return (
        <React.Fragment>
            <ConfirmationDialog
                startOpen={showConfirm}
                dangerMode={true}
                confirmCallback={acceptConfirm}
                cancelCallback={cancelConfirm}
                dialogTitle={'Are you sure?'}
                dialogText={`Are you sure you sure want to ${displayText}`}
                confirmText={displayText}
                showSpinner={showSpinner}
                cancelFocus={true}
            />
            <Button
                color="secondary"
                onClick={handleButtonClick}
                variant={'contained'}
            >
                <FontAwesomeIcon
                    icon={faSkullCrossbones}
                    fixedWidth
                />
                &nbsp;{displayText}
            </Button>
        </React.Fragment>
    );

    function handleButtonClick() {
        setShowSpinner(false);
        setShowConfirm(true);
    }

    function acceptConfirm() {
        setShowSpinner(true);
        PaymentsAdminService.AdminPaymentsSetPaymentClearedState(paymentUID, paymentType, newClearedValue)
            .then(function (response) {
                setShowConfirm(false);
                if(paymentType === PAYMENT_TYPE_STRIPE) {
                    setTimeout(() => {
                        props.resourceActionUpdateSingleResource(response.stripe_payment_log, RESOURCE_TYPE_STRIPE_PAYMENT_LOG);
                    }, 500);
                }
                else{
                    setTimeout(() => {
                        props.resourceActionUpdateSingleResource(response.priority_ach_payment, RESOURCE_TYPE_PRIORITY_ACH_PAYMENT);
                    }, 500);
                }
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }

    function cancelConfirm() {
        setShowConfirm(false);
        toast('Cancelled action');
    }


}

const mapStateToProps = (state) => {
    // const _adminMakeSystemStatsSelector = adminMakeSystemStatsSelector();

    return function realMapState(state, props) {
        return {
            // systemStats: _adminMakeSystemStatsSelector(state, props.statsID)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentsAdminButtonSetPaymentClearedState);
