import React, {useEffect, useState} from 'react';
import {useUserAccountFinalizationLoader} from "../../autoGenerated/hooks/resourceLoadingHooks";
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IF from "../../clientShared/components/utility/IFComponent";
import RenderTimestamp from "../../clientShared/utilities/RenderTimestamp";
import {RenderTimeSince, RenderTimeTo} from "../../clientShared/utilities/RenderTimeSince";
import {getDateDeltaDuration, getMomentUTC, getServerDateTimeAsMoment} from "../../utilities/commonUtils";
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import toast from "react-hot-toast";
import ClickToCopy from "../../clientShared/components/utility/ClickToCopy";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LinearProgress from "@material-ui/core/LinearProgress";
import {PRIMARY_BLUE_COLOUR} from "../../constants/styleConstants";
import * as Sentry from "@sentry/browser";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import {resourceActionUpdateSingleResource} from "../../clientShared/actions/resourceActions";
import {useDispatch} from "react-redux";
import {RESOURCE_TYPE_USER_ACCOUNT_FINALIZATION} from "../../autoGenerated/constants/resourceTypeConstants";
import AdminSendEmailVerificationButton from "./AdminSendEmailVerificationButton";
import Grid from "@material-ui/core/Grid";
import {DEPOSIFY_TAG_ID_RHINO_DISABLE_EMAILS} from "../tags/DeposifyTags";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const AccFinalizationLink = ({accountUID}) => {
    const [theLink, setTheLink] = useState("")
    const hasLink = theLink !== "";

    useEffect(() => {
        const callURL = getAdminPath(`jeff/account/${accountUID}/finalization/get`);
        axiosInstance.get(callURL)
            .then(function (response) {
                setTheLink(response.data.link);
            })
            .catch(function (error) {
                toast.error('Failed to get finalization link');
            })

    }, []);

    if (!hasLink) {
        return (<div style={{marginTop: 24}}><LinearProgress/></div>);
    }

    return (
        <Typography variant="body2">
            <FontAwesomeIcon icon={faLink} fixedWidth color={PRIMARY_BLUE_COLOUR}/>
            &nbsp;
            <ClickToCopy dataToCopy={theLink}/>
        </Typography>
    );
}

const AccFinalizationExtendButton = ({accountUID}) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const dispatch = useDispatch();

    function handleButtonPress() {
        setShowSpinner(true);

        const callURL = getAdminPath(`jeff/account/${accountUID}/finalization/extend`);
        axiosInstance.get(callURL)
            .then(function (response) {
                // setShowSpinner(false);
                toast.success('Done');
                // dispatch(loadingActionLoadResourceFromServer(RESOURCE_TYPE_USER_ACCOUNT_FINALIZATION, accountUID, resourceActionUpdateSingleResource));
                dispatch(resourceActionUpdateSingleResource(response.data, RESOURCE_TYPE_USER_ACCOUNT_FINALIZATION));
                window.location.reload();
            })
            .catch(function (error) {
                setShowSpinner(false);

                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            })
    }


    return (
        <SpinnerButton
            // Non-button props
            showSpinner={showSpinner}
            // Button props
            variant="outlined"
            color="primary"
            disabled={showSpinner}
            onClick={handleButtonPress}
        >
            Reset Finalization Expiry
        </SpinnerButton>
    );

}

const InternalDisplay = ({userAccount, userAccountFinalization}) => {
    const dateDelta = getDateDeltaDuration(getServerDateTimeAsMoment(userAccountFinalization.expires_timestamp), getMomentUTC());
    const hasExpired = dateDelta.milliseconds() > 0;
    const expText = hasExpired ? "Expired" : "Expires";
    const RenderComp = hasExpired ? RenderTimeSince : RenderTimeTo;
    const rhinoEmails = userAccount.deposify_tags.includes(DEPOSIFY_TAG_ID_RHINO_DISABLE_EMAILS) === true;

    return (
        <>
            <Typography variant="subtitle1" gutterBottom>
                {expText} <RenderComp timestamp={userAccountFinalization.expires_timestamp}/> (
                <RenderTimestamp timestamp={userAccountFinalization.expires_timestamp}/>)

            </Typography>

            <IF condition={!hasExpired}>
                <Typography variant="body2" gutterBottom>
                    You can send this link to the user, it'll will prompt them to fill in a password. Once they do that,
                    they can start using their account.
                    <br/>
                    Note: Rhino users do not need to finalize their account, since they use Deposify via Portal.
                </Typography>

                <div style={{marginTop: 12}}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <AccFinalizationLink accountUID={userAccount.account_uid}/>
                        </Grid>
                        <Grid item>
                            &nbsp;&nbsp;
                        </Grid>
                        <Grid item>
                            <AdminSendEmailVerificationButton
                                accountUID={userAccount.account_uid}
                                buttonText={"Send Link Via Email"}
                                disabled={rhinoEmails}
                            />
                        </Grid>
                    </Grid>
                </div>

            </IF>

            <IF condition={hasExpired}>
                <AccFinalizationExtendButton accountUID={userAccount.account_uid}/>
            </IF>
        </>
    );
}


const AdminFinalizeInfo = ({userAccount}) => {
    const {userAccountFinalization} = useUserAccountFinalizationLoader(userAccount.account_uid);

    const classes = useStyles();

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant="h5" component="h2">
                    Finalization
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    User has yet to pick a password and finalize their account
                </Typography>

                <IF condition={userAccountFinalization}>
                    <InternalDisplay userAccount={userAccount} userAccountFinalization={userAccountFinalization}/>
                </IF>

            </CardContent>
        </Card>
    );
};

export default AdminFinalizeInfo;