import React, {useEffect} from 'react';
import {RESOURCE_URL_PREFIX} from "../../constants/apiConstants";
import {RESOURCE_TYPE_BANK_ACCOUNT} from "../../constants/resourceConstants";
import {axiosInstance} from "../../utilities/axiosInstance";
import {useInfiniteQuery} from "react-query";
import {ButtonGroup, CircularProgress} from "@material-ui/core";
import {NULL_UUID_STRING} from "../../constants/dataConstants";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import IF from "../../clientShared/components/utility/IFComponent";
import {BankAccountContainer} from "../../autoGenerated/components/containers/resourceContainers";
import {TinyBankAccountCard} from "../allTinyCards";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const READ_LIMIT = 60;

const BANK_ACC_TYPE_DEPOSIFY_HELD = "DEPOSIFY_HELD";
const BANK_ACC_TYPE_PARTNER_HELD = "PARTNER_HELD";
const BANK_ACC_TYPE_STRIPE_CONNECTED_ACCOUNT = "STRIPE_CONNECTED_ACCOUNT";
const BANK_ACC_TYPE_STRIPE_PARTNER_CONNECTED_ACCOUNT = "STRIPE_PARTNER_CONNECTED_ACCOUNT";

const ReusableFundsLocation = ({initialFundsLocationUID, valueChangeCallback}) => {
    const fetchBankAccounts = async (key, nextPage = 0) => {
        const offset = key.pageParam ? key.pageParam : 0;
        const loadURL = `/api/${RESOURCE_URL_PREFIX}/resources/resource/${RESOURCE_TYPE_BANK_ACCOUNT}/multiple/offset/${offset}/limit/${READ_LIMIT}/order/created_timestamp/descending/true`;
        const response = await axiosInstance.get(loadURL);
        return response.data.bank_accounts;
    };

    const [viewMode, setViewMode] = React.useState(BANK_ACC_TYPE_DEPOSIFY_HELD);
    const [currentFundsLocationUID, setCurrentFundsLocationUID] = React.useState(initialFundsLocationUID);

    const {
        data,
        isLoading,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery(
        'bankAccounts',
        fetchBankAccounts,
        {
            getNextPageParam: (lastPage, pages) => lastPage.length >= READ_LIMIT ? pages.length * READ_LIMIT : null,
            staleTime: 1000 * 60 * 30, // 30 minutes
        }
    );

    useEffect(() => {
        if (hasNextPage && !isLoading && !isFetchingNextPage) {
            void fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, isLoading]);

    const doneLoading = !isLoading && !isFetching && !hasNextPage;

    if (!doneLoading) {
        return <CircularProgress/>;
    }

    // let depBankAccounts = [], partnerBankAccounts = [];
    // const internalType = partnerMode ? "PARTNER_HELD" : "DEPOSIFY_HELD";
    const internalType = viewMode;
    let autoComp = [];
    const rhinoButtonVariant = internalType === BANK_ACC_TYPE_DEPOSIFY_HELD ? "contained" : "outlined";
    const partnerButtonVariant = internalType === BANK_ACC_TYPE_PARTNER_HELD ? "contained" : "outlined";
    const stripeButtonVariant = internalType === BANK_ACC_TYPE_STRIPE_CONNECTED_ACCOUNT ? "contained" : "outlined";
    const partnerStripeButtonVariant = internalType === BANK_ACC_TYPE_STRIPE_PARTNER_CONNECTED_ACCOUNT ? "contained" : "outlined";

    data.pages.forEach((currentPage) => {
        currentPage.forEach((currentBankAccount) => {
            if (currentBankAccount.internal_type === internalType) {
                autoComp.push({
                    uid: currentBankAccount.bank_account_uid,
                    title: getBankDisplay(currentBankAccount)
                });
            }
        });
    });

    function getBankDisplay(bankAccount) {
        // if (bankAccount.partner_account_uid !== NULL_UUID_STRING) {
        //     return `! PARTNER -- ${bankAccount.bank_name} - ${bankAccount.geo_state} - ${bankAccount.address_line_one}`;
        // }
        const infoStrings = [
            // bankAccount.internal_type,
            bankAccount.bank_name,
            bankAccount.geo_state,
            bankAccount.address_line_one,
            bankAccount.address_line_two,
            bankAccount.address_line_three,
            bankAccount.name_on_account,
            bankAccount.nickname
        ];

        return infoStrings.join(", ");
    }

    const handleSelectedItemChange = (changeEvent, value, reason, details) => {
        /**
         * Change event when an option in the list is selected or cleared
         */
            // if cleared: reason = clear, value = undefined
        const newValue = value === null ? NULL_UUID_STRING : value.uid;
        setCurrentFundsLocationUID(newValue);
        valueChangeCallback(newValue);
    }

    const updateViewMode = (newMode) => {
        setViewMode(newMode);
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={3}
        >

            <Grid item>
                {/*<Typography variant="caption" gutterBottom style={{color: "#9f9f9f"}}>*/}
                {/*    Display mode*/}
                {/*</Typography>*/}
                {/*<br/>*/}

                <ButtonGroup size="medium" aria-label="small outlined button group">
                    <Button onClick={() => updateViewMode(BANK_ACC_TYPE_DEPOSIFY_HELD)}
                            variant={rhinoButtonVariant}>Rhino</Button>
                    <Button onClick={() => updateViewMode(BANK_ACC_TYPE_PARTNER_HELD)}
                            variant={partnerButtonVariant}>Partner</Button>
                    <Button onClick={() => updateViewMode(BANK_ACC_TYPE_STRIPE_CONNECTED_ACCOUNT)}
                            variant={stripeButtonVariant}>Stripe Connected - Rhino</Button>
                    <Button onClick={() => updateViewMode(BANK_ACC_TYPE_STRIPE_PARTNER_CONNECTED_ACCOUNT)}
                            variant={partnerStripeButtonVariant}>Stripe Connected - Partner</Button>
                </ButtonGroup>

            </Grid>

            <Grid item>
                <Typography variant="body2">
                    <IF condition={internalType === BANK_ACC_TYPE_DEPOSIFY_HELD}>
                        Viewing Rhino bank accounts; these are internal bank accounts used only by / for Rhino / Deposify
                    </IF>
                    <IF condition={internalType === BANK_ACC_TYPE_PARTNER_HELD}>
                        Viewing Partner Held bank accounts; these are bank accounts owned and operated by the Partner. Funds sent here are not under control of Rhino / Deposify.
                    </IF>
                    <IF condition={internalType === BANK_ACC_TYPE_STRIPE_CONNECTED_ACCOUNT}>
                        Viewing Rhino Stripe Connected bank accounts; Funds are sent here automatically during collection / processing and administered via Stripe by Rhino or the Partner.
                    </IF>
                    <IF condition={internalType === BANK_ACC_TYPE_STRIPE_PARTNER_CONNECTED_ACCOUNT}>
                        Viewing Partner Held Stripe Connected bank accounts; Funds are sent here automatically during collection / processing and administered via Stripe by Rhino or the Partner.
                    </IF>
                </Typography>
            </Grid>

            <Grid item>
                <Autocomplete
                    id="combo-box-demo"
                    options={autoComp}
                    getOptionLabel={(option) => option.title}
                    style={{width: '100%'}}
                    onChange={handleSelectedItemChange}
                    renderInput={
                        (params) =>
                            <TextField {...params} label="Bank Account"/>
                    }
                />
            </Grid>

            <Grid item>
                <IF condition={currentFundsLocationUID !== NULL_UUID_STRING}>
                    <BankAccountContainer bankAccountUID={currentFundsLocationUID}>
                        <TinyBankAccountCard/>
                    </BankAccountContainer>
                </IF>
                <IF condition={currentFundsLocationUID === NULL_UUID_STRING}>
                    <i>No bank account selected</i>
                </IF>
            </Grid>

        </Grid>
    );
};

export default ReusableFundsLocation;