/*
    From: https://github.com/functionalStoic/my-idea-pool-client/blob/master/src/api/utils/axiosInstance.js

    // TODO: This needs some nice cleanup
 */

import * as Sentry from '@sentry/browser';
import axios from 'axios';
import {
    API_DOMAIN,
    API_AUTH_HEADER_KEY,
    API_AUTH_BEARER_KEY,
    API_AUTH_ACCESS_TOKEN,
    API_AUTH_REFRESH_TOKEN
} from "../constants/apiConstants";
import {
    API_ROUTE_AUTH_ACCESS_TOKEN_REFRESH
} from "../constants/apiRouteConstants";


export function getAuthHeader() {
    return API_AUTH_BEARER_KEY + localStorage.getItem(API_AUTH_ACCESS_TOKEN)
}

export function getRefreshHeader() {
    return API_AUTH_BEARER_KEY + localStorage.getItem(API_AUTH_REFRESH_TOKEN)
}

function updateAuthHeader() {
    axiosInstance.defaults.headers[API_AUTH_HEADER_KEY] = getAuthHeader();
}

const axiosInstance = axios.create({
    baseURL: API_DOMAIN,
    timeout: 9955000,
    headers: {
        contentType: 'application/json',
        accept: 'application/json',
        Authorization: getAuthHeader()
    }
});

// const myInterceptor =
/*axiosInstance.interceptors.request.use((config) => {
    return config;
});*/
//axiosInstance.interceptors.request.eject(myInterceptor);

// TODO: This was added just for the live update stream - clean up is needed
export function forceRefresh() {
    axiosInstance.defaults.headers[API_AUTH_HEADER_KEY] = getRefreshHeader();
    axiosInstance.post(API_ROUTE_AUTH_ACCESS_TOKEN_REFRESH)
        .then(({data}) => {
            // Update local storage
            localStorage.setItem(API_AUTH_ACCESS_TOKEN, data.access_token);
            // Update the current headers and the original request headers
            updateAuthHeader();
        })
        .catch(error => {
            axiosInstance.defaults.headers[API_AUTH_HEADER_KEY] = getAuthHeader();
            Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
        });
}


axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;

        if (error.response !== undefined && error.response.status === 401) {
            // Use the refresh token header
            axiosInstance.defaults.headers[API_AUTH_HEADER_KEY] = getRefreshHeader();

            return axiosInstance.post(API_ROUTE_AUTH_ACCESS_TOKEN_REFRESH)
                .then(({data}) => {
                    // Update local storage
                    localStorage.setItem(API_AUTH_ACCESS_TOKEN, data.access_token);

                    // Update the current headers and the original request headers
                    updateAuthHeader();
                    originalRequest.headers[API_AUTH_HEADER_KEY] = getAuthHeader();

                    // Make the original call
                    return axios(originalRequest);
                })
                .catch(error => {
                    // Restore the header
                    originalRequest.headers[API_AUTH_HEADER_KEY] = getAuthHeader();
                    Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
                });
        }

        return Promise.reject(error);
    }
);

//export default axiosInstance;
export {axiosInstance, updateAuthHeader}