import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRepeat} from "@fortawesome/pro-solid-svg-icons";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {adminLoadingActionLoadGhostAccountInfoFromServer} from "../../../actions/adminActions";
import Button from "@material-ui/core/Button";
import toast from "react-hot-toast";

function RefreshAdminGhostButton(props) {

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
        >
            <FontAwesomeIcon
                icon={faRepeat}
                fixedWidth
            />
            &nbsp;&nbsp;Refresh Ghost Account
        </Button>
    );

    function handleClick() {
        props.adminLoadingActionLoadGhostAccountInfoFromServer();
        toast.success('Refreshing...');
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminLoadingActionLoadGhostAccountInfoFromServer: adminLoadingActionLoadGhostAccountInfoFromServer
    }, dispatch);
};

export default connect(null, mapActionsToProps)(RefreshAdminGhostButton);