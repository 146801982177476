import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";

// TODO: These should be exported via the client export utility
export const DEPOSIFY_TAG_ID_MIGRATED_DATA = 10;
export const DEPOSIFY_TAG_ID_BULK_IMPORTED_DATA = 11;
export const DEPOSIFY_TAG_ID_PLACEHOLDER_USER = 12;
export const DEPOSIFY_TAG_ID_PLACEHOLDER_DEPOSIT = 13;
export const DEPOSIFY_TAG_ID_PRE_AUTOMATED_INTEREST_UPDATE_DEPOSIT = 14;
export const DEPOSIFY_TAG_ID_DEPOSIT_INTEREST_MANUALLY_PAID = 15;
export const DEPOSIFY_TAG_ID_DEPOSIT_LOANED = 16;
export const DEPOSIFY_TAG_ID_PRE_PAYMENT_REQUEST_RETENTION = 17;
export const DEPOSIFY_TAG_ID_ACCOUNT_HAS_LOANS = 18;
export const DEPOSIFY_TAG_ID_YARDI_SCREENING_WORKS_PRO_XML_IMPORT_DATA = 19;
export const DEPOSIFY_TAG_ACH_PAYMENT_HAD_PREFIXED_ZEROS_REMOVED_BASED_ON_ROUTING_NUMBER = 20;
export const DEPOSIFY_TAG_ID_YARDI_VOYAGER_XML_IMPORT_DATA = 21;
export const DEPOSIFY_TAG_ID_TENANT_TRANSFERRED_DEPOSIT = 22;
export const DEPOSIFY_TAG_ID_VOYAGER_RESIDENT_FLAG_INITIAL_CREATE_STATUS_AS_NO_SEED_FROM_YARDI = 23;
export const DEPOSIFY_TAG_ID_RESOURCE_CREATED_BY_RHINO = 24;
export const DEPOSIFY_TAG_ID_RHINO_DISABLE_EMAILS = 25;
export const DEPOSIFY_TAG_ID_USE_RHINO_FEES = 26;
export const DEPOSIFY_TAG_ID_FEES_REFUNDED = 27;
export const DEPOSIFY_TAG_ID_HAS_RESOURCE_NOTE = 28;
export const DEPOSIFY_TAG_DEPOSIT_REOPENED = 29;

export const DEPOSIFY_TAG_ID_CLIENT_DATA_RENT_ROLL = 5001;
export const DEPOSIFY_TAG_ID_CLIENT_DATA_BEDLY = 5002;
export const DEPOSIFY_TAG_ID_CLIENT_DATA_VENTURE_REAL_ESTATE = 5003;
export const DEPOSIFY_TAG_ID_CLIENT_DATA_EDGE_REALTY = 5004;
export const DEPOSIFY_TAG_ID_CLIENT_DATA_JUSTIN_RE = 5005;
export const DEPOSING_TAG_ID_CLIENT_DATA_KNOX_FINANCIAL = 5006;
export const DEPOSIFY_TAG_ID_CLIENT_DATA_SPLITSPOT = 5007;
export const DEPOSIFY_TAG_ID_CLIENT_DATA_FENWAY_CONDO = 5008;
export const DEPOSIFY_TAG_ID_CLIENT_FRANKLIN_COMMUNITIES = 5009;

export const DEPOSIFY_TAG_ID_CLIENT_BULK_IMPORT_RENT_ROLL_0_JAN_2020 = 10000;
export const DEPOSIFY_TAG_ID_CLIENT_BULK_IMPORT_FRANKLIN_COMMUNITIES_0_FEB_2020 = 10001;

const TAG_DICT = {
    [DEPOSIFY_TAG_ID_MIGRATED_DATA]: {
        text: 'Migrated',
        color: 'primary',
        tooltip: 'Migrated from V.0 of the Deposify system'
    },
    [DEPOSIFY_TAG_ID_BULK_IMPORTED_DATA]: {
        text: 'Bulk Imported',
        color: 'primary',
        tooltip: 'This data was bulk imported as part of a large data set'
    },
    [DEPOSIFY_TAG_ID_PLACEHOLDER_USER]: {
        text: 'Placeholder',
        color: 'warning',
        tooltip: 'Placeholder Account - Not real, used as part of a bulk import'
    },
    [DEPOSIFY_TAG_ID_PLACEHOLDER_DEPOSIT]: {
        text: 'Placeholder',
        color: 'orange',
        tooltip: 'Placeholder Deposit - Not real, no money is in the system for this, usually part of a bulk import'
    },

    [DEPOSIFY_TAG_ID_PRE_AUTOMATED_INTEREST_UPDATE_DEPOSIT]: {
        text: 'Pre-Auto-interest',
        color: 'blue',
        tooltip: 'This deposit was in the system before auto-interest'
    },
    [DEPOSIFY_TAG_ID_DEPOSIT_INTEREST_MANUALLY_PAID]: {
        text: 'Manual-interest',
        color: 'blue',
        tooltip: 'This deposit had its interest paid manually before auto-interest came online'
    },
    [DEPOSIFY_TAG_ID_DEPOSIT_LOANED]: {
        text: 'Paid-By-Loan',
        color: 'blue',
        tooltip: 'This deposit was paid for with a Deposify finance loan'
    },
    [DEPOSIFY_TAG_ID_PRE_PAYMENT_REQUEST_RETENTION]: {
        text: 'Pre-Payment-Req-Retention',
        color: 'blue',
        tooltip: 'This payment was made before we retained payment requests and set state. There will be no payment request for this payment.'
    },
    [DEPOSIFY_TAG_ID_ACCOUNT_HAS_LOANS]: {
        text: 'Account Has Loaned Funds',
        color: 'red',
        tooltip: 'This account has used loans to fund deposits.'
    },

    [DEPOSIFY_TAG_ID_YARDI_SCREENING_WORKS_PRO_XML_IMPORT_DATA]: {
        text: 'Yardi Screening Works Pro XML Import',
        color: 'red',
        tooltip: 'This data was generated from Screening Works Pro XML'
    },
    [DEPOSIFY_TAG_ACH_PAYMENT_HAD_PREFIXED_ZEROS_REMOVED_BASED_ON_ROUTING_NUMBER]: {
        text: 'ACH Payment Has Prefixed Zeros',
        color: 'red',
        tooltip: 'Prefixed with zeros'
    },
    [DEPOSIFY_TAG_ID_YARDI_VOYAGER_XML_IMPORT_DATA]: {
        text: 'Yardi Voyager XML Import',
        color: 'red',
        tooltip: 'Generated from Yardi Voyager XML'
    },
    [DEPOSIFY_TAG_ID_TENANT_TRANSFERRED_DEPOSIT]: {
        text: 'Tenant Transferred Deposit',
        color: 'red',
        tooltip: 'Deposit was transferred to another tenant. E.g. Yardi roommate promotion'
    },
    [DEPOSIFY_TAG_ID_VOYAGER_RESIDENT_FLAG_INITIAL_CREATE_STATUS_AS_NO_SEED_FROM_YARDI]: {
        text: 'Yardi Voyager Initial Create Not From Full Lookup',
        color: 'red',
        tooltip: 'If a Voyager Resident has this tag it means their initial status was something that didn\'t merit a full data lookup'
    },
    [DEPOSIFY_TAG_ID_RESOURCE_CREATED_BY_RHINO]: {
        text: 'Created by Rhino',
        color: 'blue',
        tooltip: 'This resource was created via the Rhino integration'
    },
    [DEPOSIFY_TAG_ID_RHINO_DISABLE_EMAILS]: {
        text: 'Rhino - All Emails Disabled',
        color: 'blue',
        tooltip: 'This account will receive no emails - special set up for Rhino integration user accounts'
    },
    [DEPOSIFY_TAG_ID_USE_RHINO_FEES]: {
        text: 'Rhino Fees',
        color: 'blue',
        tooltip: 'This resource uses Rhino fees, not Deposify'
    },
    [DEPOSIFY_TAG_ID_FEES_REFUNDED]: {
        text: 'Refunded Fees $',
        color: 'red',
        tooltip: 'Fees have been refunded'
    },
    [DEPOSIFY_TAG_ID_HAS_RESOURCE_NOTE]: {
        text: 'Has Resource Note',
        color: 'blue',
        tooltip: 'This resource has a note attached'
    },
    [DEPOSIFY_TAG_DEPOSIT_REOPENED]: {
        text: 'Reopened Deposit',
        color: 'blue',
        tooltip: 'This was / is reopened - see metadata'
    },


    // --------

    [DEPOSIFY_TAG_ID_CLIENT_DATA_RENT_ROLL]: {
        text: 'Renters Warehouse',
        color: 'blue',
        tooltip: 'Renters Warehouse data'
    },
    [DEPOSIFY_TAG_ID_CLIENT_DATA_BEDLY]: {text: 'Bedly', color: 'blue', tooltip: 'Bedly data'},
    [DEPOSIFY_TAG_ID_CLIENT_DATA_VENTURE_REAL_ESTATE]: {
        text: 'Venture',
        color: 'blue',
        tooltip: 'Venture Realestate data'
    },
    [DEPOSIFY_TAG_ID_CLIENT_DATA_EDGE_REALTY]: {text: 'Edge Realty', color: 'blue', tooltip: 'Edge Realty data'},
    [DEPOSIFY_TAG_ID_CLIENT_DATA_JUSTIN_RE]: {text: 'Justin RE', color: 'blue', tooltip: 'Justin RE data'},
    [DEPOSING_TAG_ID_CLIENT_DATA_KNOX_FINANCIAL]: {
        text: 'Knox Financial',
        color: 'blue',
        tooltip: 'Knox Financial data'
    },
    [DEPOSIFY_TAG_ID_CLIENT_DATA_SPLITSPOT]: {text: 'Splitspot', color: 'blue', tooltip: 'Splitspot data'},
    [DEPOSIFY_TAG_ID_CLIENT_DATA_FENWAY_CONDO]: {text: 'Fenway', color: 'blue', tooltip: 'Fenway Condo data'},
    [DEPOSIFY_TAG_ID_CLIENT_FRANKLIN_COMMUNITIES]: {
        text: 'Franklin Communities',
        color: 'blue',
        tooltip: 'Franklin Communities'
    },
    [DEPOSIFY_TAG_ID_CLIENT_BULK_IMPORT_RENT_ROLL_0_JAN_2020]: {
        text: 'Renters Warehouse - Import 0 - Jan 2020',
        color: 'blue',
        tooltip: 'Bulk import 0 for Renters Warehouse - Jan 2020'
    },
    [DEPOSIFY_TAG_ID_CLIENT_BULK_IMPORT_FRANKLIN_COMMUNITIES_0_FEB_2020]: {
        text: 'Franklin Communities - Import 0 - Feb 2020',
        color: 'blue',
        tooltip: 'Franklin Communities - Import 0 - Feb 2020'
    }
};

const useStyles = makeStyles(theme => ({
    standardTag: {
        margin: 5,
        marginLeft: 0
    }
}));

export default function RenderDeposifyTags({deposifyTags, ...props}) {
    const classes = useStyles();

    if (deposifyTags.length === 0) {
        return <i>no tags</i>;
    }

    return (
        deposifyTags.map(
            renderTag => {
                const tagInfo = TAG_DICT[renderTag];
                const tColour = tagInfo.color === "blue" ? "primary" : "secondary";

                if (tagInfo === undefined) {
                    return (
                        <Chip
                            variant="default"
                            color='secondary'
                            size="small"
                            avatar={<Avatar>XX</Avatar>}
                            label={
                                <React.Fragment>
                                    {renderTag} - BROKEN TAG - TELL DAVE
                                </React.Fragment>
                            }
                            className={classes.standardTag}
                        />
                    );
                }


                return (
                    <Tooltip
                        title={tagInfo.tooltip}
                        placement={'top'}
                        key={`dep-tag-${renderTag}`}
                    >
                        <Chip
                            variant="default"
                            color={tColour}
                            size="small"
                            avatar={<Avatar>{tagInfo.text[0]}</Avatar>}
                            label={tagInfo.text}
                            className={classes.standardTag}
                        />
                    </Tooltip>
                );
            }
        )
    )

}