/*
    System Stats Container to load stats of a specific type for use by child components.
    All loading, error handling and display is handled here.
    The child component just needs to accept 'systemStats' arg which will be a list of system stats objects.

    Example:
        <SystemStatsContainer statsID={110} readCount={7}>
            <DailyDetailedDepositStats/>
        </SystemStatsContainer>
 */
import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {adminActionLoadSystemStatsFromServer} from "../../actions/adminActions";
import {
    checkSystemStatsLoadingIndicator,
} from "../../utilities/loadingUtilities";
import {adminMakeSystemStatsSelector} from "../../selectors/adminSelectors";
import ErrorInfoDisplay from "../utilities/displayAreas/ErrorInfoDisplay";
import {CIRCULAR_PROGRESS_DEFAULT_SIZE} from "../../constants/styleConstants";
import CircularProgress from "@material-ui/core/CircularProgress";

// TODO: Auto refresh -
// TODO: Display last loaded timestamp, button to refresh

function SystemStatsContainer(props) {
    const {loaded, loading, error} = checkSystemStatsLoadingIndicator(props.systemStats);

    if (loaded === false && loading === false && error === false) {
        props.adminActionLoadSystemStatsFromServer(props.statsID, props.readCount);
    }

    if (error === true) {
        return <ErrorInfoDisplay showError={true}>Failed to load stats</ErrorInfoDisplay>;
    }

    if (loaded === false || loading === true) {
        return <CircularProgress size={CIRCULAR_PROGRESS_DEFAULT_SIZE}/>;
    }

    // Pass the loaded stats out to any child components
    const updatedChildrenWithProps = React.Children.map(props.children,
        (child) => {
            return React.cloneElement(child, {
                // Pass the loaded stats JSON - Reverse it
                systemStats: props.systemStats.stats.reverse()
            });
        }
    );

    // Render the children
    return <React.Fragment>{updatedChildrenWithProps}</React.Fragment>;
}

const mapStateToProps = (state) => {
    const _adminMakeSystemStatsSelector = adminMakeSystemStatsSelector();

    return function realMapState(state, props) {
        return {
            systemStats: _adminMakeSystemStatsSelector(state, props.statsID)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionLoadSystemStatsFromServer: adminActionLoadSystemStatsFromServer
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(SystemStatsContainer);
