/*

 */

import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {jsDateTimeFromString, uuidToHex} from "../../../utilities/commonUtils";
import ErrorInfoDisplay from "../../utilities/displayAreas/ErrorInfoDisplay";
import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import Moment from "react-moment";
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import AdminDisplayPropertyAddress from "./adminDisplayPropertyAddress";
import {PropertyAddressContainer} from "../../../autoGenerated/components/containers/resourceContainers";

function AdminViewFullPropertyDisplay({loading, error, property, propertyUID, ...props}) {

    if(error === true){
        return (
            <ErrorInfoDisplay showError={true}>
                Failed to load property
            </ErrorInfoDisplay>
        );
    }

    if(loading === true || error === true || property === undefined){
        return <VisualizeContainerLoadingIndicator loadingResource={property} displayName={'Property'} {...props} />;
    }

    return (
        <React.Fragment>
            Full property info for: {uuidToHex(propertyUID)}
            <br/><br/>

            <h3>Property</h3>
            Title: {property.title}<br/>
            Property UID: {property.property_uid} <br/>
            Created by account: <AdminViewFullAccountInfoLink accountUID={property.creating_account_uid}/><br/>
            Property type: {property.property_type} <br/>
            Unit/Apt number: {property.unit_apt_number} <br/>
            Created: <Moment format={'DD MMM YYYY'} date={jsDateTimeFromString(property.created_timestamp, true)}/> <br/>
            Last updated: <Moment format={'DD MMM YYYY'} date={jsDateTimeFromString(property.last_updated_timestamp, true)}/> <br/>
            <br/>

            <h3>Property Address</h3>
            {
                property.property_address_uid === NULL_UUID_STRING &&
                <React.Fragment>No property address</React.Fragment>
            }
            {
                property.property_address_uid !== NULL_UUID_STRING &&
                <PropertyAddressContainer propertyAddressUID={property.property_address_uid}>
                    <AdminDisplayPropertyAddress/>
                </PropertyAddressContainer>
            }

            <br/>
        </React.Fragment>
    );

}

const mapStateToProps = (state) => ({

});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(AdminViewFullPropertyDisplay);