import React from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";
import {BILLION_BRIGHT_BLUE, NULL_UUID_STRING_HEX} from "../../../constants/dataConstants";
import {ADMIN_URL} from "../../../constants/apiConstants";


const DeposifyPropertyInfo = ({depPropertyUID}) => {
    if (depPropertyUID !== NULL_UUID_STRING_HEX) {
        const linkString = `${ADMIN_URL}/deposits/properties/${depPropertyUID}`;

        return (
            <a href={linkString} target="_blank" rel="noopener noreferrer"
               style={{color: BILLION_BRIGHT_BLUE, textDecoration: 'none'}}>
                Link
            </a>
        );
    }

    return (
        <span>
            <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth color={"#ef3c3c"}/>&nbsp;Not Found
        </span>
    );

};

const RowErrors = ({rowErrors}) => {
    if (Object.keys(rowErrors).length === 0) {
        return <><FontAwesomeIcon icon={faCheck} fixedWidth color={"#3cef66"}/>&nbsp;Okay</>;
    }

    return (
        <>
            {rowErrors.map((errorMessage, index) => (
                <span key={index}>
                    <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth color={"#ef3c3c"}/>&nbsp;
                    {errorMessage}<br/>
                </span>
            ))}
        </>
    );

};

const DuplicateInfo = ({rowList, rowIndex, currentRowIndex}) => {
    if (Object.keys(rowList).length === 0) {
        return <>None</>;
    }

    if(!rowList.includes(currentRowIndex))
        return <>Yes</>
        // return <>Yes: {rowList.length.toString()}</>

    // let dupString = rowList.join(", ");

    return <>Duplicate of row {rowIndex.toString()}</>
};

const RhinoBulkBankInfoRows = ({parsedData, validationResults}) => {
    if (Object.keys(parsedData).length === 0 || Object.keys(validationResults).length === 0) {
        return <>Loading...</>;
    }

    // todo: click to copy
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell>Row</TableCell>
                        <TableCell>Property ID</TableCell>
                        <TableCell>Bank Account</TableCell>
                        <TableCell>Deposify Property</TableCell>
                        <TableCell>Duplicates</TableCell>
                        <TableCell>Validation Results</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        parsedData.rowInfo.map((currentRow, rowIndex) => {
                            const bankAccount = parsedData.bankAccounts[currentRow.bankAccount];
                            const propertyInfo = parsedData.properties[currentRow.property];
                            const depPropertyUID = validationResults.rhino_property_ids[currentRow.property];
                            let finalErrors = [...currentRow.errors];
                            if (depPropertyUID === NULL_UUID_STRING_HEX)
                                finalErrors.push("No Deposify property, make sure Portal is syncing to Deposify")

                            return (
                                <TableRow key={`${currentRow.property}-${rowIndex}`}>
                                    <TableCell component="th" scope="row">{rowIndex.toString()}</TableCell>
                                    <TableCell component="th" scope="row">{currentRow.property.toString()}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {bankAccount.bank_name}, {bankAccount.account_number}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <DeposifyPropertyInfo depPropertyUID={depPropertyUID}/>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <DuplicateInfo
                                            rowList={propertyInfo.duplicate_rows}
                                            rowIndex={propertyInfo.row_index}
                                            currentRowIndex={rowIndex}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <RowErrors rowErrors={finalErrors}/>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RhinoBulkBankInfoRows;