// Name: System_General Table Render Definitions 


export const DEPOSIFY_TAG_TABLE_RENDER_DEF = {
    tag_uid: {displayName: "Tag UID"},
    title: {displayName: "Title"},
    html_colour: {displayName: "Html Colour"},
    icon_name: {displayName: "Icon Name"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    created_timestamp: {displayName: "Created Timestamp"},
    // description: {displayName: "Description"},
    // deposify_tags: {displayName: "Deposify Tags"},
    // notes_uid: {displayName: "Notes UID"}
};

export const SYSTEM_STATS_TABLE_RENDER_DEF = {
    // stats_json: {displayName: "Stats Json"},
    stats_uid: {displayName: "Stats UID"},
    version: {displayName: "Version"},
    stats_id: {displayName: "Stats Id"},
    stats_type: {displayName: "Stats Type"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const RESOURCE_NOTE_TABLE_RENDER_DEF = {
    notes_uid: {displayName: "Notes UID"},
    author_account_uid: {displayName: "Author Account UID"},
    notes: {displayName: "Notes"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const PERMANENT_LINK_TABLE_RENDER_DEF = {
    link_uid: {displayName: "Link UID"},
    link_type: {displayName: "Link Type"},
    resource_uid: {displayName: "Resource UID"},
    resource_type: {displayName: "Resource Type"},
    // last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    last_used_timestamp: {displayName: "Last Used Timestamp"},
    // created_timestamp: {displayName: "Created Timestamp"},
    // payload: {displayName: "Payload"},
    // notes_uid: {displayName: "Notes UID"},
    // deposify_tags: {displayName: "Deposify Tags"}
};