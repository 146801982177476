import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    appActionSetFullScreenIntBankDetails
} from "../../../../actions/appActions";
import Link from "@material-ui/core/Link";
import {NULL_UUID_STRING} from "../../../../constants/dataConstants";

function AdminViewIntBankAccountLink({bankAccountUID, linkText = 'View', ...props}) {

    if(bankAccountUID === NULL_UUID_STRING){
        return <React.Fragment>None</React.Fragment>;
    }

    return (
        <Link
            component="button"
            onClick={linkClicked}
        >
            {linkText}
        </Link>
    );

    function linkClicked() {
        props.appActionSetFullScreenIntBankDetails(
            {
                showDialog: true,
                bankAccountUID: bankAccountUID
            }
        );
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        appActionSetFullScreenIntBankDetails: appActionSetFullScreenIntBankDetails
    }, dispatch);
};

export default connect(null, mapActionsToProps)(AdminViewIntBankAccountLink);