/*

 */

import React from 'react';
import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import {dollarFormattedString} from "../../../utilities/commonUtils";
import AdminDepositStateRender from "./adminDepositStateRender";
import Grid from "@material-ui/core/Grid";
import {usStateAbbreviationToFull} from "../../../constants/usStatesConstants";

function AdminSmallDepositDisplay({loading, error, deposit, ...props}) {

    if (loading === true || error === true || deposit === undefined) {
        return <VisualizeContainerLoadingIndicator loadingResource={deposit} displayName={'Deposit'} {...props} />;
    }

    // {userAccount.username} / {userAccount.first_name} {userAccount.last_name}
    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            wrap={'nowrap'}
        >
            <Grid item>
                {deposit.title}
            </Grid>

            <Grid item>
                {dollarFormattedString(deposit.amount_in_cents)}&nbsp;|&nbsp;{usStateAbbreviationToFull(deposit.geo_state)}
            </Grid>

            <Grid item>
                <AdminDepositStateRender deposit={deposit}/>
            </Grid>

        </Grid>
    );

}

export default AdminSmallDepositDisplay;