import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Chip from "@material-ui/core/Chip";
import {faDollarSign} from "@fortawesome/pro-regular-svg-icons";
import {NULL_UUID_STRING} from "../../constants/dataConstants";




export default function PaymentHasFundsLocationChip({paymentObject}) {


    const hasLocationUID = paymentObject.clear_into_funds_location_uid !== NULL_UUID_STRING;
    const chipColour = "secondary";
    const chipText = "Funds Location NOT set";

    if(hasLocationUID === true || paymentObject.payment_succeeded === false)
    {
        return <React.Fragment/>;
    }

    return (
        <Chip
            color={chipColour}
            icon={
                <FontAwesomeIcon
                    icon={faDollarSign}
                    fixedWidth
                />
            }
            label={chipText}
        />
    );
}
