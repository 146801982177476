// Name: Yardi Table Render Definitions 

export const YARDI_SCREENING_WORKS_PRO_XML_TABLE_RENDER_DEF = {
	processing_completed_timestamp: {displayName: "Processing Completed Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	created_deposit_uids: {displayName: "Created Deposit UIDs"},
	deposify_tags: {displayName: "Deposify Tags"},
	created_tenant_account_uids: {displayName: "Created Tenant Account UIDs"},
	admin_error_message: {displayName: "Admin Error Message"},
	created_deposit_group_uid: {displayName: "Created Deposit Group UID"},
	xml_data: {displayName: "Xml Data"},
	notes_uid: {displayName: "Notes UID"},
	permanent_link_uid: {displayName: "Permanent Link UID"},
	xml_uid: {displayName: "Xml UID"},
	pmc_email_hashes: {displayName: "Pmc Email Hashes"},
	created_pmc_account_uid: {displayName: "Created Pmc Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"}
};

export const YARDI_VOYAGER_ACTIVE_SCAN_LOG_ENTRY_TABLE_RENDER_DEF = {
	scan_end_timestamp: {displayName: "Scan End Timestamp"},
	voyager_property_uid: {displayName: "Voyager Property UID"},
	delete_after_timestamp: {displayName: "Delete After Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	version: {displayName: "Version"},
	deposify_tags: {displayName: "Deposify Tags"},
	scan_type: {displayName: "Scan Type"},
	notes_uid: {displayName: "Notes UID"},
	do_not_delete: {displayName: "Do Not Delete"},
	scan_start_timestamp: {displayName: "Scan Start Timestamp"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	voyager_property_unit_uid: {displayName: "Voyager Property Unit UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	completed_steps_json: {displayName: "Completed Steps Json"},
	log_uid: {displayName: "Log UID"}
};

export const YARDI_VOYAGER_CHARGE_TABLE_RENDER_DEF = {
	voyager_charge_code: {displayName: "Voyager Charge Code"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	intent_uid: {displayName: "Intent UID"},
	notes_uid: {displayName: "Notes UID"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	charge_comments: {displayName: "Charge Comments"},
	voyager_xml_uid: {displayName: "Voyager Xml UID"},
	voyager_charge_uid: {displayName: "Voyager Charge UID"},
	charge_amount_in_cents: {displayName: "Charge Amount In Cents"},
	version: {displayName: "Version"},
	voyager_property_uid: {displayName: "Voyager Property UID"},
	charge_code_uid: {displayName: "Charge Code UID"},
	charge_code_string: {displayName: "Charge Code String"},
	voyager_property_unit_uid: {displayName: "Voyager Property Unit UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	admin_error_info: {displayName: "Admin Error Info"},
	intent_type: {displayName: "Intent Type"},
	voyager_person_code: {displayName: "Voyager Person Code"},
	voyager_resident_uid: {displayName: "Voyager Resident UID"}
};

export const YARDI_VOYAGER_CHARGE_CODE_TABLE_RENDER_DEF = {
	intent: {displayName: "Intent"},
	version: {displayName: "Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	charge_code_uid: {displayName: "Charge Code UID"},
	notes_uid: {displayName: "Notes UID"},
	public_description: {displayName: "Public Description"},
	charge_code_string: {displayName: "Charge Code String"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	owner: {displayName: "Owner"}
};

export const YARDI_VOYAGER_DEPOSIT_CLOSE_INTENT_TABLE_RENDER_DEF = {
	close_intent_uid: {displayName: "Close Intent UID"},
	yardi_voyager_property_uid: {displayName: "Yardi Property UID"},
	// voyager_property_unit_id: {displayName: "Voyager Unit Id"},
	yardi_voyager_property_unit_uid: {displayName: "Yardi Property Unit UID"},
	state: {displayName: "State"},
	last_updated_timestamp: {displayName: "Last Updated"},
	deposify_pmc_account_uid: {displayName: "Pmc Account UID"},
	intent_cancel_reason: {displayName: "Cancel Reason"},
	// source: {displayName: "Source"},
	// notes_uid: {displayName: "Notes UID"},
	// deposify_tags: {displayName: "Deposify Tags"},
	// yardi_voyager_transaction_uid: {displayName: "Yardi Voyager Transaction UID"},
	// yardi_voyager_resident_uid: {displayName: "Yardi Voyager Resident UID"},
	// admin_error_message: {displayName: "Admin Error Message"},
	// action_intent_after_timestamp: {displayName: "Action Intent After Timestamp"},
	// organization_uids_with_read_permission: {displayName: "Organization UIDs With Read Permission"},
	// version: {displayName: "Version"},
	// remind_after_timestamp: {displayName: "Remind After Timestamp"},
	// account_uids_with_read_permission: {displayName: "Account UIDs With Read Permission"},
	// created_timestamp: {displayName: "Created Timestamp"},
	// intent_creation_reason: {displayName: "Intent Creation Reason"},
	// charge_code: {displayName: "Charge Code"},
	// close_action: {displayName: "Close Action"}
};

export const YARDI_VOYAGER_DEPOSIT_CREATE_INTENT_TABLE_RENDER_DEF = {
	create_intent_uid: {displayName: "Create Intent UID"},
	created_deposit_uid: {displayName: "Created Deposit UID"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// end_date: {displayName: "End Date"},
	// deposify_tags: {displayName: "Deposify Tags"},
	voyager_property_unit_id: {displayName: "Voyager Property Unit Id"},
	// notes_uid: {displayName: "Notes UID"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	// yardi_voyager_transaction_uid: {displayName: "Yardi Voyager Transaction UID"},
	yardi_voyager_resident_uid: {displayName: "Yardi Voyager Resident UID"},
	// intent_cancel_reason: {displayName: "Intent Cancel Reason"},
	// source: {displayName: "Source"},
	yardi_voyager_property_uid: {displayName: "Yardi Voyager Property UID"},
	// admin_error_message: {displayName: "Admin Error Message"},
	// action_intent_after_timestamp: {displayName: "Action Intent After Timestamp"},
	// organization_uids_with_read_permission: {displayName: "Organization UIDs With Read Permission"},
	yardi_voyager_property_unit_uid: {displayName: "Yardi Voyager Property Unit UID"},
	// start_date: {displayName: "Start Date"},
	// deposit_invite_sent: {displayName: "Deposit Invite Sent"},
	// version: {displayName: "Version"},
	// create_intent_type: {displayName: "Create Intent Type"},
	// amount_in_cents: {displayName: "Amount In Cents"},
	// remind_after_timestamp: {displayName: "Remind After Timestamp"},
	// account_uids_with_read_permission: {displayName: "Account UIDs With Read Permission"},
	// created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	intent_creation_reason: {displayName: "Intent Creation Reason"},
	// geo_state: {displayName: "Geo State"},
	// charge_code: {displayName: "Charge Code"},
	// yardi_voyager_outgoing_resident_uid: {displayName: "Yardi Voyager Outgoing Resident UID"}
};

export const YARDI_VOYAGER_DEPOSIT_WITHHOLD_INTENT_TABLE_RENDER_DEF = {
	version: {displayName: "Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	withhold_intent_uid: {displayName: "Withhold Intent UID"},
	admin_error_message: {displayName: "Admin Error Message"},
	yardi_voyager_xml_uid: {displayName: "Yardi Voyager Xml UID"},
	notes_uid: {displayName: "Notes UID"},
	yardi_voyager_property_unit_uid: {displayName: "Yardi Voyager Property Unit UID"},
	landlord_account_uid: {displayName: "Landlord Account UID"},
	yardi_voyager_transaction_uid: {displayName: "Yardi Voyager Transaction UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	charge_code: {displayName: "Charge Code"},
	create_deposit_timestamp: {displayName: "Create Deposit Timestamp"}
};

export const YARDI_VOYAGER_EVENT_TABLE_RENDER_DEF = {
	assurant_acknowledged_timestamp: {displayName: "Assurant Acknowledged Timestamp"},
	version: {displayName: "Version"},
	voyager_property_uid: {displayName: "Voyager Property UID"},
	assurant_of_interest_flag: {displayName: "Assurant Of Interest Flag"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	event_uid: {displayName: "Event UID"},
	deposify_tags: {displayName: "Deposify Tags"},
	event_payload: {displayName: "Event Payload"},
	resource_uid: {displayName: "Resource UID"},
	notes_uid: {displayName: "Notes UID"},
	event_type: {displayName: "Event Type"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	voyager_property_unit_uid: {displayName: "Voyager Property Unit UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	voyager_resource_type: {displayName: "Voyager Resource Type"}
};

export const YARDI_VOYAGER_FLEX_DEPOSIT_INTENT_TABLE_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	end_date: {displayName: "End Date"},
	deposify_tags: {displayName: "Deposify Tags"},
	notes_uid: {displayName: "Notes UID"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	yardi_voyager_transaction_uid: {displayName: "Yardi Voyager Transaction UID"},
	parent_deposit_create_intent_uid: {displayName: "Parent Deposit Create Intent UID"},
	flex_intent_uid: {displayName: "Flex Intent UID"},
	yardi_voyager_resident_uid: {displayName: "Yardi Voyager Resident UID"},
	bond_status: {displayName: "Bond Status"},
	source: {displayName: "Source"},
	intent_cancel_reason: {displayName: "Intent Cancel Reason"},
	yardi_voyager_property_uid: {displayName: "Yardi Voyager Property UID"},
	admin_error_message: {displayName: "Admin Error Message"},
	action_intent_after_timestamp: {displayName: "Action Intent After Timestamp"},
	organization_uids_with_read_permission: {displayName: "Organization UIDs With Read Permission"},
	yardi_voyager_property_unit_uid: {displayName: "Yardi Voyager Property Unit UID"},
	start_date: {displayName: "Start Date"},
	bond_id: {displayName: "Bond Id"},
	version: {displayName: "Version"},
	bond_amount_in_cents: {displayName: "Bond Amount In Cents"},
	remind_after_timestamp: {displayName: "Remind After Timestamp"},
	account_uids_with_read_permission: {displayName: "Account UIDs With Read Permission"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	intent_creation_reason: {displayName: "Intent Creation Reason"},
	geo_state: {displayName: "Geo State"},
	deposit_amount_in_cents: {displayName: "Deposit Amount In Cents"},
	charge_code: {displayName: "Charge Code"}
};

export const YARDI_VOYAGER_ILS_GUEST_CARD_EVENT_TABLE_RENDER_DEF = {
	version: {displayName: "Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	content_owner: {displayName: "Content Owner"},
	voyager_resident_uid: {displayName: "Voyager Resident UID"},
	notes_uid: {displayName: "Notes UID"},
	guest_card_event_uid: {displayName: "Guest Card Event UID"},
	last_update_failure_timestamp: {displayName: "Last Update Failure Timestamp"},
	event_id: {displayName: "Event Id"},
	created_timestamp: {displayName: "Created Timestamp"},
	event_text: {displayName: "Event Text"},
	state: {displayName: "State"},
	update_failure_count: {displayName: "Update Failure Count"}
};

export const YARDI_VOYAGER_OTHER_OCCUPANT_TABLE_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	notes_uid: {displayName: "Notes UID"},
	first_name: {displayName: "First Name"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	email_hash: {displayName: "Email Hash"},
	email_address: {displayName: "Email Address"},
	organization_uids_with_read_permission: {displayName: "Organization UIDs With Read Permission"},
	last_name: {displayName: "Last Name"},
	voyager_roommate_code: {displayName: "Voyager Roommate Code"},
	other_occupant_uid: {displayName: "Other Occupant UID"},
	version: {displayName: "Version"},
	voyager_property_uid: {displayName: "Voyager Property UID"},
	account_uids_with_read_permission: {displayName: "Account UIDs With Read Permission"},
	voyager_prospect_code: {displayName: "Voyager Prospect Code"},
	created_timestamp: {displayName: "Created Timestamp"},
	voyager_property_unit_uid: {displayName: "Voyager Property Unit UID"},
	voyager_roommate_code_type: {displayName: "Voyager Roommate Code Type"},
	parent_voyager_resident_uid: {displayName: "Parent Voyager Resident UID"},
	voyager_tenant_code: {displayName: "Voyager Tenant Code"}
};

export const YARDI_VOYAGER_PET_TABLE_RENDER_DEF = {
	version: {displayName: "Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	neutered: {displayName: "Neutered"},
	deposify_tags: {displayName: "Deposify Tags"},
	voyager_pet_code: {displayName: "Voyager Pet Code"},
	pet_name: {displayName: "Pet Name"},
	service_animal: {displayName: "Service Animal"},
	voyager_resident_uid: {displayName: "Voyager Resident UID"},
	notes_uid: {displayName: "Notes UID"},
	pet_uid: {displayName: "Pet UID"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	pet_type: {displayName: "Pet Type"},
	pet_color: {displayName: "Pet Color"}
};

export const YARDI_VOYAGER_PHONE_TABLE_RENDER_DEF = {
	version: {displayName: "Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	phone_uid: {displayName: "Phone UID"},
	voyager_resident_uid: {displayName: "Voyager Resident UID"},
	notes_uid: {displayName: "Notes UID"},
	phone_number: {displayName: "Phone Number"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	details_hash: {displayName: "Details Hash"},
	created_timestamp: {displayName: "Created Timestamp"},
	phone_description: {displayName: "Phone Description"},
	phone_type: {displayName: "Phone Type"}
};

export const YARDI_VOYAGER_PMC_TABLE_RENDER_DEF = {
	scan_hour_step_for_get_unit_information: {displayName: "Scan Hour Step For Get Unit Information"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	needed_resident_data_read_permission: {displayName: "Needed Resident Data Read Permission"},
	deposify_tags: {displayName: "Deposify Tags"},
	notes_uid: {displayName: "Notes UID"},
	needed_common_data_read_permission: {displayName: "Needed Common Data Read Permission"},
	scan_utc_hour_stop: {displayName: "Scan Utc Hour Stop"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	push_updates_to_resident_vehicles: {displayName: "Push Updates To Resident Vehicles"},
	last_property_config_scan_timestamp: {displayName: "Last Property Config Scan Timestamp"},
	needed_common_data_write_permission: {displayName: "Needed Common Data Write Permission"},
	error_codes: {displayName: "Error Codes"},
	push_updates_to_resident_custom_columns: {displayName: "Push Updates To Resident Custom Columns"},
	push_updates_via_ils_guest_card_events: {displayName: "Push Updates Via Ils Guest Card Events"},
	version: {displayName: "Version"},
	push_updates_to_resident_pets: {displayName: "Push Updates To Resident Pets"},
	allow_unit_information_checks: {displayName: "Allow Unit Information Checks"},
	next_property_config_scan_timestamp: {displayName: "Next Property Config Scan Timestamp"},
	allow_get_tenant_status_checks: {displayName: "Allow Get Tenant Status Checks"},
	scan_utc_hour_start: {displayName: "Scan Utc Hour Start"},
	needed_resident_data_write_permission: {displayName: "Needed Resident Data Write Permission"},
	allow_property_configuration_checks: {displayName: "Allow Property Configuration Checks"},
	scan_hour_step_for_ils_guest_activity: {displayName: "Scan Hour Step For Ils Guest Activity"},
	resident_custom_columns_uid_list: {displayName: "Resident Custom Columns UID List"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	allow_permission_checks: {displayName: "Allow Permission Checks"},
	has_custom_columns: {displayName: "Has Custom Columns"},
	allow_ils_guest_card_activity_checks: {displayName: "Allow Ils Guest Card Activity Checks"},
	scan_hour_step_for_get_property_config: {displayName: "Scan Hour Step For Get Property Config"},
	last_permission_check_timestamp: {displayName: "Last Permission Check Timestamp"},
	scan_hour_step_for_get_tenant_status: {displayName: "Scan Hour Step For Get Tenant Status"}
};

export const YARDI_VOYAGER_PMC_CREDENTIALS_TABLE_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	ils_guest_username: {displayName: "Ils Guest Username"},
	deposify_tags: {displayName: "Deposify Tags"},
	common_data_platform: {displayName: "Common Data Platform"},
	res_data_platform: {displayName: "Res Data Platform"},
	notes_uid: {displayName: "Notes UID"},
	res_data_password: {displayName: "Res Data Password"},
	res_transact_server_name: {displayName: "Res Transact Server Name"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	ils_guest_wsdl_service_url: {displayName: "Ils Guest Wsdl Service Url"},
	res_transact_database: {displayName: "Res Transact Database"},
	res_data_wsdl_service_url: {displayName: "Res Data Wsdl Service Url"},
	ils_guest_server_name: {displayName: "Ils Guest Server Name"},
	common_data_wsdl_service_url: {displayName: "Common Data Wsdl Service Url"},
	res_data_server_name: {displayName: "Res Data Server Name"},
	common_data_username: {displayName: "Common Data Username"},
	ils_guest_password: {displayName: "Ils Guest Password"},
	ils_guest_database: {displayName: "Ils Guest Database"},
	version: {displayName: "Version"},
	base_url: {displayName: "Base Url"},
	res_transact_username: {displayName: "Res Transact Username"},
	res_transact_platform: {displayName: "Res Transact Platform"},
	created_timestamp: {displayName: "Created Timestamp"},
	res_data_database: {displayName: "Res Data Database"},
	ils_guest_platform: {displayName: "Ils Guest Platform"},
	common_data_password: {displayName: "Common Data Password"},
	common_data_database: {displayName: "Common Data Database"},
	res_transact_password: {displayName: "Res Transact Password"},
	res_transact_wsdl_service_url: {displayName: "Res Transact Wsdl Service Url"},
	res_data_username: {displayName: "Res Data Username"},
	common_data_server_name: {displayName: "Common Data Server Name"}
};

export const YARDI_VOYAGER_PMC_CUSTOM_COLUMN_SETTINGS_TABLE_RENDER_DEF = {
	column_type: {displayName: "Column Type"},
	version: {displayName: "Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	column_name: {displayName: "Column Name"},
	deposify_tags: {displayName: "Deposify Tags"},
	column_uid: {displayName: "Column UID"},
	notes_uid: {displayName: "Notes UID"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	created_timestamp: {displayName: "Created Timestamp"}
};

export const YARDI_VOYAGER_PMC_SERVICE_INTERFACE_VERSION_TABLE_RENDER_DEF = {
	version: {displayName: "Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	last_checked_timestamp: {displayName: "Last Checked Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	first_seen_timestamp: {displayName: "First Seen Timestamp"},
	voyager_plugin_version: {displayName: "Voyager Plugin Version"},
	notes_uid: {displayName: "Notes UID"},
	service_interface_version_uid: {displayName: "Service Interface Version UID"},
	voyager_core_interface_version: {displayName: "Voyager Core Interface Version"},
	voyager_core_app_version: {displayName: "Voyager Core App Version"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"}
};

export const YARDI_VOYAGER_PMC_STATS_TABLE_RENDER_DEF = {
	stats_uid: {displayName: "Stats UID"},
	version: {displayName: "Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	notes_uid: {displayName: "Notes UID"},
	stats_date: {displayName: "Stats Date"},
	stats_json: {displayName: "Stats Json"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	created_timestamp: {displayName: "Created Timestamp"}
};

export const YARDI_VOYAGER_PMC_STATS_COLLECTOR_TABLE_RENDER_DEF = {
	stats_uid: {displayName: "Stats UID"},
	version: {displayName: "Version"},
	delete_after_timestamp: {displayName: "Delete After Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	notes_uid: {displayName: "Notes UID"},
	stats_date: {displayName: "Stats Date"},
	stats_json: {displayName: "Stats Json"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	combo_stats_uid: {displayName: "Combo Stats UID"}
};

export const YARDI_VOYAGER_PROPERTY_TABLE_RENDER_DEF = {
	voyager_property_uid: {displayName: "Voyager Property UID"},
	marketing_name: {displayName: "Marketing Name"},
	state: {displayName: "State"},
	voyager_property_code: {displayName: "Voyager Property Code"},
	voyager_property_id: {displayName: "Voyager Property Id"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// deposify_tags: {displayName: "Deposify Tags"},
	// initial_ils_guest_card_activity_scan_timestamp: {displayName: "Initial Ils Guest Card Activity Scan Timestamp"},
	// property_details_need_refreshing: {displayName: "Property Details Need Refreshing"},
	// deposify_property_uid: {displayName: "Deposify Property UID"},
	// notes_uid: {displayName: "Notes UID"},
	// pmc_resource_creating_account_uid: {displayName: "Pmc Resource Creating Account UID"},
	// organization_uids_with_read_permission: {displayName: "Organization UIDs With Read Permission"},
	// last_ils_guest_card_activity_check_timestamp: {displayName: "Last Ils Guest Card Activity Check Timestamp"},
	// next_get_tenant_status_scan_timestamp: {displayName: "Next Get Tenant Status Scan Timestamp"},
	// next_unit_information_scan_timestamp: {displayName: "Next Unit Information Scan Timestamp"},
	// version: {displayName: "Version"},
	// allow_unit_information_checks: {displayName: "Allow Unit Information Checks"},
	// last_get_tenant_status_scan_timestamp: {displayName: "Last Get Tenant Status Scan Timestamp"},
	// allow_get_tenant_status_checks: {displayName: "Allow Get Tenant Status Checks"},
	// next_ils_guest_card_activity_check_timestamp: {displayName: "Next Ils Guest Card Activity Check Timestamp"},
	// account_uids_with_read_permission: {displayName: "Account UIDs With Read Permission"},
	// property_details_hash: {displayName: "Property Details Hash"},
	// last_unit_information_scan_timestamp: {displayName: "Last Unit Information Scan Timestamp"},
	// allow_ils_guest_card_activity_checks: {displayName: "Allow Ils Guest Card Activity Checks"},
	// last_roommate_promotion_check_timestamp: {displayName: "Last Roommate Promotion Check Timestamp"},
	// initial_ils_guest_card_activity_scan_day_count: {displayName: "Initial Ils Guest Card Activity Scan Day Count"}
};

export const YARDI_VOYAGER_PROPERTY_UNIT_TABLE_RENDER_DEF = {
	voyager_property_unit_uid: {displayName: "Voyager Property Unit UID"},
	voyager_property_unit_unique_id: {displayName: "Voyager Property Unit Unique Id"},
	voyager_property_unit_id: {displayName: "Voyager Property Unit Id"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},

	// version: {displayName: "Version"},
	// voyager_parent_property_uid: {displayName: "Voyager Parent Property UID"},
	// last_update_source: {displayName: "Last Update Source"},
	// deposify_tags: {displayName: "Deposify Tags"},
	// market_rent_in_cents: {displayName: "Market Rent In Cents"},
	// notes_uid: {displayName: "Notes UID"},
	// organization_uids_with_read_permission: {displayName: "Organization UIDs With Read Permission"},
	// deposify_property_uid: {displayName: "Deposify Property UID"},
	// creation_source: {displayName: "Creation Source"},
	// account_uids_with_read_permission: {displayName: "Account UIDs With Read Permission"},
	// unit_details_hash: {displayName: "Unit Details Hash"}
};

export const YARDI_VOYAGER_RESIDENT_TABLE_RENDER_DEF = {
	voyager_resident_uid: {displayName: "Resident UID"},
	voyager_resident_status: {displayName: "Yardi Status"},
	voyager_prospect_code: {displayName: "Prospect Code"},
	voyager_tenant_code: {displayName: "Tenant Code"},
	last_updated_timestamp: {displayName: "Last Updated"},
	voyager_property_unit_id: {displayName: "Property Unit Id"},
	first_name: {displayName: "First Name"},
	last_name: {displayName: "Last Name"},
	state: {displayName: "State"},
	// move_out_timestamp: {displayName: "Move Out Timestamp"},
	// deposify_tags: {displayName: "Deposify Tags"},
	// last_update_source: {displayName: "Last Update Source"},
	// rent_in_cents: {displayName: "Rent In Cents"},
	// lease_begin_timestamp: {displayName: "Lease Begin Timestamp"},
	// notes_uid: {displayName: "Notes UID"},
	// close_deposit_intent_uid: {displayName: "Close Deposit Intent UID"},
	// missing_data_list: {displayName: "Missing Data List"},
	// check_intents_on_status_change: {displayName: "Check Intents On Status Change"},
	// deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	// last_voyager_ils_guest_card_event_uid: {displayName: "Last Voyager Ils Guest Card Event UID"},
	// create_deposit_intent_uid: {displayName: "Create Deposit Intent UID"},
	// last_status_change_timestamp: {displayName: "Last Status Change Timestamp"},
	// move_in_timestamp: {displayName: "Move In Timestamp"},
	// email_hash: {displayName: "Email Hash"},
	// email_address: {displayName: "Email Address"},
	// organization_uids_with_read_permission: {displayName: "Organization UIDs With Read Permission"},
	// previous_voyager_resident_status: {displayName: "Previous Voyager Resident Status"},
	// creation_source: {displayName: "Creation Source"},
	// last_voyager_resident_status_check_timestamp: {displayName: "Last Voyager Resident Status Check Timestamp"},
	// transferred_to_deposify_deposit_uid: {displayName: "Transferred To Deposify Deposit UID"},
	// version: {displayName: "Version"},
	// voyager_property_uid: {displayName: "Voyager Property UID"},
	// flex_deposit_intent_uid: {displayName: "Flex Deposit Intent UID"},
	// last_drop_from_status_list_timestamp: {displayName: "Last Drop From Status List Timestamp"},
	// deposify_deposit_uid: {displayName: "Deposify Deposit UID"},
	// lease_end_timestamp: {displayName: "Lease End Timestamp"},
	// account_uids_with_read_permission: {displayName: "Account UIDs With Read Permission"},
	// created_timestamp: {displayName: "Created Timestamp"},
	// voyager_property_unit_uid: {displayName: "Voyager Property Unit UID"},
	// create_deposit_intent_is_for_roommate_promotion: {displayName: "Create Deposit Intent Is For Roommate Promotion"},
	// deposify_tenant_account_uid: {displayName: "Deposify Tenant Account UID"},
	// last_voyager_pet_uid: {displayName: "Last Voyager Pet UID"},
	// has_other_occupants: {displayName: "Has Other Occupants"}
};

export const YARDI_VOYAGER_RESIDENT_CUSTOM_COLUMN_TABLE_RENDER_DEF = {
	column_type: {displayName: "Column Type"},
	custom_column_uid: {displayName: "Custom Column UID"},
	version: {displayName: "Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	column_name: {displayName: "Column Name"},
	current_column_value: {displayName: "Current Column Value"},
	deposify_tags: {displayName: "Deposify Tags"},
	voyager_resident_uid: {displayName: "Voyager Resident UID"},
	notes_uid: {displayName: "Notes UID"},
	last_update_failure_timestamp: {displayName: "Last Update Failure Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	update_failure_count: {displayName: "Update Failure Count"}
};

export const YARDI_VOYAGER_SYSTEM_SETTINGS_TABLE_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	settings_uid: {displayName: "Settings UID"},
	notes_uid: {displayName: "Notes UID"},
	voyager_enabled: {displayName: "Voyager Enabled"},
	created_timestamp: {displayName: "Created Timestamp"}
};

export const YARDI_VOYAGER_TRANSACTION_TABLE_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	intent_uid: {displayName: "Intent UID"},
	notes_uid: {displayName: "Notes UID"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	charge_comments: {displayName: "Charge Comments"},
	voyager_transaction_uid: {displayName: "Voyager Transaction UID"},
	voyager_xml_uid: {displayName: "Voyager Xml UID"},
	charge_amount_in_cents: {displayName: "Charge Amount In Cents"},
	version: {displayName: "Version"},
	voyager_property_uid: {displayName: "Voyager Property UID"},
	charge_code_uid: {displayName: "Charge Code UID"},
	charge_code_string: {displayName: "Charge Code String"},
	created_timestamp: {displayName: "Created Timestamp"},
	voyager_property_unit_uid: {displayName: "Voyager Property Unit UID"},
	state: {displayName: "State"},
	voyager_transaction_code: {displayName: "Voyager Transaction Code"},
	admin_error_info: {displayName: "Admin Error Info"},
	intent_type: {displayName: "Intent Type"},
	voyager_resident_uid: {displayName: "Voyager Resident UID"}
};

export const YARDI_VOYAGER_XML_TABLE_RENDER_DEF = {
	version: {displayName: "Version"},
	voyager_property_uid: {displayName: "Voyager Property UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	process_after_timestamp: {displayName: "Process After Timestamp"},
	admin_error_message: {displayName: "Admin Error Message"},
	aws_file_path: {displayName: "Aws File Path"},
	notes_uid: {displayName: "Notes UID"},
	voyager_xml_uid: {displayName: "Voyager Xml UID"},
	deposify_pmc_account_uid: {displayName: "Deposify Pmc Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	voyager_property_unit_uid: {displayName: "Voyager Property Unit UID"},
	state: {displayName: "State"},
	aws_bucket: {displayName: "Aws Bucket"}
};

