/*

 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import AdminAPI from "../../../api/adminAPI";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faReceipt} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {DEPOSIT_STATE_ACTIVE} from "../../../autoGenerated/constants/services/DepositsServiceConstants";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";


class DownloadDepositReceiptLink extends React.Component {

    constructor(props) {
        super(props);
    }

    downloadDepositReceipt = () => {
        AdminAPI.GenerateDepositReceipt(this.props.depositUID)
            .then(function (responseData) {
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                // file_data is base64 encoded
                const blob = new Blob([atob(responseData.file_data)], {type: "application/pdf"});
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'Deposit Receipt.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }

    render() {
        return (
            <>
                <Typography variant={"body2"} gutterBottom>
                    This only downloads to receipt for you, it does not alter the receipt that's attached to the deposit.
                    <br/>
                    Generation can fail depending on bank settings, deposit settings, or PMC settings.
                </Typography>

                <br/>

                <Tooltip
                    title={this.props.depositState === DEPOSIT_STATE_ACTIVE ? 'Generate Receipt' : 'Receipt generation only available on active deposits'}
                    placement={'top'}
                >
                    <Button
                        variant="outlined"
                        onClick={this.downloadDepositReceipt}
                        disabled={this.props.depositState !== DEPOSIT_STATE_ACTIVE}
                    >
                        <FontAwesomeIcon
                            icon={faReceipt}
                            fixedWidth
                        />
                        &nbsp;Download Receipt
                    </Button>
                </Tooltip>
            </>
        )
    }

}

export default DownloadDepositReceiptLink;
