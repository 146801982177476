import {
    LOADING_INDICATOR_STATE_ERROR,
    LOADING_INDICATOR_STATE_LOADED,
    LOADING_INDICATOR_STATE_LOADING
} from "../../constants/loadingConstants";
import {axiosInstance} from "../../utilities/axiosInstance";
import * as Sentry from "@sentry/browser";
import {loadingActionSetLoadingIndicator} from "../../actions/loadingActions";
import {RESOURCE_TYPE_INFO} from "../../autoGenerated/constants/resourceTypeInfo";
import {createCommonResourceURL} from "../utilities/resourceUtilities";
import {RESOURCE_URL_PREFIX} from "../../constants/apiConstants";
import {resourceActionUpdateResources} from "./resourceActions";


export function loadingActionLoadResourceFromServer(resourceType,
                                                    resourceUID,
                                                    reduxAction) {
    return function (dispatch) {
        // Set the loading state
        dispatch(loadingActionSetLoadingIndicator(resourceUID, resourceType, LOADING_INDICATOR_STATE_LOADING, undefined));

        const resourceTypeInfo = RESOURCE_TYPE_INFO[resourceType];
        const loadURL = createCommonResourceURL(resourceType, resourceUID);

        // Make the call
        return axiosInstance.get(loadURL)
            .then(function (response) {

                dispatch(reduxAction(response.data[resourceTypeInfo.dataObjectName], resourceType));

                // Update the loading indicator
                //dispatch(setLoadingIndicatorState(resourceUID, resourceType, LOADING_INDICATOR_STATE_LOADED));
                dispatch(loadingActionSetLoadingIndicator(resourceUID, resourceType, LOADING_INDICATOR_STATE_LOADED, undefined));
            })
            .catch(function (error) {
                //handleLoadingIndicatorError(resourceUID, resourceType, error, dispatch);
                // Tell Sentry about the error
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    scope.setExtra("resourceUID", resourceUID);
                    scope.setExtra("resourceType", resourceType);
                    Sentry.captureException(error);
                });

                // Set the loading state error for this deposit
                if (error.response) {
                    dispatch(loadingActionSetLoadingIndicator(resourceUID, resourceType, LOADING_INDICATOR_STATE_ERROR, error.response));
                } else {
                    dispatch(loadingActionSetLoadingIndicator(resourceUID, resourceType, LOADING_INDICATOR_STATE_ERROR, error));
                }
            })
    }
}


export function loadingActionLoadMultipleResourceFromServer(resourceType,
                                                            offset ,
                                                            limit,
                                                            orderColumnName = 'None',
                                                            getDescending = true,
                                                            keepReading = true,
                                                            replaceAll = true) {
    return function (dispatch) {

        const resourceTypeInfo = RESOURCE_TYPE_INFO[resourceType];

        // <string:resource_type_string>/multiple/offset/<flask_int:offset>/limit/<flask_int:limit>/order/<string:order_column_name>/descending/<bool:get_descending>
        const loadURL = `/api/${RESOURCE_URL_PREFIX}/resources/resource/${resourceType}/multiple/offset/${offset}/limit/${limit}/order/${orderColumnName}/descending/${getDescending}`;

        // Make the call
        return axiosInstance.get(loadURL)
            .then(function (response) {

                const objectsList = response.data[resourceTypeInfo.dataObjectMultipleName];
                dispatch(resourceActionUpdateResources(resourceType, objectsList, resourceTypeInfo.uniqueIDName, replaceAll));

                if(keepReading === true && objectsList.length >= limit){
                    dispatch(loadingActionLoadMultipleResourceFromServer(resourceType, offset + limit, limit, orderColumnName, getDescending, keepReading, false));
                }

            })
            .catch(function (error) {
                // Log the error
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    scope.setExtra("resourceType", resourceType);
                    scope.setExtra("offset", offset);
                    scope.setExtra("limit", limit);
                    scope.setExtra("orderColumnName", orderColumnName);
                    scope.setExtra("getDescending", getDescending);
                    scope.setExtra("keepReading", keepReading);
                    scope.setExtra("replaceAll", replaceAll);
                    Sentry.captureException(error);
                });

            })
    }
}