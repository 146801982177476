// Name: Deposits Body Render Definitions 

export const PMC_REPORT_TIMETABLE_BODY_RENDER_DEF = {
    landlord_account_uid_list: {displayName: "Landlord Account UID List"},
    notes_uid: {displayName: "Notes UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    recipient_account_uid: {displayName: "Recipient Account UID"},
    deposify_tags: {displayName: "Deposify Tags"},
    timetable_uid: {displayName: "Timetable UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const ARCHIVED_DEPOSIT_CLOSE_REQUEST_BODY_RENDER_DEF = {
    deposit_uid: {displayName: "Deposit UID"},
    state: {displayName: "State"},
    notes: {displayName: "Notes"},
    triggering_account_type: {displayName: "Triggering Account Type"},
    created_timestamp: {displayName: "Created Timestamp"},
    response_reason: {displayName: "Response Reason"},
    triggering_account_uid: {displayName: "Triggering Account UID"},
    archive_uid: {displayName: "Archive UID"},
    attached_files_uid: {displayName: "Attached Files UID"},
    deposify_tags: {displayName: "Deposify Tags"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    amount_in_cents_to_landlord: {displayName: "Amount In Cents To Landlord"},
    reason_for_close: {displayName: "Reason For Close"},
    amount_in_cents_to_tenant: {displayName: "Amount In Cents To Tenant"}
};

export const COMPLIANCE_BODY_RENDER_DEF = {
    compliance_uid: {displayName: "Compliance UID"},
    id: {displayName: "Id"},
    rule_ids: {displayName: "Rule Ids"},
    name: {displayName: "Name"},
    action_type: {displayName: "Action Type"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    created_timestamp: {displayName: "Created Timestamp"},
    handled_by_dedicated_function: {displayName: "Handled By Dedicated Function"},
    type: {displayName: "Type"},
    description: {displayName: "Description"},
    disabled: {displayName: "Disabled"},
    action_payload: {displayName: "Action Payload"}
};

export const COMPLIANCE_HISTORY_BODY_RENDER_DEF = {
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    compliance_history_uid: {displayName: "Compliance History UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    compliance_id: {displayName: "Compliance Id"},
    deposit_uid: {displayName: "Deposit UID"}
};

export const COMPLIANCE_RULE_BODY_RENDER_DEF = {
    id: {displayName: "Id"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    compliance_rule_uid: {displayName: "Compliance Rule UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    type: {displayName: "Type"},
    value_operator: {displayName: "Value Operator"},
    value: {displayName: "Value"}
};

export const DEPOSIT_BODY_RENDER_DEF = {
    title: {displayName: "Title"},
    deposit_uid: {displayName: "Deposit UID"},
    state: {displayName: "State"},
    geo_state: {displayName: "Geo State"},
    amount_in_cents: {displayName: "Amount In Cents"},
    payment_cleared: {displayName: "Payment Cleared"},
    interest_enabled: {displayName: "Interest Enabled"},
    created_by_account_uid: {displayName: "Created By Account UID"},
    end_date: {displayName: "End Date"},
    // start_date: {displayName: "Start Date"},

    landlord_account_uid: {displayName: "Landlord Account UID"},
    landlord_has_accepted: {displayName: "Landlord Has Accepted"},
    landlord_accepted_timestamp: {displayName: "Landlord Accepted Timestamp"},
    outstanding_landlord_prompts: {displayName: "Outstanding Landlord Prompts"},
    landlord_archived: {displayName: "Landlord Archived"},
    end_amount_in_cents_to_landlord: {displayName: "End Amount In Cents To Landlord"},


    tenant_account_uid: {displayName: "Tenant Account UID"},
    tenant_has_accepted: {displayName: "Tenant Has Accepted"},
    tenant_accepted_timestamp: {displayName: "Tenant Accepted Timestamp"},
    outstanding_tenant_prompts: {displayName: "Outstanding Tenant Prompts"},
    tenant_archived: {displayName: "Tenant Archived"},
    end_amount_in_cents_to_tenant: {displayName: "End Amount In Cents To Tenant"},
    tenant_funds_lock_uid: {displayName: "Tenant Funds Lock UID"},

    interest_to_date_in_cents: {displayName: "Interest To Date In Cents"},
    cumulative_interest_in_cents_decimal: {displayName: "Cumulative Interest In Cents Decimal"},
    interest_principal_in_cents_decimal: {displayName: "Interest Principal In Cents Decimal"},
    interest_paid_into_user_account_balance_in_cents: {displayName: "Interest Paid Into User Account Balance In Cents"},
    last_interest_applied_timestamp: {displayName: "Last Interest Applied Timestamp"},

    funds_location: {displayName: "Funds Location"},

    // Dropped because we have a custom component hooked to the funds_location
    // funds_location_uid: {displayName: "Funds Location UID"},

    invoice_count: {displayName: "Invoice Count"},

    mailbox_uid: {displayName: "Mailbox UID"},
    property_uid: {displayName: "Property UID"},
    active_close_request_uid: {displayName: "Active Close Request UID"},
    receipt_file_uid: {displayName: "Receipt File UID"},
    attached_files_uid: {displayName: "Attached Files UID"},
    tenant_invoice_uid: {displayName: "Tenant Invoice UID"},
    dispute_uid: {displayName: "Dispute UID"},
    cover_photo_uid: {displayName: "Cover Photo UID"},

    payment_cleared_timestamp: {displayName: "Payment Cleared Timestamp"},
    became_active_timestamp: {displayName: "Became Active Timestamp"},
    closed_timestamp: {displayName: "Closed Timestamp"},
    cancelled_timestamp: {displayName: "Cancelled Timestamp"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    interest_enabled_timestamp: {displayName: "Interest Enabled Timestamp"},

    has_notes: {displayName: "Has Notes"},
    disabled_compliances: {displayName: "Disabled Compliances"},
    completed_compliances: {displayName: "Completed Compliances"},
    deposify_tags: {displayName: "Deposify Tags"},
    withheld_funds_in_cents: {displayName: "Withheld Funds In Cents"},

    rhino_property_id: {displayName: "Rhino Property ID"},
    rhino_unit_id: {displayName: "Rhino Unit ID"},
    rhino_property_owner_id: {displayName: "Rhino Property Owner ID"},
    rhino_policy_application_id: {displayName: "Rhino Policy Application ID"},

    funds_held_by_partner: {displayName: "Fund Held By Partner"},

    meta_data: {displayName: "MetaData"}
};


export const DEPOSIT_ADJUSTMENT_BODY_RENDER_DEF = {
    amount_in_cents: {displayName: "Amount In Cents"},
    invoice_item_uid: {displayName: "Invoice Item UID"},
    deposit_uid: {displayName: "Deposit UID"},
    adjustment_uid: {displayName: "Adjustment UID"},
    private_notes: {displayName: "Private Notes"},
    adjustment_type: {displayName: "Adjustment Type"},
    created_timestamp: {displayName: "Created Timestamp"},
    public_reason_for_adjustment: {displayName: "Public Reason For Adjustment"},
    invoice_uid: {displayName: "Invoice UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const DEPOSIT_BANK_MAPPING_BODY_RENDER_DEF = {
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    bank_account_uid: {displayName: "Bank Account UID"},
    geo_state: {displayName: "Geo State"},
    created_timestamp: {displayName: "Created Timestamp"}
};

export const DEPOSIT_BULK_IMPORT_BODY_RENDER_DEF = {
    bulk_import_uid: {displayName: "Bulk Import UID"},
    organization_uid: {displayName: "Organization UID"},
    attached_files_uid: {displayName: "Attached Files UID"},
    deposit_uids: {displayName: "Deposit UIDs"},
    created_timestamp: {displayName: "Created Timestamp"},
    notes: {displayName: "Notes"},
    name: {displayName: "Name"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"}
};

export const DEPOSIT_CLOSE_REQUEST_BODY_RENDER_DEF = {
    amount_in_cents_to_tenant: {displayName: "Amount In Cents To Tenant"},
    state: {displayName: "State"},
    response_reason: {displayName: "Response Reason"},
    attached_files_uid: {displayName: "Attached Files UID"},
    deposit_uid: {displayName: "Deposit UID"},
    reason_for_close: {displayName: "Reason For Close"},
    triggering_account_type: {displayName: "Triggering Account Type"},
    notes: {displayName: "Notes"},
    close_request_uid: {displayName: "Close Request UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    amount_in_cents_to_landlord: {displayName: "Amount In Cents To Landlord"},
    triggering_account_uid: {displayName: "Triggering Account UID"}
};

export const DEPOSIT_GROUP_BODY_RENDER_DEF = {
    subtitle: {displayName: "Subtitle"},
    creating_account_uid: {displayName: "Creating Account UID"},
    html_colour_code: {displayName: "Html Colour Code"},
    created_timestamp: {displayName: "Created Timestamp"},
    title: {displayName: "Title"},
    parent_group_uid: {displayName: "Parent Group UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    group_uid: {displayName: "Group UID"}
};

export const DEPOSIT_INTEREST_BODY_RENDER_DEF = {
    amount_in_cents: {displayName: "Amount In Cents"},
    deposit_uid: {displayName: "Deposit UID"},
    interest_uid: {displayName: "Interest UID"},
    bank_account_interest_uid: {displayName: "Bank Account Interest UID"},
    bank_account_uid: {displayName: "Bank Account UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    interest_end_date: {displayName: "Interest End Date"},
    created_timestamp: {displayName: "Created Timestamp"},
    interest_start_date: {displayName: "Interest Start Date"}
};

export const DEPOSIT_INTEREST_LOG_BODY_RENDER_DEF = {
    interest_in_cents_decimal: {displayName: "Interest In Cents Decimal"},
    entry_uid: {displayName: "Entry UID"},
    day_count: {displayName: "Day Count"},
    deposit_uid: {displayName: "Deposit UID"},
    end_date: {displayName: "End Date"},
    principal_in_cents_decimal: {displayName: "Principal In Cents Decimal"},
    bank_account_uid: {displayName: "Bank Account UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    bank_account_interest_rate_per_annum: {displayName: "Bank Account Interest Rate Per Annum"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    start_date: {displayName: "Start Date"}
};

export const DEPOSIT_INVITE_BODY_RENDER_DEF = {
    first_text_timestamp: {displayName: "First Text Timestamp"},
    email_address: {displayName: "Email Address"},
    first_email_timestamp: {displayName: "First Email Timestamp"},
    deposit_uid: {displayName: "Deposit UID"},
    use_phone_number: {displayName: "Use Phone Number"},
    last_email_timestamp: {displayName: "Last Email Timestamp"},
    invitee_account_type: {displayName: "Invitee Account Type"},
    last_text_timestamp: {displayName: "Last Text Timestamp"},
    short_code: {displayName: "Short Code"},
    inviting_account_uid: {displayName: "Inviting Account UID"},
    phone_number: {displayName: "Phone Number"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    use_email_address: {displayName: "Use Email Address"},
    email_hash: {displayName: "Email Hash"},
    deposify_tags: {displayName: "Deposify Tags"}
};

export const DEPOSIT_META_DATA_BODY_RENDER_DEF = {
    photo_file_uids: {displayName: "Photo File UIDs"},
    state: {displayName: "State"},
    deposit_uid: {displayName: "Deposit UID"},
    zip_code: {displayName: "Zip Code"},
    created_timestamp: {displayName: "Created Timestamp"},
    city: {displayName: "City"},
    tenant_last_name: {displayName: "Tenant Last Name"},
    street_address: {displayName: "Street Address"},
    tenant_first_name: {displayName: "Tenant First Name"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    unit_number: {displayName: "Unit Number"}
};

export const DEPOSIT_USER_GROUP_BODY_RENDER_DEF = {
    group_uids: {displayName: "Group UIDs"},
    deposit_uid: {displayName: "Deposit UID"},
    account_uid: {displayName: "Account UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const DEPOSIT_USER_STATS_BODY_RENDER_DEF = {
    account_uid: {displayName: "Account UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    stats: {displayName: "Stats"}
};

export const DISPUTE_BODY_RENDER_DEF = {
    state: {displayName: "State"},
    active_dispute_split_uid: {displayName: "Active Dispute Split UID"},
    attached_files_uid: {displayName: "Attached Files UID"},
    deposit_uid: {displayName: "Deposit UID"},
    first_dispute_split_uid: {displayName: "First Dispute Split UID"},
    triggering_account_type: {displayName: "Triggering Account Type"},
    reason_for_dispute: {displayName: "Reason For Dispute"},
    notes: {displayName: "Notes"},
    dispute_uid: {displayName: "Dispute UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    triggering_account_uid: {displayName: "Triggering Account UID"}
};

export const DISPUTE_INTERVENTION_REQUEST_BODY_RENDER_DEF = {
    reason_for_request: {displayName: "Reason For Request"},
    state: {displayName: "State"},
    attached_files_uid: {displayName: "Attached Files UID"},
    request_uid: {displayName: "Request UID"},
    triggering_account_type: {displayName: "Triggering Account Type"},
    notes: {displayName: "Notes"},
    created_timestamp: {displayName: "Created Timestamp"},
    dispute_uid: {displayName: "Dispute UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    triggering_account_uid: {displayName: "Triggering Account UID"}
};

export const DISPUTE_SPLIT_BODY_RENDER_DEF = {
    state: {displayName: "State"},
    tenant_account_uid: {displayName: "Tenant Account UID"},
    landlord_response: {displayName: "Landlord Response"},
    response_message: {displayName: "Response Message"},
    landlord_account_uid: {displayName: "Landlord Account UID"},
    deposit_uid: {displayName: "Deposit UID"},
    tenant_response: {displayName: "Tenant Response"},
    landlord_response_timestamp: {displayName: "Landlord Response Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    attached_files_uid: {displayName: "Attached Files UID"},
    tenant_response_timestamp: {displayName: "Tenant Response Timestamp"},
    dispute_uid: {displayName: "Dispute UID"},
    amount_in_cents_to_landlord: {displayName: "Amount In Cents To Landlord"},
    amount_in_cents_to_tenant: {displayName: "Amount In Cents To Tenant"},
    proposing_account_type: {displayName: "Proposing Account Type"},
    split_message: {displayName: "Split Message"},
    split_uid: {displayName: "Split UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    proposing_account_uid: {displayName: "Proposing Account UID"}
};

export const PROPERTY_BODY_RENDER_DEF = {
    creating_account_uid: {displayName: "Creating Account UID"},
    // old_md_list: {displayName: "Old Md List"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    property_uid: {displayName: "Property UID"},
    property_type: {displayName: "Property Type"},
    property_address_uid: {displayName: "Property Address UID"},
    title: {displayName: "Title"},
    unit_apt_number: {displayName: "Unit Apt Number"},
    created_timestamp: {displayName: "Created Timestamp"},
    // old_md: {displayName: "Old Md"},
    parent_property_uid: {displayName: "Parent Property UID"},
    deposify_tags: {displayName: "Deposify Tags"},

    partner_bank_account_uid: {displayName: "Partner Bank Account"},
    partner_bank_account_disabled: {displayName: "Partner Bank Account Disabled"}
};

export const PROPERTY_ADDRESS_BODY_RENDER_DEF = {
    state: {displayName: "State"},
    location_id: {displayName: "Location Id"},
    longitude: {displayName: "Longitude"},
    street: {displayName: "Street"},
    house_number: {displayName: "House Number"},
    version: {displayName: "Version"},
    county: {displayName: "County"},
    location_id_type: {displayName: "Location Id Type"},
    district: {displayName: "District"},
    city: {displayName: "City"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    creating_account_uid: {displayName: "Creating Account UID"},
    postal_code: {displayName: "Postal Code"},
    property_address_uid: {displayName: "Property Address UID"},
    country: {displayName: "Country"},
    latitude: {displayName: "Latitude"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    location_match_level: {displayName: "Location Match Level"}
};

export const PROPERTY_GROUP_BODY_RENDER_DEF = {
    subtitle: {displayName: "Subtitle"},
    creating_account_uid: {displayName: "Creating Account UID"},
    html_colour_code: {displayName: "Html Colour Code"},
    created_timestamp: {displayName: "Created Timestamp"},
    title: {displayName: "Title"},
    parent_group_uid: {displayName: "Parent Group UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    group_uid: {displayName: "Group UID"}
};

export const PROPERTY_USER_GROUP_BODY_RENDER_DEF = {
    group_uids: {displayName: "Group UIDs"},
    account_uid: {displayName: "Account UID"},
    property_uid: {displayName: "Property UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const DEPOSIT_WITHHELD_FUNDS_BODY_RENDER_DEF = {
    notes_uid: {displayName: "Notes UID"},
    deposit_uid: {displayName: "Deposit UID"},
    cancelled_timestamp: {displayName: "Cancelled Timestamp"},
    state: {displayName: "State"},
    title: {displayName: "Title"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    attached_files_uid: {displayName: "Attached Files UID"},
    amount_in_cents: {displayName: "Amount In Cents"},
    created_by_account_uid: {displayName: "Created By Account UID"},
    withheld_uid: {displayName: "Withheld UID"},
    description: {displayName: "Description"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const YARDI_DEPOSIT_XML_BODY_RENDER_DEF = {
    created_deposit_group_uid: {displayName: "Created Deposit Group UID"},
    created_deposit_uids: {displayName: "Created Deposit UIDs"},
    created_tenant_account_uids: {displayName: "Created Tenant Account UIDs"},
    created_pmc_account_uid: {displayName: "Created Pmc Account UID"},
    notes_uid: {displayName: "Notes UID"},
    admin_error_message: {displayName: "Admin Error Message"},
    created_timestamp: {displayName: "Created Timestamp"},
    processing_completed_timestamp: {displayName: "Processing Completed Timestamp"},
    xml_uid: {displayName: "Xml UID"},
    permanent_link_uid: {displayName: "Permanent Link UID"},
    deposify_tags: {displayName: "Deposify Tags"},
    state: {displayName: "State"},
    pmc_email_hashes: {displayName: "Pmc Email Hashes"},
    xml_data: {displayName: "Xml Data"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const DEPOSIT_POST_CLOSE_ADJUSTMENT_BODY_RENDER_DEF = {
    target_account_uid: {displayName: "Target Account UID"},
    public_reason_for_adjustment: {displayName: "Public Reason For Adjustment"},
    use_recipient_account: {displayName: "Use Recipient Account"},
    adjustment_uid: {displayName: "Adjustment UID"},
    goal_amount_in_cents: {displayName: "Goal Amount In Cents"},
    state: {displayName: "State"},
    target_account_type: {displayName: "Target Account Type"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    private_notes: {displayName: "Private Notes"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    deposit_uid: {displayName: "Deposit UID"},
    actual_amount_in_cents: {displayName: "Actual Amount In Cents"},
    notes_uid: {displayName: "Notes UID"},
    recipient_account_uid: {displayName: "Recipient Account UID"}
};