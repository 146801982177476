/*
    App Actions types
 */

export const APP_EVENT_TYPE_SET_APP_IS_STARTING = 'APP:SET_APP_IS_STARTING';
export const APP_EVENT_TYPE_ACCOUNT_LOGGED_IN = 'APP:ACCOUNT_LOGGED_IN';
export const APP_EVENT_TYPE_ACCOUNT_LOGGED_OUT = 'APP:ACCOUNT_LOGGED_OUT';
export const APP_EVENT_TYPE_CHANGE_PRIMARY_NAV_TAB = 'APP:BUTLER_TAB_CHANGE';

export const APP_EVENT_SET_SHOW_DEPOSIT_DIALOG = 'APP:SHOW_DEP_DIALOG';
export const APP_EVENT_CLEAR_SHOW_DEPOSIT_DIALOG = 'APP:CLEAR_SHOW_DEP_DIALOG';
export const APP_EVENT_SHOW_NEW_DEPOSIT_INVITE_DIALOG = 'APP:SHOW_NEW_DEPOSIT_INVITE_DIALOG';
export const APP_EVENT_CLEAR_SHOW_NEW_DEPOSIT_INVITE_DIALOG = 'APP:CLEAR_SHOW_NEW_DEPOSIT_INVITE_DIALOG';

export const APP_EVENT_SET_DEPOSIT_SEARCH_RESULTS = 'APP:SET_DEPOSIT_SEARCH_RESULTS';
export const APP_EVENT_SET_DEPOSIT_LISTING_MODE = 'APP:SET_DEPOSIT_LISTING_MODE';
export const APP_EVENT_SET_SEARCH_QUERY_INFO = 'APP:SET_SEARCH_QUERY_INFO';
export const APP_EVENT_SET_DEPOSIT_SEARCH_RES_ANIM = 'APP:SET_DEPOSIT_SEARCH_RES_ANIM';
export const APP_EVENT_SET_SELECTED_DEPOSIT_UIDS = 'APP:SET_SELECTED_DEPOSIT_UIDS';
export const APP_EVENT_ADD_SELECTED_DEPOSIT_UID = 'APP:ADD_SELECTED_DEPOSIT_UID';
export const APP_EVENT_REMOVE_SELECTED_DEPOSIT_UID = 'APP:REMOVE_SELECTED_DEPOSIT_UID';
export const APP_EVENT_SET_EXPANDED_DEPOSIT_UIDS = 'APP:SET_EXPANDED_DEPOSIT_UIDS';
export const APP_EVENT_ADD_EXPANDED_DEPOSIT_UID = 'APP:ADD_EXPANDED_DEPOSIT_UID';
export const APP_EVENT_REMOVE_EXPANDED_DEPOSIT_UID = 'APP:REMOVE_EXPANDED_DEPOSIT_UID';

export const APP_EVENT_SET_DEPOSIT_LISTING_SHOW_EXTRA_CONTROLS = 'APP:SET_DEPOSIT_LISTING_SHOW_EXTRA_CONTROLS';
export const APP_EVENT_SET_LAST_UPDATED_DEPOSIT_UID = 'APP:APP_EVENT_SET_LAST_UPDATED_DEPOSIT_UID';
export const APP_EVENT_SET_EDIT_DEPOSIT_UID = 'APP:APP_EVENT_SET_EDIT_DEPOSIT_UID';
export const APP_EVENT_SET_SHOW_MULTI_SELECT_DIALOG = 'APP:APP_EVENT_SET_SHOW_MULTI_SELECT_DIALOG';
export const APP_EVENT_SET_MULTI_SELECT_DIALOG_MODE = 'APP:APP_EVENT_SET_MULTI_SELECT_DIALOG_MODE';
export const APP_EVENT_SET_SHOW_CREATE_DEPOSIT_DIALOG = 'APP:APP_EVENT_SET_SHOW_CREATE_DEPOSIT_DIALOG';
export const APP_EVENT_SET_SHOW_EDIT_DEPOSIT_DIALOG = 'APP:APP_EVENT_SET_SHOW_EDIT_DEPOSIT_DIALOG';
export const APP_EVENT_SET_EDIT_BANK_ACCOUNT_DETAILS = 'APP:APP_EVENT_SET_EDIT_BANK_ACCOUNT_DETAILS';
export const APP_EVENT_SET_WITHDRAW_DIALOG_DETAILS = 'APP:APP_EVENT_SET_WITHDRAW_DIALOG_DETAILS';
export const APP_EVENT_SET_DEPOSIT_INVITE_DIALOG_DETAILS = 'APP:APP_EVENT_SET_DEPOSIT_INVITE_DIALOG_DETAILS';
export const APP_EVENT_SET_CANCEL_DEPOSIT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_CANCEL_DEPOSIT_DIALOG_DETAILS';
export const APP_EVENT_SET_REJECT_DEPOSIT_INVITE_DIALOG_DETAILS = 'APP:APP_EVENT_SET_REJECT_DEPOSIT_INVITE_DIALOG_DETAILS';
export const APP_EVENT_SET_ACCEPT_DEPOSIT_INVITE_DIALOG_DETAILS = 'APP:APP_EVENT_SET_ACCEPT_DEPOSIT_INVITE_DIALOG_DETAILS';
export const APP_EVENT_SET_DEPOSIT_FUNDS_DIALOG_DETAILS = 'APP:APP_EVENT_SET_DEPOSIT_FUNDS_DIALOG_DETAILS';
export const APP_EVENT_SET_ARCHIVE_DEPOSIT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_ARCHIVE_DEPOSIT_DIALOG_DETAILS';
export const APP_EVENT_SET_CLOSE_DEPOSIT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_CLOSE_DEPOSIT_DIALOG_DETAILS';
export const APP_EVENT_SET_DISPUTE_DEPOSIT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_DISPUTE_DEPOSIT_DIALOG_DETAILS';
export const APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_CLOSE_REQUEST_DIALOG_DETAILS = 'APP:APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_CLOSE_REQUEST_DIALOG_DETAILS';
export const APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_DISPUTE_SPLIT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_DISPUTE_SPLIT_DIALOG_DETAILS';
export const APP_EVENT_SET_PROPOSE_DISPUTE_SPLIT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_PROPOSE_DISPUTE_SPLIT_DIALOG_DETAILS';
export const APP_EVENT_SET_DEPOSIT_LISTING_HOVER_DEPOSIT = 'APP:APP_EVENT_SET_DEPOSIT_LISTING_HOVER_DEPOSIT';
export const APP_EVENT_SET_FULLSCREEN_DEPOSIT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_FULLSCREEN_DEPOSIT_DIALOG_DETAILS';
export const APP_EVENT_HANDLE_INVOICE_UPDATE = 'APP:HANDLE_INVOICE_UPDATE';
export const APP_EVENT_ADD_NOTIFICATION = 'APP:ADD_NOTIFICATION';
export const APP_EVENT_REMOVE_NOTIFICATION = 'APP:REMOVE_NOTIFICATION';
export const APP_EVENT_SET_SHOW_MOBILE_SEARCH_CONTROLS = 'APP:SET_SHOW_MOBILE_SEARCH_CONTROLS';
export const APP_EVENT_SET_CREATE_OR_EDIT_DEPOSIT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_CREATE_OR_EDIT_DEPOSIT_DIALOG_DETAILS';
export const APP_EVENT_SET_CREATE_OR_EDIT_WITHDRAWAL_DIALOG_DETAILS = 'APP:SET_CREATE_OR_EDIT_WITHDRAWAL_DIALOG_DETAILS';
export const APP_EVENT_SET_CREATE_OR_EDIT_US_DOMESTIC_BANK_ACCOUNT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_CREATE_OR_EDIT_US_DOMESTIC_BANK_ACCOUNT_DIALOG_DETAILS';
export const APP_EVENT_SET_CREATE_OR_EDIT_INTERNATIONAL_BANK_ACCOUNT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_CREATE_OR_EDIT_INTERNATIONAL_BANK_ACCOUNT_DIALOG_DETAILS';
export const APP_EVENT_SET_CREATE_BANK_ACCOUNT_SELECTION_DIALOG_DETAILS = 'APP:APP_EVENT_SET_CREATE_BANK_ACCOUNT_SELECTION_DIALOG_DETAILS';
export const APP_EVENT_SET_CANCEL_WITHDRAWAL_DIALOG_DETAILS = 'APP:APP_EVENT_SET_CANCEL_WITHDRAWAL_DIALOG_DETAILS';
export const APP_EVENT_SET_NEW_DEPOSIT_INVITE_DIALOG_DETAILS = 'APP:APP_EVENT_SET_NEW_DEPOSIT_INVITE_DIALOG_DETAILS';
export const APP_EVENT_SET_MIGRATED_ACCOUNTS = 'APP:APP_EVENT_SET_MIGRATED_ACCOUNTS';
export const APP_EVENT_SET_FULL_SCREEN_ACCOUNT_DEFAULT = 'APP:APP_EVENT_SET_FULL_SCREEN_ACCOUNT_DEFAULT';
export const APP_EVENT_SET_FULL_SCREEN_PROPERTY_DIALOG_DETAILS = 'APP:APP_EVENT_SET_FULL_SCREEN_PROPERTY_DIALOG_DETAILS';
export const APP_EVENT_SET_FULL_SCREEN_US_DOM_BANK_DIALOG_DETAILS = 'APP:APP_EVENT_SET_FULL_SCREEN_US_DOM_BANK_DIALOG_DETAILS';
export const APP_EVENT_SET_FULL_SCREEN_INT_BANK_DIALOG_DETAILS = 'APP:APP_EVENT_SET_FULL_SCREEN_INT_BANK_DIALOG_DETAILS';
export const APP_EVENT_SET_FULL_SCREEN_DEPOSIT_DIALOG_DETAILS = 'APP:APP_EVENT_SET_FULL_SCREEN_DEPOSIT_DIALOG_DETAILS';
export const APP_EVENT_SET_FULL_SCREEN_ATTACHED_FILES_DIALOG_DETAILS = 'APP:APP_EVENT_SET_FULL_SCREEN_ATTACHED_FILES_DIALOG_DETAILS';
