/*
    Dev
 */

import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import TablePagination from '@material-ui/core/TablePagination';
import IconButton from "@material-ui/core/IconButton";
import {DEFAULT_ITEMS_PER_PAGE} from "../../../constants/apiConstants";
import NoDataDisplay from "../../../components/utilities/displayAreas/NoDataDisplay";
import {faSadTear} from "@fortawesome/pro-solid-svg-icons";
import GenericTableHeaders from "./GenericTableHeaders";
import GenericTableRow from "./GenericTableRow";
import {RESOURCE_TYPE_INFO} from "../../../autoGenerated/constants/resourceTypeInfo";

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5)
    },
}));

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
        color: '#bfbfbf'
    },
    whiteTable: {
        color: '#bfbfbf'
    }
});

// TODO: Move this out
function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    );
}


function GenericTable({incomingUIDList, tableRenderDefinition, resourceType, loadingContainer, jsonSchema, customRenderComponents = {}, ...props}) {
    const classes = useStyles();

    const [pageIndex, setPageIndex] = React.useState(0);

    const startIndex = pageIndex * DEFAULT_ITEMS_PER_PAGE;
    const endIndex = startIndex + DEFAULT_ITEMS_PER_PAGE;

    const resourceTypeInfo = RESOURCE_TYPE_INFO[resourceType];

    return (
        <React.Fragment>
            {renderPaginationControls()}

            <Table stickyHeader className={classes.table} size="small" aria-label="a dense table" key={'acc-bal-log'}>
                <TableHead>
                    <GenericTableHeaders tableRenderDef={tableRenderDefinition}/>
                </TableHead>
                <TableBody>
                    {
                        incomingUIDList.slice(startIndex, endIndex).map(activeUID => {
                            return (
                                <TableRow key={activeUID}>
                                    <GenericTableRow
                                        ContainerComponent={loadingContainer}
                                        resourceUID={activeUID}
                                        resourceUIDName={resourceTypeInfo.clientUIDName}
                                        loadedObjectName={resourceTypeInfo.clientObjectName}
                                        tableRenderDefinition={tableRenderDefinition}
                                        jsonSchema={jsonSchema}
                                        customRenderComponents={customRenderComponents}
                                    />
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>

            {
                incomingUIDList.length === 0 &&
                <NoDataDisplay icon={faSadTear} message={'No data'}/>
            }

            {renderPaginationControls()}
        </React.Fragment>
    );

    function handleChangePage(event, newPage) {
        setPageIndex(newPage);
    }

    function renderPaginationControls() {
        return (
            <TablePagination
                rowsPerPageOptions={[]} // Disable rows per page option
                colSpan={3}
                count={incomingUIDList.length}
                rowsPerPage={DEFAULT_ITEMS_PER_PAGE}
                page={pageIndex}
                SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                }}
                onChangePage={handleChangePage}
                //onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                component={"div"}
            />
        );
    }

}

// Very important we memorize this function - since the loading containers will cause updates
export default React.memo(GenericTable);
