/*
    Loading Actions
 */

import {SET_LOADING_INDICATOR} from "./loadingActionConstants";

// Events
export function loadingActionSetLoadingIndicator(resourceUID, resourceType, newState, response) {
    return {
        type: SET_LOADING_INDICATOR,
        resourceUID: resourceUID,
        resourceType: resourceType,
        newState: newState,
        response: response
    }
}
