/*

 */

import * as Sentry from '@sentry/browser';
import {axiosInstance} from '../utilities/axiosInstance'
import {
    API_ROUTE_READ_CURRENT_USER_ACCOUNT,
    API_ROUTE_READ_CURRENT_USER_ACCOUNT_BALANCE,
    API_ROUTE_READ_ALL_INTERNATIONAL_USER_BANK_ACCOUNTS,
    API_ROUTE_READ_ALL_US_DOMESTIC_USER_BANK_ACCOUNTS,
    API_ROUTE_READ_BASIC_USER_ACCOUNT_INFO,
    API_ROUTE_READ_USER_ACCOUNT_SETTINGS
} from "../constants/apiRouteConstants";

import {
    ADD_NEW_INTERNATIONAL_USER_BANK_ACCOUNT,
    ADD_NEW_US_DOMESTIC_USER_BANK_ACCOUNT,
    UPDATE_BASIC_USER_INFO,
    UPDATE_INTERNATIONAL_BANK_ACCOUNTS,
    UPDATE_PARTIAL_USER_ACCOUNT_SETTINGS,
    UPDATE_US_DOMESTIC_BANK_ACCOUNTS,
    UPDATE_USER,
    UPDATE_USER_ACCOUNT_BALANCE,
    UPDATE_USER_ACCOUNT_SETTINGS, USER_EVENT_UPDATE_USER_ACCOUNT_BALANCE_LOG,
    USER_EVENT_UPDATE_USER_ACCOUNT_HISTORY,
    USER_SET_PENDING_EMAIL_CHANGE
} from "./userActionTypes";

// TODO: Rename this action
export function updateUser(updated_user) {
    return {
        type: UPDATE_USER,
        user_account: updated_user
    }
}

export function updateUserAccountBalance(ignoredValue, updated_user_account_balance) {
    return {
        type: UPDATE_USER_ACCOUNT_BALANCE,
        user_account_balance: updated_user_account_balance
    }
}

export function updateUserInternationalBankAccounts(bank_accounts) {
    return {
        type: UPDATE_INTERNATIONAL_BANK_ACCOUNTS,
        bank_accounts: bank_accounts
    }
}

export function updateUserUSDomesticBankAccounts(bank_accounts) {
    return {
        type: UPDATE_US_DOMESTIC_BANK_ACCOUNTS,
        bank_accounts: bank_accounts
    }
}

export function addNewUSDomesticBankAccountsAction(new_bank_account) {
    return {
        type: ADD_NEW_US_DOMESTIC_USER_BANK_ACCOUNT,
        new_bank_account: new_bank_account
    }
}

export function addNewInternationalBankAccountsAction(new_bank_account) {
    return {
        type: ADD_NEW_INTERNATIONAL_USER_BANK_ACCOUNT,
        new_bank_account: new_bank_account
    }
}


export function updateBasicUserInfo(accountUID, basicUserInfo) {
    return {
        type: UPDATE_BASIC_USER_INFO,
        accountUID: accountUID,
        basicUserInfo: basicUserInfo
    }
}

export function actionUpdateUserAccountSettings(accountSettings) {
    return {
        type: UPDATE_USER_ACCOUNT_SETTINGS,
        accountSettings: accountSettings
    }
}

export function actionUpdatePartialUserAccountSettings(partialAccountSettings) {
    return {
        type: UPDATE_PARTIAL_USER_ACCOUNT_SETTINGS,
        partialAccountSettings: partialAccountSettings
    }
}

export function userActionSetPendingEmailChange(pendingEmailChange) {
    return {
        type: USER_SET_PENDING_EMAIL_CHANGE,
        pendingEmailChange: pendingEmailChange
    }
}

export function userActionUpdateUserAccountHistory(newEntries) {
    return {
        type: USER_EVENT_UPDATE_USER_ACCOUNT_HISTORY,
        newEntries: newEntries
    }
}

export function userActionUpdateUserAccountBalanceLog(newEntries) {
    return {
        type: USER_EVENT_UPDATE_USER_ACCOUNT_BALANCE_LOG,
        newEntries: newEntries
    }
}

export const readCurrentUserAccountAction = () => {
    return dispatch => {
        _readCurrentUserAccount(dispatch);
    }
};

export function _readCurrentUserAccount(dispatch) {
    return axiosInstance.get(API_ROUTE_READ_CURRENT_USER_ACCOUNT)
        .then(function (response) {
            dispatch(updateUser(response.data.user_account));
            return response.data.user_account;
        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}

export const readCurrentUserAccountBalanceAction = () => {
    return dispatch => {
        readCurrentUserAccountBalance(dispatch);
    }
};

export function readCurrentUserAccountBalance(dispatch) {
    return axiosInstance.get(API_ROUTE_READ_CURRENT_USER_ACCOUNT_BALANCE)
        .then(function (response) {
            dispatch(updateUserAccountBalance(null, response.data.user_account_balance));
        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}

export const readInternationalBankAccountsAction = (offset, limit, recently_updated_first, keep_reading = true) => {
    return dispatch => {
        readInternationalBankAccounts(offset, limit, recently_updated_first, keep_reading, dispatch);
    }
};

export function readInternationalBankAccounts(offset, limit, recently_updated_first, keep_reading, dispatch) {
    const get_url = `${API_ROUTE_READ_ALL_INTERNATIONAL_USER_BANK_ACCOUNTS}/${offset}/${limit}/${recently_updated_first}`;
    return axiosInstance.get(get_url)
        .then(function (response) {
            // Update the bank accounts
            dispatch(updateUserInternationalBankAccounts(response.data.bank_accounts));

            if (keep_reading) {
                if (response.data.bank_accounts.length >= limit) {
                    dispatch(readInternationalBankAccountsAction(
                        offset + limit,
                        limit,
                        recently_updated_first
                    ));
                }
            }
        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}

export const readUSDomesticBankAccountsAction = (offset, limit, recently_updated_first, keep_reading = true) => {
    return dispatch => {
        readUSDomesticBankAccounts(offset, limit, recently_updated_first, keep_reading, dispatch);
    }
};

export function readUSDomesticBankAccounts(offset, limit, recently_updated_first, keep_reading, dispatch) {
    const get_url = `${API_ROUTE_READ_ALL_US_DOMESTIC_USER_BANK_ACCOUNTS}/${offset}/${limit}/${recently_updated_first}`;
    axiosInstance.get(get_url)
        .then(function (response) {
            dispatch(updateUserUSDomesticBankAccounts(response.data.bank_accounts));

            if (keep_reading) {
                if (response.data.bank_accounts.length >= limit) {
                    dispatch(readUSDomesticBankAccountsAction(
                        offset + limit,
                        limit,
                        recently_updated_first
                    ));
                }
            }
        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}

export const readBasicUserInfo = (accountUID) => {
    return dispatch => {
        _readBasicUserInfo(accountUID, dispatch);
    }
};

export function _readBasicUserInfo(accountUID, dispatch) {
    return axiosInstance.get(`${API_ROUTE_READ_BASIC_USER_ACCOUNT_INFO}/${accountUID}`)
        .then(function (response) {
            dispatch(updateBasicUserInfo(accountUID, response.data.basic_account_info));
        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}


export const userActionReadUserAccountSettings = () => {
    return dispatch => {
        readUserAccountSettings(dispatch);
    }
};

export function readUserAccountSettings(dispatch) {
    return axiosInstance.get(API_ROUTE_READ_USER_ACCOUNT_SETTINGS)
        .then(function (response) {
            dispatch(actionUpdateUserAccountSettings(response.data.user_account_settings));
            return response.data.user_account_settings;
        })
        .catch(function (error) {
            Sentry.withScope(scope => {
                scope.setExtra("response", error.response);
                Sentry.captureException(error);
            });
        })
}

export function readSingleUSDomesticBankAccount(bankAccountUID) {
    return dispatch => {
        axiosInstance.get(`api/user/bank/accounts/us/domestic/${bankAccountUID}`)
            .then(function (response) {
                dispatch(updateUserUSDomesticBankAccounts([response.data.bank_account]));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
}

export function readSingleInternationalBankAccount(bankAccountUID) {
    return dispatch => {
        axiosInstance.get(`api/user/bank/accounts/international/${bankAccountUID}`)
            .then(function (response) {
                dispatch(updateUserInternationalBankAccounts([response.data.bank_account]));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
}

export function readAllUserAccountHistory(offset, limit) {
    return dispatch => {
        axiosInstance.get(`api/accounts/history/all/${offset}/${limit}/True`)
            .then(function (response) {
                const historyEntries = response.data.history_entries;
                dispatch(userActionUpdateUserAccountHistory(historyEntries));

                if(historyEntries.length >= limit){
                    dispatch(readAllUserAccountHistory(offset + limit, limit));
                }
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
}

export function readAllAccountBalanceLogs(offset, limit) {
    return dispatch => {
        axiosInstance.get(`api/accounts/balance/change/log/all/${offset}/${limit}/True`)
            .then(function (response) {
                const balanceChangeLogs = response.data.balance_change_logs;
                dispatch(userActionUpdateUserAccountBalanceLog(balanceChangeLogs));

                if(balanceChangeLogs.length >= limit){
                    dispatch(readAllAccountBalanceLogs(offset + limit, limit));
                }
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
}





export const adminLoadUSDomesticBankAccount = (bank_account_uid) => {
    // TODO: Why isn't this using the admin route gen?
    return dispatch => {
        axiosInstance.get(`api/ornate/cabbage/accounts/banks/domestic/us/${bank_account_uid}`)
            .then(function (response) {
                dispatch(updateUserUSDomesticBankAccounts([response.data.bank_account]));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const adminLoadInternationalBankAccount = (bank_account_uid) => {
    // TODO: Why isn't this using the admin route gen?
    return dispatch => {
        axiosInstance.get(`api/ornate/cabbage/accounts/banks/international/${bank_account_uid}`)
            .then(function (response) {
                dispatch(updateUserInternationalBankAccounts([response.data.bank_account]));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};