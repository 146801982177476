// Name: User_Accounts Table Render Definitions 

import {TABLE_COLUMN_ALIGNMENT_LEFT} from "../tableConstants";

export const ADMIN_GHOST_USER_ACCOUNT_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	target_account_uid: {displayName: "Target Account UID"},
	ghost_account_uid: {displayName: "Ghost Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	currently_in_use: {displayName: "Currently In Use"},
	set_by_admin_account_uid: {displayName: "Set By Admin Account UID"}
};

export const ARCHIVED_PENDING_EMAIL_CHANGE_TABLE_RENDER_DEF = {
	sent_email_count: {displayName: "Sent Email Count"},
	created_timestamp: {displayName: "Created Timestamp"},
	pending_email_change_created_timestamp: {displayName: "Pending Email Change Created Timestamp"},
	change_uid: {displayName: "Change UID"},
	email_hash: {displayName: "Email Hash"},
	last_sent_email_timestamp: {displayName: "Last Sent Email Timestamp"},
	email_address: {displayName: "Email Address"},
	pending_email_change_last_updated_timestamp: {displayName: "Pending Email Change Last Updated Timestamp"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const BALANCE_CHANGE_LOG_TABLE_RENDER_DEF = {
	change_log_uid: {displayName: "Change Log UID"},
	account_uid: {displayName: "Account UID"},
	resource_uid: {displayName: "Resource UID"},
	resource_type: {displayName: "Resource Type"},
	// delta_total_balance_in_cents: {displayName: "Delta Total Balance In Cents"},
	new_total_balance_in_cents: {displayName: "New Total Balance"},
	new_free_balance_in_cents: {displayName: "New Free Balance"},
	new_locked_balance_in_cents: {displayName: "New Locked Balance"},
	// previous_total_balance_in_cents: {displayName: "Previous Total Balance In Cents"},
	// delta_free_balance_in_cents: {displayName: "Delta Free Balance In Cents"},
	// entry_type: {displayName: "Entry Type"},
	created_timestamp: {displayName: "Created Timestamp"},
	// delta_locked_balance_in_cents: {displayName: "Delta Locked Balance In Cents"},
	// notes: {displayName: "Notes"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// previous_locked_balance_in_cents: {displayName: "Previous Locked Balance In Cents"},
	// previous_free_balance_in_cents: {displayName: "Previous Free Balance In Cents"}
};

export const BALANCE_SOURCE_TRANSFER_TABLE_RENDER_DEF = {
	transfer_uid: {displayName: "Transfer UID"},
	// bulk_transfer_uid: {displayName: "Bulk Transfer UID"},
	account_uid: {displayName: "Account UID"},
	// created_timestamp: {displayName: "Created Timestamp"},
	amount_in_cents: {displayName: "Amount"},
	funds_location: {displayName: "Funds Location"},
	// funds_location_uid: {displayName: "Funds Location UID"},
	funds_destination: {displayName: "Funds Destination"},
	// funds_destination_uid: {displayName: "Funds Destination UID"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// balance_source_uid: {displayName: "Balance Source UID"}
};

export const CREDIT_USER_ACCOUNT_LOG_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	credit_type: {displayName: "Credit Type"},
	funds_location_uid: {displayName: "Funds Location UID"},
	resource_uid: {displayName: "Resource UID"},
	resource_type: {displayName: "Resource Type"},
	account_uid: {displayName: "Account UID"},
	funds_location: {displayName: "Funds Location"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	amount_in_cents: {displayName: "Amount In Cents"},
	credit_log_uid: {displayName: "Credit Log UID"}
};

export const EMAIL_VERIFICATION_TABLE_RENDER_DEF = {
	verification_uid: {displayName: "Verification UID"},
	account_uid: {displayName: "Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	// token_hash: {displayName: "Token Hash"},
	verification_type: {displayName: "Verification Type"},
	verification_token: {displayName: "Verification Token"},
	last_sent_email_timestamp: {displayName: "Last Sent Email Timestamp"},
	sent_email_count: {displayName: "Sent Email Count"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const EMAIL_VERIFICATION_RECORD_TABLE_RENDER_DEF = {
	sent_email_count: {displayName: "Sent Email Count"},
	token_hash: {displayName: "Token Hash"},
	verification_type: {displayName: "Verification Type"},
	verification_uid: {displayName: "Verification UID"},
	verifying_resource_uid: {displayName: "Verifying Resource UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	account_uid: {displayName: "Account UID"},
	verified_timestamp: {displayName: "Verified Timestamp"},
	verification_token: {displayName: "Verification Token"},
	verifying_resource_type: {displayName: "Verifying Resource Type"},
	record_type: {displayName: "Record Type"}
};

export const EXPIRED_PASSWORD_TABLE_RENDER_DEF = {
	expired_password_uid: {displayName: "Expired Password UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	password_salt: {displayName: "Password Salt"},
	password_hash: {displayName: "Password Hash"},
	password_version: {displayName: "Password Version"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	expired_reason: {displayName: "Expired Reason"}
};

export const FUNDS_LOCK_TABLE_RENDER_DEF = {
	lock_uid: {displayName: "Lock UID"},
	amount_in_cents: {displayName: "Amount"},
	resource_type: {displayName: "Resource Type"},
	resource_uid: {displayName: "Resource UID"},
	account_uid: {displayName: "Account UID"},
	locked_balance_source_list: {displayName: "Locked Balance Source List"},
	created_timestamp: {displayName: "Locked Timestamp"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// deposify_tags: {displayName: "Deposify Tags"}
};

export const FUNDS_LOCK_RELEASE_LOG_TABLE_RENDER_DEF = {
	release_log_uid: {displayName: "Release Log UID"},
	funds_lock_uid: {displayName: "Funds Lock UID"},
	amount_in_cents: {displayName: "Amount In Cents"},
	release_type: {displayName: "Release Type"},
	account_uid: {displayName: "Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const PASSWORD_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	password_salt: {displayName: "Password Salt"},
	password_hash: {displayName: "Password Hash"},
	password_version: {displayName: "Password Version"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const PASSWORD_RESET_TABLE_RENDER_DEF = {
	reset_token: {displayName: "Reset Token"},
	sent_email_count: {displayName: "Sent Email Count"},
	created_timestamp: {displayName: "Created Timestamp"},
	token_hash: {displayName: "Token Hash"},
	reset_reason: {displayName: "Reset Reason"},
	last_sent_email_timestamp: {displayName: "Last Sent Email Timestamp"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	expires_timestamp: {displayName: "Expires Timestamp"},
	reset_uid: {displayName: "Reset UID"}
};

export const PENDING_EMAIL_CHANGE_TABLE_RENDER_DEF = {
	sent_email_count: {displayName: "Sent Email Count"},
	created_timestamp: {displayName: "Created Timestamp"},
	change_uid: {displayName: "Change UID"},
	email_hash: {displayName: "Email Hash"},
	last_sent_email_timestamp: {displayName: "Last Sent Email Timestamp"},
	email_address: {displayName: "Email Address"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const PREVIOUS_EMAIL_ADDRESS_TABLE_RENDER_DEF = {
	previous_email_uid: {displayName: "Previous Email UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	email_hash: {displayName: "Email Hash"},
	email_address: {displayName: "Email Address"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const RELEASED_FUNDS_LOCK_TABLE_RENDER_DEF = {
	lock_uid: {displayName: "Lock UID"},
	amount_in_cents: {displayName: "Amount"},
	resource_type: {displayName: "Resource Type"},
	resource_uid: {displayName: "Resource UID"},
	account_uid: {displayName: "Account UID"},
	locked_balance_source_list: {displayName: "Locked Balance Source List"},
	created_timestamp: {displayName: "Released Timestamp"},
	// funds_locked_last_updated_timestamp: {displayName: "Funds Locked Last Updated Timestamp"},
	// funds_lock_created_timestamp: {displayName: "Funds Lock Created Timestamp"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const USER_ACCOUNT_TABLE_RENDER_DEF = {
	account_uid: {displayName: "Account UID"},
	roles: {displayName: "Roles", columnAlign: TABLE_COLUMN_ALIGNMENT_LEFT},
	state: {displayName: "State"},
    email_address: {displayName: "Email Address"},
    //email_verified: {displayName: "Email Verified"},
    first_name: {displayName: "First Name", columnAlign: TABLE_COLUMN_ALIGNMENT_LEFT},
    last_name: {displayName: "Last Name", columnAlign: TABLE_COLUMN_ALIGNMENT_LEFT}
    //username: {displayName: "Username"},
	//profile_pic_file_uid: {displayName: "Profile Pic File UID"},
	//email_hash: {displayName: "Email Hash"},
	//organization_uids: {displayName: "Organization UIDs"},
	//create_tags: {displayName: "Create Tags"},
	//create_on_behalf_of_organization_uids: {displayName: "Create On Behalf Of Organization UIDs"},
	//last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	//deposify_tags: {displayName: "Deposify Tags"},
	//middle_name: {displayName: "Middle Name"},
	//created_timestamp: {displayName: "Created Timestamp"},
	//name_title: {displayName: "Name Title"},
	//has_pending_email_change: {displayName: "Has Pending Email Change"},
	//default_role: {displayName: "Default Role"}
};

export const USER_ACCOUNT_BALANCE_TABLE_RENDER_DEF = {
    account_uid: {displayName: "Account UID"},
    total_balance_in_cents: {displayName: "Total Balance"},
    free_balance_in_cents: {displayName: "Free Balance"},
	locked_balance_in_cents: {displayName: "Locked Balance"},
    //last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    //created_timestamp: {displayName: "Created Timestamp"},
};

export const USER_ACCOUNT_BALANCE_SOURCE_TABLE_RENDER_DEF = {
	source_uid: {displayName: "Source UID"},
	account_uid: {displayName: "Account UID"},
	amount_in_cents: {displayName: "Amount"},
	balance_type: {displayName: "Balance Type"},
	funds_location: {displayName: "Funds Location"},
	// funds_location_uid: {displayName: "Funds Location UID"},
	// created_timestamp: {displayName: "Created Timestamp"},
	awaiting_balance_source_transfer: {displayName: "Awaiting Balance Source Transfer"},
	balance_source_transfer_uid: {displayName: "Balance Source Transfer UID"}
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
};

export const USER_ACCOUNT_EXTENDED_INFO_TABLE_RENDER_DEF = {
	title: {displayName: "Title"},
	address_zip_code: {displayName: "Address Zip Code"},
	address_country: {displayName: "Address Country"},
	address_locality: {displayName: "Address Locality"},
	account_uid: {displayName: "Account UID"},
	address_organization: {displayName: "Address Organization"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	middle_name: {displayName: "Middle Name"},
	address_line_four: {displayName: "Address Line Four"},
	created_timestamp: {displayName: "Created Timestamp"},
	first_name: {displayName: "First Name"},
	phone_number_verification_timestamp: {displayName: "Phone Number Verification Timestamp"},
	phone_number_verified: {displayName: "Phone Number Verified"},
	phone_number: {displayName: "Phone Number"},
	last_name: {displayName: "Last Name"},
	address_region: {displayName: "Address Region"},
	address_line_one: {displayName: "Address Line One"},
	address_line_two: {displayName: "Address Line Two"},
	address_line_three: {displayName: "Address Line Three"}
};

export const USER_ACCOUNT_META_DATA_TABLE_RENDER_DEF = {
	first_sign_in_completed: {displayName: "First Sign In Completed"},
	first_sign_into_system_timestamp: {displayName: "First Sign Into System Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	account_uid: {displayName: "Account UID"}
};

export const USER_ACCOUNT_SETTINGS_TABLE_RENDER_DEF = {
	allow_self_deposit: {displayName: "Allow Self Deposit"},
	always_show_archived_deposits: {displayName: "Always Show Archived Deposits"},
	show_local_time: {displayName: "Show Local Time"},
	show_extended_create_deposit_form: {displayName: "Show Extended Create Deposit Form"},
	created_timestamp: {displayName: "Created Timestamp"},
	deposit_listing_opens_fullscreen: {displayName: "Deposit Listing Opens Fullscreen"},
	date_display_format: {displayName: "Date Display Format"},
	default_us_state: {displayName: "Default Us State"},
	auto_archive_cancelled_deposits_day_count: {displayName: "Auto Archive Cancelled Deposits Day Count"},
	account_uid: {displayName: "Account UID"},
	auto_archive_closed_deposits_day_count: {displayName: "Auto Archive Closed Deposits Day Count"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	recent_us_states: {displayName: "Recent Us States"},
	auto_archive_cancelled_deposits: {displayName: "Auto Archive Cancelled Deposits"},
	auto_archive_closed_deposits: {displayName: "Auto Archive Closed Deposits"},
	deposit_listing_click_header_to_expand: {displayName: "Deposit Listing Click Header To Expand"}
};

export const USER_BANK_ACCOUNT_DELTA_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	delta_uid: {displayName: "Delta UID"},
	update_delta_type: {displayName: "Update Delta Type"},
	resource_type: {displayName: "Resource Type"},
	resource_uid: {displayName: "Resource UID"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	payload: {displayName: "Payload"}
};

export const US_DOMESTIC_USER_BANK_ACCOUNT_TABLE_RENDER_DEF = {
	bank_account_uid: {displayName: "Bank Account UID"},
	account_type: {displayName: "Account Type"},
	// notes: {displayName: "Notes"},
	first_name_on_account: {displayName: "First Name On Account"},
	last_name_on_account: {displayName: "Last Name On Account"},
	// ach: {displayName: "Ach"},
	// user_deleted: {displayName: "User Deleted"},
	state: {displayName: "State"},
	// account_number: {displayName: "Account Number"},
	bank_name: {displayName: "Bank Name"},
	internal_account_type: {displayName: "Internal Account Type"},
	// contact_phone_number: {displayName: "Contact Phone Number"},
	// account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// deposify_tags: {displayName: "Deposify Tags"},
	// created_timestamp: {displayName: "Created Timestamp"}
};

export const INTERNATIONAL_USER_BANK_ACCOUNT_TABLE_RENDER_DEF = {
	bank_account_uid: {displayName: "Bank Account UID"},
	account_uid: {displayName: "Account UID"},
	first_name_on_account: {displayName: "First Name On Account"},
	last_name_on_account: {displayName: "Last Name On Account"},
	// recipient_postal_code: {displayName: "Recipient Postal Code"},
	// contact_phone_number: {displayName: "Contact Phone Number"},
	// notes: {displayName: "Notes"},
	// bic_swift_code: {displayName: "Bic Swift Code"},
	// deposify_tags: {displayName: "Deposify Tags"},
	account_type: {displayName: "Account Type"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// internal_account_type: {displayName: "Internal Account Type"},
	recipient_address_city: {displayName: "Recipient Address City"},
	// created_timestamp: {displayName: "Created Timestamp"},
	// user_deleted: {displayName: "User Deleted"},
	recipient_address_street: {displayName: "Recipient Address Street"},
	// state: {displayName: "State"},
	recipient_country: {displayName: "Recipient Country"},
	// account_number: {displayName: "Account Number"},
	// bank_name: {displayName: "Bank Name"},
	// recipient_address_state: {displayName: "Recipient Address State"}
};

export const BANK_ACCOUNT_BALANCE_RECORD_TABLE_RENDER_DEF = {
	bank_account_uid: {displayName: "Bank Account UID"},
	recording_account_uid: {displayName: "Recording Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	amount_in_cents: {displayName: "Amount In Cents"},
	notes: {displayName: "Notes"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	record_uid: {displayName: "Record UID"},
	deposify_tags: {displayName: "Deposify Tags"}
};

export const USER_ACCOUNT_FINALIZATION_TABLE_RENDER_DEF = {
	account_uid: {displayName: "Account UID"},
	state: {displayName: "State"},
	last_email_timestamp: {displayName: "Last Email Timestamp"},
	sent_email_count: {displayName: "Sent Email Count"},
	// verification_token_hash: {displayName: "Verification Token Hash"},
	name_needed_for_finalization: {displayName: "Name Needed For Finalization"},
	phone_needed_for_finalization: {displayName: "Phone Needed For Finalization"},
	expires_timestamp: {displayName: "Expires Timestamp"},
	// notes_uid: {displayName: "Notes UID"},
	// created_timestamp: {displayName: "Created Timestamp"},
	// first_email_timestamp: {displayName: "First Email Timestamp"},
	// deposify_tags: {displayName: "Deposify Tags"},
	// verification_token: {displayName: "Verification Token"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const THIRD_PARTY_USER_ACCOUNT_MAPPING_TABLE_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	account_uid: {displayName: "Account UID"},
	notes_uid: {displayName: "Notes UID"},
	created_timestamp: {displayName: "Created Timestamp"}
};