/*
    Admin Reducer
 */

import {
    ADMIN_ADD_SYSTEM_STATS,
    ADMIN_ADD_TO_FULL_SCREEN_HISTORY_CHAIN,
    ADMIN_MOVE_BACK_IN_FULL_SCREEN_HISTORY_CHAIN,
    ADMIN_REMOVE_FILE_AFTER_DELETE,
    ADMIN_SET_FULL_SCREEN_FILE_INFO,
    ADMIN_SET_FULL_SCREEN_HISTORY_CHAIN,
    ADMIN_SET_GENERIC_FULL_SCREEN_DISPLAY,
    ADMIN_SET_GHOST_ACCOUNT_INFO,
    ADMIN_SET_SEARCH_INFO,
    ADMIN_SET_SEARCH_RESULTS,
    ADMIN_SET_STARTUP_LOADING, ADMIN_SET_SYSTEM_STATS
} from "../actions/adminActionTypes";
import {
    ADMIN_FULL_SCREEN_DISPLAY_MODE_DRAWER
} from "../constants/components/componentsConstants";
import {APP_EVENT_CLEAR_ALL} from "../clientShared/actions/appActionTypes";


const initialAdminState = {
    adminStartupLoading: false,
    // Search
    adminSearchInfo: {searchQuery: '', searchIndex: '', showSearchResults: false},
    adminSearchResults: {},
    // Generic full screen display
    fullScreenDisplay: {showDialog: false, resourceUID: undefined, resourceType: undefined, displayMode: ADMIN_FULL_SCREEN_DISPLAY_MODE_DRAWER},
    adminGhostAccountInfo: undefined,
    // Generic full screen display
    fullScreenFileInfo: {showDialog: false, fileUID: undefined},
    fullScreenHistoryChain: [], // Stack,
    systemStats: {}
};


export default function adminReducer(state = initialAdminState, action) {

    switch (action.type) {

        case APP_EVENT_CLEAR_ALL:
            return initialAdminState;


        case ADMIN_SET_STARTUP_LOADING:
            return {...state, ...{adminStartupLoading: action.adminStartupLoading}};

        //
        // Search
        //
        case ADMIN_SET_SEARCH_INFO:
            return {...state, ...{adminSearchInfo: action.adminSearchInfo}};

        case ADMIN_SET_SEARCH_RESULTS:
            return {...state, ...{adminSearchResults: action.adminSearchResults}};


        //
        // GENERIC FULL SCREEN DISPLAY
        //
        case ADMIN_SET_GENERIC_FULL_SCREEN_DISPLAY:
            return {...state, ...{fullScreenDisplay: action.fullScreenDisplay}};

        //
        // GHOST ACCOUNT INFO
        //
        case ADMIN_SET_GHOST_ACCOUNT_INFO:
            const finalDict = {...action.adminGhostAccountInfo, lastRefreshedTimestamp: new Date()};
            return {...state, adminGhostAccountInfo: finalDict};

        case ADMIN_SET_FULL_SCREEN_FILE_INFO:
            return {...state, fullScreenFileInfo: action.fullScreenFileInfo};

        //
        // ADMIN_REMOVE_FILE_AFTER_DELETE
        //
        case ADMIN_REMOVE_FILE_AFTER_DELETE:

            let delStorageFiles = {...state.allStorageFiles};
            delete delStorageFiles[action.fileUID];
            let delStorageFileUIDs = [...state.allStorageFilesUIDs];
            const removeIndex = delStorageFileUIDs.indexOf(action.fileUID);
            if (removeIndex > -1) {
                delStorageFileUIDs.splice(removeIndex, 1);
            }

            return {
                ...state,
                allStorageFiles: delStorageFiles,
                allStorageFilesUIDs: delStorageFileUIDs
            };

        //
        // HISTORY CHAIN
        //
        case ADMIN_SET_FULL_SCREEN_HISTORY_CHAIN:
            return {...state, fullScreenHistoryChain: action.fullScreenHistoryChain};

        case ADMIN_ADD_TO_FULL_SCREEN_HISTORY_CHAIN:
            let currentFullScreenHistoryChainAdd = [...state.fullScreenHistoryChain];
            currentFullScreenHistoryChainAdd.push({resourceUID: action.resourceUID, resourceType: action.resourceType});
            return {...state, fullScreenHistoryChain: currentFullScreenHistoryChainAdd};

        case ADMIN_MOVE_BACK_IN_FULL_SCREEN_HISTORY_CHAIN:
            let currentFullScreenHistoryChain = [...state.fullScreenHistoryChain];
            if(currentFullScreenHistoryChain.length >= 2){
                let updatedFullScreenDisplay = {...state.fullScreenDisplay};
                currentFullScreenHistoryChain.pop();
                const targetItem = currentFullScreenHistoryChain.slice(-1)[0];

                // fullScreenDisplay: {showDialog: false, resourceUID: undefined, resourceType: undefined, displayMode: ADMIN_FULL_SCREEN_DISPLAY_MODE_DRAWER},

                updatedFullScreenDisplay.resourceUID = targetItem.resourceUID;
                updatedFullScreenDisplay.resourceType = targetItem.resourceType;

                return {...state, fullScreenDisplay: updatedFullScreenDisplay, fullScreenHistoryChain: currentFullScreenHistoryChain};
            }
            else{
                return state;
            }

        //
        // System Stats
        //
        case ADMIN_SET_SYSTEM_STATS:
            return {...state, systemStats: action.systemStats};

        case ADMIN_ADD_SYSTEM_STATS:
            const updatedSystemStats = {...state.systemStats, [action.statsID]: action.systemStats};
            return {...state, systemStats: updatedSystemStats};

        //
        // DEFAULT
        //
        default:
            return state;
    }
};