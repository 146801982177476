/*

 */

import React from 'react';
import Link from "@material-ui/core/Link";
import {bindActionCreators} from "redux";
import {
    adminActionAddToFullScreenHistoryChain,
    adminActionSetGenericFullScreenDisplay
} from "../../../../actions/adminActions";
import {connect} from "react-redux";
import {fullScreenDisplaySelector} from "../../../../selectors/adminSelectors";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {PRIMARY_BLUE_COLOUR} from "../../../../constants/styleConstants";

const useStyles = makeStyles(theme => ({
    root: {
        cursor: "pointer"
    }
}));

function GenericFullScreenButton({linkText, resourceUID, resourceType, useLink = true, ...props}) {
    const classes = useStyles();

    if(useLink === true) {
        return (
            <a
                onClick={handleClick}
                className={classes.root}
                style={{color: PRIMARY_BLUE_COLOUR}}
            >
                {linkText}
            </a>
        );
    }

    // if(useLink === true) {
    //     return (
    //         <Link
    //             component="button"
    //             variant="body2"
    //             onClick={handleClick}
    //             className={classes.root}
    //         >
    //             {linkText}
    //         </Link>
    //     );
    // }

    return (
        <Button
            size="small"
            onClick={handleClick}
        >
            {linkText}
        </Button>
    );

    function handleClick() {
        props.adminActionSetGenericFullScreenDisplay(
            {
                showDialog: true,
                resourceUID: resourceUID,
                resourceType: resourceType,
                displayMode: props.fullScreenDisplay.displayMode
            }
        );

        props.adminActionAddToFullScreenHistoryChain(resourceUID, resourceType);
    }


}

const mapStateToProps = (state) => ({
    fullScreenDisplay: fullScreenDisplaySelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionSetGenericFullScreenDisplay: adminActionSetGenericFullScreenDisplay,
        adminActionAddToFullScreenHistoryChain: adminActionAddToFullScreenHistoryChain
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(GenericFullScreenButton);