// Client type: admin
// Name: Payments Service Custom Render Components

import {
    PAYMENT_OBJECT_KEY_PAYMENT_CLEARED_INTO_DEPOSIFY_BANK_ACCOUNT,
    OBJECT_KEY_STATE,
    PAYMENT_OBJECT_KEY_FLAGGED_FOR_ADMIN_REVIEW, PAYMENT_OBJECT_KEY_HIGH_RISK_PAYMENT,
    PAYMENT_OBJECT_KEY_PAYMENT_CLEARED, PAYMENT_OBJECT_KEY_PAYMENT_OBJECT,
    PAYMENT_OBJECT_KEY_PAYMENT_SUCCEEDED,
    USER_WITHDRAW_OBJECT_KEY, OBJECT_KEY_FUNDS_LOCATION, PAYMENT_OBJECT_KEY_CLEAR_INTO_FUNDS_LOCATION
} from "../objects/objectKeys";
import UserWithdrawStateSelector from "../../../components/admin/selectors/UserWithdrawStateSelector";
import BooleanTypeRender from "../../components/types/BooleanTypeRender";
import {TYPE_KEY_BOOL_VALUE} from "../types/TypeConstants";
import PaymentAdminReviewStatus from "../../../components/payments/PaymentAdminReviewStats";
import {FundsLocationDisplay} from "../../../components/deposits/FundsLocationDisplay";
import {FundsClearedLocationDisplay} from "../../../components/payments/FundsClearedLocationDisplay";
import LoanState from "../../../components/payments/LoanState";
import SezzleOrderState from "../../../components/payments/SezzleOrderState";

export const USER_WITHDRAW_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_STATE]: {
        dataPropName: USER_WITHDRAW_OBJECT_KEY,
        renderComp: UserWithdrawStateSelector,
        passDataObject: true
    },
};

export const PAYMENT_REQUEST_CUSTOM_RENDER_COMPONENTS = {};

export const STRIPE_PAYMENT_LOG_CUSTOM_RENDER_COMPONENTS = {
    [PAYMENT_OBJECT_KEY_FLAGGED_FOR_ADMIN_REVIEW]: {
        dataPropName: PAYMENT_OBJECT_KEY_PAYMENT_OBJECT,
        renderComp: PaymentAdminReviewStatus,
        passDataObject: true
    },
    [PAYMENT_OBJECT_KEY_PAYMENT_SUCCEEDED]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender
    },
    [PAYMENT_OBJECT_KEY_HIGH_RISK_PAYMENT]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender,
        extraProps: {invertColours: true}
    },
    [PAYMENT_OBJECT_KEY_PAYMENT_CLEARED_INTO_DEPOSIFY_BANK_ACCOUNT]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender
    },
    [PAYMENT_OBJECT_KEY_CLEAR_INTO_FUNDS_LOCATION]: {
        dataPropName: 'sourceObject',
        renderComp: FundsClearedLocationDisplay,
        passDataObject: true
    },
};

export const INVOICE_CUSTOM_RENDER_COMPONENTS = {};

export const INVOICE_ITEM_CUSTOM_RENDER_COMPONENTS = {};


export const PRIORITY_ACH_PAYMENT_CUSTOM_RENDER_COMPONENTS = {
    [PAYMENT_OBJECT_KEY_FLAGGED_FOR_ADMIN_REVIEW]: {
        dataPropName: PAYMENT_OBJECT_KEY_PAYMENT_OBJECT,
        renderComp: PaymentAdminReviewStatus,
        passDataObject: true
    },
    [PAYMENT_OBJECT_KEY_PAYMENT_SUCCEEDED]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender
    },
    [PAYMENT_OBJECT_KEY_PAYMENT_CLEARED_INTO_DEPOSIFY_BANK_ACCOUNT]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender
    },
    [PAYMENT_OBJECT_KEY_CLEAR_INTO_FUNDS_LOCATION]: {
        dataPropName: 'sourceObject',
        renderComp: FundsClearedLocationDisplay,
        passDataObject: true
    },
};

export const LOAN_CUSTOM_RENDER_COMPONENTS = {
    'state': {
        dataPropName: 'state',
        renderComp: LoanState
    }
};

export const LOAN_APPLICATION_CUSTOM_RENDER_COMPONENTS = {};
export const LOAN_HISTORY_CUSTOM_RENDER_COMPONENTS = {};
export const LOAN_PAYMENT_LOG_CUSTOM_RENDER_COMPONENTS = {};
export const LOAN_SYSTEM_INFO_CUSTOM_RENDER_COMPONENTS = {};
export const PRIORITY_ACH_RECURRING_PAYMENT_CUSTOM_RENDER_COMPONENTS = {};
export const STRIPE_WEBHOOK_LOG_CUSTOM_RENDER_COMPONENTS = {};
export const USER_ACCOUNT_PAYMENT_PROVIDER_INFO_CUSTOM_RENDER_COMPONENTS = {};
export const SEZZLE_ORDER_CUSTOM_RENDER_COMPONENTS = {
    'state': {
        dataPropName: 'state',
        renderComp: SezzleOrderState
    }
};
export const SEZZLE_WEBHOOK_EVENT_CUSTOM_RENDER_COMPONENTS = {};