/*
    Account Profile Image
 */
import React from "react"
import styled from 'styled-components';
import {useProfilePicLoader} from "../../hooks/useProfilePicLoader";
import {PROFILE_DEFAULT_PIC_URL} from "../../../constants/cdnConstants";

const ProfileImage = styled.img.attrs({})`
    width: 60px;
    height: 60px;
    border: double 4px transparent;
    border-radius: 80px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin-right: 10px;
    margin-top: 3px;
    cursor: pointer;
`;

const ProfileImageWithGradientBorder = styled.img.attrs({})`
    width: 60px;
    height: 60px;
    border: double 4px transparent;
    border-radius: 80px;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #4265FF,#9BF8DE);
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin-right: 10px;
    margin-top: 3px;
    cursor: pointer;
`;

const AccountProfileImage = ({accountUID, showGradientBorder = true}) => {

    const {loading, error, profilePicURL} = useProfilePicLoader(accountUID);
    const RenderComponent = showGradientBorder === true ? ProfileImageWithGradientBorder : ProfileImage;

    if(loading === true || error === true){
        return <RenderComponent src={PROFILE_DEFAULT_PIC_URL} alt="Profile picture"/>;
    }

    return <RenderComponent src={profilePicURL} alt="Profile picture"/>;
}

export default AccountProfileImage;
