/*

 */

import {createSelector} from 'reselect'
import {jsDateTimeFromString} from "../utilities/commonUtils";

const basicUserAccountInfoSelector = state => state.user.basicUserAccounts;
const accountUIDSelector = (state, accountUID) => accountUID;
const bankAccountUIDSelector = (state, bankAccountUID) => bankAccountUID;

export const currentUserAccountSelector = state => state.user.user_account;
export const currentUserAccountDefaultRole = state => state.user.user_account.default_role;
export const currentUserAccountSettingsSelector = state => state.user.user_account_settings;
export const currentUserAccountBalanceSelector = state => state.user.user_account_balance;
export const internationalBankAccountsSelector = state => state.user.user_bank_accounts.international_bank_accounts;
export const usDomesticBankAccountsSelector = state => state.user.user_bank_accounts.us_domestic_bank_accounts;

export const currentUserAccountUIDSelector = state => state.user.user_account.account_uid;

export const alwaysShowArchivedDepositsSelector = state => state.user.user_account_settings.always_show_archived_deposits;

export const currentUserNameSelector = state => `${state.user.user_account.first_name} ${state.user.user_account.last_name}`;

export const pendingEmailChangeSelector = state => state.user.pendingEmailChange;

export const userAccountHistorySelector = state => state.user.userAccountHistory;
export const accountBalanceLogSelector = state => state.user.accountBalanceLog;

export const currentUserAccountDateDisplayFormatSelector = state => state.user.user_account_settings.date_display_format;

export const makeBasicUserAccountInfoSelector = () => createSelector(
    [basicUserAccountInfoSelector, accountUIDSelector],
    (basicAccountInfo, accountUID) => basicAccountInfo[accountUID]
);

export const makeUSDomesticBankAccountSelector = () => createSelector(
    [usDomesticBankAccountsSelector, bankAccountUIDSelector],
    (domesticBankAccounts, bankAccountUID) => domesticBankAccounts[bankAccountUID]
);

export const makeInternationalBankAccountSelector = () => createSelector(
    [internationalBankAccountsSelector, bankAccountUIDSelector],
    (internationalBankAccounts, bankAccountUID) => internationalBankAccounts[bankAccountUID]
);



// Return an array of all bank accounts
export const allBankAccountSelector = createSelector(
    [usDomesticBankAccountsSelector, internationalBankAccountsSelector],
    (internationalBankAccounts, domesticBankAccounts) => Object.values(domesticBankAccounts).concat(Object.values(internationalBankAccounts))
);

export const recentlyUpdatedBankAccountsSelector = createSelector(
    [allBankAccountSelector],
    (bankAccounts) => {
        return bankAccounts.sort(function (a, b) {
            return jsDateTimeFromString(b.last_updated_timestamp) - jsDateTimeFromString(a.last_updated_timestamp)
        });
    }
);