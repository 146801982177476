import React from "react";
import {getDateDeltaString, getMomentUTC, getServerDateTimeAsMoment} from "../../utilities/commonUtils";


export const RenderTimeSince = ({timestamp}) => {
    return <>{getDateDeltaString(getServerDateTimeAsMoment(timestamp))}&nbsp;ago</>;
}

export const RenderTimeTo = ({timestamp}) => {
    return <>in{getDateDeltaString(getMomentUTC(), getServerDateTimeAsMoment(timestamp))}</>;
}


