/*

 */

import React from 'react';

import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import AdminDisplayCard from "./adminDisplayCard";
import {ADMIN_ICON_USER_WITHDRAWS} from "../adminIcons";
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import AdminDisplayInternationalBankAccount from "./adminDisplayInternationalBankAccount";
import AdminDisplayUSDomesticBankAccount from "./adminDisplayUSDomesticBankAccount";
import {dollarFormattedString} from "../../../utilities/commonUtils";
import CreatedUpdatedTimestampDisplay from "../../utilities/displayAreas/CreatedUpdatedTimestampDisplay";
import UserWithdrawStateSelector from "../selectors/UserWithdrawStateSelector";
import {
    InternationalUserBankAccountContainer,
    USDomesticUserBankAccountContainer
} from "../../../autoGenerated/components/containers/resourceContainers";


export default function AdminDisplayUserWithdraw({loading, error, userWithdraw, ...props}) {

    if (loading === true || error === true || userWithdraw === undefined) {
        return (
            <VisualizeContainerLoadingIndicator
                loadingResource={userWithdraw}
                displayName={'User Withdraw'}
                {...props} />
        );
    }

    return (
        <AdminDisplayCard
            cardTitle={'Withdraw'}
            cardSubheader={''}
            headerIcon={ADMIN_ICON_USER_WITHDRAWS}
            jsonObject={userWithdraw}
        >
            <b>User</b>: <AdminViewFullAccountInfoLink accountUID={userWithdraw.account_uid} showExtraInfo={false}/>
            <br/>

            <b>Amount</b>: {dollarFormattedString(userWithdraw.amount_in_cents)}
            <br/>

            <b>Show verification:</b> {userWithdraw.show_user_payment_verification.toString()}
            <br/>

            <b>State</b>:&nbsp;
            <UserWithdrawStateSelector userWithdraw={userWithdraw}/>
            <br/>

            <b>Created/Updated</b>:
            <CreatedUpdatedTimestampDisplay
                createdTimestamp={userWithdraw.created_timestamp}
                lastUpdatedTimestamp={userWithdraw.last_updated_timestamp}
            />
            <br/>


            <b>Bank Account</b>:
            <br/>
            {
                // TODO: Use constants and get rid of this magic string
                userWithdraw.user_bank_account_type === 'international' &&
                <InternationalUserBankAccountContainer bankAccountUID={userWithdraw.user_bank_account_uid}>
                    <AdminDisplayInternationalBankAccount/>
                </InternationalUserBankAccountContainer>
            }
            {
                userWithdraw.user_bank_account_type === 'us_domestic' &&
                <USDomesticUserBankAccountContainer bankAccountUID={userWithdraw.user_bank_account_uid}>
                    <AdminDisplayUSDomesticBankAccount/>
                </USDomesticUserBankAccountContainer>
            }

        </AdminDisplayCard>

    );

}