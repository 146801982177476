/*

 */

import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import PMCAutoSuggest from "../pmcManagement/PMCAutoSuggest";
import {ALL_LANDLORD_ACCOUNTS_LABEL, PMC_ACCOUNTS} from "../../../constants/PMCConstants";
import {DEFAULT_BATCH_UID_LOAD_LIMIT, DEV_KEEP_LOADING} from "../../../constants/apiConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {adminActionLoadAllUIDS} from "../../../actions/adminActions";
import SortingColumnAutoSuggest from "./SortingColumnAutoSuggest";
import {DEFAULT_SORTING_COLUMN} from "../../../constants/adminConstants";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {APP_ROUTE_HOME} from "../../../constants/appRoutes";
import {history} from "../../../history/history";
import {getJSONSchema} from "../../../autoGenerated/jsonSchemas/JSONSchemasMapping";
import Link from "@material-ui/core/Link";
import {createCommonResourceUIDListURL} from "../../../clientShared/utilities/resourceUtilities";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
    divider: {
        height: 28,
        margin: 4,
    }
}));

function UIDListControls({
                             resourceType,
                             uidListloadURL,
                             displayName,
                             outgoingUIDListName,
                             selectedPMCAccountUID,
                             selectedPMCLabel,
                             sortingColumnName,
                             setPMCAccountCallback,
                             setSortingColumnCallback,
                             setPMCLabelCallback,
    readDescending,
                             setReadDescendingCallback,
                             pmcSelectDisabled = false,
                             sortColumnDisabled = false,
                             ...props
                         }) {
    const classes = useStyles();

    // // Use the useEffect hook to pick up changes and reload the list as needed
    // useEffect(() => {
    //     const doTheRefresh = async () => {
    //         //refreshList();
    //     };
    //
    //     doTheRefresh();
    // }, []);

    const jsonSchema = getJSONSchema(resourceType);

    return (
        <React.Fragment>
            <Card variant="outlined">

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    wrap={'nowrap'}
                    spacing={2}
                >
                    <Grid item></Grid>

                    <Grid item>
                        <Button
                            color="primary"
                            onClick={refreshList}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} fixedWidth/>&nbsp;Refresh {displayName}
                        </Button>
                    </Grid>

                    <Grid item>
                        <Divider className={classes.divider} orientation="vertical"/>
                    </Grid>

                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <PMCAutoSuggest
                                    valueChangeCallback={handlePMCChange}
                                    selectedValue={selectedPMCLabel}
                                    disabled={pmcSelectDisabled}
                                />
                            </Grid>

                            <Grid item>
                                &nbsp;
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <SortingColumnAutoSuggest
                                    columnSuggestions={Object.keys(jsonSchema.properties)}
                                    currentValue={sortingColumnName}
                                    changeCallback={sortingColumnChanged}
                                    disabled={sortColumnDisabled}
                                />
                            </Grid>
                            <Grid item>
                                quick:&nbsp;
                                <Link
                                    component="button"
                                    onClick={()=>{sortingColumnChanged('created_timestamp')}}
                                >
                                    created
                                </Link>
                                &nbsp;|&nbsp;
                                <Link
                                    component="button"
                                    onClick={()=>{sortingColumnChanged('last_updated_timestamp')}}
                                >
                                    last Updated
                                </Link>
                                &nbsp;|&nbsp;
                                <Link
                                    component="button"
                                    onClick={()=>{sortingColumnChanged('became_active_timestamp')}}
                                >
                                    became active
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={readDescending}
                                    onChange={handleDescendingChange}
                                    name="Read Descending"
                                    color="primary"
                                />
                            }
                            label="Descending"
                        />
                    </Grid>

                </Grid>
            </Card>

        </React.Fragment>
    );

    function handleDescendingChange(newValue){
        setReadDescendingCallback(newValue.target.checked);
    }

    // Handle the PMC being changed
    function handlePMCChange(newPMCValue) {
        if (newPMCValue === null) {
            setPMCAccountCallback(NULL_UUID_STRING);
            setPMCLabelCallback(ALL_LANDLORD_ACCOUNTS_LABEL);
        } else {
            setPMCAccountCallback(PMC_ACCOUNTS[newPMCValue]);
            setPMCLabelCallback(newPMCValue);
        }

        //setTriggerRefresh(!triggerRefresh);
    }

    // Handle the sorting column being changed
    function sortingColumnChanged(newSortingColumn) {
        if (newSortingColumn === null) {
            setSortingColumnCallback(DEFAULT_SORTING_COLUMN);
        } else {
            setSortingColumnCallback(newSortingColumn);
        }

        //setTriggerRefresh(!triggerRefresh);
    }

    // Refresh the list
    function refreshList() {
        props.adminActionLoadAllUIDS(
            resourceType,
            '',
            0,
            DEFAULT_BATCH_UID_LOAD_LIMIT,
            sortingColumnName,
            selectedPMCAccountUID,
            true,
            DEV_KEEP_LOADING,
            readDescending
        );
    }

    function homeClicked() {
        history.push(APP_ROUTE_HOME);
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionLoadAllUIDS: adminActionLoadAllUIDS
    }, dispatch);
};

export default connect(null, mapActionsToProps)(React.memo(UIDListControls));