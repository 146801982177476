import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDumpsterFire} from "@fortawesome/pro-regular-svg-icons";
import LinkBase from "../../clientShared/components/links/linkBase";

export const RHINO_LINK_BANK_BULK_IMPORT = "/noodles/bank-bulk-import";

export const BankBulkImportLink = () => {
    return (
        <>
            <FontAwesomeIcon icon={faDumpsterFire} fixedWidth/>&nbsp;
            <LinkBase url={RHINO_LINK_BANK_BULK_IMPORT} text={"Bank Bulk Import"}/>
        </>
    );
};

