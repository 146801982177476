/*

 */
import {
    UPDATE_USER,
    UPDATE_USER_ACCOUNT_BALANCE,
    LOG_USER_OUT,
    UPDATE_INTERNATIONAL_BANK_ACCOUNTS,
    UPDATE_US_DOMESTIC_BANK_ACCOUNTS,
    ADD_NEW_US_DOMESTIC_USER_BANK_ACCOUNT,
    ADD_NEW_INTERNATIONAL_USER_BANK_ACCOUNT,
    UPDATE_BASIC_USER_INFO,
    UPDATE_USER_ACCOUNT_SETTINGS,
    UPDATE_PARTIAL_USER_ACCOUNT_SETTINGS,
    USER_SET_PENDING_EMAIL_CHANGE, USER_EVENT_UPDATE_USER_ACCOUNT_HISTORY, USER_EVENT_UPDATE_USER_ACCOUNT_BALANCE_LOG
} from "../actions/userActionTypes";
import {
    sentrySetCurrentUserDetails,
    sentrySetUserLoggedOutDetails
} from "../utilities/sentryLoggingUtils";
import {APP_EVENT_CLEAR_ALL, APP_EVENT_SET_LOGGED_OUT} from "../clientShared/actions/appActionTypes";



// TODO: We should have dummy data here
const initialUserState = {
    user_account: {},
    user_account_settings: {},
    user_account_balance: {},
    user_bank_accounts: {
        international_bank_accounts: {},
        us_domestic_bank_accounts: {}
    },
    basicUserAccounts: {},
    pendingEmailChange: {
        emailAddress: undefined,
        lastUpdated: undefined
    },
    userAccountHistory: [],
    accountBalanceLog: []
};

export default function userReducer(state = initialUserState, action) {
    switch (action.type) {

        case APP_EVENT_SET_LOGGED_OUT:
            return {...state};

        // TODO: DEBUG ONLY - REMOVE THIS
        case USER_EVENT_UPDATE_USER_ACCOUNT_HISTORY:
            // todo: clean this all up
            let updatedHistory = [...state.userAccountHistory];
            const cheese = updatedHistory.concat(action.newEntries);
            return {...state, userAccountHistory: cheese};

        // TODO: DEBUG ONLY - REMOVE THIS
        case USER_EVENT_UPDATE_USER_ACCOUNT_BALANCE_LOG:
            // todo: clean this all up
            let updatedBalanceChangeLog = [...state.userAccountHistory];
            const magicMouseDog = updatedBalanceChangeLog.concat(action.newEntries);
            return {...state, accountBalanceLog: magicMouseDog};

        case APP_EVENT_CLEAR_ALL:
            return initialUserState;

        case USER_SET_PENDING_EMAIL_CHANGE:
            const newPendingEmailChange = {
                emailAddress: action.pendingEmailChange,
                lastUpdated: new Date()
            };
            return {...state, pendingEmailChange: newPendingEmailChange};

        case UPDATE_USER_ACCOUNT_SETTINGS:
            return {...state, user_account_settings: action.accountSettings};

        case UPDATE_PARTIAL_USER_ACCOUNT_SETTINGS:
            const updatedSettings = {...state.user_account_settings, ...action.partialAccountSettings};
            return {...state, user_account_settings: updatedSettings};

        case UPDATE_USER:
            // Tell Sentry about this user
            sentrySetCurrentUserDetails(action.user_account);
            return {...state, user_account: action.user_account};
        case UPDATE_USER_ACCOUNT_BALANCE:
            return {...state, user_account_balance: action.user_account_balance};
        case LOG_USER_OUT:
            // Tell Sentry this user logged out
            sentrySetUserLoggedOutDetails(state.user_account);
            return {
                ...state,
                user_account: {},
                user_account_balance: {}
            };

        case UPDATE_INTERNATIONAL_BANK_ACCOUNTS:
            let updated_int_accounts = {...state.user_bank_accounts.international_bank_accounts};
            action.bank_accounts.reduce(function (map, obj) {
                updated_int_accounts[obj.bank_account_uid] = obj;
                return updated_int_accounts;
            }, {});

            const updated_user_bank_accounts = {
                ...state.user_bank_accounts,
                international_bank_accounts: updated_int_accounts
            };

            return {...state, ...{user_bank_accounts: updated_user_bank_accounts}};
        case UPDATE_US_DOMESTIC_BANK_ACCOUNTS:
            let updated_us_dom_accounts = {...state.user_bank_accounts.us_domestic_bank_accounts};
            action.bank_accounts.reduce(function (map, obj) {
                updated_us_dom_accounts[obj.bank_account_uid] = obj;
                return updated_us_dom_accounts;
            }, {});

            const more_updated_bank_accounts = {
                ...state.user_bank_accounts,
                us_domestic_bank_accounts: updated_us_dom_accounts
            };

            return {...state, ...{user_bank_accounts: more_updated_bank_accounts}};
        case ADD_NEW_US_DOMESTIC_USER_BANK_ACCOUNT:
            let _updated_us_dom_accounts = {...state.user_bank_accounts.us_domestic_bank_accounts};
            _updated_us_dom_accounts[action.new_bank_account.bank_account_uid] = action.new_bank_account;

            const _more_updated_bank_accounts = {
                ...state.user_bank_accounts,
                us_domestic_bank_accounts: _updated_us_dom_accounts
            };

            return {...state, ...{user_bank_accounts: _more_updated_bank_accounts}};

        case ADD_NEW_INTERNATIONAL_USER_BANK_ACCOUNT:
            let __updated_int_accounts = {...state.user_bank_accounts.international_bank_accounts};
            __updated_int_accounts[action.new_bank_account.bank_account_uid] = action.new_bank_account;

            const updated_all_accounts_new_int = {
                ...state.user_bank_accounts,
                international_bank_accounts: __updated_int_accounts
            };

            return {...state, ...{user_bank_accounts: updated_all_accounts_new_int}};

        case UPDATE_BASIC_USER_INFO:
            let currentBasicInfo = {...state.basicUserAccounts};
            currentBasicInfo[action.accountUID] = action.basicUserInfo;
            return {...state, ...{basicUserAccounts: currentBasicInfo}};

        default:
            return state;
    }
}