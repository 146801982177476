/*

 */

import React from 'react';
import * as Sentry from '@sentry/browser';
import {axiosInstance} from "../../../utilities/axiosInstance";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {USER_WITHDRAW_STATE_PAYMENT_COMPLETE, USER_WITHDRAWALS_STATES_DICT} from "../../../constants/paymentConstants";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {loadingActionLoadUserWithdrawFromServer} from "../../../autoGenerated/actions/resourceLoadingActions";
import toast from "react-hot-toast";


function UserWithdrawStateSelector({userWithdraw, ...props}) {

    return (
        <FormControl>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userWithdraw.state}
                onChange={handleChange}
            >
                {
                    Object.keys(USER_WITHDRAWALS_STATES_DICT).map(withdrawStateKey => {
                        return <MenuItem
                            value={withdrawStateKey}>{USER_WITHDRAWALS_STATES_DICT[withdrawStateKey]}</MenuItem>;
                    })
                }
            </Select>
        </FormControl>
    );

    function handleChange(event) {
        // withdrawals/<uuid:withdraw_uid>/set/state/<string:new_state>
        const newState = event.target.value;

        if (newState === USER_WITHDRAW_STATE_PAYMENT_COMPLETE) {
            toast.error("Use mark as complete admin button - do not set 'complete' state manually");
        } else {
            axiosInstance.put(`api/ornate/cabbage/payments/withdrawals/${userWithdraw.withdraw_uid}/set/state/${newState}`, {})
                .then(function (response) {
                    toast.success('Withdraw updated...');
                    props.loadingActionLoadUserWithdrawFromServer(userWithdraw.withdraw_uid);
                })
                .catch(function (error) {
                    Sentry.withScope(scope => {
                        scope.setExtra("response", error.response);
                        scope.setExtra("newState", newState);
                        scope.setExtra("userWithdraw", userWithdraw);
                        Sentry.captureException(error);
                    });
                });
        }
    }


}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadingActionLoadUserWithdrawFromServer: loadingActionLoadUserWithdrawFromServer
    }, dispatch);
};

export default connect(null, mapActionsToProps)(UserWithdrawStateSelector);