// Name: Messaging Table Render Definitions 

export const EMAIL_EVENT_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	email_uid: {displayName: "Email UID"},
	event_uid: {displayName: "Event UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	event_type: {displayName: "Event Type"},
	mailgun_email_id: {displayName: "Mailgun Email Id"}
};

export const EMAIL_TEMPLATE_TABLE_RENDER_DEF = {
	archived: {displayName: "Archived"},
	created_timestamp: {displayName: "Created Timestamp"},
	template_uid: {displayName: "Template UID"},
	template_version: {displayName: "Template Version"},
	email_body: {displayName: "Email Body"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	template_id: {displayName: "Template Id"},
	internal_name: {displayName: "Internal Name"},
	email_subject: {displayName: "Email Subject"}
};

export const MAILBOX_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	creating_account_uid: {displayName: "Creating Account UID"},
	mailbox_uid: {displayName: "Mailbox UID"},
	resource_uid: {displayName: "Resource UID"},
	resource_type: {displayName: "Resource Type"},
	recipient_account_uids: {displayName: "Recipient Account UIDs"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	removed_recipient_account_uids: {displayName: "Removed Recipient Account UIDs"},
	deposify_tags: {displayName: "Deposify Tags"}
};

export const MAILBOX_MESSAGE_TABLE_RENDER_DEF = {
	sender_account_uid: {displayName: "Sender Account UID"},
	read_timestamp: {displayName: "Read Timestamp"},
	deleted: {displayName: "Deleted"},
	message_title: {displayName: "Message Title"},
	deleted_timestamp: {displayName: "Deleted Timestamp"},
	message_body: {displayName: "Message Body"},
	message_type: {displayName: "Message Type"},
	mailbox_uid: {displayName: "Mailbox UID"},
	sent_timestamp: {displayName: "Sent Timestamp"},
	read: {displayName: "Read"},
	recipient_account_uid: {displayName: "Recipient Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	message_uid: {displayName: "Message UID"},
	attached_files_uid: {displayName: "Attached Files UID"},
	deposify_tags: {displayName: "Deposify Tags"}
};

export const MAILBOX_META_DATA_TABLE_RENDER_DEF = {
	meta_data_uid: {displayName: "Meta Data UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	mailbox_uid: {displayName: "Mailbox UID"},
	new_message_count: {displayName: "New Message Count"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"}
};

export const SENT_EMAIL_TABLE_RENDER_DEF = {
	email_uid: {displayName: "Email UID"},
	template_id: {displayName: "Template Id"},
	email_type: {displayName: "Email Type"},
	resource_uid: {displayName: "Resource UID"},
	resource_type: {displayName: "Resource Type"},
	recipient_email_address: {displayName: "Recipient Email Address"},
	created_timestamp: {displayName: "Created Timestamp"},
	// template_version: {displayName: "Template Version"},
	recipient_account_uid: {displayName: "Recipient Account UID"},
	// recipient_email_hash: {displayName: "Recipient Email Hash"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// mailgun_email_id: {displayName: "Mailgun Email Id"}
};

