import React from "react";

import Grid from "@material-ui/core/Grid";
import GenericObjectDisplay from "../../clientShared/components/display/GenericObjectDisplay";
import LogOnlineUserOutButton from "./LogOnlineUserOutButton";
import {currentUserAccountUIDSelector} from "../../selectors/userSelectors";
import {connect} from "react-redux";
import Alert from "@material-ui/lab/Alert";


function OnlineUserDisplay(props) {

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >

            <Grid item>
                <GenericObjectDisplay {...props}/>
            </Grid>

            {
                props.onlineUser.account_uid === props.currentUserAccountUID &&
                <Grid item>
                    <Alert severity="warning">This is your account</Alert>
                </Grid>
            }

            <Grid item>
                <LogOnlineUserOutButton onlineUserUID={props.onlineUser.online_user_uid}/>
            </Grid>


        </Grid>
    );
}

const mapStateToProps = (state) => ({
    currentUserAccountUID: currentUserAccountUIDSelector(state)
});

export default connect(mapStateToProps)(OnlineUserDisplay);

