import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import * as Yup from "yup";
import * as Sentry from "@sentry/browser";
import toast from "react-hot-toast";
import {useFormik} from "formik";

import {YUP_SCHEMA_EMAIL_ADDRESS} from "../../utilities/yupCommonValidationSchemas";
import {EmailAddressField, StandardSelectField} from "../utilities/forms/CommonFormFields";
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import {Collapse} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import ClickToCopy from "../../clientShared/components/utility/ClickToCopy";


const ChangeAccountEmailForm = ({accountUID, currentEmail, doneCallback}) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [serverError, setServerError] = useState("");

    const formSchema = Yup.object().shape({
        ...YUP_SCHEMA_EMAIL_ADDRESS,
    });

    const formik = useFormik({
        initialValues: {
            email_address: "",
        },
        onSubmit: handleSubmit,
        isInitialValid: false,
        validationSchema: formSchema
    });

    function handleSubmit(values) {
        setShowSpinner(true);
        setServerError("");

        let updatedValues = {...values};

        const callURL = getAdminPath(`jeff/account/${accountUID}/email/change`);
        axiosInstance.post(callURL, updatedValues)
            .then(function (response) {
                setShowSpinner(false);
                toast.success('Done');
                window.location.reload();
                // doneCallback();
            })
            .catch(function (error) {
                setShowSpinner(false);

                if (error.response.status === 400 && error.response.data.message === "account_exists") {
                    setServerError('An account with this email already exists');
                } else {
                    setServerError("");
                    Sentry.withScope(scope => {
                        scope.setExtra("response", error.response);
                        Sentry.captureException(error);
                    });
                }

                toast.error('Failed');
            })
    }


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="stretch"
                    spacing={1}
                >

                    <Grid item xs={12}>
                        Current email address: <ClickToCopy dataToCopy={currentEmail}/>
                    </Grid>


                    <Grid item xs={12}>
                        {EmailAddressField(formik)}
                    </Grid>

                    <Grid item xs={12}>
                        &nbsp;
                    </Grid>

                    <Grid item zeroMinWidth xs={12}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            wrap="nowrap"
                        >
                            <Grid item zeroMinWidth>
                                <SpinnerButton
                                    // Non-button props
                                    showSpinner={showSpinner}
                                    // Button props
                                    variant="contained"
                                    color="primary"
                                    disabled={!formik.isValid || showSpinner}
                                    type={'submit'}
                                >
                                    Update Email
                                </SpinnerButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

            <Collapse in={serverError !== ""}>
                <br/>
                <Alert severity="error">
                    {serverError}
                </Alert>
            </Collapse>

        </>
    );
};


export default ChangeAccountEmailForm;