/*
    Common Utilities
 */

import {
    ACTIVE_COLOR, ACTIVE_COLOR_MUTED,
    CANCELLED_COLOR, CANCELLED_COLOR_MUTED,
    CLOSE_REQUESTED_COLOR, CLOSE_REQUESTED_COLOR_MUTED,
    CLOSED_COLOR, CLOSED_COLOR_MUTED,
    DISPUTE_MEDIATOR_INVOLVED_COLOR,
    DISPUTED_COLOR, DISPUTED_COLOR_MUTED,
    PENDING_COLOR, PENDING_COLOR_MUTED,
} from "../constants/dataConstants";
import {
    DEPOSIT_STATE_PENDING,
    DEPOSIT_STATE_ACTIVE,
    DEPOSIT_STATE_CLOSED_REQUESTED,
    DEPOSIT_STATE_CLOSED,
    DEPOSIT_STATE_DISPUTED,
    DEPOSIT_STATE_DISPUTE_SPLIT_PROPOSED,
    DEPOSIT_STATE_MEDIATOR_INVOLVED_DISPUTE,
    DEPOSIT_STATE_MEDIATOR_SPLIT_PROPOSED,
    DEPOSIT_STATE_CANCELLED
} from "../constants/depositConstants";
import {rePhoneNumber} from "./yupCommonValidationSchemas";
import {
    CIRCLE_ICON,
    DEPOSIT_STATE_ICON_ACTIVE, DEPOSIT_STATE_ICON_CANCELLED,
    DEPOSIT_STATE_ICON_CLOSED,
    DEPOSIT_STATE_ICON_CLOSED_REQUESTED,
    DEPOSIT_STATE_ICON_DISPUTE_SPLIT_PROPOSED,
    DEPOSIT_STATE_ICON_DISPUTED,
    DEPOSIT_STATE_ICON_MEDIATOR_INVOLVED_DISPUTE, DEPOSIT_STATE_ICON_MEDIATOR_SPLIT_PROPOSED,
    DEPOSIT_STATE_ICON_PENDING
} from "../constants/commonIcons";
import {
    WITHDRAW_STATE_CANCELLED,
    WITHDRAW_STATE_PAYMENT_COMPLETE,
    WITHDRAW_STATE_PAYMENT_PENDING,
    WITHDRAW_STATE_PENDING,
    WITHDRAW_STATE_USER_INFO_NEEDED
} from "../constants/fundsConstants";

export const ACCOUNT_TYPE_LANDLORD = 'landlord';
export const ACCOUNT_TYPE_TENANT = 'tenant';
export const ACCOUNT_TYPE_MEDIATOR = 'mediator';
export const ACCOUNT_TYPE_PROPERTY_MANAGER = 'property_manager';

export const ACCOUNT_DISPLAY_STRING_OWNER = 'Owner';
export const ACCOUNT_DISPLAY_STRING_RESIDENT = 'Resident';
export const ACCOUNT_DISPLAY_STRING_MEDIATOR = 'Mediator';
export const ACCOUNT_DISPLAY_STRING_PROPERTY_MANAGER = 'Property Manager';

export const SHORT_CODE_DISPLAY_STRING = 'Invite Code';

export function getDisplayDepositAccountType(depositAccountType) {
    switch (depositAccountType) {
        case ACCOUNT_TYPE_LANDLORD:
            return ACCOUNT_DISPLAY_STRING_OWNER;
        case ACCOUNT_TYPE_TENANT:
            return ACCOUNT_DISPLAY_STRING_RESIDENT;
        case ACCOUNT_TYPE_MEDIATOR:
            return ACCOUNT_DISPLAY_STRING_MEDIATOR;
        case ACCOUNT_TYPE_PROPERTY_MANAGER:
            return ACCOUNT_DISPLAY_STRING_PROPERTY_MANAGER;
        default:
            return 'Deposify Account'
    }
}

export function getDepositDisplayState(depositState){
    switch (depositState) {
        case DEPOSIT_STATE_PENDING:
            return 'Pending';
        case DEPOSIT_STATE_ACTIVE:
            return 'Active';
        case DEPOSIT_STATE_CLOSED_REQUESTED:
            return 'Close Requested';
        case DEPOSIT_STATE_CLOSED:
            return 'Closed';
        case DEPOSIT_STATE_DISPUTED:
        case DEPOSIT_STATE_DISPUTE_SPLIT_PROPOSED:
        case DEPOSIT_STATE_MEDIATOR_INVOLVED_DISPUTE:
        case DEPOSIT_STATE_MEDIATOR_SPLIT_PROPOSED:
            return 'Disputed';
        case DEPOSIT_STATE_CANCELLED:
            return 'Cancelled';
        default:
            return 'Deposit';
    }
}

export function getDepositStateColor(depositState){
    switch (depositState) {
        case DEPOSIT_STATE_PENDING:
            return PENDING_COLOR;
        case DEPOSIT_STATE_ACTIVE:
            return ACTIVE_COLOR;
        case DEPOSIT_STATE_CLOSED_REQUESTED:
            return CLOSE_REQUESTED_COLOR;
        case DEPOSIT_STATE_CLOSED:
            return CLOSED_COLOR;
        case DEPOSIT_STATE_MEDIATOR_INVOLVED_DISPUTE:
        case DEPOSIT_STATE_MEDIATOR_SPLIT_PROPOSED:
            return DISPUTE_MEDIATOR_INVOLVED_COLOR;
        case DEPOSIT_STATE_DISPUTED:
        case DEPOSIT_STATE_DISPUTE_SPLIT_PROPOSED:
            return DISPUTED_COLOR;
        case DEPOSIT_STATE_CANCELLED:
            return CANCELLED_COLOR;
        default:
            return '#C9EFFF';
    }
}

export function getDepositStateColorMuted(depositState){
    //return getDepositStateColor(depositState);

    // TODO: Clean this up
    switch (depositState) {
        case DEPOSIT_STATE_PENDING:
            return PENDING_COLOR_MUTED;
        case DEPOSIT_STATE_ACTIVE:
            return ACTIVE_COLOR_MUTED;
        case DEPOSIT_STATE_CLOSED_REQUESTED:
            return CLOSE_REQUESTED_COLOR_MUTED;
        case DEPOSIT_STATE_CLOSED:
            return CLOSED_COLOR_MUTED;
        case DEPOSIT_STATE_MEDIATOR_INVOLVED_DISPUTE:
        case DEPOSIT_STATE_MEDIATOR_SPLIT_PROPOSED:
            return DISPUTED_COLOR_MUTED;
        case DEPOSIT_STATE_DISPUTED:
        case DEPOSIT_STATE_DISPUTE_SPLIT_PROPOSED:
            return DISPUTED_COLOR_MUTED;
        case DEPOSIT_STATE_CANCELLED:
            return CANCELLED_COLOR_MUTED;
        default:
            return '#C9EFFF';
    }
}

/*export function getDepositStateDescription(depositState){
    switch (depositState) {
        case DEPOSIT_STATE_PENDING:
            return 'Pending - Waiting for the deposit invite to be sent or to be accepted';
        case DEPOSIT_STATE_ACTIVE:
            return 'Active - there is nothing that needs to be done until you want to end the deposit';
        case DEPOSIT_STATE_CLOSED_REQUESTED:
            return 'Close Requested - A user has requested to close this deposit';
        case DEPOSIT_STATE_CLOSED:
            return 'Closed - This deposit has ended';
        case DEPOSIT_STATE_MEDIATOR_INVOLVED_DISPUTE:
        case DEPOSIT_STATE_MEDIATOR_SPLIT_PROPOSED:
            return 'This deposit is disputed and a mediator is involved';
        case DEPOSIT_STATE_DISPUTED:
        case DEPOSIT_STATE_DISPUTE_SPLIT_PROPOSED:
            return 'This deposit is disputed';
        case DEPOSIT_STATE_CANCELLED:
            return 'Cancelled - The deposit was no longer needed';
        default:
            return '';
    }
}*/

export function getDepositDisplayIcon(depositState){
    switch (depositState) {
        case DEPOSIT_STATE_PENDING:
            return DEPOSIT_STATE_ICON_PENDING;
        case DEPOSIT_STATE_ACTIVE:
            return DEPOSIT_STATE_ICON_ACTIVE;
        case DEPOSIT_STATE_CLOSED_REQUESTED:
            return DEPOSIT_STATE_ICON_CLOSED_REQUESTED;
        case DEPOSIT_STATE_CLOSED:
            return DEPOSIT_STATE_ICON_CLOSED;
        case DEPOSIT_STATE_DISPUTED:
            return DEPOSIT_STATE_ICON_DISPUTED;
        case DEPOSIT_STATE_DISPUTE_SPLIT_PROPOSED:
            return DEPOSIT_STATE_ICON_DISPUTE_SPLIT_PROPOSED;
        case DEPOSIT_STATE_MEDIATOR_INVOLVED_DISPUTE:
            return DEPOSIT_STATE_ICON_MEDIATOR_INVOLVED_DISPUTE;
        case DEPOSIT_STATE_MEDIATOR_SPLIT_PROPOSED:
            return DEPOSIT_STATE_ICON_MEDIATOR_SPLIT_PROPOSED;
        case DEPOSIT_STATE_CANCELLED:
            return DEPOSIT_STATE_ICON_CANCELLED;
        default:
            return CIRCLE_ICON;
    }
}

// TODO: Clean this up
export function formatPhoneNumber(phoneNumberString) {
    let returnString = '';
    let m;
    if ((m = rePhoneNumber.exec(phoneNumberString)) !== null) {
        m.forEach((match, groupIndex) => {
            if(match !== undefined && groupIndex !== 0) {
                returnString += match + ' ';
            }
        });
    }
    if(returnString === ''){
        return phoneNumberString;
    }
    return returnString;
}

export function getWithdrawalText(withdrawState){
    switch(withdrawState){
        case WITHDRAW_STATE_PENDING:
            return 'Pending';
        case WITHDRAW_STATE_USER_INFO_NEEDED:
            return 'More Info Needed';
        case WITHDRAW_STATE_PAYMENT_PENDING:
            return 'Payment Pending';
        case WITHDRAW_STATE_PAYMENT_COMPLETE:
            return 'Payment Complete';
        case WITHDRAW_STATE_CANCELLED:
            return 'Cancelled';
        default:
            return 'Processing';
    }
}