/*
    Link Base
 */

import React from "react";
import Link from "@material-ui/core/Link";
import {history} from "../../../history/history";
import {changePrimaryNavTabAction} from "../../../actions/appActions";
import {connect} from "react-redux";

function LinkBase({url, text, ...props}) {

    return (
        <Link
            onClick={() => {
                props.changePrimaryNavTabAction(url);
                history.push(url);
            }}
            style={{cursor: "pointer"}}
        >
            {text}
        </Link>
    );
}

const mapDispatchToProps = dispatch => ({
    changePrimaryNavTabAction: selected_tab => dispatch(changePrimaryNavTabAction(selected_tab))
});

export default connect(null, mapDispatchToProps)(LinkBase);

