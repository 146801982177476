import React from 'react';
import {ensureFullUUIDValue} from "../../../../clientShared/utilities/uuidUtilities";
import {
    FUNDS_LOCATION_TYPE_BANK_ACCOUNT
} from "../../../../autoGenerated/constants/services/UserAccountsServiceConstants";
import {NULL_UUID_STRING} from "../../../../constants/dataConstants";
import {FundsLocationPop, FundsLocationQuestionMark} from "./FundsLocationPop";
import {useBankAccountLoader} from "../../../../autoGenerated/hooks/resourceLoadingHooks";
import * as Sentry from "@sentry/browser";


const LOCATION_UID_STRIPE_ACCOUNT = 'f0000000-f000-f000-f000-f00000000001';
const LOCATION_UID_PRIORITY_ACCOUNT = 'd0000000-d000-d000-d000-d00000000001';
const LOCATION_MIGRATED_BALANCE = 'a0000000-a000-a000-a000-a00000000001';
const LOCATION_PRE_AUTO_DEPOSIT_INTEREST_PAYMENTS = 'b0000000-b000-b000-b000-b00000000001';
const LOCATION_DEPOSIFY_LOAN = 'c0000000-c000-c000-c000-c00000000001';

const InternalBankAccountDisplay = ({bankAccountUID}) => {
    const {bankAccount, loading, error} = useBankAccountLoader(bankAccountUID);

    if(error){
        return <span>Error loading bank account.</span>;
    }

    if(loading){
        return <span>Loading...</span>;
    }

    return (
        <FundsLocationPop
            displayText={"Bank Account"}
            title={bankAccount.bank_name}
            description={"This is a bank account"}
            dataArray={[
                bankAccount.internal_type,
                bankAccount.nickname,
                bankAccount.account_number,
                bankAccount.routing_number
            ]}
        />
    );
};

const GlobalFundsLocationDisplay = ({sourceObject, uidName= "funds_location_uid", locName = "funds_location"}) => {

    const fundsLocationUID = ensureFullUUIDValue(sourceObject[uidName]);
    const fundsLocation = sourceObject[locName];
    const isBankLocation = fundsLocation === FUNDS_LOCATION_TYPE_BANK_ACCOUNT;

    // console.log("-----", fundsLocationUID, fundsLocation, isBankLocation, LOCATION_UID_STRIPE_ACCOUNT);

    if(fundsLocationUID === LOCATION_UID_STRIPE_ACCOUNT){
        return (
            <FundsLocationPop
                displayText={"Stripe"}
                title={"Stripe"}
                description={"Stripe payment processing holding account, used for collected payments"}
                dataArray={[fundsLocationUID]}
            />
        )
    }

    if(fundsLocationUID === LOCATION_DEPOSIFY_LOAN){
        return (
            <FundsLocationPop
                displayText={"Loan"}
                title={"Loaned Funds"}
                description={"These funds were loaned to this user by Deposify. Funds location to be set by the deposit it's linked to."}
                dataArray={[fundsLocationUID]}
            />
        )
    }

    if(fundsLocationUID === LOCATION_UID_PRIORITY_ACCOUNT){
        return (
            <FundsLocationPop
                displayText={"Priority ACH"}
                title={"Priority ACH"}
                description={"Priority ACH payment processor account, used for collected payments"}
                dataArray={[fundsLocationUID]}
            />
        )
    }

    if(fundsLocationUID === LOCATION_PRE_AUTO_DEPOSIT_INTEREST_PAYMENTS){
        return (
            <FundsLocationPop
                displayText={"Special Interest Location"}
                title={"Special Interest Location"}
                description={"Set when credit is applied directly to a user account by an admin, location to be determined by the deposit linked to credit."}
                dataArray={[fundsLocationUID]}
            />
        )
    }

    if(fundsLocationUID === LOCATION_MIGRATED_BALANCE){
        return (
            <FundsLocationPop
                displayText={"Migrated Location"}
                title={"Not Set"}
                description={"This funds location was migrated from old Deposify system. Please see the Deposify documentation."}
                dataArray={[fundsLocationUID]}
            />
        )
    }

    if(fundsLocationUID === NULL_UUID_STRING && isBankLocation){
        return (
            <FundsLocationPop
                displayText={"Not Set"}
                title={"Not Set"}
                description={"No location has been set, this should be audited and updated as necessary."}
                dataArray={[fundsLocationUID]}
            />
        )
    }

    if(isBankLocation){
        return <InternalBankAccountDisplay bankAccountUID={fundsLocationUID}/>;
    }

    Sentry.withScope(scope => {
        scope.setExtra("fundsLocationUID", fundsLocationUID);
        scope.setExtra("fundsLocation", fundsLocation);
        Sentry.captureMessage('Unknown funds location');
    });

    return (
        <div>
            <FundsLocationPop
                title={"Unknown!"}
                description={"Unknown funds location, tell someone that you've seen this."}
                dataArray={["The admin display is probably out of date", fundsLocationUID, fundsLocation]}
            >
                Unknown location!
            </FundsLocationPop>
        </div>
    );
};

export default GlobalFundsLocationDisplay;