/*

 */

import React from 'react';
import CopyTextDataLink from "./copyDataLink";
import {uuidToHex} from "../../../../utilities/commonUtils";


export function CopyUIDLink({uid, linkText = 'Copy'}) {

    const hexUID = uuidToHex(uid);

    return <CopyTextDataLink dataToCopy={hexUID} linkText={linkText}/>;
}

