// Name: Payments Body Render Definitions 

export const LOAN_APPLICATION_BODY_RENDER_DEF = {
    state: {displayName: "State"},
    amount_in_cents: {displayName: "Amount In Cents"},
    deposify_tags: {displayName: "Deposify Tags"},
    application_uid: {displayName: "Application UID"},
    deposit_uid: {displayName: "Deposit UID"},
    account_uid: {displayName: "Account UID"},
    notes: {displayName: "Notes"},
    approved_loan_info_uid: {displayName: "Approved Loan Info UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const LOAN_SYSTEM_INFO_BODY_RENDER_DEF = {
    loans_are_auto_approved: {displayName: "Loans Are Auto Approved"},
    accepted_count: {displayName: "Accepted Count"},
    use_cut_off_total_checks: {displayName: "Use Cut Off Total Checks"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    loans_are_disabled: {displayName: "Loans Are Disabled"},
    loan_info_uid: {displayName: "Loan Info UID"},
    cut_off_total_in_cents: {displayName: "Cut Off Total In Cents"},
    total_in_cents: {displayName: "Total In Cents"},
    created_timestamp: {displayName: "Created Timestamp"},
    rejected_count: {displayName: "Rejected Count"}
};

export const PAYMENT_REQUEST_BODY_RENDER_DEF = {
    request_uid: {displayName: "Request UID"},
    amount_in_cents: {displayName: "Amount In Cents"},
    state: {displayName: "State"},
    description: {displayName: "Description"},
    resource_uid: {displayName: "Resource UID"},
    resource_type: {displayName: "Resource Type"},
    account_uid: {displayName: "Account UID"},
    marked_as_processed_timestamp: {displayName: "Marked As Processed Timestamp"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const PRIORITY_ACH_PAYMENT_BODY_RENDER_DEF = {
    account_type: {displayName: "Account Type"},
    // payment_request_uid: {displayName: "Payment Request UID"},
    routing_number: {displayName: "Routing Number"},
    payment_cleared_into_deposify_bank_account: {displayName: "Payment Cleared"},
    clear_into_funds_location_uid: {displayName: "Clear Into Funds Location UID"},
    clear_into_funds_location: {displayName: "Clear Into Funds Location"},
    admin_review_complete: {displayName: "Admin Review Complete"},
    fees_total_in_cents: {displayName: "Fees Total In Cents"},
    payment_succeeded: {displayName: "Payment Succeeded"},
    name_on_account: {displayName: "Name On Account"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    admin_review_account_uid: {displayName: "Admin Review Account UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    admin_review_timestamp: {displayName: "Admin Review Timestamp"},
    priority_return_payload: {displayName: "Priority Return Payload"},
    account_number: {displayName: "Account Number"},
    payment_uid: {displayName: "Payment UID"},
    total_payment_in_cents: {displayName: "Total Payment In Cents"},
    account_uid: {displayName: "Account UID"},
    state: {displayName: "State"},
    deposify_tags: {displayName: "Deposify Tags"},
    flagged_for_admin_review: {displayName: "Flagged For Admin Review"},
    // notes: {displayName: "Notes"}
};

export const STRIPE_PAYMENT_LOG_BODY_RENDER_DEF = {
    payment_log_uid: {displayName: "Payment Log UID"},
    amount_in_cents: {displayName: "Amount In Cents"},
    fees_total_in_cents: {displayName: "Fees Total In Cents"},
    payment_succeeded: {displayName: "Payment Succeeded"},
    payment_cleared_into_deposify_bank_account: {displayName: "Payment Cleared"},
    clear_into_funds_location_uid: {displayName: "Clear Into Funds Location UID"},
    clear_into_funds_location: {displayName: "Clear Into Funds Location"},
    payment_request_uid: {displayName: "Payment Request UID"},
    high_risk_payment: {displayName: "High Risk Payment"},
    account_uid: {displayName: "Account UID"},
    flagged_for_admin_review: {displayName: "Flagged For Admin Review"},
    admin_review_complete: {displayName: "Admin Review Complete"},
    resource_uid: {displayName: "Resource UID"},
    resource_type: {displayName: "Resource Type"},
    admin_review_account_uid: {displayName: "Admin Review Account UID"},
    admin_review_timestamp: {displayName: "Admin Review Timestamp"},
    stripe_charge_payload: {displayName: "Stripe Charge Payload"},
    stripe_token_payload: {displayName: "Stripe Token Payload"},
    stripe_error_info: {displayName: "Stripe Error Info"},
    description: {displayName: "Description"},
    deposify_tags: {displayName: "Deposify Tags"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const USER_WITHDRAW_BODY_RENDER_DEF = {
    user_bank_account_type: {displayName: "User Bank Account Type"},
    state: {displayName: "State"},
    amount_in_cents: {displayName: "Amount In Cents"},
    funds_lock_uid: {displayName: "Funds Lock UID"},
    user_bank_account_uid: {displayName: "User Bank Account UID"},
    deposify_tags: {displayName: "Deposify Tags"},
    account_uid: {displayName: "Account UID"},
    show_user_payment_verification: {displayName: "Show User Payment Verification"},
    notes: {displayName: "Notes"},
    user_visible_payment_verification: {displayName: "User Visible Payment Verification"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    withdraw_uid: {displayName: "Withdraw UID"}
};

export const INVOICE_BODY_RENDER_DEF = {
    state: {displayName: "State"},
    description: {displayName: "Description"},
    resource_type: {displayName: "Resource Type"},
    invoice_uid: {displayName: "Invoice UID"},
    deposify_tags: {displayName: "Deposify Tags"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    funds_location: {displayName: "Funds Location"},
    funds_location_uid: {displayName: "Funds Location UID"},
    title: {displayName: "Title"},
    resource_uid: {displayName: "Resource UID"},
    invoice_item_uids: {displayName: "Invoice Item UIDs"},
    total_left_to_pay_in_cents: {displayName: "Total Left To Pay In Cents"},
    total_in_cents: {displayName: "Total In Cents"},
    created_timestamp: {displayName: "Created Timestamp"},
    recipient_account_uid: {displayName: "Recipient Account UID"}
};

export const INVOICE_ITEM_BODY_RENDER_DEF = {
    item_state: {displayName: "Item State"},
    funds_lock_uid: {displayName: "Funds Lock UID"},
    description: {displayName: "Description"},
    invoice_uid: {displayName: "Invoice UID"},
    cost_in_cents: {displayName: "Cost In Cents"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    resource_uid: {displayName: "Resource UID"},
    title: {displayName: "Title"},
    created_timestamp: {displayName: "Created Timestamp"},
    resource_type: {displayName: "Resource Type"},
    invoice_item_uid: {displayName: "Invoice Item UID"}
};

export const USER_ACCOUNT_PAYMENT_PROVIDER_INFO_BODY_RENDER_DEF = {
    notes_uid: {displayName: "Notes UID"},
    stripe_customer_id: {displayName: "Stripe Customer Id"},
    account_uid: {displayName: "Account UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const STRIPE_WEBHOOK_LOG_BODY_RENDER_DEF = {
    notes_uid: {displayName: "Notes UID"},
    event_id: {displayName: "Event Id"},
    log_uid: {displayName: "Log UID"},
    process_after_timestamp: {displayName: "Process After Timestamp"},
    ignored: {displayName: "Ignored"},
    customer_id: {displayName: "Customer Id"},
    event_json: {displayName: "Event Json"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    event_type: {displayName: "Event Type"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    processed: {displayName: "Processed"},
    processed_info: {displayName: "Processed Info"}
};

export const PRIORITY_ACH_RECURRING_PAYMENT_BODY_RENDER_DEF = {
    notes_uid: {displayName: "Notes UID"},
    routing_number: {displayName: "Routing Number"},
    account_type: {displayName: "Account Type"},
    next_payment_timestamp: {displayName: "Next Payment Timestamp"},
    state: {displayName: "State"},
    account_uid: {displayName: "Account UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_ach_payment_uid: {displayName: "Last Ach Payment UID"},
    deposify_tags: {displayName: "Deposify Tags"},
    loan_uid: {displayName: "Loan UID"},
    name_on_account: {displayName: "Name On Account"},
    amount_in_cents: {displayName: "Amount In Cents"},
    recurring_payment_uid: {displayName: "Recurring Payment UID"},
    account_number: {displayName: "Account Number"},
    last_payment_timestamp: {displayName: "Last Payment Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};


export const LOAN_HISTORY_BODY_RENDER_DEF = {
    notes_uid: {displayName: "Notes UID"},
    history_uid: {displayName: "History UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    loan_uid: {displayName: "Loan UID"},
    payload_json: {displayName: "Payload Json"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    entry_type: {displayName: "Entry Type"}
};

export const LOAN_PAYMENT_LOG_BODY_RENDER_DEF = {
    notes_uid: {displayName: "Notes UID"},
    stripe_invoice_id: {displayName: "Stripe Invoice Id"},
    log_uid: {displayName: "Log UID"},
    period_index: {displayName: "Period Index"},
    account_uid: {displayName: "Account UID"},
    created_timestamp: {displayName: "Created Timestamp"},
    amount_paid_in_cents: {displayName: "Amount Paid In Cents"},
    deposify_tags: {displayName: "Deposify Tags"},
    loan_uid: {displayName: "Loan UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const LOAN_BODY_RENDER_DEF = {
    notes_uid: {displayName: "Notes UID"},
    payment_failed_email_count: {displayName: "Payment Failed Email Count"},
    payment_period_count_for_loan: {displayName: "Payment Period Count For Loan"},
    stripe_subscription_id: {displayName: "Stripe Subscription Id"},
    loan_uid: {displayName: "Loan UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    paid_to_date_in_cents: {displayName: "Paid To Date In Cents"},
    loan_application_uid: {displayName: "Loan Application UID"},
    deposit_uid: {displayName: "Deposit UID"},
    total_amount_in_cents_per_period: {displayName: "Total Amount In Cents Per Period"},
    state: {displayName: "State"},
    priority_ach_recurring_payment_uid: {displayName: "Priority Ach Recurring Payment UID"},
    payment_period_type: {displayName: "Payment Period Type"},
    account_uid: {displayName: "Account UID"},
    last_payment_failed_email_sent_timestamp: {displayName: "Last Payment Failed Email Sent Timestamp"},
    most_recent_stripe_charge_id: {displayName: "Most Recent Stripe Charge Id"},
    most_recent_stripe_invoice_id: {displayName: "Most Recent Stripe Invoice Id"},
    stripe_subscription_deleted: {displayName: "Stripe Subscription Deleted"},
    fees_total_amount_in_cents: {displayName: "Fees Total Amount In Cents"},
    stripe_payment_method_id: {displayName: "Stripe Payment Method Id"},
    attached_files_uid: {displayName: "Attached Files UID"},
    last_payment_timestamp: {displayName: "Last Payment Timestamp"},
    payment_provider_type: {displayName: "Payment Provider Type"},
    payment_type: {displayName: "Payment Type"},
    deposit_amount_in_cents: {displayName: "Deposit Amount In Cents"},
    fees_total_amount_in_cents_per_period: {displayName: "Fees Total Amount In Cents Per Period"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    stripe_subscription_deleted_timestamp: {displayName: "Stripe Subscription Deleted Timestamp"},
    stripe_subscription_auto_cancel_timestamp: {displayName: "Stripe Subscription Auto Cancel Timestamp"},
    total_amount_in_cents: {displayName: "Total Amount In Cents"},
    completed_payment_period_count: {displayName: "Completed Payment Period Count"}
};


export const SEZZLE_ORDER_BODY_RENDER_DEF = {
    sezzle_session_uid: {displayName: "Sezzle Session UID"},
    cancelled_timestamp: {displayName: "Cancelled Timestamp"},
    state: {displayName: "State"},
    account_credited: {displayName: "Account Credited"},
    amount_in_cents: {displayName: "Amount In Cents"},
    account_uid: {displayName: "Account UID"},
    reference_uid: {displayName: "Reference UID"},
    checkout_url: {displayName: "Checkout Url"},
    paid_timestamp: {displayName: "Paid Timestamp"},
    resource_type: {displayName: "Resource Type"},
    fees_in_cents: {displayName: "Fees In Cents"},
    deposify_tags: {displayName: "Deposify Tags"},
    refunded_timestamp: {displayName: "Refunded Timestamp"},
    notes_uid: {displayName: "Notes UID"},
    fee_reference: {displayName: "Fee Reference"},
    sezzle_order_uid: {displayName: "Sezzle Order UID"},
    resource_uid: {displayName: "Resource UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    created_timestamp: {displayName: "Created Timestamp"}
};

export const SEZZLE_WEBHOOK_EVENT_BODY_RENDER_DEF = {
    event_uid: {displayName: "Event UID"},
    processed_info: {displayName: "Processed Info"},
    event_json: {displayName: "Event Json"},
    event: {displayName: "Event"},
    data_type: {displayName: "Data Type"},
    data_uid: {displayName: "Data UID"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    processed: {displayName: "Processed"},
    created_timestamp: {displayName: "Created Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
    ignored: {displayName: "Ignored"},
    notes_uid: {displayName: "Notes UID"}
};
