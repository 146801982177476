import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import BooleanTypeRender from "../../clientShared/components/types/BooleanTypeRender";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import {ACTIVE_COLOR_MUTED} from "../../constants/dataConstants";


export default function PaymentAdminReviewStatus({paymentObject, isACH}) {

    if (paymentObject.flagged_for_admin_review === false) {
        return <BooleanTypeRender boolValue={false} invertColours={true}/>;
    }

    if (paymentObject.flagged_for_admin_review === true && paymentObject.admin_review_complete === true) {
        return (
            <Tooltip title={'Admin review is complete'} placement={'top'}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        Yes
                    </Grid>

                    <Grid item>
                        <FontAwesomeIcon icon={faCheck} fixedWidth color={ACTIVE_COLOR_MUTED} />
                    </Grid>
                </Grid>
            </Tooltip>
        );
    }

    return <BooleanTypeRender boolValue={paymentObject.flagged_for_admin_review} invertColours={true}/>;
}
