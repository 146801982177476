import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AdminSendEmailVerificationButton from "./AdminSendEmailVerificationButton";
import {DEPOSIFY_TAG_ID_RHINO_DISABLE_EMAILS} from "../tags/DeposifyTags";
import Grid from "@material-ui/core/Grid";
import AdminVerifyEmailButton from "./AdminVerifyEmailButton";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


const AdminVerificationInfo = ({userAccount}) => {
    const classes = useStyles();
    const rhinoEmails = userAccount.deposify_tags.includes(DEPOSIFY_TAG_ID_RHINO_DISABLE_EMAILS) === true;

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant="h5" component="h2">
                    Email Verification
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    User has not yet verified their email
                </Typography>

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    <Grid item>
                        <AdminSendEmailVerificationButton
                            accountUID={userAccount.account_uid}
                            buttonText={"Send Link Via Email"}
                            disabled={rhinoEmails}
                        />
                    </Grid>
                    <Grid item>
                        &nbsp;&nbsp;
                    </Grid>
                    <Grid item>
                        <AdminVerifyEmailButton
                            accountUID={userAccount.account_uid}
                        />
                    </Grid>
                </Grid>


            </CardContent>
        </Card>
    );
};

export default AdminVerificationInfo;