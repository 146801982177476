import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    appActionSetFullScreenDepositDetails
} from "../../../../actions/appActions";
import Link from "@material-ui/core/Link";
import {NULL_UUID_STRING} from "../../../../constants/dataConstants";
import {uuidToHex} from "../../../../utilities/commonUtils";
import AdminSmallDepositDisplay from "../../displays/adminSmallDepositDisplay";
import {DepositContainer} from "../../../../autoGenerated/components/containers/resourceContainers";

function AdminViewFullScreenDepositLink({depositUID, linkText = 'View', showUID = false, showBasicInfo=false, ...props}) {

    if(depositUID === NULL_UUID_STRING){
        return <React.Fragment>None</React.Fragment>;
    }

    if(showBasicInfo === true){
        return (
            <Link
                component="button"
                onClick={linkClicked}
            >
                <DepositContainer depositUID={depositUID}>
                    <AdminSmallDepositDisplay/>
                </DepositContainer>
            </Link>
        );
    }

    return (
        <Link
            component="button"
            onClick={linkClicked}
        >
            {linkText}
            {showUID === true && <React.Fragment>&nbsp;{uuidToHex(depositUID)}</React.Fragment>}
        </Link>
    );

    function linkClicked() {
        props.appActionSetFullScreenDepositDialogDetails(
            {
                showDialog: true,
                depositUID: depositUID
            }
        );
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        appActionSetFullScreenDepositDialogDetails: appActionSetFullScreenDepositDetails
    }, dispatch);
};

export default connect(null, mapActionsToProps)(AdminViewFullScreenDepositLink);