/*
    Payment Cleared / Interest controls
 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import * as Yup from "yup";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import DepositsAdminService from "../../../api/depositsAdminService";
import {
    RESOURCE_TYPE_DEPOSIT_INVITE
} from "../../../autoGenerated/constants/resourceTypeConstants";
import {resourceActionUpdateSingleResource} from "../../../clientShared/actions/resourceActions";
import {
    DEPOSIT_STATE_PENDING
} from "../../../autoGenerated/constants/services/DepositsServiceConstants";
import {Form, Formik} from "formik";
import Dialog from "@material-ui/core/Dialog";
import {YUP_SCHEMA_EMAIL_ADDRESS} from "../../../utilities/yupCommonValidationSchemas";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {EmailAddressField} from "../../utilities/forms/CommonFormFields";
import DialogActions from "@material-ui/core/DialogActions";
import CancelButton from "../../utilities/buttons/CancelButton";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";
import {faEnvelope} from "@fortawesome/pro-regular-svg-icons";
import toast from "react-hot-toast";

const EmailInviteSchema = Yup.object().shape({
    ...YUP_SCHEMA_EMAIL_ADDRESS
});

function DepositInviteAdminButtonChangeEmail({deposit, ...props}) {

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    if(deposit.state !== DEPOSIT_STATE_PENDING){
        return <React.Fragment/>;
    }

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={handleButtonClick}
                variant={'contained'}
            >
                <FontAwesomeIcon
                    icon={faEnvelope}
                    fixedWidth
                />
                &nbsp;Change Invite Email
            </Button>

            <Dialog
                open={showConfirm}
                onClose={cancelConfirm}
                aria-labelledby="deposit-invite-form-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{'email_address': ''}}
                    initialStatus={{}}
                    // validationSchema={props.depositDialogDetails.inviteMode === DEPOSIT_INVITE_DIALOG_MODE_EMAIL ? EmailInviteSchema : PhoneNumberInviteSchema}
                    validationSchema={EmailInviteSchema}
                    render={formikProps => (
                        <Form>
                            <DialogTitle id="deposit-invite-form-dialog-title">
                                Send Deposit Invite
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Send deposit invite via email
                                </DialogContentText>
                                {EmailAddressField(formikProps)}
                            </DialogContent>

                            <DialogActions>
                                <CancelButton
                                    buttonText={'Cancel'}
                                    handleCloseFunction={cancelConfirm}
                                    disabled={showSpinner}
                                />

                                <SpinnerButton
                                    // Non-button props
                                    showSpinner={showSpinner}
                                    // Button props
                                    disabled={showSpinner || !formikProps.isValid}
                                    variant={'contained'}
                                    color={'primary'}
                                    type={'submit'}
                                >
                                    Send Invite
                                </SpinnerButton>
                            </DialogActions>
                        </Form>
                    )}
                />
            </Dialog>

        </React.Fragment>
    );

    function handleButtonClick() {
        setShowSpinner(false);
        setShowConfirm(true);
    }

    function handleSubmit(formValues) {
        setShowSpinner(true);
        DepositsAdminService.InviteEmailToDeposit(deposit.deposit_uid, formValues.email_address)
            .then(function (response) {
                toast.success('Invite updated');
                setShowConfirm(false);
                setTimeout(() => {
                    props.resourceActionUpdateSingleResource(response.deposit_invite, RESOURCE_TYPE_DEPOSIT_INVITE);
                }, 500);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }

    function cancelConfirm() {
        setShowConfirm(false);
    }


}

const mapStateToProps = (state) => {
    // const _adminMakeSystemStatsSelector = adminMakeSystemStatsSelector();

    return function realMapState(state, props) {
        return {
            // systemStats: _adminMakeSystemStatsSelector(state, props.statsID)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(DepositInviteAdminButtonChangeEmail);
