/*

 */

import {createSelector} from 'reselect'

export const loadedDataSelector = state => state.generalLoader.loadedData;

export const uniqueIDSelector = (state, uniqueID) => uniqueID;

export const makeGeneralLoaderSelector = () => createSelector(
    [loadedDataSelector, uniqueIDSelector],
    (loadedData, uniqueID) => loadedData[uniqueID]
);

export const GeneralLoaderSelector = createSelector(
    [loadedDataSelector, uniqueIDSelector],
    (loadedData, uniqueID) => loadedData[uniqueID]
);