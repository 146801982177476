/*

 */

import {createSelector} from 'reselect'


export const loadingIndicatorsSelector = state => state.loading.loadingIndicators;
export const lastLoadTimestampsSelector = state => state.loading.lastLoadTimestamps;

// Loading key is usually string_resource_uid
// But it can be anything, loading indicator just uses it as a key for lookup
export const loadingKeySelector = (state, loadingKey) => loadingKey;

export const newLoadingIndicatorSelector = createSelector(
    [loadingIndicatorsSelector, loadingKeySelector],
    (loadingIndicators, loadingKey) => loadingIndicators[loadingKey]
);

export const makeLoadingIndicatorSelector = () => createSelector(
    [loadingIndicatorsSelector, loadingKeySelector],
    (loadingIndicators, loadingKey) => loadingIndicators[loadingKey]
);

export const makeLastLoadTimestampSelector = () => createSelector(
    [lastLoadTimestampsSelector, loadingKeySelector],
    (lastLoadTimestamps, loadingKey) => lastLoadTimestamps[loadingKey]
);
