/*

 */

import React from 'react';
import CopyTextDataLink from "./copyDataLink";
import {uuidToHex} from "../../../../utilities/commonUtils";
import GenericFullScreenButton from "./genericFullScreenButton";
import {NULL_UUID_STRING} from "../../../../constants/dataConstants";
import Grid from "@material-ui/core/Grid";



export function UIDLinkOrCopy({uid, resourceType = undefined, displayText = 'Copy', useCenter = false}) {

    // Null UIDs are used a lot - so just return a string indicating it's not there
    if(uid === NULL_UUID_STRING || !uid)
    {
        return <i>None</i>;
    }

    const hexUID = uuidToHex(uid);
    const justify= useCenter ? "center" : "flex-start";

    if (resourceType !== undefined) {
        return (
            <Grid
                container
                direction="row"
                justify={justify}
                alignItems="center"
            >
                <Grid item>
                    <GenericFullScreenButton linkText={'View'} resourceUID={uid} resourceType={resourceType}/>
                </Grid>
                <Grid item>
                    &nbsp;|&nbsp;
                </Grid>
                <Grid item>
                    {renderCopyLink()}
                </Grid>

            </Grid>
        );
    }

    return renderCopyLink();

    function renderCopyLink() {
        return <CopyTextDataLink dataToCopy={hexUID} linkText={displayText}/>;
    }


}
