import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import {faEyeSlash, faTruckPlow} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    SEARCH_INDEX_DISPLAY_NAMES,
    SEARCH_INDEX_NAME_ALL,
    SEARCH_INDEX_NAME_DEPOSIT_INVITES,
    SEARCH_INDEX_NAME_DEPOSITS, SEARCH_INDEX_NAME_PROPERTIES,
    SEARCH_INDEX_NAME_USER_ACCOUNTS,
    YARDI_VOYAGER_PROPERTY_SEARCH_INDEX_NAME,
    YARDI_VOYAGER_PROPERTY_UNIT_SEARCH_INDEX_NAME,
    YARDI_VOYAGER_RESIDENT_SEARCH_INDEX_NAME
} from "../../../constants/searchConstants";
import {adminActionSetSearchInfo, adminActionSetSearchResults} from "../../../actions/adminActions";
import {adminSearchInfoSelector, adminSearchResultsSelector} from "../../../selectors/adminSelectors";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
    DepositInvitesTable,
    DepositsTable, PropertiesTable,
    UserAccountsTable, YardiVoyagerPropertiesTable, YardiVoyagerPropertyUnitsTable, YardiVoyagerResidentsTable
} from "../../../autoGenerated/components/tables/allObjectTables";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 600,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    resultsRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#36414e',
        margin: 10
    },
    resultsWrapper: {
        margin: 10
    }
}));


function SearchBarResults(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.resultsRoot}>
            <div className={classes.resultsWrapper}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    warp={'nowrap'}
                >
                    {/*<Grid item>*/}
                    {/*    Search results: {props.adminSearchResults.data_uids.length.toString()}*/}
                    {/*</Grid>*/}

                    <Grid item>
                        Searched for: <i>{props.adminSearchInfo.searchQuery}</i>
                    </Grid>

                    <Grid item>
                        <RenderSearchControls/>
                    </Grid>

                    <RenderSearchResultsDict/>

                </Grid>
            </div>
        </Paper>
    );

    // TODO: Rename
    function RenderSearchControls() {
        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                wrap={'nowrap'}
            >
                <Grid item>
                    <Button color="secondary" onClick={clearSearchResults}>
                        <FontAwesomeIcon icon={faTruckPlow} fixedWidth/>&nbsp;Clear Results
                    </Button>
                </Grid>

                <Grid item>
                    <Divider className={classes.divider} orientation="vertical"/>
                </Grid>

                <Grid item>
                    <Button color="secondary" onClick={hideSearchResults}>
                        <FontAwesomeIcon icon={faEyeSlash} fixedWidth/>&nbsp;Hide Results
                    </Button>
                </Grid>


            </Grid>
        );
    }

    function RenderSearchResultsDict() {
        return (
            <div>
                {
                    Object.keys(props.adminSearchResults).map(searchIndexKey => {
                        return (
                            <div>
                                <b>{props.adminSearchResults[searchIndexKey].length.toString()} {SEARCH_INDEX_DISPLAY_NAMES[searchIndexKey]}</b><br/>
                                <RenderResultsTable indexName={searchIndexKey}
                                                    uidsList={props.adminSearchResults[searchIndexKey]}/> <br/>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    function RenderResultsTable({indexName, uidsList}) {
        if (indexName === SEARCH_INDEX_NAME_DEPOSITS) {
            return <DepositsTable depositUIDList={uidsList}/>;
        }

        if (indexName === SEARCH_INDEX_NAME_USER_ACCOUNTS) {
            return <UserAccountsTable accountUIDList={uidsList}/>;
        }

        if (indexName === SEARCH_INDEX_NAME_DEPOSIT_INVITES) {
            return <DepositInvitesTable depositUIDList={uidsList}/>;
        }

        if (indexName === SEARCH_INDEX_NAME_PROPERTIES) {
            return <PropertiesTable propertyUIDList={uidsList}/>;
        }

        if (indexName === YARDI_VOYAGER_RESIDENT_SEARCH_INDEX_NAME){
            return <YardiVoyagerResidentsTable voyagerResidentUIDList={uidsList}/>;
        }

        if (indexName === YARDI_VOYAGER_PROPERTY_SEARCH_INDEX_NAME){
            return <YardiVoyagerPropertiesTable voyagerPropertyUIDList={uidsList}/>;
        }

        if (indexName === YARDI_VOYAGER_PROPERTY_UNIT_SEARCH_INDEX_NAME){
            return <YardiVoyagerPropertyUnitsTable voyagerPropertyUnitUIDList={uidsList}/>;
        }

        return <i>Unknown results type: <b>{indexName}</b></i>;
    }

    function clearSearchResults() {
        props.adminActionSetSearchResults({});
    }

    function hideSearchResults() {
        props.adminActionSetSearchInfo(
            {
                searchQuery: '',
                searchIndex: SEARCH_INDEX_NAME_ALL,
                showSearchResults: false
            }
        );
    }

}

const mapStateToProps = (state) => ({
    adminSearchInfo: adminSearchInfoSelector(state),
    adminSearchResults: adminSearchResultsSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionSetSearchInfo: adminActionSetSearchInfo,
        adminActionSetSearchResults: adminActionSetSearchResults
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(SearchBarResults);
