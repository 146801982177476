// Client type: admin
// Name: User_Accounts Service Custom Render Components

import {
    OBJECT_KEY_ACCOUNT_UID,
    OBJECT_KEY_EMAIL_ADDRESS,
    OBJECT_KEY_FUNDS_LOCATION,
    OBJECT_KEY_STATE,
    USER_ACCOUNT_OBJECT_KEY_BANK_ACCOUNT,
    USER_ACCOUNT_OBJECT_KEY_EMAIL_VERIFIED,
    USER_ACCOUNT_OBJECT_KEY_ROLES
} from "../objects/objectKeys";
import UserAccountRolesDisplay from "../../components/display/services/accounts/UserAccountRolesDisplay";
import UserAccountStateDisplay from "../../components/display/services/accounts/UserAccountStateDisplay";
import UserAccountEmailVerifiedDisplay
    from "../../components/display/services/accounts/UserAccountEmailVerifiedDisplay";
import BankAccountStateSelector from "../../../components/admin/selectors/BankAccountStateSelector";
import UserAccountGhostAndAccountUIDAtom from "../../../components/accounts/UserAccountGhostAndAccountUIDAtom";
import GlobalFundsLocationDisplay from "../../../components/new/services/userAccounts/GlobalFundsLocationDisplay";
import OrgListing from "../../../components/new/services/userAccounts/OrgListing";

export const USER_ACCOUNT_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_ACCOUNT_UID]: {
        dataPropName: 'accountUID',
        renderComp: UserAccountGhostAndAccountUIDAtom,
        passDataObject: false,
        useOnTable: true
    },
    [USER_ACCOUNT_OBJECT_KEY_ROLES]: {
        dataPropName: 'userAccount',
        renderComp: UserAccountRolesDisplay,
        passDataObject: true
    },
    [OBJECT_KEY_STATE]: {
        dataPropName: 'userAccount',
        renderComp: UserAccountStateDisplay,
        passDataObject: true
    },
    [OBJECT_KEY_EMAIL_ADDRESS]: {
        dataPropName: 'userAccount',
        renderComp: UserAccountEmailVerifiedDisplay,
        passDataObject: true
    },
    [USER_ACCOUNT_OBJECT_KEY_EMAIL_VERIFIED]: {
        dataPropName: 'userAccount',
        renderComp: UserAccountEmailVerifiedDisplay,
        passDataObject: true,
        extraProps: {showEmail: false}
    },
    ["organization_uids"]: {
        dataPropName: 'sourceObject',
        renderComp: OrgListing,
        passDataObject: true
    }
};

export const ADMIN_GHOST_USER_ACCOUNT_CUSTOM_RENDER_COMPONENTS = {};

export const ARCHIVED_PENDING_EMAIL_CHANGE_CUSTOM_RENDER_COMPONENTS = {};

export const BALANCE_CHANGE_LOG_CUSTOM_RENDER_COMPONENTS = {};

export const BALANCE_SOURCE_TRANSFER_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_FUNDS_LOCATION]: {
        dataPropName: 'sourceObject',
        renderComp: GlobalFundsLocationDisplay,
        passDataObject: true
    },
    ["funds_destination"]: {
        dataPropName: 'sourceObject',
        renderComp: GlobalFundsLocationDisplay,
        passDataObject: true,
        extraProps: {uidName: "funds_destination_uid", locName: "funds_destination"}
    },
};

export const CREDIT_USER_ACCOUNT_LOG_CUSTOM_RENDER_COMPONENTS = {};

export const EMAIL_VERIFICATION_CUSTOM_RENDER_COMPONENTS = {};

export const EMAIL_VERIFICATION_RECORD_CUSTOM_RENDER_COMPONENTS = {};

export const EXPIRED_PASSWORD_CUSTOM_RENDER_COMPONENTS = {};

export const FUNDS_LOCK_CUSTOM_RENDER_COMPONENTS = {
    // ["resource_type"]: {
    //     dataPropName: 'sourceObject',
    //     renderComp: ResourceUidAndTypeLink,
    //     passDataObject: true,
    // },
};

export const FUNDS_LOCK_RELEASE_LOG_CUSTOM_RENDER_COMPONENTS = {};

export const PASSWORD_CUSTOM_RENDER_COMPONENTS = {};

export const PASSWORD_RESET_CUSTOM_RENDER_COMPONENTS = {};

export const PENDING_EMAIL_CHANGE_CUSTOM_RENDER_COMPONENTS = {};

export const PREVIOUS_EMAIL_ADDRESS_CUSTOM_RENDER_COMPONENTS = {};

export const RELEASED_FUNDS_LOCK_CUSTOM_RENDER_COMPONENTS = {};

export const USER_ACCOUNT_BALANCE_CUSTOM_RENDER_COMPONENTS = {};

export const USER_ACCOUNT_BALANCE_SOURCE_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_FUNDS_LOCATION]: {
        dataPropName: 'sourceObject',
        renderComp: GlobalFundsLocationDisplay,
        passDataObject: true
    },
};

export const USER_ACCOUNT_EXTENDED_INFO_CUSTOM_RENDER_COMPONENTS = {};

export const USER_ACCOUNT_META_DATA_CUSTOM_RENDER_COMPONENTS = {};

export const USER_ACCOUNT_SETTINGS_CUSTOM_RENDER_COMPONENTS = {};

export const USER_BANK_ACCOUNT_DELTA_CUSTOM_RENDER_COMPONENTS = {};

export const US_DOMESTIC_USER_BANK_ACCOUNT_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_STATE]: {
        dataPropName: USER_ACCOUNT_OBJECT_KEY_BANK_ACCOUNT,
        renderComp: BankAccountStateSelector,
        passDataObject: true
    },
};

export const INTERNATIONAL_USER_BANK_ACCOUNT_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_STATE]: {
        dataPropName: USER_ACCOUNT_OBJECT_KEY_BANK_ACCOUNT,
        renderComp: BankAccountStateSelector,
        passDataObject: true
    },
};

export const BANK_ACCOUNT_BALANCE_RECORD_CUSTOM_RENDER_COMPONENTS = {};

export const USER_ACCOUNT_FINALIZATION_CUSTOM_RENDER_COMPONENTS = {};

export const THIRD_PARTY_USER_ACCOUNT_MAPPING_CUSTOM_RENDER_COMPONENTS = {};