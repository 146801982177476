/*
    Common object keys and their display names if you need to render them
 */

// Common
export const OBJECT_KEY_CREATED_TIMESTAMP = 'created_timestamp';
export const OBJECT_KEY_LAST_UPDATED_TIMESTAMP = 'created_timestamp';
export const OBJECT_KEY_STATE = 'state';
export const OBJECT_KEY_ACCOUNT_UID = 'account_uid';
export const OBJECT_KEY_DEPOSIFY_TAGS = 'deposify_tags';
export const OBJECT_KEY_EMAIL_ADDRESS = 'email_address';
export const OBJECT_KEY_FUNDS_LOCATION = 'funds_location';
export const OBJECT_KEY_FUNDS_LOCATION_UID = 'funds_location_uid';

// User account
export const USER_ACCOUNT_OBJECT_KEY_ROLES = 'roles';
export const USER_ACCOUNT_OBJECT_KEY_EMAIL_VERIFIED = 'email_verified';

// export const USER_ACCOUNT_OBJECT_KEY_DEFAULT_ROLE = 'default_role';
export const USER_ACCOUNT_OBJECT_KEY_BANK_ACCOUNT = 'bankAccount';
// Deposit
export const DEPOSIT_OBJECT_KEY = 'deposit';
export const DEPOSIT_OBJECT_KEY_START_DATE = 'start_date';
export const DEPOSIT_OBJECT_KEY_TENANT_ACCOUNT_UID = 'tenant_account_uid';
export const DEPOSIT_OBJECT_KEY_LANDLORD_ACCOUNT_UID = 'landlord_account_uid';
export const DEPOSIT_OBJECT_KEY_COMPLETED_COMPLIANCE = 'completed_compliances';
export const DEPOSIT_OBJECT_KEY_DISABLED_COMPLIANCE = 'disabled_compliances';
export const DEPOSIT_OBJECT_KEY_INTEREST_ENABLED = 'interest_enabled';
export const DEPOSIT_OBJECT_KEY_LANDLORD_HAS_ACCEPTED = 'landlord_has_accepted';
export const DEPOSIT_OBJECT_KEY_TENANT_HAS_ACCEPTED = 'tenant_has_accepted';

// Deposit Invite
export const DEPOSIT_INVITE_OBJECT_KEY_DEPOSIT_INVITE_UID = 'invite_uid';
export const DEPOSIT_INVITE_OBJECT_KEY_INVITING_ACCOUNT_UID = 'inviting_account_uid';
export const DEPOSIT_INVITE_OBJECT_KEY_INVITEE_ACCOUNT_TYPE = 'invitee_account_type';
export const DEPOSIT_INVITE_OBJECT_KEY_USE_EMAIL_ADDRESS = 'use_email_address';
export const DEPOSIT_INVITE_OBJECT_KEY_USE_PHONE_NUMBER = 'use_phone_number';

// Withdrawal
// TODO: This is now duplicating what's in the object info
export const USER_WITHDRAW_OBJECT_KEY = 'userWithdraw';
export const USER_WITHDRAW_OBJECT_KEY_STATE = OBJECT_KEY_STATE;

// Storage
export const STORAGE_OBJECT_KEY_UPLOADING_ACCOUNT_UID = 'uploading_account_uid';

// Payments
export const PAYMENT_OBJECT_KEY_PAYMENT_OBJECT = 'paymentObject';
export const PAYMENT_OBJECT_KEY_FLAGGED_FOR_ADMIN_REVIEW = 'flagged_for_admin_review';
export const PAYMENT_OBJECT_KEY_ADMIN_REVIEW_COMPLETE = 'admin_review_complete';
export const PAYMENT_OBJECT_KEY_PAYMENT_SUCCEEDED = 'payment_succeeded';
export const PAYMENT_OBJECT_KEY_PAYMENT_CLEARED_INTO_DEPOSIFY_BANK_ACCOUNT = 'payment_cleared_into_deposify_bank_account';
export const PAYMENT_OBJECT_KEY_HIGH_RISK_PAYMENT = 'high_risk_payment';
export const PAYMENT_OBJECT_KEY_CLEAR_INTO_FUNDS_LOCATION = 'clear_into_funds_location';

export const PAYMENT_OBJECT_KEY_PAYMENT_CLEARED = 'payment_cleared';

// Messaging
export const MESSAGING_OBJECT_KEY_SENDER_ACCOUNT_UID = 'sender_account_uid';
export const MESSAGING_OBJECT_KEY_RECIPIENT_ACCOUNT_UID = 'recipient_account_uid';
