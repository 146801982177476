// Client type: admin
// Name: Banking Service Custom Render Components

import React from "react";
import BankAccountInternalType from "../../../components/banking/bankingCustomComponents";
import AdminViewFullAccountInfoLink from "../../../components/admin/buttons/linkButtons/adminViewFullAccountInfoLink";

export const BANK_ACCOUNT_CUSTOM_RENDER_COMPONENTS = {
    internal_type: {
        dataPropName: "internalType",
        renderComp: BankAccountInternalType,
        passDataObject: false,
        extraProps: {}
    },
    partner_account_uid: {
        dataPropName: "accountUID",
        renderComp: AdminViewFullAccountInfoLink,
        passDataObject: false,
        extraProps: {}
    }
};

export const BANK_ACCOUNT_INTEREST_CUSTOM_RENDER_COMPONENTS = {};
export const BANK_TRANSFER_CUSTOM_RENDER_COMPONENTS = {};
export const BULK_FUNDS_TRANSFER_CUSTOM_RENDER_COMPONENTS = {};
export const BANK_ACCOUNT_HISTORICAL_INTEREST_RATE_CUSTOM_RENDER_COMPONENTS = {};
export const BANK_ACCOUNT_BALANCE_RECORD_CUSTOM_RENDER_COMPONENTS = {};
