/*
	Mapping of resource types to the primary render component
 */

// Name: Primary Render Components

import {
	RESOURCE_TYPE_USER_ACCOUNT,
	RESOURCE_TYPE_DEPOSIT,
	RESOURCE_TYPE_COMPLIANCE,
	RESOURCE_TYPE_STRIPE_PAYMENT_LOG,
	RESOURCE_TYPE_USER_WITHDRAW,
	RESOURCE_TYPE_PRIORITY_ACH_PAYMENT,
	RESOURCE_TYPE_BANK_ACCOUNT, RESOURCE_TYPE_ONLINE_USER,
} from "../../../autoGenerated/constants/resourceTypeConstants";

import GenericObjectDisplay from "../../../clientShared/components/display/GenericObjectDisplay";
import {UserWithdrawDisplay} from "../../../components/new/services/payments/UserWithdrawDisplay";
import {UserAccountDisplay} from "../../../components/new/services/userAccounts/UserAccountDisplay";
import {DepositDisplay} from "../../../components/new/services/deposits/DepositDisplay";
import {BankAccountDisplay} from "../../../components/banking/BankAccountDisplay";
import {ComplianceDisplay} from "../../../components/deposits/ComplianceDisplay";
import {ACHPaymentDisplay} from "../../../components/payments/ACHPaymentDisplay";
import {StripePaymentDisplay} from "../../../components/payments/StripePaymentDisplay";
import OnlineUserDisplay from "../../../components/OnlineUsers/OnlineUserDisplay";

export const PRIMARY_RENDER_COMPONENTS = {
	[RESOURCE_TYPE_USER_ACCOUNT]: UserAccountDisplay,
	[RESOURCE_TYPE_DEPOSIT]: DepositDisplay,
	[RESOURCE_TYPE_COMPLIANCE]: ComplianceDisplay,
	[RESOURCE_TYPE_STRIPE_PAYMENT_LOG]: StripePaymentDisplay,
	[RESOURCE_TYPE_USER_WITHDRAW]: UserWithdrawDisplay,
	[RESOURCE_TYPE_PRIORITY_ACH_PAYMENT]: ACHPaymentDisplay,
	[RESOURCE_TYPE_BANK_ACCOUNT]: BankAccountDisplay,
	[RESOURCE_TYPE_ONLINE_USER]: OnlineUserDisplay
};

export function getPrimaryRenderComponent(resourceType){
	const returnValue = PRIMARY_RENDER_COMPONENTS[resourceType] || GenericObjectDisplay;
	if(returnValue === undefined){
		throw new Error(`getPrimaryRenderComponent for ${resourceType} failed`);
	}
	return returnValue;
}
