/*
    General Loaded Reducer
 */

import {APP_EVENT_CLEAR_ALL} from "../actions/appActionTypes";
import {GENERAL_LOADER_CLEAR_DATA, GENERAL_LOADER_UPDATE_DATA} from "../actions/GeneralLoaderActionTypes";

//
// Initial State
//
const INITIAL_GENERAL_LOADER_REDUCER_STATE = {
    loadedData: {},
};

//
// Reducer
//
export default function generalLoaderReducer(state = INITIAL_GENERAL_LOADER_REDUCER_STATE, action) {

    switch (action.type) {

        case APP_EVENT_CLEAR_ALL:
            return INITIAL_GENERAL_LOADER_REDUCER_STATE;


        //
        // UPDATE DATA
        //
        case GENERAL_LOADER_UPDATE_DATA:

            const updatedLoadedData = {...state.loadedData, [action.uniqueID]: action.loadedData};
            return {...state, loadedData: updatedLoadedData};


        //
        // CLEAR DATA
        //
        case GENERAL_LOADER_CLEAR_DATA:
            const clearedLoadedData = {...state.loadedData};
            delete clearedLoadedData[action.uniqueID];
            return {...state, loadedData: clearedLoadedData};

        //
        // DEFAULT
        //
        default:
            return state;
    }
};