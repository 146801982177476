/*

 */

import React from 'react';
import {DEPOSIFY_TAG_ID_PLACEHOLDER_USER} from "../../tags/DeposifyTags";
import {faGhost, faMask, faUserSlash} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    standardTag: {
        margin: 10,
        marginLeft: 0,
        color: '#fbfbfb',
        backgroundColor: '#6ddae9',
    },
    badTag: {
        margin: 10,
        marginLeft: 0,
        color: '#fbfbfb',
        backgroundColor: '#e97662',
    },
    bannedAccount: {
        margin: 10,
        marginLeft: 0,
        color: '#fbfbfb',
        backgroundColor: '#a70116',
    },
}));

export default function UserAccountWarningsDisplay({userAccount, ...props}) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <IsBannedAccount userAccount={userAccount} classes={classes} />
            <IsEmailVerified userAccount={userAccount} classes={classes} />
            <IsPlaceholderUser userAccount={userAccount} classes={classes} />
            <HasPendingEmailChange userAccount={userAccount} classes={classes} />
        </React.Fragment>
    );

}

function IsBannedAccount({userAccount, classes}) {
    if (userAccount.state === 'banned') {
        return <RenderBadChip
            chipIcon={faUserSlash}
            chipLabel={'Account Banned'}
            tooltip={'This account has been banned from using the service'}
            className={classes.bannedAccount}
        />
    }

    return <React.Fragment/>;
}

function IsEmailVerified({userAccount, classes}) {
    if (userAccount.email_verified === false) {
        return <RenderBadChip
            chipIcon={faMask}
            chipLabel={'Email Not Verified'}
            tooltip={'Account was created, but email has not yet been verified'}
            className={classes.badTag}
        />
    }

    return <React.Fragment/>;
}

function IsPlaceholderUser({userAccount, classes}) {
    if (userAccount.deposify_tags.includes(DEPOSIFY_TAG_ID_PLACEHOLDER_USER) === true) {
        return <RenderBadChip
            chipIcon={faGhost}
            chipLabel={'Placeholder Account'}
            tooltip={'This is a placeholder account - created during bulk import for a PMC'}
            className={classes.badTag}
        />
    }

    return <React.Fragment/>;
}

function HasPendingEmailChange({userAccount, classes}) {
    if (userAccount.has_pending_email_change === true) {
        return <RenderBadChip
            chipIcon={faGhost}
            chipLabel={'Has Pending Email Change'}
            tooltip={'This user is in the process of changing their email address'}
            className={classes.standardTag}
        />
    }

    return <React.Fragment/>;
}

function RenderBadChip({chipIcon, chipLabel, tooltip, className}) {
    return (
        <Tooltip
            title={tooltip}
            placement={'top'}
        >
            <Chip
                color={'secondary'}
                icon={
                    <FontAwesomeIcon
                        icon={chipIcon}
                        size={'2x'}
                        fixedWidth
                        color={'#FFFFFF'}
                    />
                }
                label={chipLabel}
                className={className}
            />
        </Tooltip>
    );
}
