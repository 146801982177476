import * as Sentry from "@sentry/browser";
import {NULL_UUID_STRING} from "../constants/dataConstants";


export function sentrySetAnonymousUserDetails() {
    Sentry.setUser(
        {
            account_uid: NULL_UUID_STRING,
            username: 'anonymous',
            logged_in: false
        }
    );
}

export function sentrySetCurrentUserDetails(user_account) {
    Sentry.setUser(
        {
            account_uid: user_account.account_uid,
            username: user_account.username,
            logged_in: true
        }
    );
}

// This keeps the last logged in user details, but changed the flag to logged out
export function sentrySetUserLoggedOutDetails(user_account) {
    Sentry.setUser(
        {
            account_uid: user_account.account_uid,
            username: user_account.username,
            logged_in: false
        }
    );
}