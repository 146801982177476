/*

 */


import {APP_EVENT_CLEAR_ALL, APP_EVENT_SET_LOGGED_OUT} from "../clientShared/actions/appActionTypes";

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const AUTH_ACTION_SET_USER_LOGGED_IN = 'AUTH:SET_USER_LOGGED_IN';
export const AUTH_ACTION_SET_AUTH_ACTION_HAPPENING = 'AUTH:SET_AUTH_ACTION_HAPPENING';
export const AUTH_ACTION_SET_AUTH_EXPIRE_TIMESTAMP = 'AUTH:SET_AUTH_EXPIRE_TIMESTAMP';
export const AUTH_ACTION_SET_STARTUP_AUTH_CHECK_COMPLETE = 'AUTH:SET_STARTUP_AUTH_CHECK_COMPLETE';
export const AUTH_ACTION_SET_USER_LOGGING_OUT  = 'AUTH:AUTH_ACTION_SET_USER_LOGGING_OUT';

const initialAuthState = {
    // TODO: Clean up fetching, rename error message
    isFetching: false,
    errorMessage: '',

    startupAuthCheckComplete: false,
    userLoggedIn: false,
    userLoggingOut: false,
    authActionHappening: false,
    authExpireTimestamp: 0
};


export default function authReducer(state = initialAuthState, action) {
    switch (action.type) {
        case APP_EVENT_SET_LOGGED_OUT:
            return {...state, startupAuthCheckComplete: true};


        case APP_EVENT_CLEAR_ALL:
            // Carry the startup over from the old state
            return {...initialAuthState, startupAuthCheckComplete: state.startupAuthCheckComplete};

        // Indicates if there's a user logged into the app
        case AUTH_ACTION_SET_STARTUP_AUTH_CHECK_COMPLETE:
            return {...state, startupAuthCheckComplete: action.startupAuthCheckComplete};

        // Indicates if there's a user logged into the app
        case AUTH_ACTION_SET_USER_LOGGED_IN:
            return {...state, userLoggedIn: action.userLoggedIn};

        // Indicates the user is about to logout
        case AUTH_ACTION_SET_USER_LOGGING_OUT:
            return {...state, userLoggingOut: action.userLoggingOut};

        // Indicates if there's an auth call happening which should prevent other calls
        case AUTH_ACTION_SET_AUTH_ACTION_HAPPENING:
            return {...state, authActionHappening: action.authActionHappening};

        // Set the token expires timestamp
        case AUTH_ACTION_SET_AUTH_EXPIRE_TIMESTAMP:
            return {...state, authExpireTimestamp: action.authExpireTimestamp};

        case LOGIN_FAILURE:
            return {
                ...state,
                isFetching: action.isFetching,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};