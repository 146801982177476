/*
    Resource Reducer
 */

import {APP_EVENT_CLEAR_ALL} from "../actions/appActionTypes";
import {
    RESOURCE_REMOVE_SINGLE_RESOURCE,
    RESOURCE_UPDATE_RESOURCE_UIDS,
    RESOURCE_UPDATE_RESOURCE_UIDS_FOR_OWNER,
    RESOURCE_UPDATE_RESOURCES
} from "../actions/resourceActionTypes";

//
// Initial State
//
const INITIAL_RESOURCE_REDUCER_STATE = {
    resources: {},
    resourceUIDs: {},
    resourceUIDsByOwner: {}
};

//
// Reducer
//
export default function resourceReducer(state = INITIAL_RESOURCE_REDUCER_STATE, action) {

    switch (action.type) {

        case APP_EVENT_CLEAR_ALL:
            return INITIAL_RESOURCE_REDUCER_STATE;


        //
        // RESOURCES
        //
        case RESOURCE_UPDATE_RESOURCES:

            if (action.replaceAll === true) {
                // TODO: Look into nice JS ways of cleaning this up
                let allResources = {};
                action.resourceList.reduce(function (map, dictObject) {
                    allResources[dictObject[action.resourceUIDName]] = dictObject;
                    return allResources;
                }, {});

                const updatedResourcesByType = {...state.resources, [action.resourceType]: allResources};
                return {...state, resources: updatedResourcesByType};
            } else {
                let currentResources = state.resources[action.resourceType] || {};
                action.resourceList.reduce(function (map, dictObject) {
                    currentResources[dictObject[action.resourceUIDName]] = dictObject;
                    return currentResources;
                }, {});

                const updatedResourcesByType = {...state.resources, [action.resourceType]: currentResources};

                return {...state, resources: updatedResourcesByType};
            }

        case RESOURCE_REMOVE_SINGLE_RESOURCE:
            let currentResourcesRemoval = state.resources[action.resourceType] || {};
            delete currentResourcesRemoval[action.resourceUID];

            const updatedResourcesByTypeRem = {...state.resources, [action.resourceType]: currentResourcesRemoval};

            if(action.removeFromUIDList === true) {
                const currentResourceUIDsRemoval = state.resourceUIDs[action.resourceType] || [];
                delete currentResourceUIDsRemoval[action.resourceUID];

                const updatedUIDsByType = {...state.resourceUIDs, [action.resourceType]: currentResourceUIDsRemoval};

                return {...state, resources: updatedResourcesByTypeRem, resourceUIDs: updatedUIDsByType};
            }
            else{
                return {...state, resources: updatedResourcesByTypeRem};
            }

        //
        // RESOURCE UIDS
        //
        case RESOURCE_UPDATE_RESOURCE_UIDS:
            /*
                resourceUIDsByOwner: {resourceType: [], ...}
             */
            if (action.replaceAll === true) {
                const updatedResourcesByType = {
                    ...state.resourceUIDs,
                    [action.resourceType]: action.uidList
                };
                return {...state, resourceUIDs: updatedResourcesByType};
            } else {
                const currentResourceUIDs = state.resourceUIDs[action.resourceType] || [];
                let updateUIDList = currentResourceUIDs.concat(
                    action.uidList.filter((item) => currentResourceUIDs.indexOf(item) < 0)
                );
                const updatedResourcesByType = {
                    ...state.resourceUIDs,
                    [action.resourceType]: updateUIDList
                };
                return {...state, resourceUIDs: updatedResourcesByType};
            }

        //
        // RESOURCE UIDS BY OWNER
        //
        case RESOURCE_UPDATE_RESOURCE_UIDS_FOR_OWNER:
            /*
                resourceUIDsByOwner: {accountUID: {resourceType: [], ...}
             */
            const accountResources = {...state.resourceUIDsByOwner[action.accountUID] || []};
            if (action.replaceAll === true) {
                const updatedAccountResources = {...accountResources, [action.resourceType]: action.uidList};
                const updatedResourcesByOwner = {
                    ...state.resourceUIDsByOwner,
                    [action.accountUID]: updatedAccountResources
                };
                return {...state, resourceUIDsByOwner: updatedResourcesByOwner};
            } else {
                const currentUIDList = accountResources[action.resourceType] || [];
                let updateUIDList = currentUIDList.concat(
                    action.uidList.filter((item) => currentUIDList.indexOf(item) < 0)
                );
                const updatedAccountResources = {...accountResources, [action.resourceType]: updateUIDList};
                const updatedResourcesByOwner = {
                    ...state.resourceUIDsByOwner,
                    [action.accountUID]: updatedAccountResources
                };
                return {...state, resourceUIDsByOwner: updatedResourcesByOwner};
            }

        //
        // DEFAULT
        //
        default:
            return state;
    }
};