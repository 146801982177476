/*

 */

import React, {useEffect} from 'react';
import {axiosInstance} from "../../../utilities/axiosInstance";
import {getAdminPath} from "../../../api/adminAPI";
import {CircularProgress} from "@material-ui/core";
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";

function AdminAccountExistsViaEmailDisplay({emailAddress, ...props}) {

    const [showSpinner, setShowSpinner] = React.useState(true);
    const [accountExists, setAccountExists] = React.useState(false);
    const [accountUID, setAccountUID] = React.useState(undefined);

    useEffect(() => {
        const doVerification = async () => {
            axiosInstance.get(getAdminPath(`accounts/email/${emailAddress}`))
                .then(function (response) {
                    setShowSpinner(false);
                    setAccountExists(true);
                    setAccountUID(response.data.user_account.account_uid);
                })
                .catch(function (error) {
                    setShowSpinner(false);
                })

        };

        doVerification();
    }, [emailAddress]);

    if (showSpinner === true) {
        return <CircularProgress size={12}/>;
    }

    if (accountExists === true) {
        return (
            <React.Fragment>
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    fixedWidth
                    color={'orange'}
                />
                &nbsp;Account Exists
                <br/>
                <AdminViewFullAccountInfoLink accountUID={accountUID}/>
            </React.Fragment>
        );
    }

    return <React.Fragment><i>No account in system</i></React.Fragment>;

}

export default AdminAccountExistsViaEmailDisplay;