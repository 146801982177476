import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Chip from "@material-ui/core/Chip";
import {faReceipt} from "@fortawesome/pro-regular-svg-icons";




export default function PriorityACHPaymentPaidChip({achPayment}) {

    const chipColour = achPayment.payment_succeeded === true ? "primary" : "secondary";
    const chipText = achPayment.payment_succeeded === true ? "Payment Succeeded" : "Payment Failed";

    return (
        <Chip
            color={chipColour}
            icon={
                <FontAwesomeIcon
                    icon={faReceipt}
                    fixedWidth
                />
            }
            label={chipText}
        />
    );
}
