// User Withdrawal states
export const USER_WITHDRAW_STATE_PENDING = 'pending';
export const USER_WITHDRAW_STATE_USER_INFO_NEEDED = 'user_info_needed';
export const USER_WITHDRAW_STATE_PAYMENT_PENDING = 'payment_pending';
export const USER_WITHDRAW_STATE_PAYMENT_COMPLETE = 'payment_complete';
export const USER_WITHDRAW_STATE_CANCELLED = 'cancelled';


export const USER_WITHDRAWALS_STATES_DICT = {
    [USER_WITHDRAW_STATE_PENDING]: 'Pending',
    [USER_WITHDRAW_STATE_USER_INFO_NEEDED]: 'User Info Needed',
    [USER_WITHDRAW_STATE_PAYMENT_PENDING]: 'Payment Made',
    [USER_WITHDRAW_STATE_PAYMENT_COMPLETE]: 'Payment Complete',
    [USER_WITHDRAW_STATE_CANCELLED]: 'Cancelled'
};



export const PAYMENT_TYPE_STRIPE = 'stripe';
export const PAYMENT_TYPE_PRIORITY_ACH = 'priority_ach';


export const PRIORITY_ACH_PAYMENT_STATE_AWAITING_CLEARANCE = 'awaiting_clearance';
export const PRIORITY_ACH_PAYMENT_STATE_CLEARED = 'cleared';
export const PRIORITY_ACH_PAYMENT_STATE_DID_NOT_CLEAR = 'did_not_clear';
export const PRIORITY_ACH_PAYMENT_STATE_PAYMENT_FAILED = 'payment_failed';

export const ALL_PRIORITY_ACH_PAYMENT_STATES = [
    PRIORITY_ACH_PAYMENT_STATE_AWAITING_CLEARANCE,
    PRIORITY_ACH_PAYMENT_STATE_CLEARED,
    PRIORITY_ACH_PAYMENT_STATE_DID_NOT_CLEAR,
    PRIORITY_ACH_PAYMENT_STATE_PAYMENT_FAILED
];
