/*

 */

import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import ErrorInfoDisplay from "../../utilities/displayAreas/ErrorInfoDisplay";

function AdminDisplayUserAccountPassword({loading, error, userAccountPassword, ...props}) {

    if(error === true){
        return (
            <ErrorInfoDisplay showError={true}>
                Failed to load user account password
            </ErrorInfoDisplay>
        );
    }

    if(loading === true || error === true || userAccountPassword === undefined){
        return <VisualizeContainerLoadingIndicator loadingResource={userAccountPassword} displayName={'User Account Password'} {...props} />;
    }

    return (
        <React.Fragment>
            {
                Object.keys(userAccountPassword).map(
                    accountKey => {
                        return (
                            <React.Fragment key={accountKey}>
                                <b>{accountKey}</b>: {userAccountPassword[accountKey].toString()}<br/>
                            </React.Fragment>);
                    }
                )
            }
        </React.Fragment>
    );

}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(AdminDisplayUserAccountPassword);