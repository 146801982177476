/*

 */

import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import {ERROR_ICON} from "../../../constants/commonIcons";
import {makeStyles} from "@material-ui/core";
import {DANGER_BUTTON_COLOR} from "../../../constants/dataConstants";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 60,
        minWidth: 245,
        borderRadius: 10,
        border: '1px solid #e97662',
        backgroundColor: '#FFFFFF'
    },
    iconStyle: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10
    },
    titleStyle: {
        fontWeight: 600,
        fontStyle: 'bold',
        fontSize: 16,
        color: DANGER_BUTTON_COLOR
    },
    messageStyle: {
        fontWeight: 300,
        fontStyle: 'light',
        fontSize: 13,
        margin: 10
    }
}));

export default function ErrorInfoDisplay({showError, title, ...props}) {
    const classes = useStyles();

    if(showError === false){
        return <React.Fragment/>;
    }

    return (
        <React.Fragment>

            <Grid
                container
                direction="column"
                justify="center"
                alignItems="stretch"
            >
                <Grid item>
                    <Paper
                        className={classes.root}
                        elevation={0}
                    >
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="stretch"
                        >
                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="baseline"
                                >
                                    <Grid item className={classes.iconStyle}>
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={ERROR_ICON}
                                            color={'#e97662'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.titleStyle}>
                                            {title === undefined ? 'Error' : title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={classes.messageStyle}>
                                {props.children}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

        </React.Fragment>
    );

}

