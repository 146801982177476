import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ChangeAccountEmailButton from "./ChangeAccountEmailButton";
import ClickToCopy from "../../clientShared/components/utility/ClickToCopy";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


const AdminChangeAccountEmail = ({userAccount}) => {
    const classes = useStyles();

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant="h5" component="h2">
                    Update Email
                </Typography>

                <Typography className={classes.pos} color="textSecondary">
                Current email address: <ClickToCopy dataToCopy={userAccount.email_address}/>
                </Typography>

                <ChangeAccountEmailButton userAccount={userAccount}/>
            </CardContent>
        </Card>
    );
};

export default AdminChangeAccountEmail;