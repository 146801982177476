import {
    DEPOSIT_ACCOUNT_TYPE_LANDLORD,
    DEPOSIT_ACCOUNT_TYPE_TENANT,
    DEPOSIT_STATE_PENDING
} from "../constants/depositConstants";
import React from "react";
import {NULL_UUID_STRING} from "../constants/dataConstants";
import {store} from "../store/appStore";
import {dollarsToCents} from "./commonUtils";

function isLandlord(deposit, accountUID) {
    return accountUID === deposit.landlord_account_uid;
}

function getOtherPartyAccountType(deposit, accountUID) {
    if (isLandlord(deposit, accountUID)) {
        return DEPOSIT_ACCOUNT_TYPE_TENANT;
    } else {
        return DEPOSIT_ACCOUNT_TYPE_LANDLORD;
    }
}

function getOtherPartyAccountUID(deposit, accountUID) {
    if (isLandlord(deposit, accountUID)) {
        return deposit.tenant_account_uid;
    } else {
        return deposit.landlord_account_uid;
    }
}

export function renderOtherPartyName(deposit, depositMD, accountUID) {
    if (deposit === undefined || depositMD === undefined || accountUID === undefined) {
        return <React.Fragment>...</React.Fragment>;
    }

    const otherPartyAccount = getOtherPartyAccountType(deposit, accountUID);
    if (otherPartyAccount === DEPOSIT_ACCOUNT_TYPE_TENANT) {
        // eslint-disable-next-line
        if (depositMD === undefined) {
            return <React.Fragment>...</React.Fragment>;
        }

        if (depositMD.tenant_first_name !== '' && depositMD.tenant_last_name !== '') {
            return (
                <React.Fragment>
                    {depositMD.tenant_first_name}&nbsp;{depositMD.tenant_last_name}
                </React.Fragment>
            );
        }
    }

    const otherPartyAccountUID = getOtherPartyAccountUID();
    if (otherPartyAccountUID === NULL_UUID_STRING) {
        return <React.Fragment/>;
    }

    // Fall back to user name if there's no tenant info
    if (store.user.basicUserAccounts[otherPartyAccountUID] === undefined) {
        return <React.Fragment>Loading..</React.Fragment>
    }

    return (
        <React.Fragment>
            {store.user.basicUserAccounts[otherPartyAccountUID].username}
        </React.Fragment>
    );
}

// TODO: Rename this
export function isInvitedAccount(deposit, accountUID) {
    // If the landlord & tenant account are the same and this user created the deposit, then return True
    if (deposit.created_by_account_uid === deposit.landlord_account_uid &&
        deposit.created_by_account_uid === deposit.tenant_account_uid &&
        deposit.created_by_account_uid === accountUID) {
        return true;
    }
    return accountUID !== deposit.created_by_account_uid;
}

// TODO: Rename this
export function showAcceptReject(deposit, accountUID) {
    if (deposit.state !== DEPOSIT_STATE_PENDING) {
        return false;
    }

    if(deposit.tenant_has_accepted === false && deposit.tenant_account_uid === accountUID){
        return true;
    }

    if(deposit.landlord_has_accepted === false && deposit.landlord_account_uid === accountUID){
        return true;
    }
    
    // // Landlord auto-accepts
    // if (deposit.created_by_account_uid === deposit.landlord_account_uid &&
    //     deposit.created_by_account_uid === accountUID) {
    //
    //     return false;
    // }
    //
    // if (deposit.landlord_account_uid === accountUID) {
    //     return !deposit.landlord_has_accepted;
    // } else {
    //     return !deposit.tenant_has_accepted;
    // }
    return false;
}

// TODO: Rename this
export function showInvite(deposit, accountUID) {
    return deposit.state === DEPOSIT_STATE_PENDING && deposit.created_by_account_uid === accountUID;
}

export function showWaitingForResponse(deposit, accountUID){
    // Only check if it's pending
    if(deposit.state === DEPOSIT_STATE_PENDING){
        if(deposit.tenant_account_uid === accountUID){
            // Current user is tenant and they have accepted and the landlord has yet to accept
            return deposit.tenant_has_accepted === true && deposit.landlord_has_accepted === false;
        }
        else{
            return deposit.landlord_has_accepted === true && deposit.tenant_has_accepted === false;
        }
    }

    return false;
}

export function areLandlordAndTenantTheSame(deposit){
    return deposit.tenant_account_uid === deposit.landlord_account_uid;
}

export function getDepositCloseAmounts(deposit, userAmountInDollars, userIsTenant){
    // In a lot of places we need to get an input amount from the user and then use that to split the deposit
    // This function does that, it takes in a dollar amount for the current user and spits back the split amount
    // in cents for passing to the server

    // Convert the dollar amount to cents and make sure it's valid
    const amountInCents = dollarsToCents(userAmountInDollars);
    if (amountInCents < 0 || amountInCents > deposit.amount_in_cents || isNaN(amountInCents) === true) {
        return false;
    }

    // Setup our tenant and landlord return amounts
    let tenantCloseAmountInCents = 0;
    let landlordCloseAmountInCents = 0;

    // Assign based on the role of this user
    if (userIsTenant) {
        tenantCloseAmountInCents = amountInCents;
        landlordCloseAmountInCents = deposit.amount_in_cents - amountInCents;
    } else {
        tenantCloseAmountInCents = deposit.amount_in_cents - amountInCents;
        landlordCloseAmountInCents = amountInCents;
    }

    // Send it back
    return {
        tenantCloseAmountInCents: tenantCloseAmountInCents,
        landlordCloseAmountInCents: landlordCloseAmountInCents
    }
}

export function isArchivedForCurrentUser(deposit, currentUserAccountUID){
    if(deposit.tenant_account_uid === currentUserAccountUID){
        return deposit.tenant_archived;
    }

    return deposit.landlord_archived;
}

export function userCanEditDeposit(deposit, userAccountUID){
    if(deposit.state !== DEPOSIT_STATE_PENDING){
        return false;
    }

    return deposit.created_by_account_uid === userAccountUID;
}