/*
    App Reducers
 */
import {hasAccessTokenInLocalStorage} from "../utilities/commonUtils";
import {APP_ROUTE_ACCOUNT, APP_ROUTE_FUNDS, APP_ROUTE_HOME, APP_ROUTE_SUPPORT} from "../constants/appRoutes";
import {
    DEPOSIT_LISTING_DISPLAY_MODE_ALL_DEPOSITS,
    DEPOSIT_LISTING_DISPLAY_MODE_SEARCH_RESULTS
} from "../constants/appConstants";

import {
    APP_EVENT_ADD_EXPANDED_DEPOSIT_UID,
    APP_EVENT_ADD_NOTIFICATION,
    APP_EVENT_ADD_SELECTED_DEPOSIT_UID,
    APP_EVENT_CLEAR_SHOW_DEPOSIT_DIALOG,
    APP_EVENT_CLEAR_SHOW_NEW_DEPOSIT_INVITE_DIALOG,
    APP_EVENT_HANDLE_INVOICE_UPDATE,
    APP_EVENT_REMOVE_EXPANDED_DEPOSIT_UID,
    APP_EVENT_REMOVE_NOTIFICATION,
    APP_EVENT_REMOVE_SELECTED_DEPOSIT_UID,
    APP_EVENT_SET_ACCEPT_DEPOSIT_INVITE_DIALOG_DETAILS,
    APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_CLOSE_REQUEST_DIALOG_DETAILS,
    APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_DISPUTE_SPLIT_DIALOG_DETAILS,
    APP_EVENT_SET_ARCHIVE_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_CANCEL_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_CANCEL_WITHDRAWAL_DIALOG_DETAILS,
    APP_EVENT_SET_CLOSE_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_BANK_ACCOUNT_SELECTION_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_OR_EDIT_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_OR_EDIT_INTERNATIONAL_BANK_ACCOUNT_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_OR_EDIT_US_DOMESTIC_BANK_ACCOUNT_DIALOG_DETAILS,
    APP_EVENT_SET_CREATE_OR_EDIT_WITHDRAWAL_DIALOG_DETAILS,
    APP_EVENT_SET_DEPOSIT_FUNDS_DIALOG_DETAILS,
    APP_EVENT_SET_DEPOSIT_INVITE_DIALOG_DETAILS,
    APP_EVENT_SET_DEPOSIT_LISTING_HOVER_DEPOSIT,
    APP_EVENT_SET_DEPOSIT_LISTING_MODE,
    APP_EVENT_SET_DEPOSIT_LISTING_SHOW_EXTRA_CONTROLS,
    APP_EVENT_SET_DEPOSIT_SEARCH_RES_ANIM,
    APP_EVENT_SET_DEPOSIT_SEARCH_RESULTS,
    APP_EVENT_SET_DISPUTE_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_EDIT_BANK_ACCOUNT_DETAILS,
    APP_EVENT_SET_EXPANDED_DEPOSIT_UIDS,
    APP_EVENT_SET_FULLSCREEN_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_LAST_UPDATED_DEPOSIT_UID,
    APP_EVENT_SET_MULTI_SELECT_DIALOG_MODE,
    APP_EVENT_SET_NEW_DEPOSIT_INVITE_DIALOG_DETAILS,
    APP_EVENT_SET_PROPOSE_DISPUTE_SPLIT_DIALOG_DETAILS,
    APP_EVENT_SET_REJECT_DEPOSIT_INVITE_DIALOG_DETAILS,
    APP_EVENT_SET_SEARCH_QUERY_INFO,
    APP_EVENT_SET_SELECTED_DEPOSIT_UIDS,
    APP_EVENT_SET_SHOW_DEPOSIT_DIALOG,
    APP_EVENT_SET_SHOW_MOBILE_SEARCH_CONTROLS,
    APP_EVENT_SET_SHOW_MULTI_SELECT_DIALOG,
    APP_EVENT_SET_WITHDRAW_DIALOG_DETAILS,
    APP_EVENT_SHOW_NEW_DEPOSIT_INVITE_DIALOG,
    APP_EVENT_TYPE_ACCOUNT_LOGGED_IN,
    APP_EVENT_TYPE_ACCOUNT_LOGGED_OUT,
    APP_EVENT_TYPE_CHANGE_PRIMARY_NAV_TAB,
    APP_EVENT_TYPE_SET_APP_IS_STARTING,
    APP_EVENT_SET_MIGRATED_ACCOUNTS,
    APP_EVENT_SET_FULL_SCREEN_ACCOUNT_DEFAULT,
    APP_EVENT_SET_FULL_SCREEN_PROPERTY_DIALOG_DETAILS,
    APP_EVENT_SET_FULL_SCREEN_US_DOM_BANK_DIALOG_DETAILS,
    APP_EVENT_SET_FULL_SCREEN_INT_BANK_DIALOG_DETAILS,
    APP_EVENT_SET_FULL_SCREEN_DEPOSIT_DIALOG_DETAILS,
    APP_EVENT_SET_FULL_SCREEN_ATTACHED_FILES_DIALOG_DETAILS,
} from "../actions/appActionTypes";
import {APP_EVENT_CLEAR_ALL, APP_EVENT_SET_LOGGED_OUT} from "../clientShared/actions/appActionTypes";



// We want to avoid a bad value going to the tabs selector so it doesn't log an error
function getStartingTab() {
    const validStartingTabs = [APP_ROUTE_HOME, APP_ROUTE_FUNDS, APP_ROUTE_SUPPORT, APP_ROUTE_ACCOUNT];
    if (validStartingTabs.includes(window.location.pathname)) {
        return window.location.pathname;
    }
    return '';
}

// TODO: Clean all this up
const initialAppState = {
    app_is_starting: true,
    selected_primary_nav_tab: getStartingTab(),
    account_logged_in: hasAccessTokenInLocalStorage(),
    show_deposit_dialog_uid: undefined,
    show_new_invite_dialog_uid: undefined,
    deposit_search_results: [],
    deposit_listing_mode: DEPOSIT_LISTING_DISPLAY_MODE_ALL_DEPOSITS,
    search_query_info: '',
    deposit_search_res_anim: false,
    selected_deposit_uids: [],
    expanded_deposit_uids: [],
    depositListingShowExtraControls: false,
    depositListingShowMobileSearchControls: false,
    lastUpdatedDepositUID: undefined,
    showMultiSelectDialog: false,
    multiSelectDialogMode: '',
    bankAccountDialogDetails: {showDialog: false, editMode: false, bankAccountUID: undefined, accountType: undefined},
    withdrawDialogDetails: {showDialog: false, editMode: false, withdrawUID: undefined},
    depositInviteDialogDetails: {showDialog: false, inviteUID: undefined, inviteMode: undefined},
    cancelDepositDialogDetails: {showDialog: false, depositUID: undefined},
    rejectDepositInviteDialogDetails: {
        showDialog: false,
        depositUID: undefined,
        depositAccountType: undefined,
        inviteUID: undefined
    },
    acceptDepositInviteDialogDetails: {
        showDialog: false,
        depositUID: undefined,
        depositAccountType: undefined,
        inviteUID: undefined,
        invoiceUID: undefined
    },
    depositFundsDialogDetails: {
        showDialog: false,
        amountInCents: undefined,
        resourceUID: undefined,
        resourceType: undefined,
        description: undefined,
        invoiceUID: undefined
    },
    archiveDepositDialogDetails: {showDialog: false, depositUID: undefined, archiveMode: false},
    closeDepositDialogDetails: {showDialog: false, depositUID: undefined},
    disputeDepositDialogDetails: {showDialog: false, depositUID: undefined},
    acceptOrRejectDepositCloseRequestDialogDetails: {showDialog: false, depositUID: undefined, acceptingMode: false},
    acceptOrRejectDisputeSplitDialogDetails: {showDialog: false, depositUID: undefined, disputeUID: undefined, splitUID: undefined, acceptingMode: false},
    proposeDisputeSplitDialogDetails: {showDialog: false, depositUID: undefined, disputeUID: undefined},
    depositListingHoverDepositUID: undefined,
    fullscreenDepositDialogDetails: {showDialog: false, depositUID: undefined},
    notifications: [],
    nextNotificationID: -1,
    createOrEditDepositDialogDetails: {showDialog: false, depositUID: undefined, editMode: false},
    createOrEditWithdrawalDialogDetails: {showDialog: false, editMode: false, withdrawUID: undefined},
    createOrEditUSDomesticBankAccountDialogDetails: {showDialog: false, editMode: false, bankAccountUID: undefined},
    createOrEditInternationalBankAccountDialogDetails: {showDialog: false, editMode: false, bankAccountUID: undefined},
    createBankAccountSelectionDialogDetails: {showDialog: false},
    cancelWithdrawalDialogDetails: {showDialog: false, withdrawUID: undefined},
    newDepositInviteDialogDetails: {showDialog: false, depositUID: undefined, depositTitle: undefined},
    migratedAccounts: [],
    adminFullScreenAccountDialogDetails: {showDialog: false, accountUID: undefined},
    adminFullScreenPropertyDialogDetails: {showDialog: false, propertyUID: undefined},
    adminFullScreenUSDomBankDialogDetails: {showDialog: false, bankAccountUID: undefined},
    adminFullScreenIntBankDialogDetails: {showDialog: false, bankAccountUID: undefined},
    adminFullScreenDepositDialogDetails: {showDialog: false, depositUID: undefined},
    adminFullScreenAttachedFilesDialogDetails: {showDialog: false, attachedFilesUID: undefined}
};

export function clearAllState() {
    return {
        type: APP_EVENT_CLEAR_ALL,
        clearAll: true
    };
}

export function appActionSetLoggedOut() {
    return {
        type: APP_EVENT_SET_LOGGED_OUT
    }
}

export default function appReducer(state = initialAppState, action) {

    switch (action.type) {

        case APP_EVENT_SET_LOGGED_OUT:
            return {...state, app_is_starting: false};

        case APP_EVENT_SET_MIGRATED_ACCOUNTS:
            return {...state, migratedAccounts: action.migratedAccounts};

        case APP_EVENT_SET_FULL_SCREEN_US_DOM_BANK_DIALOG_DETAILS:
            return {...state, adminFullScreenUSDomBankDialogDetails: action.adminFullScreenUSDomBankDialogDetails};

        case APP_EVENT_SET_FULL_SCREEN_ATTACHED_FILES_DIALOG_DETAILS:
            return {...state, adminFullScreenAttachedFilesDialogDetails: action.adminFullScreenAttachedFilesDialogDetails};

        case APP_EVENT_SET_FULL_SCREEN_DEPOSIT_DIALOG_DETAILS:
            return {...state, adminFullScreenDepositDialogDetails: action.adminFullScreenDepositDialogDetails};

        case APP_EVENT_SET_FULL_SCREEN_INT_BANK_DIALOG_DETAILS:
            return {...state, adminFullScreenIntBankDialogDetails: action.adminFullScreenIntBankDialogDetails};

        case APP_EVENT_SET_FULL_SCREEN_ACCOUNT_DEFAULT:
            return {...state, adminFullScreenAccountDialogDetails: action.adminFullScreenAccountDialogDetails};

        case APP_EVENT_SET_FULL_SCREEN_PROPERTY_DIALOG_DETAILS:
            return {...state, adminFullScreenPropertyDialogDetails: action.adminFullScreenPropertyDialogDetails};

        case APP_EVENT_SET_NEW_DEPOSIT_INVITE_DIALOG_DETAILS:
            return {...state, newDepositInviteDialogDetails: action.newDepositInviteDialogDetails};

        case APP_EVENT_SET_CANCEL_WITHDRAWAL_DIALOG_DETAILS:
            return {...state, cancelWithdrawalDialogDetails: action.cancelWithdrawalDialogDetails};

        case APP_EVENT_SET_CREATE_OR_EDIT_US_DOMESTIC_BANK_ACCOUNT_DIALOG_DETAILS:
            return {...state, createOrEditUSDomesticBankAccountDialogDetails: action.createOrEditUSDomesticBankAccountDialogDetails};

        case APP_EVENT_SET_CREATE_OR_EDIT_INTERNATIONAL_BANK_ACCOUNT_DIALOG_DETAILS:
            return {...state, createOrEditInternationalBankAccountDialogDetails: action.createOrEditInternationalBankAccountDialogDetails};

        case APP_EVENT_SET_CREATE_BANK_ACCOUNT_SELECTION_DIALOG_DETAILS:
            return {...state, createBankAccountSelectionDialogDetails: action.createBankAccountSelectionDialogDetails};

        case APP_EVENT_SET_CREATE_OR_EDIT_WITHDRAWAL_DIALOG_DETAILS:
            return {...state, createOrEditWithdrawalDialogDetails: action.createOrEditWithdrawalDialogDetails};

        case APP_EVENT_SET_CREATE_OR_EDIT_DEPOSIT_DIALOG_DETAILS:
            return {...state, createOrEditDepositDialogDetails: action.createOrEditDepositDialogDetails};

        case APP_EVENT_SET_SHOW_MOBILE_SEARCH_CONTROLS:
            return {...state, depositListingShowMobileSearchControls: action.depositListingShowMobileSearchControls};

        case APP_EVENT_SET_FULLSCREEN_DEPOSIT_DIALOG_DETAILS:
            return {...state, fullscreenDepositDialogDetails: action.fullscreenDepositDialogDetails};

        case APP_EVENT_SET_DEPOSIT_LISTING_HOVER_DEPOSIT:
            return {...state, depositListingHoverDepositUID: action.depositListingHoverDepositUID};

        case APP_EVENT_SET_PROPOSE_DISPUTE_SPLIT_DIALOG_DETAILS:
            return {
                ...state,
                proposeDisputeSplitDialogDetails: action.proposeDisputeSplitDialogDetails
            };

        case APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_DISPUTE_SPLIT_DIALOG_DETAILS:
            return {
                ...state,
                acceptOrRejectDisputeSplitDialogDetails: action.acceptOrRejectDisputeSplitDialogDetails
            };

        case APP_EVENT_SET_ACCEPT_OR_REJECT_DEPOSIT_CLOSE_REQUEST_DIALOG_DETAILS:
            return {
                ...state,
                acceptOrRejectDepositCloseRequestDialogDetails: action.updatedAcceptOrRejectDepositCloseRequestDialogDetails
            };

        case APP_EVENT_SET_DISPUTE_DEPOSIT_DIALOG_DETAILS:
            return {...state, disputeDepositDialogDetails: action.updatedDisputeDepositDialogDetails};

        case APP_EVENT_SET_CLOSE_DEPOSIT_DIALOG_DETAILS:
            return {...state, closeDepositDialogDetails: action.updatedCloseDepositDialogDetails};

        case APP_EVENT_SET_ARCHIVE_DEPOSIT_DIALOG_DETAILS:
            return {...state, archiveDepositDialogDetails: action.updatedArchiveDepositDialogDetails};

        case APP_EVENT_SET_DEPOSIT_FUNDS_DIALOG_DETAILS:
            return {...state, depositFundsDialogDetails: action.updatedDepositFundsDialogDetails};

        case APP_EVENT_SET_ACCEPT_DEPOSIT_INVITE_DIALOG_DETAILS:
            return {...state, acceptDepositInviteDialogDetails: action.updatedAcceptDepositInviteDialogDetails};

        case APP_EVENT_SET_REJECT_DEPOSIT_INVITE_DIALOG_DETAILS:
            return {...state, rejectDepositInviteDialogDetails: action.updatedRejectDepositInviteDialogDetails};

        case APP_EVENT_SET_CANCEL_DEPOSIT_DIALOG_DETAILS:
            return {...state, cancelDepositDialogDetails: action.updatedCancelDepositDialogDetails};

        case APP_EVENT_SET_DEPOSIT_INVITE_DIALOG_DETAILS:
            return {...state, depositInviteDialogDetails: action.updatedDepositInviteDialogDetails};

        case APP_EVENT_SET_WITHDRAW_DIALOG_DETAILS:
            return {...state, withdrawDialogDetails: action.updatedWithdrawDialogDetails};

        case APP_EVENT_SET_EDIT_BANK_ACCOUNT_DETAILS:
            return {...state, bankAccountDialogDetails: action.updatedEditBankInfo};

        case APP_EVENT_SET_MULTI_SELECT_DIALOG_MODE:
            return {...state, multiSelectDialogMode: action.newValue};

        case APP_EVENT_SET_SHOW_MULTI_SELECT_DIALOG:
            return {...state, showMultiSelectDialog: action.newValue};

        case APP_EVENT_SET_DEPOSIT_LISTING_SHOW_EXTRA_CONTROLS:
            return {...state, depositListingShowExtraControls: action.newValue};

        case APP_EVENT_SET_LAST_UPDATED_DEPOSIT_UID:
            return {...state, lastUpdatedDepositUID: action.depositUID};

        case APP_EVENT_SET_SELECTED_DEPOSIT_UIDS:
            return {...state, selected_deposit_uids: action.selected_deposit_uids};

        case APP_EVENT_ADD_SELECTED_DEPOSIT_UID:
            let addSelArray = [...state.selected_deposit_uids];
            if (!addSelArray.includes(action.deposit_uid)) {
                addSelArray.push(action.deposit_uid);
            }
            return {...state, selected_deposit_uids: addSelArray};

        case APP_EVENT_REMOVE_SELECTED_DEPOSIT_UID:
            let removeSelArray = [...state.selected_deposit_uids];
            const removeIndex = removeSelArray.indexOf(action.deposit_uid);
            if (removeIndex > -1) {
                removeSelArray.splice(removeIndex, 1);
            }
            return {...state, selected_deposit_uids: removeSelArray};

        case APP_EVENT_SET_EXPANDED_DEPOSIT_UIDS:
            return {...state, expanded_deposit_uids: action.expanded_deposit_uids};

        case APP_EVENT_ADD_EXPANDED_DEPOSIT_UID:
            let add_updatedExpandedDeposits = [...state.expanded_deposit_uids];
            if (!add_updatedExpandedDeposits.includes(action.deposit_uid)) {
                add_updatedExpandedDeposits.push(action.deposit_uid);
            }
            return {...state, expanded_deposit_uids: add_updatedExpandedDeposits};

        case APP_EVENT_REMOVE_EXPANDED_DEPOSIT_UID:
            let remove_updatedExpandedDeposits = [...state.expanded_deposit_uids];
            const rem_index = remove_updatedExpandedDeposits.indexOf(action.deposit_uid);
            if (rem_index > -1) {
                remove_updatedExpandedDeposits.splice(rem_index, 1);
            }
            return {...state, expanded_deposit_uids: remove_updatedExpandedDeposits};

        case APP_EVENT_TYPE_SET_APP_IS_STARTING:
            return {...state, app_is_starting: action.appIsStarting};

        case APP_EVENT_SET_SEARCH_QUERY_INFO:
            return {...state, search_query_info: action.searchQueryInfo};

        case APP_EVENT_SET_DEPOSIT_SEARCH_RES_ANIM:
            return {...state, deposit_search_res_anim: action.deposit_search_res_anim};

        case APP_EVENT_SET_DEPOSIT_LISTING_MODE:
            return {...state, deposit_listing_mode: action.depositListingMode};

        case APP_EVENT_SET_DEPOSIT_SEARCH_RESULTS:
            return {
                ...state,
                deposit_search_results: action.deposit_search_results,
                deposit_listing_mode: DEPOSIT_LISTING_DISPLAY_MODE_SEARCH_RESULTS
            };

        case APP_EVENT_SET_SHOW_DEPOSIT_DIALOG:
            if (state.account_logged_in === true) {
                return {...state, show_deposit_dialog_uid: action.depositUID};
            } else {
                return state;
            }
        case APP_EVENT_CLEAR_SHOW_DEPOSIT_DIALOG:
            return {...state, show_deposit_dialog_uid: undefined};


        case APP_EVENT_SHOW_NEW_DEPOSIT_INVITE_DIALOG:
            if (state.account_logged_in === true) {
                return {...state, show_new_invite_dialog_uid: action.depositUID};
            } else {
                return state;
            }
        case APP_EVENT_CLEAR_SHOW_NEW_DEPOSIT_INVITE_DIALOG:
            return {...state, show_new_invite_dialog_uid: undefined};

        case APP_EVENT_CLEAR_ALL:
            return initialAppState;
        case APP_EVENT_TYPE_ACCOUNT_LOGGED_IN:
            return {
                ...state,
                account_logged_in: true
            };
        case APP_EVENT_TYPE_ACCOUNT_LOGGED_OUT:
            return {
                ...state,
                account_logged_in: false
            };
        case APP_EVENT_TYPE_CHANGE_PRIMARY_NAV_TAB:
            return {
                ...state,
                selected_primary_nav_tab: action.selected_tab
            };

        case APP_EVENT_HANDLE_INVOICE_UPDATE:
            // depositFundsDialogDetails
            // acceptDepositInviteDialogDetails
            let doUpdate = false;

            let updatedDepositFundsDialogDetails = {...state.depositFundsDialogDetails};
            if(updatedDepositFundsDialogDetails.showDialog === true) {
                if (updatedDepositFundsDialogDetails.resourceUID === action.depositUID) {
                    updatedDepositFundsDialogDetails = initialAppState.depositFundsDialogDetails;
                    doUpdate = true;
                }
            }

            let updatedAcceptDepositInviteDialogDetails = {...state.acceptDepositInviteDialogDetails};
            if(updatedAcceptDepositInviteDialogDetails.showDialog === true){
                if(updatedAcceptDepositInviteDialogDetails.depositUID === action.depositUID){
                    updatedAcceptDepositInviteDialogDetails = initialAppState.acceptDepositInviteDialogDetails;
                    doUpdate = true;
                }
            }

            if(doUpdate === true){
                return {
                    ...state,
                    acceptDepositInviteDialogDetails: updatedAcceptDepositInviteDialogDetails,
                    depositFundsDialogDetails: updatedDepositFundsDialogDetails
                }
            }

            return state;

        case APP_EVENT_ADD_NOTIFICATION:
            const newNotificationID = state.nextNotificationID + 1; // increment notification ID
            return {
                ...state,
                nextNotificationID: newNotificationID, // update the next notification ID
                notifications: [
                    ...state.notifications,
                    // Add the new notification and set the ID
                    {...action.notification, id: newNotificationID}
                ]
            };

        case APP_EVENT_REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== action.notificationID)
            };

        default:
            return state;
    }
};