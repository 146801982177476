import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDagger} from "@fortawesome/pro-regular-svg-icons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/browser";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Collapse, FilledInput} from "@material-ui/core";
import {centsToDollars, dollarFormattedString, dollarsToCents} from "../../utilities/commonUtils";
import Typography from "@material-ui/core/Typography";
import {history} from "../../history/history";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import {DEPOSIT_STATE_ACTIVE} from "../../autoGenerated/constants/services/DepositsServiceConstants";


const RhinoDecreaseDepositAmount = ({deposit}) => {
    const [showDialog, setShowDialog] = React.useState(false);
    const [newAmount, setNewAmount] = useState(centsToDollars(deposit.amount_in_cents));
    const [confirmMode, setConfirmMode] = useState(false);
    const [newCentsAmount, setNewCentsAmount] = useState(0);
    const [spinning, setSpinning] = useState(false);
    const disabled = deposit.state !== DEPOSIT_STATE_ACTIVE;

    return (
        <div>
            <Button
                color="secondary"
                onClick={handleOpen}
                variant={'outlined'}
                // size={'small'}
                disabled={disabled}
            >
                <FontAwesomeIcon
                    icon={faDagger}
                    fixedWidth
                />
                &nbsp;Decrease Deposit Amount
            </Button>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="jeff-edit-deposit-title"
                aria-describedby="jeff-edit-deposit-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="account-dialog-title">Decrease Deposit Amount</DialogTitle>
                <DialogContent>

                    <Collapse in={!confirmMode}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
                            <FilledInput
                                id="filled-adornment-amount"
                                value={newAmount}
                                onChange={handleChange}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </FormControl>
                    </Collapse>

                    <Collapse in={confirmMode}>
                        <Typography variant="h6">
                            Are you sure?
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            This will close the current deposit, and create a new deposit
                            for <b>{dollarFormattedString(newCentsAmount)}</b>
                        </Typography>

                        <SpinnerButton
                            // Non-button props
                            showSpinner={spinning}
                            // Button props
                            variant="outlined"
                            color="secondary"
                            disabled={spinning}
                            onClick={handleUpdate}
                        >
                            Yes, Create A New Deposit
                        </SpinnerButton>
                    </Collapse>

                </DialogContent>
                <DialogActions>

                    <Collapse in={!confirmMode}>
                        <Button onClick={handleUpdateClicked} color="primary" variant={"contained"}>
                            Create New Deposit
                        </Button>
                    </Collapse>

                    <Button onClick={handleClose} color="primary" autoFocus disabled={spinning}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
        setNewAmount(centsToDollars(deposit.amount_in_cents));
        setConfirmMode(false);
        setSpinning(false);
    }

    function handleChange(e) {
        setNewAmount(e.target.value);
    }

    function handleUpdateClicked() {
        let xCents = 0;

        try {
            xCents = dollarsToCents(newAmount);
        } catch (e) {
            toast.error("Invalid amount");
            return;
        }

        if (xCents === deposit.amount_in_cents) {
            toast.error("New amount can't be the same as the old.");
            return;
        }
        if (xCents <= 0) {
            toast.error("New amount must be something.");
            return;
        }

        if (xCents > deposit.amount_in_cents) {
            toast.error("New amount must be lower.");
            return;
        }

        setNewCentsAmount(xCents);

        setConfirmMode(true);
    }

    function handleUpdate() {
        setSpinning(true);
        let updatedValues = {new_amount_in_cents: newCentsAmount};

        const callURL = getAdminPath(`jeff/deposits/${deposit.deposit_uid}/decrease`);
        axiosInstance.post(callURL, updatedValues)
            .then(function (response) {
                handleClose();
                toast.success('Deposit created, redirecting...');
                history.push(`/deposits/${response.data.deposit_uid}/detailed`);

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
                setSpinning(false);
            })
    }
};

export default RhinoDecreaseDepositAmount;