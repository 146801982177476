/*
    Toggle interest enabled on a bank account
 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {resourceActionUpdateSingleResource} from "../../clientShared/actions/resourceActions";
import ConfirmationDialog from "../utilities/dialogs/ConfirmationDialog";
import BankingAdminService from "../../api/BankingAdminService";
import {RESOURCE_TYPE_BANK_ACCOUNT} from "../../autoGenerated/constants/resourceTypeConstants";
import toast from "react-hot-toast";


function BankingAdminButtonToggleInterestEnabled({bankAccount, ...props}) {

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    const isEnabling = bankAccount.interest_enabled;
    const descriptionString = isEnabling ? 'Disable' : 'Enable';

    if (bankAccount.internal_type !== "DEPOSIFY_HELD") {
        return (
            <>
                <br/>
                Interest controls not available.<br/>
                Bank account not held by Deposify / Rhino.
            </>
        );
    }

    return (
        <React.Fragment>
            <ConfirmationDialog
                startOpen={showConfirm}
                dangerMode={true}
                confirmCallback={acceptConfirm}
                cancelCallback={cancelConfirm}
                dialogTitle={'Are you sure?'}
                dialogText={`Are you sure you sure you want to ${descriptionString} interest for this bank account?`}
                confirmText={`Yes, ${descriptionString}`}
                showSpinner={showSpinner}
                cancelFocus={true}
            />
            <Button
                color="secondary"
                onClick={handleButtonClick}
                variant={'contained'}
            >
                <FontAwesomeIcon
                    icon={faSkullCrossbones}
                    fixedWidth
                />
                &nbsp;{descriptionString} Interest
            </Button>
        </React.Fragment>
    );

    function handleButtonClick() {
        setShowSpinner(false);
        setShowConfirm(true);
    }

    function acceptConfirm() {
        setShowSpinner(true);
        BankingAdminService.ToggleInterestEnabled(bankAccount.bank_account_uid, !bankAccount.interest_enabled)
            .then(function (response) {
                toast.success('Bank account updated');
                setShowConfirm(false);
                setTimeout(() => {
                    props.resourceActionUpdateSingleResource(response.bank_account, RESOURCE_TYPE_BANK_ACCOUNT);
                }, 500);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }

    function cancelConfirm() {
        setShowConfirm(false);
        toast('Cancelled action');
    }


}

const mapStateToProps = (state) => {
    // const _adminMakeSystemStatsSelector = adminMakeSystemStatsSelector();

    return function realMapState(state, props) {
        return {
            // systemStats: _adminMakeSystemStatsSelector(state, props.statsID)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(BankingAdminButtonToggleInterestEnabled);
