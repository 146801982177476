import React from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import {SYSTEM_STATS_DEFAULT_HEIGHT, SYSTEM_STATS_DEFAULT_WIDTH} from "../systemStatsConstants";
import {systemStatsCreatedTimestampToName} from "../../utilities/statsUtilities";


export function DailyWithdrawalStats({
                                       systemStats,
                                       width = SYSTEM_STATS_DEFAULT_WIDTH,
                                       height = SYSTEM_STATS_DEFAULT_HEIGHT,
                                       ...props
                                   }) {
    // Convert the created timestamp into the name for the tooltip
    systemStats.forEach(systemStatsCreatedTimestampToName);

    return (
        <React.Fragment>
            Withdrawals<br/>
            <LineChart
                width={width}
                height={height}
                data={systemStats}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip label={'Cheese'}/>

                <Line type="monotone" dataKey="stats_json.state_stats.pending.count" stroke="#a1dcbc" activeDot={{r: 8}} name={'Pending'}/>
                <Line type="monotone" dataKey="stats_json.state_stats.cancelled.count" stroke="#bec5cd" activeDot={{r: 8}} name={'Cancelled'}/>
                <Line type="monotone" dataKey="stats_json.state_stats.payment_complete.count" stroke="#a1dcbc" activeDot={{r: 8}} name={'Complete'}/>
                <Line type="monotone" dataKey="stats_json.state_stats.user_info_needed.count" stroke="#f09f91" activeDot={{r: 8}} name={'User Info Needed'}/>



            </LineChart>
        </React.Fragment>
    );

}
