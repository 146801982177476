/*
    Dev Page
 */

import React, {useState} from "react";
import * as Sentry from "@sentry/browser";
import {connect} from 'react-redux';
import AdminRootPage from "../pages/AdminRootPage";
import RichTextEditor from 'react-rte';
import {useDebounce} from '@umijs/hooks';
import {EMAIL_TEMPLATE_HTML} from "./devCabbage";
import './split.css';
import makeStyles from "@material-ui/core/styles/makeStyles";
import _ from "lodash";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RenderTimestamp from "../../clientShared/utilities/RenderTimestamp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import {faClock, faEnvelope, faFileExport, faSave, faSync, faWind} from "@fortawesome/pro-light-svg-icons";
import MessagingAdminService from "../../api/MessagingAdminService";
import {
    RESOURCE_TYPE_EMAIL_TEMPLATE
} from "../../autoGenerated/constants/resourceTypeConstants";
import {bindActionCreators} from "redux";
import {resourceActionUpdateSingleResource} from "../../clientShared/actions/resourceActions";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import {getDateDeltaDuration, getMomentUTC, getServerDateTimeAsMoment} from "../../utilities/commonUtils";
import {loadingActionLoadMultipleResourceFromServer} from "../../clientShared/actions/sharedLoadingActions";
import {allEmailTemplatesSelector} from "../../autoGenerated/selectors/modernResourceSelectors";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BackLink from "../admin/buttons/linkButtons/BackLink";
import HomeLink from "../admin/buttons/linkButtons/HomeLink";

const BODY_VAR_STRING = '{{ email_body|safe }}';

const useStyles = makeStyles({
    gridContainer: {
        // width: '100%'
    },
    rteComponent: {
        minHeight: 300,
        backgroundColor: '#282c34'
    },
    rteToolbar: {
        backgroundColor: '#282c34'
    },
    rteEditor: {
        backgroundColor: '#282c34'
    },
    renderArea: {
        minHeight: 300
    },
    listArea: {
        maxHeight: 700,
        overflow: 'auto'
    }
});

const REPLACE_DICT = {
    deposit_title: '#54 Summer Street'
};

// Regex for Jinja2 vars, E.g {{ deposit_title }}:  {{\s?(\w*)\s?}}

function EmailTemplateListItem({emailTemplate, changeCallback, selectedIndex}) {
    const end = getMomentUTC();
    const diffDuration = getDateDeltaDuration(getServerDateTimeAsMoment(emailTemplate.last_updated_timestamp), end);
    const icon = diffDuration.days() <= 2 ? faClock : faEnvelope;
    const colour = diffDuration.days() <= 2 ? 'green' : undefined;

    return (
        <ListItem button onClick={handleClick} selected={emailTemplate.template_id === selectedIndex}>
            <FontAwesomeIcon icon={icon} color={colour} fixedWidth/>
            &nbsp;{emailTemplate.template_id.toString()} - {emailTemplate.internal_name}
        </ListItem>
    );

    function handleClick() {
        changeCallback(emailTemplate);
    }
}

function SelectedTemplateInfo({emailTemplate}) {
    return (
        <React.Fragment>
            ID: {emailTemplate.template_id.toString()}<br/>
            Internal name: {emailTemplate.internal_name}<br/>
            Version: {emailTemplate.template_version}<br/>
            Created: <RenderTimestamp timestamp={emailTemplate.created_timestamp}/><br/>
            Updated: <RenderTimestamp timestamp={emailTemplate.last_updated_timestamp}/><br/>
        </React.Fragment>
    );

}

//<Split gutterSize="4" className="normal-grid">



// TODO: Preview should use the root template instead of the hard coded dev import
function EditEmailTemplatePage(props) {
    const classes = useStyles();

    const [currentTemplate, setCurrentTemplate] = useState(undefined);
    const [showSpinner, setShowSpinner] = useState(false);
    const [errorInfo, setErrorInfo] = useState('');

    const [richValue, setRichValue] = useState(RichTextEditor.createValueFromString('<b>Hello</b>', 'html'));
    const richValueHTML = useDebounce(richValue.toString('html'), 500);

    let htmlPayload = EMAIL_TEMPLATE_HTML.replace(BODY_VAR_STRING, richValueHTML);
    _.forOwn(REPLACE_DICT, function (value, key) {
        // Note: Keep this strict
        htmlPayload = htmlPayload.replace(`{{ ${key} }}`, value);
    });

    return (
        <AdminRootPage>

            <BackLink/>&nbsp;|&nbsp;<HomeLink/>
            <br/><br/>

            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
                wrap={'nowrap'}
                className={classes.gridContainer}
            >
                <Grid item xs={4}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Tooltip title={'Reload All Templates'} placement={'top'}>
                                <IconButton
                                    aria-label={'Close'}
                                    onClick={reloadAllEmailTemplates}
                                    size={'small'}
                                    color={'primary'}
                                >
                                    <FontAwesomeIcon
                                        icon={faSync}
                                        fixedWidth
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={'Export All Templates'} placement={'top'}>
                                <IconButton
                                    aria-label={'Close'}
                                    onClick={downloadAllTemplatesJSON}
                                    size={'small'}
                                    color={'primary'}
                                >
                                    <FontAwesomeIcon
                                        icon={faFileExport}
                                        fixedWidth
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Typography variant="h6">
                                Email Templates ({Object.keys(props.allEmailTemplates).length.toString()})
                            </Typography>
                        </Grid>
                    </Grid>

                    <List dense className={classes.listArea}>
                        {
                            Object.keys(props.allEmailTemplates).map(currentEmailTemplateUID => {
                                return (
                                    <EmailTemplateListItem
                                        emailTemplate={props.allEmailTemplates[currentEmailTemplateUID]}
                                        key={`email-template-list-item-${currentEmailTemplateUID}`}
                                        changeCallback={handleTemplateIDChange}
                                        selectedIndex={currentEmailTemplateUID}
                                    />
                                );
                            })
                        }
                    </List>
                </Grid>

                <Grid item xs={8}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={2}
                    >
                        <Grid item>
                            <Typography variant="h6">
                                Selected Template
                            </Typography>
                            {
                                currentTemplate === undefined &&
                                <i>None selected</i>
                            }
                            {
                                currentTemplate !== undefined &&
                                <React.Fragment>
                                    <SelectedTemplateInfo emailTemplate={currentTemplate}/>
                                    <br/>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <SpinnerButton
                                                // Non-button props
                                                showSpinner={showSpinner}
                                                // Button props
                                                variant="outlined"
                                                color="primary"
                                                disabled={showSpinner}
                                                onClick={saveTemplateFunction}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faSave}
                                                    fixedWidth
                                                />&nbsp;Save
                                            </SpinnerButton>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={sendEmailFunction}
                                                color="primary"
                                                variant={'outlined'}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faWind}
                                                    fixedWidth
                                                />&nbsp;Send Email
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </React.Fragment>
                            }
                        </Grid>

                        <Collapse in={errorInfo !== ''}>
                            <Alert severity="error">{errorInfo}</Alert>
                        </Collapse>

                        <Grid item>
                            <RichTextEditor
                                value={richValue}
                                onChange={onChange}
                                className={classes.rteComponent}
                                toolbarClassName={classes.rteToolbar}
                                editorClassName={classes.rteEditor}
                            />
                        </Grid>

                        <Grid item>
                            <div
                                dangerouslySetInnerHTML={
                                    {__html: htmlPayload}
                                }
                                className={classes.renderArea}
                            >
                                {/* Needs to be an empty div for the dangerous HTML   */}
                            </div>
                        </Grid>

                        {/*<Grid item>*/}
                        {/*    <pre*/}
                        {/*        style={{*/}
                        {/*            fontSize: '1.0rem',*/}
                        {/*            padding: '.25rem .5rem',*/}
                        {/*            // overflowX: 'scroll',*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        {htmlPayload}*/}
                        {/*    </pre>*/}
                        {/*</Grid>*/}

                    </Grid>
                </Grid>

            </Grid>

            <br/>
            <br/>


        </AdminRootPage>
    );

    function onChange(value) {
        setRichValue(value);
        // setRichHTMLValue(value.toString('html'));
    }

    function handleTemplateIDChange(selectedTemplate) {
        setCurrentTemplate(selectedTemplate);
        setRichValue(RichTextEditor.createValueFromString(selectedTemplate.email_body, 'html'))
    }

    function saveTemplateFunction() {
        setShowSpinner(true);

        MessagingAdminService.UpdateEmailTemplate(currentTemplate.template_uid, richValue.toString('html'))
            .then(function (response) {
                props.resourceActionUpdateSingleResource(response.email_template, RESOURCE_TYPE_EMAIL_TEMPLATE);
                setShowSpinner(false);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    scope.setExtra("currentTemplate", currentTemplate);
                    Sentry.captureException(error);
                });
                setShowSpinner(false);
            });
    }

    function sendEmailFunction() {
        setErrorInfo('');

        MessagingAdminService.SendTestEmail(currentTemplate.template_id)
            .then(function (response) {
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    scope.setExtra("currentTemplate", currentTemplate);
                    Sentry.captureException(error);
                });

                setErrorInfo(error.data.error_info);
            });
    }

    function reloadAllEmailTemplates() {
        props.loadingActionLoadMultipleResourceFromServer(RESOURCE_TYPE_EMAIL_TEMPLATE, 0, 200, 'template_id', false);
    }

    function downloadAllTemplatesJSON(){
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        // file_data is base64 encoded
        const blob = new Blob([JSON.stringify(Object.values(props.allEmailTemplates), null, 4)], {type: "application/json"});
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'all_email_templates.json';
        a.click();
        window.URL.revokeObjectURL(url);
    }

}

const mapStateToProps = (state) => ({
    allEmailTemplates: allEmailTemplatesSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource,
        loadingActionLoadMultipleResourceFromServer: loadingActionLoadMultipleResourceFromServer
    }, dispatch);
};


export default connect(mapStateToProps, mapActionsToProps)(EditEmailTemplatePage);
