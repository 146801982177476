/*
    Dev
 */

import React from "react";
import Grid from "@material-ui/core/Grid";
import SearchBar from "../admin/controls/SearchBar";
import {ALL_PAGE_GROUPS} from "../../autoGenerated/constants/pageGroups";
import PageGroupCard from "../PageGroupCard/PageGroupCard";
import {SERVICE_INFO} from "../../autoGenerated/constants/serviceInfo";
import shortid from "shortid";
import {AdminHomepageStatsCollection} from "../stats/adminHomepageStatsCollection";
import AdminRootPage from "./AdminRootPage";
import {DARK_HEADER_LOGO_MEDIUM} from "../../constants/cdnConstants";
import AdminQuickAccessLinks from "../links/AdminQuickAccessLinks";
import AdminDisplayGhostAccount from "../admin/displays/adminDisplayGhostAccount";
import AdminOfflineControlsDisplay from "../admin/displays/AdminOfflineControlsDisplay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHatWizard} from "@fortawesome/pro-regular-svg-icons";
import LinkBase from "../../clientShared/components/links/linkBase";
import RhinoNoodlesCard from "../rhino/rhinoNoodlesCard";


function AdminHomePage(props) {


    return (
        <AdminRootPage>

            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}
            >

                <Grid item>
                    <img
                        src={DARK_HEADER_LOGO_MEDIUM}
                        alt="Deposify Logo Dark Colours"
                        width={220}
                        height={120}
                    />
                </Grid>

                <Grid item>
                    <SearchBar/>
                    <br/>
                </Grid>


                <Grid item>
                    <AdminHomepageStatsCollection/>
                    <br/>
                    <hr/>
                </Grid>

                <Grid item>
                    <AdminQuickAccessLinks/>
                </Grid>

                {/*<Grid item>*/}
                {/*    <OfflineStatusIndicator/>*/}
                {/*</Grid>*/}

                <Grid item>
                    <FontAwesomeIcon icon={faHatWizard} fixedWidth/>&nbsp;
                    <LinkBase url={"/jeff"} text={"Admin Tools"}/>
                </Grid>

                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        {
                            Object.keys(SERVICE_INFO).map(serviceID => {
                                return (
                                    <Grid item key={shortid.generate()}>
                                        <PageGroupCard
                                            serviceID={serviceID}
                                            pageGroup={ALL_PAGE_GROUPS[serviceID]}
                                        />
                                    </Grid>
                                );
                            })
                        }

                        <Grid item>
                            <RhinoNoodlesCard/>
                        </Grid>

                    </Grid>
                </Grid>

                {/*<Grid item>*/}
                {/*    <BigAdminHomeButton*/}
                {/*        text={'Danger'}*/}
                {/*        historyLocation={APP_ADMIN_ROUTE_DANGER_PAGE}*/}
                {/*        icon={ADMIN_ICON_DANGER_PAGE}*/}
                {/*        danger={true}*/}
                {/*    />*/}
                {/*</Grid>*/}

                <Grid item>
                    <AdminDisplayGhostAccount/>
                </Grid>

                <Grid item>
                    <AdminOfflineControlsDisplay/>
                </Grid>

                <Grid item>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                </Grid>

            </Grid>


        </AdminRootPage>
    );


}


export default AdminHomePage;
