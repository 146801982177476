/*

 */

export const DEPOSIT_STARTUP_READ_LIMIT = 20;

export const DEPOSIT_ACCOUNT_TYPE_LANDLORD = 'landlord';
export const DEPOSIT_ACCOUNT_TYPE_TENANT = 'tenant';

export const DEPOSIT_STATE_PENDING = 'pending';
export const DEPOSIT_STATE_ACTIVE = 'active';
export const DEPOSIT_STATE_CLOSED_REQUESTED = 'close_requested';
export const DEPOSIT_STATE_CLOSED = 'closed';
export const DEPOSIT_STATE_DISPUTED = 'disputed';
export const DEPOSIT_STATE_DISPUTE_SPLIT_PROPOSED = 'dispute_split_proposed';
export const DEPOSIT_STATE_MEDIATOR_INVOLVED_DISPUTE = 'mediator_involved_dispute';
export const DEPOSIT_STATE_MEDIATOR_SPLIT_PROPOSED = 'mediator_split_proposed';
export const DEPOSIT_STATE_CANCELLED = 'cancelled';

export const DISPUTE_STATE_ACTIVE = 'active';
export const DISPUTE_STATE_ACTIVE_WITH_MEDIATOR = 'active_with_mediator';
export const DISPUTE_STATE_ENDED = 'ended';

// export const DISPUTE_SPLIT_RESPONSE_AWAITING_RESPONSE = 'awaiting_response';
export const DISPUTE_SPLIT_RESPONSE_REJECTED = 'rejected';
export const DISPUTE_SPLIT_RESPONSE_ACCEPTED = 'accepted';

export const DEPOSIT_ARCHIVE_ALLOWED_STATES = [
    DEPOSIT_STATE_CANCELLED,
    DEPOSIT_STATE_CLOSED
];


// Tab indexes for a deposit card
export const DEPOSIT_TAB_SUMMARY = 0;
export const DEPOSIT_TAB_MESSAGES = 1;
export const DEPOSIT_TAB_FILES = 2;
export const DEPOSIT_TAB_HISTORY = 3;


// Invite dialog modes
export const DEPOSIT_INVITE_DIALOG_MODE_EMAIL = 'invite_mode:email';
export const DEPOSIT_INVITE_DIALOG_MODE_PHONE = 'invite_mode:phone';

// Archive / unarchive deposits
export const ARCHIVE_DEPOSIT_STRING = 'Archive Deposit';
export const UNARCHIVE_DEPOSIT_STRING = 'Remove From Archive';

export const ARCHIVE_DEPOSIT_INFO_STRING = 'Are you sure you want to archive this security deposit?';
export const UNARCHIVE_DEPOSIT_INFO_STRING = 'Are you sure you want to remove this security deposit from the archives?';

export const DEPOSIT_CARD_MIN_AREA_HEIGHT = 350;

export const DEPOSIT_CLOSE_REQUEST_STATE_PENDING = 'pending';