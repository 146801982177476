/*

 */

import {createSelector} from 'reselect'

export const resourceTypeSelector = (state, resourceType) => resourceType;

export const resourceUIDsSelector = state => state.resources.resourceUIDs;
export const resourceUIDsByOwnerSelector = state => state.resources.resourceUIDsByOwner;

const resourceAndAccountInfoSelector = (state, resourceAndAccountInfo) => resourceAndAccountInfo;

export const uuidSelector = (state, incomingUUID) => incomingUUID;

export const makeResourceUIDListByAccountSelector = () => createSelector(
    [resourceUIDsByOwnerSelector, resourceAndAccountInfoSelector],
    (resourceUIDsByOwner, resourceAndAccountInfo) => {
        const accountResources = resourceUIDsByOwner[resourceAndAccountInfo.accountUID] || {};
        return accountResources[resourceAndAccountInfo.resourceType] || [];
    }
);

export const resourceUIDListSelector = () => createSelector(
    [resourceUIDsSelector, resourceTypeSelector],
    (resourceUIDs, resourceType) => {
        return resourceUIDs[resourceType] || [];
    }
);

export const newResourceUIDListSelector = createSelector(
    [resourceUIDsSelector, resourceTypeSelector],
    (resourceUIDs, resourceType) => {
        return resourceUIDs[resourceType] || [];
    }
);

export const resourceUIDListByAccountSelector = createSelector(
    [resourceUIDsByOwnerSelector, resourceAndAccountInfoSelector],
    (resourceUIDsByOwner, resourceAndAccountInfo) => {
        const accountResources = resourceUIDsByOwner[resourceAndAccountInfo.accountUID] || {};
        return accountResources[resourceAndAccountInfo.resourceType] || [];
    }
);