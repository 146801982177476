/*
    Property Related Constants
 */

export const PROPERTY_APT_UNIT_NUMBER_MIN = 1;
export const PROPERTY_APT_UNIT_NUMBER_MAX = 100;

export const PROPERTY_TITLE_LENGTH_MIN = 2;
export const PROPERTY_TITLE_LENGTH_MAX = 128;


// Property Types -- AUTO GENERATE THESE FROM THE SERVER CODE - DO NOT EDIT BY HAND
export const PROPERTY_TYPE_APARTMENT = 'apartment';
export const PROPERTY_TYPE_UNIT = 'unit';
export const PROPERTY_TYPE_APARTMENT_BUILDING = 'apartment_building';
export const PROPERTY_TYPE_APARTMENT_BUILDING_FLOOR = 'apartment_building_floor';
export const PROPERTY_TYPE_BUILDING = 'building';
export const PROPERTY_TYPE_BUILDING_FLOOR = 'building_floor';
export const PROPERTY_TYPE_HOUSE = 'house';
export const PROPERTY_TYPE_ROOM = 'room';
export const PROPERTY_TYPE_TOWNHOUSE = 'townhouse';
export const PROPERTY_TYPE_CITY = 'city';
export const PROPERTY_TYPE_DISTRICT = 'district';
//export const PROPERTY_TYPE_US_STATE = 'us_state';
export const PROPERTY_TYPE_COMMUNITY = 'community';
export const PROPERTY_TYPE_GATED_COMMUNITY = 'gated_community';
export const PROPERTY_TYPE_HOUSING_ESTATE = 'housing_estate';
export const PROPERTY_TYPE_DORMITORY = 'dormitory';
export const PROPERTY_TYPE_DORMITORY_UNIT = 'dormitory_unit';
export const PROPERTY_TYPE_DUPLEX = 'duplex';
export const PROPERTY_TYPE_DUPLEX_UNIT = 'duplex_unit';
export const PROPERTY_TYPE_SUITE = 'suite';
export const PROPERTY_TYPE_CABIN = 'cabin';
export const PROPERTY_TYPE_CHALET = 'chalet';
export const PROPERTY_TYPE_COTTAGE = 'cottage';
export const PROPERTY_TYPE_RETIREMENT_COMMUNITY = 'retirement_community';
export const PROPERTY_TYPE_VILLA = 'villa';
export const PROPERTY_TYPE_ASSISTED_LIVING = 'assisted_living';
export const PROPERTY_TYPE_VILLAGE = 'village';

export const PROPERTY_TYPES_WITH_UNIT_NUMBERS = [
    PROPERTY_TYPE_APARTMENT,
    PROPERTY_TYPE_UNIT,
    PROPERTY_TYPE_BUILDING_FLOOR,
    PROPERTY_TYPE_APARTMENT_BUILDING_FLOOR,
    PROPERTY_TYPE_ROOM,
    PROPERTY_TYPE_DORMITORY_UNIT,
    PROPERTY_TYPE_DUPLEX_UNIT,
    PROPERTY_TYPE_SUITE
];

export const LABEL_FOR_PROPERTY_TYPE_NUMBER = {
    [PROPERTY_TYPE_APARTMENT]: 'Apt / Unit #',
    [PROPERTY_TYPE_UNIT]: 'Unit #',
    [PROPERTY_TYPE_BUILDING_FLOOR]: 'Floor #',
    [PROPERTY_TYPE_APARTMENT_BUILDING_FLOOR]: 'Floor #',
    [PROPERTY_TYPE_ROOM]: 'Room #',
    [PROPERTY_TYPE_DORMITORY_UNIT]: 'Unit #',
    [PROPERTY_TYPE_DUPLEX_UNIT]: 'Unit #',
    [PROPERTY_TYPE_SUITE]: 'Suite #'
};

export const ALL_PROPERTY_TYPES = [
    PROPERTY_TYPE_APARTMENT,
    PROPERTY_TYPE_UNIT,
    PROPERTY_TYPE_APARTMENT_BUILDING,
    PROPERTY_TYPE_APARTMENT_BUILDING_FLOOR,
    PROPERTY_TYPE_BUILDING,
    PROPERTY_TYPE_BUILDING_FLOOR,
    PROPERTY_TYPE_HOUSE,
    PROPERTY_TYPE_ROOM,
    PROPERTY_TYPE_TOWNHOUSE,
    PROPERTY_TYPE_CITY,
    PROPERTY_TYPE_DISTRICT,
    //PROPERTY_TYPE_US_STATE,
    PROPERTY_TYPE_COMMUNITY,
    PROPERTY_TYPE_GATED_COMMUNITY,
    PROPERTY_TYPE_HOUSING_ESTATE,
    PROPERTY_TYPE_DORMITORY,
    PROPERTY_TYPE_DORMITORY_UNIT,
    PROPERTY_TYPE_DUPLEX,
    PROPERTY_TYPE_DUPLEX_UNIT,
    PROPERTY_TYPE_SUITE,
    PROPERTY_TYPE_CABIN,
    PROPERTY_TYPE_CHALET,
    PROPERTY_TYPE_COTTAGE,
    PROPERTY_TYPE_RETIREMENT_COMMUNITY,
    PROPERTY_TYPE_VILLA,
    PROPERTY_TYPE_ASSISTED_LIVING,
    PROPERTY_TYPE_VILLAGE
];

export const PROPERTY_TYPE_MAPPING = {
    [PROPERTY_TYPE_APARTMENT]: 'Apartment',
    [PROPERTY_TYPE_UNIT]: 'Unit',
    [PROPERTY_TYPE_APARTMENT_BUILDING]: 'Apartment Building',
    [PROPERTY_TYPE_APARTMENT_BUILDING_FLOOR]: 'Apartment Building Floor',
    [PROPERTY_TYPE_BUILDING]: 'Building',
    [PROPERTY_TYPE_BUILDING_FLOOR]: 'Building Floor',
    [PROPERTY_TYPE_HOUSE]: 'House',
    [PROPERTY_TYPE_ROOM]: 'Room',
    [PROPERTY_TYPE_TOWNHOUSE]: 'Townhouse',
    [PROPERTY_TYPE_CITY]: 'City',
    [PROPERTY_TYPE_DISTRICT]: 'District',
    //[PROPERTY_TYPE_US_STATE]: 'U.S. State',
    [PROPERTY_TYPE_COMMUNITY]: 'Community',
    [PROPERTY_TYPE_GATED_COMMUNITY]: 'Gated Community',
    [PROPERTY_TYPE_HOUSING_ESTATE]: 'Housing Estate',
    [PROPERTY_TYPE_DORMITORY]: 'Dormitory',
    [PROPERTY_TYPE_DORMITORY_UNIT]: 'Dormitory Unit',
    [PROPERTY_TYPE_DUPLEX]: 'Duplex',
    [PROPERTY_TYPE_DUPLEX_UNIT]: 'Duplex Unit',
    [PROPERTY_TYPE_SUITE]: 'Suite',
    [PROPERTY_TYPE_CABIN]: 'Cabin',
    [PROPERTY_TYPE_CHALET]: 'Chalet',
    [PROPERTY_TYPE_COTTAGE]: 'Cottage',
    [PROPERTY_TYPE_RETIREMENT_COMMUNITY]: 'Retirement Community',
    [PROPERTY_TYPE_VILLA]: 'Villa',
    [PROPERTY_TYPE_ASSISTED_LIVING]: 'Assisted Living',
    [PROPERTY_TYPE_VILLAGE]: 'Village',
};

export function getSinglePropertyTypeSuggestion(propertyType) {
    return {
        value: propertyType,
        label: PROPERTY_TYPE_MAPPING[propertyType],
    };
}

export const PROPERTY_TYPE_SUGGESTIONS_LIST = Object.keys(PROPERTY_TYPE_MAPPING).map(propTypeKey => ({
    value: propTypeKey,
    label: PROPERTY_TYPE_MAPPING[propTypeKey],
}));