/*

 */

import React from 'react';
import {connect} from "react-redux";

import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import {
    makeResourceUIDListByAccountSelector,
    resourceUIDListSelector
} from "../../selectors/resourceSelectors";


function UIDListContainer({resourceType, accountUID, DisplayComp, outgoingUIDListName = 'uidList', ...props}) {
    const dynamicProps = {
        [outgoingUIDListName]: accountUID === NULL_UUID_STRING ? props.uidListForAllAccounts : props.uidListForPMC
    };

    return <DisplayComp {...dynamicProps} />;
}

const mapStateToProps = (state) => {
    const _resourceUIDListSelector = resourceUIDListSelector();
    const _makeResourceUIDListByAccountSelector = makeResourceUIDListByAccountSelector();

    return function realMapState(state, props) {
        const resourceAndAccountInfo = {
            resourceType: props.resourceType,
            accountUID: props.accountUID
        };

        return {
            uidListForAllAccounts: _resourceUIDListSelector(state, props.resourceType),
            uidListForPMC: _makeResourceUIDListByAccountSelector(state, resourceAndAccountInfo)
        };
    }
};

export default connect(mapStateToProps)(React.memo(UIDListContainer));