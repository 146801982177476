/*

 */

import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorInfoDisplay from "../../../../components/utilities/displayAreas/ErrorInfoDisplay";
import {CIRCULAR_PROGRESS_DEFAULT_SIZE} from "../../../../constants/styleConstants";


function VisualizeContainerLoadingIndicator({loading, error, loadingResource, displayName}) {

    if (error === true) {
        return (
            <ErrorInfoDisplay showError={true}>
                Failed to load {displayName}.<br/>
                Please contact support if the issue persists.
            </ErrorInfoDisplay>
        );
    }

    if (loading === true) {
        return <CircularProgress size={CIRCULAR_PROGRESS_DEFAULT_SIZE} />;
    }

    if (loadingResource === undefined) {
        return <CircularProgress size={CIRCULAR_PROGRESS_DEFAULT_SIZE} />;
    }

    return <React.Fragment/>;
}


export default VisualizeContainerLoadingIndicator;