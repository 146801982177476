import React from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import {SYSTEM_STATS_DEFAULT_HEIGHT, SYSTEM_STATS_DEFAULT_WIDTH} from "../systemStatsConstants";
import {systemStatsCreatedTimestampToName} from "../../utilities/statsUtilities";


export function DailyPaymentStats({
                                      systemStats,
                                      width = SYSTEM_STATS_DEFAULT_WIDTH,
                                      height = SYSTEM_STATS_DEFAULT_HEIGHT,
                                      ...props
                                  }) {

    // Convert the created timestamp into the name for the tooltip
    systemStats.forEach(systemStatsCreatedTimestampToName);

    return (
        <React.Fragment>
            Payments<br/>
            <LineChart
                width={width}
                height={height}
                data={systemStats}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                {/*<Line type="monotone" dataKey="stats_json.ach_flagged_for_admin_review_stats.count" stroke="#aeeeb4" name='ACH Flagged Count'/>*/}
                {/*<Line type="monotone" dataKey="stats_json.ach_flagged_for_admin_review_stats.sum" stroke="#aeeeb4" name='ACH Flagged Sum'/>*/}

                <Line type="monotone" dataKey="stats_json.ach_success_payment_stats.count" stroke="#aeeeb4" name='ACH Success Count'/>
                {/*<Line type="monotone" dataKey="stats_json.ach_success_payment_stats.sum" stroke="#aeeeb4" name='ACH Success Sum'/>*/}

                {/*<Line type="monotone" dataKey="stats_json.stripe_declined_fees_stats.count" stroke="#aeeeb4" name="Stripe fees count"/>*/}
                {/*<Line type="monotone" dataKey="stats_json.stripe_declined_fees_stats.sum" stroke="#aeeeb4" name="Stripe Fees Sum"/>*/}

                {/*<Line type="monotone" dataKey="stats_json.stripe_flagged_for_admin_review_stats.count" stroke="#aeeeb4" name="Stripe Flagged Count"/>*/}
                {/*<Line type="monotone" dataKey="stats_json.stripe_flagged_for_admin_review_stats.sum" stroke="#aeeeb4"/>*/}

                {/*<Line type="monotone" dataKey="stats_json.stripe_high_risk_stats.count" stroke="#aeeeb4" name="Stripe High Risk Count"/>*/}
                {/*<Line type="monotone" dataKey="stats_json.stripe_high_risk_stats.sum" stroke="#aeeeb4" name="Stripe High Risk Sum"/>*/}

                <Line type="monotone" dataKey="stats_json.stripe_success_payment_stats.count" stroke="#aed1e0" name="Stripe Success Count"/>
                {/*<Line type="monotone" dataKey="stats_json.stripe_success_payment_stats.sum" stroke="#aeeeb4" name="Stripe Success Sum"/>*/}

            </LineChart>
        </React.Fragment>
    );

}
