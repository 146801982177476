

export function depositHasRhinoInfo(deposit){
    // Rhino IDs are -1 / 0 by default, so if any positive number is present...
    let rhinoIDArray = [
        deposit.rhino_property_id,
        deposit.rhino_unit_id,
        deposit.rhino_property_owner_id,
        deposit.rhino_policy_application_id
    ];
    let idSum = rhinoIDArray.reduce((a, b) => a + b, 0);

    return idSum > 0;
}