/*

 */

/*

 */

import React from 'react';

import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import AdminDisplayCard from "./adminDisplayCard";
import {dollarFormattedString} from "../../../utilities/commonUtils";
import CreatedUpdatedTimestampDisplay from "../../utilities/displayAreas/CreatedUpdatedTimestampDisplay";
import {faUsdCircle} from "@fortawesome/pro-solid-svg-icons";


export default function AdminDisplayUserAccountBalance({loading, error, userAccountBalance, ...props}) {

    if (loading === true || error === true || userAccountBalance === undefined) {
        return <VisualizeContainerLoadingIndicator loadingResource={userAccountBalance}
                                                   displayName={'User Account Balance'} {...props} />;
    }

    return (
        <AdminDisplayCard
            cardTitle={'Account Balance'}
            cardSubheader={''}
            headerIcon={faUsdCircle}
            jsonObject={userAccountBalance}
        >
            <b>Total balance:</b>:&nbsp;{dollarFormattedString(userAccountBalance.total_balance_in_cents)}<br/>
            <b>Free balance:</b>:&nbsp;{dollarFormattedString(userAccountBalance.free_balance_in_cents)}<br/>
            <b>Locked balance:</b>:&nbsp;{dollarFormattedString(userAccountBalance.locked_balance_in_cents)}<br/>

            <CreatedUpdatedTimestampDisplay
                createdTimestamp={userAccountBalance.created_timestamp}
                lastUpdatedTimestamp={userAccountBalance.last_updated_timestamp}
            />

        </AdminDisplayCard>

    );

}
