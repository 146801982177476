import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {adminActionLoadAllUIDS} from "../../actions/adminActions";
import {DEFAULT_BATCH_UID_LOAD_LIMIT, DEV_BUILD} from "../../constants/apiConstants";
import {
    newResourceUIDListSelector,
    resourceUIDListByAccountSelector
} from "../selectors/resourceSelectors";
import {NULL_UUID_STRING} from "../../constants/dataConstants";
import {
    checkLoadingIndicator,
    createLoadingIndicatorUID,
    createUIDListLoadingResourceUID
} from "../../utilities/loadingUtilities";
import {newLoadingIndicatorSelector} from "../../selectors/loadingSelectors";


const useUIDListLoad = (resourceType, sortingColumnName, accountUID, outgoingUIDListName) => {

    const accAndResInfo = {accountUID: accountUID, resourceType: resourceType};
    const resUID = createUIDListLoadingResourceUID(accountUID, resourceType);
    const loadingIndicatorKey = createLoadingIndicatorUID(resUID, resourceType);
    const loadingIndicator = useSelector(state => newLoadingIndicatorSelector(state, loadingIndicatorKey));

    const dispatch = useDispatch();

    // Use the general list selector if there's no account UID provided
    const uidList = useSelector(state => accountUID === NULL_UUID_STRING ? newResourceUIDListSelector(state, resourceType) : resourceUIDListByAccountSelector(state, accAndResInfo));

    useEffect(() => {
        const fetchFromServer = async () => {
            dispatch(adminActionLoadAllUIDS(resourceType, '', 0, DEFAULT_BATCH_UID_LOAD_LIMIT, sortingColumnName, accountUID, true));
        };
        fetchFromServer();
    }, []);

    const {loading, error, response} = checkLoadingIndicator(loadingIndicator);

    if(DEV_BUILD === true && error === true){
        throw new Error(`useUIDListLoad, res: ${resourceType}, sort: ${sortingColumnName}, ${accountUID}`);
    }

    // console.log(':: UID list hook', uidList);
    return {loading, error, [outgoingUIDListName]: uidList};
};

export default useUIDListLoad;