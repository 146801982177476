import React from "react";
import Moment from "react-moment";
import {connect} from "react-redux";
import {jsDateTimeFromString} from "../../utilities/commonUtils";
import {currentUserAccountDateDisplayFormatSelector} from "../../selectors/userSelectors";


const RenderTimestamp = ({timestamp, dateDisplayFormat}) => {
    return <Moment format={dateDisplayFormat} date={jsDateTimeFromString(timestamp, true)}/>;
}

const mapStateToProps = (state) => ({
    dateDisplayFormat: currentUserAccountDateDisplayFormatSelector(state)
});

export default connect(mapStateToProps)(RenderTimestamp);