/**
 * Copy Button
 *
 * Copy the data passed in to the clipboard
 */
import React, {useEffect, useState, Fragment} from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Tooltip from "@material-ui/core/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-light-svg-icons";
import {faClipboard} from "@fortawesome/pro-light-svg-icons";
import IconButton from "@material-ui/core/IconButton";

const COPIED_TOOL_TIP = <Fragment><FontAwesomeIcon icon={faCheck} fixedWidth color={'#1be82b'}/>Copied</Fragment>;

const CopyButton = ({dataToCopy, toolTipPostFixText}) => {
    const TOOL_TIP = `Click to copy ${toolTipPostFixText}`;
    const [toolTip, setToolTip] = useState(TOOL_TIP);

    useEffect(() => {
        if (toolTip === TOOL_TIP) return;
        const timer = setTimeout(() => setToolTip(TOOL_TIP), 2000);
        return () => clearTimeout(timer);
    }, [toolTip, TOOL_TIP]);

    return (
        <CopyToClipboard text={`${dataToCopy}`} onCopy={handleClick}>
            <Tooltip title={toolTip} placement={'bottom'}>
                <IconButton
                    aria-label={TOOL_TIP}
                    size={'small'}
                    color={'primary'}
                >
                    <FontAwesomeIcon
                        icon={faClipboard}
                        fixedWidth
                    />
                </IconButton>
            </Tooltip>
        </CopyToClipboard>
    );

    function handleClick() {
        setToolTip(COPIED_TOOL_TIP);
    }
}

export default CopyButton;
