// Name: Banking Body Render Definitions 

export const BANK_ACCOUNT_BALANCE_RECORD_BODY_RENDER_DEF = {
	amount_in_cents: {displayName: "Amount In Cents"},
	bank_account_uid: {displayName: "Bank Account UID"},
	deposify_tags: {displayName: "Deposify Tags"},
	notes: {displayName: "Notes"},
	record_uid: {displayName: "Record UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	recording_account_uid: {displayName: "Recording Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const BANK_ACCOUNT_BODY_RENDER_DEF = {
	bank_account_uid: {displayName: "Bank Account UID"},
	internal_type: {displayName: "Internal Type"},
	bank_name: {displayName: "Bank Name"},
	nickname: {displayName: "Nickname"},
	account_number: {displayName: "Account Number"},
	routing_number: {displayName: "Routing Number"},
	account_type: {displayName: "Account Type"},
	interest_enabled: {displayName: "Interest Enabled"},
	interest_rate_start_date: {displayName: "Interest Rate Start Date"},
	interest_rate_per_annum: {displayName: "Interest Rate Per Annum"},
	geo_state: {displayName: "Geo State"},
	address_line_one: {displayName: "Address Line One"},
	address_line_two: {displayName: "Address Line Two"},
	address_line_three: {displayName: "Address Line Three"},
	partner_account_uid: {displayName: "Partner Account UID"},
	deposify_tags: {displayName: "Deposify Tags"},
	created_timestamp: {displayName: "Created Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const BANK_ACCOUNT_HISTORICAL_INTEREST_RATE_BODY_RENDER_DEF = {
	rate_history_uid: {displayName: "Rate History UID"},
	bank_account_uid: {displayName: "Bank Account UID"},
	interest_rate_per_annum: {displayName: "Interest Rate Per Annum"},
	start_timestamp: {displayName: "Start"},
	end_timestamp: {displayName: "End"},
	deposify_tags: {displayName: "Deposify Tags"},
	created_timestamp: {displayName: "Created Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
};

export const BANK_ACCOUNT_INTEREST_BODY_RENDER_DEF = {
	amount_in_cents: {displayName: "Amount In Cents"},
	bank_account_uid: {displayName: "Bank Account UID"},
	archived: {displayName: "Archived"},
	deposify_tags: {displayName: "Deposify Tags"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	notes: {displayName: "Notes"},
	created_timestamp: {displayName: "Created Timestamp"},
	recording_account_uid: {displayName: "Recording Account UID"},
	interest_uid: {displayName: "Interest UID"}
};

export const BANK_TRANSFER_BODY_RENDER_DEF = {
	destination_bank_account_uid: {displayName: "Destination Bank Account UID"},
	state: {displayName: "State"},
	amount_in_cents: {displayName: "Amount In Cents"},
	source_bank_account_uid: {displayName: "Source Bank Account UID"},
	confirmation: {displayName: "Confirmation"},
	transfer_uid: {displayName: "Transfer UID"},
	deposify_tags: {displayName: "Deposify Tags"},
	notes: {displayName: "Notes"},
	created_timestamp: {displayName: "Created Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const BULK_FUNDS_TRANSFER_BODY_RENDER_DEF = {
	funds_destination_uid: {displayName: "Funds Destination UID"},
	amount_in_cents: {displayName: "Amount In Cents"},
	bulk_transfer_uid: {displayName: "Bulk Transfer UID"},
	funds_destination: {displayName: "Funds Destination"},
	funds_location: {displayName: "Funds Location"},
	funds_location_uid: {displayName: "Funds Location UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

