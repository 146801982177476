import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import EditEnabledDisabledComplianceDialog from "../deposits/EditEnabledDisabledComplianceDialog";

// TODO: These should be exported via the client export utility
export const DEPOSIT_COMPLIANCE_ID_GENERATE_ACTIVE_DEPOSIT_RECEIPTS = 0;
export const DEPOSIT_COMPLIANCE_ID_DAILY_DEPOSIT_INTEREST = 1;
export const DEPOSIT_COMPLIANCE_ID_QUARTERLY_REPORT_AND_PAYOUT = 2;

const TAG_DICT = {
    [DEPOSIT_COMPLIANCE_ID_GENERATE_ACTIVE_DEPOSIT_RECEIPTS]: {
        text: 'Receipts',
        color: 'primary',
        tooltip: 'Automatic receipt applied to deposit'
    },
    [DEPOSIT_COMPLIANCE_ID_DAILY_DEPOSIT_INTEREST]: {
        text: 'Daily interest',
        color: 'primary',
        tooltip: 'Daily interest'
    },
    [DEPOSIT_COMPLIANCE_ID_QUARTERLY_REPORT_AND_PAYOUT]: {
        text: 'Quarterly interest',
        color: 'primary',
        tooltip: 'Quarterly interest payout and email report'
    }
};

const useStyles = makeStyles(theme => ({
    standardTag: {
        margin: 5,
        marginLeft: 0
    }
}));

export default function RenderComplianceTags({deposit, completedMode, ...props}) {
    const classes = useStyles();

    const complianceIDs = completedMode === true ? deposit.completed_compliances : deposit.disabled_compliances;

    return (
        <React.Fragment>
            {
                complianceIDs.map(
                    complianceID => {
                        const tagInfo = TAG_DICT[complianceID];

                        if (tagInfo === undefined) {
                            return (
                                <Chip
                                    variant="default"
                                    color='secondary'
                                    size="small"
                                    avatar={<Avatar>XX</Avatar>}
                                    label={
                                        <React.Fragment>
                                            {complianceID} - BROKEN COMPLIANCE ID TAG - TELL DAVE
                                        </React.Fragment>
                                    }
                                    className={classes.standardTag}
                                />
                            );
                        }


                        return (
                            <Tooltip
                                title={tagInfo.tooltip}
                                placement={'top'}
                            >
                                <Chip
                                    variant="default"
                                    color='primary'
                                    size="small"
                                    avatar={<Avatar>{tagInfo.text[0]}</Avatar>}
                                    label={tagInfo.text}
                                    className={classes.standardTag}
                                />
                            </Tooltip>
                        );
                    }
                )
            }

            {
                complianceIDs.length === 0 && <i>None</i>
            }

            <br/>
            <EditEnabledDisabledComplianceDialog deposit={deposit} completedMode={completedMode}/>
        </React.Fragment>
    )

}