/*

 */
import {
    SET_DEPOSIT_LOADING_STATE,
    SET_DEPOSIT_META_DATA_LOADING_STATE,
    SET_DEPOSIT_INVITE_LOADING_STATE,
    SET_BASIC_USER_INFO_LOADING_STATE,
    SET_ATTACHED_FILES_LOADING_STATE,
    SET_STORAGE_FILE_LOADING_STATE,
    SET_PROPERTY_LOADING_STATE,
    SET_INVOICE_LOADING_STATE,
    SET_PROPERTY_ADDRESS_LOADING_STATE,
    SET_LOADING_INDICATOR,
    CLEAR_LOADING_INDICATOR,
    SET_LAST_LOAD_TIMESTAMP,
    CLEAR_LAST_LOAD_TIMESTAMP
} from "../actions/loadingActionConstants";
import {createLoadingIndicatorUID} from "../utilities/loadingUtilities";
import {APP_EVENT_CLEAR_ALL, APP_EVENT_SET_LOGGED_OUT} from "../clientShared/actions/appActionTypes";


// TODO: We should have dummy data here
const initialLoadingReducerState = {
    deposits: {},
    depositMetaData: {},
    depositInvites: {},
    basicUserInfo: {},
    attachedFiles: {},
    storageFiles: {},
    properties: {},
    invoices: {},
    disputes: {},
    disputeSplits: {},
    propertyAddresses: {},
    loadingIndicators: {},
    lastLoadTimestamps: {}
};

export default function loadingReducer(state = initialLoadingReducerState, action) {
    switch (action.type) {

        case APP_EVENT_SET_LOGGED_OUT:
            return {...state};

        // Clear everything, E.g. logout of the app
        case APP_EVENT_CLEAR_ALL:
            return initialLoadingReducerState;

        case SET_LOADING_INDICATOR:
            let updatedLoadingIndicators = {...state.loadingIndicators};
            const resourceKey = createLoadingIndicatorUID(action.resourceUID, action.resourceType);
            updatedLoadingIndicators[resourceKey] = {
                state: action.newState,
                response: action.response
            };
            return {...state, ...{loadingIndicators: updatedLoadingIndicators}};

        case CLEAR_LOADING_INDICATOR:
            let clearLastLoadTimestamp = {...state.lastLoadTimestamps};
            const clearTSKey = createLoadingIndicatorUID(action.resourceUID, action.resourceType);
            delete clearLastLoadTimestamp[clearTSKey];
            return {...state, ...{lastLoadTimestamps: clearLastLoadTimestamp}};

        case SET_LAST_LOAD_TIMESTAMP:
            let updatedLastLoadTimestamp = {...state.lastLoadTimestamps};
            const tsResourceKey = createLoadingIndicatorUID(action.resourceUID, action.resourceType);
            updatedLastLoadTimestamp[tsResourceKey] = action.newTimestamp;
            return {...state, ...{lastLoadTimestamps: updatedLastLoadTimestamp}};

        case CLEAR_LAST_LOAD_TIMESTAMP:
            let clearUpdatedLoadingIndicators = {...state.loadingIndicators};
            const clearResourceKey = createLoadingIndicatorUID(action.resourceUID, action.resourceType);
            delete clearUpdatedLoadingIndicators[clearResourceKey];
            return {...state, ...{loadingIndicators: clearUpdatedLoadingIndicators}};

        // Set the state of a deposit
        case SET_DEPOSIT_LOADING_STATE:
            let updatedDepositLoadingStates = {...state.deposits};
            updatedDepositLoadingStates[action.depositUID] = action.depositLoadingState;
            return {...state, ...{deposits: updatedDepositLoadingStates}};

        // Set the state of a deposit meta data
        case SET_DEPOSIT_META_DATA_LOADING_STATE:
            let updatedDepositMetaDataLoadingStates = {...state.depositMetaData};
            updatedDepositMetaDataLoadingStates[action.depositUID] = action.depositMetaDataLoadingState;
            return {...state, ...{depositMetaData: updatedDepositMetaDataLoadingStates}};

        // Set the state of a deposit invite
        case SET_DEPOSIT_INVITE_LOADING_STATE:
            let updatedDepositInviteLoadingStates = {...state.depositInvites};
            updatedDepositInviteLoadingStates[action.depositUID] = action.depositInviteLoadingState;
            return {...state, ...{depositInvites: updatedDepositInviteLoadingStates}};

        // Set the state of the basic user info that's loading
        case SET_BASIC_USER_INFO_LOADING_STATE:
            let updatedBasicUserInfoLoadingStates = {...state.basicUserInfo};
            updatedBasicUserInfoLoadingStates[action.accountUID] = action.basicUserInfoLoadingState;
            return {...state, ...{basicUserInfo: updatedBasicUserInfoLoadingStates}};

        // Set the state of the attached files info that's loading
        case SET_ATTACHED_FILES_LOADING_STATE:
            let updatedAttachedFilesLoadingStates = {...state.attachedFiles};
            updatedAttachedFilesLoadingStates[action.attachedFilesUID] = action.attachedFilesLoadingState;
            return {...state, ...{attachedFiles: updatedAttachedFilesLoadingStates}};

        // Set the state of the attached files info that's loading
        case SET_STORAGE_FILE_LOADING_STATE:
            let updatedStorageFileLoadingStates = {...state.storageFiles};
            updatedStorageFileLoadingStates[action.fileUID] = action.storageFileLoadingState;
            return {...state, ...{storageFiles: updatedStorageFileLoadingStates}};

        // Set the state of the attached files info that's loading
        case SET_PROPERTY_LOADING_STATE:
            let updatedPropertyLoadingStates = {...state.properties};
            updatedPropertyLoadingStates[action.propertyUID] = action.propertyLoadingState;
            return {...state, ...{properties: updatedPropertyLoadingStates}};

        // Set the state of the attached files info that's loading
        case SET_INVOICE_LOADING_STATE:
            let updatedInvoiceLoadingStates = {...state.invoices};
            updatedInvoiceLoadingStates[action.invoiceUID] = action.invoiceLoadingState;
            return {...state, ...{invoices: updatedInvoiceLoadingStates}};

        // Set the state of the attached files info that's loading
        case SET_PROPERTY_ADDRESS_LOADING_STATE:
            let updatedProperty_addressLoadingStates = {...state.propertyAddresses};
            updatedProperty_addressLoadingStates[action.propertyAddressUID] = action.propertyAddressLoadingState;
            return {...state, ...{propertyAddresses: updatedProperty_addressLoadingStates}};

        // Default
        default:
            return state;

    }
}
