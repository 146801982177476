/*

 */

export const UPDATE_USER = 'users.updateUser';
export const UPDATE_USER_ACCOUNT_BALANCE = 'users.updateUserAccountBalance';
export const LOG_USER_OUT = 'users.logout';
export const UPDATE_INTERNATIONAL_BANK_ACCOUNTS = 'user.updateInternationalBankAccounts';
export const UPDATE_US_DOMESTIC_BANK_ACCOUNTS = 'user.updateUSDomesticBankAccounts';
export const ADD_NEW_US_DOMESTIC_USER_BANK_ACCOUNT = 'USER:ADD_NEW_US_DOM_USER_BANK_ACCOUNT';
export const ADD_NEW_INTERNATIONAL_USER_BANK_ACCOUNT = 'USER:ADD_NEW_INTERNATIONAL_USER_BANK_ACCOUNT';
export const UPDATE_BASIC_USER_INFO = 'USER:UPDATE_BASIC_USER_INFO';
export const UPDATE_USER_ACCOUNT_SETTINGS = 'USER:UPDATE_USER_ACCOUNT_SETTINGS';
export const UPDATE_PARTIAL_USER_ACCOUNT_SETTINGS = 'USER:UPDATE_PARTIAL_USER_ACCOUNT_SETTINGS';
export const USER_SET_PENDING_EMAIL_CHANGE = 'USER:SET_PENDING_EMAIL_CHANGE';
export const USER_EVENT_UPDATE_USER_ACCOUNT_HISTORY = 'USER:UPDATE_USER_ACCOUNT_HISTORY';
export const USER_EVENT_UPDATE_USER_ACCOUNT_BALANCE_LOG = 'USER:UPDATE_USER_ACCOUNT_BALANCE_LOG';
