/*

 */

import React from 'react';
import * as Sentry from '@sentry/browser';
import {axiosInstance} from "../../../../utilities/axiosInstance";
import Link from "@material-ui/core/Link";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getAdminPath} from "../../../../api/adminAPI";
import {
    adminActionRemoveFileAfterDelete,
    adminActionSetFullScreenFileInfo,
} from "../../../../actions/adminActions";
import ConfirmationDialog from "../../../utilities/dialogs/ConfirmationDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/pro-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {fullScreenFileInfoSelector, makeAttachedFilesWithFileSelector} from "../../../../selectors/adminSelectors";
import toast from "react-hot-toast";

function DeleteStorageFileLink({fileUID, deletingAccountUID, useIconButton = false, useButton = false, ...props}) {
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);

    return (
        <React.Fragment>
            <ConfirmationDialog
                startOpen={showDialog}
                dangerMode={true}
                confirmCallback={deleteTheStorageFile}
                cancelCallback={handleClose}
                dialogTitle={'Delete File'}
                dialogText={'Are you sure you sure you want to delete this file?'}
                confirmText={'Delete'}
                showSpinner={showSpinner}
            />

            {
                useButton === true &&
                <Button
                    color="secondary"
                    onClick={handleOpen}
                >
                    <FontAwesomeIcon
                        icon={faTrashAlt}
                        fixedWidth
                    />
                    &nbsp;Delete
                </Button>
            }
            {
                useIconButton === false && useButton === false &&
                <Link
                    component="button"
                    variant="body2"
                    onClick={handleOpen}
                >
                    Delete
                </Link>
            }
            {
                useIconButton === true &&
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={handleOpen}
                >
                    <FontAwesomeIcon
                        icon={faTrashAlt}
                        fixedWidth
                    />
                </IconButton>
            }
        </React.Fragment>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
        setShowSpinner(false);
    }

    function deleteTheStorageFile() {
        setShowSpinner(true);

        // Cancel the deposit
        const deleteURL = getAdminPath(`storage/files/${fileUID}/delete/${deletingAccountUID}`);

        axiosInstance.delete(deleteURL)
            .then(function (response) {

                if (props.fullScreenFileInfo.fileUID === fileUID) {
                    props.adminActionSetFullScreenFileInfo({
                        showDialog: false,
                        fileUID: undefined
                    });
                }

                props.adminActionRemoveFileAfterDelete(fileUID);
                for (const attachedFilesUID of props.localAttachedFilesWithThisFile) {
                    props.adminLoadingActionLoadAttachedStorageFilesFromServer(attachedFilesUID);
                }

                handleClose();

            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);

                toast.error('Failed to remove file');
            });
    }

}

const mapStateToProps = (state) => {
    const _makeAttachedFilesWithFileSelector = makeAttachedFilesWithFileSelector();

    return function realMapState(state, props) {
        return {
            localAttachedFilesWithThisFile: _makeAttachedFilesWithFileSelector(state, props.fileUID),
            fullScreenFileInfo: fullScreenFileInfoSelector(state)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionRemoveFileAfterDelete: adminActionRemoveFileAfterDelete,
        adminActionSetFullScreenFileInfo: adminActionSetFullScreenFileInfo
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(DeleteStorageFileLink);
