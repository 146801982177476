/*

 */

import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import VisualizeContainerLoadingIndicator
    from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import ErrorInfoDisplay from "../../utilities/displayAreas/ErrorInfoDisplay";
import AdminDisplayPermissionEntry from "./adminDisplayPermissionEntry";
import Grid from "@material-ui/core/Grid";
import DisplayDepositModel from "./deposits/displayDepositModel";
import AdminDisplayAttachedFiles from "./adminDisplayAttachedFiles";
import {AdminDepositFilePondCard} from "../controls/adminDepositFilePondCard";
import CloseRequestDisplayHOC from "./CloseRequestDisplayHOC";
import {
    AttachedStorageFilesContainer,
    PermissionEntryContainer
} from "../../../autoGenerated/components/containers/resourceContainers";

function AdminDisplayDeposit({loading, error, deposit, ...props}) {
    if (error === true) {
        return (
            <ErrorInfoDisplay showError={true}>
                Failed to load deposit
            </ErrorInfoDisplay>
        );
    }

    if (deposit === undefined) {
        if (loading === true || error === true || deposit === undefined) {
            return <VisualizeContainerLoadingIndicator loadingResource={deposit} displayName={'Deposit'} {...props} />;
        }
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
            >

                <Grid item>
                    <DisplayDepositModel loading={loading} error={error} deposit={deposit}/>
                </Grid>

                <Grid item>
                    <CloseRequestDisplayHOC closeRequestUID={deposit.active_close_request_uid}/>
                </Grid>

                {/*{*/}
                {/*    deposit.state === DEPOSIT_STATE_PENDING &&*/}
                {/*    <Grid item>*/}
                {/*        <DepositInviteContainer inviteUID={deposit.deposit_uid}>*/}
                {/*            <AdminDisplayDepositInvite/>*/}
                {/*        </DepositInviteContainer>*/}
                {/*    </Grid>*/}
                {/*}*/}

                <Grid item>
                    <AttachedStorageFilesContainer attachedFilesUID={deposit.attached_files_uid}>
                        <AdminDisplayAttachedFiles/>
                    </AttachedStorageFilesContainer>
                </Grid>

                <Grid item>
                    <PermissionEntryContainer resourceUID={deposit.deposit_uid}>
                        <AdminDisplayPermissionEntry/>
                    </PermissionEntryContainer>
                </Grid>

            </Grid>

            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                spacing={1}
            >

                <Grid item>
                    <AdminDepositFilePondCard
                        deposit={deposit}
                        attachedFilesUID={deposit.attached_files_uid}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    );

}

const mapStateToProps = (state) => ({});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(AdminDisplayDeposit);