import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {faFileInvoiceDollar, faSkull} from "@fortawesome/pro-light-svg-icons";
import SpinnerButton from "../../../utilities/buttons/SpinnerButton";
import {bindActionCreators} from "redux";
import {
    loadingActionLoadUserAccountBalanceFromServer,
    loadingActionLoadUserWithdrawFromServer
} from "../../../../autoGenerated/actions/resourceLoadingActions";
import {connect} from "react-redux";
import {getAdminPath} from "../../../../api/adminAPI";
import {axiosInstance} from "../../../../utilities/axiosInstance";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/browser";


const UserWithdrawalMarkCompleteButton = ({
                                              userWithdrawal,
                                              loadingActionLoadUserWithdrawFromServer,
                                              loadingActionLoadUserAccountBalanceFromServer
                                          }) => {
    const buttonEnabled = userWithdrawal.state === "pending" || userWithdrawal.state === "payment_pending";
    const [showDialog, setShowDialog] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    return (
        <React.Fragment>
            <SpinnerButton
                // Non-button props
                showSpinner={showSpinner}
                useDangerColor={true}

                // Button props
                onClick={handleOpen}
                color={'secondary'}
                autoFocus={false}
                variant={'contained'}
                disabled={!buttonEnabled | showSpinner}
            >
                <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    fixedWidth
                />
                &nbsp;Set Withdrawal Complete
            </SpinnerButton>

            {/*<Button*/}
            {/*    color="secondary"*/}
            {/*    onClick={handleOpen}*/}
            {/*    variant={'outlined'}*/}
            {/*    // size={'small'}*/}
            {/*    disabled={!buttonEnabled}*/}
            {/*>*/}
            {/*    <FontAwesomeIcon*/}
            {/*        icon={faFileInvoiceDollar}*/}
            {/*        fixedWidth*/}
            {/*    />*/}
            {/*    &nbsp;Set Withdrawal Complete*/}
            {/*</Button>*/}

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="jeff-credit-dialog-title"
                aria-describedby="jeff-credit-description"
            >
                <DialogTitle id="account-dialog-title">Set Withdrawal Complete</DialogTitle>
                <DialogContent>
                    Are you sure you want to mark this withdrawal as complete?
                    <br/>
                    <br/>
                    This will <strong><i>PERMANENTLY</i></strong> remove the funds from the user's account.
                    <br/>
                    <br/>

                    <Button
                        color="secondary"
                        onClick={handleConfirm}
                        disabled={!buttonEnabled}
                    >
                        <FontAwesomeIcon
                            icon={faSkull}
                            fixedWidth
                        />
                        &nbsp;Yes, Mark As Complete
                    </Button>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
    }

    function handleConfirm() {
        setShowDialog(false);
        setShowSpinner(true);

        const baseURL = `jeff/withdrawal/${userWithdrawal.withdraw_uid}/complete`;
        const callURL = getAdminPath(baseURL);

        const postPromise = axiosInstance.post(callURL, {})
            .then(function (response) {
                loadingActionLoadUserWithdrawFromServer(userWithdrawal.withdraw_uid).then(() => {
                    setShowSpinner(false);
                    setShowSpinner(false);
                })

                loadingActionLoadUserAccountBalanceFromServer(userWithdrawal.account_uid);
            })
            .catch(function (error) {
                setShowSpinner(false);
                setShowSpinner(false);

                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            });

        toast.promise(
            postPromise,
            {
                loading: 'Marking...',
                success: 'Marked as complete',
                error: 'Unable to mark as complete',
            }
        ).then(() => {
            setShowSpinner(false);
        });

    }
};


const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadingActionLoadUserWithdrawFromServer: loadingActionLoadUserWithdrawFromServer,
        loadingActionLoadUserAccountBalanceFromServer: loadingActionLoadUserAccountBalanceFromServer
    }, dispatch);
};

export default connect(null, mapActionsToProps)(UserWithdrawalMarkCompleteButton);