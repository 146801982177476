import React, {useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {useOrganizationLoader} from "../../../../autoGenerated/hooks/resourceLoadingHooks";
import {ClickAwayListener, makeStyles, Popover} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ClickToCopy from "../../../../clientShared/components/utility/ClickToCopy";

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: "none"
    },
    popoverContent: {
        pointerEvents: "auto"
    },
    contents: {
        margin: 18,
        marginRight: 32,
        maxWidth: 400
    },
}));


export const OrgDisplayPopper = ({
                                     title, description, dataArray, displayText
                                 }) => {
    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);

    const classes = useStyles();
    const bottomMargin = dataArray.length === 0 ? 8 : 18;

    const popoverEnter = ({currentTarget}) => {
        setOpenedPopover(true);
    };

    const popoverLeave = ({currentTarget}) => {
        setOpenedPopover(false);
    };

    const handleClick = (clickEvent) => {
        clickEvent.preventDefault();
        clickEvent.stopPropagation();
        if (openedPopover) {
            popoverLeave(clickEvent.currentTarget);
        } else {
            popoverEnter(clickEvent.currentTarget);
        }
    }

    return (
        <>
            <ClickAwayListener onClickAway={popoverLeave}>
                <span
                    ref={popoverAnchor}
                    onClick={handleClick}
                    onMouseEnter={popoverEnter}
                    onMouseLeave={popoverLeave}
                >
                    {displayText}
                </span>
            </ClickAwayListener>

            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent
                }}
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{onMouseEnter: popoverEnter, onMouseLeave: popoverLeave}}
                disableScrollLock
            >
                <div className={classes.contents}>
                    <Typography variant={"h6"} gutterBottom align={"center"}>
                        {title}
                    </Typography>

                    <Typography variant={"body2"} gutterBottom style={{fontWeight: 300, marginBottom: bottomMargin}}>
                        {description}
                    </Typography>

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        spacing={2}
                    >
                        {
                            dataArray.map((currentData, dataIndex) => {
                                return (
                                    <Grid item key={`funds-info-${dataIndex}`}>
                                        <ClickToCopy dataToCopy={currentData}/>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>

                </div>
            </Popover>

        </>
    );
};

const OrgDisplay = ({organizationUID}) => {
    const {loading, error, organization} = useOrganizationLoader(organizationUID);

    if (error) {
        return <>Failed to load org {organizationUID}</>;
    }

    if (loading || !organization) {
        return <>Loading...</>;
    }

    return (
        <OrgDisplayPopper
            displayText={organization.name}
            title={organization.name}
            description={organization.description}
            dataArray={[
                organizationUID
            ]}
        />
    );
};


const OrgListing = ({sourceObject, uidListName = "organization_uids"}) => {
    const orgUIDList = sourceObject[uidListName];

    if (orgUIDList.length === 0) {
        return <i>None</i>;
    }

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            {
                orgUIDList.map((orgUID, uidIndex) => {
                    return (
                        <Grid item key={`org-${uidIndex}`}>
                            {/*<OrgDisplay organizationUID={orgUID}/>*/}
                            <ClickToCopy dataToCopy={orgUID}/>
                        </Grid>
                    );
                })
            }
        </Grid>
    );
};

export default OrgListing;