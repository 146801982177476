// Name: Frontend Service Custom Render Components 

import {DEPOSIT_OBJECT_KEY, DEPOSIT_OBJECT_KEY_TENANT_ACCOUNT_UID, OBJECT_KEY_ACCOUNT_UID} from "../objects/objectKeys";
import DepositPartyDisplay from "../../../components/admin/displays/DepositPartyDisplay";
import UserAccountNameDisplay from "../../../components/accounts/infoDisplays/userAccountNameDisplay";
import UserAccountNameDisplayModern from "../../../components/accounts/infoDisplays/userAccountNameDisplayModern";

export const FRONTEND_GENERAL_SETTINGS_CUSTOM_RENDER_COMPONENTS = {};

export const ONLINE_USER_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_ACCOUNT_UID]: {
        dataPropName: 'accountUID',
        renderComp: UserAccountNameDisplayModern,
        passDataObject: false
    },
};
