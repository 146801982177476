/*
    Display the roles for a user account
 */

import React from 'react';
import * as Sentry from "@sentry/browser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import {getUserAccountRoleIcon} from "../../../../constants/style/icons";
import {getUserAccountRoleDisplayName} from "../../../../constants/style/displayData";
import {getUserAccountRoleColour} from "../../../../constants/style/colours";
import {DEV_BUILD} from "../../../../../constants/apiConstants";


const UserAccountRolesDisplay = ({userAccount}) => {

    // Sanity check - if the default role is missing from the roles list
    if (userAccount.roles.includes(userAccount.default_role) === false) {
        Sentry.withScope(scope => {
            scope.setExtra("account_uid", userAccount.account_uid);
            scope.setExtra("default_role", userAccount.default_role);
            scope.setExtra("roles", userAccount.roles);
            Sentry.captureMessage('Invalid user roles', 'error');
        });
        if(DEV_BUILD === true){
            throw new Error(`Account doe not have a default role: ${userAccount.account_uid}`);
        }
    }

    const hasMultipleRoles = userAccount.roles.length > 1;

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            wrap={'nowrap'}
            spacing={1}
        >

            {
                userAccount.roles.map(userRole => {
                    const isDefaultRole = userRole === userAccount.default_role;
                    const roleIcon = getUserAccountRoleIcon(userRole);
                    const roleDisplayName = getUserAccountRoleDisplayName(userRole);
                    const colour = getUserAccountRoleColour(userRole);

                    return (
                        <Grid item key={`role-display-${userRole}`}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            wrap={'nowrap'}
                            spacing={1}
                        >
                            <Grid item>
                                <Tooltip
                                    title={roleDisplayName}
                                    placement={'top'}
                                >
                                    <span>
                                        <FontAwesomeIcon
                                            icon={roleIcon}
                                            size={'2x'}
                                            fixedWidth
                                            color={colour}
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                &nbsp;{roleDisplayName}
                            </Grid>

                            {
                                isDefaultRole === true && hasMultipleRoles === true &&
                                <Grid item>
                                    <i>(Default)</i>
                                </Grid>
                            }

                        </Grid>
                        </Grid>
                    );
                })
            }
        </Grid>
    );

}

export default UserAccountRolesDisplay;