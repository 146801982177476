import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import {faPencil} from "@fortawesome/pro-light-svg-icons";

export const PERMISSION_ID_READ = 0;
export const PERMISSION_ID_WRITE = 1;
export const PERMISSION_ID_DELETE = 2;
export const PERMISSION_ID_CREATE_DEPOSIT = 3;
export const PERMISSION_ID_CREATE_BANK_ACCOUNT_US_DOMESTIC = 4;
export const PERMISSION_ID_CREATE_BANK_ACCOUNT_INTERNATIONAL = 5;
export const PERMISSION_ID_CREATE_WITHDRAWAL = 6;
export const PERMISSION_ID_CREATE_PROPERTY = 7;
export const PERMISSION_ID_UPLOAD_FILES = 8;
export const PERMISSION_ID_DOWNLOAD_FILES = 9;
export const PERMISSION_ID_CHANGE_EMAIL = 10;
export const PERMISSION_ID_CHANGE_PASSWORD = 11;

const PERMISSIONS_DISPLAY_DICT = {
    [PERMISSION_ID_READ]: {text: 'Read', icon: faPencil},
    [PERMISSION_ID_WRITE]: {text: 'Write', icon: faPencil},
    [PERMISSION_ID_DELETE]: {text: 'Delete', icon: faPencil},
    [PERMISSION_ID_CREATE_DEPOSIT]: {text: 'Create Deposit', icon: faPencil},
    [PERMISSION_ID_CREATE_BANK_ACCOUNT_US_DOMESTIC]: {text: 'Create Bank Acc - Dom', icon: faPencil},
    [PERMISSION_ID_CREATE_BANK_ACCOUNT_INTERNATIONAL]: {text: 'Create Bank Acc - Int', icon: faPencil},
    [PERMISSION_ID_CREATE_WITHDRAWAL]: {text: 'Create Withdrawal', icon: faPencil},
    [PERMISSION_ID_CREATE_PROPERTY]: {text: 'Create Property', icon: faPencil},
    [PERMISSION_ID_UPLOAD_FILES]: {text: 'Upload Files', icon: faPencil},
    [PERMISSION_ID_DOWNLOAD_FILES]: {text: 'Download Files', icon: faPencil},
    [PERMISSION_ID_CHANGE_EMAIL]: {text: 'Change Email', icon: faPencil},
    [PERMISSION_ID_CHANGE_PASSWORD]: {text: 'Change Password', icon: faPencil}
};

// TODO: Add styles and colours for the icon later
export function renderPermissionsList(permissionsList) {

    return (
        permissionsList.map(
            renderTag => {
                const tagInfo = PERMISSIONS_DISPLAY_DICT[renderTag];
                if(tagInfo === undefined){
                }
                return (
                    <Chip
                        variant="default"
                        color='primary'
                        size="small"
                        avatar={<Avatar>{tagInfo.text[0]}</Avatar>}
                        label={tagInfo.text}
                    />
                );
            }
        )
    )

}