// Name: History Table Render Definitions 

export const SNAPSHOT_TABLE_RENDER_DEF = {
	snapshot_uid: {displayName: "Snapshot UID"},
	snapshot_type: {displayName: "Snapshot Type"},
	resource_uid: {displayName: "Resource UID"},
	resource_type: {displayName: "Resource Type"},
	created_timestamp: {displayName: "Created Timestamp"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// account_uid: {displayName: "Account UID"},
	// payload: {displayName: "Payload"}
};

export const SYSTEM_HISTORY_ENTRY_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	entry_uid: {displayName: "Entry UID"},
	entry_payload: {displayName: "Entry Payload"},
	deposify_only: {displayName: "Deposify Only"},
	resource_uid: {displayName: "Resource UID"},
	resource_type: {displayName: "Resource Type"},
	visible_to_account_uids: {displayName: "Visible To Account UIDs"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	event_id: {displayName: "Event Id"},
	event_type: {displayName: "Event Type"},
	deposify_tags: {displayName: "Deposify Tags"}
};

export const DEPOSIT_HISTORY_ENTRY_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	event_id: {displayName: "Event Id"},
	event_type: {displayName: "Event Type"},
	entry_payload: {displayName: "Entry Payload"},
	account_uid: {displayName: "Account UID"},
	// deposify_only: {displayName: "Deposify Only"},
	resource_uid: {displayName: "Resource UID"},
	// resource_type: {displayName: "Resource Type"},
	// visible_to_account_uids: {displayName: "Visible To Account UIDs"},
	entry_uid: {displayName: "Entry UID"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// deposify_tags: {displayName: "Deposify Tags"}
};

export const UPDATE_LOG_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	entry_uid: {displayName: "Entry UID"},
	entry_type: {displayName: "Entry Type"},
	update_note: {displayName: "Update Note"},
	resource_type: {displayName: "Resource Type"},
	resource_uid: {displayName: "Resource UID"},
	account_uid: {displayName: "Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	payload: {displayName: "Payload"}
};

export const USER_HISTORY_ENTRY_TABLE_RENDER_DEF = {
	entry_uid: {displayName: "Entry UID"},
	account_uid: {displayName: "Account UID"},
	resource_uid: {displayName: "Resource UID"},
	resource_type: {displayName: "Resource Type"},
	event_id: {displayName: "Event Id"},
	event_type: {displayName: "Event Type"},
	created_timestamp: {displayName: "Created Timestamp"},
	// entry_payload: {displayName: "Entry Payload"},
	// deposify_only: {displayName: "Deposify Only"},
	// visible_to_account_uids: {displayName: "Visible To Account UIDs"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// deposify_tags: {displayName: "Deposify Tags"}
};

