/*
    App Store
 */
import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore
} from "redux"
import thunk from "redux-thunk";
import authReducers from "../reducers/authReducers"
import userReducer from "../reducers/userReducer"
import appReducers from "../reducers/appReducers"
import loadingReducer from "../reducers/loadingRecucer";
import adminReducer from "../reducers/adminReducers";
import resourceReducer from "../clientShared/reducers/resourceReducer";
import generalLoaderReducer from "../clientShared/reducers/GeneralLoadingReducer";
import genericUIReducer from "../clientShared/reducers/GenericUIReducer";

const allReducers = combineReducers({
    app: appReducers,
    auth: authReducers,
    user: userReducer,
    loading: loadingReducer,
    admin: adminReducer,
    resources: resourceReducer,
    generalLoader: generalLoaderReducer,
    genericUI: genericUIReducer
});

const allStoreEnhancers = compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


export const store = createStore(
    allReducers,
    allStoreEnhancers
);