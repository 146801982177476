import React, {useState} from 'react';
import RhinoPropertySearchByIDField from "./RhinoPropertySearchByIDField";
import PropertySelectListHack from "./PropertySelectListHack";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/pro-regular-svg-icons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {usePropertyLoader} from "../../autoGenerated/hooks/resourceLoadingHooks";
import {NULL_UUID_STRING} from "../../constants/dataConstants";
import IF from "../../clientShared/components/utility/IFComponent";
import Typography from "@material-ui/core/Typography";
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/browser";


const SmallPropertyCard = ({propertyUID}) => {
    const {loading, error, property} = usePropertyLoader(propertyUID);

    if (error) {
        return <>Failed to load</>;
    }

    if (!property) {
        return <>Loading...</>;
    }

    return (
        <>
            <Typography color="textSecondary">
                Current Property
            </Typography>
            <Typography variant="h6">
                {property.property_type}: {property.title}, {property.unit_apt_number}
            </Typography>
        </>
    );
};


const RhinoEditDepositProperty = ({deposit}) => {
    const [propertyUIDList, setPropertyUIDList] = useState([]);
    const [selectedPropertyUID, setSelectedPropertyUID] = useState(deposit.property_uid);
    const [showDialog, setShowDialog] = React.useState(false);
    const showUpdateButton = selectedPropertyUID !== deposit.property_uid;
    const depHasProperty = deposit.property_uid !== NULL_UUID_STRING;

    return (
        <div>
            <Button
                color="primary"
                onClick={handleOpen}
                variant={'outlined'}
                // size={'small'}
            >
                <FontAwesomeIcon
                    icon={faPencil}
                    fixedWidth
                />
                &nbsp;Edit Property By Rhino ID
            </Button>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="jeff-credit-dialog-title"
                aria-describedby="jeff-credit-description"
                maxWidth={'md'}
                fullWidth={true}
            >
                <DialogTitle id="account-dialog-title">Edit Deposit Property by Rhino ID</DialogTitle>
                <DialogContent>

                    <IF condition={depHasProperty}>
                        <SmallPropertyCard propertyUID={deposit.property_uid}/>
                    </IF>
                    <IF condition={!depHasProperty}>
                        <i>No current property</i>
                    </IF>
                    <br/>

                    <Typography color="textSecondary" gutterBottom>
                        Update Property
                    </Typography>

                    <RhinoPropertySearchByIDField uidListCallback={setPropertyUIDList}/>
                    <PropertySelectListHack
                        propertyUIDList={propertyUIDList}
                        selectedPropertyUID={selectedPropertyUID}
                        setSelectedCallback={setSelectedPropertyUID}
                    />

                </DialogContent>
                <DialogActions>

                    <Button onClick={handleUpdate} color="primary" variant={"contained"} disabled={!showUpdateButton}>
                        Update Deposit
                    </Button>

                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
    }

    function handleUpdate() {
        handleClose();
        let updatedValues = {title: deposit.title, property_uid: selectedPropertyUID};

        const callURL = getAdminPath(`jeff/deposits/${deposit.deposit_uid}/update/special`);
        axiosInstance.patch(callURL, updatedValues)
            .then(function (response) {
                toast.success('Deposit updated');
                window.location.reload();
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            })
    }
};

export default RhinoEditDepositProperty;