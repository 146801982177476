/*

 */

import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import ErrorInfoDisplay from "../../utilities/displayAreas/ErrorInfoDisplay";
import {ADMIN_ICON_CLOSE_REQUESTS} from "../adminIcons";
import AdminDisplayCard from "./adminDisplayCard";
import LandlordAndTenantDisplayWrapper from "./deposits/LandlordAndTenantDisplayWrapper";
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import {dollarFormattedString} from "../../../utilities/commonUtils";
import CreatedUpdatedTimestampDisplay from "../../utilities/displayAreas/CreatedUpdatedTimestampDisplay";
import AttachedFilesDisplayHOC from "./AttachedFilesDisplayHOC";
import CancelCloseRequestButton from "../buttons/CancelCloseRequestButton";
import {DEPOSIT_CLOSE_REQUEST_STATE_PENDING} from "../../../constants/depositConstants";
import RenderDeposifyTags from "../../tags/DeposifyTags";

function AdminDisplayCloseRequest({loading, error, depositCloseRequest, emptyDisplay = false, ...props}) {

    if (emptyDisplay) {
        return (
            <AdminDisplayCard
                cardTitle={'Close Request'}
                cardSubheader={``}
                headerIcon={ADMIN_ICON_CLOSE_REQUESTS}
                jsonObject={{no: 'close request'}}
                disableShowOriginal={true}
            >
                <i>No close request</i>
            </AdminDisplayCard>
        );
    }

    if (error === true) {
        return (
            <ErrorInfoDisplay showError={true}>
                Failed to load close request
            </ErrorInfoDisplay>
        );
    }

    if (loading === true || error === true || depositCloseRequest === undefined) {
        return <VisualizeContainerLoadingIndicator loadingResource={depositCloseRequest}
                                                   displayName={'Close request'} {...props} />;
    }

    /*
    amount_in_cents_to_landlord:
    amount_in_cents_to_tenant:
    attached_files_uid:
    close_request_uid:
    created_timestamp: 06 Feb 2020 18:02:97
    deposify_tags:
    deposit_uid:
    last_updated_timestamp: 06 Feb 2020 18:02:97
    notes:
    reason_for_close:
    response_reason:
    state:
    triggering_account_type:
    triggering_account_uid:
     */

    return (
        <AdminDisplayCard
            cardTitle={'Close Request'}
            cardSubheader={``}
            headerIcon={ADMIN_ICON_CLOSE_REQUESTS}
            jsonObject={depositCloseRequest}
        >
            <b>Triggering account type</b>: {depositCloseRequest.triggering_account_type}
            <br/>
            <b>Triggering account</b>: <AdminViewFullAccountInfoLink
            accountUID={depositCloseRequest.triggering_account_uid}/>
            <br/>

            <LandlordAndTenantDisplayWrapper
                landlordChildren={
                    <React.Fragment>
                        <b>Return amount</b>: {dollarFormattedString(depositCloseRequest.amount_in_cents_to_landlord)}
                    </React.Fragment>
                }
                tenantChildren={
                    <React.Fragment>
                        <b>Return amount</b>: {dollarFormattedString(depositCloseRequest.amount_in_cents_to_tenant)}
                    </React.Fragment>
                }
            />
            <br/>

            <b>State</b>: {depositCloseRequest.state}
            <br/>
            <b>Close reason:</b><br/>
            {depositCloseRequest.reason_for_close}
            {depositCloseRequest.reason_for_close === '' && <i>No reason provided</i>}
            <br/>
            <b>Response</b>:<br/>
            {depositCloseRequest.response}
            {depositCloseRequest.response === '' && <i>No response provided</i>}
            <br/>
            <b>Notes</b>:<br/>
            {depositCloseRequest.notes}
            {depositCloseRequest.notes === '' && <i>No notes provided</i>}
            <br/>
            <br/>

            <b>Created/Updated</b>:
            <CreatedUpdatedTimestampDisplay
                createdTimestamp={depositCloseRequest.created_timestamp}
                lastUpdatedTimestamp={depositCloseRequest.last_updated_timestamp}
            />
            <br/><br/>

            <b>Attached Files</b>:<br/>
            <AttachedFilesDisplayHOC attachedFilesUID={depositCloseRequest.attached_files_uid}/>
            <br/><br/>

            <b>Tags</b>:<br/>
            <RenderDeposifyTags deposifyTags={depositCloseRequest.deposify_tags}/>
            <br/><br/>

            {
                depositCloseRequest.state === DEPOSIT_CLOSE_REQUEST_STATE_PENDING &&
                <CancelCloseRequestButton
                    depositUID={depositCloseRequest.deposit_uid}
                    closeRequestUID={depositCloseRequest.close_request_uid}
                    cancellingAccountUID={depositCloseRequest.triggering_account_uid}
                />
            }

        </AdminDisplayCard>
    );

}

const mapStateToProps = (state) => ({});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(AdminDisplayCloseRequest);