/*
    Messaging Admin Service
 */

import CommonResourcesService from "../clientShared/services/CommonResourcesService";
import {RESOURCE_TYPE_EMAIL_TEMPLATE} from "../autoGenerated/constants/resourceTypeConstants";
import axiosRequest from "../clientShared/utilities/axios/AxiosRequest";

//
// Service
//
const MessagingAdminService = {
    UpdateEmailTemplate,
    SendTestEmail
};

// Export
export default MessagingAdminService;


//----------------------------------------------------------------------------------------------------------------------
function UpdateEmailTemplate(templateUID, emailBody) {
    const putValues = {
        email_body: emailBody
    };

    return CommonResourcesService.CommonUpdateResource(RESOURCE_TYPE_EMAIL_TEMPLATE, templateUID, putValues);
}

//----------------------------------------------------------------------------------------------------------------------
function SendTestEmail(templateUID) {
    const url = `api/dev/messaging/emails/template/${templateUID}/send`;
    return axiosRequest({
        url: url,
        data: {},
        method: 'POST'
    });
}
