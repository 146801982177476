import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {SERVICE_INFO} from "../../autoGenerated/constants/serviceInfo";
import {PAGE_GROUP_HIGHLIGHTS} from "../../constants/resourceCardHighlights";
import PageGroupResourcePageLink from "../links/PageGroupResourcePageLink";
import Grid from "@material-ui/core/Grid";
import {PAGE_GROUP_CARD_IMAGE_URL} from "../../constants/cdnConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getServiceIcon} from "../../autoGenerated/constants/serviceAndResourceIcons";
import shortid from 'shortid';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
        minWidth: 345,
        minHeight: 430
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

// {
//     Object.keys(DEPOSITS_PAGE_GROUP).map(resourceType => {
//         const currentGroupItem = DEPOSITS_PAGE_GROUP[resourceType];
//         return (
//             <Link
//                 component="button"
//                 variant="body2"
//                 onClick={() => {history.push(currentGroupItem.route)}}
//             >
//                 {resourceType}
//             </Link>
//         );
//     })
// }

export default function PageGroupCard({serviceID, pageGroup, ...props}) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    if (serviceID === undefined) {
        return <React.Fragment/>;
    }

    const serviceInfo = SERVICE_INFO[serviceID];
    if (serviceInfo === undefined) {
        console.log('------ NO SERVICE INFO; Did you forget an export for service:', serviceID);
        return <React.Fragment>Error</React.Fragment>;
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    // <Avatar aria-label="recipe" className={classes.avatar}
                    //         style={{backgroundColor: serviceInfo.colour}}>
                    //     {serviceInfo.name.slice(0, 1).toUpperCase()}
                    // </Avatar>
                    <FontAwesomeIcon icon={getServiceIcon(serviceID)} fixedWidth/>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon/>
                    </IconButton>
                }
                title={serviceInfo.name}
                subheader={serviceInfo.description}
            />
            <CardMedia
                className={classes.media}
                image={PAGE_GROUP_CARD_IMAGE_URL}
                title={serviceInfo.name}
            />
            <CardContent>
                {renderPageGroupHighlights()}
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    disabled={pageGroup === undefined}
                >
                    <ExpandMoreIcon/>
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {renderAllWholePageGroup()}
                </CardContent>
            </Collapse>
        </Card>
    );

    function renderPageGroupHighlights() {
        if (pageGroup === undefined) {
            return (
                <Typography variant="body2" color="textSecondary" component="p">
                    This service has no pages
                </Typography>
            );
        }

        return (
            <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="flex-start"
            >
                {
                    PAGE_GROUP_HIGHLIGHTS[serviceID].map(resourceType => {
                        return (
                            <Grid item key={shortid.generate()}>
                                <PageGroupResourcePageLink resourceType={resourceType} pageGroup={pageGroup}/>
                            </Grid>
                        );
                    })
                }
            </Grid>
        );
    }

    function renderAllWholePageGroup() {
        if (pageGroup === undefined) {
            return <Typography paragraph>No pages for this service</Typography>;
        }

        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid item>
                    <Typography paragraph>All pages:</Typography>
                </Grid>

                {
                    Object.keys(pageGroup).map(resourceType => {
                        return (
                            <Grid item key={shortid.generate()}>
                                <PageGroupResourcePageLink resourceType={resourceType} pageGroup={pageGroup}/>
                            </Grid>
                        );
                    })
                }
            </Grid>
        );
    }

}