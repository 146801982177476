// Name: Storage Table Render Definitions 

export const ATTACHED_STORAGE_FILES_TABLE_RENDER_DEF = {
	created_timestamp: {displayName: "Created Timestamp"},
	file_uids: {displayName: "File UIDs"},
	creating_account_uid: {displayName: "Creating Account UID"},
	resource_type: {displayName: "Resource Type"},
	resource_uid: {displayName: "Resource UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	attached_files_uid: {displayName: "Attached Files UID"}
};

export const STORAGE_FILE_TABLE_RENDER_DEF = {
	file_uid: {displayName: "File UID"},
	original_file_name: {displayName: "Original File Name"},
	uploaded_timestamp: {displayName: "Uploaded Timestamp"},
	// bucket_name: {displayName: "Bucket Name"},
	// file_type: {displayName: "File Type"},
	bucket_folder_path: {displayName: "Bucket Folder Path"},
	uploading_account_uid: {displayName: "Uploading Account UID"},
	// deleting_account_uid: {displayName: "Deleting Account UID"},
	state: {displayName: "State"},
	// last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	// deposify_tags: {displayName: "Deposify Tags"},
	// created_timestamp: {displayName: "Created Timestamp"},
	// deleted: {displayName: "Deleted"},
	// size_in_bytes: {displayName: "Size In Bytes"},
	// deleted_timestamp: {displayName: "Deleted Timestamp"},
	// resource_type: {displayName: "Resource Type"},
	// bucket_file_name: {displayName: "Bucket File Name"},
	resource_uid: {displayName: "Resource UID"},
	// file_extension: {displayName: "File Extension"}
};

