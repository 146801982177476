/*
    Dev
 */

import React from "react";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import {ALL_LANDLORD_ACCOUNTS_LABEL} from "../../../constants/PMCConstants";
import UIDListControls from "../../../components/admin/uidLists/UIDListControls";
import UIDListContainer from "../containers/UIDListContainer";
import PageLinks from "../../../components/pages/common/PageLinks";
import {RESOURCE_TYPE_INFO} from "../../../autoGenerated/constants/resourceTypeInfo";
import AdminRootPage from "../../../components/pages/AdminRootPage";


function GenericResourceTablePage({resourceType, displayComp, icon, displayName, ...props}) {

    const resourceTypeInfo = RESOURCE_TYPE_INFO[resourceType];

    const [selectedPMCAccountUID, setSelectedPMCAccountUID] = React.useState(NULL_UUID_STRING);
    const [selectedPMCLabel, setSelectedPMCLabel] = React.useState(ALL_LANDLORD_ACCOUNTS_LABEL);
    const [sortingColumnName, setSortingColumnName] = React.useState(resourceTypeInfo.defaultSortingColumn);
    const [readDescending, setReadDescending] = React.useState(true);

    const titleStyle = {marginBottom: 10};

    return (
        <AdminRootPage>

            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                wrap={'nowrap'}
            >
                <Grid item>
                    <Typography align={'left'} variant={'h4'} style={titleStyle}>
                        <FontAwesomeIcon
                            icon={icon}
                            fixedWidth
                        />
                        &nbsp;{displayName}
                    </Typography>
                </Grid>

                <Grid item>
                    <UIDListControls
                        resourceType={resourceType}
                        uidListloadURL={resourceTypeInfo.uidListLoadEndpoint}
                        displayName={displayName}
                        outgoingUIDListName={resourceTypeInfo.clientUIDListName}
                        selectedPMCAccountUID={selectedPMCAccountUID}
                        selectedPMCLabel={selectedPMCLabel}
                        sortingColumnName={sortingColumnName}
                        setPMCAccountCallback={setSelectedPMCAccountUID}
                        setPMCLabelCallback={setSelectedPMCLabel}
                        setSortingColumnCallback={setSortingColumnName}
                        readDescending={readDescending}
                        setReadDescendingCallback={setReadDescending}
                    />
                </Grid>

                <Grid item>
                    <PageLinks/>
                </Grid>

                <Grid item>
                    <UIDListContainer
                        resourceType={resourceType}
                        accountUID={selectedPMCAccountUID}
                        outgoingUIDListName={resourceTypeInfo.clientUIDListName}
                        DisplayComp={displayComp}
                    />
                </Grid>

                <Grid item>
                    <PageLinks/>
                </Grid>

            </Grid>


        </AdminRootPage>
    );


}

export default GenericResourceTablePage;