import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';

import {store} from "./store/appStore";
import { SnackbarProvider } from 'notistack';

import {faCheck, faTimes, faExclamationTriangle, faInfoCircle} from '@fortawesome/pro-regular-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider
            iconVariant={{
                success: <FontAwesomeIcon icon={faCheck} fixedWidth />,
                error: <FontAwesomeIcon icon={faTimes} fixedWidth />,
                warning: <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth/>,
                info: <FontAwesomeIcon icon={faInfoCircle} fixedWidth />,
            }}
        >
            <App/>
        </SnackbarProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
