import React from 'react';
import BackLink from "./buttons/linkButtons/BackLink";
import HomeLink from "./buttons/linkButtons/HomeLink";
import AdminRootPage from "../pages/AdminRootPage";
import PropertiesBulkImport from "../../NewStuff/components/PropertiesBulkImport/PropertiesBulkImport";

const PropertiesBulkAdminToolPage = () => {
    return (
        <AdminRootPage>

            <BackLink/>&nbsp;|&nbsp;<HomeLink/>
            <br/><br/>

            <PropertiesBulkImport/>
        </AdminRootPage>
    );
};

export default PropertiesBulkAdminToolPage;