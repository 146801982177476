/*

 */

import React from 'react';
import {useSiteOffline} from "../../clientShared/hooks/useSiteOffline";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWifi} from "@fortawesome/pro-solid-svg-icons";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    danger: {
        backgroundColor: '#811010',
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: '2px'
    },
    okay: {
        backgroundColor: '#14723a',
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: '2px'
    }
}));


const OfflineStatusIndicator = (props) => {
    const classes = useStyles();

    const {loading, error, offline} = useSiteOffline();

    if(loading === true){
        return (
            <React.Fragment>
                Loading...
            </React.Fragment>
        );
    }

    if(error === true){
        return (
            <React.Fragment>
                Error
            </React.Fragment>
        );
    }

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <FontAwesomeIcon
                    icon={faWifi}
                    fixedWidth
                />&nbsp;
                Public Site Status:&nbsp;
            </Grid>

            {renderStatusItem()}
        </Grid>
    );

    function renderStatusItem(){

        if(loading === true) {
            return (
                <Grid item className={classes.okay}>
                    Loading...
                </Grid>
            );
        }

        if(offline === false) {
            return (
                <Grid item className={classes.okay}>
                    Online
                </Grid>
            );
        }

        return (
            <Grid item className={classes.danger}>
                Site Is OFFLINE
            </Grid>
        );

    }


}

export default OfflineStatusIndicator;