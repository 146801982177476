import React, {useRef, useState} from 'react';
import Button from "@material-ui/core/Button";
import {faUpload} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import RhinoBankBulkUploadFileInfoSamples from "./rhinoBankBulkUploadFileInfoSamples";
import Collapse from "@material-ui/core/Collapse";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";


const RhinoBankBulkUploadArea = ({handleFileContent}) => {
    const fileInput = useRef(null);
    const [hasData, setHasData] = useState(false);
    const [loading, setLoading] = useState(false);
    const buttonVariant = hasData ? "outlined" : "contained";

    const handleButtonClick = () => {
        fileInput.current.click();
    };

    const handleFileRead = (event) => {
        setLoading(true);
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            handleFileContent(event.target.result);
            setHasData(true);
            setLoading(false);
        };

        reader.readAsText(file);
    };

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >
            <Grid item>
                <Typography variant={"h6"}>
                    Upload File
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                    Select the TSV file containing the property and banking info using the button below.
                </Typography>
            </Grid>

            <Grid item>
                <RhinoBankBulkUploadFileInfoSamples/>
            </Grid>

            <Grid item>
                <Collapse in={!hasData}>
                    <input
                        type="file"
                        style={{display: 'none'}}
                        accept=".tsv,text/tab-separated-values"
                        ref={fileInput}
                        onChange={handleFileRead}
                    />

                    <SpinnerButton
                        // Non-button props
                        showSpinner={loading}
                        // Button props
                        variant={buttonVariant}
                        color="primary"
                        disabled={loading}
                        type={'submit'}
                        onClick={handleButtonClick}
                    >
                        <FontAwesomeIcon
                            icon={faUpload}
                            fixedWidth
                        />&nbsp;
                        Upload TSV file
                    </SpinnerButton>

                    {/*<Button*/}
                    {/*    variant={buttonVariant}*/}
                    {/*    color="primary"*/}
                    {/*    onClick={handleButtonClick}*/}
                    {/*    disabled={loading}*/}
                    {/*>*/}
                    {/*    <FontAwesomeIcon*/}
                    {/*        icon={faUpload}*/}
                    {/*        fixedWidth*/}
                    {/*    />&nbsp;*/}
                    {/*    Upload TSV file*/}
                    {/*</Button>*/}
                </Collapse>

                <Collapse in={hasData}>
                    {/* in a hurry, this will do until we can fully reset the state */}
                    <Button variant={buttonVariant} color="primary" onClick={() => {
                        window.location.reload()
                    }}>
                        <FontAwesomeIcon
                            icon={faUpload}
                            fixedWidth
                        />&nbsp;
                        Upload New file
                    </Button>
                </Collapse>
            </Grid>

        </Grid>
    );
};

export default RhinoBankBulkUploadArea;