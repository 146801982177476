import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import {faCopy} from "@fortawesome/pro-light-svg-icons";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Link from "@material-ui/core/Link";


function CopyDataButton({closeDialogCallback = undefined, toolTip = 'Copy to clipboard', showLinkText = false, ...props}) {

    function copyData() {
        if (closeDialogCallback !== undefined) {
            closeDialogCallback();
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={toolTip} placement={'top'}>
                <CopyToClipboard text={props.data}>
                    <IconButton
                        aria-label={toolTip}
                        size={'small'}
                        onClick={copyData}
                        color={'primary'}
                    >
                        <FontAwesomeIcon
                            icon={faCopy}
                            fixedWidth
                        />
                    </IconButton>
                </CopyToClipboard>
            </Tooltip>

            {
                showLinkText === true &&
                <Link component="button" onClick={copyData}>{toolTip}</Link>
            }

        </React.Fragment>
    );
}


export default CopyDataButton;