import React from 'react';
import Grid from "@material-ui/core/Grid";
import BackLink from "../components/admin/buttons/linkButtons/BackLink";
import HomeLink from "../components/admin/buttons/linkButtons/HomeLink";
import JeffCreditDebitFormDialogButton from "./components/credit_and_debit/jeffCreditDebitFormDialogButton";
import JeffReportButton from "./components/reports/jeffReportButton";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import JeffYardiTenantRefreshFormDialogButton
    from "./components/yardi_tenant_refresh/jeffYardiTenantRefreshFormDialogButton";
import JeffYardiProspectRefreshFormDialogButton
    from "./components/yardi_prospect_refresh/jeffYardiProspectRefreshFormDialogButton";
import JeffYardiTenantProcessFormDialogButton
    from "./components/yardi_resident_processing/jeffYardiTenantProcessFormDialogButton";
import CreateUserAccountFinalization from "../components/accounts/CreateUserAccountFinalization";
import {faHatWizard} from "@fortawesome/pro-regular-svg-icons";

// refresh by tenant code
// refresh by prospect code


const JeffPage = () => {


    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                &nbsp;
            </Grid>

            <Grid item>
                <BackLink/>&nbsp;|&nbsp;<HomeLink/>
            </Grid>

            <Grid item>
                &nbsp;
            </Grid>

            <Grid item>
                <Typography variant={"h2"}>
                    <FontAwesomeIcon icon={faHatWizard} fixedWidth/>&nbsp;Admin Tools
                </Typography>
            </Grid>

            <Grid item>
                <CreateUserAccountFinalization/>
            </Grid>

            <Grid item>
                <JeffCreditDebitFormDialogButton creditMode={true}/>
            </Grid>

            <Grid item>
                <JeffCreditDebitFormDialogButton creditMode={false}/>
            </Grid>

            {/*<Grid item>*/}
            {/*    <JeffFinalizationExtFormDialogButton/>*/}
            {/*</Grid>*/}

            {/*<Grid item>*/}
            {/*    <JeffDepositPermRefreshFormDialogButton/>*/}
            {/*</Grid>*/}

            <Grid item>
                <hr/>
                <Typography variant={"h6"}>
                    Yardi
                </Typography>
            </Grid>

            <Grid item>
                <JeffYardiTenantRefreshFormDialogButton/>
            </Grid>

            <Grid item>
                <JeffYardiProspectRefreshFormDialogButton/>
            </Grid>

            <Grid item>
                <JeffYardiTenantProcessFormDialogButton/>
            </Grid>

            <Grid item>
                <hr/>
                {/*<Typography color="textSecondary" variant={"h4"}>*/}
                {/*    <FontAwesomeIcon icon={faDragon} fixedWidth/>&nbsp;*/}
                {/*    <FontAwesomeIcon icon={faDragon} fixedWidth/>&nbsp;*/}
                {/*    Warning*/}
                {/*    &nbsp;<FontAwesomeIcon icon={faDragon} fixedWidth/>&nbsp;*/}
                {/*    <FontAwesomeIcon icon={faDragon} fixedWidth/>*/}
                {/*</Typography>*/}
            </Grid>

            {/*<Grid item>*/}
            {/*    <Typography color="textSecondary" variant={"h5"}>*/}
            {/*        Running reports is heavy on the system, run them only when you need to.*/}
            {/*        <br/>*/}
            {/*        Don't run it several times in a row.*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}

            <Grid item>
                <hr/>
                <Typography variant={"h6"}>
                    Reports
                </Typography>
            </Grid>

            <Grid item>
                <JeffReportButton
                    reportName={"All Deposits"}
                    reportURL={"jeff/reports/deposits/all"}
                    reportFileName={"all_deposits.xlsx"}
                />
            </Grid>

            <Grid item style={{minWidth: 100}}>
                <hr/>
            </Grid>

            <Grid item>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <JeffReportButton
                            reportName={"Ardmore"}
                            reportURL={"jeff/reports/deposits/ardmore"}
                            reportFileName={"ardmore.xlsx"}
                        />
                    </Grid>
                    <Grid item>
                        <JeffReportButton
                            reportName={"Tropicana"}
                            reportURL={"jeff/reports/deposits/tropicana"}
                            reportFileName={"tropicana.xlsx"}
                        />
                    </Grid>
                    <Grid item>
                        <JeffReportButton
                            reportName={"Mashcole"}
                            reportURL={"jeff/reports/deposits/mashcole"}
                            reportFileName={"mashcole.xlsx"}
                        />
                    </Grid>
                    <Grid item>
                        <JeffReportButton
                            reportName={"Venture / Gateway"}
                            reportURL={"jeff/reports/deposits/venture-gateway"}
                            reportFileName={"Venture_Gateway.xlsx"}
                        />
                    </Grid>
                    <Grid item>
                        <JeffReportButton
                            reportName={"Southwood"}
                            reportURL={"jeff/reports/deposits/southwood"}
                            reportFileName={"Southwood.xlsx"}
                        />
                    </Grid>
                    <Grid item>
                        <JeffReportButton
                            reportName={"Knox"}
                            reportURL={"jeff/reports/deposits/knox"}
                            reportFileName={"Knox.xlsx"}
                        />
                    </Grid>
                    <Grid item>
                        <JeffReportButton
                            reportName={"SplitSpot"}
                            reportURL={"jeff/reports/deposits/splitspot"}
                            reportFileName={"Splitspot.xlsx"}
                        />
                    </Grid>

                </Grid>

            </Grid>

            <Grid item style={{minWidth: 100}}>
                <hr/>
            </Grid>

            <Grid item>
                <JeffReportButton
                    reportName={"Withdrawals"}
                    reportURL={"jeff/reports/withdrawals"}
                    reportFileName={"All User Withdraw Requests.xlsx"}
                />
            </Grid>

            <Grid item>
                <JeffReportButton
                    reportName={"Balances"}
                    reportURL={"jeff/reports/balances"}
                    reportFileName={"All User Account Balances.xlsx"}
                />
            </Grid>

            <Grid item>
                &nbsp;
            </Grid>

            <Grid item>
                <BackLink/>&nbsp;|&nbsp;<HomeLink/>
            </Grid>

        </Grid>
    );
};

export default JeffPage;