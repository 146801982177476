/*

 */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {makeStyles} from "@material-ui/core";
import {appActionSetFullScreenUSDomBankDetails} from "../../actions/appActions";
import {
    adminFullScreenUSDomBankDialogDetailsSelector
} from "../../selectors/appSelectors";
import CancelButton from "../utilities/buttons/CancelButton";
import {CANCEL_ICON} from "../../constants/commonIcons";
import {DEPOSIT_INDICATOR_ICON_COLOR} from "../../constants/dataConstants";
import AdminDisplayUSDomesticBankAccount from "./displays/adminDisplayUSDomesticBankAccount";
import {USDomesticUserBankAccountContainer} from "../../autoGenerated/components/containers/resourceContainers";

const useStyles = makeStyles(theme => ({
    dialogContent: {
        //padding: 0
    },
    xsScreenDialogContent: {
        padding: 0
    },
    titleText:{
        fontSize: 13,
        [theme.breakpoints.up("md")]: {
            fontSize: 15
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 17
        },
    }
}));

function FullScreenUSDomesticBankAccountDialog(props) {
    const classes = useStyles();

    if(props.adminFullScreenUSDomBankDialogDetails.showDialog !== true){
        return <React.Fragment/>;
    }

    return (
        <Dialog
            open={props.adminFullScreenUSDomBankDialogDetails.showDialog}
            onClose={handleClose}
            aria-labelledby="fullscreen-deposit-dialog-title"
            fullWidth={true}
            maxWidth={'xl'}
            //fullScreen
        >
            <RenderDialogTitle/>

            <DialogContent>
                <USDomesticUserBankAccountContainer bankAccountUID={props.adminFullScreenUSDomBankDialogDetails.bankAccountUID}>
                    <AdminDisplayUSDomesticBankAccount/>
                </USDomesticUserBankAccountContainer>
            </DialogContent>

            <DialogActions>
                <CancelButton handleCloseFunction={handleClose} buttonText={'Close'}/>
            </DialogActions>
        </Dialog>
    );

    function RenderDialogTitle() {
        return (
            <DialogTitle id="fullscreen-deposit-dialog-title">
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item className={classes.titleText}>
                        US Domestic Bank Account Details
                    </Grid>

                    <Grid item>
                        <Tooltip
                            title={'Close'}
                            placement={'top'}
                        >
                            <IconButton
                                aria-label={'Close'}
                                onClick={handleClose}
                                size={'medium'}
                            >
                                <FontAwesomeIcon
                                    icon={CANCEL_ICON}
                                    color={DEPOSIT_INDICATOR_ICON_COLOR}
                                    fixedWidth
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
        );
    }

    function handleClose() {
        props.appActionSetFullScreenUSDomBankDetails({
            showDialog: false,
            bankAccountUID: undefined
        });
    }

}

const mapStateToProps = (state) => ({
    adminFullScreenUSDomBankDialogDetails: adminFullScreenUSDomBankDialogDetailsSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        appActionSetFullScreenUSDomBankDetails: appActionSetFullScreenUSDomBankDetails
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(FullScreenUSDomesticBankAccountDialog);