/*
    Admin Action Types
 */

export const ADMIN_SET_STARTUP_LOADING = 'ADMIN:ADMIN_SET_STARTUP_LOADING';
export const ADMIN_UPDATE_ONLINE_USER_ACCOUNTS = 'ADMIN:ADMIN_UPDATE_ONLINE_USER_ACCOUNTS';
export const ADMIN_SET_LAST_ALL_ONLINE_USERS_REFRESH = 'ADMIN:SET_LAST_ALL_ONLINE_USERS_REFRESH';

export const ADMIN_SET_SEARCH_RESULTS = 'ADMIN:ADMIN_SET_SEARCH_RESULTS';
export const ADMIN_SET_SEARCH_INFO = 'ADMIN:ADMIN_SET_SEARCH_INFO';

export const ADMIN_SET_GENERIC_FULL_SCREEN_DISPLAY = 'ADMIN:SET_GENERIC_FULL_SCREEN_DISPLAY';

export const ADMIN_SET_GHOST_ACCOUNT_INFO = 'ADMIN:SET_GHOST_ACCOUNT_INFO';

export const ADMIN_SET_FULL_SCREEN_FILE_INFO = 'ADMIN:SET_FULL_SCREEN_FILE_INFO';
export const ADMIN_REMOVE_FILE_AFTER_DELETE = 'ADMIN:REMOVE_FILE_FROM_ATTACHED_FILES';

export const ADMIN_SET_SELECTED_PMC_INFO = 'ADMIN:SET_SELECTED_PMC_INFO';

export const ADMIN_SET_FULL_SCREEN_HISTORY_CHAIN = 'ADMIN:SET_FULL_SCREEN_HISTORY_CHAIN';
export const ADMIN_ADD_TO_FULL_SCREEN_HISTORY_CHAIN = 'ADMIN:ADD_TO_FULL_SCREEN_HISTORY_CHAIN';
export const ADMIN_MOVE_BACK_IN_FULL_SCREEN_HISTORY_CHAIN = 'ADMIN:MOVE_BACK_IN_FULL_SCREEN_HISTORY_CHAIN';

export const ADMIN_SET_SYSTEM_STATS = 'ADMIN:SET_SYSTEM_STATS';
export const ADMIN_ADD_SYSTEM_STATS = 'ADMIN:ADD_SYSTEM_STATS';
