/*
    Payment Cleared / Interest controls
 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import ConfirmationDialog from "../../utilities/dialogs/ConfirmationDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {FUNDS_LOCATION_TYPE_BANK_ACCOUNT} from "../../../autoGenerated/constants/services/UserAccountsServiceConstants";
import {NULL_UUID_STRING} from "../../../constants/dataConstants";
import DepositsAdminService from "../../../api/depositsAdminService";
import {
    RESOURCE_TYPE_DEPOSIT
} from "../../../autoGenerated/constants/resourceTypeConstants";
import {resourceActionUpdateSingleResource} from "../../../clientShared/actions/resourceActions";
import {DEPOSIT_STATE_ACTIVE} from "../../../autoGenerated/constants/services/DepositsServiceConstants";
import toast from "react-hot-toast";


function DepositAdminButtonSetPaymentClearedAndEnableInterest({deposit, ...props}) {

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    // Don't even show this button if all conditions are not met
    if (deposit.interest_enabled === true ||
        deposit.payment_cleared === true ||
        deposit.funds_location !== FUNDS_LOCATION_TYPE_BANK_ACCOUNT ||
        deposit.funds_location_uid === NULL_UUID_STRING ||
        deposit.state !== DEPOSIT_STATE_ACTIVE ||
        deposit.funds_held_by_partner === true
    ) {
        return <React.Fragment/>;
    }

    return (
        <React.Fragment>
            <ConfirmationDialog
                startOpen={showConfirm}
                dangerMode={true}
                confirmCallback={acceptConfirm}
                cancelCallback={cancelConfirm}
                dialogTitle={'Are you sure?'}
                dialogText={'Are you sure you sure you mark PAYMENT CLEARED and ENABLE INTEREST?'}
                confirmText={'Clear and Enable'}
                showSpinner={showSpinner}
                cancelFocus={true}
            />

            <Button
                color="secondary"
                onClick={handleButtonClick}
                variant={'contained'}
            >
                <FontAwesomeIcon
                    icon={faSkullCrossbones}
                    fixedWidth
                />
                &nbsp;Clear Payment & Enable Interest
            </Button>

        </React.Fragment>
    );

    function handleButtonClick() {
        setShowSpinner(false);
        setShowConfirm(true);
    }

    function acceptConfirm() {
        setShowSpinner(true);
        DepositsAdminService.DepositSetPaymentClearedAndEnableInterest(deposit.deposit_uid)
            .then(function (response) {
                toast.success('Payment cleared and interest enabled');
                setShowConfirm(false);
                setTimeout(() => {
                    props.resourceActionUpdateSingleResource(response.deposit, RESOURCE_TYPE_DEPOSIT);
                }, 500);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }

    function cancelConfirm() {
        setShowConfirm(false);
        toast('Cancelled action');
    }


}

const mapStateToProps = (state) => {
    // const _adminMakeSystemStatsSelector = adminMakeSystemStatsSelector();

    return function realMapState(state, props) {
        return {
            // systemStats: _adminMakeSystemStatsSelector(state, props.statsID)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(DepositAdminButtonSetPaymentClearedAndEnableInterest);
