/*
    Stats utilities
 */

import {jsDateTimeFromString} from "../../utilities/commonUtils";

export function systemStatsCreatedTimestampToName(value, index, array) {
    /*
        Set the name attribute on a system stats object so it appears in the tool tip as the name
        Converts the normal timestamp: 2020-08-09 00:01:05:589347 into Sun Aug 9 2020

        Usage:
            systemStats.forEach(systemStatsCreatedTimestampToName);
     */
    return value['name'] = jsDateTimeFromString(value['created_timestamp']).toUTCString()
}