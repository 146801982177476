/*

 */

import React from 'react';
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import {history} from "../../../../history/history";
import {APP_ROUTE_HOME} from "../../../../constants/appRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/pro-solid-svg-icons";
import {makeStyles} from "@material-ui/core/styles";
import {FADED_LINK_COLOUR} from "../../../../constants/styleConstants";

const useStyles = makeStyles(theme => ({
    linkColour: {
        color: FADED_LINK_COLOUR
    }
}));

function HomeLink({useHref = false, ...props}) {
    const classes = useStyles();

    return (
        <Tooltip
            title={'Home'}
            placement={'top'}
        >
            <span>
                <Link
                    variant="body2"
                    onClick={handleClick}
                    className={classes.linkColour}
                    href={useHref ? APP_ROUTE_HOME : null}
                    style={{cursor: "pointer"}}
                >
                    <FontAwesomeIcon
                        icon={faHome}
                        fixedWidth
                    />
                    &nbsp;Home
                </Link>
            </span>
        </Tooltip>
    );

    function handleClick() {
        history.push(APP_ROUTE_HOME);
    }
}


export default HomeLink;
