// Name: User_Accounts Body Render Definitions 

export const ADMIN_GHOST_USER_ACCOUNT_BODY_RENDER_DEF = {
	ghost_account_uid: {displayName: "Ghost Account UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	target_account_uid: {displayName: "Target Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	set_by_admin_account_uid: {displayName: "Set By Admin Account UID"},
	currently_in_use: {displayName: "Currently In Use"}
};

export const ARCHIVED_PENDING_EMAIL_CHANGE_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	email_hash: {displayName: "Email Hash"},
	last_sent_email_timestamp: {displayName: "Last Sent Email Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	sent_email_count: {displayName: "Sent Email Count"},
	pending_email_change_last_updated_timestamp: {displayName: "Pending Email Change Last Updated Timestamp"},
	pending_email_change_created_timestamp: {displayName: "Pending Email Change Created Timestamp"},
	email_address: {displayName: "Email Address"},
	change_uid: {displayName: "Change UID"},
	account_uid: {displayName: "Account UID"}
};

export const BALANCE_CHANGE_LOG_BODY_RENDER_DEF = {
	entry_type: {displayName: "Entry Type"},
	previous_total_balance_in_cents: {displayName: "Previous Total Balance In Cents"},
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	notes: {displayName: "Notes"},
	delta_locked_balance_in_cents: {displayName: "Delta Locked Balance In Cents"},
	created_timestamp: {displayName: "Created Timestamp"},
	delta_total_balance_in_cents: {displayName: "Delta Total Balance In Cents"},
	delta_free_balance_in_cents: {displayName: "Delta Free Balance In Cents"},
	new_locked_balance_in_cents: {displayName: "New Locked Balance In Cents"},
	change_log_uid: {displayName: "Change Log UID"},
	resource_uid: {displayName: "Resource UID"},
	new_total_balance_in_cents: {displayName: "New Total Balance In Cents"},
	previous_locked_balance_in_cents: {displayName: "Previous Locked Balance In Cents"},
	new_free_balance_in_cents: {displayName: "New Free Balance In Cents"},
	previous_free_balance_in_cents: {displayName: "Previous Free Balance In Cents"},
	account_uid: {displayName: "Account UID"}
};

export const BALANCE_SOURCE_TRANSFER_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	funds_location_uid: {displayName: "Funds Location UID"},
	bulk_transfer_uid: {displayName: "Bulk Transfer UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	amount_in_cents: {displayName: "Amount In Cents"},
	balance_source_uid: {displayName: "Balance Source UID"},
	funds_destination_uid: {displayName: "Funds Destination UID"},
	funds_location: {displayName: "Funds Location"},
	transfer_uid: {displayName: "Transfer UID"},
	funds_destination: {displayName: "Funds Destination"},
	account_uid: {displayName: "Account UID"}
};

export const CREDIT_USER_ACCOUNT_LOG_BODY_RENDER_DEF = {
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	funds_location_uid: {displayName: "Funds Location UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	amount_in_cents: {displayName: "Amount In Cents"},
	credit_log_uid: {displayName: "Credit Log UID"},
	credit_type: {displayName: "Credit Type"},
	resource_uid: {displayName: "Resource UID"},
	funds_location: {displayName: "Funds Location"},
	account_uid: {displayName: "Account UID"}
};

export const EMAIL_VERIFICATION_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	token_hash: {displayName: "Token Hash"},
	verification_type: {displayName: "Verification Type"},
	last_sent_email_timestamp: {displayName: "Last Sent Email Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	verification_uid: {displayName: "Verification UID"},
	sent_email_count: {displayName: "Sent Email Count"},
	verification_token: {displayName: "Verification Token"},
	account_uid: {displayName: "Account UID"}
};

export const EMAIL_VERIFICATION_RECORD_BODY_RENDER_DEF = {
	record_type: {displayName: "Record Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	token_hash: {displayName: "Token Hash"},
	verification_type: {displayName: "Verification Type"},
	verification_uid: {displayName: "Verification UID"},
	sent_email_count: {displayName: "Sent Email Count"},
	verified_timestamp: {displayName: "Verified Timestamp"},
	verification_token: {displayName: "Verification Token"},
	verifying_resource_type: {displayName: "Verifying Resource Type"},
	verifying_resource_uid: {displayName: "Verifying Resource UID"},
	account_uid: {displayName: "Account UID"}
};

export const EXPIRED_PASSWORD_BODY_RENDER_DEF = {
	password_version: {displayName: "Password Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	expired_reason: {displayName: "Expired Reason"},
	expired_password_uid: {displayName: "Expired Password UID"},
	password_hash: {displayName: "Password Hash"},
	password_salt: {displayName: "Password Salt"},
	account_uid: {displayName: "Account UID"}
};

export const FUNDS_LOCK_BODY_RENDER_DEF = {
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	amount_in_cents: {displayName: "Amount In Cents"},
	locked_balance_source_list: {displayName: "Locked Balance Source List"},
	resource_uid: {displayName: "Resource UID"},
	lock_uid: {displayName: "Lock UID"},
	deposify_tags: {displayName: "Deposify Tags"},
	account_uid: {displayName: "Account UID"}
};

export const FUNDS_LOCK_RELEASE_LOG_BODY_RENDER_DEF = {
	release_type: {displayName: "Release Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	amount_in_cents: {displayName: "Amount In Cents"},
	release_log_uid: {displayName: "Release Log UID"},
	funds_lock_uid: {displayName: "Funds Lock UID"},
	account_uid: {displayName: "Account UID"}
};

export const PASSWORD_BODY_RENDER_DEF = {
	password_version: {displayName: "Password Version"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	password_hash: {displayName: "Password Hash"},
	password_salt: {displayName: "Password Salt"},
	account_uid: {displayName: "Account UID"}
};

export const PASSWORD_RESET_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	token_hash: {displayName: "Token Hash"},
	reset_reason: {displayName: "Reset Reason"},
	reset_uid: {displayName: "Reset UID"},
	last_sent_email_timestamp: {displayName: "Last Sent Email Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	sent_email_count: {displayName: "Sent Email Count"},
	reset_token: {displayName: "Reset Token"},
	expires_timestamp: {displayName: "Expires Timestamp"},
	account_uid: {displayName: "Account UID"}
};

export const PENDING_EMAIL_CHANGE_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	email_hash: {displayName: "Email Hash"},
	last_sent_email_timestamp: {displayName: "Last Sent Email Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	sent_email_count: {displayName: "Sent Email Count"},
	email_address: {displayName: "Email Address"},
	change_uid: {displayName: "Change UID"},
	account_uid: {displayName: "Account UID"}
};

export const PREVIOUS_EMAIL_ADDRESS_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	email_hash: {displayName: "Email Hash"},
	created_timestamp: {displayName: "Created Timestamp"},
	previous_email_uid: {displayName: "Previous Email UID"},
	email_address: {displayName: "Email Address"},
	account_uid: {displayName: "Account UID"}
};

export const RELEASED_FUNDS_LOCK_BODY_RENDER_DEF = {
	funds_lock_created_timestamp: {displayName: "Funds Lock Created Timestamp"},
	resource_type: {displayName: "Resource Type"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	amount_in_cents: {displayName: "Amount In Cents"},
	locked_balance_source_list: {displayName: "Locked Balance Source List"},
	resource_uid: {displayName: "Resource UID"},
	lock_uid: {displayName: "Lock UID"},
	funds_locked_last_updated_timestamp: {displayName: "Funds Locked Last Updated Timestamp"},
	account_uid: {displayName: "Account UID"}
};

export const USER_ACCOUNT_BODY_RENDER_DEF = {
    account_uid: {displayName: "Account UID"},
    state: {displayName: "State"},
    email_address: {displayName: "Email Address"},
    //email_hash: {displayName: "Email Hash"},
    email_verified: {displayName: "Email Verified"},
	has_pending_email_change: {displayName: "Has Pending Email Change"},
    username: {displayName: "Username"},
    name_title: {displayName: "Name Title"},
    first_name: {displayName: "First Name"},
    middle_name: {displayName: "Middle Name"},
    last_name: {displayName: "Last Name"},
	profile_pic_file_uid: {displayName: "Profile Pic File UID"},
    roles: {displayName: "Roles"},
    organization_uids: {displayName: "Organization UIDs"},
    // create_on_behalf_of_organization_uids: {displayName: "Create On Behalf Of Organization UIDs"},
    created_timestamp: {displayName: "Created Timestamp"},
    last_updated_timestamp: {displayName: "Last Updated Timestamp"},
    deposify_tags: {displayName: "Deposify Tags"},
	//default_role: {displayName: "Default Role"},
	partner_global_bank_account_uid: {displayName: "Partner Global Bank Account"},
	partner_global_bank_account_disabled: {displayName: "Partner Global Bank Account Disabled"}
};

export const USER_ACCOUNT_BALANCE_BODY_RENDER_DEF = {
	account_uid: {displayName: "Account UID"},
	total_balance_in_cents: {displayName: "Total Balance"},
	free_balance_in_cents: {displayName: "Free Balance"},
	locked_balance_in_cents: {displayName: "Locked Balance"},
	created_timestamp: {displayName: "Created Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"}
};

export const USER_ACCOUNT_BALANCE_SOURCE_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	balance_source_transfer_uid: {displayName: "Balance Source Transfer UID"},
	funds_location_uid: {displayName: "Funds Location UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	amount_in_cents: {displayName: "Amount In Cents"},
	source_uid: {displayName: "Source UID"},
	funds_location: {displayName: "Funds Location"},
	balance_type: {displayName: "Balance Type"},
	awaiting_balance_source_transfer: {displayName: "Awaiting Balance Source Transfer"},
	account_uid: {displayName: "Account UID"}
};

export const USER_ACCOUNT_EXTENDED_INFO_BODY_RENDER_DEF = {
	address_line_three: {displayName: "Address Line Three"},
	address_locality: {displayName: "Address Locality"},
	address_line_two: {displayName: "Address Line Two"},
	phone_number: {displayName: "Phone Number"},
	address_organization: {displayName: "Address Organization"},
	address_region: {displayName: "Address Region"},
	address_country: {displayName: "Address Country"},
	address_line_four: {displayName: "Address Line Four"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	last_name: {displayName: "Last Name"},
	first_name: {displayName: "First Name"},
	phone_number_verified: {displayName: "Phone Number Verified"},
	middle_name: {displayName: "Middle Name"},
	phone_number_verification_timestamp: {displayName: "Phone Number Verification Timestamp"},
	address_line_one: {displayName: "Address Line One"},
	title: {displayName: "Title"},
	account_uid: {displayName: "Account UID"},
	address_zip_code: {displayName: "Address Zip Code"}
};

export const USER_ACCOUNT_META_DATA_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	first_sign_in_completed: {displayName: "First Sign In Completed"},
	first_sign_into_system_timestamp: {displayName: "First Sign Into System Timestamp"},
	account_uid: {displayName: "Account UID"}
};

export const USER_ACCOUNT_SETTINGS_BODY_RENDER_DEF = {
	default_us_state: {displayName: "Default Us State"},
	auto_archive_cancelled_deposits_day_count: {displayName: "Auto Archive Cancelled Deposits Day Count"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	show_extended_create_deposit_form: {displayName: "Show Extended Create Deposit Form"},
	show_local_time: {displayName: "Show Local Time"},
	created_timestamp: {displayName: "Created Timestamp"},
	auto_archive_closed_deposits: {displayName: "Auto Archive Closed Deposits"},
	recent_us_states: {displayName: "Recent Us States"},
	deposit_listing_opens_fullscreen: {displayName: "Deposit Listing Opens Fullscreen"},
	allow_self_deposit: {displayName: "Allow Self Deposit"},
	date_display_format: {displayName: "Date Display Format"},
	auto_archive_cancelled_deposits: {displayName: "Auto Archive Cancelled Deposits"},
	always_show_archived_deposits: {displayName: "Always Show Archived Deposits"},
	auto_archive_closed_deposits_day_count: {displayName: "Auto Archive Closed Deposits Day Count"},
	deposit_listing_click_header_to_expand: {displayName: "Deposit Listing Click Header To Expand"},
	account_uid: {displayName: "Account UID"}
};

export const USER_BANK_ACCOUNT_DELTA_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	delta_uid: {displayName: "Delta UID"},
	payload: {displayName: "Payload"},
	created_timestamp: {displayName: "Created Timestamp"},
	resource_uid: {displayName: "Resource UID"},
	account_uid: {displayName: "Account UID"},
	update_delta_type: {displayName: "Update Delta Type"},
	resource_type: {displayName: "Resource Type"}
};

export const US_DOMESTIC_USER_BANK_ACCOUNT_BODY_RENDER_DEF = {
	account_number: {displayName: "Account Number"},
	user_deleted: {displayName: "User Deleted"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	first_name_on_account: {displayName: "First Name On Account"},
	notes: {displayName: "Notes"},
	account_type: {displayName: "Account Type"},
	created_timestamp: {displayName: "Created Timestamp"},
	ach: {displayName: "Ach"},
	state: {displayName: "State"},
	bank_account_uid: {displayName: "Bank Account UID"},
	bank_name: {displayName: "Bank Name"},
	internal_account_type: {displayName: "Internal Account Type"},
	deposify_tags: {displayName: "Deposify Tags"},
	last_name_on_account: {displayName: "Last Name On Account"},
	contact_phone_number: {displayName: "Contact Phone Number"},
	account_uid: {displayName: "Account UID"}
};

export const INTERNATIONAL_USER_BANK_ACCOUNT_BODY_RENDER_DEF = {
	recipient_address_city: {displayName: "Recipient Address City"},
	notes: {displayName: "Notes"},
	recipient_address_street: {displayName: "Recipient Address Street"},
	recipient_address_state: {displayName: "Recipient Address State"},
	internal_account_type: {displayName: "Internal Account Type"},
	recipient_country: {displayName: "Recipient Country"},
	last_name_on_account: {displayName: "Last Name On Account"},
	bic_swift_code: {displayName: "Bic Swift Code"},
	account_number: {displayName: "Account Number"},
	user_deleted: {displayName: "User Deleted"},
	account_type: {displayName: "Account Type"},
	recipient_postal_code: {displayName: "Recipient Postal Code"},
	bank_account_uid: {displayName: "Bank Account UID"},
	contact_phone_number: {displayName: "Contact Phone Number"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	first_name_on_account: {displayName: "First Name On Account"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	bank_name: {displayName: "Bank Name"},
	deposify_tags: {displayName: "Deposify Tags"},
	account_uid: {displayName: "Account UID"}
};

export const BANK_ACCOUNT_BALANCE_RECORD_BODY_RENDER_DEF = {
	record_uid: {displayName: "Record UID"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	notes: {displayName: "Notes"},
	recording_account_uid: {displayName: "Recording Account UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	amount_in_cents: {displayName: "Amount In Cents"},
	bank_account_uid: {displayName: "Bank Account UID"},
	deposify_tags: {displayName: "Deposify Tags"}
};

export const USER_ACCOUNT_FINALIZATION_BODY_RENDER_DEF = {
	verification_token_hash: {displayName: "Verification Token Hash"},
	name_needed_for_finalization: {displayName: "Name Needed For Finalization"},
	expires_timestamp: {displayName: "Expires Timestamp"},
	phone_needed_for_finalization: {displayName: "Phone Needed For Finalization"},
	notes_uid: {displayName: "Notes UID"},
	created_timestamp: {displayName: "Created Timestamp"},
	account_uid: {displayName: "Account UID"},
	first_email_timestamp: {displayName: "First Email Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	state: {displayName: "State"},
	verification_token: {displayName: "Verification Token"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	last_email_timestamp: {displayName: "Last Email Timestamp"},
	sent_email_count: {displayName: "Sent Email Count"}
};

export const THIRD_PARTY_USER_ACCOUNT_MAPPING_BODY_RENDER_DEF = {
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	deposify_tags: {displayName: "Deposify Tags"},
	account_uid: {displayName: "Account UID"},
	notes_uid: {displayName: "Notes UID"},
	created_timestamp: {displayName: "Created Timestamp"}
};
