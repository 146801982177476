/*
    Resource Actions
 */

import {
    RESOURCE_REMOVE_SINGLE_RESOURCE,
    RESOURCE_UPDATE_RESOURCE_UIDS,
    RESOURCE_UPDATE_RESOURCE_UIDS_FOR_OWNER,
    RESOURCE_UPDATE_RESOURCES
} from "./resourceActionTypes";
import {RESOURCE_TYPE_INFO} from "../../autoGenerated/constants/resourceTypeInfo";

//
// RESOURCES
//
export function resourceActionUpdateResources(resourceType, resourceList, resourceUIDName, replaceAll = false) {
    return {
        type: RESOURCE_UPDATE_RESOURCES,
        resourceType: resourceType,
        resourceList: resourceList,
        resourceUIDName: resourceUIDName,
        replaceAll: replaceAll
    }
}

export function resourceActionRemoveSingleResource(resourceType, resourceUID, removeFromUIDList) {
    return {
        type: RESOURCE_REMOVE_SINGLE_RESOURCE,
        resourceType: resourceType,
        resourceUID: resourceUID,
        removeFromUIDList: removeFromUIDList
    }
}

//
// RESOURCE UIDS
//
export function resourceActionUpdateResourceUIDs(resourceType, uidList, replaceAll = false) {
    return {
        type: RESOURCE_UPDATE_RESOURCE_UIDS,
        resourceType: resourceType,
        uidList: uidList,
        replaceAll: replaceAll
    }
}


//
// RESOURCE UIDS BY OWNER
//
export function resourceActionUpdateResourceUIDsForOwner(accountUID, resourceType, uidList, replaceAll = false) {
    return {
        type: RESOURCE_UPDATE_RESOURCE_UIDS_FOR_OWNER,
        accountUID: accountUID,
        resourceType: resourceType,
        uidList: uidList,
        replaceAll: replaceAll
    }
}

export function resourceActionUpdateSingleResource(resourceObject, resourceType) {
    return resourceActionUpdateResources(
        resourceType,
        [resourceObject],
        RESOURCE_TYPE_INFO[resourceType].uniqueIDName
    );
}

export const resourceActionAddSingleResource = (resourceObject, resourceType, addToUIDList) => {
    return dispatch => {
        const resourceInfo = RESOURCE_TYPE_INFO[resourceType];

        if (addToUIDList === true) {
            dispatch(resourceActionUpdateResourceUIDs(
                resourceType,
                [resourceObject[resourceInfo.uniqueIDName]],
                false
            ));
        }

        return resourceActionUpdateResources(
            resourceType,
            [resourceObject],
            resourceInfo.uniqueIDName
        );
    }
}
