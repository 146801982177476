import React from 'react';
import * as Sentry from "@sentry/browser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faGhost} from "@fortawesome/pro-solid-svg-icons";
import {axiosInstance} from "../../../utilities/axiosInstance";
import {getAdminPath} from "../../../api/adminAPI";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {adminLoadingActionLoadGhostAccountInfoFromServer} from "../../../actions/adminActions";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";
import Tooltip from "@material-ui/core/Tooltip";
import {ADMIN_GHOST_ACCOUNT_UID} from "../../../constants/adminConstants";
import {adminGhostAccountInfoSelector} from "../../../selectors/adminSelectors";

function SetAdminGhostButton({targetAccountUID, adminGhostAccountInfo, ...props}) {

    const [showSpinner, setShowSpinner] = React.useState(false);
    const buttonDisabled = targetAccountUID === ADMIN_GHOST_ACCOUNT_UID;
    const isGhost = adminGhostAccountInfo ? adminGhostAccountInfo.target_account_uid === targetAccountUID : false;

    if (isGhost === true) {
        return (
            <Tooltip
                title={'This is the current ghost account'}
                placement={'top'}
            >
            <span>
                <FontAwesomeIcon
                    icon={faCheck}
                    fixedWidth
                />
                </span>
            </Tooltip>
        );
    }

    return (
        <Tooltip
            title={'Set this as ghost account'}
            placement={'top'}
        >
            <span>
            <SpinnerButton
                // Non-button props
                showSpinner={showSpinner}
                // Button props
                variant="outlined"
                color="primary"
                disabled={showSpinner || buttonDisabled}
                onClick={handleClick}
            >
                <FontAwesomeIcon
                    icon={faGhost}
                    fixedWidth
                />
            </SpinnerButton>
                </span>
        </Tooltip>
    );

    function handleClick() {
        setShowSpinner(true);
        const clearGhostPath = getAdminPath(`ghost/set/target/${targetAccountUID}`);

        return axiosInstance.put(clearGhostPath, {})
            .then(function (response) {
                props.adminLoadingActionLoadGhostAccountInfoFromServer();
                setShowSpinner(false);
            })
            .catch(function (error) {
                // Tell Sentry about the error
                Sentry.withScope(scope => {
                    scope.setExtra("targetAccountUID", targetAccountUID);
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
            });
    }

}

const mapStateToProps = (state) => ({
    adminGhostAccountInfo: adminGhostAccountInfoSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminLoadingActionLoadGhostAccountInfoFromServer: adminLoadingActionLoadGhostAccountInfoFromServer
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(SetAdminGhostButton);