import React from "react";
import {faHandshake, faSkull, faUnicorn} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RHINO_COLOUR} from "../../constants/rhinoConstants";
import {faStripe} from "@fortawesome/free-brands-svg-icons";

export default function BankAccountInternalType({internalType}) {

    switch (internalType) {
        case "DEPOSIFY_HELD":
            return (
                <>
                    <FontAwesomeIcon
                        icon={faUnicorn}
                        fixedWidth
                        color={RHINO_COLOUR}
                    />
                    &nbsp;Rhino
                </>
            )
        case "PARTNER_HELD":
            return (
                <>
                    <FontAwesomeIcon
                        icon={faHandshake}
                        fixedWidth
                    />
                    &nbsp;Partner
                </>
            );
        case "STRIPE_CONNECTED_ACCOUNT":
            return (
                <>
                    <FontAwesomeIcon
                        icon={faStripe}
                        fixedWidth
                    />
                    &nbsp;Stripe
                </>
            );
        case "STRIPE_PARTNER_CONNECTED_ACCOUNT":
            return (
                <>
                    <FontAwesomeIcon
                        icon={faStripe}
                        fixedWidth
                    />
                    &nbsp;Stripe
                </>
            );
        default:
            return (
                <>
                    <FontAwesomeIcon
                        icon={faSkull}
                        fixedWidth
                        color={"red"}
                    />
                    &nbsp;Unknown!
                </>
            );
    }


}