// Client type: admin
// Name: System General Service Custom Render Components

export const DEPOSIFY_TAG_CUSTOM_RENDER_COMPONENTS = {};

export const SYSTEM_STATS_CUSTOM_RENDER_COMPONENTS = {};

export const RESOURCE_NOTE_CUSTOM_RENDER_COMPONENTS = {};

export const PERMANENT_LINK_CUSTOM_RENDER_COMPONENTS = {}
