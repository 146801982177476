/*
    Payment Cleared / Interest controls
 */
import React from 'react';
import DepositAdminButtonSetPaymentClearedAndEnableInterest
    from "./DepositAdminButtonSetPaymentClearedAndEnableInterest";
import DepositAdminButtonResetPaymentClearedAndDisableInterest
    from "./DepositAdminButtonResetPaymentClearedAndDisableInterest";
import DepositAdminButtonSetPaymentCleared from "./DepositAdminButtonSetPaymentCleared";
import DepositAdminButtonResetPaymentCleared from "./DepositAdminButtonResetPaymentCleared";
import DepositAdminButtonEnableInterest from "./DepositAdminButtonEnableInterest";
import DepositAdminButtonDisableInterest from "./DepositAdminButtonDisableInterest";
import Grid from "@material-ui/core/Grid";
import DepositAdminButtonSetPaymentClearedToPartnerAccount from "./DepositAdminButtonSetPaymentClearedToPartnerAccount";


function PaymentClearedAndInterestControls({deposit, ...props}) {

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid item>
                <DepositAdminButtonSetPaymentClearedToPartnerAccount deposit={deposit}/>
            </Grid>
            <Grid item>
                <DepositAdminButtonSetPaymentClearedAndEnableInterest deposit={deposit}/>
            </Grid>
            <Grid item>
                <DepositAdminButtonResetPaymentClearedAndDisableInterest deposit={deposit}/>
            </Grid>
            <Grid item>
                <DepositAdminButtonSetPaymentCleared deposit={deposit}/>
            </Grid>
            <Grid item>
                <DepositAdminButtonResetPaymentCleared deposit={deposit}/>
            </Grid>
            <Grid item>
                <DepositAdminButtonEnableInterest deposit={deposit}/>
            </Grid>
            <Grid item>
                <DepositAdminButtonDisableInterest deposit={deposit}/>
            </Grid>

        </Grid>
    );

}


export default PaymentClearedAndInterestControls;
