/*

 */

import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 12,
        fontWeight: 300,
        color: '#7d8385',
        [theme.breakpoints.up("md")]: {
            fontSize: 16
        },
    },
}));

export default function NoDataDisplay(props) {
    const classes = useStyles();

    const marginStyle = {
        marginTop: props.marginTop === undefined ? 10 : props.marginTop,
        marginBottom: props.marginBottom === undefined ? 10 : props.marginBottom
    };

    return (
        <Box
            display="flex"
            justify="center"
            style={marginStyle}
        >
            <Typography align={'center'} className={classes.root}>
                <FontAwesomeIcon
                    icon={props.icon}
                    fixedWidth
                />
                &nbsp;{props.message}
            </Typography>
        </Box>
    );

}

