import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Chip from "@material-ui/core/Chip";
import {faCheck, faUserCog} from "@fortawesome/pro-regular-svg-icons";




export default function PaymentAdminFlaggedChip({achPayment}) {

    const chipColour = achPayment.flagged_for_admin_review === true ? "secondary": "primary";
    const chipText = achPayment.flagged_for_admin_review === true ? "Flagged For Admin Review" : "No Admin Review Needed";

    if(achPayment.flagged_for_admin_review === true && achPayment.admin_review_complete === true){
        return (
            <Chip
                color={"primary"}
                icon={
                    <FontAwesomeIcon
                        icon={faCheck}
                        fixedWidth
                    />
                }
                label={'Admin review complete'}
            />
        );
    }

    return (
        <Chip
            color={chipColour}
            icon={
                <FontAwesomeIcon
                    icon={faUserCog}
                    fixedWidth
                />
            }
            label={chipText}
        />
    );
}
