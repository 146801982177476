/*
    Reset Payment Cleared / Interest controls
 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";

import PaymentsAdminService from "../../api/PaymentsAdminService";
import {
    RESOURCE_TYPE_PRIORITY_ACH_PAYMENT,
    RESOURCE_TYPE_STRIPE_PAYMENT_LOG
} from "../../autoGenerated/constants/resourceTypeConstants";
import ConfirmationDialog from "../utilities/dialogs/ConfirmationDialog";

import {resourceActionUpdateSingleResource} from "../../clientShared/actions/resourceActions";
import toast from "react-hot-toast";


// TODO: Update to use the payment type
function PaymentsAdminButtonSetAdminReviewState({paymentObject, isStripe, ...props}) {

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    if (paymentObject.flagged_for_admin_review === false) {
        return <React.Fragment/>;
    }

    const inSetCompleteMode = paymentObject.admin_review_complete === false;
    const wordChoice = inSetCompleteMode ? 'Complete' : 'Incomplete';

    return (
        <React.Fragment>
            <ConfirmationDialog
                startOpen={showConfirm}
                dangerMode={true}
                confirmCallback={acceptConfirm}
                cancelCallback={cancelConfirm}
                dialogTitle={'Are you sure?'}
                dialogText={`Are you sure you sure you mark this payment admin review as ${wordChoice}`}
                confirmText={`Mark as ${wordChoice}`}
                showSpinner={showSpinner}
                cancelFocus={true}
            />
            <Button
                color="primary"
                onClick={handleButtonClick}
                variant={'outlined'}
                size={'small'}
            >
                <FontAwesomeIcon
                    icon={faSkullCrossbones}
                    fixedWidth
                />
                &nbsp;Set Admin Review {wordChoice}
            </Button>
        </React.Fragment>
    );

    function handleButtonClick() {
        setShowSpinner(false);
        setShowConfirm(true);
    }

    function acceptConfirm() {
        if (isStripe) {
            return acceptConfirmStripe();
        }
        return acceptConfirmACH();
    }

    function acceptConfirmStripe() {
        setShowSpinner(true);

        PaymentsAdminService.AdminPaymentsStripeSetAdminReview(paymentObject.payment_log_uid, inSetCompleteMode)
            .then(function (response) {
                setShowConfirm(false);
                setTimeout(() => {
                    props.resourceActionUpdateSingleResource(
                        response.stripe_payment_log,
                        RESOURCE_TYPE_STRIPE_PAYMENT_LOG
                    );
                }, 500);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }

    function acceptConfirmACH() {
        setShowSpinner(true);

        PaymentsAdminService.AdminPaymentsACHSetAdminReview(paymentObject.payment_uid, inSetCompleteMode)
            .then(function (response) {
                setShowConfirm(false);
                setTimeout(() => {
                    props.resourceActionUpdateSingleResource(
                        response.priority_ach_payment,
                        RESOURCE_TYPE_PRIORITY_ACH_PAYMENT
                    );
                }, 500);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }

    function cancelConfirm() {
        setShowConfirm(false);
        toast('Cancelled action');
    }


}

const mapStateToProps = (state) => {
    // const _adminMakeSystemStatsSelector = adminMakeSystemStatsSelector();

    return function realMapState(state, props) {
        return {
            // systemStats: _adminMakeSystemStatsSelector(state, props.statsID)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentsAdminButtonSetAdminReviewState);
