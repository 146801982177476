/*
    Admin API
 */

import axiosRequest from "../clientShared/utilities/axios/AxiosRequest";

const ADMIN_URL_PATH = 'ornate/cabbage';

export function getAdminPath(pathPostFix) {
    return `api/${ADMIN_URL_PATH}/${pathPostFix}`;
}


//
// Service
//
const AdminAPI = {
    GenerateDepositReceipt,
    CreateUserAccountsForFinalization
};


export default AdminAPI;

//----------------------------------------------------------------------------------------------------------------------
function GenerateDepositReceipt(depositUID) {
    const getURL = getAdminPath(`deposits/${depositUID}/receipt/generate`);
    return axiosRequest({
        url: getURL,
        method: 'GET'
    });
}

// TODO: Move this, added for quick testing
//----------------------------------------------------------------------------------------------------------------------
function CreateUserAccountsForFinalization(postData) {
    const postURL = getAdminPath(`accounts/create/for/finalization/create/true`);
    return axiosRequest({
        url: postURL,
        data: [postData],
        method: 'POST'
    });
}

