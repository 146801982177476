import React from "react";
import GenericObjectDisplay from "../../../../clientShared/components/display/GenericObjectDisplay";
import AdminDisplayAttachedFiles from "../../../admin/displays/adminDisplayAttachedFiles";
import {
    AttachedStorageFilesContainer,
    DepositInviteContainer
} from "../../../../autoGenerated/components/containers/resourceContainers";
import {AdminDepositFilePondCard} from "../../../admin/controls/adminDepositFilePondCard";
import DownloadDepositReceiptLink from "../../../admin/buttons/downloadDepositReceiptLink";
import AdminDisplayDepositInvite from "../../../admin/displays/adminDisplayDepositInvite";
import DepositAdminControls from "../../../deposits/depositAdminControls";
import Grid from "@material-ui/core/Grid";
import DetailedDepositLink from "../../../deposits/DetailedDepositLink";
import IF from "../../../../clientShared/components/utility/IFComponent";
import RenderDeposifyTags from "../../../tags/DeposifyTags";
import DepositRhinoInfo from "../../../deposits/DepositRhinoInfo";
import {depositHasRhinoInfo} from "../../../deposits/rhinoFunctionality";
import DepositRhinoFundsInfo from "../../../deposits/DepositRhinoFundsInfo";


export function DepositDisplay({showHeaders = true, ...props}) {
    const hasRhinoInfo = depositHasRhinoInfo(props.deposit);

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >
            <IF condition={showHeaders}>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        // spacing={2}
                        style={{marginTop: 12}}
                    >
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start"
                            >
                                <Grid item>
                                    <DetailedDepositLink depositUID={props.deposit.deposit_uid}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <RenderDeposifyTags deposifyTags={props.deposit.deposify_tags}/>
                        </Grid>

                        <IF condition={props.deposit.funds_held_by_partner === true}>
                            <Grid item>
                                <DepositRhinoFundsInfo deposit={props.deposit}/>
                            </Grid>
                        </IF>

                        <IF condition={hasRhinoInfo}>
                            <Grid item>
                                <DepositRhinoInfo deposit={props.deposit}/>
                            </Grid>
                        </IF>

                    </Grid>
                </Grid>
            </IF>

            <Grid item>
                <GenericObjectDisplay {...props}/>
            </Grid>

            <Grid item>
                <DetailedDepositLink depositUID={props.deposit.deposit_uid}/>
            </Grid>

            <Grid item>
                <DepositAdminControls deposit={props.deposit}/>
            </Grid>

            <Grid item>
                <DepositInviteContainer depositUID={props.deposit.deposit_uid}>
                    <AdminDisplayDepositInvite/>
                </DepositInviteContainer>
            </Grid>

            <Grid item>
                <AttachedStorageFilesContainer attachedFilesUID={props.deposit.attached_files_uid}>
                    <AdminDisplayAttachedFiles/>
                </AttachedStorageFilesContainer>
            </Grid>

            {/*<Grid item>*/}
            {/*    <PermissionEntryContainer resourceUID={props.deposit.deposit_uid}>*/}
            {/*        <AdminDisplayPermissionEntry/>*/}
            {/*    </PermissionEntryContainer>*/}
            {/*</Grid>*/}

            <Grid item>
                <AdminDepositFilePondCard
                    deposit={props.deposit}
                    attachedFilesUID={props.deposit.attached_files_uid}
                />
            </Grid>

            <Grid item>
                <DownloadDepositReceiptLink
                    depositUID={props.deposit.deposit_uid}
                    depositState={props.deposit.state}
                />
            </Grid>


        </Grid>
    );
}

