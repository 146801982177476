// Client type: admin
// Name: Deposits Service Custom Render Components 

import AdminDepositStateRender from "../../../components/admin/displays/adminDepositStateRender";
import DepositDatesRender from "../../../components/admin/displays/depositDatesRender";
import DepositPartyDisplay from "../../../components/admin/displays/DepositPartyDisplay";
import AdminViewFullAccountInfoLink from "../../../components/admin/buttons/linkButtons/adminViewFullAccountInfoLink";
import CreatedUpdatedTimestampWrapper from "../../../components/admin/displays/CreatedUpdatedTimestampWrapper";
import {
    DEPOSIT_INVITE_OBJECT_KEY_INVITEE_ACCOUNT_TYPE,
    DEPOSIT_INVITE_OBJECT_KEY_INVITING_ACCOUNT_UID,
    DEPOSIT_INVITE_OBJECT_KEY_USE_EMAIL_ADDRESS,
    DEPOSIT_INVITE_OBJECT_KEY_USE_PHONE_NUMBER,
    DEPOSIT_OBJECT_KEY,
    DEPOSIT_OBJECT_KEY_COMPLETED_COMPLIANCE,
    DEPOSIT_OBJECT_KEY_DISABLED_COMPLIANCE,
    DEPOSIT_OBJECT_KEY_INTEREST_ENABLED,
    DEPOSIT_OBJECT_KEY_LANDLORD_ACCOUNT_UID, DEPOSIT_OBJECT_KEY_LANDLORD_HAS_ACCEPTED,
    DEPOSIT_OBJECT_KEY_START_DATE,
    DEPOSIT_OBJECT_KEY_TENANT_ACCOUNT_UID, DEPOSIT_OBJECT_KEY_TENANT_HAS_ACCEPTED,
    OBJECT_KEY_FUNDS_LOCATION,
    OBJECT_KEY_LAST_UPDATED_TIMESTAMP,
    OBJECT_KEY_STATE,
    PAYMENT_OBJECT_KEY_PAYMENT_CLEARED,
} from "../objects/objectKeys";
import DepositAccountTypeDisplay from "../../components/display/services/deposits/DepositAccountTypeDisplay";
import DepositInviteSentDisplay from "../../../components/admin/displays/depositInviteSentDisplay";
import RenderComplianceTags from "../../../components/tags/ComplianceTags";
import {FundsLocationDisplay} from "../../../components/deposits/FundsLocationDisplay";
import {TYPE_KEY_BOOL_VALUE} from "../types/TypeConstants";
import BooleanTypeRender from "../../components/types/BooleanTypeRender";
import DepositFundsHeldByPartner from "../../../components/deposits/depositsCustomRenderComponents";

export const ARCHIVED_DEPOSIT_CLOSE_REQUEST_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_STATE]: {
        dataPropName: 'deposit',
        renderComp: AdminDepositStateRender,
        passDataObject: true,
        extraProps: {}
    },
    [DEPOSIT_OBJECT_KEY_START_DATE]: {
        dataPropName: DEPOSIT_OBJECT_KEY,
        renderComp: DepositDatesRender,
        passDataObject: true,
        extraProps: {}
    },
    [DEPOSIT_OBJECT_KEY_TENANT_ACCOUNT_UID]: {
        dataPropName: DEPOSIT_OBJECT_KEY,
        renderComp: DepositPartyDisplay,
        passDataObject: true,
        extraProps: {useLandlord: false}
    },
    [DEPOSIT_OBJECT_KEY_LANDLORD_ACCOUNT_UID]: {
        dataPropName: 'deposit',
        renderComp: DepositPartyDisplay,
        passDataObject: true,
        extraProps: {useLandlord: true}
    },
    [DEPOSIT_OBJECT_KEY_COMPLETED_COMPLIANCE]: {
        dataPropName: 'deposit',
        renderComp: RenderComplianceTags,
        passDataObject: true,
        extraProps: {completedMode: true}
    },
    funds_held_by_partner: {
        dataPropName: 'fundsHeldByPartner',
        renderComp: DepositFundsHeldByPartner,
        passDataObject: false,
        extraProps: {}
    },
    [DEPOSIT_OBJECT_KEY_DISABLED_COMPLIANCE]: {
        dataPropName: 'deposit',
        renderComp: RenderComplianceTags,
        passDataObject: true,
        extraProps: {completedMode: false}
    },
    [OBJECT_KEY_FUNDS_LOCATION]: {
        dataPropName: 'sourceObject',
        renderComp: FundsLocationDisplay,
        passDataObject: true
    },
    [PAYMENT_OBJECT_KEY_PAYMENT_CLEARED]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender
    },
    [PAYMENT_OBJECT_KEY_PAYMENT_CLEARED]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender
    },
    [DEPOSIT_OBJECT_KEY_INTEREST_ENABLED]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender
    },
    [DEPOSIT_OBJECT_KEY_TENANT_HAS_ACCEPTED]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender
    },
    [DEPOSIT_OBJECT_KEY_LANDLORD_HAS_ACCEPTED]: {
        dataPropName: TYPE_KEY_BOOL_VALUE,
        renderComp: BooleanTypeRender
    },
};

// <DepositInviteSentDisplay depositInvite={depositInvite} useEmail={true} />
export const DEPOSIT_INVITE_CUSTOM_RENDER_COMPONENTS = {
    // [DEPOSIT_INVITE_OBJECT_KEY_DEPOSIT_INVITE_UID]: {
    //     dataPropName: 'uid',
    //     renderComp: CopyUIDLink,
    //     passDataObject: false,
    //     useOnTable: false
    // },
    [DEPOSIT_INVITE_OBJECT_KEY_INVITING_ACCOUNT_UID]: {
        dataPropName: 'accountUID',
        renderComp: AdminViewFullAccountInfoLink,
        passDataObject: false,
        extraProps: {showExtraInfo: true}
    },
    [OBJECT_KEY_LAST_UPDATED_TIMESTAMP]: {
        dataPropName: 'sourceObject',
        renderComp: CreatedUpdatedTimestampWrapper,
        passDataObject: true
    },
    [DEPOSIT_INVITE_OBJECT_KEY_INVITEE_ACCOUNT_TYPE]:{
        dataPropName: 'depositAccountType',
        renderComp: DepositAccountTypeDisplay,
        passDataObject: false
    },
    [DEPOSIT_INVITE_OBJECT_KEY_USE_EMAIL_ADDRESS]: {
        dataPropName: 'depositInvite',
        renderComp: DepositInviteSentDisplay,
        passDataObject: true,
        extraProps: {useEmail: true}
    },
    [DEPOSIT_INVITE_OBJECT_KEY_USE_PHONE_NUMBER]: {
        dataPropName: 'depositInvite',
        renderComp: DepositInviteSentDisplay,
        passDataObject: true,
        extraProps: {useEmail: false}
    },
};

export const DEPOSIT_ADJUSTMENT_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_BULK_IMPORT_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_CLOSE_REQUEST_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_GROUP_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_INTEREST_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_META_DATA_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_USER_GROUP_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_USER_STATS_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_WITHHELD_FUNDS_CUSTOM_RENDER_COMPONENTS = {};

export const DISPUTE_CUSTOM_RENDER_COMPONENTS = {};

export const DISPUTE_INTERVENTION_REQUEST_CUSTOM_RENDER_COMPONENTS = {};

export const DISPUTE_SPLIT_CUSTOM_RENDER_COMPONENTS = {};

export const PROPERTY_CUSTOM_RENDER_COMPONENTS = {
    'creating_account_uid': {
        dataPropName: 'accountUID',
        renderComp: AdminViewFullAccountInfoLink,
        passDataObject: false,
        extraProps: {showExtraInfo: true}
    },
    'created_timestamp': {
        dataPropName: 'sourceObject',
        renderComp: CreatedUpdatedTimestampWrapper,
        passDataObject: true
    }
};

export const PROPERTY_ADDRESS_CUSTOM_RENDER_COMPONENTS = {};

export const PROPERTY_GROUP_CUSTOM_RENDER_COMPONENTS = {};

export const PROPERTY_USER_GROUP_CUSTOM_RENDER_COMPONENTS = {};

export const COMPLIANCE_CUSTOM_RENDER_COMPONENTS = {};

export const COMPLIANCE_RULE_CUSTOM_RENDER_COMPONENTS = {};

export const COMPLIANCE_HISTORY_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_BANK_MAPPING_CUSTOM_RENDER_COMPONENTS = {};
export const DEPOSIT_INTEREST_LOG_CUSTOM_RENDER_COMPONENTS = {};

export const PMC_REPORT_TIMETABLE_CUSTOM_RENDER_COMPONENTS = {};

export const YARDI_DEPOSIT_XML_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_POST_CLOSE_ADJUSTMENT_CUSTOM_RENDER_COMPONENTS = {};
