import React from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";


const BankValidationResults = ({results}) => {
    if (Object.keys(results).length === 0) {
        return <><FontAwesomeIcon icon={faCheck} fixedWidth color={"#3cef66"}/>&nbsp;Okay</>;
    }

    return (
        <>
            {Object.keys(results).map((key, index) => (
                <span key={index}>
                    <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth color={"#ef3c3c"}/>&nbsp;
                    {results[key]}<br/>
                </span>
            ))}
        </>
    );

};


const RhinoBulkBankInfoBankAccounts = ({parsedData, validationResults}) => {
    if (Object.keys(parsedData).length === 0 || Object.keys(validationResults).length === 0) {
        return <>Loading...</>;
    }

    // todo: click to copy
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell>Bank Name</TableCell>
                        <TableCell>Nickname</TableCell>
                        <TableCell>Name On Account</TableCell>
                        <TableCell>Account Number</TableCell>
                        <TableCell>Routing Number</TableCell>
                        <TableCell>Account Type</TableCell>
                        <TableCell>U.S. State</TableCell>
                        <TableCell>Validation Results</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(parsedData.bankAccounts).map((bankAccountHash) => {
                            const bankAccount = parsedData.bankAccounts[bankAccountHash];
                            const bankValidation = validationResults.bank_accounts[bankAccountHash];

                            return (
                                <TableRow key={bankAccountHash}>
                                    <TableCell component="th" scope="row">{bankAccount.bank_name}</TableCell>
                                    <TableCell component="th" scope="row">{bankAccount.nickname}</TableCell>
                                    <TableCell component="th" scope="row">{bankAccount.name_on_account}</TableCell>
                                    <TableCell component="th" scope="row">{bankAccount.account_number}</TableCell>
                                    <TableCell component="th" scope="row">{bankAccount.routing_number}</TableCell>
                                    <TableCell component="th" scope="row">{bankAccount.account_type}</TableCell>
                                    <TableCell component="th" scope="row">{bankAccount.geo_state}</TableCell>
                                    <TableCell component="th" scope="row">
                                        <BankValidationResults results={bankValidation}/>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RhinoBulkBankInfoBankAccounts;