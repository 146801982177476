// Client type: admin
// Name: History Service Custom Render Components

import {OBJECT_KEY_ACCOUNT_UID} from "../objects/objectKeys";
import AdminViewFullAccountInfoLink from "../../../components/admin/buttons/linkButtons/adminViewFullAccountInfoLink";

export const SNAPSHOT_CUSTOM_RENDER_COMPONENTS = {};
export const SYSTEM_HISTORY_ENTRY_CUSTOM_RENDER_COMPONENTS = {};
export const UPDATE_LOG_CUSTOM_RENDER_COMPONENTS = {};
export const USER_HISTORY_ENTRY_CUSTOM_RENDER_COMPONENTS = {};

export const DEPOSIT_HISTORY_ENTRY_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_ACCOUNT_UID]: {
        dataPropName: "accountUID",
        renderComp: AdminViewFullAccountInfoLink,
        // passDataObject: false,
        // extraProps: {}
    },
};
