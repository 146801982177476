/*

 */

import React, {useState} from 'react';
import {axiosInstance} from "../../../utilities/axiosInstance";
import {getAdminPath} from "../../../api/adminAPI";
import {CircularProgress} from "@material-ui/core";
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {faAngel, faSkullCrossbones} from "@fortawesome/pro-regular-svg-icons";
import Collapse from "@material-ui/core/Collapse";
import OfflineStatusIndicator from "../OfflineStatusIndicator";
import SiteOfflineStatusSwitch from "../buttons/SiteOfflineStatusSwitch";
import KickAllOnlineUsersOffAdminButton from "../buttons/KickAllOnlineUsersOffAdminButton";
import Grid from "@material-ui/core/Grid";
import scrollIntoView from "scroll-into-view-if-needed";

const LAST_ELEMENT_ID = 'offline-controls-last-element';

const AdminOfflineControlsDisplay = (props) => {

    const [showControls, setShowControls] = useState(false);

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
            item id={LAST_ELEMENT_ID}
        >
            <Grid item>
                <br/>
                <Button
                    variant="contained"
                    color={showControls === false ? "secondary" : "primary"}
                    onClick={toggleControls}
                >
                    <FontAwesomeIcon
                        icon={showControls === false ? faSkullCrossbones : faAngel}
                        fixedWidth
                    />
                    &nbsp;{showControls === false ? 'Show Offline Controls' : 'Disable Offline Controls'}
                </Button>
                <br/><br/>
            </Grid>

            <Collapse in={showControls}>
                {/*<Grid item>*/}
                {/*    <OfflineStatusIndicator/>*/}
                {/*</Grid>*/}

                {/*<Grid item>*/}
                {/*    <SiteOfflineStatusSwitch/>*/}
                {/*</Grid>*/}

                <Grid>
                    <KickAllOnlineUsersOffAdminButton/>
                </Grid>
            </Collapse>
        </Grid>
    );

    function toggleControls(){
        const newValue = !showControls;
        setShowControls(newValue);
        if(newValue === true) {
            const node = document.getElementById(LAST_ELEMENT_ID);
            if (node !== null) {
                scrollIntoView(node, {
                    scrollMode: 'if-needed',
                    behavior: 'instant',
                    block: 'center',
                    inline: 'center',
                    boundary: document.getElementById(LAST_ELEMENT_ID),
                });
            }
        }
    }

}

export default AdminOfflineControlsDisplay;