/*

 */


import {RESOURCE_URL_PREFIX} from "../../constants/apiConstants";

export function createCommonResourceURL(resourceType, resourceUID) {
    return `/api/${RESOURCE_URL_PREFIX}/resources/resource/${resourceType}/${resourceUID}`
}


export function createCommonResourceUIDListURL() {
    return `/api/${RESOURCE_URL_PREFIX}/resources/uid/list`
}

