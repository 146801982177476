import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {usePropertyLoader} from "../../autoGenerated/hooks/resourceLoadingHooks";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const InternalListItem = ({propertyUID}) => {
    const {loading, error, property} = usePropertyLoader(propertyUID);

    if (error) {
        return <>Failed to load</>;
    }

    if (!property) {
        return <>Loading...</>;
    }

    return (
        <>
            {property.title}, {property.property_type}, {property.unit_apt_number}
        </>
    );
}

const PropertySelectListHack = ({selectedPropertyUID, propertyUIDList, setSelectedCallback}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(()=> {
        if(propertyUIDList.length !== 0){
            handleOpen();
        }
    }, [propertyUIDList])


    if (propertyUIDList.length === 0) {
        return <i>No properties to display</i>;
    }

    return (
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedPropertyUID ? selectedPropertyUID : ""}
            onChange={aChange}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
        >
            {
                propertyUIDList.map(propID => {
                    return (
                        <MenuItem value={propID} key={`prop-list-item-${propID}`}>
                            <InternalListItem
                                propertyUID={propID}
                            />
                        </MenuItem>
                    );
                })
            }
        </Select>
    );

    function aChange(x) {
        setSelectedCallback(x.target.value);
    }
};

export default PropertySelectListHack;





