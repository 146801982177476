import React from 'react';
import Grid from "@material-ui/core/Grid";
import * as Yup from "yup";
import {axiosInstance} from "../../../utilities/axiosInstance";
import * as Sentry from "@sentry/browser";
import toast from "react-hot-toast";
import {useFormik} from "formik";
import {StandardTextField} from "../../../components/utilities/forms/CommonFormFields";
import SpinnerButton from "../../../components/utilities/buttons/SpinnerButton";
import {getAdminPath} from "../../../api/adminAPI";


const JeffYardiTenantRefreshForm = ({doneCallback}) => {
    const [showSpinner, setShowSpinner] = React.useState(false);
    // const [serverError, setServerError] = React.useState(false);

    const formSchema = Yup.object().shape({
        property_uid: Yup.string().required(),
        tenant_code: Yup.string().required(),
    });

    const formik = useFormik({
        initialValues: {
            property_uid: "",
            tenant_code: '',
        },
        onSubmit: handleSubmit,
        isInitialValid: false,
        validationSchema: formSchema
    });

    function handleSubmit(values) {
        setShowSpinner(true);

        const baseURL = `jeff/yardi/property/${values.property_uid}/tenant/refresh/${values.tenant_code}`;

        const callURL = getAdminPath(baseURL);
        axiosInstance.get(callURL, {})
            .then(function (response) {
                setShowSpinner(false);
                toast.success('Done');
                formik.resetForm();
                doneCallback();
            })
            .catch(function (error) {
                setShowSpinner(false);

                // setServerError(true);
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            })
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="stretch"
                spacing={1}
            >
                <Grid item xs={12}>
                    {StandardTextField(formik, 'property_uid', 'Voyager Property UID')}
                </Grid>

                <Grid item xs={12}>
                    {StandardTextField(formik, 'tenant_code', 'Tenant Code')}
                </Grid>

                <Grid item xs={12}>
                    &nbsp;
                </Grid>

                <Grid item zeroMinWidth xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="nowrap"
                    >
                        <Grid item zeroMinWidth>
                            <SpinnerButton
                                // Non-button props
                                showSpinner={showSpinner}
                                // Button props
                                variant="contained"
                                color="primary"
                                disabled={!formik.isValid || showSpinner}
                                type={'submit'}
                            >
                                Refresh Tenant
                            </SpinnerButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>

    );
};


export default JeffYardiTenantRefreshForm;