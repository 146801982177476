/*

 */
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from "@material-ui/styles";
import {APP_THEME} from "./AppTheme";
import AppStartingSpinner from "./components/app/spinner/AppStartingSpinner";


export default function AppLoading(props) {

    return (
        <ThemeProvider theme={APP_THEME}>
            <CssBaseline/>
            <h1>Loading...</h1>
            <AppStartingSpinner/>
        </ThemeProvider>
    );

}

