/*

 */

import React from 'react';

export const ACCOUNT_NAME_DISPLAY_FULL_NAME = 0;
export const ACCOUNT_NAME_DISPLAY_FULL_NAME_WITH_TITLES = 1;
export const ACCOUNT_NAME_DISPLAY_USERNAME = 2;
export const ACCOUNT_NAME_DISPLAY_EMAIL = 3;

export default function UserAccountNameDisplay({userAccount, displayMode = ACCOUNT_NAME_DISPLAY_FULL_NAME, ...props}) {
    const namesList = [
        userAccount.first_name,
        userAccount.middle_name,
        userAccount.last_name,
        userAccount.name_title
    ];

    if (displayMode === ACCOUNT_NAME_DISPLAY_FULL_NAME) {
        return <React.Fragment>{namesList.join(' ')}</React.Fragment>;
    }
    else if(displayMode === ACCOUNT_NAME_DISPLAY_FULL_NAME_WITH_TITLES){
        return (
            <React.Fragment>
                <b>Full name</b>:&nbsp;{namesList.join(' ')}&nbsp;::&nbsp;
                [
                <RenderNameOrNone name={userAccount.name_title} prefix={'title'}/>&nbsp;
                <RenderNameOrNone name={userAccount.first_name} prefix={'first'}/>&nbsp;
                <RenderNameOrNone name={userAccount.middle_name} prefix={'middle'}/>&nbsp;
                <RenderNameOrNone name={userAccount.last_name} prefix={'last'}/>
                ]
            </React.Fragment>
        );
    }
    else if(displayMode === ACCOUNT_NAME_DISPLAY_USERNAME){
        return <React.Fragment>{userAccount.username}</React.Fragment>;
    }
    else if(displayMode === ACCOUNT_NAME_DISPLAY_EMAIL){
        return <React.Fragment>{userAccount.email_address}</React.Fragment>;
    }

    return <React.Fragment>Name Display Mode</React.Fragment>;


    function RenderNameOrNone({name, prefix}){
        return <React.Fragment>({prefix}) {name === '' ? <i>none</i> : name}</React.Fragment>
    }
}
