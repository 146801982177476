import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Chip from "@material-ui/core/Chip";
import {faDollarSign} from "@fortawesome/pro-regular-svg-icons";




export default function PaymentClearedChip({paymentObject}) {


    const paymentCleared = paymentObject.payment_cleared_into_deposify_bank_account;
    const chipColour = paymentCleared === true ? "primary" : "secondary";
    const chipText = paymentCleared === true ? "Payment Cleared" : "Payment NOT Cleared";

    return (
        <Chip
            color={chipColour}
            icon={
                <FontAwesomeIcon
                    icon={faDollarSign}
                    fixedWidth
                />
            }
            label={chipText}
        />
    );
}
