/*
    Banking Admin Service
 */

import axiosRequest from "../clientShared/utilities/axios/AxiosRequest";
import {getAdminPath} from "./adminAPI";

//
// Service
//
const BankingAdminService = {
    ToggleInterestEnabled
};

// Export
export default BankingAdminService;


//----------------------------------------------------------------------------------------------------------------------
function ToggleInterestEnabled(bankAccountUID, interestEnabled) {
    const url = getAdminPath(`banking/account/${bankAccountUID}/set/interest/enabled/${interestEnabled}`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}
