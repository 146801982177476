/*

 */

// Storage File Type
import {
    FILE_ICON,
    FILE_ICON_EXCEL,
    FILE_ICON_IMAGE,
    FILE_ICON_PDF,
    FILE_ICON_TEXT,
    FILE_ICON_WORD
} from "./commonIcons";

export const STORAGE_FILE_TYPE_IMAGE = 'image';
export const STORAGE_FILE_TYPE_ATTACHMENT = 'attachment';

// Storage File Category
export const STORAGE_FILE_CATEGORY_PROFILE_PIC = 'profile_pic';
export const STORAGE_FILE_CATEGORY_DEPOSIT_ATTACHMENT = 'deposit_attachment';
export const STORAGE_FILE_CATEGORY_MAILBOX_MESSAGE_ATTACHMENT = 'mailbox_message_attachment';
export const STORAGE_FILE_CATEGORY_DISPUTE_ATTACHMENT = 'dispute_attachment';
export const STORAGE_FILE_CATEGORY_DISPUTE_SPLIT_ATTACHMENT = 'dispute_split_attachment';


export function getFileIconByExtension(fileExtension){
    switch(fileExtension){
        case 'pdf':
            return FILE_ICON_PDF;
        case 'doc':
        case  'docx':
            return FILE_ICON_WORD;
        case 'xls':
            return FILE_ICON_EXCEL;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return FILE_ICON_IMAGE;
        case 'txt':
            return FILE_ICON_TEXT;
        default:
            return FILE_ICON;
    }
}