import Papa from "papaparse";
import BankAccount from "./rhinoBulkBankAccount";
import RhinoProperty from "./rhinoBulkBankProperty";


const field_mapping_info = {column_name: null, required: true, example: null, expected_col_names: [], warnings: []}
// todo: examples
const header_mapping_default = {
    meta: {
        hasAllRequiredColumns: false
    },
    rhino_property_id: {
        ...field_mapping_info,
        expected_col_names: ["property id", "rhino property id"],
        displayName: "Rhino Property ID",
        description: "Property ID, E.g. 123456"
    },
    bank_name: {
        ...field_mapping_info,
        expected_col_names: ["bank name"],
        displayName: "Bank Name",
        description: "E.g. Wells Fargo Main Street"
    },
    nickname: {
        ...field_mapping_info,
        required: false,
        expected_col_names: ["nickname"],
        displayName: "Nickname",
        description: "Optional, display nick name, E.g. Wells Fargo for Summerset Apts."
    },
    name_on_account: {
        ...field_mapping_info,
        expected_col_names: ["name on account"],
        displayName: "Name On Bank Account",
        description: "Jane Doe PMC"
    },
    account_number: {
        ...field_mapping_info,
        expected_col_names: ["account number"],
        displayName: "Bank Account Number",
        description: "You can prefix with zeros if necessary, E.g. 00123456789"
    },
    routing_number: {
        ...field_mapping_info,
        expected_col_names: ["routing number", "routing"],
        displayName: "Bank Routing Number",
        description: "You can prefix with zeros if necessary, E.g. 00234094890"
    },
    account_type: {
        ...field_mapping_info,
        expected_col_names: ["type", "account type"],
        displayName: "Bank Account Type",
        description: "Checking or Savings"
    },
    geo_state: {
        ...field_mapping_info,
        expected_col_names: ["state", "us state", "u.s. state"],
        required: false,
        displayName: "U.S. State",
        description: "Optional, two character State code, E.g. NY"
    },
    partner_id: {
        ...field_mapping_info,
        expected_col_names: ["partner id", "user id", "rhino user id"],
        displayName: "Partner User ID",
        description: "Partner user ID in Portal, E.g. 125662"
    }
};

class BulkBankTSV {
    constructor(rawTSVData) {
        this.rawTSVData = rawTSVData;

        this.columnNames = [];
        this.headerMapping = {...header_mapping_default};
        this.parsedData = {};
        this.parseComplete = false;
        this.validationResults = {};
    }

    parse() {
        this.parseTSVForHeaders();
        this.autoMapHeaders();
        this.parseRawData();
        this.parseComplete = true;

        return this;
    }

    parseTSVForHeaders() {
        let returnList = []

        Papa.parse(this.rawTSVData, {
            delimiter: "\t",
            header: true,
            complete: function (results) {
                // Get the column names
                returnList = [...results.meta.fields];
            }
        });

        // return returnList.map(item => item.toLowerCase().trim());
        this.columnNames = returnList;
    }

    autoMapHeaders() {
        let burnList = [];
        let hasAllRequiredColumns = true;

        Object.keys(this.headerMapping).map(currentKey => {
            if (currentKey === "meta") {
                return;
            }

            let currentField = this.headerMapping[currentKey];

            const matchColName = this.columnNames.find(element => currentField.expected_col_names.includes(element.trim().toLowerCase()));
            if (matchColName !== undefined) {
                currentField.column_name = matchColName;

                if (burnList.includes(matchColName)) {
                    // todo: clean this up
                    currentField.warnings.push("Multiple using same col")
                } else {
                    burnList.push(matchColName);
                }
            } else {
                if (currentField.required) {
                    hasAllRequiredColumns = false;
                }
            }
        })

        this.headerMapping.meta.hasAllRequiredColumns = hasAllRequiredColumns;
    }

    parseRawData() {
        const tsvRows = Papa.parse(this.rawTSVData, {
            delimiter: "\t",
            header: true,
        });

        let partnerID = -1;
        let bankAccounts = {};
        let properties = {};
        let rowInfo = [];
        let hasErrors = false;
        let hasMergedBankAccounts = false;

        for (let rowIndex = 0; rowIndex < tsvRows.data.length; rowIndex++) {
            const row = tsvRows.data[rowIndex];

            // Extract the bank account and see if it's already been seen on a previous row
            let bankAccount = new BankAccount().extractFromTSVRow(row, this.headerMapping);
            const bankAccountHash = bankAccount.generateHash();
            if (bankAccountHash in bankAccounts) {
                bankAccount = bankAccounts[bankAccountHash];
                hasMergedBankAccounts = true;
            } else {
                bankAccounts[bankAccountHash] = bankAccount;

                if(partnerID === -1)
                    partnerID = bankAccount.partner_id;
            }

            let currentRowInfo = {bankAccount: bankAccountHash, property: null, errors: []}

            // Extract the property, if possible, if not, record the error for display
            let rhinoProperty = null;
            try {
                rhinoProperty = new RhinoProperty().extractFromTSVRow(row, this.headerMapping);
            } catch (e) {
                currentRowInfo.errors.push(e);
                hasErrors = true;
            }

            if (rhinoProperty) {
                currentRowInfo.property = rhinoProperty.rhino_property_id;
                rhinoProperty.bank_hash = bankAccountHash;
                rhinoProperty.row_index = rowIndex;

                // If this is a duplicate property, then we need to do some checks and record some info
                if (rhinoProperty.rhino_property_id in properties) {
                    // Record the duplicate index to the original
                    let existingProperty = properties[rhinoProperty.rhino_property_id];
                    existingProperty.duplicate_rows.push(rowIndex);

                    // Check the bank info, it must match!
                    if (existingProperty.bank_hash !== bankAccountHash) {
                        currentRowInfo.errors.push(
                            `Property duplicate of row ${existingProperty.row_index}, but has different bank info`
                        );
                        hasErrors = true;
                    }
                } else {
                    properties[rhinoProperty.rhino_property_id] = rhinoProperty;
                }
            }

            rowInfo.push(currentRowInfo);
        }

        if(partnerID === -1)
            throw new Error("Parsing of TSV data complete, but Partner ID is not set")

        this.parsedData = {
            bankAccounts: bankAccounts,
            properties: properties,
            rowInfo: rowInfo,
            hasErrors: hasErrors,
            hasMergedBankAccounts: hasMergedBankAccounts,
            partnerID: partnerID
        };
        return this.parsedData;
    }

    generateValidationJSON() {
        let bankAccounts = {...this.parsedData.bankAccounts};
        Object.values(bankAccounts).map(currentBankAccount => delete currentBankAccount.meta);
        let propertyIDs = Object.keys(this.parsedData.properties);

        return {bank_accounts: bankAccounts, rhino_property_ids: propertyIDs, partner_id: this.parsedData.partnerID};
    }

}

export default BulkBankTSV;
