/*
    Dev
 */

import React from "react";
import GenericObjectWrapper from "../../clientShared/components/display/GenericObjectWrapper";
import Grid from "@material-ui/core/Grid";
import PageLinks from "./common/PageLinks";
import {convertHexUIDStringToFull} from "../../clientShared/utilities/uuidUtilities";
import AdminRootPage from "./AdminRootPage";

function SingleResourcePage({resourceType, ...props}) {
    // Grab the UID from the URL
    const resourceUID = convertHexUIDStringToFull(props.match.params.url_resource_uid);

    return (
        <AdminRootPage>

            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                wrap={'nowrap'}
            >
                <Grid item>
                    <PageLinks/>
                </Grid>

                <Grid item>
                    <GenericObjectWrapper
                        resourceUID={resourceUID}
                        resourceType={resourceType}
                    />
                </Grid>

                <Grid item>
                    <PageLinks/>
                </Grid>

            </Grid>

        </AdminRootPage>
    );

}

export default SingleResourcePage;
