import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {appActionSetFullScreenAccountDetails} from "../../../../actions/appActions";
import Link from "@material-ui/core/Link";
import {NULL_UUID_STRING} from "../../../../constants/dataConstants";
import AdminSmallUserAccountDisplay from "../../displays/adminSmallUserAccountDisplay";
import {UserAccountContainer} from "../../../../autoGenerated/components/containers/resourceContainers";
import {RESOURCE_TYPE_USER_ACCOUNT} from "../../../../autoGenerated/constants/resourceTypeConstants";
import {UIDLinkOrCopy} from "./UIDLinkOrCopy";

function AdminViewFullAccountInfoLink({
                                          accountUID,
                                          linkText = 'View',
                                          showAccountName = true,
                                          showExtraInfo = false,
                                          ...props
                                      }) {
    if (accountUID === NULL_UUID_STRING) {
        return <i>None</i>;
    }

    if (showAccountName === true || showExtraInfo === true) {
        return (
            <React.Fragment>
                {/*<Link*/}
                {/*    component="button"*/}
                {/*    onClick={linkClicked}*/}
                {/*>*/}
                {/*    View Account*/}
                {/*</Link>*/}
                <UIDLinkOrCopy
                    uid={accountUID}
                    resourceType={RESOURCE_TYPE_USER_ACCOUNT}
                    useCenter={true}
                />
                {/*<br/>*/}
                <UserAccountContainer accountUID={accountUID}>
                    <AdminSmallUserAccountDisplay showExtraInfo={showExtraInfo}/>
                </UserAccountContainer>
            </React.Fragment>
        );
    }

    return (
        <Link
            component="button"
            onClick={linkClicked}
        >
            {linkText}
        </Link>
    );

    function linkClicked() {
        props.appActionSetFullScreenAccountDetails(
            {
                showDialog: true,
                accountUID: accountUID
            }
        );
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        appActionSetFullScreenAccountDetails: appActionSetFullScreenAccountDetails
    }, dispatch);
};

export default connect(null, mapActionsToProps)(AdminViewFullAccountInfoLink);