/*

 */

import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import {ADMIN_ICON_FILES} from "../adminIcons";
import AdminDisplayCard from "./adminDisplayCard";
import QuickResourceInfoDisplay from "../../utilities/displayAreas/QuickResouceInfoDisplay";
import CreatedUpdatedTimestampDisplay from "../../utilities/displayAreas/CreatedUpdatedTimestampDisplay";
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import QuickTimestampDisplay from "../../utilities/displayAreas/QuickTimestampDisplay";
import {formatBytes} from "../../../utilities/commonUtils";
import AdminDownloadStorageFileLink from "../buttons/linkButtons/adminDownloadStorageFileLink";
import DeleteStorageFileLink from "../buttons/linkButtons/deleteStorageFileLink";
import {currentUserAccountUIDSelector} from "../../../selectors/userSelectors";
import Grid from "@material-ui/core/Grid";
import RenderDeposifyTags from "../../tags/DeposifyTags";


function AdminDisplayFile({loading, error, storageFile, fileUID, ...props}) {

    if (loading === true || error === true || storageFile === undefined) {
        return (
            <VisualizeContainerLoadingIndicator
                loadingResource={storageFile}
                displayName={'File'} {...props}
            />
        );
    }

    return (
        <AdminDisplayCard
            cardTitle={'File'}
            cardSubheader={storageFile.original_file_name}
            headerIcon={ADMIN_ICON_FILES}
            jsonObject={storageFile}
        >
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                wrap={'nowrap'}
                spacing={2}
            >
                <Grid item>
                    <AdminDownloadStorageFileLink fileUID={storageFile.file_uid}
                                                  useButton={true}/>
                </Grid>

                <Grid item>
                    <DeleteStorageFileLink fileUID={storageFile.file_uid}
                                           deletingAccountUID={props.currentUserAccountUID}
                                           useButton={true}/>
                </Grid>
            </Grid>

            <br/>


            <b>Uploaded by</b>: <AdminViewFullAccountInfoLink accountUID={storageFile.uploading_account_uid}
                                                              showExtraInfo={false}/>
            <br/>
            <b>Uploaded</b>: <QuickTimestampDisplay timestamp={storageFile.uploaded_timestamp} showTimeSince={true}/>
            <br/><br/>

            <b>State</b>: {storageFile.state} <br/>
            <br/>

            <b>Type</b>: {storageFile.file_type} <br/>
            <b>Size</b>: {formatBytes(storageFile.size_in_bytes).toString()} <br/>
            <b>Extension</b>: {storageFile.file_extension} <br/>
            <b>Original name</b>: {storageFile.original_file_name} <br/>
            <br/>

            <b>Bucket name</b>: {storageFile.bucket_name} <br/>
            <b>Bucket file name</b>: {storageFile.bucket_file_name} <br/>
            <b>Bucket folder path</b>: {storageFile.bucket_folder_path} <br/>
            <br/>

            <QuickResourceInfoDisplay
                resourceUID={storageFile.resource_uid}
                resourceType={storageFile.resource_type}
                showHorizontal={true}
            />
            <br/>
            <CreatedUpdatedTimestampDisplay
                createdTimestamp={storageFile.created_timestamp}
                lastUpdatedTimestamp={storageFile.last_updated_timestamp}
                showTimeSinceCreation={true}
                showTimeSinceUpdated={true}
            />
            <br/>
            <b>Tags</b>:&nbsp;
            <RenderDeposifyTags deposifyTags={storageFile.deposify_tags}/>


            {/*<br/>*/}
            {/*<b>Permissions</b>*/}
            {/*<AdminPermissionEntryContainer permissionEntryUID={storageFile.file_uid}>*/}
            {/*    <AdminPermissionDisplay/>*/}
            {/*</AdminPermissionEntryContainer>*/}
        </AdminDisplayCard>
    );

}

const mapStateToProps = (state) => ({
    currentUserAccountUID: currentUserAccountUIDSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(AdminDisplayFile);