/*
    Cancel Button
 */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    cancelButton: {
        marginRight: 'auto'
    }
}));

export default function CancelButton({handleCloseFunction, buttonText = 'Close', disabled=false}) {
    const classes = useStyles();

    return (
        <Button
            onClick={handleCloseFunction}
            className={classes.cancelButton}
            disabled={disabled}
        >
            {buttonText}
        </Button>
    );
}