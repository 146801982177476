import Typography from "@material-ui/core/Typography";
import React from "react";


const LOAN_STATE_PENDING = 'pending';
const LOAN_STATE_ACTIVE = 'active';
const LOAN_STATE_PAYMENT_DETAILS_NEEDED = 'payment_details_needed';
const LOAN_STATE_COMPLETE = 'complete';
const LOAN_STATE_DELINQUENT = 'delinquent';


const LoanState = ({state}) => {
    let fontColour = '#FFFFFF';
    if (state === LOAN_STATE_ACTIVE || state === LOAN_STATE_COMPLETE) {
        fontColour = '#24b73e';
    } else if (state === LOAN_STATE_DELINQUENT) {
        fontColour = '#b60e0e';
    } else if (state === LOAN_STATE_PAYMENT_DETAILS_NEEDED) {
        fontColour = '#b6b30e';
    }
    const style = {color: fontColour};

    return <Typography variant="body2" style={style}>{state}</Typography>;
}

export default LoanState;