/*
    Deposit Actions
 */
import * as Sentry from "@sentry/browser";
// Event types
import {
    API_ROUTE_ADD_FILES_TO_ATTACHED_STORAGE_FILES,
    API_ROUTE_CREATE_ATTACHED_STORAGE_FILES,
    API_ROUTE_READ_ATTACHED_STORAGE_FILES,
    API_ROUTE_READ_STORAGE_FILE
} from "../constants/apiRouteConstants";
import {axiosInstance} from "../utilities/axiosInstance";


export const UPDATE_STORAGE_FILES = 'STORAGE:UPDATE_STORAGE_FILES';
export const UPDATE_ATTACHED_STORAGE_FILES = 'STORAGE:UPDATE_ATTACHED_STORAGE_FILES';


// Events
export function updateStorageFiles(storageFiles) {
    return {
        type: UPDATE_STORAGE_FILES,
        storageFiles: storageFiles
    }
}

export function updateStorageFile(storageFile) {
    return updateStorageFiles([storageFile]);
}

export function updateAttachedStorageFiles(attachedStorageFiles) {
    return {
        type: UPDATE_ATTACHED_STORAGE_FILES,
        attachedStorageFiles: attachedStorageFiles
    }
}

export const readStorageFile = (fileUID) => {
    return dispatch => {
        axiosInstance.get(`${API_ROUTE_READ_STORAGE_FILE}/${fileUID}`)
            .then(function (response) {
                dispatch(updateStorageFiles([response.data.storage_file]));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const readAttachedStorageFiles = (attachedFilesUID) => {
    return dispatch => {
        axiosInstance.get(`${API_ROUTE_READ_ATTACHED_STORAGE_FILES}/${attachedFilesUID}`)
            .then(function (response) {
                dispatch(updateAttachedStorageFiles(response.data.attached_storage_files));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};

export const addFileToAttachedStorageFiles = (attachedFilesUID, fileUID) => {
    return dispatch => {
        const postValues = {
            'attached_files_uid': attachedFilesUID,
            'file_uid_list': [fileUID]
        };

        axiosInstance.post(API_ROUTE_ADD_FILES_TO_ATTACHED_STORAGE_FILES, postValues)
            .then(function (response) {
                dispatch(updateAttachedStorageFiles(response.data.attached_storage_files));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};


export const createAttachedStorageFiles = (resourceUID, resourceType, fileUIDs) => {
    return dispatch => {

        const postValues = {
            'resource_uid': resourceUID,
            'resource_type': resourceType,
            'file_uids': fileUIDs
        };

        axiosInstance.post(API_ROUTE_CREATE_ATTACHED_STORAGE_FILES, postValues)
            .then(function (response) {
                dispatch(updateAttachedStorageFiles(response.data.attached_storage_files));
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            })
    }
};