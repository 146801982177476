/*

 */

import React from 'react';
import * as Sentry from "@sentry/browser";
import Grid from '@material-ui/core/Grid';
import {Form, Formik} from 'formik';
import * as Yup from "yup";
import {YUP_SCHEMA_EMAIL_ADDRESS, YUP_SCHEMA_PASSWORD} from "../../../utilities/yupCommonValidationSchemas";
import {EmailAddressField, PasswordField} from "../../utilities/forms/CommonFormFields";
import {postLoginAction} from "../../../actions/authActions";
import {connect} from "react-redux";
import {axiosInstance} from "../../../utilities/axiosInstance";
import {API_ROUTE_AUTH_LOGIN} from "../../../constants/apiRouteConstants";
import {bindActionCreators} from "redux";
import {makeStyles} from "@material-ui/core";
import {
    LOGIN_STATE_ACCOUNT_BANNED,
    LOGIN_STATE_EMAIL_NOT_VERIFIED,
    LOGIN_STATE_LOGIN_DETAILS_INCORRECT
} from "../../../constants/authConstants";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";
import ErrorInfoDisplay from "../../utilities/displayAreas/ErrorInfoDisplay";


const useStyles = makeStyles(theme => ({
    loginButton: {
        color: '#FFFFFF',
        minWidth: 150,
        marginLeft: 'auto'
    }
}));

function LoginForm(props) {
    const classes = useStyles();

    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [serverErrorMessage, setServerErrorMessage] = React.useState('');
    const [serverError, setServerError] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    const LoginValidationSchema = Yup.object().shape({
        ...YUP_SCHEMA_EMAIL_ADDRESS,
        ...YUP_SCHEMA_PASSWORD
    });

    function handleLoginError(login_state) {

        switch (login_state) {
            case LOGIN_STATE_LOGIN_DETAILS_INCORRECT:
                setServerErrorMessage('Incorrect login details, please check your details and try again');
                break;
            case LOGIN_STATE_EMAIL_NOT_VERIFIED:
                setServerErrorMessage('Please verify you email address before trying to login');
                break;
            case LOGIN_STATE_ACCOUNT_BANNED:
                setServerErrorMessage('This account has been banned from Deposify.');
                break;
            default:
                setServerErrorMessage('Unable to login at this time. Please contact support if the issue persists');
                break;
        }

        setShowSpinner(false);
        setServerError(true);
    }

    const handleSubmit = (formValues) => {
        setShowSpinner(true);
        setServerError(false);

        axiosInstance.post(API_ROUTE_AUTH_LOGIN, formValues)
            .then(function (response) {
                setShowSpinner(false);
                props.postLoginAction(
                    response.data.user_account,
                    response.data.access_token,
                    response.data.refresh_token,
                    props.preLoginRoute
                );
            })
            .catch(function (error) {
                // No point sending this to Sentry if it's just a bad login
                if (error.response.status !== 400) {
                    Sentry.withScope(scope => {
                        scope.setExtra("response", error.response);
                        Sentry.captureException(error);
                    });
                }

                // Use the data if it's there, otherwise send back a generic message
                if (error.response && error.response.data) {
                    handleLoginError(error.response.data.login_state);
                } else {
                    setServerError(true);
                }

                setShowSpinner(false);
            })
    };

    function togglePasswordVisibility() {
        setPasswordVisible(!passwordVisible);
    }

    return (
        <React.Fragment>

            <Formik
                onSubmit={handleSubmit}
                initialValues={{
                    email_address: "",
                    password: ''
                }}
                initialStatus={{}}
                validationSchema={LoginValidationSchema}
                render={formikProps => (
                    <Form>
                        <Grid
                            container
                            direction="column"
                            justify="space-evenly"
                            alignItems="stretch"
                            spacing={1}
                            wrap="nowrap"
                        >
                            <Grid item zeroMinWidth>
                                {EmailAddressField(formikProps)}
                            </Grid>

                            <Grid item zeroMinWidth>
                                {PasswordField(formikProps, passwordVisible, togglePasswordVisibility)}
                            </Grid>

                            <Grid item zeroMinWidth>
                                <ErrorInfoDisplay showError={serverError}>
                                    {serverErrorMessage}
                                </ErrorInfoDisplay>
                            </Grid>

                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    <Grid item zeroMinWidth>
                                        <SpinnerButton
                                            // Non-button props
                                            showSpinner={showSpinner}
                                            // Button props
                                            variant="contained"
                                            color="primary"
                                            className={classes.loginButton}
                                            disabled={!formikProps.isValid || showSpinner}
                                            type={'submit'}
                                        >
                                            Login
                                        </SpinnerButton>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>


                    </Form>
                )}
            />

        </React.Fragment>
    )
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        postLoginAction: postLoginAction
    }, dispatch);
};


export default connect(null, mapActionsToProps)(LoginForm);