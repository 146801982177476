import React, {useState} from 'react';
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/browser";
import SpinnerButton from "../utilities/buttons/SpinnerButton";


const AdminSendEmailVerificationButton = ({accountUID, buttonText = "Send VerificationEmail", disabled = false}) => {

    const [showSpinner, setShowSpinner] = useState(false);

    function handleButtonPress() {
        setShowSpinner(true);

        const callURL = getAdminPath(`jeff/account/${accountUID}/email/verify/send`);
        axiosInstance.get(callURL)
            .then(function (response) {
                setShowSpinner(false);
                toast.success('Done');
            })
            .catch(function (error) {
                setShowSpinner(false);

                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            })
    }

    return (
        <SpinnerButton
            // Non-button props
            showSpinner={showSpinner}
            // Button props
            variant="text"
            color="primary"
            disabled={showSpinner || disabled}
            onClick={handleButtonPress}
        >
            {buttonText}
        </SpinnerButton>
    );

};


export default AdminSendEmailVerificationButton;