/*
    Payment Cleared / Interest controls
 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import DepositsAdminService from "../../../api/depositsAdminService";
import {resourceActionUpdateSingleResource} from "../../../clientShared/actions/resourceActions";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";
import {DEPOSIT_STATE_PENDING} from "../../../autoGenerated/constants/services/DepositsServiceConstants";
import toast from "react-hot-toast";


function DepositAdminButtonResendInvite({deposit, ...props}) {

    const [showSpinner, setShowSpinner] = React.useState(false);

    if (deposit.state !== DEPOSIT_STATE_PENDING) {
        return <React.Fragment/>;
    }

    return (
        <React.Fragment>
            <SpinnerButton
                // Non-button props
                showSpinner={showSpinner}
                // Button props
                variant="outlined"
                color="primary"
                disabled={showSpinner}
                onClick={handleClick}
            >
                <FontAwesomeIcon
                    icon={faSkullCrossbones}
                    fixedWidth
                />
                &nbsp;Resend Invite Email
            </SpinnerButton>
        </React.Fragment>
    );

    function handleClick() {
        setShowSpinner(true);
        DepositsAdminService.ResendDepositInvite(deposit.deposit_uid)
            .then(function (response) {
                toast.success('Invite sent');
                setShowSpinner(false);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }

}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(null, mapActionsToProps)(DepositAdminButtonResendInvite);
