/**
 */

// Generic form dialog modes
export const GENERIC_UI_FORM_MODE_CREATE = 'GFM:CREATE';
export const GENERIC_UI_FORM_MODE_EDIT = 'GFM:EDIT';

// Initial state for the generic form dialog
export const GENERIC_UI_GENERIC_FORM_DIALOG_INITIAL_STATE = {
    showDialog: false,
    formID: undefined,
    dialogTitle: 'Form Title',
    initialFormData: {},
    formMode: GENERIC_UI_FORM_MODE_CREATE
};