import React from 'react';
import Typography from "@material-ui/core/Typography";
import {BILLION_BRIGHT_BLUE} from "../../../constants/dataConstants";

const TSV_URL = "https://deposify-public-cdn.s3.us-east-2.amazonaws.com/site/forms/Property+%26+Bank+Sample.tsv";
const XLSX_URL = "https://deposify-public-cdn.s3.us-east-2.amazonaws.com/site/forms/Property+%26+Bank+Sample.xlsx";

const TextDisplay = () => {
    return (

        <Typography variant="body2" color="textSecondary" component="p">
            A TSV (Tab Seperated Values) file is expected, the first line should be the column names.
            <br/>
            In Google docs you can download a Sheets file in TSV format by going File -> Download -> TSV.
            <br/>
            Here are two sample TSV files with columns:&nbsp;
            <a href={TSV_URL} target="_blank" rel="noopener noreferrer"
               style={{color: BILLION_BRIGHT_BLUE, textDecoration: 'none'}}>
                TSV Sample
            </a>,&nbsp;
            <a href={XLSX_URL} target="_blank" rel="noopener noreferrer"
               style={{color: BILLION_BRIGHT_BLUE, textDecoration: 'none'}}>
                XLSX Sample
            </a>
        </Typography>

    );
};


const RhinoBankBulkUploadFileInfoSamples = ({}) => {
    return <TextDisplay/>
};

export default RhinoBankBulkUploadFileInfoSamples;