/*
    Auth Utilities
 */

import jwt_decode from "jwt-decode";
import {
    API_AUTH_ACCESS_TOKEN,
    API_AUTH_REFRESH_TOKEN
} from "../constants/apiConstants";


export function getAccessTokenFromLocalStorage() {
    return localStorage.getItem(API_AUTH_ACCESS_TOKEN);
}

export function getRefreshTokenFromLocalStorage() {
    return localStorage.getItem(API_AUTH_REFRESH_TOKEN);
}

export function setLocalStorageAccessToken(newAccessToken) {
    localStorage.setItem(API_AUTH_ACCESS_TOKEN, newAccessToken);
}

export function setLocalStorageRefreshToken(newRefreshToken) {
    localStorage.setItem(API_AUTH_REFRESH_TOKEN, newRefreshToken);
}

export function setAccessAndRefreshTokens(newAccessToken, newRefreshToken){
    setLocalStorageAccessToken(newAccessToken);
    setLocalStorageRefreshToken(newRefreshToken);
}

export function decodeJWTToken(encodedToken){
    return jwt_decode(encodedToken)
}

export function getTokenExpireTimestamp(encodedToken){
    return decodeJWTToken(encodedToken).exp;
}

export function getSecondsToTokenExpiry(encodedToken){
    const decodedToken = decodeJWTToken(encodedToken);
    const nowTimeStamp = Math.floor(Date.now() / 1000);

    return decodedToken.exp - nowTimeStamp;
}

export function hasTokenExpired(encodedToken){
    const secondsToExpireTime = getSecondsToTokenExpiry(encodedToken);
    return secondsToExpireTime <= 0;
}