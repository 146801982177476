/*

 */

import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import ErrorInfoDisplay from "../../utilities/displayAreas/ErrorInfoDisplay";
import AdminDownloadStorageFileLink from "../buttons/linkButtons/adminDownloadStorageFileLink";
import {ADMIN_ICON_FILES} from "../adminIcons";
import AdminDisplayCard from "./adminDisplayCard";
import QuickResourceInfoDisplay from "../../utilities/displayAreas/QuickResouceInfoDisplay";
import CreatedUpdatedTimestampDisplay from "../../utilities/displayAreas/CreatedUpdatedTimestampDisplay";
import AdminViewFullAccountInfoLink from "../buttons/linkButtons/adminViewFullAccountInfoLink";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import FullScreenFileLink from "../buttons/linkButtons/fullScreenFileLink";
import {StorageFileContainer} from "../../../autoGenerated/components/containers/resourceContainers";

function AdminDisplayAttachedFiles({loading, error, attachedStorageFiles, ...props}) {

    if (loading === true || error === true || attachedStorageFiles === undefined) {
        return <VisualizeContainerLoadingIndicator loadingResource={attachedStorageFiles}
                                                   displayName={'Attached Files'} {...props} />;
    }

    return (
        <AdminDisplayCard
            cardTitle={'Attached Files'}
            cardSubheader={`File count: ${attachedStorageFiles.file_uids.length.toString()}`}
            headerIcon={ADMIN_ICON_FILES}
            jsonObject={attachedStorageFiles}
        >
            <h3>Files: {attachedStorageFiles.file_uids.length.toString()}</h3>

            <List dense={true}>

                {
                    attachedStorageFiles.file_uids.map(fileUID => {
                        return (
                            <StorageFileContainer fileUID={fileUID} key={fileUID}>
                                <StorageFileDisplayListItem/>
                            </StorageFileContainer>
                        );
                    })
                }
            </List>

            <br/>
            <b>Created by</b>: <AdminViewFullAccountInfoLink accountUID={attachedStorageFiles.creating_account_uid}
                                                             showExtraInfo={false}/>
            <br/>
            <QuickResourceInfoDisplay
                resourceUID={attachedStorageFiles.resource_uid}
                resourceType={attachedStorageFiles.resource_type}
                showHorizontal={true}
            />
            <br/>
            <CreatedUpdatedTimestampDisplay
                createdTimestamp={attachedStorageFiles.created_timestamp}
                lastUpdatedTimestamp={attachedStorageFiles.last_updated_timestamp}
                showTimeSinceCreation={true}
                showTimeSinceUpdated={true}
            />
        </AdminDisplayCard>
    );

    function StorageFileDisplay({loading, error, storageFileUID, storageFile, ...rowProps}) {

        if (error === true) {
            return (
                <ErrorInfoDisplay showError={true}>
                    Failed to load storageFile
                </ErrorInfoDisplay>
            );
        }

        if (loading === true || error === true || storageFile === undefined) {
            return <VisualizeContainerLoadingIndicator loadingResource={storageFile}
                                                       displayName={'StorageFile'} {...props} />;
        }

        return (
            <React.Fragment>
                <AdminDownloadStorageFileLink fileUID={storageFile.file_uid}/>&nbsp;-&nbsp;
                {storageFile.file_type}&nbsp;-&nbsp;
                {storageFile.original_file_name}&nbsp;
            </React.Fragment>
        );
    }

    function StorageFileDisplayListItem({loading, error, fileUID, storageFile, ...rowProps}) {

        if (loading === true || error === true || storageFile === undefined) {
            return <VisualizeContainerLoadingIndicator loadingResource={storageFile}
                                                       displayName={'StorageFile'} {...props} />;
        }

        return (
            <React.Fragment>
                <ListItem>
                    {/*<ListItemAvatar>*/}
                    {/*    <Avatar>*/}
                    {/*        <FontAwesomeIcon*/}
                    {/*            icon={faPoo}*/}
                    {/*            fixedWidth*/}
                    {/*        />*/}
                    {/*    </Avatar>*/}
                    {/*</ListItemAvatar>*/}
                    <ListItemText
                        primary={storageFile.original_file_name}
                        secondary={storageFile.file_type}
                    />
                    <ListItemSecondaryAction>
                        <FullScreenFileLink fileUID={fileUID} useIconButton={true}/>
                        {/*<RemoveFileFromAttachmentsLink attachedFilesUID={attachedStorageFiles.attached_files_uid} fileUID={storageFileUID} useIconButton={true}/>*/}
                    </ListItemSecondaryAction>
                </ListItem>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => ({});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(AdminDisplayAttachedFiles);