/*
    Auth API
 */

import axiosRequest from "../clientShared/utilities/axios/AxiosRequest";
import {getAdminPath} from "./adminAPI";


//
// Service
//
const AuthAPI = {
    SetSiteOfflineStatus
};


export default AuthAPI;

//----------------------------------------------------------------------------------------------------------------------
function SetSiteOfflineStatus(newOfflineStatus) {
    const getURL = getAdminPath(`auth/offline/status/set/${newOfflineStatus}`);
    return axiosRequest({
        url: getURL,
        method: 'PUT',
        data: {}
    });
}

