/*

 */

import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import ErrorInfoDisplay from "../../utilities/displayAreas/ErrorInfoDisplay";
import {ADMIN_ICON_BANK_ACCOUNT} from "../adminIcons";
import AdminDisplayCard from "./adminDisplayCard";
import BankAccountStateSelector from "../selectors/BankAccountStateSelector";

function AdminDisplayUSDomesticBankAccount({loading, error, bankAccount, ...props}) {

    if(error === true){
        return (
            <ErrorInfoDisplay showError={true}>
                Failed to load US Domestic bank account
            </ErrorInfoDisplay>
        );
    }

    if(loading === true || error === true || bankAccount === undefined){
        return <VisualizeContainerLoadingIndicator loadingResource={bankAccount} displayName={'US Domestic bank account'} {...props} />;
    }

    return (
        <AdminDisplayCard
            cardTitle={'US Domestic Bank Account'}
            cardSubheader={''}
            headerIcon={ADMIN_ICON_BANK_ACCOUNT}
            jsonObject={bankAccount}
        >
            <b>State: </b> <BankAccountStateSelector bankAccount={bankAccount}/>
            <br/>

            {
                Object.keys(bankAccount).map(
                    accountKey => {
                        return (
                            <React.Fragment key={accountKey}>
                                <b>{accountKey}</b>: {bankAccount[accountKey].toString()}<br/>
                            </React.Fragment>);
                    }
                )
            }
        </AdminDisplayCard>
    );

}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(AdminDisplayUSDomesticBankAccount);