/*
    Reset Payment Cleared / Interest controls
 */
import React from 'react';
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ConfirmationDialog from "../../utilities/dialogs/ConfirmationDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import DepositsAdminService from "../../../api/depositsAdminService";
import {
    RESOURCE_TYPE_DEPOSIT
} from "../../../autoGenerated/constants/resourceTypeConstants";
import {resourceActionUpdateSingleResource} from "../../../clientShared/actions/resourceActions";
import {DEPOSIT_STATE_ACTIVE} from "../../../autoGenerated/constants/services/DepositsServiceConstants";
import toast from "react-hot-toast";


function DepositAdminButtonResetPaymentClearedAndDisableInterest({deposit, ...props}) {

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);

    // Don't even show this button if all conditions are not met
    if (deposit.interest_enabled === false ||
        deposit.payment_cleared === false ||
        deposit.state !== DEPOSIT_STATE_ACTIVE) {
        return <React.Fragment/>;
    }

    return (
        <React.Fragment>
            <ConfirmationDialog
                startOpen={showConfirm}
                dangerMode={true}
                confirmCallback={acceptConfirm}
                cancelCallback={cancelConfirm}
                dialogTitle={'Are you sure?'}
                dialogText={'Are you sure you sure you mark RESET PAYMENT CLEARED and DISABLE INTEREST?'}
                confirmText={'Reset and Disable'}
                showSpinner={showSpinner}
                cancelFocus={true}
            />
            <Button
                color="secondary"
                onClick={handleButtonClick}
                variant={'contained'}
            >
                <FontAwesomeIcon
                    icon={faSkullCrossbones}
                    fixedWidth
                />
                &nbsp;Reset Payment & Disable Interest
            </Button>
        </React.Fragment>
    );

    function handleButtonClick() {
        setShowSpinner(false);
        setShowConfirm(true);
    }

    function acceptConfirm() {
        setShowSpinner(true);
        DepositsAdminService.DepositResetPaymentClearedAndDisableInterest(deposit.deposit_uid)
            .then(function (response) {
                toast.success('Reset payment cleared and interest disabled');
                setShowConfirm(false);
                setTimeout(() => {
                    props.resourceActionUpdateSingleResource(response.deposit, RESOURCE_TYPE_DEPOSIT);
                }, 500);
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }

    function cancelConfirm() {
        setShowConfirm(false);
        toast('Cancelled action');
    }


}

const mapStateToProps = (state) => {
    // const _adminMakeSystemStatsSelector = adminMakeSystemStatsSelector();

    return function realMapState(state, props) {
        return {
            // systemStats: _adminMakeSystemStatsSelector(state, props.statsID)
        };
    }
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(DepositAdminButtonResetPaymentClearedAndDisableInterest);
