import {
    RESOURCE_TYPE_ALL_MAILBOX_MESSAGES,
    RESOURCE_TYPE_DEPOSIT,
    RESOURCE_TYPE_USER_ACCOUNT,
    RESOURCE_TYPE_USER_ACCOUNT_BALANCE,
    RESOURCE_TYPE_USER_ACCOUNT_META_DATA, RESOURCE_TYPE_USER_ACCOUNT_SETTINGS
} from "../constants/resourceConstants";
import {LOADING_INDICATOR_STATE_ERROR, LOADING_INDICATOR_STATE_LOADING} from "../constants/loadingConstants";
import {NULL_UUID_STRING} from "../constants/dataConstants";
import {isEmpty} from "./commonUtils";

// TODO: Update master with this
export function createLoadingIndicatorUID(resourceUID, resourceType) {
    /*
        Multiple resources can have the same UID, e.g. UserAccount and UserAccountBalance, so we need a way
        to differentiate between them
     */
    switch (resourceType) {
        case RESOURCE_TYPE_DEPOSIT:
            return `DEP_${resourceUID}`;
        case RESOURCE_TYPE_ALL_MAILBOX_MESSAGES:
            return `ALL_MSG_${resourceUID}`;
        case RESOURCE_TYPE_USER_ACCOUNT:
            return `ACC_${resourceUID}`;
        case RESOURCE_TYPE_USER_ACCOUNT_BALANCE:
            return `ACC_BAL_${resourceUID}`;
        case RESOURCE_TYPE_USER_ACCOUNT_META_DATA:
            return `ACC_MD_${resourceUID}`;
        case RESOURCE_TYPE_USER_ACCOUNT_SETTINGS:
            return `ACC_SETTINGS_${resourceUID}`;
        default:
            return resourceUID;
    }
}

export function createUIDListLoadingResourceUID(accountUID, resourceType){
    return `uid_list_${accountUID}_${resourceType}`;
}

export function checkLoadingIndicator(loadingIndicator) {
    let returnValues = {
        loading: false,
        error: false,
        response: undefined,
        nullLoadingIndicator: true
    };

    if (loadingIndicator === undefined) {
        return returnValues;
    }

    returnValues.loading = loadingIndicator.state === LOADING_INDICATOR_STATE_LOADING;
    returnValues.error = loadingIndicator.state === LOADING_INDICATOR_STATE_ERROR;
    returnValues.response = loadingIndicator.response;
    returnValues.nullLoadingIndicator = false;

    return returnValues;
}

export function loadingIndicatorShouldProceedWithLoad(loadingIndicator) {
    // If there's no indicator, then there's nothing stopping us from loading
    if (loadingIndicator === undefined) {
        return true;
    }

    const loading = loadingIndicator.state === LOADING_INDICATOR_STATE_LOADING;
    const error = loadingIndicator.state === LOADING_INDICATOR_STATE_ERROR;

    return loading === false && error === false;
}

export function checkResourceUIDIsValid(resourceUID) {
    return resourceUID !== undefined && resourceUID !== NULL_UUID_STRING;
}

export function checkSystemStatsLoadingIndicator(systemStats) {
    let returnValues = {
        loading: false,
        error: false,
        loaded: false
    };

    if (systemStats === undefined || isEmpty(systemStats)) {
        return returnValues;
    }

    returnValues.loading = systemStats.loading;
    returnValues.error = systemStats.error;
    returnValues.loaded = systemStats.loaded;

    return returnValues;
}