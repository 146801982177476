import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DANGER_BUTTON_COLOR} from "../../constants/dataConstants";
import {fullScreenDisplaySelector} from "../../selectors/adminSelectors";
import {ADMIN_FULL_SCREEN_DISPLAY_MODE_DRAWER} from "../../constants/components/componentsConstants";
import {adminActionSetFullScreenHistoryChain, adminActionSetGenericFullScreenDisplay} from "../../actions/adminActions";
import GenericObjectWrapper from "../../clientShared/components/display/GenericObjectWrapper";
import DataDisplayDrawerNavigation from "./DataDisplayDrawerNavigation";
import Divider from "@material-ui/core/Divider";


const useStyles = makeStyles(theme => ({
    list: {
        //width: 250,
        minWidth: 400,
        margin: 10
    },
    fullList: {
        width: 'auto',
    },
    logoutText: {
        color: DANGER_BUTTON_COLOR
    },
    buttonContainer: {
        position: 'fixed',
        top: 8,
        left: -5,
        //backgroundColor: '#FFFFFF',
        zIndex: theme.zIndex.modal
    },
    menuButton: {
        backgroundColor: '#FFFFFF'
    },
    drawer: {
        minWidth: 400,
        flexShrink: 0
    },
    drawerContents: {
        // maxWidth: 300,
        // [theme.breakpoints.up("md")]: {
        //     maxWidth: 600
        // },
        // [theme.breakpoints.up("lg")]: {
        //     maxWidth: 1000
        // }
    }
}));

function DataDisplayDrawer(props) {
    const classes = useStyles();

    const drawerIsOpen = props.fullScreenDisplay.showDialog === true && props.fullScreenDisplay.displayMode === ADMIN_FULL_SCREEN_DISPLAY_MODE_DRAWER;

    return (
        <React.Fragment>
            <Drawer
                open={drawerIsOpen}
                onClose={closeDrawer}
                // variant="persistent"
                anchor="right"
                className={classes.drawer}
            >
                <div className={classes.drawerContents}>
                    <DrawerContents/>
                </div>
            </Drawer>
        </React.Fragment>
    );

    function closeDrawer() {
        props.adminActionSetGenericFullScreenDisplay(
            {
                showDialog: false,
                resourceUID: props.fullScreenDisplay.resourceUID,
                resourceType: props.fullScreenDisplay.resourceType,
                displayMode: props.fullScreenDisplay.displayMode
            }
        );

        props.adminActionSetFullScreenHistoryChain([]);
    }

    function DrawerContents() {
        return (
            <div
                className={classes.list}
                role="presentation"
            >
                <DataDisplayDrawerNavigation/>

                <Divider/>

                <GenericObjectWrapper
                    resourceUID={props.fullScreenDisplay.resourceUID}
                    resourceType={props.fullScreenDisplay.resourceType}
                />
            </div>
        );
    }


}

const mapStateToProps = (state) => ({
    fullScreenDisplay: fullScreenDisplaySelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionSetGenericFullScreenDisplay: adminActionSetGenericFullScreenDisplay,
        adminActionSetFullScreenHistoryChain: adminActionSetFullScreenHistoryChain
    }, dispatch);
};

//NOTE: Removed this as the third argument: mergeProps
export default connect(mapStateToProps, mapActionsToProps)(DataDisplayDrawer);