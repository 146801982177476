/*
    Dev
 */

import React from "react";
import JSONSchemaRenderString from "./JSONSchemaRenderString";
import JSONSchemaRenderNumber from "./JSONSchemaRenderNumber";
import {DEFAULT_CUSTOM_RENDER_COMPONENTS} from "./JSONSchemaDefaultCustomRenderComponents";
import GenericObjectAsTable from "../../../clientShared/components/display/GenericObjectAsTable";
import Alert from "@material-ui/lab/Alert";
import * as Sentry from "@sentry/browser";
import {DEV_BUILD} from "../../../constants/apiConstants";

export const RENDER_OBJECT_DATA_MODE_TABLE = 'table';
export const RENDER_OBJECT_DATA_MODE_OBJECT = 'object';

export function renderObjectData(renderMode, key, sourceObject, schema, customRenders = {}) {
    // console.log(DEPOSIT_JSON_SCHEMA);ren
    // console.log(DEPOSIT_JSON_SCHEMA.definitions.DepositSchema);
    // console.log('Render data', customRenders);

    const schemaProperties = schema.properties;

    const propDetails = schemaProperties[key];
    if (propDetails === undefined) {
        if (DEV_BUILD) {
            console.error("---- source object ----")
            console.error(sourceObject);
            throw new Error(`renderObjectData - schema error, key: ${key}`)
        }

        Sentry.withScope(scope => {
            scope.setExtra("key", key);
            scope.setExtra("sourceObject", sourceObject);
            scope.setExtra("schema", schema);
            Sentry.captureMessage('Failed to render object data')
        });

        console.log('ERROR, Not found in schema', key);
        return <Alert severity="error">ERROR - Schema</Alert>;
    }

    const objectValue = sourceObject[key];
    if (objectValue === undefined) {
        console.log('ERROR, Not found in object:', key);
        return <React.Fragment>ERROR - obj</React.Fragment>;
    }

    // Combine the default custom render components dict with the passed in dict
    // NOTE: Order is important here - we want the passed in ones to be added to the default - this allows for the
    //       passed in to overwrite the defaults
    const customRenderComponents = Object.assign({}, DEFAULT_CUSTOM_RENDER_COMPONENTS, customRenders);

    const customRenderComponent = customRenderComponents[key];
    if (customRenderComponent) {
        // You can flag something not to be used in the body or the table, true is the default
        const useOnTable = customRenderComponent.useOnTable === undefined ? true : customRenderComponent.useOnTable;
        const useOnBody = customRenderComponent.useOnBody === undefined ? true : customRenderComponent.useOnBody;

        if ((renderMode === RENDER_OBJECT_DATA_MODE_OBJECT && useOnBody === true) ||
            (renderMode === RENDER_OBJECT_DATA_MODE_TABLE && useOnTable === true)) {
            const RenderComp = customRenderComponent.renderComp;
            // Should we pass the whole object?
            const passDataObject = customRenderComponent.passDataObject === undefined ? false : customRenderComponent.passDataObject;

            const renderCompProps = {[customRenderComponent.dataPropName]: passDataObject === true ? sourceObject : objectValue};
            return <RenderComp {...renderCompProps} {...customRenderComponent.extraProps} />;
        }
    }

    switch (propDetails.type) {
        case 'string':
            return <JSONSchemaRenderString propDetails={propDetails} objectValue={objectValue}/>;
        case 'number':
            return <JSONSchemaRenderNumber propDetails={propDetails} objectValue={objectValue}/>;
        case 'boolean':
            // console.log('Bool', propDetails);
            return <React.Fragment>{objectValue.toString()}</React.Fragment>;
        case 'array':
            // console.log('Array', propDetails);
            return <React.Fragment>{objectValue.toString()}</React.Fragment>;
        case 'object':
            return <GenericObjectAsTable sourceObject={objectValue}/>;
        default:
            // console.log('???', propDetails);
            return <React.Fragment>{objectValue.toString()}</React.Fragment>;
    }

}
