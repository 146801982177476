import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import * as Yup from "yup";
import * as Sentry from "@sentry/browser";
import toast from "react-hot-toast";
import {useFormik} from "formik";

import {YUP_SCHEMA_PASSWORD} from "../../utilities/yupCommonValidationSchemas";
import {StandardTextField} from "../utilities/forms/CommonFormFields";
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import SpinnerButton from "../utilities/buttons/SpinnerButton";


const ChangeAccountPasswordForm = ({accountUID, doneCallback}) => {
    const [showSpinner, setShowSpinner] = useState(false);

    const formSchema = Yup.object().shape({
        ...YUP_SCHEMA_PASSWORD,
    });

    const formik = useFormik({
        initialValues: {
            password: "",
        },
        onSubmit: handleSubmit,
        isInitialValid: false,
        validationSchema: formSchema
    });

    function handleSubmit(values) {
        setShowSpinner(true);

        let updatedValues = {...values};

        const callURL = getAdminPath(`jeff/account/${accountUID}/password/change`);
        axiosInstance.post(callURL, updatedValues)
            .then(function (response) {
                setShowSpinner(false);
                toast.success('Done');
                doneCallback();
            })
            .catch(function (error) {
                setShowSpinner(false);

                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            })
    }


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="stretch"
                spacing={1}
            >


                <Grid item xs={12}>
                    {StandardTextField(formik, 'password', 'New Password')}
                </Grid>

                <Grid item xs={12}>
                    &nbsp;
                </Grid>

                <Grid item zeroMinWidth xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="nowrap"
                    >
                        <Grid item zeroMinWidth>
                            <SpinnerButton
                                // Non-button props
                                showSpinner={showSpinner}
                                // Button props
                                variant="contained"
                                color="primary"
                                disabled={!formik.isValid || showSpinner}
                                type={'submit'}
                            >
                                Update Password
                            </SpinnerButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};


export default ChangeAccountPasswordForm;