/*
    Dev Page
 */

import React from "react";
import {connect} from 'react-redux';
import AdminRootPage from "../pages/AdminRootPage";

import {bindActionCreators} from "redux";


function DevPage(props) {

    return (
        <AdminRootPage>


        </AdminRootPage>
    );


}

const mapStateToProps = (state) => ({});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(DevPage);
