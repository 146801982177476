/*
    Common Utilities
 */

import {API_AUTH_ACCESS_TOKEN} from "../constants/apiConstants";
import moment from "moment";
import {DATA_FORMAT_TIME_STAMP_URL_FORMAT, DATA_FORMAT_TIME_STAMPS_MOMENT} from "../constants/dataConstants";

function hasOwnProperty(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

// Used to check if an object is empty since you can't go x === {}
export function isEmpty(obj) {
    for (const n in obj) if (hasOwnProperty(obj, n) && obj[n]) return false; // eslint-disable-line
    return true;
}

export function isObject(val) {
    if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
}

export function hasAccessTokenInLocalStorage() {
    return localStorage.getItem(API_AUTH_ACCESS_TOKEN) !== null;
}

export function convertServerTimestampToLocalTimestamp(serverTimestamp) {
    // Sample server TS: '2020-02-26 01:05:49:045078'

    // Server timestamps are always UTC and always have 6 milliseconds
    // Moment only handles 3 ms, so we need to slice them off
    //
    // NOTE: We pass the format in here since browser consistency is poor
    //       See https://momentjs.com/docs/#/parsing/string
    //
    return moment.utc(serverTimestamp.slice(0, -3), DATA_FORMAT_TIME_STAMPS_MOMENT).local();
}

export function renderServerTimestamp(serverTimestamp){
    return convertServerTimestampToLocalTimestamp(serverTimestamp).format('DD MMM YYYY');
}

export function renderServerTimestampWithTime(serverTimestamp){
    return convertServerTimestampToLocalTimestamp(serverTimestamp).format('DD MMM YYYY HH:MM:SS');
}

export function jsDateTimeFromString(date_time_string, return_as_string = false) {
    // Our time stamp strings are always in this format
    // 2019-07-16 19:11:54:472315
    const bits = date_time_string.split(/\D/);

    // What's that!? Yes the Javascript month starts at 0 - You know, they way all calendars do
    const js_month_fix = parseInt(bits[1]) - 1;

    // Moment only recognizes 3 MS digits, any more and it will consider the time invalid
    const trimmed_ms = parseInt(bits[6].slice(0, -3));

    // const the_date = new Date(bits[0], js_month_fix, bits[2], bits[3], bits[4], bits[5], trimmed_ms);
    const the_date = new Date(Date.UTC(bits[0], js_month_fix, bits[2], bits[3], bits[4], bits[5], trimmed_ms));

    if (return_as_string) {
        return the_date.toISOString();
    }
    return the_date;
}

export function getDateDeltaDuration(start_date, end_date) {
    let startDateMoment = moment(start_date).utc();
    let endDateMoment = moment(end_date).utc();

    return moment.duration(endDateMoment.diff(startDateMoment));
}

export function getMomentUTC() {
    var moment = require('moment');
    return moment.utc();
}

export function getServerDateTimeAsMoment(serverTimestamp) {
    // Server time stamps are stings in UTC format: 2019-08-11 01:41:30:908000
    return moment.utc(jsDateTimeFromString(serverTimestamp));
}

export function formatServerDate(serverTimestamp) {
    // Server time stamps are stings in UTC format: 2019-08-11 01:41:30:908000
    return getServerDateTimeAsMoment(serverTimestamp).format('DD MMM YYYY');
}

export function getDateDeltaString(start_date,
                                   end_date = null,
                                   useTimeIfDaysAreZero = true,
                                   show_seconds = false) {
    const _end_date = end_date === null ? getMomentUTC() : end_date;
    const diffDuration = getDateDeltaDuration(start_date, _end_date);

    let deltaMessage = '';
    let andAdded = false;

    if (diffDuration.years() !== 0) {
        deltaMessage += diffDuration.years();
        deltaMessage += diffDuration.years() > 1 ? ' years' : ' year';
    }

    if (diffDuration.days() === 0 && diffDuration.months() > 0) {
        deltaMessage += ' and';
        andAdded = true;
    }

    if (diffDuration.months() !== 0) {
        deltaMessage += ' ' + diffDuration.months();
        deltaMessage += diffDuration.months() > 1 ? ' months' : ' month';
    }

    if (diffDuration.days() !== 0) {
        if (!andAdded && deltaMessage.length > 0) {
            deltaMessage += ' and';
        }

        deltaMessage += ' ' + diffDuration.days();
        deltaMessage += diffDuration.days() > 1 ? ' days' : ' day';
    }

    if (deltaMessage === '') {
        if (useTimeIfDaysAreZero) {
            if (diffDuration.hours() !== 0) {
                deltaMessage = diffDuration.hours();
                deltaMessage += diffDuration.hours() > 1 ? ' hours, ' : ' hour, ';
            }

            if (show_seconds === false && diffDuration.hours() !== 0) {
                deltaMessage += ' and ';
            }

            deltaMessage += diffDuration.minutes();
            deltaMessage += diffDuration.minutes() !== 1 ? ' minutes' : ' minute';

            if (show_seconds) {
                deltaMessage += ' and ';

                deltaMessage += diffDuration.seconds();
                deltaMessage += diffDuration.seconds() !== 1 ? ' seconds' : ' second';
            }
        } else {
            deltaMessage = '0 Days';
        }
    }

    return deltaMessage;
}

export function convertJSDateToPythonDate(incomingDate) {
    // Convert from JS date into a server friendly format
    const momentNow = moment(incomingDate);
    return momentNow.utc(false).format(DATA_FORMAT_TIME_STAMPS_MOMENT);
}

export function convertJSDateToURLDate(incomingDate) {
    // Convert from JS date into a server friendly format
    const momentNow = moment(incomingDate);
    return momentNow.utc(false).format(DATA_FORMAT_TIME_STAMP_URL_FORMAT);
}

export function dollarsToCents(dollar_amount) {
    // Using toFixed at both stages prevents JS from converting 0.99 into 0.99999998 into 0.98 :/
    return parseInt(parseFloat(parseFloat(dollar_amount).toFixed(2) * 100.00).toFixed(2));
}

export function centsToDollars(amount_in_cents) {
    return parseFloat(parseInt(amount_in_cents) / 100).toFixed(2);
}

export function dollarFormattedString(amount_in_cents, include_dollar_sign = true) {
    const dollar_amount = centsToDollars(amount_in_cents);
    if (isNaN(dollar_amount)) {
        return '';
    }
    if (include_dollar_sign) {
        return '$' + dollar_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return dollar_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export function countDecimals(number_value) {
    const sections = number_value.toString().split(".");
    if (sections === undefined || sections.length <= 1) {
        return 0;
    }
    return sections[1].length || 0;
}


export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getFileExtension(fileName) {
    const fileExtensionRE = /(?:\.([^.]+))?$/;
    return fileExtensionRE.exec(fileName)[1];
}

export function capitalize(sourceString) {
    if (typeof sourceString !== 'string') return '';
    return sourceString.charAt(0).toUpperCase() + sourceString.slice(1)
}

// TODO: Magic numbers
export function trimStringIfNeeded(sourceString, maxSize = 25) {
    if (sourceString.length < maxSize) {
        return sourceString;
    }
    return sourceString.substring(0, (maxSize - 3)) + '...';
}

export function uuidToHex(incomingUUID){
    return incomingUUID.toString().split('-').join('');
}