/*

 */

import React from 'react';
import {formatServerDate} from "../../../utilities/commonUtils";

export default function DepositDatesRender({deposit, ...props}) {
    return (
        <React.Fragment>
            {formatServerDate(deposit.start_date)}
            <hr/>
            {formatServerDate(deposit.end_date)}
        </React.Fragment>
    );

}