import React from "react";

import Grid from "@material-ui/core/Grid";
import {FUNDS_LOCATION_TYPE_BANK_ACCOUNT} from "../../autoGenerated/constants/services/UserAccountsServiceConstants";
import {NULL_UUID_STRING} from "../../constants/dataConstants";
import {BankAccountContainer} from "../../autoGenerated/components/containers/resourceContainers";
import {TinyBankAccountCard} from "../allTinyCards";


export function FundsClearedLocationDisplay({sourceObject, ...props}) {

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >

            {
                sourceObject.clear_into_funds_location === FUNDS_LOCATION_TYPE_BANK_ACCOUNT && sourceObject.clear_into_funds_location_uid !== NULL_UUID_STRING &&
                <Grid item>
                    <BankAccountContainer bankAccountUID={sourceObject.clear_into_funds_location_uid}>
                        <TinyBankAccountCard/>
                    </BankAccountContainer>
                </Grid>
            }

            {
                sourceObject.clear_into_funds_location !== FUNDS_LOCATION_TYPE_BANK_ACCOUNT || sourceObject.clear_into_funds_location_uid === NULL_UUID_STRING &&
                <Grid item>
                    <i>No location</i>
                </Grid>
            }

        </Grid>
    );
}

