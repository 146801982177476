/*

 */
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from "@material-ui/styles";
import {
    Router,
    Switch,
    Route
} from 'react-router-dom';
import {history} from './history/history'
import {
    APP_ROUTE_HOME,
    APP_ROUTE_LOGIN
} from "./constants/appRoutes";
import Login from './components/auth/pages/LoginPage';
import {APP_THEME} from "./AppTheme";


export default function NotLoggedInApp(props) {

    return (
        <ThemeProvider theme={APP_THEME}>
            <CssBaseline/>
            <Router history={history}>
                <Switch>
                    <Route exact path={APP_ROUTE_HOME} component={Login}/>
                    <Route exact path={APP_ROUTE_LOGIN} component={Login}/>

                    {/* TODO: We need a handler for bad routes for users not logged in */}
                    <Route component={Login}/>
                </Switch>
            </Router>
        </ThemeProvider>
    );

}

