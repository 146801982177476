/*
    Display the state for a user account
 */

import React from 'react';
import {faAlien, faCheck, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {
    USER_ACCOUNT_STATE_ACTIVE, USER_ACCOUNT_STATE_AWAITING_FINALIZATION,
    USER_ACCOUNT_STATE_BANNED
} from "../../../../../autoGenerated/constants/services/UserAccountsServiceConstants";
import {BAD_COLOUR, GOOD_COLOUR, WARNING_COLOUR} from "../../../../constants/style/colours";

const USER_ACCOUNT_STATE_DISPLAY_MAPPING = {
    [USER_ACCOUNT_STATE_ACTIVE]: {icon: faCheck, displayName: 'Active', colour: GOOD_COLOUR},
    [USER_ACCOUNT_STATE_BANNED]: {icon: faTimes, displayName: 'Banned', colour: BAD_COLOUR},
    [USER_ACCOUNT_STATE_AWAITING_FINALIZATION]: {icon: faAlien, displayName: 'Awaiting Finalization', colour: WARNING_COLOUR}
};

export default function UserAccountStateDisplay({userAccount, ...props}) {

    const stateInfo = USER_ACCOUNT_STATE_DISPLAY_MAPPING[userAccount.state];

    return <React.Fragment>{stateInfo.displayName}</React.Fragment>;

}
