import Typography from "@material-ui/core/Typography";
import React from "react";


const SEZZLE_ORDER_STATE_PENDING = 'pending';
const SEZZLE_ORDER_STATE_CANCELLED = 'cancelled';
const SEZZLE_ORDER_STATE_COMPLETE = 'complete';
const SEZZLE_ORDER_STATE_REFUNDED = 'refunded';
const SEZZLE_ORDER_STATE_CANCELLED_DUE_TO_INACTIVITY = 'cancelled_due_to_inactivity';


const SezzleOrderState = ({state}) => {
    let fontColour = '#FFFFFF';

    switch (state) {
        case SEZZLE_ORDER_STATE_CANCELLED:
            fontColour = '#6d6969';
            break;
        case SEZZLE_ORDER_STATE_COMPLETE:
            fontColour = '#32911a';
            break;
        case SEZZLE_ORDER_STATE_REFUNDED:
            fontColour = '#be72b9';
            break;
        case SEZZLE_ORDER_STATE_CANCELLED_DUE_TO_INACTIVITY:
            fontColour = '#989393';
            break;
        default:
            fontColour = '#FFFFFF';
    }

    const style = {color: fontColour};

    return <Typography variant="body2" style={style}>{state}</Typography>;
}

export default SezzleOrderState;