/*
    Generic Wrapper to load a resource and render it
 */

import React from "react";
import {RESOURCE_TYPE_INFO} from "../../../autoGenerated/constants/resourceTypeInfo";
import {getResourceIcon} from "../../../autoGenerated/constants/serviceAndResourceIcons";
import {getResourceContainer} from "../../../autoGenerated/components/containers/resourceContainers";
import {getResourceCustomRenderComponents} from "../../constants/renderers/CustomRenderComponentsMapping";
import {getJSONSchema} from "../../../autoGenerated/jsonSchemas/JSONSchemasMapping";
import VisualizeContainerLoadingIndicator from "../utility/progress/VisualizeContainerLoadingIndicator";
import {getPrimaryRenderComponent} from "../../../constants/new/components/PrimaryRenderComponents";
import {getBodyRenderDefinition} from "../../../autoGenerated/constants/BodyRenderDefinitionMapping";


function GenericObjectWrapper({resourceUID, resourceType, ...props}) {
    // Grab the needed info for this resource
    const resourceTypeInfo = RESOURCE_TYPE_INFO[resourceType];
    const resourceIcon = getResourceIcon(resourceType);
    const ContainerComponent = getResourceContainer(resourceType);
    const customRenderComponents = getResourceCustomRenderComponents(resourceType);
    const jsonSchema = getJSONSchema(resourceType);
    const RenderComponent = getPrimaryRenderComponent(resourceType);
    const bodyRenderDefinition = getBodyRenderDefinition(resourceType);

    // Setup our props for the container
    const containerProps = {[resourceTypeInfo.clientUIDName]: resourceUID};

    // console.log('CP', containerProps);
    // console.log('RI', resourceIcon);
    // console.log('CC', ContainerComponent);
    // console.log('CRC', customRenderComponents);
    // console.log('JS', jsonSchema);

    return (
        <ContainerComponent {...containerProps}>
            <ObjectRender/>
        </ContainerComponent>
    );

    function ObjectRender(renderProps) {
        // Grab the object and display something to show it's loading
        const loadedObject = renderProps[resourceTypeInfo.clientObjectName];
        if (loadedObject === undefined) {
            return (
                <VisualizeContainerLoadingIndicator
                    loading={renderProps.loading}
                    error={renderProps.error}
                    loadingResource={loadedObject}
                    displayName={resourceTypeInfo.displayName}
                />
            );
        }

        return (
            <RenderComponent
                {...renderProps}
                resourceUID={resourceUID}
                resourceType={resourceType}
                resourceTypeInfo={resourceTypeInfo}
                resourceIcon={resourceIcon}
                customRenderComponents={customRenderComponents}
                jsonSchema={jsonSchema}
                bodyRenderDefinition={bodyRenderDefinition}
                {...props}
            />
        );
    }

}

export default React.memo(GenericObjectWrapper);