/*
    Common validation schemas
 */

import * as Yup from "yup";

import {
    MIN_USERNAME_LENGTH,
    MAX_USERNAME_LENGTH,
    MIN_PASSWORD_LENGTH,
    MAX_PASSWORD_LENGTH,
    MIN_DEPOSIT_TITLE_LENGTH,
    MAX_DEPOSIT_TITLE_LENGTH,
    MIN_DEPOSIT_AMOUNT_IN_DOLLARS,
    MAX_DEPOSIT_AMOUNT_IN_DOLLARS,
    MIN_BANK_NAME_LENGTH,
    MAX_BANK_NAME_LENGTH,
    MIN_BANK_BRANCH_NAME_LENGTH,
    MAX_BANK_BRANCH_NAME_LENGTH,
    MIN_BANK_ACCOUNT_NUMBER_LENGTH,
    MAX_BANK_ACCOUNT_NUMBER_LENGTH,
    MIN_ACH_LENGTH,
    MAX_ACH_LENGTH,
    MIN_RECIPIENT_NAME_LENGTH,
    MAX_RECIPIENT_NAME_LENGTH,
    MIN_PHONE_NUMBER_LENGTH,
    MAX_PHONE_NUMBER_LENGTH,
    MIN_BIC_SWIFT_CODE_LENGTH,
    MAX_BIC_SWIFT_CODE_LENGTH,
    MIN_RECIPIENT_ADDRESS_STREET_LENGTH,
    MAX_RECIPIENT_ADDRESS_STREET_LENGTH,
    MIN_RECIPIENT_ADDRESS_CITY_LENGTH,
    MAX_RECIPIENT_ADDRESS_CITY_LENGTH,
    MIN_RECIPIENT_ADDRESS_STATE_LENGTH,
    MAX_RECIPIENT_ADDRESS_STATE_LENGTH,
    MIN_RECIPIENT_POSTAL_CODE_LENGTH,
    MAX_RECIPIENT_POSTAL_CODE_LENGTH,
    MIN_COUNTRY_LENGTH,
    MAX_COUNTRY_LENGTH, MIN_FIRST_NAME_LENGTH, MAX_FIRST_NAME_LENGTH, MIN_LAST_NAME_LENGTH, MAX_LAST_NAME_LENGTH
} from "../constants/dataConstants";
import {countDecimals} from "./commonUtils";
import {
    BANK_ACCOUNT_TYPES
} from "../constants/dataConstants";
import {SUPPORTED_US_STATES_DICT} from "../constants/usStatesConstants";
import {
    ALL_PROPERTY_TYPES, PROPERTY_APT_UNIT_NUMBER_MAX,
    PROPERTY_APT_UNIT_NUMBER_MIN,
    PROPERTY_TITLE_LENGTH_MAX,
    PROPERTY_TITLE_LENGTH_MIN
} from "../constants/propertyConstants";

// Regular expression for checking phone numbers
// export const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
// eslint-disable-next-line
export const rePhoneNumber = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/;

// CUSTOM VALIDATION METHODS
// ---------------------------------------------------------------------------------------------------------------------
Yup.addMethod(Yup.number, 'maxDecimalPlaces', function (decimalPlacesCount, message) {
    return this.test('maxDecimalPlaces', message, function (value) {
        const {path, createError} = this;

        let valid = false;
        if (value !== undefined) {
            valid = countDecimals(value) <= decimalPlacesCount;
        }

        return valid || createError({path, message});
    });
});

Yup.addMethod(Yup.string, 'match', function (refKey, message) {
    return this.test('match', message, function (value) {
        return value === this.resolve(refKey)
    });
});

Yup.addMethod(Yup.string, "phone", function () {
    return this.test('phone', 'Phone number is not valid', function (value) {
        if (value === undefined) {
            return false;
        }
        if (value.toString().startsWith('+0') === true) {
            return false;
        }
        return rePhoneNumber.test(value)
    });
});

// FIELDS
// ---------------------------------------------------------------------------------------------------------------------
export const YUP_FIELD_PASSWORD = Yup.string()
    .required("A password is required")
    .typeError('A password is required')
    .min(MIN_PASSWORD_LENGTH, 'Min password length is: ' + MIN_PASSWORD_LENGTH)
    .max(MAX_PASSWORD_LENGTH, 'Max password length is: ' + MAX_PASSWORD_LENGTH);

export const YUP_FIELD_DEPOSIT_TITLE = Yup.string()
    .required("A deposit title is required")
    .typeError('A deposit title is required')
    .min(MIN_DEPOSIT_TITLE_LENGTH, 'Min deposit title length is: ' + MIN_DEPOSIT_TITLE_LENGTH)
    .max(MAX_DEPOSIT_TITLE_LENGTH, 'Max deposit title length is: ' + MAX_DEPOSIT_TITLE_LENGTH);

export const YUP_FIELD_DEPOSIT_AMOUNT = Yup.number()
    .required("A deposit amount is required")
    .typeError('A deposit amount is required')
    .positive('A positive amount is required')
    .min(MIN_DEPOSIT_AMOUNT_IN_DOLLARS, 'Min amount is: $' + MIN_DEPOSIT_AMOUNT_IN_DOLLARS)
    .max(MAX_DEPOSIT_AMOUNT_IN_DOLLARS, 'Max amount is: $' + MAX_DEPOSIT_AMOUNT_IN_DOLLARS)
    .maxDecimalPlaces(2, 'Only two decimal places allowed');

export const YUP_FIELD_US_STATE = Yup.string()
    .required('A U.S. State is required')
    .typeError('A U.S. State is required')
    .oneOf(Object.keys(SUPPORTED_US_STATES_DICT), 'Please select a valid U.S. State');

export const YUP_FIELD_BANK_NAME = Yup.string()
    .required("A bank name is required")
    .typeError('A bank name is required')
    .min(MIN_BANK_NAME_LENGTH, 'Min bank name length is: ' + MIN_BANK_NAME_LENGTH)
    .max(MAX_BANK_NAME_LENGTH, 'Max bank name length is: ' + MAX_BANK_NAME_LENGTH);

export const YUP_FIELD_BANK_BRANCH_NAME = Yup.string()
    .required("A bank branch name is required")
    .typeError('A bank branch name is required')
    .min(MIN_BANK_BRANCH_NAME_LENGTH, 'Min bank branch name length is: ' + MIN_BANK_BRANCH_NAME_LENGTH)
    .max(MAX_BANK_BRANCH_NAME_LENGTH, 'Max bank branch name length is: ' + MAX_BANK_BRANCH_NAME_LENGTH);

export const YUP_FIELD_BANK_ACCOUNT_NUMBER = Yup.string()
    .required("A bank account number is required")
    .typeError('A bank account number is required')
    .min(MIN_BANK_ACCOUNT_NUMBER_LENGTH, 'Min bank account number length is: ' + MIN_BANK_ACCOUNT_NUMBER_LENGTH)
    .max(MAX_BANK_ACCOUNT_NUMBER_LENGTH, 'Max bank account number length is: ' + MAX_BANK_ACCOUNT_NUMBER_LENGTH);

export const YUP_FIELD_ACH = Yup.string()
    .required("ACH is required")
    .typeError('ACH is required')
    .min(MIN_ACH_LENGTH, 'Min ACH length is: ' + MIN_ACH_LENGTH)
    .max(MAX_ACH_LENGTH, 'Max ACH length is: ' + MAX_ACH_LENGTH);

export const YUP_FIELD_BIC_SWIFT_CODE = Yup.string()
    .required("Field is required")
    .typeError('Field is required')
    .min(MIN_BIC_SWIFT_CODE_LENGTH, 'Min length is: ' + MIN_BIC_SWIFT_CODE_LENGTH)
    .max(MAX_BIC_SWIFT_CODE_LENGTH, 'Max length is: ' + MAX_BIC_SWIFT_CODE_LENGTH);

export const YUP_FIELD_RECIPIENT_NAME = Yup.string()
    .required("A recipient name is required")
    .typeError('A recipient name is required')
    .min(MIN_RECIPIENT_NAME_LENGTH, 'Min recipient name length is: ' + MIN_RECIPIENT_NAME_LENGTH)
    .max(MAX_RECIPIENT_NAME_LENGTH, 'Max recipient name length is: ' + MAX_RECIPIENT_NAME_LENGTH);

export const YUP_FIELD_ADDRESS_STREET = Yup.string()
    .required("A street address is required")
    .typeError('A street address is required')
    .min(MIN_RECIPIENT_ADDRESS_STREET_LENGTH, 'Min recipient name length is: ' + MIN_RECIPIENT_ADDRESS_STREET_LENGTH)
    .max(MAX_RECIPIENT_ADDRESS_STREET_LENGTH, 'Max recipient name length is: ' + MAX_RECIPIENT_ADDRESS_STREET_LENGTH);

export const YUP_FIELD_ADDRESS_CITY = Yup.string()
    .required("A city is required")
    .typeError('A city is required')
    .min(MIN_RECIPIENT_ADDRESS_CITY_LENGTH, 'Min city length is: ' + MIN_RECIPIENT_ADDRESS_CITY_LENGTH)
    .max(MAX_RECIPIENT_ADDRESS_CITY_LENGTH, 'Max city length is: ' + MAX_RECIPIENT_ADDRESS_CITY_LENGTH);

export const YUP_FIELD_ADDRESS_STATE = Yup.string()
    .required("A State is required")
    .typeError('A State is required')
    .min(MIN_RECIPIENT_ADDRESS_STATE_LENGTH, 'Min State name length is: ' + MIN_RECIPIENT_ADDRESS_STATE_LENGTH)
    .max(MAX_RECIPIENT_ADDRESS_STATE_LENGTH, 'Max State name length is: ' + MAX_RECIPIENT_ADDRESS_STATE_LENGTH);

export const YUP_FIELD_POSTAL_CODE = Yup.string()
    .required("A recipient name is required")
    .typeError('A recipient name is required')
    .min(MIN_RECIPIENT_POSTAL_CODE_LENGTH, 'Min recipient name length is: ' + MIN_RECIPIENT_POSTAL_CODE_LENGTH)
    .max(MAX_RECIPIENT_POSTAL_CODE_LENGTH, 'Max recipient name length is: ' + MAX_RECIPIENT_POSTAL_CODE_LENGTH);

export const YUP_FIELD_COUNTRY = Yup.string()
    .required("A country is required")
    .typeError('A country is required')
    .min(MIN_COUNTRY_LENGTH, 'Min country length is: ' + MIN_COUNTRY_LENGTH)
    .max(MAX_COUNTRY_LENGTH, 'Max country length is: ' + MAX_COUNTRY_LENGTH);

export const YUP_FIELD_PHONE_NUMBER = Yup.string()
    .required("A phone number is required")
    .typeError('A phone number is required')
    .min(MIN_PHONE_NUMBER_LENGTH, 'Min phone number length is: ' + MIN_PHONE_NUMBER_LENGTH)
    .max(MAX_PHONE_NUMBER_LENGTH, 'Max phone number length is: ' + MAX_PHONE_NUMBER_LENGTH);

export const YUP_FIELD_BANK_ACCOUNT_TYPE = Yup.string()
    .required('A bank account type is required')
    .typeError('A bank account type required')
    .oneOf(BANK_ACCOUNT_TYPES, 'Please select a bank account type');

// SCHEMAS
// ---------------------------------------------------------------------------------------------------------------------
export const YUP_SCHEMA_PHONE_NUMBER = {
    phone_number: Yup.string()
        .required("A phone number is required")
        .typeError('A numerical phone number is required')
        .phone('Please provide a valid phone number')
};

export const YUP_SCHEMA_USERNAME = {
    username: Yup.string()
        .required("A username is required")
        .typeError('A username is required')
        .min(MIN_USERNAME_LENGTH, 'Min username length is: ' + MIN_USERNAME_LENGTH)
        .max(MAX_USERNAME_LENGTH, 'Max username length is: ' + MAX_USERNAME_LENGTH)
};

export const YUP_SCHEMA_FIRST_NAME = {
    first_name: Yup.string()
        .required("A first name is required")
        .typeError('A first name is required')
        .min(MIN_FIRST_NAME_LENGTH, 'Min first name length is: ' + MIN_FIRST_NAME_LENGTH)
        .max(MAX_FIRST_NAME_LENGTH, 'Max first name length is: ' + MAX_FIRST_NAME_LENGTH)
};

export const YUP_SCHEMA_LAST_NAME = {
    last_name: Yup.string()
        .required("A last name is required")
        .typeError('A last name is required')
        .min(MIN_LAST_NAME_LENGTH, 'Min last name length is: ' + MIN_LAST_NAME_LENGTH)
        .max(MAX_LAST_NAME_LENGTH, 'Max last name length is: ' + MAX_LAST_NAME_LENGTH)
};

export const YUP_SCHEMA_EMAIL_ADDRESS = {
    email_address: Yup.string()
        .required("An email address is required")
        .typeError('An email address is required')
        .email('Please provide a valid email address')
};

export const YUP_SCHEMA_PASSWORD = {
    password: YUP_FIELD_PASSWORD
};

export const YUP_SCHEMA_DEPOSIT_TITLE = {
    title: YUP_FIELD_DEPOSIT_TITLE
};

export const YUP_SCHEMA_DEPOSIT_AMOUNT = {
    dollar_amount: YUP_FIELD_DEPOSIT_AMOUNT
};

export const YUP_SCHEMA_START_AND_END_DATE = {
    start_date: Yup.date()
        .required("A start date is required")
        .typeError('A start date is required'),
    end_date: Yup.date()
        .required("An end date is required")
        .typeError('An end date is required')
        .min(Yup.ref('start_date'), 'End Date must come after the Start Date')
};

export const YUP_SCHEMA_NEW_PASSWORD = {
    new_password: YUP_FIELD_PASSWORD,
    repeat_password: Yup.string().match(Yup.ref('new_password'), 'Passwords must match')
};

export const YUP_SCHEMA_US_STATE = {
    us_state: YUP_FIELD_US_STATE
};

export const YUP_SCHEMA_US_DOM_BANK_ACCOUNT = {
    bank_name: YUP_FIELD_BANK_NAME,
    account_number: YUP_FIELD_BANK_ACCOUNT_NUMBER,
    account_type: YUP_FIELD_BANK_ACCOUNT_TYPE,
    ach: YUP_FIELD_ACH,
    first_name_on_account: YUP_FIELD_RECIPIENT_NAME,
    last_name_on_account: YUP_FIELD_RECIPIENT_NAME,
    contact_phone_number: YUP_FIELD_PHONE_NUMBER
};

export const YUP_SCHEMA_INTERNATIONAL_BANK_ACCOUNT_BANK_DETAILS = {
    recipient_country: YUP_FIELD_COUNTRY,
    bank_name: YUP_FIELD_BANK_NAME,
    //bank_branch_name: YUP_FIELD_BANK_BRANCH_NAME,
    bic_swift_code: YUP_FIELD_BIC_SWIFT_CODE,
    account_number: YUP_FIELD_BANK_ACCOUNT_NUMBER,
    account_type: YUP_FIELD_BANK_ACCOUNT_TYPE
};

export const YUP_SCHEMA_INTERNATIONAL_BANK_ACCOUNT_RECIPIENT_DETAILS = {
    first_name_on_account: YUP_FIELD_RECIPIENT_NAME,
    last_name_on_account: YUP_FIELD_RECIPIENT_NAME,
    recipient_address_street: YUP_FIELD_ADDRESS_STREET,
    recipient_address_city: YUP_FIELD_ADDRESS_CITY,
    recipient_address_state: YUP_FIELD_ADDRESS_STATE,
    recipient_postal_code: YUP_FIELD_POSTAL_CODE,
    contact_phone_number: YUP_FIELD_PHONE_NUMBER
};

export const YUP_SCHEMA_PROPERTY_NAME = {
    property_name: Yup.string()
        .required("A property name is required")
        .typeError('A property name is required')
        .min(PROPERTY_TITLE_LENGTH_MIN, 'Min property name length is: ' + PROPERTY_TITLE_LENGTH_MIN)
        .max(PROPERTY_TITLE_LENGTH_MAX, 'Max property name length is: ' + PROPERTY_TITLE_LENGTH_MAX)
};

export const YUP_SCHEMA_PROPERTY_TYPE = {
    property_type: Yup.string()
        .required('A property type is required')
        .typeError('A property type required')
        .oneOf(ALL_PROPERTY_TYPES, 'Please select a property type')
};

export const YUP_SCHEMA_PROPERTY_TYPE_LIST_ITEM = {
    value: Yup.string().oneOf(ALL_PROPERTY_TYPES, 'Please select a property type'),
    label: Yup.string().required('A property type is required')
};

export const YUP_SCHEMA_PROPERTY_UNIT_APT_NUMBER = {
    apt_unit_number: Yup.string()
        .required('A unit / apt is required')
        .typeError('A unit / apt required')
        .min(PROPERTY_APT_UNIT_NUMBER_MIN, 'Min unit / apt length is: ' + PROPERTY_APT_UNIT_NUMBER_MIN)
        .max(PROPERTY_APT_UNIT_NUMBER_MAX, 'Max unit / apt length is: ' + PROPERTY_APT_UNIT_NUMBER_MAX)
};

export const YUP_SCHEMA_PROPERTY_ADDRESS = {
    location_id: Yup.string().required('Please provide an address')
};

export const YUP_SCHEMA_CREATE_OR_EDIT_DEPOSIT = {
    ...YUP_SCHEMA_DEPOSIT_TITLE,
    ...YUP_SCHEMA_DEPOSIT_AMOUNT,
    ...YUP_SCHEMA_US_STATE,
    ...YUP_SCHEMA_START_AND_END_DATE
};