// Consts
export const IGNORE_LOADING_CALL = 'LOADING:IGNORE_LOADING_CALL';
export const SET_DEPOSIT_LOADING_STATE = 'LOADING:SET_DEPOSIT_LOADING_STATE';
export const SET_DEPOSIT_META_DATA_LOADING_STATE = 'LOADING:SET_DEPOSIT_META_DATA_LOADING_STATE';
export const SET_DEPOSIT_INVITE_LOADING_STATE = 'LOADING:SET_DEPOSIT_INVITE_LOADING_STATE';
export const SET_BASIC_USER_INFO_LOADING_STATE = 'LOADING:SET_BASIC_USER_INFO_LOADING_STATE';
export const SET_ATTACHED_FILES_LOADING_STATE = 'LOADING:SET_ATTACHED_FILES_LOADING_STATE';
export const SET_STORAGE_FILE_LOADING_STATE = 'LOADING:SET_STORAGE_FILE_LOADING_STATE';
export const SET_PROPERTY_LOADING_STATE = 'LOADING:SET_PROPERTY_LOADING_STATE';
export const SET_INVOICE_LOADING_STATE = 'LOADING:SET_INVOICE_LOADING_STATE';
export const SET_DISPUTE_LOADING_STATE = 'LOADING:SET_DISPUTE_LOADING_STATE';
export const SET_DISPUTE_SPLIT_LOADING_STATE = 'LOADING:SET_DISPUTE_SPLIT_LOADING_STATE';
export const SET_CLOSE_REQUEST_LOADING_STATE = 'LOADING:SET_CLOSE_REQUEST_LOADING_STATE';
export const SET_PROPERTY_ADDRESS_LOADING_STATE = 'LOADING:SET_PROPERTY_ADDRESS_LOADING_STATE';

export const SET_LOADING_INDICATOR = 'LOADING:SET_LOADING_INDICATOR';
export const CLEAR_LOADING_INDICATOR = 'LOADING:CLEAR_LOADING_INDICATOR';
export const SET_LAST_LOAD_TIMESTAMP = 'LOADING:SET_LAST_LOAD_TIMESTAMP';
export const CLEAR_LAST_LOAD_TIMESTAMP = 'LOADING:CLEAR_LAST_LOAD_TIMESTAMP';