/*
    Deposits Admin Service
 */

import axiosRequest from "../clientShared/utilities/axios/AxiosRequest";
import {getAdminPath} from "./adminAPI";
import {API_ROUTE_CREATE_DEPOSIT} from "../constants/apiRouteConstants";

//
// Service
//
const DepositsAdminService = {
    DepositSetPaymentClearedAndEnableInterest,
    DepositSetPaymentClearedToPartnerAccount,
    DepositResetPaymentClearedAndDisableInterest,
    DepositDisableInterest,
    DepositEnableInterest,
    DepositSetPaymentCleared,
    DepositResetPaymentCleared,
    ResendDepositInvite,
    RefreshDepositPermissions,
    CancelDeposit,
    ComplianceToggleEnabled,
    InviteEmailToDeposit,
    RevertDepositToPending,
    AdminSetDepositFundsLocation,
    AdminDepositsSetEnabledDisabledCompliances,
    AdminAdminSendRevertedDepositEmail,
    CreateDeposit
};

// Export
export default DepositsAdminService;


function CreateDeposit(postData) {
    return axiosRequest({
        url: API_ROUTE_CREATE_DEPOSIT,
        method: 'POST',
        data: postData
    });
}


function DepositSetPaymentClearedAndEnableInterest(depositUID) {
    const url = getAdminPath(`deposits/deposits/${depositUID}/admin/set/payment/cleared/enable/interest`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}

function DepositSetPaymentClearedToPartnerAccount(depositUID) {
    const url = getAdminPath(`deposits/deposits/${depositUID}/admin/set/payment/cleared/partner/held`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function DepositResetPaymentClearedAndDisableInterest(depositUID) {
    const url = getAdminPath(`deposits/deposits/${depositUID}/admin/reset/payment/cleared/disable/interest`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function DepositDisableInterest(depositUID) {
    const url = getAdminPath(`deposits/deposits/${depositUID}/admin/disable/interest`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function DepositEnableInterest(depositUID) {
    const url = getAdminPath(`deposits/deposits/${depositUID}/admin/enable/interest`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function DepositSetPaymentCleared(depositUID) {
    const url = getAdminPath(`deposits/deposits/${depositUID}/admin/set/payment/cleared`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function DepositResetPaymentCleared(depositUID) {
    const url = getAdminPath(`deposits/deposits/${depositUID}/admin/reset/payment/cleared`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function ResendDepositInvite(depositUID) {
    const url = getAdminPath(`deposits/deposits/invites/${depositUID}/resend`);
    return axiosRequest({
        url: url,
        method: 'GET'
    });
}


function RefreshDepositPermissions(depositUID) {
    const url = getAdminPath(`deposits/deposits/${depositUID}/admin/permissions/refresh`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}

function CancelDeposit(depositUID, cancellingAccountUID) {
    const url = getAdminPath(`deposits/deposits/${depositUID}/admin/cancel/account/${cancellingAccountUID}`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function ComplianceToggleEnabled(complianceUID, enabled) {
    const url = getAdminPath(`deposits/compliance/${complianceUID}/set/enabled/${enabled}`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function InviteEmailToDeposit(depositUID, emailAddress) {
    const url = getAdminPath(`deposits/invite/deposit/${depositUID}/email/${emailAddress}`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function RevertDepositToPending(depositUID) {
    const url = getAdminPath(`deposits/deposit/${depositUID}/revert/to/pending`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function AdminSetDepositFundsLocation(depositUID, fundsLocation, fundsLocationUID) {
    const url = getAdminPath(`deposits/${depositUID}/set/funds/location/${fundsLocation}/uid/${fundsLocationUID}`);
    return axiosRequest({
        url: url,
        method: 'PUT'
    });
}


function AdminDepositsSetEnabledDisabledCompliances(depositUID, completedCompliances, disabledCompliances) {

    const putData = {
        completed_compliances: completedCompliances,
        disabled_compliances: disabledCompliances
    }

    const url = getAdminPath(`deposits/${depositUID}/set/enabled/disabled/compliances`);
    return axiosRequest({
        url: url,
        method: 'PUT',
        data: putData
    });
}


function AdminAdminSendRevertedDepositEmail(depositUID, sendLandlord, sendTenant, revertedReason) {

    const url = getAdminPath(`deposits/deposit/${depositUID}/revert/email/landlord/${sendLandlord}/tenant/${sendTenant}/reason/${revertedReason}`);
    return axiosRequest({
        url: url,
        method: 'GET'
    });
}