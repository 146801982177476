/*

 */

import React from 'react';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LANDLORD_ICON, TENANT_ICON} from "../../../../constants/commonIcons";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    divider: {
        height: 28,
        margin: 4,
    }
}));

export default function LandlordAndTenantDisplayWrapper({landlordChildren, tenantChildren, ...props}) {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            wrap={'nowrap'}
            spacing={1}
        >
            <Grid item>

                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    wrap={'nowrap'}
                >
                    <Grid item>
                        <h3>
                            <FontAwesomeIcon
                                icon={LANDLORD_ICON}
                                size={'2x'}
                                fixedWidth
                            />
                            Landlord
                        </h3>
                    </Grid>

                    <Grid item>
                        {landlordChildren}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item>
                <Divider className={classes.divider} orientation="vertical"/>
            </Grid>

            <Grid item>

                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    wrap={'nowrap'}
                >
                    <Grid item>
                        <h3>
                            <FontAwesomeIcon
                                icon={TENANT_ICON}
                                size={'2x'}
                                fixedWidth
                            />
                            Tenant
                        </h3>
                    </Grid>

                    <Grid item>
                        {tenantChildren}
                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    );

}