/*

 */

import React from 'react';
import Grid from "@material-ui/core/Grid";
import HomeLink from "../../admin/buttons/linkButtons/HomeLink";
import BackLink from "../../admin/buttons/linkButtons/BackLink";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    divider: {
        height: 28,
        margin: 4,
    }
}));

export default function PageLinks(props) {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            wrap={'nowrap'}
            spacing={2}
        >
            <Grid item>
                &nbsp;<BackLink/>
            </Grid>

            <Grid item>
                <Divider className={classes.divider} orientation="vertical"/>
            </Grid>

            <Grid item>
                <HomeLink/>
            </Grid>

        </Grid>
    );
}