import React, {useEffect} from 'react';
import {newLoadingIndicatorSelector} from "../../selectors/loadingSelectors";
import {useDispatch, useSelector} from "react-redux";
import {checkLoadingIndicator} from "../../utilities/loadingUtilities";
import {DEV_BUILD} from "../../constants/apiConstants";
import {GeneralLoaderSelector} from "../selectors/GeneralLoaderSelector";
import {generalLoaderActionLoadFormFromServer} from "../actions/GeneralLoaderActions";
import {get} from "lodash";


const useGeneralLoader = (uniqueID, loadURL, outgoingResourceName, responseExtractName = undefined) => {
    const dispatch = useDispatch();
    const resource = useSelector(state => GeneralLoaderSelector(state, uniqueID));
    const loadingIndicator = useSelector(state => newLoadingIndicatorSelector(state, uniqueID));

    useEffect(() => {
        const fetchFromServer = async () => {
            // console.log('::::::::::: General loader hook call out', uniqueID, loadURL);
            dispatch(generalLoaderActionLoadFormFromServer(uniqueID, loadURL));
        };
        // TODO: We should probably have an expire time on this - or some kind of refresh
        if(resource === undefined) {
            fetchFromServer();
        }
    }, []);

    const {loading, error, response} = checkLoadingIndicator(loadingIndicator);
    const returnLoading = loading === true || resource === undefined;

    if(DEV_BUILD === true && error === true){
        throw new Error(`useGeneralLoader: ${uniqueID}, ${loadURL}`);
    }

    const extractedResource = get(resource, responseExtractName);

    // console.log(':: Hook', loading, error, response, resource, returnLoading);
    return {loading: returnLoading, error, [outgoingResourceName]: extractedResource};
};

export default useGeneralLoader;