import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import {faRunning} from "@fortawesome/pro-regular-svg-icons";
import JeffYardiTenantProcessForm from "./jeffYardiTenantProcessForm";



const JeffYardiTenantProcessFormDialogButton = () => {
    const [showDialog, setShowDialog] = React.useState(false);

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={handleOpen}
                variant={'outlined'}
                // size={'small'}
            >
                <FontAwesomeIcon
                    icon={faRunning}
                    fixedWidth
                />
                &nbsp;Process Yardi Resident
            </Button>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="jeff-credit-dialog-title"
                aria-describedby="jeff-credit-description"
            >
                <DialogTitle id="account-dialog-title">Process Resident</DialogTitle>
                <DialogContent>
                    <JeffYardiTenantProcessForm doneCallback={handleClose}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
    }
};


export default JeffYardiTenantProcessFormDialogButton;