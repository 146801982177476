export const WITHDRAW_STATE_PENDING = 'pending';
export const WITHDRAW_STATE_USER_INFO_NEEDED = 'user_info_needed';
export const WITHDRAW_STATE_PAYMENT_PENDING = 'payment_pending';
export const WITHDRAW_STATE_PAYMENT_COMPLETE = 'payment_complete';
export const WITHDRAW_STATE_CANCELLED = 'cancelled';

export function getWithdrawStateColor(withdrawState) {
    switch (withdrawState) {
        case WITHDRAW_STATE_PENDING:
            return '#42bc93';
        case WITHDRAW_STATE_USER_INFO_NEEDED:
            return '#ffb307';
        case WITHDRAW_STATE_PAYMENT_PENDING:
            return '#00b700';
        case WITHDRAW_STATE_PAYMENT_COMPLETE:
            return '#00a200';
        case WITHDRAW_STATE_CANCELLED:
            return '#a2adb8';
        default:
            return '#29ABE2';
    }
}

// States in which the withdrawal can be updated or cancelled
export const WITHDRAW_EDIT_CANCEL_STATES = [
    WITHDRAW_STATE_PENDING,
    WITHDRAW_STATE_USER_INFO_NEEDED
];


export const BANK_ACCOUNT_STATE_NOT_IN_USE = 'not_in_use';
export const BANK_ACCOUNT_STATE_IN_USE = 'in_use';

export const BANK_ACCOUNT_STATS_DICT = {
    [BANK_ACCOUNT_STATE_NOT_IN_USE]: 'not_in_use',
    [BANK_ACCOUNT_STATE_IN_USE]: 'in_use'
};