/*

 */

import React from 'react';
import CardHeader from "@material-ui/core/CardHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlask, faUnicorn} from "@fortawesome/pro-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {red} from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import QuickTimestampDisplay from "../../utilities/displayAreas/QuickTimestampDisplay";
import {uuidToHex} from "../../../utilities/commonUtils";
import {CopyUIDLink} from "../buttons/linkButtons/copyUIDLink";
import CopyTextDataLink from "../buttons/linkButtons/copyDataLink";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    avatar: {
        backgroundColor: red[500],
    },
});

export default function AdminDisplayCard({
                                             cardTitle,
                                             cardSubheader,
                                             jsonObject = undefined,
                                             headerIcon = faFlask,
                                             disableShowOriginal = false,
                                             ...props
                                         }) {
    const classes = useStyles();

    const [showJSONView, setShowJSONView] = React.useState(false);
    const [useJSONFormatting, setUseJSONFormatting] = React.useState(true);
    const hasJSONData = jsonObject !== undefined;


    return (
        <Card className={classes.root} variant="outlined">
            <CardHeader
                avatar={
                    <FontAwesomeIcon icon={headerIcon} fixedWidth size={'3x'}/>
                }
                // action={
                //     <IconButton aria-label="settings">
                //         <FontAwesomeIcon icon={faUnicorn} fixedWidth/>
                //     </IconButton>
                // }
                title={cardTitle}
                subheader={cardSubheader}
            />
            <CardContent>
                {
                    showJSONView === false && props.children
                }
                {
                    showJSONView === true && renderJSONData()
                }
            </CardContent>
            <CardActions>
                {
                    hasJSONData === true &&
                    <Button
                        size="small"
                        onClick={toggleJSONView}
                        disabled={disableShowOriginal}
                    >
                        {showJSONView === true ? 'View Fancy' : 'View Original'}
                    </Button>
                }
            </CardActions>
        </Card>
    );

    function toggleJSONView() {
        if (hasJSONData === true) {
            setShowJSONView(!showJSONView);
        }
    }

    function renderJSONData() {
        return (
            <React.Fragment>
                {
                    Object.keys(jsonObject).map(
                        accountKey => {
                            return (
                                <React.Fragment key={accountKey}>
                                    <RenderJSONKeyAndData jsonKey={accountKey} jsonData={jsonObject[accountKey]}/>
                                    <br/>
                                </React.Fragment>);
                        }
                    )
                }
                <br/>
                <Tooltip title={'Toggle JSON formatting'} placement={'top'}>
                    <Switch
                        checked={useJSONFormatting}
                        onChange={toggleJSONFormatting}
                        inputProps={{'aria-label': 'toggle JSON formatting'}}
                    />
                </Tooltip>
            </React.Fragment>
        );
    }

    function toggleJSONFormatting() {
        setUseJSONFormatting(!useJSONFormatting);
    }

    function RenderJSONKeyAndData({jsonKey, jsonData}) {

        if (useJSONFormatting === true) {
            if (jsonKey.endsWith('timestamp') === true) {
                return <React.Fragment><b>{jsonKey}</b>: <QuickTimestampDisplay timestamp={jsonData}
                                                                                showTime={true}/></React.Fragment>;
            } else if (jsonKey.endsWith('_uid') === true) {
                return <React.Fragment><b>{jsonKey}</b>: <CopyUIDLink uid={jsonData}
                                                                      linkText={`${uuidToHex(jsonData)}`}/>
                </React.Fragment>;
            }
        }

        return <React.Fragment><b>{jsonKey}</b>: <CopyTextDataLink dataToCopy={jsonData.toString()}
                                                                   linkText={jsonData.toString()}/></React.Fragment>;
    }


}
