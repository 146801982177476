/*

 */

import React from 'react';

import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import UserAccountNameDisplay, {
    ACCOUNT_NAME_DISPLAY_EMAIL,
    ACCOUNT_NAME_DISPLAY_FULL_NAME, ACCOUNT_NAME_DISPLAY_FULL_NAME_WITH_TITLES
} from "../../accounts/infoDisplays/userAccountNameDisplay";
import UserAccountWarningsDisplay from "../../accounts/infoDisplays/userAccountWarningsDisplay";
import RenderDeposifyTags from "../../tags/DeposifyTags";
import UserAccountRolesDisplay from "../../../clientShared/components/display/services/accounts/UserAccountRolesDisplay";
import Grid from "@material-ui/core/Grid";
import UserAccountProfilePicInfoDisplay from "../../accounts/infoDisplays/userAccountProfilePicDisplay";
import CreatedUpdatedTimestampDisplay from "../../utilities/displayAreas/CreatedUpdatedTimestampDisplay";
import {CopyUIDLink} from "../buttons/linkButtons/copyUIDLink";
import {uuidToHex} from "../../../utilities/commonUtils";
import AdminDisplayCard from "./adminDisplayCard";
import {faUserCircle} from "@fortawesome/pro-solid-svg-icons";


export default function AdminDisplayUserAccount({loading, error, userAccount, ...props}) {

    if (loading === true || error === true || userAccount === undefined) {
        return (
            <VisualizeContainerLoadingIndicator
                loadingResource={userAccount}
                displayName={'User Account'}
                {...props} />
        );
    }

    return (
        <AdminDisplayCard
            cardTitle={<UserAccountNameDisplay userAccount={userAccount} displayMode={ACCOUNT_NAME_DISPLAY_FULL_NAME}/>}
            cardSubheader={<UserAccountNameDisplay userAccount={userAccount} displayMode={ACCOUNT_NAME_DISPLAY_EMAIL}/>}
            headerIcon={faUserCircle}
            jsonObject={userAccount}
        >
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                wrap={'nowrap'}
                spacing={1}
            >
                <Grid item>
                    {/*<UserAccountRoleIconDisplay userAccount={userAccount}/>*/}
                </Grid>
                <Grid item>
                    <UserAccountWarningsDisplay userAccount={userAccount}/>
                </Grid>
            </Grid>
            <br/>

            <UserAccountRolesDisplay userAccount={userAccount}/>
            <br/>
            <UserAccountProfilePicInfoDisplay userAccount={userAccount}/>
            <br/>
            <CreatedUpdatedTimestampDisplay createdTimestamp={userAccount.created_timestamp}
                                            lastUpdatedTimestamp={userAccount.last_updated_timestamp}/>
            <br/>
            <UserAccountNameDisplay userAccount={userAccount} displayMode={ACCOUNT_NAME_DISPLAY_FULL_NAME_WITH_TITLES}/>
            <br/>
            <b>UID</b>: <CopyUIDLink uid={userAccount.account_uid} linkText={`${uuidToHex(userAccount.account_uid)}`}/>
            <br/>

            <b>Tags</b>:<br/>
            <RenderDeposifyTags deposifyTags={userAccount.deposify_tags}/>
        </AdminDisplayCard>

    );

}