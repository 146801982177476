/*

 */

import React from 'react';
import Zoom from '@material-ui/core/Zoom';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {LOADING_SPINNER_URL} from "../../../constants/cdnConstants";

const CIRCULAR_PROGRESS_SIZE = 134;
const CIRCULAR_PROGRESS_THICKNESS = 1;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        position: 'relative',
        color: '#FFFFFF'
    },
    fabProgress: {
        color: '#31c1e2',
        position: 'absolute',
        top: -3,
        left: -3,
        zIndex: 1,
    }
}));


export default function AppStartingSpinner(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    minHeight: '100%',
                    minWidth: '100%',
                    top: 0,
                    position: 'absolute',
                    backgroundColor: '#333436'
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        height: '100%'
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >

                        <Zoom in={true}>
                            <div className={classes.wrapper}>
                                <img
                                    src={LOADING_SPINNER_URL}
                                    alt="Deposify Logo"
                                    width={128}
                                    height={128}
                                />
                                <CircularProgress
                                    size={CIRCULAR_PROGRESS_SIZE}
                                    thickness={CIRCULAR_PROGRESS_THICKNESS}
                                    className={classes.fabProgress}
                                />
                            </div>
                        </Zoom>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}
