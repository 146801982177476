/*
    Resource Action Types
 */

export const RESOURCE_UPDATE_RESOURCES = 'RESOURCE:UPDATE_RESOURCES';
export const RESOURCE_UPDATE_RESOURCE_UIDS = 'RESOURCE:UPDATE_RESOURCE_UIDS';
export const RESOURCE_UPDATE_RESOURCE_UIDS_FOR_OWNER = 'RESOURCE:UPDATE_RESOURCE_UIDS_FOR_OWNER';

export const RESOURCE_REMOVE_SINGLE_RESOURCE = 'RESOURCE:REMOVE_SINGLE_RESOURCE';

