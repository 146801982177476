/*
    API Routes
 */

export const API_ROUTE_AUTH_ACCESS_TOKEN_REFRESH = 'api/auth/refresh';

// Auth
export const API_ROUTE_AUTH_LOGIN = 'api/auth/login';
export const API_ROUTE_AUTH_REFRESH = 'api/auth/refresh';
export const API_ROUTE_AUTH_LOGOUT_PRE = 'api/auth/logout/pre';
export const API_ROUTE_AUTH_LOGOUT = 'api/auth/logout';
export const API_ROUTE_AUTH_CHECK_AUTH_STATUS = 'api/auth/check/auth/status';

// User accounts
export const API_ROUTE_CREATE_USER_ACCOUNT = '/api/accounts/create';
export const API_ROUTE_READ_USER_ACCOUNT = '/api/accounts/current';
export const API_ROUTE_READ_CURRENT_USER_ACCOUNT = '/api/accounts/current';
export const API_ROUTE_READ_CURRENT_USER_ACCOUNT_BALANCE = '/api/accounts/balance';
//api/accounts/basic/account/{account_uid}
export const API_ROUTE_READ_BASIC_USER_ACCOUNT_INFO = 'api/accounts/basic/account';
export const API_ROUTE_READ_USER_ACCOUNT_SETTINGS = 'api/accounts/settings/settings';

// Withdrawals
export const API_ROUTE_CREATE_USER_WITHDRAW = '/api/payments/withdrawals';
// offset/limit/most_recent_first
export const API_ROUTE_CREATE_READ_WITHDRAWALS_BY_USER_ACCOUNT = '/api/payments/withdrawals';

// Bank accounts
// {offset}/{limit}/{recently_updated_first}
export const API_ROUTE_READ_ALL_INTERNATIONAL_USER_BANK_ACCOUNTS = 'api/user/bank/accounts/international/all';
export const API_ROUTE_READ_ALL_US_DOMESTIC_USER_BANK_ACCOUNTS = 'api/user/bank/accounts/us/domestic/all';

export const API_ROUTE_CREATE_US_DOMESTIC_USER_BANK_ACCOUNT = 'api/user/bank/accounts/us/domestic';
export const API_ROUTE_CREATE_INTERNATIONAL_USER_BANK_ACCOUNT = 'api/user/bank/accounts/international';

export const API_ROUTE_CREATE_DEPOSIT = 'api/deposits/create';
// api/deposits/deposits/all/account/{offset}/{limit}/{recently_updated_first}
export const API_ROUTE_READ_ALL_DEPOSITS_BY_ACCOUNT = 'api/deposits/deposits/all/account';

//api/deposits/{deposit_uid}
export const API_ROUTE_READ_DEPOSIT = 'api/deposits';
export const API_ROUTE_READ_DEPOSIT_INVITE_BY_DEPOSIT_UID = 'api/deposits/invites/deposit';
// api/deposits/invites/{invite_uid}/add/email
export const API_ROUTE_ADD_EMAIL_ADDRESS_TO_DEPOSIT_INVITE = 'api/deposits/invites';
// api/deposits/invites/{invite_uid}/add/phone
export const API_ROUTE_ADD_PHONE_NUMBER_TO_DEPOSIT_INVITE = 'api/deposits/invites';

export const API_ROUTE_DEPOSITS_INVOICE_CREATE = 'api/deposits/payments/invoice/create';
export const API_ROUTE_DEPOSITS_INVOICE_CANCEL = 'api/deposits/payments/invoice/cancel';

export const API_ROUTE_REJECT_DEPOSIT_AS_TENANT = 'api/deposits/reject/tenant';
export const API_ROUTE_REJECT_DEPOSIT_AS_LANDLORD = 'api/deposits/reject/landlord';

export const API_ROUTE_ACCEPT_DEPOSIT_AS_TENANT = 'api/deposits/accept/tenant';
export const API_ROUTE_ACCEPT_DEPOSIT_AS_LANDLORD = 'api/deposits/accept/landlord';

// api/payments/invoice/{invoice_uid}
export const API_ROUTE_READ_INVOICE = 'api/payments/invoice';
export const API_ROUTE_PAY_INVOICE = 'api/payments/invoice/pay';

export const API_ROUTE_READ_FUNDS_LOCK_BY_RESOURCE = 'api/funds/locks/resource';

export const API_ROUTE_CANCEL_DEPOSIT = 'api/deposits/cancel';

export const API_ROUTE_CLOSE_DEPOSIT_REQUEST_LANDLORD = 'api/deposits/close/request/landlord';
export const API_ROUTE_CLOSE_DEPOSIT_REQUEST_TENANT = 'api/deposits/close/request/tenant';

export const API_ROUTE_ACCEPT_DEPOSIT_CLOSE_REQUEST = 'api/deposits/close/request/accept';
export const API_ROUTE_REJECT_DEPOSIT_CLOSE_REQUEST = 'api/deposits/close/request/reject';

export const API_ROUTE_DISPUTE_DEPOSIT = 'api/deposits/dispute';
// {dispute_uid}
export const API_ROUTE_READ_DISPUTE = 'api/deposits/dispute';
// {split_uid}
export const API_ROUTE_READ_DISPUTE_SPLIT = 'api/deposits/dispute/split';
// {dispute_uid}
export const API_ROUTE_READ_DISPUTE_SPLIT_AWAITING_RESPONSE = 'api/deposits/dispute/split/awaiting';

export const API_ROUTE_ACCEPT_DISPUTE_SPLIT = 'api/deposits/dispute/split/accept';
export const API_ROUTE_REJECT_DISPUTE_SPLIT = 'api/deposits/dispute/split/reject';
export const API_ROUTE_PROPOSE_DISPUTE_SPLIT = 'api/deposits/dispute/split/propose';

export const API_ROUTE_REQUEST_MEDIATOR = 'api/deposits/dispute/mediator/request';

export const API_ROUTE_SET_DISPUTE_SPLIT_ATTACHMENTS = 'api/deposits/dispute/split/attachments/set';
export const API_ROUTE_SET_DISPUTE_ATTACHMENTS = 'api/deposits/dispute/attachments/set';

//
// Messaging
//
export const API_ROUTE_SEND_MAILBOX_MESSAGE = 'api/messaging/mailbox/message/send';
// api/messaging/mailbox/messages/new/{mailbox_uid}/{offset}/{limit}
export const API_ROUTE_READ_NEW_MAILBOX_MESSAGES = 'api/messaging/mailbox/messages/new';
export const API_ROUTE_READ_ALL_MAILBOX_MESSAGES = 'api/messaging/mailbox/messages/all';
export const API_ROUTE_READ_ALL_MAILBOX_MESSAGES_AFTER_TIMESTAMP = 'api/messaging/mailbox/messages/all/timestamp';

export const API_ROUTE_CREATE_STORAGE_FILE_FOR_UPLOAD = 'api/storage/file/upload/create';
// api/storage/file/{file_uid}/upload/complete
export const API_ROUTE_MARK_STORAGE_FILE_UPLOAD_COMPLETE = 'api/storage/file';

//api/storage/file/{file_uid}
export const API_ROUTE_READ_STORAGE_FILE = 'api/storage/file';

export const API_ROUTE_ADD_FILES_TO_ATTACHED_STORAGE_FILES = 'api/storage/attached/add';
export const API_ROUTE_REMOVE_FILES_FROM_ATTACHED_STORAGE_FILES = 'api/storage/attached/remove';

export const API_ROUTE_CREATE_ATTACHED_STORAGE_FILES = 'api/storage/attached';
export const API_ROUTE_CREATE_PRE_UPLOAD_ATTACHED_STORAGE_FILES = 'api/storage/attached/pre/upload';

// api/storage/attached/{attached_files_uid}
export const API_ROUTE_READ_ATTACHED_STORAGE_FILES = 'api/storage/attached';

export const API_ROUTE_CREATE_FILE_DOWNLOAD_URL = 'api/storage/file/download';

export const API_ROUTE_SET_MAILBOX_MESSAGE_ATTACHMENTS = 'api/messaging/mailbox/messages/attachments/set';

// api/messaging/mailbox/{mailbox_uid}/message/{message_uid}
export const API_ROUTE_READ_MAILBOX_MESSAGE = 'api/messaging/mailbox';

// api/deposits/{deposit_uid}/history/{offset}/{limit}/{most_recent_first}
export const API_ROUTE_READ_DEPOSIT_HISTORY = 'api/deposits';

// api/events/action/items/{offset}/{limit}/{recently_updated_first}
export const API_ROUTE_READ_ACTION_ITEMS = 'api/events/action/items';
// api/events/action/items/{item_uid}/delete
export const API_ROUTE_DELETE_ACTION_ITEM = 'api/events/action/items';

//api/deposits/meta/data/{deposit_uid}
export const API_ROUTE_READ_DEPOSIT_META_DATA = 'api/deposits/meta/data';
// TODO: Clean up these debug routes
export const API_ROUTE_DEMO_POPULATE = 'api/debug/demo/populate/user';
export const API_ROUTE_ASSURANT_DEMO_POPULATE = 'api/debug/demo/populate/assurant';
export const API_ROUTE_CLEAN_HOUSE = 'api/debug/clean/house';

export const API_ROUTE_READ_DEPOSIT_USER_STATS = 'api/deposits/user/stats';
export const API_ROUTE_UPDATE_DEPOSIT_USER_STATS = 'api/deposits/user/stats/update';

export const API_ROUTE_PROCESS_STRIPE_PAYMENT = 'api/payments/stripe/process';

export const API_ROUTE_VERIFY_EMAIL = 'api/auth/verify/email';

export const API_ROUTE_UPDATE_USER_ACCOUNT_SETTINGS = 'api/accounts/settings/update';

export const API_ROUTE_CREATE_PAYMENT_REQUEST = 'api/payments/request';

export const API_ROUTE_UPDATE_USER_ACCOUNT_EMAIL_ADDRESS = 'api/accounts/settings/email/change';
export const API_ROUTE_READ_PENDING_EMAIL_CHANGE_EMAIL_ADDRESS ='/api/accounts/pending/email/change';
export const API_ROUTE_UPDATE_ACCOUNT_PROFILE_INFO = 'api/accounts/update/profile/info';
export const API_ROUTE_CHANGE_USER_ACCOUNT_PASSWORD = 'api/accounts/update/password';
export const API_ROUTE_READ_ALL_DEPOSIT_UIDS_BY_ACCOUNT = 'api/deposits/deposits/all/account/uids';