import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ClickToCopy from "../../clientShared/components/utility/ClickToCopy";
import Grid from "@material-ui/core/Grid";
import {RHINO_IMAGE} from "../../constants/rhinoConstants";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const InternalCard = ({title, id}) => {
    const classes = useStyles();

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography className={classes.pos} color="textSecondary">
                    {title}
                </Typography>
                <Typography variant="h6">
                    <ClickToCopy dataToCopy={id}/>
                </Typography>
            </CardContent>
        </Card>
    );
};

const DepositRhinoInfo = ({deposit}) => {

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <img src={RHINO_IMAGE} width={96} height={96} alt={"Rhino Logo"} style={{top: 12}}/>
            </Grid>
            <Grid item>
                <InternalCard title={"Partner"} id={deposit.rhino_property_owner_id}/>
            </Grid>
            <Grid item>
                <InternalCard title={"Property"} id={deposit.rhino_property_id}/>
            </Grid>
            <Grid item>
                <InternalCard title={"Unit"} id={deposit.rhino_unit_id}/>
            </Grid>
            <Grid item>
                <InternalCard title={"Policy App"} id={deposit.rhino_policy_application_id}/>
            </Grid>
        </Grid>
    );
};

export default DepositRhinoInfo;