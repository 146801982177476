/*
    TODO: Autogen this if we use it a lot
 */
import React from 'react';
import SmallDisplayCard from "../clientShared/components/display/SmallDisplayCard";
import {
    RESOURCE_TYPE_BANK_ACCOUNT,
    RESOURCE_TYPE_DEPOSIT,
    RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT,
    RESOURCE_TYPE_MAILBOX_MESSAGE,
    RESOURCE_TYPE_PRIORITY_ACH_PAYMENT,
    RESOURCE_TYPE_STORAGE_FILE,
    RESOURCE_TYPE_STRIPE_PAYMENT_LOG,
    RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT,
    RESOURCE_TYPE_USER_WITHDRAW
} from "../autoGenerated/constants/resourceTypeConstants";
import {dollarFormattedString, trimStringIfNeeded} from "../utilities/commonUtils";

export function TinyDepositCard({loading, error, deposit, ...props}) {

    if (loading === true || error === true || deposit === undefined) {
        return <React.Fragment>{loading.toString()}, {error.toString()}</React.Fragment>;
    }

    return (
        <SmallDisplayCard
            title={'deposit'}
            header={`${deposit.title}`}
            subheader={`${deposit.geo_state}, ${deposit.amount_in_cents}`}
            resourceType={RESOURCE_TYPE_DEPOSIT}
            resourceUID={deposit.deposit_uid}
        />
    );
}

export function TinyUserWithdrawalCard({loading, error, userWithdrawal, ...props}) {

    if (loading === true || error === true || userWithdrawal === undefined) {
        return <React.Fragment>{loading.toString()}, {error.toString()}</React.Fragment>;
    }

    return (
        <SmallDisplayCard
            title={'user withdrawal'}
            header={dollarFormattedString(userWithdrawal.amount_in_cents)}
            subheader={`${userWithdrawal.state}`}
            resourceType={RESOURCE_TYPE_USER_WITHDRAW}
            resourceUID={userWithdrawal.withdraw_uid}
        />
    );
}

export function TinyUSDomesticBankAccountCard({loading, error, bankAccount, ...props}) {
    if (loading === true || error === true || bankAccount === undefined) {
        return <React.Fragment>{loading.toString()}, {error.toString()}</React.Fragment>;
    }

    return (
        <SmallDisplayCard
            title={'bank account'}
            header={`${bankAccount.first_name_on_account} - ${bankAccount.last_name_on_account}`}
            subheader={`${bankAccount.bank_name}, ${bankAccount.state}`}
            resourceType={RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT}
            resourceUID={bankAccount.bank_account_uid}
        />
    );
}

export function TinyInternationalBankAccountCard({loading, error, bankAccount, ...props}) {
    if (loading === true || error === true || bankAccount === undefined) {
        return <React.Fragment>{loading.toString()}, {error.toString()}</React.Fragment>;
    }

    return (
        <SmallDisplayCard
            title={'bank account'}
            header={`${bankAccount.first_name_on_account} - ${bankAccount.last_name_on_account}`}
            subheader={`${bankAccount.bank_name}, ${bankAccount.state}`}
            resourceType={RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT}
            resourceUID={bankAccount.bank_account_uid}
        />
    );
}

export function TinyBankAccountCard({loading, error, bankAccount, ...props}) {

    if (loading === true || error === true || bankAccount === undefined) {
        return <React.Fragment>{loading.toString()}, {error.toString()}</React.Fragment>;
    }

    return (
        <SmallDisplayCard
            title={'bank account'}
            header={`${bankAccount.bank_name} - ${bankAccount.geo_state}`}
            subheader={`${bankAccount.address_line_one}, ${bankAccount.account_number}`}
            resourceType={RESOURCE_TYPE_BANK_ACCOUNT}
            resourceUID={bankAccount.bank_account_uid}
        />
    );
}

export function TinyStripePaymentCard({loading, error, stripePaymentLog, ...props}) {

    if (loading === true || error === true || stripePaymentLog === undefined) {
        return <React.Fragment>{loading.toString()}, {error.toString()}</React.Fragment>;
    }

    return (
        <SmallDisplayCard
            title={'stripe payment'}
            header={dollarFormattedString(stripePaymentLog.amount_in_cents)}
            subheader={`Succeeded: ${stripePaymentLog.payment_succeeded}, High risk: ${stripePaymentLog.high_risk_payment}`}
            resourceType={RESOURCE_TYPE_STRIPE_PAYMENT_LOG}
            resourceUID={stripePaymentLog.payment_log_uid}
        />
    );
}

export function TinyPriorityACHCard({loading, error, priorityAchPayment, ...props}) {

    if (loading === true || error === true || priorityAchPayment === undefined) {
        return <React.Fragment>{loading.toString()}, {error.toString()}</React.Fragment>;
    }

    return (
        <SmallDisplayCard
            title={'priority ach payment'}
            header={dollarFormattedString(priorityAchPayment.total_payment_in_cents)}
            subheader={`Succeeded: ${priorityAchPayment.payment_succeeded}, Admin flagged: ${priorityAchPayment.flagged_for_admin_review}`}
            resourceType={RESOURCE_TYPE_PRIORITY_ACH_PAYMENT}
            resourceUID={priorityAchPayment.payment_uid}
        />
    );
}

export function TinyStorageFileCard({loading, error, storageFile, ...props}) {

    if (loading === true || error === true || storageFile === undefined) {
        return <React.Fragment>{loading.toString()}, {error.toString()}</React.Fragment>;
    }

    return (
        <SmallDisplayCard
            title={'priority ach payment'}
            header={storageFile.original_file_name}
            subheader={`Size: ${storageFile.size_in_bytes}, User Deleted: ${storageFile.deleted}`}
            resourceType={RESOURCE_TYPE_STORAGE_FILE}
            resourceUID={storageFile.file_uid}
        />
    );
}

export function TinyMailboxMessageCard({loading, error, mailboxMessage, ...props}) {

    if (loading === true || error === true || mailboxMessage === undefined) {
        return <React.Fragment>{loading.toString()}, {error.toString()}</React.Fragment>;
    }

    return (
        <SmallDisplayCard
            title={'mailbox message'}
            header={trimStringIfNeeded(mailboxMessage.message_body, 20)}
            subheader={`Read: ${mailboxMessage.read}, Sent: ${mailboxMessage.sent_timestamp}`}
            resourceType={RESOURCE_TYPE_MAILBOX_MESSAGE}
            resourceUID={mailboxMessage.message_uid}
        />
    );
}