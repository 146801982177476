/*

 */

import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QuickTimestampDisplay from "../../utilities/displayAreas/QuickTimestampDisplay";
import Tooltip from "@material-ui/core/Tooltip";
import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";


export default function DepositAcceptedIndicator({deposit, useLandlord}) {

    const hasAccepted = useLandlord === true ? deposit.landlord_has_accepted : deposit.tenant_has_accepted;
    const acceptedTimestamp = useLandlord === true ? deposit.landlord_accepted_timestamp : deposit.tenant_accepted_timestamp;
    const displayName = useLandlord === true ? 'Landlord' : 'Tenant';

    const toolTipPostfix = hasAccepted === true ? 'accepted' : 'not yet accepted';
    const tooltip = `${displayName} has ${toolTipPostfix}`;

    return (
        <Tooltip
            title={tooltip}
            placement={'top'}
        >
            <span>
                <FontAwesomeIcon
                    icon={hasAccepted === true ? faCheck : faTimes}
                    fixedWidth
                    color={hasAccepted === true ? 'green' : 'red'}
                />
                {
                    hasAccepted === true &&
                    <QuickTimestampDisplay timestamp={acceptedTimestamp}/>
                }
            </span>
        </Tooltip>
    );


}
