import React from 'react';
import * as Sentry from "@sentry/browser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGhost} from "@fortawesome/pro-solid-svg-icons";
import {axiosInstance} from "../../../utilities/axiosInstance";
import {getAdminPath} from "../../../api/adminAPI";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {adminLoadingActionLoadGhostAccountInfoFromServer} from "../../../actions/adminActions";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";
import {adminGhostAccountInfoSelector} from "../../../selectors/adminSelectors";
import toast from "react-hot-toast";

function ClearAdminGhostButton(props) {

    const [showSpinner, setShowSpinner] = React.useState(false);
    const buttonDisabled = props.adminGhostAccountInfo === undefined || props.adminGhostAccountInfo.currently_in_use === false;

    return (
        <SpinnerButton
            // Non-button props
            showSpinner={showSpinner}
            // Button props
            variant="outlined"
            color="secondary"
            disabled={showSpinner || buttonDisabled}
            onClick={handleClick}
        >
            <FontAwesomeIcon
                icon={faGhost}
                fixedWidth
            />
            &nbsp;&nbsp;Clear Ghost Account
        </SpinnerButton>
    );

    function handleClick() {
        setShowSpinner(true);
        const clearGhostPath = getAdminPath('ghost/clear');

        return axiosInstance.put(clearGhostPath, {})
            .then(function (response) {
                props.adminLoadingActionLoadGhostAccountInfoFromServer();
                setShowSpinner(false);
            })
            .catch(function (error) {
                // Tell Sentry about the error
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed to clear ghost account');
                setShowSpinner(false);
            });
    }

}

const mapStateToProps = (state) => ({
    adminGhostAccountInfo: adminGhostAccountInfoSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminLoadingActionLoadGhostAccountInfoFromServer: adminLoadingActionLoadGhostAccountInfoFromServer
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(ClearAdminGhostButton);