// Client type: admin
// Name: Messaging Service Custom Render Components 

import {
    MESSAGING_OBJECT_KEY_RECIPIENT_ACCOUNT_UID,
    MESSAGING_OBJECT_KEY_SENDER_ACCOUNT_UID
} from "../objects/objectKeys";
import AdminViewFullAccountInfoLink from "../../../components/admin/buttons/linkButtons/adminViewFullAccountInfoLink";

export const EMAIL_EVENT_CUSTOM_RENDER_COMPONENTS = {};

export const EMAIL_TEMPLATE_CUSTOM_RENDER_COMPONENTS = {};

export const MAILBOX_CUSTOM_RENDER_COMPONENTS = {};

export const MAILBOX_MESSAGE_CUSTOM_RENDER_COMPONENTS = {
    [MESSAGING_OBJECT_KEY_SENDER_ACCOUNT_UID]: {
        dataPropName: 'accountUID',
        renderComp: AdminViewFullAccountInfoLink,
        passDataObject: false,
        extraProps: {showExtraInfo: true}
    },
    [MESSAGING_OBJECT_KEY_RECIPIENT_ACCOUNT_UID]: {
        dataPropName: 'accountUID',
        renderComp: AdminViewFullAccountInfoLink,
        passDataObject: false,
        extraProps: {showExtraInfo: true}
    },
};

export const MAILBOX_META_DATA_CUSTOM_RENDER_COMPONENTS = {};

export const SENT_EMAIL_CUSTOM_RENDER_COMPONENTS = {};
