// Name: Yardi Service Custom Render Components 

export const YARDI_SCREENING_WORKS_PRO_XML_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_ACTIVE_SCAN_LOG_ENTRY_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_CHARGE_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_CHARGE_CODE_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_DEPOSIT_CLOSE_INTENT_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_DEPOSIT_CREATE_INTENT_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_DEPOSIT_WITHHOLD_INTENT_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_EVENT_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_FLEX_DEPOSIT_INTENT_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_ILS_GUEST_CARD_EVENT_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_OTHER_OCCUPANT_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PET_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PHONE_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PMC_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PMC_CREDENTIALS_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PMC_CUSTOM_COLUMN_SETTINGS_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PMC_SERVICE_INTERFACE_VERSION_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PMC_STATS_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PMC_STATS_COLLECTOR_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PROPERTY_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_PROPERTY_UNIT_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_RESIDENT_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_RESIDENT_CUSTOM_COLUMN_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_SYSTEM_SETTINGS_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_TRANSACTION_CUSTOM_RENDER_COMPONENTS = {};
export const YARDI_VOYAGER_XML_CUSTOM_RENDER_COMPONENTS = {};
