/*

 */

import React from 'react';
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import {history} from "../../../../history/history";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-solid-svg-icons";
import {makeStyles} from "@material-ui/core/styles";
import {FADED_LINK_COLOUR} from "../../../../constants/styleConstants";

const useStyles = makeStyles(theme => ({
    linkColour: {
        color: FADED_LINK_COLOUR
    }
}));

function BackLink(props) {
    const classes = useStyles();

    return (
        <Tooltip
            title={'Back'}
            placement={'top'}
        >
            <span>
                <Link
                    variant="body2"
                    onClick={handleClick}
                    className={classes.linkColour}
                    style={{cursor: "pointer"}}
                >
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        fixedWidth
                    />
                    &nbsp;Back
                </Link>
            </span>
        </Tooltip>
    );

    function handleClick() {
        history.goBack();
    }
}


export default BackLink;
