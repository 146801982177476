/*

 */

import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ClickToCopy from "../../clientShared/components/utility/ClickToCopy";
import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";


const UserAccountEmailDisplay = ({userAccount}) => {

    const tooltip = userAccount.email_verified === true ? 'Email Verified' : 'Email Not Verified';
    const icon = userAccount.email_verified === true ? faCheck : faTimes;
    const colour = userAccount.email_verified === true ? 'green' : 'red';

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
        >

            <Grid item>
                <Typography variant="subtitle1">
                    <ClickToCopy dataToCopy={userAccount.email_address}/>
                </Typography>
            </Grid>

            <Grid item>
                <Tooltip title={tooltip} placement={'right'}>
                    <span>
                        <FontAwesomeIcon
                            icon={icon}
                            fixedWidth
                            color={colour}
                        />
                    </span>
                </Tooltip>
            </Grid>

        </Grid>

    );

}

export default UserAccountEmailDisplay;
