/**
 * URL Utilities
 */

export function renderURL(urlTemplateString, params) {
    /**
     * Replace variables in the template string with vars from the passed in object
     *
     * Usage:
     * const templateString = 'http://localhost:3000/api/deposits/:depositUID/invite/resend/email';
     * const finalURL = renderURL(templateString, {depositUID: '123-456-789'});
     */
    const remainingParams = {...params};

    // TODO: Needs testing for missing values
    return urlTemplateString.replace(
        /:[_A-Za-z][_A-Za-z0-9]*/g,
        currentParam => {
            // remove the ':' at the start and grab the param by name
            const key = currentParam.slice(1);
            delete remainingParams[key];
            return encodeURIComponent(params[key]);
        }
    );
}
