// Name: Custom Render Components Mapping


import {
	RESOURCE_TYPE_ACTION_ITEM,
	RESOURCE_TYPE_ARCHIVED_DEPOSIT_CLOSE_REQUEST,
	RESOURCE_TYPE_ARCHIVED_PENDING_EMAIL_CHANGE,
	RESOURCE_TYPE_ATTACHED_STORAGE_FILES,
	RESOURCE_TYPE_BALANCE_CHANGE_LOG,
	RESOURCE_TYPE_BALANCE_SOURCE_TRANSFER,
	RESOURCE_TYPE_BANK_ACCOUNT,
	RESOURCE_TYPE_BANK_ACCOUNT_BALANCE_RECORD,
	RESOURCE_TYPE_BANK_ACCOUNT_HISTORICAL_INTEREST_RATE,
	RESOURCE_TYPE_BANK_ACCOUNT_INTEREST,
	RESOURCE_TYPE_BANK_TRANSFER,
	RESOURCE_TYPE_BULK_FUNDS_TRANSFER,
	RESOURCE_TYPE_COMPLIANCE,
	RESOURCE_TYPE_COMPLIANCE_HISTORY,
	RESOURCE_TYPE_COMPLIANCE_RULE,
	RESOURCE_TYPE_CREDIT_USER_ACCOUNT_LOG,
	RESOURCE_TYPE_DEPOSIFY_TAG,
	RESOURCE_TYPE_DEPOSIT,
	RESOURCE_TYPE_DEPOSIT_ADJUSTMENT,
	RESOURCE_TYPE_DEPOSIT_BANK_MAPPING,
	RESOURCE_TYPE_DEPOSIT_CLOSE_REQUEST,
	RESOURCE_TYPE_DEPOSIT_GROUP,
	RESOURCE_TYPE_DEPOSIT_HISTORY_ENTRY,
	RESOURCE_TYPE_DEPOSIT_INTEREST,
	RESOURCE_TYPE_DEPOSIT_INTEREST_LOG,
	RESOURCE_TYPE_DEPOSIT_INVITE,
	RESOURCE_TYPE_DEPOSIT_USER_STATS,
	RESOURCE_TYPE_DEPOSIT_WITHHELD_FUNDS,
	RESOURCE_TYPE_DEPOSITS_BULK_IMPORT,
	RESOURCE_TYPE_DISPUTE,
	RESOURCE_TYPE_DISPUTE_INTERVENTION_REQUEST,
	RESOURCE_TYPE_DISPUTE_SPLIT,
	RESOURCE_TYPE_EMAIL_EVENT,
	RESOURCE_TYPE_EMAIL_TEMPLATE,
	RESOURCE_TYPE_EMAIL_VERIFICATION,
	RESOURCE_TYPE_EMAIL_VERIFICATION_RECORD,
	RESOURCE_TYPE_EVENT,
	RESOURCE_TYPE_EVENT_HOOK,
	RESOURCE_TYPE_EXPIRED_PASSWORD,
	RESOURCE_TYPE_FUNDS_LOCK,
	RESOURCE_TYPE_FUNDS_LOCK_RELEASE_LOG,
	RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT,
	RESOURCE_TYPE_INVOICE,
	RESOURCE_TYPE_INVOICE_ITEM,
	RESOURCE_TYPE_IP_ADDRESS,
	RESOURCE_TYPE_KNOWN_IP_ADDRESS,
	RESOURCE_TYPE_KNOWN_USER_AGENT,
	RESOURCE_TYPE_LOAN,
	RESOURCE_TYPE_LOAN_APPLICATION,
	RESOURCE_TYPE_LOAN_HISTORY,
	RESOURCE_TYPE_LOAN_PAYMENT_LOG,
	RESOURCE_TYPE_LOAN_SYSTEM_INFO,
	RESOURCE_TYPE_MAILBOX,
	RESOURCE_TYPE_MAILBOX_MESSAGE,
	RESOURCE_TYPE_MAILBOX_META_DATA,
	RESOURCE_TYPE_NOTIFICATION,
	RESOURCE_TYPE_PASSWORD,
	RESOURCE_TYPE_PASSWORD_RESET,
	RESOURCE_TYPE_PAYMENT_REQUEST,
	RESOURCE_TYPE_PENDING_EMAIL_CHANGE,
	RESOURCE_TYPE_PERMISSION_ENTRY,
	RESOURCE_TYPE_PERMISSION_LOG_ENTRY,
	RESOURCE_TYPE_PERMISSIONS_ORGANIZATION_SETTINGS,
	RESOURCE_TYPE_PREVIOUS_EMAIL_ADDRESS,
	RESOURCE_TYPE_PRIORITY_ACH_PAYMENT,
	RESOURCE_TYPE_PRIORITY_ACH_RECURRING_PAYMENT,
	RESOURCE_TYPE_PROPERTY,
	RESOURCE_TYPE_PROPERTY_ADDRESS,
	RESOURCE_TYPE_RELEASED_FUNDS_LOCK,
	RESOURCE_TYPE_RESOURCE_NOTE,
	RESOURCE_TYPE_SENT_EMAIL,
	RESOURCE_TYPE_SNAPSHOT,
	RESOURCE_TYPE_STORAGE_FILE,
	RESOURCE_TYPE_STRIPE_PAYMENT_LOG,
	RESOURCE_TYPE_STRIPE_WEBHOOK_LOG,
	RESOURCE_TYPE_SYSTEM_HISTORY_ENTRY,
	RESOURCE_TYPE_SYSTEM_STATS,
	RESOURCE_TYPE_UPDATE_LOG_ENTRY,
	RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT,
	RESOURCE_TYPE_USER_ACCOUNT,
	RESOURCE_TYPE_USER_ACCOUNT_ADMIN_GHOST,
	RESOURCE_TYPE_USER_ACCOUNT_BALANCE,
	RESOURCE_TYPE_USER_ACCOUNT_BALANCE_SOURCE,
	RESOURCE_TYPE_USER_ACCOUNT_PAYMENT_PROVIDER_INFO,
	RESOURCE_TYPE_USER_ACCOUNT_SETTINGS,
	RESOURCE_TYPE_USER_AGENT,
	RESOURCE_TYPE_USER_BANK_ACCOUNT_DELTA,
	RESOURCE_TYPE_USER_HISTORY_ENTRY,
	RESOURCE_TYPE_USER_WITHDRAW
} from "../../../autoGenerated/constants/resourceTypeConstants";
import {
	BANK_ACCOUNT_BALANCE_RECORD_CUSTOM_RENDER_COMPONENTS,
	BANK_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
	BANK_ACCOUNT_HISTORICAL_INTEREST_RATE_CUSTOM_RENDER_COMPONENTS,
	BANK_ACCOUNT_INTEREST_CUSTOM_RENDER_COMPONENTS,
	BANK_TRANSFER_CUSTOM_RENDER_COMPONENTS,
	BULK_FUNDS_TRANSFER_CUSTOM_RENDER_COMPONENTS,
} from "./BankingServiceCustomRenderComponents.js";
import {
	ARCHIVED_DEPOSIT_CLOSE_REQUEST_CUSTOM_RENDER_COMPONENTS,
	COMPLIANCE_CUSTOM_RENDER_COMPONENTS,
	COMPLIANCE_HISTORY_CUSTOM_RENDER_COMPONENTS,
	COMPLIANCE_RULE_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_ADJUSTMENT_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_BANK_MAPPING_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_BULK_IMPORT_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_CLOSE_REQUEST_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_GROUP_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_INTEREST_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_INTEREST_LOG_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_INVITE_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_USER_STATS_CUSTOM_RENDER_COMPONENTS,
	DEPOSIT_WITHHELD_FUNDS_CUSTOM_RENDER_COMPONENTS,
	DISPUTE_CUSTOM_RENDER_COMPONENTS,
	DISPUTE_INTERVENTION_REQUEST_CUSTOM_RENDER_COMPONENTS,
	DISPUTE_SPLIT_CUSTOM_RENDER_COMPONENTS,
	PROPERTY_ADDRESS_CUSTOM_RENDER_COMPONENTS,
	PROPERTY_CUSTOM_RENDER_COMPONENTS,
} from "./DepositsServiceCustomRenderComponents.js";
import {
	ACTION_ITEM_CUSTOM_RENDER_COMPONENTS,
	EVENT_CUSTOM_RENDER_COMPONENTS,
	EVENT_HOOK_CUSTOM_RENDER_COMPONENTS,
	NOTIFICATION_CUSTOM_RENDER_COMPONENTS,
} from "./EventsServiceCustomRenderComponents.js";
import {
	DEPOSIT_HISTORY_ENTRY_CUSTOM_RENDER_COMPONENTS,
	SNAPSHOT_CUSTOM_RENDER_COMPONENTS,
	SYSTEM_HISTORY_ENTRY_CUSTOM_RENDER_COMPONENTS,
	UPDATE_LOG_CUSTOM_RENDER_COMPONENTS,
	USER_HISTORY_ENTRY_CUSTOM_RENDER_COMPONENTS,
} from "./HistoryServiceCustomRenderComponents.js";
import {
	EMAIL_EVENT_CUSTOM_RENDER_COMPONENTS,
	EMAIL_TEMPLATE_CUSTOM_RENDER_COMPONENTS,
	MAILBOX_CUSTOM_RENDER_COMPONENTS,
	MAILBOX_MESSAGE_CUSTOM_RENDER_COMPONENTS,
	MAILBOX_META_DATA_CUSTOM_RENDER_COMPONENTS,
	SENT_EMAIL_CUSTOM_RENDER_COMPONENTS,
} from "./MessagingServiceCustomRenderComponents.js";
import {
	INVOICE_CUSTOM_RENDER_COMPONENTS,
	INVOICE_ITEM_CUSTOM_RENDER_COMPONENTS,
	LOAN_APPLICATION_CUSTOM_RENDER_COMPONENTS,
	LOAN_CUSTOM_RENDER_COMPONENTS,
	LOAN_HISTORY_CUSTOM_RENDER_COMPONENTS,
	LOAN_PAYMENT_LOG_CUSTOM_RENDER_COMPONENTS,
	LOAN_SYSTEM_INFO_CUSTOM_RENDER_COMPONENTS,
	PAYMENT_REQUEST_CUSTOM_RENDER_COMPONENTS,
	PRIORITY_ACH_PAYMENT_CUSTOM_RENDER_COMPONENTS,
	PRIORITY_ACH_RECURRING_PAYMENT_CUSTOM_RENDER_COMPONENTS,
	STRIPE_PAYMENT_LOG_CUSTOM_RENDER_COMPONENTS,
	STRIPE_WEBHOOK_LOG_CUSTOM_RENDER_COMPONENTS,
	USER_ACCOUNT_PAYMENT_PROVIDER_INFO_CUSTOM_RENDER_COMPONENTS,
	USER_WITHDRAW_CUSTOM_RENDER_COMPONENTS,
} from "./PaymentsServiceCustomRenderComponents.js";
import {
	PERMISSION_ENTRY_CUSTOM_RENDER_COMPONENTS,
	PERMISSION_LOG_ENTRY_CUSTOM_RENDER_COMPONENTS,
	PERMISSIONS_ORGANIZATION_SETTINGS_CUSTOM_RENDER_COMPONENTS,
} from "./PermissionsServiceCustomRenderComponents.js";
import {
	IP_ADDRESS_CUSTOM_RENDER_COMPONENTS,
	KNOWN_IP_ADDRESS_CUSTOM_RENDER_COMPONENTS,
	KNOWN_USER_AGENT_CUSTOM_RENDER_COMPONENTS,
	USER_AGENT_CUSTOM_RENDER_COMPONENTS,
} from "./SecurityServiceCustomRenderComponents.js";
import {
	ATTACHED_STORAGE_FILES_CUSTOM_RENDER_COMPONENTS,
	STORAGE_FILE_CUSTOM_RENDER_COMPONENTS,
} from "./StorageServiceCustomRenderComponents.js";
import {
	DEPOSIFY_TAG_CUSTOM_RENDER_COMPONENTS,
	RESOURCE_NOTE_CUSTOM_RENDER_COMPONENTS,
	SYSTEM_STATS_CUSTOM_RENDER_COMPONENTS,
} from "./SystemGeneralServiceCustomRenderComponents.js";
import {
	ADMIN_GHOST_USER_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
	ARCHIVED_PENDING_EMAIL_CHANGE_CUSTOM_RENDER_COMPONENTS,
	BALANCE_CHANGE_LOG_CUSTOM_RENDER_COMPONENTS,
	BALANCE_SOURCE_TRANSFER_CUSTOM_RENDER_COMPONENTS,
	CREDIT_USER_ACCOUNT_LOG_CUSTOM_RENDER_COMPONENTS,
	EMAIL_VERIFICATION_CUSTOM_RENDER_COMPONENTS,
	EMAIL_VERIFICATION_RECORD_CUSTOM_RENDER_COMPONENTS,
	EXPIRED_PASSWORD_CUSTOM_RENDER_COMPONENTS,
	FUNDS_LOCK_CUSTOM_RENDER_COMPONENTS,
	FUNDS_LOCK_RELEASE_LOG_CUSTOM_RENDER_COMPONENTS,
	INTERNATIONAL_USER_BANK_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
	PASSWORD_CUSTOM_RENDER_COMPONENTS,
	PASSWORD_RESET_CUSTOM_RENDER_COMPONENTS,
	PENDING_EMAIL_CHANGE_CUSTOM_RENDER_COMPONENTS,
	PREVIOUS_EMAIL_ADDRESS_CUSTOM_RENDER_COMPONENTS,
	RELEASED_FUNDS_LOCK_CUSTOM_RENDER_COMPONENTS,
	US_DOMESTIC_USER_BANK_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
	USER_ACCOUNT_BALANCE_CUSTOM_RENDER_COMPONENTS,
	USER_ACCOUNT_BALANCE_SOURCE_CUSTOM_RENDER_COMPONENTS,
	USER_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
	USER_ACCOUNT_SETTINGS_CUSTOM_RENDER_COMPONENTS,
	USER_BANK_ACCOUNT_DELTA_CUSTOM_RENDER_COMPONENTS,
} from "./UserAccountsServiceCustomRenderComponents.js";

export const RESOURCE_TO_CUSTOM_RENDER_COMPONENTS_DICT = {
    [RESOURCE_TYPE_BANK_ACCOUNT_BALANCE_RECORD]: BANK_ACCOUNT_BALANCE_RECORD_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_BANK_ACCOUNT]: BANK_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_BANK_ACCOUNT_HISTORICAL_INTEREST_RATE]: BANK_ACCOUNT_HISTORICAL_INTEREST_RATE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_BANK_ACCOUNT_INTEREST]: BANK_ACCOUNT_INTEREST_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_BANK_TRANSFER]: BANK_TRANSFER_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_BULK_FUNDS_TRANSFER]: BULK_FUNDS_TRANSFER_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_ARCHIVED_DEPOSIT_CLOSE_REQUEST]: ARCHIVED_DEPOSIT_CLOSE_REQUEST_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_COMPLIANCE]: COMPLIANCE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_COMPLIANCE_HISTORY]: COMPLIANCE_HISTORY_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_COMPLIANCE_RULE]: COMPLIANCE_RULE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT]: DEPOSIT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_ADJUSTMENT]: DEPOSIT_ADJUSTMENT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_BANK_MAPPING]: DEPOSIT_BANK_MAPPING_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSITS_BULK_IMPORT]: DEPOSIT_BULK_IMPORT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_CLOSE_REQUEST]: DEPOSIT_CLOSE_REQUEST_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_GROUP]: DEPOSIT_GROUP_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_INTEREST]: DEPOSIT_INTEREST_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_INTEREST_LOG]: DEPOSIT_INTEREST_LOG_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_INVITE]: DEPOSIT_INVITE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_USER_STATS]: DEPOSIT_USER_STATS_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_WITHHELD_FUNDS]: DEPOSIT_WITHHELD_FUNDS_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DISPUTE]: DISPUTE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DISPUTE_INTERVENTION_REQUEST]: DISPUTE_INTERVENTION_REQUEST_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DISPUTE_SPLIT]: DISPUTE_SPLIT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PROPERTY]: PROPERTY_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PROPERTY_ADDRESS]: PROPERTY_ADDRESS_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_ACTION_ITEM]: ACTION_ITEM_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_EVENT_HOOK]: EVENT_HOOK_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_EVENT]: EVENT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_NOTIFICATION]: NOTIFICATION_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_SNAPSHOT]: SNAPSHOT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_SYSTEM_HISTORY_ENTRY]: SYSTEM_HISTORY_ENTRY_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIT_HISTORY_ENTRY]: DEPOSIT_HISTORY_ENTRY_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_UPDATE_LOG_ENTRY]: UPDATE_LOG_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_HISTORY_ENTRY]: USER_HISTORY_ENTRY_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_EMAIL_EVENT]: EMAIL_EVENT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_EMAIL_TEMPLATE]: EMAIL_TEMPLATE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_MAILBOX]: MAILBOX_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_MAILBOX_MESSAGE]: MAILBOX_MESSAGE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_MAILBOX_META_DATA]: MAILBOX_META_DATA_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_SENT_EMAIL]: SENT_EMAIL_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_INVOICE]: INVOICE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_INVOICE_ITEM]: INVOICE_ITEM_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_LOAN]: LOAN_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_LOAN_APPLICATION]: LOAN_APPLICATION_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_LOAN_HISTORY]: LOAN_HISTORY_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_LOAN_PAYMENT_LOG]: LOAN_PAYMENT_LOG_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_LOAN_SYSTEM_INFO]: LOAN_SYSTEM_INFO_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PAYMENT_REQUEST]: PAYMENT_REQUEST_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PRIORITY_ACH_PAYMENT]: PRIORITY_ACH_PAYMENT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PRIORITY_ACH_RECURRING_PAYMENT]: PRIORITY_ACH_RECURRING_PAYMENT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_STRIPE_PAYMENT_LOG]: STRIPE_PAYMENT_LOG_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_STRIPE_WEBHOOK_LOG]: STRIPE_WEBHOOK_LOG_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_ACCOUNT_PAYMENT_PROVIDER_INFO]: USER_ACCOUNT_PAYMENT_PROVIDER_INFO_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_WITHDRAW]: USER_WITHDRAW_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PERMISSIONS_ORGANIZATION_SETTINGS]: PERMISSIONS_ORGANIZATION_SETTINGS_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PERMISSION_ENTRY]: PERMISSION_ENTRY_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PERMISSION_LOG_ENTRY]: PERMISSION_LOG_ENTRY_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_IP_ADDRESS]: IP_ADDRESS_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_KNOWN_IP_ADDRESS]: KNOWN_IP_ADDRESS_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_KNOWN_USER_AGENT]: KNOWN_USER_AGENT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_AGENT]: USER_AGENT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_ATTACHED_STORAGE_FILES]: ATTACHED_STORAGE_FILES_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_STORAGE_FILE]: STORAGE_FILE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_DEPOSIFY_TAG]: DEPOSIFY_TAG_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_RESOURCE_NOTE]: RESOURCE_NOTE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_SYSTEM_STATS]: SYSTEM_STATS_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_ACCOUNT_ADMIN_GHOST]: ADMIN_GHOST_USER_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_ARCHIVED_PENDING_EMAIL_CHANGE]: ARCHIVED_PENDING_EMAIL_CHANGE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_BALANCE_CHANGE_LOG]: BALANCE_CHANGE_LOG_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_BALANCE_SOURCE_TRANSFER]: BALANCE_SOURCE_TRANSFER_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_CREDIT_USER_ACCOUNT_LOG]: CREDIT_USER_ACCOUNT_LOG_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_EMAIL_VERIFICATION]: EMAIL_VERIFICATION_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_EMAIL_VERIFICATION_RECORD]: EMAIL_VERIFICATION_RECORD_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_EXPIRED_PASSWORD]: EXPIRED_PASSWORD_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_FUNDS_LOCK]: FUNDS_LOCK_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_FUNDS_LOCK_RELEASE_LOG]: FUNDS_LOCK_RELEASE_LOG_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_INTERNATIONAL_USER_BANK_ACCOUNT]: INTERNATIONAL_USER_BANK_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PASSWORD]: PASSWORD_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PASSWORD_RESET]: PASSWORD_RESET_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PENDING_EMAIL_CHANGE]: PENDING_EMAIL_CHANGE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_PREVIOUS_EMAIL_ADDRESS]: PREVIOUS_EMAIL_ADDRESS_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_RELEASED_FUNDS_LOCK]: RELEASED_FUNDS_LOCK_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_ACCOUNT]: USER_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_ACCOUNT_BALANCE]: USER_ACCOUNT_BALANCE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_ACCOUNT_BALANCE_SOURCE]: USER_ACCOUNT_BALANCE_SOURCE_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_ACCOUNT_SETTINGS]: USER_ACCOUNT_SETTINGS_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_USER_BANK_ACCOUNT_DELTA]: USER_BANK_ACCOUNT_DELTA_CUSTOM_RENDER_COMPONENTS,
    [RESOURCE_TYPE_US_DOMESTIC_USER_BANK_ACCOUNT]: US_DOMESTIC_USER_BANK_ACCOUNT_CUSTOM_RENDER_COMPONENTS,
};

export function getResourceCustomRenderComponents(resourceType) {
    return RESOURCE_TO_CUSTOM_RENDER_COMPONENTS_DICT[resourceType];
}
