/*

 */

import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerQuestion} from "@fortawesome/pro-solid-svg-icons";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {APP_ROUTE_HOME} from "../../../constants/appRoutes";

export default function BadRoute() {

    return (
        <div>
            <br/><br/>
            <h1>
                404 - Not Found
            </h1>

            <Typography>
                <FontAwesomeIcon icon={faMapMarkerQuestion} fixedWidth/>
                Sorry, we couldn't find that page. Please check the address and try again.
            </Typography>

            <br/>

            <Link to={APP_ROUTE_HOME}>Home</Link>

        </div>
    )
}