/*

 */

import React from 'react';
import * as Sentry from '@sentry/browser';
import {axiosInstance} from "../../../../utilities/axiosInstance";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/pro-solid-svg-icons";


function AdminDownloadStorageFileLink({fileUID, useButton = false, ...props}) {

    if(useButton === true){
        return (
            <Button
                color="primary"
                onClick={downloadFile}
            >
                <FontAwesomeIcon
                    icon={faDownload}
                    fixedWidth
                />
                &nbsp;Download
            </Button>
        );
    }

    return (
        <Link
            component="button"
            variant="body2"
            onClick={downloadFile}
        >
            Download
        </Link>
    );

    function downloadFile() {
        axiosInstance.get(`api/ornate/cabbage/storage/files/${fileUID}/download`)
            .then(function (response) {
                window.location.href = response.data.url;
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
            });
    }


}

export default AdminDownloadStorageFileLink;