/*
    Button with a spinner
 */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {DANGER_BUTTON_COLOR} from "../../../constants/dataConstants";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        // margin: theme.spacing(1),
        // marginTop: 24,
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonProgressDanger: {
        color: DANGER_BUTTON_COLOR,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

export default function SpinnerButton(props) {
    const classes = useStyles();
    // destruct the object so we separate out the non-button related props, if we pass them all in we get an error
    const {showSpinner, useDangerColor, ...buttonProps} = props;

    const progressClass = useDangerColor === true ? classes.buttonProgressDanger : classes.buttonProgress;

    return (
        <div className={classes.wrapper}>
            <Button {...buttonProps}>
                {props.children}
                {
                    showSpinner &&
                    <CircularProgress size={24} className={progressClass}/>
                }
            </Button>
        </div>
    );
}