import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/pro-regular-svg-icons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/browser";
import TextField from "@material-ui/core/TextField";


const RhinoEditDepositTitle = ({deposit}) => {
    const [showDialog, setShowDialog] = React.useState(false);
    const [newTitle, setNewTitle] = useState(deposit.title);
    const goodLength = newTitle.length >= 3 && newTitle.length < 128;
    const showUpdateButton = newTitle !== deposit.title && goodLength;

    return (
        <div>
            <Button
                color="primary"
                onClick={handleOpen}
                variant={'outlined'}
                // size={'small'}
            >
                <FontAwesomeIcon
                    icon={faPencil}
                    fixedWidth
                />
                &nbsp;Edit Deposit Title
            </Button>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="jeff-edit-deposit-title"
                aria-describedby="jeff-edit-deposit-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="account-dialog-title">Edit Deposit Title</DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        label="Title"
                        onChange={handleChange}
                        fullWidth={true}
                        value={newTitle}
                    />
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleUpdate} color="primary" variant={"contained"} disabled={!showUpdateButton}>
                        Update Deposit
                    </Button>

                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );

    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
    }

    function handleChange(changeEvent) {
        setNewTitle(changeEvent.target.value);
    }

    function handleUpdate() {
        handleClose();
        let updatedValues = {title: newTitle, property_uid: deposit.property_uid};

        const callURL = getAdminPath(`jeff/deposits/${deposit.deposit_uid}/update/special`);
        axiosInstance.patch(callURL, updatedValues)
            .then(function (response) {
                toast.success('Deposit updated');
                window.location.reload();
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                toast.error('Failed');
            })
    }
};

export default RhinoEditDepositTitle;