/*

 */

import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AdminDisplayUserAccount from "./adminDisplayUserAccount";
import AdminDisplayUserAccountBalance from "./adminDisplayUserAccountBalance";
import AdminDisplayUserAccountPassword from "./adminDisplayUserAccountPassword";
import AdminDisplayUserAccountSettings from "./adminDisplayUserAccountSettings";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faKey} from "@fortawesome/pro-regular-svg-icons";
import Grid from "@material-ui/core/Grid";
import {
    PasswordContainer,
    UserAccountBalanceContainer,
    UserAccountContainer,
    UserAccountSettingsContainer
} from "../../../autoGenerated/components/containers/resourceContainers";

function AdminFullAccountInfoDisplay({accountUID, ...props}) {

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid item>
                    <UserAccountContainer accountUID={accountUID}>
                        <AdminDisplayUserAccount/>
                    </UserAccountContainer>
                </Grid>

                <Grid item>
                    <UserAccountBalanceContainer accountUID={accountUID}>
                        <AdminDisplayUserAccountBalance/>
                    </UserAccountBalanceContainer>
                </Grid>
            </Grid>

            <br/><br/>

            <h2><FontAwesomeIcon icon={faKey} fixedWidth/>Account Password</h2>
            <PasswordContainer accountUID={accountUID}>
                <AdminDisplayUserAccountPassword/>
            </PasswordContainer>
            <br/><br/>

            <h2><FontAwesomeIcon icon={faCog} fixedWidth/>Account Settings</h2>
            <UserAccountSettingsContainer accountUID={accountUID}>
                <AdminDisplayUserAccountSettings/>
            </UserAccountSettingsContainer>
            <br/>

            <br/>
        </React.Fragment>
    );

}

const mapStateToProps = (state) => ({});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(AdminFullAccountInfoDisplay);