import {
    USER_ACCOUNT_ROLES_ADMIN,
    USER_ACCOUNT_ROLES_BROKER,
    USER_ACCOUNT_ROLES_LANDLORD,
    USER_ACCOUNT_ROLES_MEDIATOR,
    USER_ACCOUNT_ROLES_SUPER_ADMIN,
    USER_ACCOUNT_ROLES_TENANT
} from "../../../autoGenerated/constants/services/UserAccountsServiceConstants";

const USER_ACCOUNT_ROLE_DATA_DISPLAY_MAPPING = {
    [USER_ACCOUNT_ROLES_LANDLORD]: 'Landlord',
    [USER_ACCOUNT_ROLES_TENANT]: 'Tenant',
    [USER_ACCOUNT_ROLES_BROKER]: 'Property Management Company',
    [USER_ACCOUNT_ROLES_MEDIATOR]: 'Mediator',
    [USER_ACCOUNT_ROLES_ADMIN]: 'Admin',
    [USER_ACCOUNT_ROLES_SUPER_ADMIN]: 'Super Admin'
};

export function getUserAccountRoleDisplayName(userAccountRole) {
    return USER_ACCOUNT_ROLE_DATA_DISPLAY_MAPPING[userAccountRole];
}