import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GenericFullScreenButton from "../../../components/admin/buttons/linkButtons/genericFullScreenButton";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
    root: {
        minWidth: 220,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 6,
    },
});

/*
    object
    desc
    title
    sub
    resource_link, full_link
 */

export default function SmallDisplayCard({title, header, subheader, resourceType, resourceUID, ...props}) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h6" >
                    {header}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {subheader}
                </Typography>

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <Typography className={classes.title} color="textSecondary">
                            {title}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <GenericFullScreenButton
                            linkText={'View'}
                            resourceUID={resourceUID}
                            resourceType={resourceType}
                            // useLink={false}
                        />
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
}