/*
    Dev
 */

import React from "react";
import {
    RENDER_OBJECT_DATA_MODE_OBJECT,
    renderObjectData
} from "../../../components/tables/JSONSchema/JSONSchemaUtilities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TableCell} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CopyDataButton from "../../../components/utilities/buttons/CopyDataButton";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import ClickToCopy from "../utility/ClickToCopy";

const useStyles = makeStyles({
    header: {
        marginLeft: 15
    },
    table: {
        minWidth: 500,
    },
    cellStyle: {
        border: 0,
        // borderBottom: '1px solid #2D2E30'
        borderBottom: '1px solid #4e5054'
    },
    headerMargin: {
        marginTop: 10
    }
});


function GenericObjectDisplay({resourceUID, resourceType, resourceTypeInfo, resourceIcon, customRenderComponents, jsonSchema, bodyRenderDefinition, ...props}) {
    const classes = useStyles();
    const [showJSONView, setShowJSONView] = React.useState(false);

    const loadedObject = props[resourceTypeInfo.clientObjectName];
    const loadedObjectJSON = JSON.stringify(loadedObject, null, 2);

    return (
        <React.Fragment>

            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item>
                    &nbsp;
                </Grid>

                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap={'nowrap'}
                    >
                        <Grid item>
                            <h2>
                                <FontAwesomeIcon
                                    icon={resourceIcon}
                                    fixedWidth
                                />
                            </h2>
                        </Grid>

                        <Grid item>
                            <h2>
                                &nbsp;&nbsp;{resourceTypeInfo.displayName}
                            </h2>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item>
                    <Tooltip title={'Formatting'} placement={'top'}>
                        <Checkbox
                            value="uncontrolled"
                            color={'primary'}
                            inputProps={{'aria-label': 'uncontrolled-checkbox'}}
                            checked={!showJSONView}
                            onChange={toggleJSONView}
                        />
                    </Tooltip>
                </Grid>
            </Grid>


            <Collapse in={!showJSONView}>
                <div>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table" size="small">
                            <TableBody>
                                {
                                    Object.keys(bodyRenderDefinition).map(objectKey => {
                                        // console.log('--- current key', objectKey);

                                        // Skip this key if it's on the ignore list
                                        // if (resourceTypeInfo.ignoreKeys.includes(objectKey) || jsonSchema.ui_info.hidden_table_fields.includes(objectKey)) {
                                        if (resourceTypeInfo.ignoreKeys.includes(objectKey)) {
                                            return <React.Fragment key={`gd-${resourceUID}-${objectKey}`}/>;
                                        }

                                        return (
                                            <TableRow key={`gd-${resourceUID}-${objectKey}`}>
                                                <TableCell className={classes.cellStyle}>
                                                    <CopyDataButton
                                                        data={loadedObject[objectKey]}
                                                    />{jsonSchema.properties[objectKey].title}
                                                </TableCell>

                                                <TableCell className={classes.cellStyle}>
                                                    {renderObjectData(RENDER_OBJECT_DATA_MODE_OBJECT, objectKey, loadedObject, jsonSchema, customRenderComponents)}
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box display="flex" justifyContent="flex-end" className={classes.headerMargin}>
                        <ClickToCopy dataToCopy={loadedObjectJSON} displayData={'Copy JSON to clipboard'}/>
                    </Box>
                </div>
            </Collapse>

            <Collapse in={showJSONView}>
                {
                    showJSONView === true &&
                    <React.Fragment>
                        <pre
                            style={{
                                fontSize: '1.0rem',
                                padding: '.25rem .5rem',
                                // overflowX: 'scroll',
                            }}
                        >
                            {loadedObjectJSON}
                        </pre>
                        <br/>
                        <Box display="flex" justifyContent="flex-end">
                            <ClickToCopy dataToCopy={loadedObjectJSON} displayData={'Copy JSON to clipboard'}/>
                        </Box>
                    </React.Fragment>
                }
            </Collapse>

        </React.Fragment>
    );

    function toggleJSONView() {
        setShowJSONView(!showJSONView);
    }

}

export default React.memo(GenericObjectDisplay);