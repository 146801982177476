/*
    Generic UI Reducer
 */

import {APP_EVENT_CLEAR_ALL} from "../actions/appActionTypes";
import {GENERIC_UI_SET_GENERIC_FORM_DIALOG_DATA} from "../actions/GenericUIActionTypes";
import {GENERIC_UI_GENERIC_FORM_DIALOG_INITIAL_STATE} from "../constants/GenericUIConstants";

//
// Initial State
//
const INITIAL_GENERIC_UI_REDUCER_STATE = {
    genericFormDialog: GENERIC_UI_GENERIC_FORM_DIALOG_INITIAL_STATE
};

//
// Reducer
//
export default function genericUIReducer(state = INITIAL_GENERIC_UI_REDUCER_STATE, action) {

    switch (action.type) {

        case APP_EVENT_CLEAR_ALL:
            return INITIAL_GENERIC_UI_REDUCER_STATE;


        //
        // FORMS
        //
        case GENERIC_UI_SET_GENERIC_FORM_DIALOG_DATA:
            return {
                ...state,
                genericFormDialog: {
                    showDialog: action.showDialog,
                    formID: action.formID,
                    dialogTitle: action.dialogTitle,
                    initialFormData: action.initialFormData,
                    formMode: action.formMode
                }
            };

        //
        // DEFAULT
        //
        default:
            return state;
    }
};