/*
    Common form fields so we can quickly change things and reuse them
 */

import TextField from "@material-ui/core/TextField";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CALENDAR_ICON, HIDE_PASSWORD_ICON, SHOW_PASSWORD_ICON} from "../../../constants/commonIcons";
import {KeyboardDatePicker} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from "@material-ui/core/InputLabel";
import {SUPPORTED_US_STATES_DICT} from "../../../constants/usStatesConstants";
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import Checkbox from "@material-ui/core/Checkbox";
import {PRIMARY_BLUE_COLOUR} from "../../../constants/styleConstants";


function hasFieldBeenTouched(formikProps, fieldName) {
    if (!(fieldName in formikProps.touched) || formikProps.touched[fieldName] === false) {
        return false;
    }
    return true;
}

export function extractFormikErrors(formikProps, fieldName) {
    if (!hasFieldBeenTouched(formikProps, fieldName)) {
        return ' ';
    }
    if (formikProps.errors[fieldName] !== undefined) {
        return formikProps.errors[fieldName];
    }
    // if (formikProps.status[fieldName] !== undefined && formikProps.status[fieldName] !== '') {
    //     return formikProps.status[fieldName];
    // }
    return ' ';
}

export function formikFieldHasError(formikProps, fieldName) {
    if (!hasFieldBeenTouched(formikProps, fieldName)) {
        return false;
    }
    if (formikProps.errors[fieldName]) {
        if (formikProps.errors[fieldName]) {
            return true;
        }
    }

    // if (formikProps.status[fieldName]) {
    //     if (formikProps.status[fieldName] !== '') {
    //         return true;
    //     }
    // }

    return false;
}

// TODO: REMOVE THIS AND USE THE GENERIC FIELD
export const UsernameField = formikProps => {
    return (
        <TextField
            name="username"
            helperText={formikProps.touched.username ? formikProps.errors.username : ''}
            error={formikProps.touched.username ? Boolean(formikProps.errors.username) : false}
            label="Username"
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            fullWidth
            onInput={() => formikProps.setFieldTouched('username', true, true)}
        />
    );
};

export const EmailAddressField = formikProps => {
    return (
        <TextField
            name="email_address"
            helperText={extractFormikErrors(formikProps, 'email_address')}
            error={formikFieldHasError(formikProps, 'email_address')}
            label="Email Address"
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            type={'email'}
            fullWidth
            onInput={() => formikProps.setFieldTouched('email_address', true, true)}
        />
    );
};

export const PhoneNumberField = (formikProps,
                                 fieldName='phone_number',
                                 label='Phone Number') => {
    return (
        <MaterialUiPhoneNumber
            name={fieldName}
            value={formikProps.values[fieldName]}
            helperText={extractFormikErrors(formikProps, fieldName)}
            error={formikFieldHasError(formikProps, fieldName)}
            label={label}
            onChange={(newValue) => {
                // Phone number field spits the change out as a new value, so we need to convert to an event for Formik
                formikProps.handleChange({
                    target: {
                        value: newValue,
                        name: fieldName,
                    },
                });
            }}
            onBlur={formikProps.handleBlur}
            fullWidth
            onInput={() => formikProps.setFieldTouched(fieldName, true, true)}
            defaultCountry="us"
        />
    );
};

export const PasswordField = (formikProps,
                              passwordVisible,
                              togglePasswordVisibility,
                              fieldName = 'password',
                              labelValue = 'Password',
                              autoComplete = 'off') => {
    return (
        <TextField
            name={fieldName}
            helperText={extractFormikErrors(formikProps, fieldName)}
            error={formikFieldHasError(formikProps, fieldName)}
            label={labelValue}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            type={passwordVisible ? 'text' : 'password'}
            InputProps={{
                endAdornment: <FontAwesomeIcon
                    icon={passwordVisible ? HIDE_PASSWORD_ICON : SHOW_PASSWORD_ICON}
                    fixedWidth
                    onClick={togglePasswordVisibility}
                    style={{cursor: "pointer"}}
                />
            }}
            fullWidth
            onInput={() => formikProps.setFieldTouched(fieldName, true, true)}
            autoComplete={autoComplete}
        />
    );
};

function getInputProps(startIcon) {
    if (startIcon === undefined) {
        return {};
    }

    return {
        startAdornment: (
            <InputAdornment position="start">
                <FontAwesomeIcon
                    icon={startIcon}
                    style={{}}
                />
            </InputAdornment>
        ),
    };

}

// TODO: We should not set styles here - this should be handled by our parent
export const StandardTextField = (formikProps,
                                  fieldName,
                                  labelValue,
                                  autoComplete = true,
                                  startIcon = undefined,
                                  topMargin = 0) => {
    return (
        <React.Fragment>
            <TextField
                id={'input_field_' + fieldName}
                value={formikProps.values[fieldName]}
                name={fieldName}
                helperText={extractFormikErrors(formikProps, fieldName)}
                error={formikFieldHasError(formikProps, fieldName)}
                label={labelValue}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                type={'text'}
                fullWidth={true}
                style={{marginTop: topMargin}}
                onInput={() => formikProps.setFieldTouched(fieldName, true, true)}
                autoComplete={autoComplete === true ? 'on' : 'off'}
                InputProps={getInputProps(startIcon)}
            />
        </React.Fragment>
    );
};

export function StandardMultiLineTextField(
                                               formikProps,
                                               fieldName,
                                               labelText,
                                               rowCount = 4,
                                               maxRows = 20,
                                               autoComplete = true,
                                               startIcon = undefined,
                                               topMargin = 0
                                           ) {
    return (
        <TextField
            id={'input_field_' + fieldName}
            value={formikProps.values[fieldName]}
            name={fieldName}
            helperText={extractFormikErrors(formikProps, fieldName)}
            error={formikFieldHasError(formikProps, fieldName)}
            label={labelText}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            type={'text'}
            fullWidth={true}
            multiline
            rows={rowCount}
            rowsMax={maxRows}
            style={{marginTop: topMargin}}
            onInput={() => formikProps.setFieldTouched(fieldName, true, true)}
            autoComplete={autoComplete === true ? 'on' : 'off'}
            InputProps={getInputProps(startIcon)}
        />
    );
}

export const StandardCheckBox = (formikProps,
                                  fieldName,
                                  labelValue) => {
    return (
        <React.Fragment>
            <Checkbox
                id={'input_field_' + fieldName}
                value={formikProps.values[fieldName]}
                name={fieldName}
                //helperText={extractFormikErrors(formikProps, fieldName)}
                //error={formikFieldHasError(formikProps, fieldName)}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                onInput={() => formikProps.setFieldTouched(fieldName, true, true)}
                color={'primary'}
            />
        </React.Fragment>
    );
};

export const StandardDatePickerField = (formikProps,
                                        fieldName,
                                        labelValue,
                                        //dateFormat,
                                        //minDate = undefined,
                                        displayIcon = CALENDAR_ICON) => {
    return (
        <KeyboardDatePicker
            name={fieldName}
            id={`date-picker-dialog-${fieldName}`}
            value={formikProps.values[fieldName]}
            helperText={extractFormikErrors(formikProps, fieldName)}
            error={formikFieldHasError(formikProps, fieldName)}
            label={labelValue}
            // Date picker is from an outside lib, so we need to help it get to Formik
            onChange={(value) => {
                formikProps.setFieldValue(fieldName, value);
            }}
            onBlur={formikProps.handleBlur}
            onInput={() => formikProps.setFieldTouched(fieldName, true, true)}
            //format={dateFormat}
            format="MM/DD/YYYY"
            //minDate={minDate}
            keyboardIcon={
                <FontAwesomeIcon
                    icon={displayIcon}
                    size={'1x'}
                    fixedWidth
                    color={PRIMARY_BLUE_COLOUR}
                />
            }
        />
    );
};


export const StandardDatePickerFieldXXX = (formikProps,
                                        fieldName,
                                        labelValue,
                                        dateFormat,
                                        minDate = undefined,
                                        displayIcon = CALENDAR_ICON) => {
    return (
        <KeyboardDatePicker
            name={fieldName}
            value={formikProps.values[fieldName]}
            helperText={extractFormikErrors(formikProps, fieldName)}
            error={formikFieldHasError(formikProps, fieldName)}
            label={labelValue}
            // Date picker is from an outside lib, so we need to help it get to Formik
            onChange={value => {
                formikProps.setFieldValue(fieldName, value);
            }}
            onBlur={formikProps.handleBlur}
            //onInput={() => formikProps.setFieldTouched(fieldName, true, true)}
            //format={dateFormat}
            format="MM/DD/YYYY"
            minDate={minDate}
            keyboardIcon={
                <FontAwesomeIcon
                    icon={displayIcon}
                    size={'1x'}
                    style={{}}
                    fixedWidth
                    color={PRIMARY_BLUE_COLOUR}
                />
            }
        />
    );
};

// TODO: This can be removed and replaced with the generic select below
export const StandardUSStateSelectField = (formikProps,
                                           fieldName,
                                           labelValue) => {
    return (
        <FormControl
            fullWidth
            error={formikFieldHasError(formikProps, fieldName)}
            //style={{minWidth: 250}}
        >
            <InputLabel htmlFor={fieldName}>{labelValue}</InputLabel>
            <Select
                name={fieldName}
                value={formikProps.values[fieldName]}
                input={<Input name={fieldName} id={fieldName}/>}
                onInput={() => formikProps.setFieldTouched(fieldName, true, true)}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={formikFieldHasError(formikProps, fieldName)}
            >
                <MenuItem value=""><em>None</em></MenuItem>
                {Object.keys(SUPPORTED_US_STATES_DICT).map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
            </Select>
            <FormHelperText>{extractFormikErrors(formikProps, fieldName)}</FormHelperText>
        </FormControl>
    );
};

export const StandardSelectField = (formikProps,
                                    fieldName,
                                    labelValue,
                                    valuesArray,
                                    valuesArrayAreMenuItems=false) => {
    return (
        <FormControl
            error={formikFieldHasError(formikProps, fieldName)}
            fullWidth
        >
            <InputLabel htmlFor={fieldName}>{labelValue}</InputLabel>
            <Select
                name={fieldName}
                value={formikProps.values[fieldName]}
                input={<Input name={fieldName} id={fieldName}/>}
                // style={{
                //     minWidth: 250
                // }}
                fullWidth
                onInput={() => formikProps.setFieldTouched(fieldName, true, true)}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={formikFieldHasError(formikProps, fieldName)}
            >
                <MenuItem value=""><em>None</em></MenuItem>
                {
                    // If the array needs to be turned into menu items
                    valuesArrayAreMenuItems === false &&
                    valuesArray.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                }
                {
                    // if the array is already an array of menu items
                    valuesArrayAreMenuItems === true &&
                    valuesArray.map(x => x)
                }
            </Select>
            <FormHelperText>{extractFormikErrors(formikProps, fieldName)}</FormHelperText>
        </FormControl>
    );
};

