/*
    Admin controls for a deposit
 */
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import {faAngel, faSkullCrossbones} from "@fortawesome/pro-regular-svg-icons";
import Collapse from "@material-ui/core/Collapse";
import PaymentClearedAndInterestControls from "./admin_buttons/PaymentClearedAndInterestControls";
import DepositAdminButtonResendInvite from "./admin_buttons/DepositAdminButtonResendInvite";
import DepositAdminButtonRefreshPermissions from "./admin_buttons/DepositAdminButtonRefreshPermissions";
import DepositAdminButtonCancelDeposit from "./admin_buttons/DepositAdminButtonCancelDeposit";
import Grid from "@material-ui/core/Grid";
import DepositInviteAdminButtonChangeEmail from "./admin_buttons/DepositInviteAdminButtonChangeEmail";
import DepositAdminButtonResetToPending from "./admin_buttons/DepositAdminButtonResetToPending";
import EditEnabledDisabledComplianceDialog from "./EditEnabledDisabledComplianceDialog";
import DetailedUserAccountInfoLink from "../links/DetailedUserAccountInfoLink";
import SendRevertedDepositEmailsButton from "./admin_buttons/SendRevertedDepositEmailsButton";
import IF from "../../clientShared/components/utility/IFComponent";
import {Alert} from "@material-ui/lab";
import RhinoEditDepositProperty from "./RhinoEditDepositProperty";
import RhinoEditDepositTitle from "./RhinoEditDepositTitle";
import RhinoDecreaseDepositAmount from "./RhinoDecreaseDepositAmount";
import AdminReopenDepositButton from "./AdminReopenDepositButton";

// TODO: Show funds location

function DepositAdminControls({deposit, startExpanded = false}) {

    const [controlsEnabled, setControlsEnabled] = React.useState(startExpanded);

    return (
        <React.Fragment>
            <IF condition={!startExpanded}>
                Admin Controls<br/>
                <Button
                    variant="contained"
                    color={controlsEnabled === false ? "secondary" : "primary"}
                    onClick={toggleAdminControls}
                    style={{marginBottom: 18}}
                >
                    <FontAwesomeIcon
                        icon={controlsEnabled === false ? faSkullCrossbones : faAngel}
                        fixedWidth
                    />
                    &nbsp;{controlsEnabled === false ? 'Show Admin Controls' : 'Hide Admin Controls'}
                </Button>
            </IF>

            <Collapse in={controlsEnabled}>
                <Alert severity="info" style={{marginBottom: 18}}>
                    Admin actions on a deposit can move funds, impact interest or have knock on consequences, proceed
                    with caution.
                </Alert>

                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item>
                        <DetailedUserAccountInfoLink
                            accountUID={deposit.landlord_account_uid}
                            linkText={'Landlord Detailed Account Info'}
                        />
                        <br/>
                        <DetailedUserAccountInfoLink
                            accountUID={deposit.tenant_account_uid}
                            linkText={'Tenant Detailed Account Info'}
                        />
                    </Grid>

                    <Grid item>
                        <EditEnabledDisabledComplianceDialog deposit={deposit} completedMode={true}/>
                    </Grid>

                    <Grid item>
                        <SendRevertedDepositEmailsButton deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <PaymentClearedAndInterestControls deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <DepositAdminButtonResendInvite deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <DepositAdminButtonRefreshPermissions deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <DepositAdminButtonCancelDeposit deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <DepositInviteAdminButtonChangeEmail deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <DepositAdminButtonResetToPending deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <RhinoEditDepositTitle deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <RhinoEditDepositProperty deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <RhinoDecreaseDepositAmount deposit={deposit}/>
                    </Grid>

                    <Grid item>
                        <AdminReopenDepositButton deposit={deposit}/>
                    </Grid>

                </Grid>
            </Collapse>

        </React.Fragment>
    );

    function toggleAdminControls() {
        setControlsEnabled(!controlsEnabled);
    }
}


export default DepositAdminControls;
