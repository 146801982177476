import {OBJECT_KEY_DEPOSIFY_TAGS} from "../../../clientShared/constants/objects/objectKeys";
import RenderDeposifyTags from "../../tags/DeposifyTags";

// Used by default and applied to all objects when rendering
// NOTE: These defaults can be overwritten by passing custom render components with the same name
export const DEFAULT_CUSTOM_RENDER_COMPONENTS = {
    [OBJECT_KEY_DEPOSIFY_TAGS]: {
        dataPropName: 'deposifyTags',
        renderComp: RenderDeposifyTags
    },
};