import React, {Component} from 'react'
import * as Sentry from "@sentry/browser";
import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import {withSnackbar} from 'notistack';
import {MAX_UPLOAD_FILE_SIZE_IN_BYTES} from "../../../constants/dataConstants";
import {STORAGE_FILE_TYPE_ATTACHMENT} from "../../../constants/storageConstants";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {addFileToAttachedStorageFiles} from "../../../actions/storageActions";
import withStyles from "@material-ui/core/styles/withStyles";
import "./filePond.css";
import {axiosInstance} from "../../../utilities/axiosInstance";
import {getAdminPath} from "../../../api/adminAPI";


registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateSize);

const STATUS_COMPLETE = 5;

const useStyles = theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    filepond: {
        '& Root': {
            maxHeight: '10em'
        }
    }

});


// TODO: Convert this to a function so we can use the snackbar
// TODO: Resource UID for the file pond setup
class FilePondComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        };
    }

    doTheUpload(fieldName, file, metadata, load, error, progress, abort) {
        // TODO: Make sure this is coming in all the time - or disable the whole thing
        const fileType = this.props.fileType === undefined ? STORAGE_FILE_TYPE_ATTACHMENT : this.props.fileType;

        const postValues = {
            file_name: file.name,
            file_type: fileType,
            file_size_in_bytes: file.size
        };

        // Set this up since the onLoad function won't have access to this.props
        const uploadingAccountUID = this.props.uploadingAccountUID;
        const createPath = getAdminPath(`storage/file/upload/create/by/account/${uploadingAccountUID}`);
        const createPromise = axiosInstance.post(createPath, postValues);

        // TODO: File count limit, file size limit, customize the zone
        createPromise
            .then(function (response) {
                const fileUID = response.data.file_uid;
                const uploadURL = response.data.url;

                const request = new XMLHttpRequest();

                request.onerror = function () {
                    error('Failed to upload file');
                };

                //request.overrideMimeType("application/octet-stream");
                request.open('PUT', uploadURL);

                // Should call the progress method to update the progress to 100% before calling load
                // Setting computable to false switches the loading indicator to infinite mode
                request.upload.onprogress = (e) => {
                    progress(e.lengthComputable, e.loaded, e.total);
                };

                // Should call the load method when done and pass the returned server file id
                // this server file id is then used later on when reverting or restoring a file
                // so your server knows which file to return without exposing that info to the client
                request.onload = function () {
                    if (request.status >= 200 && request.status < 300) {
                        // the load method accepts either a string (id) or an object
                        //load(request.responseText);
                        load(fileUID);

                        const putPath = `storage/file/${fileUID}/upload/complete/by/account/${uploadingAccountUID}`;

                        // Tell the storage service that we are done uploading
                        axiosInstance.put(getAdminPath(putPath))
                            .then(function (response) {

                            })
                            .catch(function (markError) {
                                Sentry.withScope(scope => {
                                    scope.setExtra("response", markError.response);
                                    Sentry.captureException(markError);
                                });

                                // These error calls are important - they let filepond show the error
                                error('Failed to complete file upload');
                            });

                    } else {
                        // Can call the error method if something is wrong, should exit after
                        error('oh no');
                    }
                };

                request.send(file);

                // Should expose an abort method so the request can be cancelled
                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        request.abort();

                        // Let FilePond know the request has been cancelled
                        abort();
                    }
                };


            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
                abort();
            });
    }

    handleInit() {
        if (this.props.setParentRef !== undefined) {
            this.props.setParentRef(this.pond);
        }
    }

    handleChange(files) {
        this.setState({
            files: files
        });
    }

    handleSubmit = (values) => {

        // const filePondFiles = this.pond.getFiles();
        // for (const fileIndex in filePondFiles) {
        //     const theFile = filePondFiles[fileIndex];
        // }
    };

    areFilesUploading() {
        if (this.pond === undefined || this.props.uploadingStateCallback === undefined) {
            return;
        }

        const filesAreUploading = this.pond.getFiles().filter(f => f.status !== STATUS_COMPLETE).length > 0;
        this.props.uploadingStateCallback(filesAreUploading);
        return filesAreUploading;
    }

    onProcessFiles(elError, elFile) {
        const filesAreUploading = this.areFilesUploading();

        if (elFile === undefined) {
            return;
        }
        if (elFile.status !== STATUS_COMPLETE) {
            return;
        }

        const removeAfterUpload = this.props.removeAfterUpload === undefined ? false : this.props.removeAfterUpload;
        if (removeAfterUpload === true) {
            // Remove the file with a bit of delay so it looks nicer for the user
            setTimeout(() => {
                this.pond.removeFile(elFile.id);
            }, 1000);
        }

        // this.props.readStorageFile(elFile.serverId);
        //
        // const attachedFilesUID = this.props.attachedFilesUID;
        // if (attachedFilesUID !== undefined) {
        //     this.props.addFileToAttachedStorageFiles(attachedFilesUID, elFile.serverId);
        // }

        // if(this.props.fileUploadCompleteCallback !== undefined){
        //     this.props.fileUploadCompleteCallback(elFile.serverId);
        // }

        // If all file uploading is complete make the callback
        if (filesAreUploading === false) {
            // Grab all the files so we can extract the serverId (file_uid)
            const filePondFiles = this.pond.getFiles();
            let allFileUIDs = [];

            filePondFiles.map(fpFile => {
                // Add the file_uid to the list
                allFileUIDs.push(fpFile.serverId);

                // Set a timeout for this file to be removed
                setTimeout(() => {
                    this.pond.removeFile(fpFile.id);
                }, 1500);
            });

            // Callback with the file uid list
            this.props.allFileUploadsCompleteCallback(allFileUIDs);
        }
    }

    onProcessFileStart(theFile) {
        this.areFilesUploading();
    }

    render() {
        return (
            <React.Fragment>
                <div
                    style={{
                        //minWidth: 300
                    }}
                >
                    <FilePond
                        disabled={this.props.uploadDisabled}
                        style={{maxHeight: 400}}
                        ref={ref => this.pond = ref}
                        files={this.state.files}
                        allowMultiple={true}
                        maxFiles={10}
                        maxFileSize={MAX_UPLOAD_FILE_SIZE_IN_BYTES}
                        oninit={() => this.handleInit()}
                        onupdatefiles={(fileItems) => {
                            // Set current file objects to this.state
                            this.setState({
                                files: fileItems.map(fileItem => fileItem.file)
                            });
                        }}
                        //onremovefile={(error, file)=> {console.log(error, file)}}
                        server={
                            {
                                url: '/api/storage/uploader',
                                process: (fieldName, file, metadata, load, error, progress, abort) => {
                                    this.doTheUpload(fieldName, file, metadata, load, error, progress, abort);
                                },
                                // This will prevent the delete call from happening on the server
                                revert: null
                            }
                        }
                        onaddfilestart={(file) => this.onProcessFileStart()}
                        onprocessfile={(error, file) => this.onProcessFiles(error, file)}
                        //instantUpload={false}
                        //allowImagePreview={false}
                        imagePreviewMaxHeight={96}
                        //beforeRemoveFile={(item)=>{ console.log('xxxx', item); return false }}
                    />

                </div>

            </React.Fragment>
        )
    }
}


const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        addFileToAttachedStorageFiles: addFileToAttachedStorageFiles
    }, dispatch);
};

export default connect(null, mapActionsToProps)(withSnackbar(withStyles(useStyles)(FilePondComp)));