/*

 */
import * as Sentry from "@sentry/browser";
import {
    axiosInstance, getAuthHeader, getRefreshHeader,
    updateAuthHeader
} from '../utilities/axiosInstance'
import {updateUser} from './userActions'
import {history} from "../history/history";
import {
    AUTH_ACTION_SET_AUTH_EXPIRE_TIMESTAMP,
    AUTH_ACTION_SET_STARTUP_AUTH_CHECK_COMPLETE,
    AUTH_ACTION_SET_USER_LOGGED_IN, AUTH_ACTION_SET_USER_LOGGING_OUT
} from "../reducers/authReducers";
import {
    API_ROUTE_AUTH_ACCESS_TOKEN_REFRESH, API_ROUTE_AUTH_CHECK_AUTH_STATUS,
    API_ROUTE_AUTH_LOGOUT, API_ROUTE_AUTH_LOGOUT_PRE
} from "../constants/apiRouteConstants";
import {
    API_AUTH_ACCESS_TOKEN, API_AUTH_HEADER_KEY,
    API_AUTH_REFRESH_TOKEN
} from '../constants/apiConstants'
import {
    APP_ROUTE_HOME,
    APP_ROUTE_LOGIN
} from "../constants/appRoutes";
import {accountLoggedInAction, accountLoggedOutAction, appStartUp} from "./appActions";
import {changePrimaryNavTabAction} from "../actions/appActions";
import {appActionSetLoggedOut, clearAllState} from "../reducers/appReducers";
import {NULL_UUID_STRING} from "../constants/dataConstants";
import {
    getAccessTokenFromLocalStorage,
    getRefreshTokenFromLocalStorage, getSecondsToTokenExpiry, getTokenExpireTimestamp,
    hasTokenExpired, setAccessAndRefreshTokens, setLocalStorageAccessToken
} from "../utilities/authUtilities";
import {SECONDS_LIMIT_CHECK_FOR_STARTUP_ACCESS_TOKEN} from "../constants/authConstants";
import {LOG_USER_OUT} from "./userActionTypes";
import {adminActionStartupLoad} from "./adminActions";


/*
function loginErrorAction(errorMessage) {
    return {
        type: LOGIN_FAILURE,
        isFetching: false,
        errorMessage: errorMessage
    };
}

function authActionSetAuthActionHappening(authActionHappening) {
    return {
        type: AUTH_ACTION_SET_AUTH_ACTION_HAPPENING,
        authActionHappening: authActionHappening
    };
}
*/

function authActionSetAuthExpireTimestamp(authExpireTimestamp) {
    return {
        type: AUTH_ACTION_SET_AUTH_EXPIRE_TIMESTAMP,
        authExpireTimestamp: authExpireTimestamp
    };
}

function authActionSetUserLoggedIn(userLoggedIn) {
    return {
        type: AUTH_ACTION_SET_USER_LOGGED_IN,
        userLoggedIn: userLoggedIn
    };
}

function authActionSetUserLoggingOut(userLoggingOut) {
    return {
        type: AUTH_ACTION_SET_USER_LOGGING_OUT,
        userLoggingOut: userLoggingOut
    };
}

function authActionSetStartupAuthCheckComplete(startupAuthCheckComplete) {
    return {
        type: AUTH_ACTION_SET_STARTUP_AUTH_CHECK_COMPLETE,
        startupAuthCheckComplete: startupAuthCheckComplete
    };
}


export function authActionAppStartupAuthCheck() {
    return dispatch => {
        // Reset the state
        dispatch(authActionSetStartupAuthCheckComplete(false));

        // Grab the tokens from local storage, if either are missing then the user can't be logged in
        const accessToken = getAccessTokenFromLocalStorage();
        const refreshToken = getRefreshTokenFromLocalStorage();
        if (accessToken === null || refreshToken === null) {
            // No token means nothing is valid, boot to login
            dispatch(authActionSetStartupAuthCheckComplete(true));
            return dispatch(postLogoutAction());
        }

        // Check if both the tokens have expired
        const accessTokenHasExpired = hasTokenExpired(accessToken);
        const refreshTokenHasExpired = hasTokenExpired(refreshToken);
        if (accessTokenHasExpired === true && refreshTokenHasExpired === true) {
            // If both tokens have expired, boot to login
            dispatch(authActionSetStartupAuthCheckComplete(true));
            return dispatch(postLogoutAction());
        }

        const accessTokenExpireTimeInSeconds = getSecondsToTokenExpiry(accessToken);

        // If the access token is valid, but the refresh will die soon, boot to login
        if (refreshTokenHasExpired === true &&
            accessTokenExpireTimeInSeconds < SECONDS_LIMIT_CHECK_FOR_STARTUP_ACCESS_TOKEN) {
            dispatch(authActionSetStartupAuthCheckComplete(true));
            return dispatch(logUserOutAction());
        }

        if (accessTokenHasExpired === true && refreshTokenHasExpired === false) {
            axiosInstance.defaults.headers[API_AUTH_HEADER_KEY] = getRefreshHeader();
            axiosInstance.post(API_ROUTE_AUTH_ACCESS_TOKEN_REFRESH)
                .then(({data}) => {
                    // Update local storage
                    setLocalStorageAccessToken(data.access_token);
                    // Update the current headers and the original request headers
                    updateAuthHeader();

                    dispatch(authActionSetStartupAuthCheckComplete(true));
                    // Set the access token expire timestamp so we can refresh when it gets close
                    dispatch(authActionSetAuthExpireTimestamp(getTokenExpireTimestamp(accessToken)));

                    dispatch(appStartUp());

                    dispatch(authActionSetUserLoggedIn(true));

                })
                .catch(error => {
                    axiosInstance.defaults.headers[API_AUTH_HEADER_KEY] = getAuthHeader();
                    Sentry.withScope(scope => {
                        scope.setExtra("response", error.response);
                        Sentry.captureException(error);
                    });

                    dispatch(authActionSetStartupAuthCheckComplete(true));
                    return dispatch(postLogoutAction());
                });
        }

        axiosInstance.defaults.headers[API_AUTH_HEADER_KEY] = getAuthHeader();
        axiosInstance.get(API_ROUTE_AUTH_CHECK_AUTH_STATUS)
            .then(({data}) => {
                dispatch(authActionSetStartupAuthCheckComplete(true));
                // Set the access token expire timestamp so we can refresh when it gets close
                dispatch(authActionSetAuthExpireTimestamp(getTokenExpireTimestamp(accessToken)));

                dispatch(appStartUp());
                dispatch(authActionSetUserLoggedIn(true));
                dispatch(adminActionStartupLoad());
            })
            .catch(error => {
                // Set as complete and kick out to the login screen
                dispatch(authActionSetStartupAuthCheckComplete(true));
                return dispatch(postLogoutAction());
            });
    }
}


export const postLoginAction = (user_account, access_token, refresh_token, postLoginRoute) => {
    return dispatch => {
        // Update local storage tokens
        setAccessAndRefreshTokens(access_token, refresh_token);
        // Update the auth headers for the Axios instance
        updateAuthHeader();

        dispatch(authActionSetAuthExpireTimestamp(getTokenExpireTimestamp(access_token)));

        dispatch(authActionSetUserLoggingOut(false));
        dispatch(authActionSetUserLoggedIn(true));

        // Update the user account
        dispatch(updateUser(user_account));
        dispatch(accountLoggedInAction());
        dispatch(changePrimaryNavTabAction(APP_ROUTE_HOME));

        dispatch(appStartUp());

        dispatch(adminActionStartupLoad());

        console.log('POST AUTH', postLoginRoute.pathname);

        // Redirect to the url that was passed in - if that URL was login, then go to home
        if(postLoginRoute.pathname === APP_ROUTE_LOGIN){
            history.push(APP_ROUTE_HOME);
        }
        else {
            history.push(postLoginRoute);
        }
    }
};


export const logUserOutAction = () => {
    return dispatch => {
        // Set this first to give components a heads up
        // Live stream uses this to perform a graceful shutdown before the tokens get invalidated
        // dispatch(authActionSetUserLoggingOut(true));

        axiosInstance.delete(API_ROUTE_AUTH_LOGOUT_PRE)
            .then(function (response) {

                // Trigger with a small delay
                setTimeout(() => {
                    axiosInstance.delete(API_ROUTE_AUTH_LOGOUT)
                        .then(function (response) {
                            dispatch(postLogoutAction());
                        })
                        .catch(function (error) {
                            Sentry.withScope(scope => {
                                scope.setExtra("response", error.response);
                                Sentry.captureException(error);
                            });
                            // If the server fails - so be it - still terminate the local token
                            dispatch(postLogoutAction());
                        });
                }, 300);

            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });
                // If the server fails - so be it - still terminate the local token
                dispatch(postLogoutAction());
            });

        // TODO: Is this still used - this is old auth code from the first prototype
        return {
            type: LOG_USER_OUT,
            payload: {}
        }
    }
};

export const postLogoutAction = () => {
    return dispatch => {
        // Clear the local storage and update Axios instance headers
        localStorage.removeItem(API_AUTH_ACCESS_TOKEN);
        localStorage.removeItem(API_AUTH_REFRESH_TOKEN);
        updateAuthHeader();

        // TODO: This function can be removed as the auth logged in flag now takes over
        dispatch(accountLoggedOutAction());
        //dispatch(changePrimaryNavTabAction(APP_ROUTE_HOME));

        dispatch(clearAllState());

        Sentry.setUser({account_uid: NULL_UUID_STRING, username: 'anonymous'});

        dispatch(authActionSetUserLoggedIn(false));

        dispatch(appActionSetLoggedOut());

        dispatch(authActionSetUserLoggingOut(false));

        // Redirect to the login page
        history.push(APP_ROUTE_LOGIN);
    }
};