/*

 */

import React from 'react';
import QuickResourceInfoDisplay from "../../utilities/displayAreas/QuickResouceInfoDisplay";
import AdminPermissionListingDisplay from "./adminDisplayPermissionListingDisplay";
import QuickTimestampDisplay from "../../utilities/displayAreas/QuickTimestampDisplay";
import VisualizeContainerLoadingIndicator from "../../../clientShared/components/utility/progress/VisualizeContainerLoadingIndicator";
import {ADMIN_ICON_PERMISSIONS} from "../adminIcons";
import AdminDisplayCard from "./adminDisplayCard";

export default function AdminDisplayPermissionEntry({loading, error, permissionEntry, ...props}) {

    if (loading === true || error === true || permissionEntry === undefined) {
        return (
            <VisualizeContainerLoadingIndicator
                loadingResource={permissionEntry}
                displayName={'Permission entry'}
                {...props}
            />
        );
    }

    return (
        <AdminDisplayCard
            cardTitle={'Permission Entry'}
            cardSubheader={''}
            headerIcon={ADMIN_ICON_PERMISSIONS}
            jsonObject={permissionEntry}
        >
            <QuickResourceInfoDisplay
                resourceUID={permissionEntry.resource_uid}
                resourceType={permissionEntry.resource_type}
                showHorizontal={true}
            />
            <br/>

            <AdminPermissionListingDisplay permissionListing={permissionEntry.permissions_listing}/>
            <br/>

            <QuickTimestampDisplay timestamp={permissionEntry.created_timestamp}/>
            <br/>

            <QuickTimestampDisplay timestamp={permissionEntry.last_updated_timestamp}/>
        </AdminDisplayCard>
    );

}
