import React from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {PMC_ACCOUNTS} from "../../../constants/PMCConstants";

export default function PMCAutoSuggest({valueChangeCallback, selectedValue, labelText= 'PMC', disabled=false, ...props}) {

    return (
        <Autocomplete
            options={Object.keys(PMC_ACCOUNTS)}
            getOptionLabel={option => option}
            id="pmc-auto-select"
            autoSelect
            autoHighlight
            value={selectedValue}
            onChange={handleChange}
            renderInput={params => (
                <TextField
                    {...params}
                    label={labelText}
                    margin="normal"
                    fullWidth
                />
            )}
            style={{minWidth: 220}}
            disabled={disabled}
        />
    );

    function handleChange(event, value){
        valueChangeCallback(value);
    }
}