import React, {useEffect, useState} from 'react';
import BulkBankTSV from "./rhinoBulkBankTSVData";
import {getAdminPath} from "../../../api/adminAPI";
import {axiosInstance} from "../../../utilities/axiosInstance";
import toast from "react-hot-toast";
import RhinoBulkBankInfo from "./rhinoBulkBankInfo";
import RhinoBankBulkUploadArea from "./rhinoBankBulkUploadArea";
import IF from "../../../clientShared/components/utility/IFComponent";
import Collapse from "@material-ui/core/Collapse";
import {Alert} from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import RhinoBankBulkUploadHeaderIssues from "./rhinoBankBulkUploadHeaderIssues";
import LinearProgress from "@material-ui/core/LinearProgress";
import HomeLink from "../../admin/buttons/linkButtons/HomeLink";

const InnerDataDisplay = ({parsedData}) => {
    const [validationResults, setValidationResults] = useState({});
    const [validationError, setValidationError] = useState(false);
    const hasValidationResults = Object.keys(validationResults).length > 0;

    useEffect(() => {
        const fetchData = async () => {
            let bankTSVData = new BulkBankTSV({});
            bankTSVData.parsedData = parsedData;
            const postJSON = bankTSVData.generateValidationJSON()
            const callURL = getAdminPath(`jeff/rhino/bulk/import/bank/validate`);

            // console.log("--- sending for validation", postJSON);

            try {
                const response = await axiosInstance.post(callURL, postJSON);
                setValidationResults(response.data);
                setValidationError(false);
            } catch (e) {
                console.error(e);
                toast.error('Failed to validate data');
                setValidationError(true);
            }
        };

        if (!hasValidationResults) {
            void fetchData();
        }

    }, []);

    if (validationError) {
        return (
            <Alert severity="error">
                Failed to validate the data on the server.
                <br/>
                See the browser console window for an error message.
                <br/>
                An error should have been logged on the server, please contact engineering about this.
            </Alert>
        );
    }

    if (!hasValidationResults) {
        return <LinearProgress/>;
    }

    return <RhinoBulkBankInfo parsedData={parsedData} validationResults={validationResults}/>;
};


const RhinoBankBulkPage = () => {
    const [rawFileContent, setRawFileContent] = useState('');
    const [parsedData, setParsedData] = useState({});
    const [parsingError, setParsingError] = useState(false);
    const [parsedTSVObject, setParsedTSVObject] = useState(null);
    const hasRawFileData = rawFileContent !== '';
    const hasData = Object.keys(parsedData).length > 0;

    const headerOkay = parsedTSVObject ? parsedTSVObject.headerMapping.meta.hasAllRequiredColumns : true;
    const showHeaderProblem = !headerOkay && parsedTSVObject;
    const showData = hasData && headerOkay && !showHeaderProblem && !parsingError;

    // todo: loading indicator for large files

    useEffect(() => {
        if (!hasData && hasRawFileData) {
            try {
                let bankTSVData = new BulkBankTSV(rawFileContent);
                bankTSVData.parse();
                setParsedData(bankTSVData.parsedData);
                // console.log("----------------------------")
                // console.log(bankTSVData)
                // console.log(bankTSVData.headerMapping)
                setParsedTSVObject(bankTSVData);
            } catch (e) {
                console.error(e);
                setParsingError(true);
            }
        }
    }, [rawFileContent]);


    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
            style={{margin: 12}}
        >
            <Grid item>
                {/* we need to wipe this component state */}
                <HomeLink useHref={true}/>
            </Grid>

            <IF condition={!showData}>
                <Grid item>
                    <RhinoBankBulkUploadArea handleFileContent={handleFileContent}/>
                </Grid>
            </IF>

            <Grid item>
                <Collapse in={parsingError}>
                    <Alert severity="error">
                        Failed to parse the file, see the browser console window for more info
                    </Alert>
                </Collapse>
            </Grid>

            <IF condition={showHeaderProblem}>
                <Grid item>
                    <RhinoBankBulkUploadHeaderIssues headerMapping={parsedTSVObject?.headerMapping}/>
                </Grid>
            </IF>

            <IF condition={showData}>
                <Grid item>
                    <InnerDataDisplay parsedData={parsedData}/>
                </Grid>
            </IF>
        </Grid>
    );

    function handleFileContent(fileContent) {
        setRawFileContent(fileContent);
    }

};

export default RhinoBankBulkPage;