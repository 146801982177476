/*

 */

import React from 'react';
import Grid from "@material-ui/core/Grid";
import SetAdminGhostButton from "../admin/buttons/SetAdminGhostButton";
import {UIDLinkOrCopy} from "../admin/buttons/linkButtons/UIDLinkOrCopy";
import {RESOURCE_TYPE_USER_ACCOUNT} from "../../autoGenerated/constants/resourceTypeConstants";

const UserAccountGhostAndAccountUIDAtom = ({accountUID}) => {
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <UIDLinkOrCopy uid={accountUID} resourceType={RESOURCE_TYPE_USER_ACCOUNT} />
            </Grid>

            <Grid item>
                <SetAdminGhostButton targetAccountUID={accountUID}/>
            </Grid>

        </Grid>
    );
}

export default UserAccountGhostAndAccountUIDAtom;