/*

 */

export const SYSTEM_STATS_ID_DAILY_INTEREST_STATS = 100;
export const SYSTEM_STATS_ID_COMPLIANCE_RUN_STATS = 101;
export const SYSTEM_STATS_ID_QUARTERLY_INTEREST_COMPLIANCE_RUN_STATS = 102;
export const SYSTEM_STATS_ID_DAILY_DEPOSIT_STATE_STATS = 103;
export const SYSTEM_STATS_ID_DAILY_PAYMENT_STATS = 104;
export const SYSTEM_STATS_ID_DAILY_WITHDRAWAL_STATS = 105;
export const SYSTEM_STATS_ID_DAILY_USER_ACCOUNT_STATS = 106;
export const SYSTEM_STATS_ID_DAILY_GENERAL_DEPOSIT_STATS = 107;
export const SYSTEM_STATS_ID_DAILY_STORAGE_STATS = 108;
export const SYSTEM_STATS_ID_DAILY_MESSAGING_STATS = 109;
export const SYSTEM_STATS_ID_DAILY_DEPOSIT_DETAILED_STATS = 110;