/*

 */
import * as Sentry from "@sentry/browser";
import {loadingActionSetLoadingIndicator} from "../../actions/loadingActions";
import {
    LOADING_INDICATOR_STATE_ERROR,
    LOADING_INDICATOR_STATE_LOADED,
    LOADING_INDICATOR_STATE_LOADING
} from "../../constants/loadingConstants";
import axiosRequest from "../utilities/axios/AxiosRequest";
import {GENERAL_LOADER_FAUX_RESOURCE_TYPE} from "../constants/GeneralLoaderConstants";
import {GENERAL_LOADER_CLEAR_DATA, GENERAL_LOADER_UPDATE_DATA} from "./GeneralLoaderActionTypes";


export function generalLoaderActionUpdateLoadedData(loadedData, uniqueID) {
    return {
        type: GENERAL_LOADER_UPDATE_DATA,
        loadedData: loadedData,
        uniqueID: uniqueID
    }
}

export function generalLoaderActionClearLoadedData(uniqueID) {
    return {
        type: GENERAL_LOADER_CLEAR_DATA,
        uniqueID: uniqueID
    }
}

export function generalLoaderActionLoadFormFromServer(uniqueID, loadURL) {
    return function (dispatch) {
        // Set the loading state
        dispatch(loadingActionSetLoadingIndicator(uniqueID, GENERAL_LOADER_FAUX_RESOURCE_TYPE, LOADING_INDICATOR_STATE_LOADING, undefined));

        // Make the call
        return axiosRequest({url: loadURL, method: 'GET'})
            .then(function (response) {
                dispatch(generalLoaderActionUpdateLoadedData(response, uniqueID));

                dispatch(loadingActionSetLoadingIndicator(uniqueID, GENERAL_LOADER_FAUX_RESOURCE_TYPE, LOADING_INDICATOR_STATE_LOADED, undefined));

            })
            .catch(function (error) {
                //handleLoadingIndicatorError(resourceUID, resourceType, error, dispatch);
                // Tell Sentry about the error
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    scope.setExtra("uniqueID", uniqueID);
                    Sentry.captureException(error);
                });

                // Set the loading state error for this deposit
                if (error.response) {
                    dispatch(loadingActionSetLoadingIndicator(uniqueID, GENERAL_LOADER_FAUX_RESOURCE_TYPE, LOADING_INDICATOR_STATE_ERROR, error.response));
                } else {
                    dispatch(loadingActionSetLoadingIndicator(uniqueID, GENERAL_LOADER_FAUX_RESOURCE_TYPE, LOADING_INDICATOR_STATE_ERROR, error));
                }
            })
    }
}