import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Alert} from "@material-ui/lab";
import IF from "../../../clientShared/components/utility/IFComponent";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/pro-regular-svg-icons";
import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";
import BulkBankTSV from "./rhinoBulkBankTSVData";
import {axiosInstance} from "../../../utilities/axiosInstance";
import toast from "react-hot-toast";
import {getAdminPath} from "../../../api/adminAPI";
import HomeLink from "../../../clientShared/components/links/homeLink";
import {NULL_UUID_STRING_HEX} from "../../../constants/dataConstants";


const RhinoBulkBankImportControls = ({parsedData, validationResults, successCallback}) => {
    const [showSpinner, setShowSpinner] = useState(false);

    if (Object.keys(parsedData).length === 0 || Object.keys(validationResults).length === 0) {
        return <>Loading...</>;
    }

    const genValidationErrors = validationResults.errors.length > 0;
    const anyBankErrors = Object.values(validationResults.bank_accounts).some(account => Object.keys(account).length > 0);
    const anyRowErrors = Object.values(parsedData.rowInfo).some(currentRow => currentRow.errors.length > 0);
    const anyMissingProperties = Object.values(validationResults.rhino_property_ids).some(currentRow => currentRow === NULL_UUID_STRING_HEX);
    const hasPartnerAccount = Object.keys(validationResults.partner_account).length > 0;
    const canSubmit = !parsedData.hasErrors && !genValidationErrors && !anyBankErrors && !anyRowErrors && !anyMissingProperties && hasPartnerAccount;

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            <IF condition={!hasPartnerAccount}>
                <Grid item>
                    <Alert severity="warning">
                        Partner account not found, please check the ID, and make sure Portal is syncing with Deposify.
                        <br/>
                        You can use the Deposify admin search box to check via name, email and other details.
                    </Alert>
                </Grid>
            </IF>

            <IF condition={anyBankErrors}>
                <Grid item>
                    <Alert severity="warning">
                        Bank account errors; Please see the <b>Bank Accounts</b> section above for details on the issue,
                        once fixed you can re-parse the data for import
                    </Alert>
                </Grid>
            </IF>

            <IF condition={anyRowErrors}>
                <Grid item>
                    <Alert severity="warning">
                        Property errors; Please see the <b>Properties</b> section above for details on the issue, once
                        fixed you can re-parse the data for import
                    </Alert>
                </Grid>
            </IF>

            <IF condition={anyMissingProperties}>
                <Grid item>
                    <Alert severity="warning">
                        Property errors; Deposify properties have not been found, please make sure Portal is syncing the expected properties. The Portal Property ID must match, and the type must be 'building'.
                    </Alert>
                </Grid>
            </IF>

            <IF condition={genValidationErrors}>
                {
                    validationResults.errors.map((currentError, errorIndex) => {
                        return (
                            <Grid item key={`gen-error-warn-${errorIndex}`}>
                                <Alert severity="warning">
                                    {currentError}
                                </Alert>
                            </Grid>
                        )
                    })
                }
            </IF>

            <IF condition={canSubmit}>
                <Grid item>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <FontAwesomeIcon icon={faCheckCircle} fixedWidth/>&nbsp;
                        Your data is ready to import, please review the summary above, and when you're ready, click the
                        import button.
                    </Typography>
                </Grid>
            </IF>

            <Grid item>
                <SpinnerButton
                    // Non-button props
                    showSpinner={showSpinner}
                    // Button props
                    onClick={handleSubmit}
                    disabled={showSpinner || !canSubmit}
                    variant={'contained'}
                    size={'large'}
                    color={'primary'}
                    type={'submit'}
                >
                    <FontAwesomeIcon
                        icon={faDownload}
                        fixedWidth
                    />
                    &nbsp;Import The Data
                </SpinnerButton>
            </Grid>

            <Grid item>
                &nbsp;
            </Grid>

            <Grid item>
                <Grid item>
                    <HomeLink text={"Cancel the import and do nothing"}/>
                </Grid>
            </Grid>

        </Grid>
    );

    function handleSubmit() {
        setShowSpinner(true);

        let bankTSVData = new BulkBankTSV({});
        bankTSVData.parsedData = parsedData;
        const condensedInfo = bankTSVData.generateValidationJSON()

        const postJSON = {
            "partner_id": condensedInfo["partner_id"],
            "full_info": parsedData,
            "condensed_info": condensedInfo
        }

        const callURL = getAdminPath(`jeff/rhino/bulk/import/bank`);

        axiosInstance.post(callURL, postJSON)
            .then(function (response) {
                setShowSpinner(false);
                successCallback(response.data);
            })
            .catch(function (error) {
                console.error(error);
                toast.error('Failed to import');
            })
    }
};

export default RhinoBulkBankImportControls;