import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import * as Yup from "yup";
import * as Sentry from "@sentry/browser";
import toast from "react-hot-toast";
import {useFormik} from "formik";

import {
    YUP_SCHEMA_EMAIL_ADDRESS,
    YUP_SCHEMA_FIRST_NAME,
    YUP_SCHEMA_LAST_NAME
} from "../../utilities/yupCommonValidationSchemas";
import {EmailAddressField, StandardSelectField, StandardTextField} from "../utilities/forms/CommonFormFields";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {getAdminPath} from "../../api/adminAPI";
import {axiosInstance} from "../../utilities/axiosInstance";
import SpinnerButton from "../utilities/buttons/SpinnerButton";
import ClickToCopy from "../../clientShared/components/utility/ClickToCopy";
import Button from "@material-ui/core/Button";
import {Collapse} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import SupportLink from "../../clientShared/components/links/SupportLink";


const CreateAccountFinalizationForm = ({doneCallback}) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [serverError, setServerError] = useState("");
    const [accLink, setAccLink] = useState("");
    const hasLink = accLink !== "";

    const formSchema = Yup.object().shape({
        account_type: Yup.string()
            .required('You must select an account type')
            .typeError('You must select an account type')
            .oneOf(['Resident', 'Owner', 'Property Manager'], 'You must select an account type'),
        ...YUP_SCHEMA_FIRST_NAME,
        ...YUP_SCHEMA_LAST_NAME,
        ...YUP_SCHEMA_EMAIL_ADDRESS,
        send_email: Yup.boolean()
    });

    const formik = useFormik({
        initialValues: {
            account_type: '',
            first_name: "",
            last_name: "",
            email_address: "",
            send_email: false
        },
        onSubmit: handleSubmit,
        isInitialValid: false,
        validationSchema: formSchema
    });

    function handleSubmit(values) {
        setShowSpinner(true);
        setServerError("");

        let updatedValues = {...values};

        const callURL = getAdminPath("jeff/account/new/finalization");
        axiosInstance.post(callURL, updatedValues)
            .then(function (response) {
                setShowSpinner(false);
                toast.success('Done');
                setAccLink(response.data.link);
                formik.resetForm();
                // doneCallback();
            })
            .catch(function (error) {
                setShowSpinner(false);

                if(error.response.status === 400 && error.response.data.message ==="account_exists"){
                    setServerError('An account with this email already exists');
                }
                else {
                    setServerError("");
                    Sentry.withScope(scope => {
                        scope.setExtra("response", error.response);
                        Sentry.captureException(error);
                    });
                }

                toast.error('Failed');
            })
    }

    if(hasLink){
        return (
            <>
                Account has been created, click the link below to copy it.
                <br/>
                <br/>
                <ClickToCopy dataToCopy={accLink}/>
                <br/>
                <br/>
                <Button onClick={doneCallback} variant={"outlined"} color={"primary"}>Close Window</Button>
            </>
        );
    }

    return (
        <>
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="stretch"
                spacing={1}
            >

                <Grid item xs={12}>
                    {StandardSelectField(formik, 'account_type', 'Account Type', ['Resident', 'Owner', 'Property Manager'])}
                </Grid>

                <Grid item xs={12}>

                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item zeroMinWidth xs={6}>
                            {StandardTextField(formik, 'first_name', 'First Name')}
                        </Grid>

                        <Grid item zeroMinWidth xs={6}>
                            {StandardTextField(formik, 'last_name', 'Last Name')}
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    {EmailAddressField(formik)}
                </Grid>

                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={formik.values['send_email']}
                                    name={'send_email'}
                                    //helperText={extractFormikErrors(formikProps, 'accept_tos')}
                                    //error={formikFieldHasError(formikProps, 'accept_tos')}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    onInput={() => formik.setFieldTouched('send_email', true, true)}
                                    color={'primary'}
                                />
                            }
                            label={"Send email link"}
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    &nbsp;
                </Grid>

                <Grid item zeroMinWidth xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="nowrap"
                    >
                        <Grid item zeroMinWidth>
                            <SpinnerButton
                                // Non-button props
                                showSpinner={showSpinner}
                                // Button props
                                variant="contained"
                                color="primary"
                                disabled={!formik.isValid || showSpinner}
                                type={'submit'}
                            >
                                Create Account
                            </SpinnerButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>

            <Collapse in={serverError !== ""}>
                <br/>
                <Alert severity="error">
                    {serverError}
                </Alert>
            </Collapse>

        </>
    );
};


export default CreateAccountFinalizationForm;