/*
    Common Resources Service
 */
import axiosRequest from "../utilities/axios/AxiosRequest";
import {createCommonResourceURL} from "../utilities/resourceUtilities";


//
// Service
//
const CommonResourcesService = {
    CommonReadResource,
    CommonUpdateResource
};


//----------------------------------------------------------------------------------------------------------------------
function CommonReadResource(resourceType, resourceUID) {
    return axiosRequest({
        url: createCommonResourceURL(resourceType, resourceUID),
        method: 'GET'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function CommonUpdateResource(resourceType, resourceUID, updateData) {
    return axiosRequest({
        url: createCommonResourceURL(resourceType, resourceUID),
        data: updateData,
        method: 'PUT'
    });
}

export default CommonResourcesService;