/*
    Display a Deposit Account Type
 */

import React from 'react';
import {
    LANDLORD_ICON,
    PROPERTY_MANAGER_ICON,
    TENANT_ICON
} from "../../../../constants/style/icons";
import {
    DEPOSIT_ACCOUNT_TYPE_LANDLORD,
    DEPOSIT_ACCOUNT_TYPE_MEDIATOR,
    DEPOSIT_ACCOUNT_TYPE_TENANT
} from "../../../../../autoGenerated/constants/services/DepositsServiceConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";

const ACC_TYPE_TO_DISPLAY_INFO = {
    [DEPOSIT_ACCOUNT_TYPE_LANDLORD]: {displayName: 'Owner', icon: LANDLORD_ICON},
    [DEPOSIT_ACCOUNT_TYPE_TENANT]: {displayName: 'Resident', icon: TENANT_ICON},
    [DEPOSIT_ACCOUNT_TYPE_MEDIATOR]: {displayName: 'PMC', icon: PROPERTY_MANAGER_ICON}
};

export default function DepositAccountTypeDisplay({depositAccountType, ...props}) {
    const displayInfo = ACC_TYPE_TO_DISPLAY_INFO[depositAccountType];

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            wrap={'nowrap'}
        >
            <Grid item>
                <FontAwesomeIcon
                    icon={displayInfo.icon}
                    fixedWidth
                />
            </Grid>

            <Grid item>
                &nbsp;{displayInfo.displayName}
            </Grid>

        </Grid>
    );

}
