/*
    Dev
 */

import React, {useState} from "react";
import {convertHexUIDStringToFull} from "../../clientShared/utilities/uuidUtilities";
import {bindActionCreators} from "redux";
import {adminActionLoadAllUIDS} from "../../actions/adminActions";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
import {useDepositLoader} from "../../autoGenerated/hooks/resourceLoadingHooks";
import Grid from "@material-ui/core/Grid";
import HomeLink from "../admin/buttons/linkButtons/HomeLink";
import BackLink from "../admin/buttons/linkButtons/BackLink";
import GenericObjectWrapper from "../../clientShared/components/display/GenericObjectWrapper";
import {RESOURCE_TYPE_DEPOSIT} from "../../autoGenerated/constants/resourceTypeConstants";
import {useDepositHistoryEntryUIDListLoader} from "../../autoGenerated/hooks/resourceUIDListLoadingHooks";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import {DepositHistoryEntriesTable} from "../../autoGenerated/components/tables/allObjectTables";
import AppLoading from "../../AppLoading";
import AdminDepositStateRender from "../admin/displays/adminDepositStateRender";
import IF from "../../clientShared/components/utility/IFComponent";
import RenderDeposifyTags from "../tags/DeposifyTags";
import Typography from "@material-ui/core/Typography";
import ClickToCopy from "../../clientShared/components/utility/ClickToCopy";
import {uuidToHex} from "../../utilities/commonUtils";
import AdminDisplayDepositInvite from "../admin/displays/adminDisplayDepositInvite";
import {
    AttachedStorageFilesContainer,
    DepositInviteContainer
} from "../../autoGenerated/components/containers/resourceContainers";
import AdminDisplayAttachedFiles from "../admin/displays/adminDisplayAttachedFiles";
import {AdminDepositFilePondCard} from "../admin/controls/adminDepositFilePondCard";
import DownloadDepositReceiptLink from "../admin/buttons/downloadDepositReceiptLink";
import DepositAdminControls from "./depositAdminControls";
import {depositHasRhinoInfo} from "./rhinoFunctionality";
import DepositRhinoInfo from "./DepositRhinoInfo";
import DepositRhinoFundsInfo from "./DepositRhinoFundsInfo";
import qs from "qs";
import {APP_ROUTE_DETAILED_DEPOSIT, APP_ROUTE_DETAILED_USER_ACCOUNT} from "../../constants/appRoutes";
import {history} from "../../history/history";

const useStyles = makeStyles({
    root: {
        padding: 30,
        // overflowX: 'scroll',
        // height: '100vh',
        // width: '100%'
        overflow: 'auto'
    },
    paper: {
        // width: '100%',
        maxWidth: 1600
    },
    tabPanelRoot: {
        padding: 0
    },
    tabWrapper: {
        borderTop: '1px solid #005980'
    }
});

const TAB_INDEX_DEPOSIT = "0";
const TAB_INDEX_HISTORY = "1";
const TAB_INDEX_INVITE = "2";
const TAB_INDEX_FILE = "3";
const TAB_INDEX_ADMIN = "4";

function DetailedDepositInfoPageTwo(props) {
    const classes = useStyles();

    const tabIndex = qs.parse(props.location.search, {ignoreQueryPrefix: true}).tabIndex || TAB_INDEX_DEPOSIT;
    const [value, setValue] = useState(tabIndex);

    // Grab the UID from the URL
    const depositUID = convertHexUIDStringToFull(props.match.params.deposit_uid);
    const {loading: depLoading, error: depError, deposit} = useDepositLoader(depositUID);
    const {entryUIDList: depositHistoryUIDList} = useDepositHistoryEntryUIDListLoader(depositUID);

    if (depLoading) {
        return <AppLoading/>;
    }

    const hasRhinoInfo = depositHasRhinoInfo(deposit);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const baseURL = APP_ROUTE_DETAILED_DEPOSIT.replace(":deposit_uid", depositUID);
        history.push(`${baseURL}?tabIndex=${newValue}`);
    };


    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}
            className={classes.root}
        >
            <Grid item>
                <BackLink/>&nbsp;|&nbsp;<HomeLink/>
            </Grid>

            <Grid item>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <h1>{deposit.title}</h1>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    <ClickToCopy dataToCopy={uuidToHex(deposit.deposit_uid)}/>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <AdminDepositStateRender deposit={deposit} large={true}/>
                    </Grid>

                    <Grid item>
                        <RenderDeposifyTags deposifyTags={deposit.deposify_tags}/>
                    </Grid>

                    <IF condition={deposit.funds_held_by_partner === true}>
                        <Grid item>
                            <DepositRhinoFundsInfo deposit={deposit}/>
                        </Grid>
                    </IF>

                    <IF condition={hasRhinoInfo}>
                        <Grid item>
                            <DepositRhinoInfo deposit={deposit}/>
                        </Grid>
                    </IF>

                </Grid>
            </Grid>

            <Grid item>
                &nbsp;
            </Grid>

            <Grid item>

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    wrap={'nowrap'}
                >
                    <Grid item xs={2}>
                        <Paper square className={classes.paper}>

                            <Tabs
                                orientation="vertical"
                                value={value}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChange}
                                aria-label="disabled tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label={"Deposit"} value={TAB_INDEX_DEPOSIT}/>
                                <Tab label={"History"} value={TAB_INDEX_HISTORY}/>
                                <Tab label={"Invite"} value={TAB_INDEX_INVITE}/>
                                <Tab label={"Files"} value={TAB_INDEX_FILE}/>
                                <Tab label={"Admin"} value={TAB_INDEX_ADMIN}/>
                            </Tabs>

                        </Paper>
                    </Grid>

                    <Grid item xs={10}>
                        <div className={classes.tabWrapper}>
                            <TabContext value={value}>

                                <TabPanel value={TAB_INDEX_DEPOSIT} className={classes.tabPanelRoot}>
                                    <GenericObjectWrapper
                                        resourceUID={depositUID}
                                        resourceType={RESOURCE_TYPE_DEPOSIT}
                                        showHeaders={false}
                                    />
                                </TabPanel>

                                <TabPanel value={TAB_INDEX_HISTORY} className={classes.tabPanelRoot}>
                                    <DepositHistoryEntriesTable entryUIDList={depositHistoryUIDList}/>
                                </TabPanel>

                                <TabPanel value={TAB_INDEX_INVITE} className={classes.tabPanelRoot}>
                                    <DepositInviteContainer depositUID={deposit.deposit_uid}>
                                        <AdminDisplayDepositInvite/>
                                    </DepositInviteContainer>
                                </TabPanel>

                                <TabPanel value={TAB_INDEX_FILE} className={classes.tabPanelRoot}>
                                    <>
                                        <AttachedStorageFilesContainer attachedFilesUID={deposit.attached_files_uid}>
                                            <AdminDisplayAttachedFiles/>
                                        </AttachedStorageFilesContainer>
                                        <AdminDepositFilePondCard
                                            deposit={deposit}
                                            attachedFilesUID={deposit.attached_files_uid}
                                        />
                                    </>
                                </TabPanel>

                                <TabPanel value={TAB_INDEX_ADMIN} className={classes.tabPanelRoot}>
                                    <div style={{margin: 12}}>
                                        <DepositAdminControls deposit={deposit} startExpanded={true}/>

                                        <br/>
                                        <hr/>

                                        <DownloadDepositReceiptLink
                                            depositUID={deposit.deposit_uid}
                                            depositState={deposit.state}
                                        />
                                        <br/>
                                        <br/>

                                    </div>
                                </TabPanel>

                            </TabContext>
                        </div>
                    </Grid>

                </Grid>

            </Grid>


        </Grid>
    );


}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionLoadAllUIDS: adminActionLoadAllUIDS
    }, dispatch);
};

export default connect(null, mapActionsToProps)(DetailedDepositInfoPageTwo);

