/*
    Support Page link
 */

import React from "react";
import {APP_ROUTE_SUPPORT} from "../../../constants/appRoutes";
import LinkBase from "./linkBase";
import Link from "@material-ui/core/Link";
import {history} from "../../../history/history";

function SupportLink({message='Support', changeTabs=true, ...props}) {

    if(changeTabs === true) {
        return (
            <LinkBase url={APP_ROUTE_SUPPORT} text={message}/>
        );
    }

    return (
        <Link
            href={'#'}
            onClick={onClick}
        >
            {message}
        </Link>
    );

    function onClick(event){
        event.preventDefault();
        history.push(APP_ROUTE_SUPPORT);
    }
}


export default SupportLink;
