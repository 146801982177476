import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSkullCrossbones} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {faAt, faPencil} from "@fortawesome/pro-regular-svg-icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {connect} from "react-redux";
import * as Sentry from "@sentry/browser";
import {bindActionCreators} from "redux";
import {DEPOSIT_STATE_PENDING} from "../../../autoGenerated/constants/services/DepositsServiceConstants";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CancelButton from "../../utilities/buttons/CancelButton";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";
import DepositsAdminService from "../../../api/depositsAdminService";

import {resourceActionUpdateSingleResource} from "../../../clientShared/actions/resourceActions";
import Grid from "@material-ui/core/Grid";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));


function SendRevertedDepositEmailsButton({deposit, ...props}) {
    const classes = useStyles();

    const [showDialog, setShowDialog] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [revertReason, setRevertReason] = React.useState(0);
    const [sendLandlord, setSendLandlord] = React.useState(true);
    const [sendTenant, setSendTenant] = React.useState(true);

    if(deposit.state !== DEPOSIT_STATE_PENDING){
        return <React.Fragment/>;
    }

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={handleOpen}
                variant={'outlined'}
                size={'small'}
            >
                <FontAwesomeIcon
                    icon={faAt}
                    fixedWidth
                />
                &nbsp;Send Reverted Deposit Emails
            </Button>

            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="fullscreen-deposit-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
                //fullScreen
            >
                <DialogTitle id="edit-funds-location-dialog-title">Send Reverted Emails</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Send deposit reverted emails to landlord and tenant<br/>
                        Only the tenant gets the declined reason, the landlord just gets info about the deposit changing from Active to Pending
                    </DialogContentText>

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >

                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={sendLandlord}
                                        onChange={toggleLandlord}
                                        inputProps={{'aria-label': 'toggle JSON formatting'}}
                                    />
                                }
                                label="Send to landlord"
                            />
                        </Grid>

                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={sendLandlord}
                                        onChange={toggleTenant}
                                        inputProps={{'aria-label': 'toggle JSON formatting'}}
                                    />
                                }
                                label="Send to tenant"
                            />
                        </Grid>

                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-dialog-select-label">Funds Location</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={revertReason}
                                    onChange={handleChange}
                                    input={<Input />}
                                    fullWidth
                                >
                                    <MenuItem value={0}>
                                        Incorrect Details
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Insufficient funds
                                    </MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <CancelButton handleCloseFunction={handleClose} buttonText={'Close'}/>

                    <SpinnerButton
                        // Non-button props
                        showSpinner={showSpinner}
                        // Button props
                        variant="contained"
                        color="secondary"
                        disabled={showSpinner}
                        onClick={handleChangeFundsLocation}
                    >
                        <FontAwesomeIcon
                            icon={faSkullCrossbones}
                            fixedWidth
                        />
                        &nbsp;Send Email
                    </SpinnerButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );

    function toggleLandlord() {
        setSendLandlord(!sendLandlord);
    }
    function toggleTenant() {
        setSendTenant(!sendTenant);
    }
    
    function handleOpen() {
        setShowDialog(true);
    }

    function handleClose() {
        setShowDialog(false);
        setShowSpinner(false);
    }

    function handleChange(event){
        setRevertReason(event.target.value);
    }

    function handleChangeFundsLocation() {
        setShowSpinner(true);

        DepositsAdminService.AdminAdminSendRevertedDepositEmail(deposit.deposit_uid, sendLandlord, sendTenant, revertReason)
            .then(function (response) {
                toast.success('Done');
                handleClose();
            })
            .catch(function (error) {
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed');
            })
    }


}


const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        resourceActionUpdateSingleResource: resourceActionUpdateSingleResource
    }, dispatch);
};

export default connect(null, mapActionsToProps)(React.memo(SendRevertedDepositEmailsButton));

