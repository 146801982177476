import {
    faAddressBook,
    faBuilding,
    faGavel,
    faStreetView,
    faUserCrown,
    faUserVisor
} from "@fortawesome/pro-solid-svg-icons";
import {
    USER_ACCOUNT_ROLES_ADMIN,
    USER_ACCOUNT_ROLES_BROKER,
    USER_ACCOUNT_ROLES_LANDLORD,
    USER_ACCOUNT_ROLES_MEDIATOR,
    USER_ACCOUNT_ROLES_SUPER_ADMIN,
    USER_ACCOUNT_ROLES_TENANT
} from "../../../autoGenerated/constants/services/UserAccountsServiceConstants";

// User Account Role Icons
export const LANDLORD_ICON = faBuilding;
export const TENANT_ICON = faStreetView;
export const PROPERTY_MANAGER_ICON = faAddressBook;
export const MEDIATOR_ICON = faGavel;
export const ADMIN_ICON = faUserCrown;
export const SUPER_ADMIN_ICON = faUserVisor;


const USER_ACCOUNT_ROLE_ICON_MAPPING = {
    [USER_ACCOUNT_ROLES_LANDLORD]: LANDLORD_ICON,
    [USER_ACCOUNT_ROLES_TENANT]: TENANT_ICON,
    [USER_ACCOUNT_ROLES_BROKER]: PROPERTY_MANAGER_ICON,
    [USER_ACCOUNT_ROLES_MEDIATOR]: MEDIATOR_ICON,
    [USER_ACCOUNT_ROLES_ADMIN]: ADMIN_ICON,
    [USER_ACCOUNT_ROLES_SUPER_ADMIN]: SUPER_ADMIN_ICON
};

export function getUserAccountRoleIcon(userAccountRole) {
    return USER_ACCOUNT_ROLE_ICON_MAPPING[userAccountRole];
}