import {
    faBuilding, faEnvelopeOpen, faFileAlt,
    faFileInvoiceDollar, faFlagUsa, faGlobeEurope, faHandHoldingBox,
    faHome, faMailbox, faMailBulk,
    faMapMarkerAlt, faPortalExit, faSackDollar, faUniversity,
    faUsers, faKey, faHistory, faWifi, faInboxOut, faRouter, faPhoneLaptop, faCctv, faUnicorn, faBiohazard, faCoins
} from "@fortawesome/pro-solid-svg-icons";

export const ADMIN_ICON_HOME = faHome;
export const ADMIN_ICON_USER_ACCOUNTS = faUsers;
export const ADMIN_ICON_DEPOSITS = faFileInvoiceDollar;

export const ADMIN_ICON_BANK_ACCOUNT = faUniversity;
export const ADMIN_ICON_USA = faFlagUsa;
export const ADMIN_ICON_INTERNATIONAL = faGlobeEurope;
export const ADMIN_ICON_WITHDRAWAL = faSackDollar;

export const ADMIN_ICON_INVITE = faHandHoldingBox;

export const ADMIN_ICON_CLOSE_REQUESTS = faPortalExit;
export const ADMIN_ICON_MAILBOXES = faMailbox;
export const ADMIN_ICON_MAILBOX_MESSAGES = faMailBulk;

export const ADMIN_ICON_FILES = faFileAlt;
export const ADMIN_ICON_PERMISSIONS = faKey;
export const ADMIN_ICON_HISTORY = faHistory;
export const ADMIN_ICON_ONLINE_USERS = faWifi;

export const ADMIN_ICON_SENT_EMAIL = faInboxOut;
export const ADMIN_ICON_IP_ADDRESSES = faRouter;
export const ADMIN_ICON_USER_AGENT = faPhoneLaptop;
export const ADMIN_ICON_PERMISSION_LOGS = faCctv;

export const ADMIN_ICON_BALANCE_CHANGE_LOGS = faCctv;
export const ADMIN_ICON_BALANCE_SOURCE_TRANSFERS = faCctv;
export const ADMIN_ICON_CREDIT_USER_ACCOUNT_LOGS = faCctv;
export const ADMIN_ICON_EMAIL_VERIFICATIONS = faCctv;
export const ADMIN_ICON_FUNDS_LOCK_RELEASE_LOGS = faCctv;

export const ADMIN_ICON_USER_BANK_ACCOUNT_DELTAS = faPhoneLaptop;
export const ADMIN_ICON_RELEASED_FUNDS_LOCKS = faPhoneLaptop;
export const ADMIN_ICON_PREVIOUS_EMAIL_ADDRESSS = faPhoneLaptop;
export const ADMIN_ICON_PENDING_EMAIL_CHANGES = faPhoneLaptop;
export const ADMIN_ICON_PASSWORD_RESETS = faPhoneLaptop;

export const ADMIN_ICON_BANK_ACCOUNT_INTERESTS = faUnicorn;
export const ADMIN_ICON_BANK_ACCOUNTS = faUnicorn;
export const ADMIN_ICON_BANK_TRANSFERS = faUnicorn;
export const ADMIN_ICON_DEPOSIT_ADJUSTMENTS = faUnicorn;
export const ADMIN_ICON_DEPOSIT_BULK_IMPORTS = faUnicorn;
export const ADMIN_ICON_DEPOSIT_INTERESTS = faUnicorn;
export const ADMIN_ICON_PROPERTY_ADDRESSS = faUnicorn;
export const ADMIN_ICON_ACTION_ITEMS = faUnicorn;
export const ADMIN_ICON_EVENTS = faUnicorn;
export const ADMIN_ICON_NOTIFICATIONS = faUnicorn;
export const ADMIN_ICON_DEPOSIT_HISTORY_ENTRYS = faUnicorn;
export const ADMIN_ICON_SYSTEM_HISTORY_ENTRYS = faUnicorn;
export const ADMIN_ICON_UPDATE_LOGS = faUnicorn;
export const ADMIN_ICON_EMAIL_EVENTS = faUnicorn;
export const ADMIN_ICON_EMAIL_TEMPLATES = faUnicorn;
export const ADMIN_ICON_INVOICES = faUnicorn;
export const ADMIN_ICON_INVOICE_ITEMS = faUnicorn;
export const ADMIN_ICON_STRIPE_PAYMENT_LOGS = faUnicorn;
export const ADMIN_ICON_PAYMENT_REQUESTS = faUnicorn;

export const ADMIN_ICON_USER_WITHDRAWS = faCoins;

export const ADMIN_ICON_US_DOMESTIC_USER_BANK_ACCOUNTS = faCctv;
export const ADMIN_ICON_INTERNATIONAL_USER_BANK_ACCOUNTS = faCctv;

export const ADMIN_ICON_STORAGE_FILES = faCctv;
export const ADMIN_ICON_ATTACHED_STORAGE_FILESS = faCctv;
export const ADMIN_ICON_PROPERTYS = faCctv;
export const ADMIN_ICON_DEPOSIT_CLOSE_REQUESTS = faCctv;

export const ADMIN_ICON_DANGER_PAGE = faBiohazard;

export const ADMIN_ICON_USER_ACCOUNT_BALANCES = faCctv;
