import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fullScreenDisplaySelector, fullScreenHistoryChainSelector} from "../../selectors/adminSelectors";
import {
    adminActionMoveBackInFullScreenHistoryChain,
    adminActionSetFullScreenHistoryChain,
    adminActionSetGenericFullScreenDisplay
} from "../../actions/adminActions";
import {
    faArrowLeft,
    faHistory,
    faTimes
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";


function DataDisplayDrawerNavigation({fullScreenHistoryChain, ...props}) {

    const drawerIsOpen = props.fullScreenDisplay.showDialog === true;
    if (drawerIsOpen === false) {
        return <React.Fragment/>;
    }

    return (
        <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            wrap={'nowrap'}
            spacing={1}
        >

            <Grid item>
                {renderBackButton()}
            </Grid>

            <Grid item>
                {renderBackToRootLink()}
            </Grid>

            <Grid item>
                {renderCloseButton()}
            </Grid>

        </Grid>
    );

    function renderBackToRootLink() {
        if(fullScreenHistoryChain.length <= 1){
            return <React.Fragment/>;
        }

        return (
            <Link
                component="button"
                onClick={handleBackToRoot}
            >
                <FontAwesomeIcon
                    icon={faHistory}
                    fixedWidth
                />
                &nbsp;Start
            </Link>
        );
    }

    function renderBackButton() {
        if(fullScreenHistoryChain.length <= 1){
            return <React.Fragment/>;
        }

        return (
            <Link
                component="button"
                onClick={handleMoveBack}
            >
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    fixedWidth
                />
                &nbsp;Back
            </Link>
        );
    }

    function renderCloseButton() {
        return (
            <Link
                component="button"
                onClick={handleClose}
                color={'secondary'}
            >
                <FontAwesomeIcon
                    icon={faTimes}
                    fixedWidth
                />
                &nbsp;Close
            </Link>
        );
    }

    function handleMoveBack(){
        props.adminActionMoveBackInFullScreenHistoryChain();
    }

    function handleBackToRoot(){
        const newTarget = fullScreenHistoryChain.slice(0, 1);

        // Tell the history about the change
        props.adminActionSetFullScreenHistoryChain(newTarget);

        // Tell the full screen dialog to update and reflect this
        props.adminActionSetGenericFullScreenDisplay(
            {
                showDialog: true,
                resourceUID: newTarget[0].resourceUID,
                resourceType: newTarget[0].resourceType,
                displayMode: props.fullScreenDisplay.displayMode
            }
        );
    }

    function handleClose() {
        props.adminActionSetGenericFullScreenDisplay(
            {
                showDialog: false,
                resourceUID: props.fullScreenDisplay.resourceUID,
                resourceType: props.fullScreenDisplay.resourceType,
                displayMode: props.fullScreenDisplay.displayMode
            }
        );

        props.adminActionSetFullScreenHistoryChain([]);
    }

}

const mapStateToProps = (state) => ({
    fullScreenDisplay: fullScreenDisplaySelector(state),
    fullScreenHistoryChain: fullScreenHistoryChainSelector(state)
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        adminActionSetGenericFullScreenDisplay: adminActionSetGenericFullScreenDisplay,
        adminActionSetFullScreenHistoryChain: adminActionSetFullScreenHistoryChain,
        adminActionMoveBackInFullScreenHistoryChain: adminActionMoveBackInFullScreenHistoryChain
    }, dispatch);
};

//NOTE: Removed this as the third argument: mergeProps
export default connect(mapStateToProps, mapActionsToProps)(DataDisplayDrawerNavigation);