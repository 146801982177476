/*
    Deposits Service
 */


import axiosRequest from "../clientShared/utilities/axios/AxiosRequest";
import {USER_ACCOUNT_ROLE_BROKER, USER_ACCOUNT_ROLE_LANDLORD} from "../constants/accountConstants";
import {
    API_ROUTE_ACCEPT_DEPOSIT_CLOSE_REQUEST,
    API_ROUTE_ACCEPT_DISPUTE_SPLIT,
    API_ROUTE_ADD_PHONE_NUMBER_TO_DEPOSIT_INVITE, API_ROUTE_CANCEL_DEPOSIT,
    API_ROUTE_CLOSE_DEPOSIT_REQUEST_LANDLORD,
    API_ROUTE_CLOSE_DEPOSIT_REQUEST_TENANT,
    API_ROUTE_CREATE_DEPOSIT,
    API_ROUTE_DISPUTE_DEPOSIT,
    API_ROUTE_PROPOSE_DISPUTE_SPLIT,
    API_ROUTE_READ_ALL_DEPOSIT_UIDS_BY_ACCOUNT,
    API_ROUTE_READ_DEPOSIT_HISTORY,
    API_ROUTE_REJECT_DEPOSIT_CLOSE_REQUEST,
    API_ROUTE_REJECT_DISPUTE_SPLIT
} from "../constants/apiRouteConstants";
import {NULL_UUID_STRING} from "../constants/dataConstants";
import {
    RESOURCE_TYPE_DEPOSIT,
    RESOURCE_TYPE_DEPOSIT_CLOSE_REQUEST,
    RESOURCE_TYPE_DEPOSIT_INVITE,
    RESOURCE_TYPE_DEPOSIT_USER_STATS,
    RESOURCE_TYPE_DEPOSIT_WITHHELD_FUNDS,
    RESOURCE_TYPE_DISPUTE,
    RESOURCE_TYPE_DISPUTE_SPLIT,
    RESOURCE_TYPE_PROPERTY,
    RESOURCE_TYPE_PROPERTY_ADDRESS
} from "../autoGenerated/constants/resourceTypeConstants";
import CommonResourcesService from "../clientShared/services/CommonResourcesService";
import {
    API_ROUTE_ACCEPT_DEPOSIT_AS_LANDLORD,
    API_ROUTE_ACCEPT_DEPOSIT_AS_TENANT,
    API_ROUTE_CREATE_SEZZLE_ORDER,
    API_ROUTE_DEPOSIT_WITHHOLD_FUNDS,
    API_ROUTE_PROPERTY_ADDRESS_GEOCODER_LOCATION_ID_LOOKUP,
    API_ROUTE_PROPERTY_ADDRESS_GEOCODER_SEARCH,
    API_ROUTE_READ_CHILD_PROPERTIES,
    API_ROUTE_REJECT_DEPOSIT_AS_LANDLORD,
    API_ROUTE_REJECT_DEPOSIT_AS_TENANT,
    API_ROUTE_RESEND_DEPOSIT_INVITE,
} from "./APIRoutes";
import {renderURL} from "../clientShared/utilities/urlUtilities";

//
// Service
//
const DepositsService = {
    ReadAllDepositUIDs,
    ReadDeposit,
    CreateDeposit,
    CreateDepositModern,
    UpdateDeposit,
    UpdateProperty,
    SetPropertyParent,
    SetDepositPropertyUID,
    CancelDeposit,
    ArchiveDeposit,
    UnarchiveDeposit,
    DisputeDeposit,
    TenantRejectsDepositInvite,
    LandlordRejectsDepositInvite,
    TenantAcceptsDepositInvite,
    LandlordAcceptsDepositInvite,
    SendDepositInviteViaEmail,
    SendDepositInviteViaText,
    CreateCloseRequestAsTenant,
    CreateCloseRequestAsLandlord,
    AcceptDepositCloseRequest,
    RejectDepositCloseRequest,
    AcceptDisputeSplit,
    RejectDisputeSplit,
    ProposeDisputeSplit,
    UserAddedFilesToDeposit,
    UserAddedFilesToDispute,
    UserAddedFilesToDisputeSplit,
    DeleteProperty,
    ReadDepositInvite,
    ReadUserStats,
    ReadDepositHistory,
    ReadDispute,
    ReadDisputeSplit,
    TenantRequestsHelp,
    ReadDepositCloseRequest,
    WithholdDepositFunds,
    ReadAllWithheldFundsUIDsByDeposit,
    CancelWithheldFunds,
    EditWithheldFundsAmount,
    UpdateWithheldFunds,
    LandlordClosesDeposit,
    AttachFilesToWithheldFunds,
    CreateProperty,
    CreatePropertyAddress,
    RescindDepositInvite,
    ResendDepositInvite,
    SezzleCreateDepositPayment,
    ReadChildProperties,
    PropertyAddressGeocoderSearch,
    PropertyAddressGeocoderLocationIDLookup
};


//----------------------------------------------------------------------------------------------------------------------
function ReadAllDepositUIDs(offset, limit, orderColumn, getDescending, includeArchived) {
    const getRoute = `${API_ROUTE_READ_ALL_DEPOSIT_UIDS_BY_ACCOUNT}/${offset}/${limit}/${orderColumn}/${getDescending}/${includeArchived}`;
    return axiosRequest({
        url: getRoute,
        method: 'GET'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function ReadDeposit(depositUID) {
    return CommonResourcesService.CommonReadResource(RESOURCE_TYPE_DEPOSIT, depositUID);
}

//----------------------------------------------------------------------------------------------------------------------
function CreateDeposit(creatingAccountType, title, amountInCents, startDate, endDate, usState, propertyUID) {
    const postAccountType = creatingAccountType === USER_ACCOUNT_ROLE_BROKER ? USER_ACCOUNT_ROLE_LANDLORD : creatingAccountType;
    const postValues = {
        creating_account_type: postAccountType,
        title: title,
        amount_in_cents: amountInCents,
        start_date: startDate,
        end_date: endDate,
        geo_state: usState,
        property_uid: propertyUID
    };

    return axiosRequest({
        url: API_ROUTE_CREATE_DEPOSIT,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function CreateDepositModern(createData) {
    return axiosRequest({
        url: API_ROUTE_CREATE_DEPOSIT,
        method: 'POST',
        data: createData
    });
}

//----------------------------------------------------------------------------------------------------------------------
function SetDepositPropertyUID(depositUID, propertyUID) {
    const putValues = {
        property_uid: propertyUID
    };

    return CommonResourcesService.CommonUpdateResource(RESOURCE_TYPE_DEPOSIT, depositUID, putValues);
}

//----------------------------------------------------------------------------------------------------------------------
function UpdateDeposit(depositUID, updateData) {
    return CommonResourcesService.CommonUpdateResource(RESOURCE_TYPE_DEPOSIT, depositUID, updateData);
}

//----------------------------------------------------------------------------------------------------------------------
function UpdateProperty(propertyUID, updateData) {
    return CommonResourcesService.CommonUpdateResource(RESOURCE_TYPE_PROPERTY, propertyUID, updateData);
}

//----------------------------------------------------------------------------------------------------------------------
function SetPropertyParent(propertyUID, newParentPropertyUID) {
    const updateData = {parent_property_uid: newParentPropertyUID};
    return UpdateProperty(propertyUID, updateData);
}

//----------------------------------------------------------------------------------------------------------------------
function CancelDeposit(depositUID) {
    return axiosRequest({
        url: renderURL(API_ROUTE_CANCEL_DEPOSIT, {depositUID: depositUID}),
        method: 'PUT',
        data: {}
    });
}

//----------------------------------------------------------------------------------------------------------------------
function ArchiveDeposit(depositUID) {
    return axiosRequest({
        url: `api/deposits/${depositUID}/archive`,
        method: 'PUT'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function UnarchiveDeposit(depositUID) {
    return axiosRequest({
        url: `api/deposits/${depositUID}/unarchive`,
        method: 'PUT'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function DisputeDeposit(postData) {
    return axiosRequest({
        url: API_ROUTE_DISPUTE_DEPOSIT,
        method: 'POST',
        data: postData
    });
}

//----------------------------------------------------------------------------------------------------------------------
function TenantRejectsDepositInvite(depositUID, accountUID) {
    // TODO: Update this so the server sets the account UID
    const postValues = {
        deposit_uid: depositUID,
        rejecting_account_uid: accountUID
    };

    return axiosRequest({
        url: API_ROUTE_REJECT_DEPOSIT_AS_TENANT,
        method: 'POST',
        data: postValues
    });
}

// TODO: Update this so the server sets the account UID
//----------------------------------------------------------------------------------------------------------------------
function LandlordRejectsDepositInvite(depositUID, accountUID) {
    // TODO: Update this so the server sets the account UID
    const postValues = {
        deposit_uid: depositUID,
        rejecting_account_uid: accountUID
    };

    return axiosRequest({
        url: API_ROUTE_REJECT_DEPOSIT_AS_LANDLORD,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function TenantAcceptsDepositInvite(depositUID) {
    // const postValues = {
    //     deposit_uid: depositUID,
    //     invoice_uid: invoiceUID,
    //     funds_lock_uid: fundsLockUID
    // };

    return axiosRequest({
        url: renderURL(API_ROUTE_ACCEPT_DEPOSIT_AS_TENANT, {depositUID: depositUID}),
        method: 'POST',
        data: {}
    });
}

//----------------------------------------------------------------------------------------------------------------------
function LandlordAcceptsDepositInvite(depositUID, invoiceUID, fundsLockUID) {
    const postValues = {
        deposit_uid: depositUID,
        invoice_uid: NULL_UUID_STRING,
        funds_lock_uid: NULL_UUID_STRING
    };

    return axiosRequest({
        url: API_ROUTE_ACCEPT_DEPOSIT_AS_LANDLORD,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function SendDepositInviteViaEmail(depositUID, emailAddress) {

    const postValues = {
        email_address: emailAddress
    };

    return axiosRequest({
        url: `api/deposits/invites/${depositUID}/add/email`,
        method: 'POST',
        data: postValues
    });
}


//----------------------------------------------------------------------------------------------------------------------
function SendDepositInviteViaText(depositUID, phoneNumber) {

    const postValues = {
        phone_number: phoneNumber
    };

    return axiosRequest({
        url: `${API_ROUTE_ADD_PHONE_NUMBER_TO_DEPOSIT_INVITE}/${depositUID}/add/phone`,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function CreateCloseRequestAsTenant(depositUID, amountInCentsForLandlord, amountInCentsForTenant, reasonForClose) {

    const postValues = {
        deposit_uid: depositUID,
        amount_in_cents_to_landlord: amountInCentsForLandlord,
        amount_in_cents_to_tenant: amountInCentsForTenant,
        reason_for_close: reasonForClose
    };

    return axiosRequest({
        url: API_ROUTE_CLOSE_DEPOSIT_REQUEST_TENANT,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function CreateCloseRequestAsLandlord(depositUID, amountInCentsForLandlord, amountInCentsForTenant, reasonForClose) {

    const postValues = {
        deposit_uid: depositUID,
        amount_in_cents_to_landlord: amountInCentsForLandlord,
        amount_in_cents_to_tenant: amountInCentsForTenant,
        reason_for_close: reasonForClose
    };

    return axiosRequest({
        url: API_ROUTE_CLOSE_DEPOSIT_REQUEST_LANDLORD,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function AcceptDepositCloseRequest(depositUID, isAccepting, responseReason) {

    const postValues = {
        deposit_uid: depositUID,
        is_accepting_close_request: isAccepting,
        response_reason: responseReason
    };

    return axiosRequest({
        url: API_ROUTE_ACCEPT_DEPOSIT_CLOSE_REQUEST,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function RejectDepositCloseRequest(depositUID, isAccepting, responseReason) {
    const postValues = {
        deposit_uid: depositUID,
        is_accepting_close_request: isAccepting,
        response_reason: responseReason
    };

    return axiosRequest({
        url: API_ROUTE_REJECT_DEPOSIT_CLOSE_REQUEST,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function AcceptDisputeSplit(splitUID, response, responseMessage) {
    const postValues = {
        split_uid: splitUID,
        response: response,
        response_message: responseMessage
    };

    return axiosRequest({
        url: API_ROUTE_ACCEPT_DISPUTE_SPLIT,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function RejectDisputeSplit(splitUID, response, responseMessage) {
    const postValues = {
        split_uid: splitUID,
        response: response,
        response_message: responseMessage
    };

    return axiosRequest({
        url: API_ROUTE_REJECT_DISPUTE_SPLIT,
        method: 'POST',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function ProposeDisputeSplit(postData) {
    return axiosRequest({
        url: API_ROUTE_PROPOSE_DISPUTE_SPLIT,
        method: 'POST',
        data: postData
    });
}

//----------------------------------------------------------------------------------------------------------------------
function UserAddedFilesToDeposit(depositUID, fileUIDs) {
    const postValues = {
        file_uid_list: fileUIDs
    };

    return axiosRequest({
        url: `api/deposits/${depositUID}/files/added/user`,
        method: 'PUT',
        data: postValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function UserAddedFilesToDispute(depositUID, disputeUID, fileUIDs) {
    const putValues = {
        file_uid_list: fileUIDs
    };

    return axiosRequest({
        url: `api/deposits/${depositUID}/dispute/${disputeUID}/files/added`,
        method: 'PUT',
        data: putValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function UserAddedFilesToDisputeSplit(depositUID, splitUID, fileUIDs) {
    const putValues = {
        file_uid_list: fileUIDs
    };

    return axiosRequest({
        url: `api/deposits/${depositUID}/dispute/split/${splitUID}/files/added`,
        method: 'PUT',
        data: putValues
    });
}

//----------------------------------------------------------------------------------------------------------------------
function DeleteProperty(propertyUID) {
    return axiosRequest({
        url: `api/properties/${propertyUID}/delete`,
        method: 'DELETE'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function ReadDepositInvite(depositUID) {
    return CommonResourcesService.CommonReadResource(RESOURCE_TYPE_DEPOSIT_INVITE, depositUID);
}


//----------------------------------------------------------------------------------------------------------------------
function ReadUserStats(accountUID) {
    return CommonResourcesService.CommonReadResource(RESOURCE_TYPE_DEPOSIT_USER_STATS, accountUID);
}

//----------------------------------------------------------------------------------------------------------------------
function ReadDepositHistory(depositUID, offset, limit, mostRecentFirst) {
    const getRoute = `${API_ROUTE_READ_DEPOSIT_HISTORY}/${depositUID}/history/${offset}/${limit}/${mostRecentFirst}`;
    return axiosRequest({
        url: getRoute,
        method: 'GET'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function ReadDispute(disputeUID) {
    return CommonResourcesService.CommonReadResource(RESOURCE_TYPE_DISPUTE, disputeUID);
}

//----------------------------------------------------------------------------------------------------------------------
function ReadDisputeSplit(splitUID) {
    return CommonResourcesService.CommonReadResource(RESOURCE_TYPE_DISPUTE_SPLIT, splitUID);
}

//----------------------------------------------------------------------------------------------------------------------
function ReadDepositCloseRequest(closeRequestUID) {
    return CommonResourcesService.CommonReadResource(RESOURCE_TYPE_DEPOSIT_CLOSE_REQUEST, closeRequestUID);
}

//----------------------------------------------------------------------------------------------------------------------
function TenantRequestsHelp(helpMessage) {
    return axiosRequest({
        url: `api/deposits/tenant/request/help`,
        data: helpMessage,
        method: 'PUT'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function WithholdDepositFunds(withholdPayload) {
    return axiosRequest({
        url: API_ROUTE_DEPOSIT_WITHHOLD_FUNDS,
        data: withholdPayload,
        method: 'POST'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function ReadAllWithheldFundsUIDsByDeposit(depositUID, offset, limit) {
    return axiosRequest({
        url: `api/deposits/deposit/${depositUID}/withheld/funds/list/${offset}/${limit}`,
        method: 'GET'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function CancelWithheldFunds(depositUID, withheldUID) {
    return axiosRequest({
        url: `api/deposits/${depositUID}/withheld/funds/${withheldUID}/cancel`,
        method: 'DELETE'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function EditWithheldFundsAmount(depositUID, withheldUID, newAmountInCents) {
    return axiosRequest({
        url: `api/deposits/${depositUID}/withheld/funds/${withheldUID}/edit/amount/${newAmountInCents}`,
        data: {},
        method: 'PUT'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function UpdateWithheldFunds(withholdUID, updateData) {
    return CommonResourcesService.CommonUpdateResource(RESOURCE_TYPE_DEPOSIT_WITHHELD_FUNDS, withholdUID, updateData);
}

//----------------------------------------------------------------------------------------------------------------------
function LandlordClosesDeposit(depositUID, amountInCentsForLandlord, amountInCentsForTenant, reasonForClose) {
    const postValues = {
        deposit_uid: depositUID,
        amount_in_cents_to_landlord: amountInCentsForLandlord,
        amount_in_cents_to_tenant: amountInCentsForTenant,
        reason_for_close: reasonForClose
    };

    return axiosRequest({
        url: `api/deposits/${depositUID}/close/landlord`,
        data: postValues,
        method: 'POST'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function AttachFilesToWithheldFunds(depositUID, withheldUID, fileUIDList) {
    return axiosRequest({
        url: `api/deposits/${depositUID}/withheld/funds/${withheldUID}/files/added`,
        data: {file_uid_list: fileUIDList},
        method: 'PUT'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function CreateProperty(postData) {
    return CommonResourcesService.CommonCreateResource(RESOURCE_TYPE_PROPERTY, postData);
}

//----------------------------------------------------------------------------------------------------------------------
function CreatePropertyAddress(postData) {
    return CommonResourcesService.CommonCreateResource(RESOURCE_TYPE_PROPERTY_ADDRESS, postData);
}

//----------------------------------------------------------------------------------------------------------------------
function RescindDepositInvite(depositUID) {
    return axiosRequest({
        url: `api/deposits/${depositUID}/invite/cancel`,
        method: 'DELETE'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function ResendDepositInvite(depositUID) {
    return axiosRequest({
        url: renderURL(API_ROUTE_RESEND_DEPOSIT_INVITE, {depositUID: depositUID}),
        data: {},
        method: 'PUT'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function SezzleCreateDepositPayment(depositUID) {
    return axiosRequest({
        url: renderURL(API_ROUTE_CREATE_SEZZLE_ORDER, {depositUID: depositUID}),
        data: {},
        method: 'POST'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function ReadChildProperties(propertyUID, offset, limit) {
    return axiosRequest({
        url: renderURL(
            API_ROUTE_READ_CHILD_PROPERTIES,
            {propertyUID: propertyUID, offset: offset, limit: limit}
        ),
        method: 'GET'
    });
}

//----------------------------------------------------------------------------------------------------------------------
function PropertyAddressGeocoderSearch(searchQuery) {
    return axiosRequest({
        url: API_ROUTE_PROPERTY_ADDRESS_GEOCODER_SEARCH,
        method: 'PUT',
        data: {'search_query': searchQuery}
    });
}

//----------------------------------------------------------------------------------------------------------------------
function PropertyAddressGeocoderLocationIDLookup(locationID) {
    return axiosRequest({
        url: API_ROUTE_PROPERTY_ADDRESS_GEOCODER_LOCATION_ID_LOOKUP,
        method: 'PUT',
        data: {'location_id': locationID}
    });
}

export default DepositsService;