/*

 */

import React from 'react';
import SystemStatsContainer from "./systemStatsContainer";
import {
    SYSTEM_STATS_ID_DAILY_DEPOSIT_DETAILED_STATS,
    SYSTEM_STATS_ID_DAILY_PAYMENT_STATS,
    SYSTEM_STATS_ID_DAILY_WITHDRAWAL_STATS
} from "./systemStatsIDs";
import {DailyPaymentStats} from "./graphs/statsDailyPaymentStats";
import {DailyDetailedDepositStats} from "./graphs/statsDailyDetailedDepositStats";
import Grid from "@material-ui/core/Grid";
import {DEFAULT_STATS_LOOK_BACK_COUNT} from "../../constants/apiConstants";
import {DailyPaymentAmountStats} from "./graphs/statsDailyPaymentAmountStats";
import {DailyWithdrawalStats} from "./graphs/statsDailyWithdrawalStats";

const GRAPH_WIDTH = 400;

export function AdminHomepageStatsCollection() {

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
        >
            <Grid item>
                {topRow()}
            </Grid>

            <Grid item>
                {bottomRow()}
            </Grid>

        </Grid>
    )

    function topRow() {
        return (

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item>
                    <SystemStatsContainer
                        statsID={SYSTEM_STATS_ID_DAILY_PAYMENT_STATS}
                        readCount={DEFAULT_STATS_LOOK_BACK_COUNT}
                    >
                        <DailyPaymentStats width={GRAPH_WIDTH}/>
                    </SystemStatsContainer>
                </Grid>

                <Grid item>
                    <SystemStatsContainer
                        statsID={SYSTEM_STATS_ID_DAILY_PAYMENT_STATS}
                        readCount={DEFAULT_STATS_LOOK_BACK_COUNT}
                    >
                        <DailyPaymentAmountStats width={GRAPH_WIDTH}/>
                    </SystemStatsContainer>
                </Grid>

                {/*<Grid item>*/}
                {/*    <SystemStatsContainer*/}
                {/*        statsID={SYSTEM_STATS_ID_DAILY_DEPOSIT_DETAILED_STATS}*/}
                {/*        // readCount={DEFAULT_STATS_LOOK_BACK_COUNT}*/}
                {/*        readCount={7}*/}
                {/*    >*/}
                {/*        <DailyDetailedDepositStats/>*/}
                {/*    </SystemStatsContainer>*/}
                {/*</Grid>*/}

                {/*<Grid item>*/}
                {/*    <SystemStatsContainer*/}
                {/*        statsID={SYSTEM_STATS_ID_DAILY_INTEREST_STATS}*/}
                {/*        readCount={DEFAULT_STATS_LOOK_BACK_COUNT}*/}
                {/*    >*/}
                {/*        <DailyInterestStats/>*/}
                {/*    </SystemStatsContainer>*/}
                {/*</Grid>*/}

                {/*<Grid item>*/}
                {/*    <SystemStatsContainer*/}
                {/*        statsID={SYSTEM_STATS_ID_DAILY_DEPOSIT_STATE_STATS}*/}
                {/*        readCount={DEFAULT_STATS_LOOK_BACK_COUNT}*/}
                {/*    >*/}
                {/*        <DailyDepositStateStats/>*/}
                {/*    </SystemStatsContainer>*/}
                {/*</Grid>*/}

                <Grid item>
                    <SystemStatsContainer
                        statsID={SYSTEM_STATS_ID_DAILY_WITHDRAWAL_STATS}
                        readCount={DEFAULT_STATS_LOOK_BACK_COUNT}
                    >
                        <DailyWithdrawalStats width={GRAPH_WIDTH}/>
                    </SystemStatsContainer>
                </Grid>

            </Grid>
        )
    }

    function bottomRow() {
        return (

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={2}
            >


                <Grid item>
                    <SystemStatsContainer
                        statsID={SYSTEM_STATS_ID_DAILY_DEPOSIT_DETAILED_STATS}
                        // readCount={DEFAULT_STATS_LOOK_BACK_COUNT}
                        readCount={14}
                    >

                        <DailyDetailedDepositStats width={1024}/>
                    </SystemStatsContainer>
                </Grid>


            </Grid>
        )
    }

}
