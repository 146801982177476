/*

 */

import {createSelector} from 'reselect'
import {jsDateTimeFromString} from "../utilities/commonUtils";
import {alwaysShowArchivedDepositsSelector, currentUserAccountUIDSelector} from "./userSelectors";
import {isArchivedForCurrentUser} from "../utilities/DepositSharedFunctions";

// TODO: Remove this
export const depositsSelector = state => state.deposits;
// TODO: Rename this
export const actualDepositsSelector = state => state.deposits.deposits;
const depositMetaDataSelector = state => state.deposits.depositMetaData;
export const depositInvitesSelector = state => state.deposits.depositInvites;
const depositDisputeSelector = state => state.deposits.disputes;
const depositDisputeSplitsSelector = state => state.deposits.disputeSplits;
export const depositCloseRequestsSelector = state => state.deposits.closeRequests;
export const depositUserStatsSelector = state => state.deposits.depositUserStats;
export const depositHistorySelector = state => state.deposits.depositHistory;

export const depositUIDSelector = (state, depositUID) => depositUID;
export const disputeUIDSelector = (state, disputeUID) => disputeUID;
export const disputeSplitUIDSelector = (state, disputeSplitUID) => disputeSplitUID;
export const closeRequestUIDSelector = (state, closeRequestUID) => closeRequestUID;

export const includeArchivedDepositsSelector = (state, includeArchivedDeposits) => includeArchivedDeposits;

export const makeDepositSelector = () => createSelector(
    [actualDepositsSelector, depositUIDSelector],
    (deposits, depositUID) => deposits[depositUID]
);

export const makeDepositMetaDataSelector = () => createSelector(
    [depositMetaDataSelector, depositUIDSelector],
    (metaData, depositUID) => metaData[depositUID]
);

export const makeDepositInviteByDepositSelector = () => createSelector(
    [depositInvitesSelector, depositUIDSelector],
    (depositInvites, depositUID) => {
        //depositInvites[depositUID]
        const keys = Object.keys(depositInvites);
        for (const key of keys) {
            const currentDepositInvite = depositInvites[key];
            if(currentDepositInvite.deposit_uid === depositUID){
                return currentDepositInvite;
            }
        }

        return undefined;
    }
);

export const makeDisputeSelector = () => createSelector(
    [depositDisputeSelector, disputeUIDSelector],
    (disputes, disputeUID) => disputes[disputeUID]
);

export const makeDisputeSplitSelector = () => createSelector(
    [depositDisputeSplitsSelector, disputeSplitUIDSelector],
    (disputeSplits, disputeSplitUID) => disputeSplits[disputeSplitUID]
);

export const makeCloseRequestSelector = () => createSelector(
    [depositCloseRequestsSelector, closeRequestUIDSelector],
    (closeRequests, closeRequestUID) => closeRequests[closeRequestUID]
);

export const makeDepositHistorySelector = () => createSelector(
    [depositHistorySelector, depositUIDSelector],
    (depositHistory, depositUID) => {
        const historyEntries = Object.values(depositHistory).filter(hisEntry => hisEntry.resource_uid === depositUID);
        return historyEntries.sort(function (a, b) {
            return jsDateTimeFromString(b.last_updated_timestamp) - jsDateTimeFromString(a.last_updated_timestamp)
        });
    }
);



export const makeRecentlyUpdatedDepositsSelector = () => createSelector(
    [recentlyUpdatedDepositsSelector, includeArchivedDepositsSelector, currentUserAccountUIDSelector],
    (deposits, includeArchivedDeposits, currentUserAccountUID) => {
        if (includeArchivedDeposits) {
            return deposits;
        }

        return deposits.filter(dep => isArchivedForCurrentUser(dep, currentUserAccountUID) === false);
    }
);


export const archiveAwareAllDepositsSelector = createSelector(
    [actualDepositsSelector, alwaysShowArchivedDepositsSelector, currentUserAccountUIDSelector],
    (deposits, showArchived, currentUserAccountUID) => {
        if (showArchived) {
            return Object.values(deposits);
        }
        return Object.values(deposits).filter(deposit => !isArchivedForCurrentUser(deposit, currentUserAccountUID));
    }
);

export const recentlyUpdatedDepositsSelector = createSelector(
    [archiveAwareAllDepositsSelector],
    (deposits) => {
        return deposits.sort(function (a, b) {
            return jsDateTimeFromString(b.last_updated_timestamp) - jsDateTimeFromString(a.last_updated_timestamp)
        });
    }
);

export const NewDepositSelector = createSelector(
    [actualDepositsSelector, depositUIDSelector],
    (deposits, depositUID) => deposits[depositUID]
);
