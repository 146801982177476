import React, {useState} from 'react';
import RhinoBulkBankInfoBankAccounts from "./rhinoBulkBankInfoBankAccounts";
import RhinoBulkBankInfoRows from "./rhinoBulkBankInfoRows";
import Typography from "@material-ui/core/Typography";
import IF from "../../../clientShared/components/utility/IFComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload, faInfoCircle, faUserSecret} from "@fortawesome/pro-regular-svg-icons";
import RhinoBulkBankImportControls from "./rhinoBulkBankImportControls";
import Confetti from 'react-confetti'
import {useWindowSize} from "react-use";
import {faFileExcel, faRocket} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import * as XLSX from 'xlsx';
import Grid from "@material-ui/core/Grid";
import {Alert} from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import HomeLink from "../../admin/buttons/linkButtons/HomeLink";

const RhinoBulkBankInfo = ({parsedData, validationResults}) => {
    const {width, height} = useWindowSize();
    const [submitResults, setSubmitResults] = useState({});

    if (Object.keys(parsedData).length === 0 || Object.keys(validationResults).length === 0) {
        return <>Loading...</>;
    }

    const hasResults = Object.keys(submitResults).length > 0;
    const hasPartnerAccount = Object.keys(validationResults.partner_account).length > 0;
    const partnerAccount = validationResults.partner_account;

    if (hasResults) {
        return (
            <>
                <Confetti
                    width={width}
                    height={height}
                    recycle={false}
                />

                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item>
                        <Typography variant={"h6"} align={"center"} style={{marginBottom: 18}}>
                            <FontAwesomeIcon icon={faRocket} fixedWidth/>&nbsp;Import Complete
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            Your bulk import of bank accounts is complete.
                            <br/>
                            You should download the two files below and keep them in case anything looks wrong / for
                            reference in the future.
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="span">
                            <ul>
                                <li>The Excel (XLSX) contains a list of Property IDs mapped to Bank Account UIDs.</li>
                                <li>The JSON file contains the raw data used during import (this contains all the
                                    banking info, so keep it safe&nbsp;
                                    <FontAwesomeIcon icon={faUserSecret} fixedWidth/>
                                </li>
                            </ul>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            alignItems="center"
                            spacing={4}
                        >
                            <Grid item>
                                <Button variant="contained" size="medium" color="primary" onClick={downloadExcelData}>
                                    <FontAwesomeIcon icon={faFileExcel} fixedWidth/>&nbsp;Download Excel Data (XLSX)
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button variant="contained" size="medium" color="primary" onClick={downloadData}>
                                    <FontAwesomeIcon icon={faFileDownload} fixedWidth/>&nbsp;Download JSON Data
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <br/>
                    </Grid>

                    <Grid item>
                        That's it, you're all done. <HomeLink useHref={true}/>
                    </Grid>

                    {/*<Grid item>*/}
                    {/*    <pre>{JSON.stringify(submitResults, null, 2)}</pre>*/}
                    {/*</Grid>*/}

                </Grid>
            </>
        )
    }

    // todo: grid
    // todo: click to copy
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >
            <Grid item>
                <Typography variant={"h6"}>
                    Partner Account
                </Typography>

                <IF condition={!hasPartnerAccount}>
                    <Alert severity="warning">
                        No partner account found in Deposify with ID: {parsedData.partnerID}
                    </Alert>
                </IF>

                <IF condition={hasPartnerAccount}>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Please ensure the following information is correct, and for the intended partner:
                    </Typography>

                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Rhino User ID</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Second Name</TableCell>
                                    <TableCell>Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">{partnerAccount.rhino_user_id}</TableCell>
                                    <TableCell component="th" scope="row">{partnerAccount.first_name}</TableCell>
                                    <TableCell component="th" scope="row">{partnerAccount.last_name}</TableCell>
                                    <TableCell component="th" scope="row">{partnerAccount.email_address}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </IF>
            </Grid>

            <Grid item>
                <br/>
            </Grid>

            <Grid item>
                <Typography variant={"h6"}>
                    Bank Accounts
                </Typography>

                <IF condition={parsedData.hasMergedBankAccounts}>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <FontAwesomeIcon icon={faInfoCircle} fixedWidth/>&nbsp;
                        For convenience, the duplicate bank account data has been merged.
                    </Typography>
                </IF>

                <RhinoBulkBankInfoBankAccounts parsedData={parsedData} validationResults={validationResults}/>
            </Grid>

            <Grid item>
                <br/>
            </Grid>

            <Grid item>
                <Typography variant={"h6"}>
                    Properties
                </Typography>

                <RhinoBulkBankInfoRows parsedData={parsedData} validationResults={validationResults}/>
            </Grid>

            <Grid item>
                <br/>
            </Grid>

            <Grid item>
                <RhinoBulkBankImportControls
                    parsedData={parsedData}
                    validationResults={validationResults}
                    successCallback={handleSubmitResults}
                />
            </Grid>

            {/*<Grid item>*/}
            {/*    <br/>*/}
            {/*    <pre>{JSON.stringify(parsedData, null, 2)}</pre>*/}
            {/*    <br/>*/}
            {/*    <pre>{JSON.stringify(validationResults, null, 2)}</pre>*/}
            {/*</Grid>*/}
        </Grid>
    );

    function handleSubmitResults(newResults) {
        setSubmitResults(newResults);
    }

    function downloadData() {
        const data = {
            parsedData: parsedData,
            validationResults: validationResults,
            submitResults: submitResults
        }

        const blob = new Blob([JSON.stringify(data, null, 2)], {type: 'application/json'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = genFileName("json");
        link.click();
    }

    function downloadExcelData() {
        const userAccount = validationResults.partner_account;

        let finalData = [];
        Object.keys(submitResults.property_bank_uid_mapping).map(propertyID => {
            finalData.push({
                "Property ID": propertyID,
                "Bank Account UID": submitResults.property_bank_uid_mapping[propertyID],
                "Partner ID": userAccount.rhino_user_id,
                "Partner Account UID": userAccount.account_uid
            });
        })

        const ws = XLSX.utils.json_to_sheet(finalData);

        ws['!cols'] = [
            {wch: 20},  // Property ID
            {wch: 30},  // Bank Account UID
            {wch: 20},  // Partner ID
            {wch: 30},  // Partner Account UID
        ];

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Bank Accounts');

        const fileName = genFileName("xlsx");

        XLSX.writeFile(wb, fileName);
    }

    function genFileName(extension) {
        const userAccount = validationResults.partner_account;
        const preFix = `bank_bulk_import_${userAccount.first_name}_${userAccount.first_name}`;

        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const date = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const dateTimeString = `${year}_${month}_${date}__${hours}_${minutes}_${seconds}`;

        return `${preFix}__${dateTimeString}.${extension}`;
    }
};

export default RhinoBulkBankInfo;