import React from 'react';
import * as Sentry from "@sentry/browser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWifiSlash} from "@fortawesome/pro-solid-svg-icons";
import SpinnerButton from "../../utilities/buttons/SpinnerButton";
import ConfirmationDialog from "../../utilities/dialogs/ConfirmationDialog";
import FrontendAdminService from "../../../api/FrontendAdminService";
import toast from "react-hot-toast";

const KickAllOnlineUsersOffAdminButton = (props) => {

    const [showSpinner, setShowSpinner] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);

    return (
        <React.Fragment>
            <ConfirmationDialog
                startOpen={showDialog}
                dangerMode={true}
                confirmCallback={handleClick}
                cancelCallback={() => {
                }}
                dialogTitle={'Are you sure?'}
                dialogText={`Are you sure you sure want to kick all online users off?`}
                confirmText={'Yes'}
                showSpinner={showSpinner}
                cancelFocus={true}
            />
            <SpinnerButton
                // Non-button props
                showSpinner={showSpinner}
                // Button props
                variant="outlined"
                color="secondary"
                disabled={showSpinner}
                onClick={handleButtonClick}
            >
                <FontAwesomeIcon
                    icon={faWifiSlash}
                    fixedWidth
                />&nbsp;Kick All Users Offline
            </SpinnerButton>
        </React.Fragment>
    );

    function handleButtonClick() {
        setShowSpinner(false);
        setShowDialog(true);
    }

    function handleClick() {
        setShowSpinner(true);

        FrontendAdminService.LogAllOnlineUsersOut()
            .then(function (response) {
                setShowSpinner(false);
                setShowDialog(false);
            })
            .catch(function (error) {
                // Tell Sentry about the error
                Sentry.withScope(scope => {
                    scope.setExtra("response", error.response);
                    Sentry.captureException(error);
                });

                setShowSpinner(false);
                toast.error('Failed to kick all users offline');
            });
    }

}


export default KickAllOnlineUsersOffAdminButton;