import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/pro-regular-svg-icons";
import {getAdminPath} from "../../../api/adminAPI";
import {axiosInstance} from "../../../utilities/axiosInstance";
import toast from "react-hot-toast";
import SpinnerButton from "../../../components/utilities/buttons/SpinnerButton";


const JeffReportButton = ({reportName, reportURL, reportFileName}) => {
    const [showSpinner, setShowSpinner] = React.useState(false);

    return (

        <SpinnerButton
            // Non-button props
            showSpinner={showSpinner}
            // Button props
            onClick={handleOpen}
            disabled={showSpinner}
            variant={'outlined'}
            // size={'small'}
            color={'primary'}
            type={'submit'}
        >
            <FontAwesomeIcon
                icon={faDownload}
                fixedWidth
            />
            &nbsp;{reportName}
        </SpinnerButton>
    );

    function handleOpen() {
        setShowSpinner(true);

        // window.location.href = getAdminPath(reportURL);

        const blobDLParams = {
            responseType: 'blob',
            headers: {
                Accept: 'application/octet-stream',
            },
        };

        // const cacheBusterURL = (url) => `${url}?cb=${Date.now()}`;
        const cacheBusterURL = `${getAdminPath(reportURL)}?cb=${Date.now()}`;
        const dlPromise = axiosInstance.get(cacheBusterURL, blobDLParams)
            .then(function (response) {
                // console.log(response);

                const blob = new Blob(
                    [response.data],
                    {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                );
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = reportFileName;
                link.click();

                setShowSpinner(false);
            })
            .catch(function (error) {
                setShowSpinner(false);
                toast.error('Failed to download report');
            });

        toast.promise(
            dlPromise,
            {
                loading: 'Generating...',
                success: 'Report Generated',
                error: 'Unable to generate report at this time',
            }
        ).then(() => {
            setShowSpinner(false);
        });

    }


};


export default JeffReportButton;