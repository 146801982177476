import React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";


const RhinoBankBulkUploadHeaderIssues = ({headerMapping}) => {
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >
            <Grid item>
                <Typography variant={"h6"}>
                    TSV File Parsing Error
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                    Please update your TSV file, then upload to re-parse.
                    <br/>
                    All required columns must be present in the file, and the column name must match the expected names
                    listed below.
                    <br/>
                    Extra columns, or columns with names not on the list below will be ignored.
                </Typography>

                {/*<br/>*/}
                {/*<RhinoBankBulkUploadFileInfoSamples/>*/}
            </Grid>

            <Grid item>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Column</TableCell>
                                <TableCell>Required</TableCell>
                                <TableCell>Allowed Names</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.keys(headerMapping).map((headerKey, keyIndex) => {
                                    if (headerKey === "meta")
                                        return;

                                    const currentField = headerMapping[headerKey];
                                    const found = currentField.column_name !== null;

                                    const expectedColsString = currentField.expected_col_names.join(", ");
                                    const requiredString = currentField.required ? "Yes" : "No";
                                    const theIcon = currentField.required ? found ? faCheck : faExclamationTriangle : faCheck;
                                    const theIconColor = currentField.required ? found ? "#3cef66" : "#ef3c3c" : "#3cef66";

                                    return (
                                        <TableRow key={`header-problem-${keyIndex}`}>
                                            <TableCell component="th" scope="row">
                                                <FontAwesomeIcon icon={theIcon} fixedWidth color={theIconColor}/>&nbsp;
                                                {currentField.displayName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {requiredString}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {expectedColsString}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {currentField.description}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </Grid>
    );
};

export default RhinoBankBulkUploadHeaderIssues;