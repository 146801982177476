// Name: Storage Body Render Definitions 

export const ATTACHED_STORAGE_FILES_BODY_RENDER_DEF = {
	file_uids: {displayName: "File UIDs"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	attached_files_uid: {displayName: "Attached Files UID"},
	creating_account_uid: {displayName: "Creating Account UID"},
	resource_uid: {displayName: "Resource UID"},
	resource_type: {displayName: "Resource Type"}
};

export const STORAGE_FILE_BODY_RENDER_DEF = {
	deleting_account_uid: {displayName: "Deleting Account UID"},
	resource_type: {displayName: "Resource Type"},
	size_in_bytes: {displayName: "Size In Bytes"},
	resource_uid: {displayName: "Resource UID"},
	uploading_account_uid: {displayName: "Uploading Account UID"},
	file_type: {displayName: "File Type"},
	deleted: {displayName: "Deleted"},
	bucket_file_name: {displayName: "Bucket File Name"},
	file_uid: {displayName: "File UID"},
	bucket_folder_path: {displayName: "Bucket Folder Path"},
	bucket_name: {displayName: "Bucket Name"},
	deleted_timestamp: {displayName: "Deleted Timestamp"},
	last_updated_timestamp: {displayName: "Last Updated Timestamp"},
	uploaded_timestamp: {displayName: "Uploaded Timestamp"},
	created_timestamp: {displayName: "Created Timestamp"},
	state: {displayName: "State"},
	file_extension: {displayName: "File Extension"},
	deposify_tags: {displayName: "Deposify Tags"},
	original_file_name: {displayName: "Original File Name"}
};

